import './Manage-Goal-Mappings.scss';

import { useEffect, useState } from 'react';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';

// TYPES
import { AgencyItem } from '../../state';

// API REQUESTS
import { getUserAgencies } from '../../api';

// UI COMPONENTS
import AdminPage from '../../components/Admin-Page/Admin-Page';
import AddGoalForm from '../../components/Add-Goal-Form/Add-Goal-Form';
import ExistingGoalsTable from '../../components/Charts/Existing-Goals-Table';

// FUNCTIONAL COMPONENT
function ManageGoalMappings() {
    // HOOKS
    const asyncWrapper = useAsyncWrapper();

    // CURRENT USER'S ID
    const { user } = useAppSelector(({ auth }) => auth);

    // USER'S GOALS LIST
    const { list: goalsList } = useAppSelector(({ goals }) => goals);

    // REDUX ACTIONS
    const { loadGoalsList } = useActions();

    // USER'S AGENCIES
    const [agencies, setAgencies] = useState<AgencyItem[]>([]);

    // LOAD USER'S AGENCIES
    const loadAgencies = asyncWrapper(async function () {
        if (!user) return;
        const agencies = await getUserAgencies(user.id);
        setAgencies(agencies);
    });

    // FETCH USER'S AGENGIES ON INITIAL PAGE LOAD
    useEffect(() => {
        loadAgencies();
    }, []);

    // FETCH AND STORE GOALS LIST ON INITIAL PAGE LOAD (IF NOT ALREADY STORED IN REDUX)
    useEffect(() => {
        if (goalsList.length || !user) return;
        loadGoalsList(user);
    }, []);

    return (
        <AdminPage header="add new goal">
            <div className="manage-goal-mappings-container">
                {/* --- ADD NEW GOAL FORM --- */}
                <div className="add-goal-form-content">
                    <AddGoalForm agencies={agencies} />
                </div>

                {/* --- EXISTING GOALS --- */}
                <div className="existing-goals-content">
                    <h2 className="existing-goals-header">Existing Goals</h2>
                    <div className="existing-goals-table">
                        <ExistingGoalsTable goals={goalsList} />
                    </div>
                </div>
            </div>
        </AdminPage>
    );
}

export default ManageGoalMappings;
