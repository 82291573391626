import produce from 'immer';
import { AdhocModalState } from '../models';
import { AdhocModalAction } from '../actions';
import { ADHOC_MODAL_ACTION_TYPES } from '../action-types';

const DEFAULT_ADHOC_MODAL_STATE: AdhocModalState = {
    display: false,
    target: '',
    numerator: 'cost',
    denominator: null,
    product: null,
};

export const adhocModalReducer = produce(
    (state: AdhocModalState, action: AdhocModalAction): AdhocModalState => {
        switch (action.type) {
            case ADHOC_MODAL_ACTION_TYPES.SET_ADHOC_DATA:
                state = {
                    ...DEFAULT_ADHOC_MODAL_STATE,
                };

                state.product = action.payload.product;
                state.denominator =
                    action.payload.denominator === 'Revenue'
                        ? 'Revenue'
                        : 'Actions';

                return state;

            case ADHOC_MODAL_ACTION_TYPES.SET_DISPLAY_ADHOC_MODAL:
                state.display = action.payload;
                return state;

            case ADHOC_MODAL_ACTION_TYPES.SET_ADHOC_TARGET:
                state.target = action.payload;
                return state;

            case ADHOC_MODAL_ACTION_TYPES.SET_ADHOC_NUMERATOR:
                state.numerator = action.payload;
                return state;

            case ADHOC_MODAL_ACTION_TYPES.SET_ADHOC_DENOMINATOR:
                state.denominator = action.payload;
                return state;

            case ADHOC_MODAL_ACTION_TYPES.SET_ADHOC_PRODUCT:
                state.product = action.payload;
                return state;

            case ADHOC_MODAL_ACTION_TYPES.RESET_ADHOC_DATA:
                state = { ...DEFAULT_ADHOC_MODAL_STATE };

                if (action.payload) {
                    state.product = action.payload;
                }

                return state;

            default:
                return state;
        }
    },
    DEFAULT_ADHOC_MODAL_STATE
);
