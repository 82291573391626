import {
    CustomColumnDetails,
    UpdateBingSubAccount,
    UpdateGoogleSubAccount,
    UpdateAdvertiserDetails,
} from '../../models';
import { BingAdsFormSubAccount } from './bing-ads-model';
import { GoogleAdsFormSubAccount } from './google-ads-model';
import {
    SA360FormAdvertiser,
    CustomColumnFormData,
} from './search-ads-360-model';

export interface KenshooReportDetails {
    report_name: string; // DISABLED
    report_id: string; // ENABLED
    report_type: string; // DISABLED
}

export interface EditAccountState {
    platformId: number; // NOT EDITABLE
    platform: string; // NOT EDITABLE
    accountName: string; // NOT EDITABLE
    agencyId: string; // NOT EDITABLE
    isDSASupport: boolean; // (SA 360 PLATFORM ONLY)
    isPLASupport: boolean; // (SA 360 PLATFORM ONLY)
    isRevenueSupport: boolean;
    isCustomColumnSupport: boolean;
    isGoogleAdsSupport: boolean; // NOT EDITABLE
    editIsGoogleAdsSupport: boolean; // EDITABLE VALUE -> (ONLY ENABLE NOT DISABLE)
    isBingAdsSupport: boolean; // NOT EDITABLE
    editIsBingAdsSupport: boolean; // EDITABLE VALUE -> (ONLY ENABLE NOT DISABLE)
    sa360ManagerEmail: string;
    advertisers: SA360FormAdvertiser[];
    kenshooReportDetails: KenshooReportDetails[];
    bingAdsManagerEmail: string; // ENABLED
    bingAdsSubAccounts: BingAdsFormSubAccount[]; // ENABLED/DISABLED ?
    customColumns: CustomColumnFormData[]; // ENABELD/DISABLED ?
    customRevenueColumns: CustomColumnFormData[];
    isManagerAccount: boolean; // NOT EDITABLE -> (GOOGLE ADS PLATFORM ONLY)
    editIsManagerAccount: boolean; // EDITABLE -> (GOOGLE ADS PLATFORM ONLY)
    customerName: string; // (GOOGLE ADS PLATFORM ONLY)
    customerId: string; // (GOOGLE ADS PLATFORM ONLY)
    customerStatus: boolean; // (GOOGLE ADS PLATFORM && NON MANAGER ACCOUNT ONLY)
    adwordsManagerEmail: string; // ENABLED (GOOGLE ADS ONLY)
    googleAdsSubAccounts: GoogleAdsFormSubAccount[]; // ENABLED/DISABLED
}

export const INITIAL_EDIT_ACCOUNT_STATE: EditAccountState = {
    platformId: 0,
    platform: '',
    accountName: '',
    agencyId: '',
    isDSASupport: false,
    isPLASupport: false,
    isRevenueSupport: false,
    isCustomColumnSupport: false,
    isGoogleAdsSupport: false,
    editIsGoogleAdsSupport: false,
    isBingAdsSupport: false,
    editIsBingAdsSupport: false,
    sa360ManagerEmail: '',
    advertisers: [],
    kenshooReportDetails: [],
    bingAdsManagerEmail: '',
    bingAdsSubAccounts: [],
    customColumns: [],
    customRevenueColumns: [],
    isManagerAccount: false,
    editIsManagerAccount: false,
    customerName: '',
    customerId: '',
    customerStatus: true,
    adwordsManagerEmail: '',
    googleAdsSubAccounts: [],
};
