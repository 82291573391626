import { axios } from '../axios-config';

// CONSTANTS
import {
    API_BULKSHEET_TRANSLATOR,
    API_DARWIN,
    StatusOption,
    TODAY,
} from '../../constants';

// TYPES
import {
    GETDarwinDataV3,
    OptimizationStrategy,
    CreateBulkSheetParams,
    CreateCappingBulkSheetParams,
} from '../../state';

// UTILS
import { saveDownloadFile } from '../../utils/download-file';

export const getDarwinDataV3 = async (
    userId: string,
    goalId: string | number,
    strategy: OptimizationStrategy
) => {
    try {
        const url = API_DARWIN + '/BidSelection/getDarwinDataV3';
        const { data } = await axios.get(url, {
            params: {
                userId,
                goalId,
                strategy,
            },
        });

        return data;
    } catch (error) {
        throw error;
    }
};

// DOWNLOAD BIDS BULKSHEET
export const createBulkSheet = async (
    goalMappingName: string,
    params: CreateBulkSheetParams
) => {
    try {
        const fileName = `Bids_${goalMappingName}_${TODAY}.xlsx`;
        const url = API_BULKSHEET_TRANSLATOR + '/createBulkSheet';
        const { userId, goalId, strategy, bInsights } = params;

        const body = new URLSearchParams();
        body.append('userId', userId);
        body.append('goalId', goalId.toString());
        body.append('strategy', strategy || 'Max Budget');
        body.append('bInsights', bInsights);

        const blob = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            responseType: 'blob',
        });

        saveDownloadFile(blob, fileName);
    } catch (error) {
        throw error;
    }
};

// DOWNLOAD CAPS BULKSHEET
export const createCappingBulkSheet = async (
    goalMappingName: string,
    params: CreateCappingBulkSheetParams
) => {
    try {
        const fileName = `Caps_${goalMappingName}_${TODAY}.xlsx`;
        const url = API_BULKSHEET_TRANSLATOR + '/createCappingBulkSheet';
        const { userId, goalId, strategy, cInsights } = params;

        const body = new URLSearchParams();
        body.append('userId', userId);
        body.append('goalId', goalId.toString());
        body.append('strategy', strategy || 'Max Budget');
        body.append('cInsights', cInsights);

        const blob = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            responseType: 'blob',
        });

        saveDownloadFile(blob, fileName);
    } catch (error) {
        throw error;
    }
};

// DOWNLOAD EDITED BIDS BULKSHEET
export const createFormattedBidsBulkSheet = async (
    goalMappingName: string,
    params: CreateBulkSheetParams
) => {
    try {
        const fileName = `Bids_${goalMappingName}_${TODAY}.xlsx`;
        const url = API_BULKSHEET_TRANSLATOR + '/createFormattedBidsBulkSheet';
        const { userId, goalId, strategy, bInsights } = params;

        const body = new URLSearchParams();
        body.append('userId', userId);
        body.append('goalId', goalId.toString());
        body.append('strategy', strategy || 'Max Budget');
        body.append('bInsights', bInsights);

        const blob = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            responseType: 'blob',
        });

        saveDownloadFile(blob, fileName);
    } catch (error) {
        throw error;
    }
};

// DOWNLOAD EDITTED CAPS BULKSHEET
export const createFormattedCappingBulkSheet = async (
    goalMappingName: string,
    params: CreateCappingBulkSheetParams
) => {
    try {
        const fileName = `Caps_${goalMappingName}_${TODAY}.xlsx`;
        const url =
            API_BULKSHEET_TRANSLATOR + '/createFormattedCappingBulkSheet';
        const { userId, goalId, strategy, cInsights } = params;

        const body = new URLSearchParams();
        body.append('userId', userId);
        body.append('goalId', goalId.toString());
        body.append('strategy', strategy || 'Max Budget');
        body.append('cInsights', cInsights);

        const blob = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            responseType: 'blob',
        });

        saveDownloadFile(blob, fileName);
    } catch (error) {
        throw error;
    }
};

// DOWNLOAD BULKSHEET LOGS
export const createBulksheetLogs = async (fileName: string) => {
    try {
        const url = API_BULKSHEET_TRANSLATOR + '/downloadBulkSheetLogs';
        const blob = await axios.get(url, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            params: {
                fileName,
            },
            responseType: 'blob',
        });
        saveDownloadFile(blob, fileName);
    } catch (error) {
        throw error;
    }
};

// GET BULKSHEET LOGS
export const getBulksheetLogs = async (userId: string) => {
    try {
        const url = API_DARWIN + '/BulkSheetTranslator/getBulkSheetLogs';
        const { data } = await axios.get(url, {
            params: {
                userId,
            },
        });
        return data;
    } catch (error) {
        throw error;
    }
};

// UPLOAD BULKSHEETS DIRECTLY TO SA360
export const uploadSA360BulkSheet = async (
    userId: string,
    goalId: string | number,
    strategy: OptimizationStrategy
) => {
    try {
        const url = API_DARWIN + '/BulkSheetTranslator/uploadBulkSheet';

        const body = new URLSearchParams();
        body.append('userId', userId);
        body.append('goalId', goalId.toString());
        body.append('strategy', strategy || 'Max Budget');

        const { data } = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return data;
    } catch (error) {
        throw error;
    }
};

// UPLOAD MODIFIED FILE TO SA360
export const uploadModifiedFileSA360 = async (userId: string, file: File) => {
    try {
        const url = API_DARWIN + '/BulkSheetTranslator/manualBulkSheetUpload';

        const body: FormData = new FormData();
        body.append('file', file);
        body.append('userId', userId);

        const { data } = await axios.post(url, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: body,
        });

        return data;
    } catch (error) {
        throw error;
    }
};
