import { SELECT_ALL } from '../../constants';
import {
    POSTAddUserDetails,
    POSTEditUserBody,
    POSTEditUserDetails,
    POSTUserPermissions,
    UserDetails,
    UserFormState,
} from '../../state';

export const formatAddUserBody = (userId: string, state: UserFormState) => {
    const userDetails: POSTAddUserDetails = {
        email: state.email,
        llEmail: state.lionEmail,
        fname: state.firstName,
        lname: state.lastName,
        roleId: state.role ? state.role.value : '4', // DEFAULT ROLE: READ-ONLY
    };

    const userPermissions = state.advertisers.reduce(
        (permissions: POSTUserPermissions, { value }) => {
            // FILTER OUT "SELECT ALL" IF PRESENT
            if (value === SELECT_ALL.value) return permissions;

            // GET ACCOUNT & ADVERTISER IDS FROM ADVERTISER DROPDOWN OPTION VALUE;
            // --- NOTE: Dropdown Option value === `${accountId}-${advertiserId}`
            const [accountId, advertiserId] = value.split('-');

            // SET ACCOUNT ID VALUE TO EMPTY ARRAY IF NOT ALREADY PRESET IN PERMISSIONS OBJECT
            if (!permissions[accountId]) {
                permissions[accountId] = [];
            }

            // ADD ID TO ACCOUNTS ARRAY OF SELECTED ADVERTISER IDS
            permissions[accountId].push(advertiserId);

            return permissions;
        },
        {}
    );

    const permittedAccount = Object.keys(userPermissions)[0];

    // SET PLATFORM ID OF FIRST SELECTED ACCOUNT
    const platformId =
        state.accessData?.accounts
            .find(
                (account) => account.account_id.toString() === permittedAccount
            )
            ?.platform_id.toString() || '1';

    // CREATE CSV STRING OF SELECTED FEATURE IDS
    const featurePermissions = state.features
        .map((feature) => feature.value)
        .filter((value) => value !== SELECT_ALL.value)
        .join(',');

    return {
        userId,
        platformId,
        userDetails: JSON.stringify(userDetails),
        userPermissions: JSON.stringify(userPermissions),
        featurePermissions,
    };
};

export const formatEditUserBody = (
    userId: string,
    state: UserFormState
): POSTEditUserBody => {
    const userDetails: POSTEditUserDetails = {
        email: state.email,
        llEmail: state.lionEmail,
        fname: state.firstName,
        lname: state.lastName,
        roleId: state.role ? state.role.value : '4',
        pwd: state.newPassword || '',
        confirmPassword: state.confirmPassword || '',
        action: state.action?.toString() || '0',
    };

    const userPermissions = state.advertisers.reduce(
        (permissions: POSTUserPermissions, { value }) => {
            // FILTER OUT "SELECT ALL" IF PRESENT
            if (value === SELECT_ALL.value) return permissions;

            // GET ACCOUNT & ADVERTISER IDS FROM ADVERTISER DROPDOWN OPTION VALUE;
            // --- NOTE: Dropdown Option value === `${accountId}-${advertiserId}`
            const [accountId, advertiserId] = value.split('-');

            // SET ACCOUNT ID VALUE TO EMPTY ARRAY IF NOT ALREADY PRESET IN PERMISSIONS OBJECT
            if (!permissions[accountId]) {
                permissions[accountId] = [];
            }

            // ADD ID TO ACCOUNTS ARRAY OF SELECTED ADVERTISER IDS
            permissions[accountId].push(advertiserId);

            return permissions;
        },
        {}
    );

    return {
        userId,
        userDetails: JSON.stringify(userDetails),
        userPermissionsAdded: JSON.stringify(userPermissions),
        pwd: state.newPassword || '',
        confirmPassword: state.confirmPassword || '',
        action: state.action?.toString() || '0',
        featurePermissionsAdded: state.features
            .map(({ value }) => value)
            .filter((value) => value !== SELECT_ALL.value)
            .join(','),
    };
};
