import './Add-Account.scss';

import { useEffect, useState } from 'react';
import { getPlatformsData } from '../../../api';
import { DropdownOption, ManagerEmail, PlatformDetails } from '../../../state';

// UI COMPONENTS
import Dropdown from '@publicismedia-ds/ui-dropdown';
import AccountOnboardingForm from '../Account-Onboarding-Form/Account-Onboarding-Form';

// COMPONENT PROPS
interface AddAccountProps {
    onSubmit: () => Promise<void>;
}

function AddAccount({ onSubmit }: AddAccountProps) {
    const [platformList, setPlatformList] = useState<PlatformDetails[]>([]);
    const [selectedPlatform, setSelectedPlatform] =
        useState<PlatformDetails | null>(null);
    const [managerEmails, setManagerEmails] = useState<string[]>([]);

    // FETCH AND STORE PLATFORM DATA ON INITIAL PAGE LOAD
    useEffect(() => {
        (async function () {
            // FETCH PLATFORM DATA
            const data = await getPlatformsData();

            // CHECK PLATFORM DATA IS PRESENT
            if (!data || !data?.platformList.length) return;

            // MANAGER EMAILS DATA
            const [emails]: ManagerEmail[] = data.platformList.slice(-1);

            // STORE MANAGER EMAILS
            setManagerEmails(emails.mEmail);

            const platforms: PlatformDetails[] = data.platformList.slice(0, -1);

            // STORE PLATFORM DATA IN STATE
            setPlatformList(platforms);
        })();
    }, []);

    const platformOptions = platformList.map(
        (platform): DropdownOption => ({
            label: platform.platformName,
            value: platform.platformID.toString(),
        })
    );

    return (
        <div className="add-account-container">
            <Dropdown
                options={platformOptions}
                onChange={(selected: DropdownOption) => {
                    const platform =
                        platformList.find(
                            (platform) =>
                                platform.platformID.toString() ===
                                selected.value
                        ) || null;
                    setSelectedPlatform(platform);
                }}
                value={selectedPlatform}
            >
                Select Platform
            </Dropdown>
            <AccountOnboardingForm
                platformId={selectedPlatform?.platformID}
                mEmails={managerEmails}
                onSubmit={onSubmit}
            />
        </div>
    );
}

export default AddAccount;
