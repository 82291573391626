import produce from 'immer';
import { NOTES_ACTION_TYPES } from '../action-types';
import { NotesAction } from '../actions';
import { NoteDetails } from '../models';

interface NotesState {
    notesData: NoteDetails[];
}

const DEFAULT_NOTES_STATE: NotesState = {
    notesData: [],
};

export const notesReducer = produce(
    (state: NotesState, action: NotesAction): NotesState => {
        switch (action.type) {
            case NOTES_ACTION_TYPES.LOAD_NOTES_DATA:
                state.notesData = action.payload;
                return state;

            default:
                return state;
        }
    },
    DEFAULT_NOTES_STATE
);
