export enum LoginFormActionTypes {
    UPDATE_EMAIL = 'update_email',
    UPDATE_PASSWORD = 'update_password',
    UPDATE_LOADING = 'update_loading',
    UPDATE_FORGOT_PASSWORD = 'update_forgot_password',
    UPDATE_ERROR_MESSAGE = 'update_error_message',
    RESET_FORM = 'reset_form',
}

export interface UpdateEmailAction {
    type: LoginFormActionTypes.UPDATE_EMAIL;
    value: string;
}

export interface UpdatePasswordAction {
    type: LoginFormActionTypes.UPDATE_PASSWORD;
    value: string;
}

export interface UpdateLoadingAction {
    type: LoginFormActionTypes.UPDATE_LOADING;
    value: boolean;
}

export interface UpdateForgotPasswordAction {
    type: LoginFormActionTypes.UPDATE_FORGOT_PASSWORD;
    value: boolean;
}

export interface UpdateErrorMessageAction {
    type: LoginFormActionTypes.UPDATE_ERROR_MESSAGE;
    value: string;
}

export interface ResetFormAction {
    type: LoginFormActionTypes.RESET_FORM;
}

export type LoginFormAction =
    | UpdateEmailAction
    | UpdatePasswordAction
    | UpdateLoadingAction
    | UpdateForgotPasswordAction
    | UpdateErrorMessageAction
    | ResetFormAction;
