import { axios } from '../axios-config';
import { API_UNIVERSAL_SETTINGS } from '../../constants';
import {
    formatAddScheduleBody,
    formatEditScheduleBody,
    saveDownloadFile,
} from '../../utils';
import {
    GETSchedules,
    AllSchedulesData,
    GETRunNowSchedules,
    ScheduleGoalFormState,
    GETAddScheduleResponse,
    ScheduleDetails,
} from '../../state';

// GET USER'S SCHEDULED GOALS LIST DATA
export const getSchedules = async (userId: string) => {
    const url = API_UNIVERSAL_SETTINGS + '/getSchedule';

    try {
        const { data } = await axios.get<GETSchedules>(url, {
            params: {
                userId,
            },
        });

        return data;
    } catch (error: any) {
        throw error;
    }
};

// FETCH USERS RUN NOW SCHEDULED GOALS
export const getRunNowSchedules = async (userId: string) => {
    const url = API_UNIVERSAL_SETTINGS + '/getRunNowSchedule';

    try {
        const { data } = await axios.get<GETRunNowSchedules>(url, {
            params: { userId },
        });

        return data;
    } catch (error: any) {
        throw error;
    }
};

export const getAllSchedules = async (userId: string) => {
    try {
        // GET SCHEDULED GOALS
        const { scheduleList: scheduled } = await getSchedules(userId);

        // GET RUN NOW SCHEDULED GOALS
        const { scheduleList: runNow } = await getRunNowSchedules(userId);

        const data: AllSchedulesData = {
            scheduled: scheduled || [],
            runNow: runNow || [],
        };

        return data;
    } catch (error: any) {
        throw error;
    }
};

export const getDownloadReportInZip = async (
    userId: string,
    scheduleId: number,
    fileName: string
) => {
    const url = API_UNIVERSAL_SETTINGS + '/downloadReportInZip';

    try {
        const blob = await axios.get(url, {
            params: { userId, scheduleId },
            responseType: 'blob',
        });

        saveDownloadFile(blob, fileName);
    } catch (error: any) {
        throw error;
    }
    //   const headers = new HttpHeaders({'Content-Type': 'application/octet-stream' })
};

// ADD SCHEDULED GOAL
export const addSchedule = async (
    userId: string,
    formState: ScheduleGoalFormState
) => {
    const url = API_UNIVERSAL_SETTINGS + '/addSchedule';
    const body = formatAddScheduleBody(userId, formState);

    if (!body) return;

    try {
        const { data } = await axios.get<GETAddScheduleResponse>(url, {
            params: body,
        });

        return data;
    } catch (error: any) {
        throw error;
    }
};

// ADD RUN NOW GOAL
export const addScheduleRunNow = async (
    userId: string,
    formState: ScheduleGoalFormState
) => {
    const url = API_UNIVERSAL_SETTINGS + '/processMultipleGoals';

    const formattedBody = formatAddScheduleBody(userId, formState);

    if (!formattedBody) return;

    const body = new URLSearchParams({ ...formattedBody });

    try {
        const { data } = await axios.post<GETAddScheduleResponse>(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        return data;
    } catch (error: any) {
        throw error;
    }
};

export const editSchedule = async (
    userId: string,
    scheduleId: number,
    formState: ScheduleGoalFormState
) => {
    const url = API_UNIVERSAL_SETTINGS + '/editSchedule';

    const formattedBody = formatEditScheduleBody(userId, scheduleId, formState);

    if (!formattedBody) return;

    const body = new URLSearchParams({ ...formattedBody });

    try {
        const { data } = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        return data;
    } catch (error: any) {
        throw error;
    }
};

export const deleteSchedule = async (userId: string, scheduleId: number) => {
    const url = API_UNIVERSAL_SETTINGS + '/deleteSchedule';

    const body = new URLSearchParams();
    body.append('scheduleId', scheduleId.toString());
    body.append('userId', userId);

    try {
        const { data } = await axios.post(url, body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });

        return data;
    } catch (error: any) {
        throw error;
    }
};
