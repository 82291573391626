import { PLATFORM_IDS } from '../../../constants';
import { DropdownOption, ExistingAccountDetails } from '../../models';

export interface BingAdsFormSubAccount {
    name: string;
    id: string;
    number: string;
    customerId: string;
    status?: boolean;
    existing?: boolean;
}

export interface BingAdsFormState {
    platformId: PLATFORM_IDS.BING_ADS;
    bingAdsManagerEmail: DropdownOption | '';
    agencyName: string;
    accountData: BingAdsFormSubAccount[];
    isEditting: boolean;
}

export const INITIAL_BING_SUB_ACCOUNT: BingAdsFormSubAccount = {
    id: '',
    name: '',
    number: '',
    customerId: '',
};

export const INITIAL_BING_ADS_FORM: BingAdsFormState = {
    platformId: PLATFORM_IDS.BING_ADS,
    bingAdsManagerEmail: '',
    agencyName: '',
    accountData: [INITIAL_BING_SUB_ACCOUNT],
    isEditting: false,
};

export interface BingAdsSubAccountPayload {
    index: number;
    name?: string;
    id?: string;
    number?: string;
    customerId?: string;
    status?: boolean;
    existing?: boolean;
}

export enum BING_ADS_ACTION_TYPES {
    SET_MANAGER_EMAIL = 'set_manager_email',
    SET_AGENCY_NAME = 'set_agency_name',
    ADD_SUB_ACCOUNT = 'add_sub_account',
    UPDATE_SUB_ACCOUNT = 'update_sub_account',
    REMOVE_SUB_ACCOUNT = 'remove_sub_account',
    LOAD_FORM_DATA = 'load_form_data',
    RESET_BING_ADS_FORM = 'reset_bing_ads_form',
}

interface SetMangerEmailAction {
    type: BING_ADS_ACTION_TYPES.SET_MANAGER_EMAIL;
    payload: DropdownOption;
}

interface SetAgencyNameAction {
    type: BING_ADS_ACTION_TYPES.SET_AGENCY_NAME;
    payload: string;
}

interface AddSubAccountAction {
    type: BING_ADS_ACTION_TYPES.ADD_SUB_ACCOUNT;
}

interface UpdateSubAccountAction {
    type: BING_ADS_ACTION_TYPES.UPDATE_SUB_ACCOUNT;
    payload: BingAdsSubAccountPayload;
}

interface RemoveSubAccountAction {
    type: BING_ADS_ACTION_TYPES.REMOVE_SUB_ACCOUNT;
    payload: number;
}

interface LoadFormDataAction {
    type: BING_ADS_ACTION_TYPES.LOAD_FORM_DATA;
    payload: ExistingAccountDetails;
}

interface ResetBingAdsFormAction {
    type: BING_ADS_ACTION_TYPES.RESET_BING_ADS_FORM;
}

export type BingAdsFormAction =
    | SetMangerEmailAction
    | SetAgencyNameAction
    | AddSubAccountAction
    | UpdateSubAccountAction
    | RemoveSubAccountAction
    | LoadFormDataAction
    | ResetBingAdsFormAction;
