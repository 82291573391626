import './Scenario-Planner.scss';

import { useAppSelector } from '../../../hooks';

// UI COMPONENTS
import { Row, Column } from '@publicismedia-ds/ui-grid';
import Product from '../../../components/Product/Product';
import ScenarioPlannerContent from './Scenario-Planner-Content';
import BulkActions from '../../../components/Bulk-Actions/Bulk-Actions';

// FUNCTIONAL COMPONENT
function ScenarioPlanner() {
    // REDUX STATE
    const { data, isAdhoc } = useAppSelector(
        ({ products }) => products.scenarioPlanner
    );

    return (
        <>
            <Product>
                <Row cols={3}>
                    <Column from={3} to={4}>
                        <BulkActions />
                    </Column>
                </Row>
                <Row>
                    {data ? (
                        <>
                            <ScenarioPlannerContent
                                data={data}
                                isAdhoc={isAdhoc}
                            />
                        </>
                    ) : (
                        <h2>No Data Available</h2>
                    )}
                </Row>
            </Product>
        </>
    );
}

export default ScenarioPlanner;
