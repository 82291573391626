import {
    UserRoleId,
    AccountBrand,
    DropdownOption,
    GETRolesAccountBrands,
    UserDetails,
} from '../../models';

export interface UserFormState {
    email: string;
    lionEmail: string; // optional
    firstName: string;
    lastName: string;
    role: DropdownOption | '';
    roleOptions: DropdownOption[];
    allAdvertiserOptions: { [key: string]: DropdownOption[] }; // key === advertiser ID
    accounts: DropdownOption[];
    accountOptions: DropdownOption[];
    advertisers: DropdownOption[];
    advertiserOptions: DropdownOption[];
    features: DropdownOption[];
    featureOptions: DropdownOption[];
    accessData: GETRolesAccountBrands | null;
    // THE FOLLOWING ARE INCLUDED ONLY WHEN EDITTING AN EXISTING USER
    newPassword?: string;
    confirmPassword?: string;
    action?: number;
}

export const INITIAL_USER_FORM_STATE: UserFormState = {
    email: '',
    lionEmail: '',
    firstName: '',
    lastName: '',
    role: '',
    roleOptions: [],
    allAdvertiserOptions: {},
    accounts: [],
    accountOptions: [],
    advertisers: [],
    advertiserOptions: [],
    features: [],
    featureOptions: [],
    accessData: null,
};

export enum USER_FORM_ACTION_TYPES {
    SET_ACCESS_DATA = 'set_access_data',
    SET_EMAIL = 'set_email',
    SET_LION_EMAIL = 'set_lion_email',
    SET_FIRST_NAME = 'set_first_name',
    SET_LAST_NAME = 'set_last_name',
    SET_ROLE = 'set_role',
    ADD_ACCOUNT = 'add_account',
    REMOVE_ACCOUNT = 'remove_account',
    ADD_ADVERTISER = 'add_advertiser',
    REMOVE_ADVERTISER = 'remove_advertiser',
    ADD_FEATURE = 'add_feature',
    REMOVE_FEATURE = 'remove_feature',
    SET_NEW_PASSWORD = 'set_new_password',
    SET_CONFIRM_PASSWORD = 'set_confirm_password',
    LOAD_EXISTING_USER = 'load_existing_user',
    RESET_USER_FORM = 'reset_user_form',
    UPDATE_ACTION_VALUE = 'update_action_value',
}

interface SetAccessDataAction {
    type: USER_FORM_ACTION_TYPES.SET_ACCESS_DATA;
    payload: GETRolesAccountBrands;
}

interface SetEmailAction {
    type: USER_FORM_ACTION_TYPES.SET_EMAIL;
    payload: string;
}

interface SetLionEmailAction {
    type: USER_FORM_ACTION_TYPES.SET_LION_EMAIL;
    payload: string;
}

interface SetFirstNameAction {
    type: USER_FORM_ACTION_TYPES.SET_FIRST_NAME;
    payload: string;
}

interface SetLastNameAction {
    type: USER_FORM_ACTION_TYPES.SET_LAST_NAME;
    payload: string;
}

interface SetRoleAction {
    type: USER_FORM_ACTION_TYPES.SET_ROLE;
    payload: DropdownOption;
}

interface AddAccoutAction {
    type: USER_FORM_ACTION_TYPES.ADD_ACCOUNT;
    payload: DropdownOption;
}

interface RemoveAccountAction {
    type: USER_FORM_ACTION_TYPES.REMOVE_ACCOUNT;
    payload: DropdownOption;
}

interface AddAdvertiserAction {
    type: USER_FORM_ACTION_TYPES.ADD_ADVERTISER;
    payload: DropdownOption;
}

interface RemoveAdvertiserAction {
    type: USER_FORM_ACTION_TYPES.REMOVE_ADVERTISER;
    payload: DropdownOption;
}

interface AddFeatureAction {
    type: USER_FORM_ACTION_TYPES.ADD_FEATURE;
    payload: DropdownOption;
}

interface RemoveFeatureAction {
    type: USER_FORM_ACTION_TYPES.REMOVE_FEATURE;
    payload: DropdownOption;
}

interface SetNewPasswordAction {
    type: USER_FORM_ACTION_TYPES.SET_NEW_PASSWORD;
    payload: string;
}

interface SetConfirmPasswordAction {
    type: USER_FORM_ACTION_TYPES.SET_CONFIRM_PASSWORD;
    payload: string;
}

interface LoadExistingUserAction {
    type: USER_FORM_ACTION_TYPES.LOAD_EXISTING_USER;
    payload: UserDetails;
}

interface UpdateActionValueAction {
    type: USER_FORM_ACTION_TYPES.UPDATE_ACTION_VALUE;
    payload: number;
}

interface ResetUserFormAction {
    type: USER_FORM_ACTION_TYPES.RESET_USER_FORM;
}

export type UserFormAction =
    | SetAccessDataAction
    | SetEmailAction
    | SetLionEmailAction
    | SetFirstNameAction
    | SetLastNameAction
    | SetRoleAction
    | AddAccoutAction
    | RemoveAccountAction
    | AddAdvertiserAction
    | RemoveAdvertiserAction
    | AddFeatureAction
    | RemoveFeatureAction
    | SetNewPasswordAction
    | SetConfirmPasswordAction
    | LoadExistingUserAction
    | UpdateActionValueAction
    | ResetUserFormAction;
