import { AlertActionType } from '../action-types';
import {
    AlertErrorAction,
    AlertSuccessAction,
    ClearAlertsAction,
} from '../actions/alert-actions';

export const alertSuccess = (message: string): AlertSuccessAction => {
    return {
        type: AlertActionType.ALERT_SUCCESS,
        payload: {
            message,
        },
    };
};

export const alertError = (message: string): AlertErrorAction => {
    return {
        type: AlertActionType.ALERT_ERROR,
        payload: {
            message,
        },
    };
};

export const clearAlerts = (): ClearAlertsAction => {
    return {
        type: AlertActionType.CLEAR_ALERTS,
        payload: {},
    };
};
