import { useActions } from '../../hooks';
import { STATUS_OPTIONS } from '../../constants';
import { CustomColumnFormData, DropdownOption } from '../../state';

// UI COMPONENTS
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { memo } from 'react';

// COMPONENT PROPS
interface CustomColumnProps {
    index: number;
    data: CustomColumnFormData;
    type: 'conversion' | 'revenue';
}

// FUNCTIONAL COMPONENT
function CustomColumn({ data, index, type }: CustomColumnProps) {
    // REDUX ACTIONS
    const {
        updateCustomColumn,
        removeCustomColumn,
        updateCustomRevenue,
        removeCustomRevenue,
    } = useActions();

    // HANDLE UPDATING COLUMN NAME
    const onUpdateColumnName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const name = evt.target.value;
        if (type === 'conversion') {
            updateCustomColumn({ index, name });
        }
        if (type === 'revenue') {
            updateCustomRevenue({ index, name });
        }
    };

    // HANDLE UPDATING COLUMN STATUS
    const onUpdateColumnStatus = (selected: DropdownOption) => {
        const status = selected.value === 'true';

        if (type === 'conversion') {
            updateCustomColumn({ index, status });
        }

        if (type === 'revenue') {
            updateCustomRevenue({ index, status });
        }
    };

    // HANDLE REMOVE COLUMN DATA
    const onRemoveColumn = () => {
        if (type === 'conversion') {
            removeCustomColumn(index);
        }

        if (type === 'revenue') {
            removeCustomRevenue(index);
        }
    };

    return (
        <div className="inputs-row">
            {/* UPDATE CUSTOM COLUMN NAME */}
            <Textbox
                value={data.name}
                onChange={onUpdateColumnName}
                disabled={data.existing}
            >
                Conversion Column Name
            </Textbox>

            {/* ENABLE/DISABLE CUSTOM COLUMN SUPPORT */}
            <Dropdown
                options={STATUS_OPTIONS}
                value={{
                    label: '',
                    value: data.status.toString(),
                }}
                onChange={onUpdateColumnStatus}
            >
                Status
            </Dropdown>
            {/* ICON TO REMOVE CONVERSION COLUMN DATA (NON-EXISTING COLUMNS ONLY) */}
            {!data.existing && (
                <i className="icon-close" onClick={onRemoveColumn} />
            )}
        </div>
    );
}

export default memo(CustomColumn);
