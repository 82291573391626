import { PLATFORM_IDS } from '../../../constants';
import { DropdownOption, ExistingAccountDetails } from '../../models';

export interface GoogleAdsFormState {
    platformId: PLATFORM_IDS.GOOGLE_ADS;
    adwordsMangerEmail: DropdownOption | '';
    customerName: string;
    customerId: string;
    isManagerAccount: '1' | '0';
    customerData: GoogleAdsFormSubAccount[];
    isEditing: boolean;
}

export interface GoogleAdsFormSubAccount {
    id: string;
    name: string;
    status?: boolean;
    existing?: boolean;
}

export enum GOOGLE_ADS_ACTION_TYPES {
    SET_MANAGER_EMAIL = 'set_manager_email',
    SET_CUSTOMER_NAME = 'set_customer_name',
    SET_CUSTOMER_ID = 'set_customer_id',
    SET_IS_MANGER_ACCOUNT = 'set_is_manager_account',
    ADD_SUB_CUSTOMER = 'add_sub_customer',
    UPDATE_SUB_CUSTOMER = 'update_sub_customer',
    REMOVE_SUB_CUSTOMER = 'remove_sub_customer',
    LOAD_FORM_DATA = 'load_form_data',
    RESET_GOOGLE_ADS_FORM = 'reset_google_ads_form',
}

export const INITIAL_SUB_CUSTOMER_DATA: GoogleAdsFormSubAccount = {
    id: '',
    name: '',
};

export interface UpdateSubCustomerPayload {
    index: number;
    id?: string;
    name?: string;
    status?: boolean;
    existing?: boolean;
}

export const INITIAL_GOOGLE_ADS_FORM: GoogleAdsFormState = {
    platformId: PLATFORM_IDS.GOOGLE_ADS,
    adwordsMangerEmail: '',
    customerId: '',
    customerName: '',
    isManagerAccount: '0',
    customerData: [{ ...INITIAL_SUB_CUSTOMER_DATA }],
    isEditing: false,
};

interface SetManagerEmailAction {
    type: GOOGLE_ADS_ACTION_TYPES.SET_MANAGER_EMAIL;
    payload: DropdownOption;
}

interface SetCustomerIdAction {
    type: GOOGLE_ADS_ACTION_TYPES.SET_CUSTOMER_ID;
    payload: string;
}

interface SetCustomerNameAction {
    type: GOOGLE_ADS_ACTION_TYPES.SET_CUSTOMER_NAME;
    payload: string;
}

interface SetIsManagerAccountAction {
    type: GOOGLE_ADS_ACTION_TYPES.SET_IS_MANGER_ACCOUNT;
    payload: '1' | '0';
}

interface AddSubCustomerAction {
    type: GOOGLE_ADS_ACTION_TYPES.ADD_SUB_CUSTOMER;
}

interface UpdateSubCustomerAction {
    type: GOOGLE_ADS_ACTION_TYPES.UPDATE_SUB_CUSTOMER;
    payload: UpdateSubCustomerPayload;
}

interface RemoveSubCustomerAction {
    type: GOOGLE_ADS_ACTION_TYPES.REMOVE_SUB_CUSTOMER;
    payload: number;
}

interface LoadFormDataAction {
    type: GOOGLE_ADS_ACTION_TYPES.LOAD_FORM_DATA;
    payload: ExistingAccountDetails;
}

interface ResetFormAction {
    type: GOOGLE_ADS_ACTION_TYPES.RESET_GOOGLE_ADS_FORM;
}

export type GoogleAdsFormAction =
    | SetManagerEmailAction
    | SetCustomerIdAction
    | SetCustomerNameAction
    | SetIsManagerAccountAction
    | AddSubCustomerAction
    | UpdateSubCustomerAction
    | RemoveSubCustomerAction
    | LoadFormDataAction
    | ResetFormAction;
