import produce from 'immer';
import { GoalsListType } from '../action-types/goals-types';
import { GoalsListAction } from '../actions/goals-actions';
import {
    GoalSettings,
    GoalsListItem,
} from '../models/response-models/goal-responses';

interface GoalsListState {
    loading: boolean;
    list: GoalsListItem[];
    goalSettings: GoalSettings | null;
}

const initialState: GoalsListState = {
    loading: false,
    list: [],
    goalSettings: null,
};

const reducer = produce(
    (
        state: GoalsListState = initialState,
        action: GoalsListAction
    ): GoalsListState => {
        switch (action.type) {
            case GoalsListType.SET_GOALS_LIST:
                state.list = action.payload.goalsList;
                state.loading = false;
                return state;

            case GoalsListType.CLEAR_GOALS_LIST:
                state.list = [];
                return state;

            case GoalsListType.SET_GOALS_LOADING:
                state.loading = action.payload.isLoading;
                return state;

            case GoalsListType.SET_GOAL_SETTINGS:
                state.goalSettings = action.payload.goalSettings;
                return state;

            default:
                return state;
        }
    },
    initialState
);

export default reducer;
