import './Product.scss';

interface ProductProps {
    header?: string | React.ReactNode;
    children: React.ReactNode;
}

function Product({ header, children }: ProductProps) {
    return (
        <div className="selected-product-container">
            {!!header && (
                <div className="selected-product-header">
                    {typeof header === 'string' ? (
                        <h1>{header}</h1>
                    ) : (
                        <>{header}</>
                    )}
                </div>
            )}
            <div className="selected-product-content">
                {children ? (
                    children
                ) : (
                    <div className="no-product-data">
                        <h2>No Data Available.</h2>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Product;
