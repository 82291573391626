import produce from 'immer';
import { UniversalSettingsState } from '../models';
import { UniversalSettingsAction } from '../actions';
import { UNIVERSAL_SETTINGS_ACTION_TYPES } from '../action-types';

const initialState: UniversalSettingsState = {
    userId: '',
    goalId: null,
    adjustHighDbFlag: false,
    budget: 0,
    calculationMethod: '',
    conversionColumn: '',
    conversionType: null,
    conversionGoal: 0,
    customStartDate: '',
    customEndDate: '',
    darwinLastNDays: 0, // 0
    darwinLastNDaysLR: 0, // 14
    dpadLastNWeeks: 0, // 2
    efficiencyDenominator: '',
    efficiencyNumerator: '',
    efficiencyTarget: 0,
    excludeFlightDates: '',
    goals: null,
    flightStart: null,
    flightEnd: null,
    maxBid: '',
    maxBidHeadRoom: 0,
    maxDailyBudget: 0,
    minBid: '',
    minBidHeadRoom: 0,
    minDailyBudget: 0,
    optimizationType: '',
    optimizationStrategy: '',
    multipleTimePeriod: false,
    optInOutIntradayAlerts: false,
    revenueColumn: '',
    useWT: false,
    optimizingBudgetCapsForEfficiencyFlag: false,
    phrasePercentBidFactor: '',
    broadPercentBidFactor: '',
};

const maxCustomDate = new Date();
maxCustomDate.setDate(maxCustomDate.getDate() - 1);

const minCustomDate = new Date();
minCustomDate.setDate(minCustomDate.getDate() - 180);

const universalSettingsReducer = produce(
    (
        state: UniversalSettingsState = initialState,
        action: UniversalSettingsAction
    ): UniversalSettingsState => {
        switch (action.type) {
            case UNIVERSAL_SETTINGS_ACTION_TYPES.LOAD_SETTINGS: {
                const { customStartDate, customEndDate } = action.payload.value;

                state = action.payload.value;

                // ADJUST CUSTOM START DATE TO BE WITHIN ACCEPTABLE DATE RANGE
                if (new Date(customStartDate) < minCustomDate) {
                    state.customStartDate = minCustomDate
                        .toDateString()
                        .slice(4);
                }

                // ADUST CUSTOM END DATE TO BE WITHIN ACCEPTABLE DATE RANGE
                if (new Date(customEndDate) > maxCustomDate) {
                    state.customEndDate = maxCustomDate.toDateString().slice(4);
                }

                return state;
            }

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_BUDGET:
                state.budget = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_FLIGHT_START:
                state.flightStart = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_FLIGHT_END:
                state.flightEnd = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_CONVERSION_TYPE:
                state.conversionType = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_CONVERSION_COLUMN:
                state.conversionColumn = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_OPTIMIZATION_TYPE:
                state.optimizationType = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_CUSTOM_START_DATE: {
                const { value } = action.payload;
                let actualValue = value;

                // SET TO ZERO SO API USES CUSTOM DATES INSTEAD OF THIS
                if (state.darwinLastNDays !== 0) {
                    state.darwinLastNDays = 0;
                }

                // SET ACTUAL_VALUE TO MIN DATE IF VALUE IS LESS THAN MIN DATE
                if (new Date(value) < minCustomDate) {
                    actualValue = minCustomDate.toDateString().slice(4);
                }

                // SET ACTUAL_VALUE TO MAX DATE IF VALUE IS GREATER THAN MAX DATE
                if (new Date(value) > maxCustomDate) {
                    actualValue = maxCustomDate.toDateString().slice(4);
                }

                // ADUST END DATE IF START DATE OCCURS AFTER CURRENT END DATE VALUE
                if (
                    state.customEndDate &&
                    new Date(value) > new Date(state.customEndDate)
                ) {
                    state.customEndDate = value;
                }

                state.customStartDate = actualValue;
                return state;
            }

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_CUSTOM_END_DATE: {
                const { value } = action.payload;
                let actualValue = value;

                // SET TO ZERO SO API USES CUSTOM DATES INSTEAD OF THIS
                if (state.darwinLastNDays !== 0) {
                    state.darwinLastNDays = 0;
                }

                // SET ACTUAL_VALUE TO MIN DATE IF VALUE IS LESS THAN MIN DATE
                if (new Date(value) < minCustomDate) {
                    actualValue = minCustomDate.toDateString().slice(4);
                }

                // SET ACTUAL_VALUE TO MAX DATE IF VALUE IS GREATER THAN MAX DATE
                if (new Date(value) > maxCustomDate) {
                    actualValue = maxCustomDate.toDateString().slice(4);
                }

                // ADJUST START DATE IF END DATE OCCURS PRIOR TO CURRENT START DATE VALUE
                if (
                    state.customStartDate &&
                    new Date(actualValue) < new Date(state.customStartDate)
                ) {
                    state.customStartDate = actualValue;
                }

                state.customEndDate = actualValue;
                return state;
            }

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_EFFICIENCY_NUMERATOR:
                state.efficiencyNumerator = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_EFFICIENCY_DENOMINATOR:
                state.efficiencyDenominator = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_EFFICIENCY_TARGET:
                state.efficiencyTarget = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_REVENUE_COLUMN:
                state.revenueColumn = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_LAST_N_DAYS:
                state.darwinLastNDays = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_LAST_N_DAYS_LR:
                state.darwinLastNDaysLR = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_LAST_N_WEEKS:
                state.dpadLastNWeeks = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_EXCLUDE_FLIGHT_DATES:
                state.excludeFlightDates = action.payload
                    .map(({ value }) => {
                        return value;
                    })
                    .join(',');
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_MULTIPLE_TIME_PERIOD:
                state.multipleTimePeriod = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_CALCULATION_METHOD:
                state.calculationMethod = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_OPTIMIZATION_STRATEGY:
                state.optimizationStrategy = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_MINIMUM_BID:
                state.minBid = action.payload.value;
                return state;
            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_MAXIMUM_BID:
                state.maxBid = action.payload.value;
                return state;
            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_MINIMUM_BID_HEADROOM:
                state.minBidHeadRoom = action.payload.value;
                return state;
            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_MAXIMUM_BID_HEADROOM:
                state.maxBidHeadRoom = action.payload.value;
                return state;
            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_MINIMUM_DAILY_BUDGET:
                state.minDailyBudget = action.payload.value;
                return state;
            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_MAXIMUM_DAILY_BUDGET:
                state.maxDailyBudget = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_USE_WEIGHTED_FLAG:
                state.useWT = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_ADJUST_HIGH_DB_FLAG:
                state.adjustHighDbFlag = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_OPTIMIZE_BUDGET_CAPS:
                state.optimizingBudgetCapsForEfficiencyFlag =
                    action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_PHRASE_PERCENT_FACTOR:
                state.phrasePercentBidFactor = action.payload.value;
                return state;

            case UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_BROAD_PERCENT_FACTOR:
                state.broadPercentBidFactor = action.payload.value;
                return state;

            default:
                return state;
        }
    },
    initialState
);

export default universalSettingsReducer;
