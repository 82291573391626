import { DropdownOption, ScheduleDetails, GoalsListItem } from '../../';

export enum SCHEDULE_GOAL_TYPES {
    SET_SCHEDULE_NAME = 'set_name',
    SET_SELECTED_GOALS = 'set_selected_goals',
    SET_SELECTED_MODULES = 'set_selected_modules',
    SET_EXECUTION_WAY = 'set_execution_way',
    SET_FREQUENCY = 'set_frequency',
    SET_DAYS_TO_RUN = 'set_days_to_run',
    SET_TIME_OF_DAY = 'set_time_of_day',
    ADD_EMAIL = 'add_email',
    REMOVE_EMAIL = 'remove_email',
    RESET_FORM = 'reset_form',
    LOAD_SCHEDULE_DATA = 'load_schedule_data',
}

const SCHEDULE_MODULES = [
    'Performance Monitor',
    'Yield Optimizer',
    'Scenario Planner',
    'Performance Diagnostic',
] as const;
export const SCHEDULE_MODULES_OPTIONS = SCHEDULE_MODULES.map((module) => ({
    label: module,
    value: module,
}));
export type ScheduleModulesOption = typeof SCHEDULE_MODULES_OPTIONS[number];
export type ScheduleModule = typeof SCHEDULE_MODULES[number];

// SCHEDULE EXECTION OPTIONS
export const EXECUTION_WAYS = ['schedule', 'runNow'] as const;
export const EXECUTION_WAYS_OPTIONS = [
    { label: 'Schedule', value: EXECUTION_WAYS[0] },
    { label: 'Run Now', value: EXECUTION_WAYS[1] },
] as const;
export type ExecutionWay = typeof EXECUTION_WAYS[number];

// SCHEDULE FREQUENCY OPTIONS
export const SCHEDULE_FREQUENCYS = ['daily', 'weekly', 'monthly'] as const;
export const SCHEDULE_FREQUENCY_OPTIONS = SCHEDULE_FREQUENCYS.map(
    (frequency) => ({
        label: frequency[0].toUpperCase() + frequency.slice(1),
        value: frequency,
    })
);
export type ScheduleFrequency = typeof SCHEDULE_FREQUENCYS[number];

// SCHEDULE TIME OF DAY OPTIONS
export const SCHEDULE_TIME_OF_DAY = [
    '5am',
    '6am',
    '7am',
    '8am',
    '9am',
    '10am',
    '11am',
    '12pm',
    '1pm',
    '2pm',
    '3pm',
    '4pm',
    '5pm',
    '6pm',
    '7pm',
    '8pm',
    '9pm',
    '10pm',
    '11pm',
];
export type ScheduleTimeOfDay = typeof SCHEDULE_TIME_OF_DAY[number];

// WEEKLY EXECTION DAYS TO RUN OPTIONS
export const DAYS_TO_RUN = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
] as const;
export const DAYS_TO_RUN_OPTIONS = DAYS_TO_RUN.map((day) => ({
    label: day[0].toUpperCase() + day.slice(1),
    value: day,
}));
export type DaysToRun = typeof DAYS_TO_RUN[number];

// DATE TO RUN EXECUTION OPTIONS
export const DATE_TO_RUN_OPTIONS = [
    { label: '1st', value: '1' },
    { label: '2nd', value: '2' },
    { label: '3rd', value: '3' },
    { label: '4th', value: '4' },
    { label: '5th', value: '5' },
    { label: '6th', value: '6' },
    { label: '7th', value: '7' },
    { label: '8th', value: '8' },
    { label: '9th', value: '9' },
    { label: '10th', value: '10' },
    { label: '11th', value: '11' },
    { label: '12th', value: '12' },
    { label: '13th', value: '13' },
    { label: '14th', value: '14' },
    { label: '15th', value: '15' },
    { label: '16th', value: '16' },
    { label: '17th', value: '17' },
    { label: '18th', value: '18' },
    { label: '19th', value: '19' },
    { label: '20th', value: '20' },
    { label: '21st', value: '21' },
    { label: '22nd', value: '22' },
    { label: '23rd', value: '23' },
    { label: '24th', value: '24' },
    { label: '25th', value: '25' },
    { label: '26th', value: '26' },
    { label: '27th', value: '27' },
    { label: '28th', value: '28' },
    { label: '29th', value: '29' },
    { label: '30th', value: '30' },
    { label: '31st or last day of month', value: '31' },
];
// SCHEDULED GOAL FORM STATE TYPE
export interface ScheduleGoalFormState {
    scheduleName: string;
    selectedGoals: DropdownOption[];
    selectedModules: DropdownOption[];
    executionWay: DropdownOption | null;
    daysToRun: DropdownOption[];
    frequency: DropdownOption | null;
    timeOfDay: DropdownOption | null;
    emails: string[];
}

// SCHEDULED GOAL FORM'S INITIAL STATE
export const INITIAL_SCHEDULE_GOAL_FORM: ScheduleGoalFormState = {
    scheduleName: '',
    selectedGoals: [],
    selectedModules: [],
    executionWay: null,
    daysToRun: [],
    frequency: null,
    timeOfDay: null,
    emails: [],
};

// SCHEDULED GOAL FORM STATE ACTIONS
interface SetScheduleNameAction {
    type: SCHEDULE_GOAL_TYPES.SET_SCHEDULE_NAME;
    payload: string;
}

interface SetSelectedGoalsAction {
    type: SCHEDULE_GOAL_TYPES.SET_SELECTED_GOALS;
    payload: DropdownOption[];
}

interface SetSelectedModulesAction {
    type: SCHEDULE_GOAL_TYPES.SET_SELECTED_MODULES;
    payload: DropdownOption[];
}

interface SetExecutionWayAction {
    type: SCHEDULE_GOAL_TYPES.SET_EXECUTION_WAY;
    payload: DropdownOption | null;
}

interface SetFrequencyAction {
    type: SCHEDULE_GOAL_TYPES.SET_FREQUENCY;
    payload: DropdownOption | null;
}

interface SetDaysToRunAction {
    type: SCHEDULE_GOAL_TYPES.SET_DAYS_TO_RUN;
    payload: DropdownOption[];
}

interface SetTimeOfDayAction {
    type: SCHEDULE_GOAL_TYPES.SET_TIME_OF_DAY;
    payload: DropdownOption | null;
}

interface SetAddEmailAction {
    type: SCHEDULE_GOAL_TYPES.ADD_EMAIL;
    payload: string;
}

interface SetRemoveEmailAction {
    type: SCHEDULE_GOAL_TYPES.REMOVE_EMAIL;
    payload: string;
}

interface ResetFormAction {
    type: SCHEDULE_GOAL_TYPES.RESET_FORM;
}

interface LoadScheduleDataAction {
    type: SCHEDULE_GOAL_TYPES.LOAD_SCHEDULE_DATA;
    payload: {
        schedule: ScheduleDetails;
        goals: GoalsListItem[];
    };
}

export type ScheduleGoalFormAction =
    | SetScheduleNameAction
    | SetSelectedGoalsAction
    | SetSelectedModulesAction
    | SetExecutionWayAction
    | SetFrequencyAction
    | SetDaysToRunAction
    | SetTimeOfDayAction
    | SetAddEmailAction
    | SetRemoveEmailAction
    | ResetFormAction
    | LoadScheduleDataAction;
