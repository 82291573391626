import {
    NoteData,
    AgencyItem,
    DropdownOption,
    GETAccountData,
} from '../../models';

export interface AddNoteFormState {
    agencies: AgencyItem[];
    agencyOptions: DropdownOption[];
    advertiserOptions: DropdownOption[];
    selectedAgency: string;
    selectedAdvertiser: string;
    notes: string;
    notesHistory: NoteData[];
    createdByUserId: string;
    updatedByUserId: string;
    isUpdated: '0' | '1';
}

export enum ADD_NOTE_ACTION_TYPE {
    LOAD_AGENCIES_DATA = 'load_agencies_data',
    SET_SELECTED_AGENCY = 'set_selected_agency',
    SET_SELECTED_ADVERTISER = 'set_selected_advertiser',
    SET_NOTE = 'set_note',
    RESET_FORM = 'reset_form',
}

interface LoadAgenciesDataAction {
    type: ADD_NOTE_ACTION_TYPE.LOAD_AGENCIES_DATA;
    payload: AgencyItem[];
}

interface SetSelectedAgencyAction {
    type: ADD_NOTE_ACTION_TYPE.SET_SELECTED_AGENCY;
    payload: GETAccountData;
}

interface SetSelectedAdvertiserAction {
    type: ADD_NOTE_ACTION_TYPE.SET_SELECTED_ADVERTISER;
    payload: DropdownOption;
}

interface SetNoteAction {
    type: ADD_NOTE_ACTION_TYPE.SET_NOTE;
    payload: string;
}

interface ResetFormAction {
    type: ADD_NOTE_ACTION_TYPE.RESET_FORM;
}

export type AddNoteFormAction =
    | LoadAgenciesDataAction
    | SetSelectedAgencyAction
    | SetSelectedAdvertiserAction
    | SetNoteAction
    | ResetFormAction;
