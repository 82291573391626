import { useEffect, useMemo, useState } from 'react';
import { useActions, useAppSelector } from '../../hooks';

// TYPES
import {
    UserDetails,
    GoalsListItem,
    CustomAlertItem,
    CustomAlertTableRow,
} from '../../state';

// UI COMPONENTS
import DataTable, {
    TD_ALIGN,
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import Tooltip from '@publicismedia-ds/ui-tooltip';
import Status, { STATUS_TYPE } from '@publicismedia-ds/ui-status';

// COMPONENT PROPS
interface CustomAlertsTableProps {
    data: CustomAlertItem[];
    users: UserDetails[];
    goalList: GoalsListItem[];
    onView?: (alertId: number) => void;
    onEdit?: (alertId: number) => void;
    onDelete?: (alertId: number) => void;
}

// FUNCTIONAL COMPONENT (CUSTOM ALERTS TABLE)
function CustomAlertsTable({
    data,
    users,
    goalList,
    onEdit,
    onView,
    onDelete,
}: CustomAlertsTableProps) {
    // TABLE DATA PROVIDER
    const [provider, setProvider] = useState<any>();

    // REDUX STATE
    const userId = useAppSelector(({ auth }) => auth.user?.id);

    // GET NAMES FOR GOALS, AGENCIES, ADVERTISERS, AND USERS BY IDs
    const names = useMemo(() => {
        const goals: { [key: string]: string } = {};
        const agencies: { [key: string]: string } = {};
        const advertisers: { [key: string]: string } = {};
        const userNames: { [key: string]: string } = {};

        for (const goal of goalList) {
            if (!agencies[goal.agencyName]) {
                agencies[goal.agencyId] = goal.agencyName;
            }

            if (!goals[goal.goalId]) {
                goals[goal.goalId] = goal.goalMappingName;
            }

            if (!advertisers[goal.advertiserId]) {
                advertisers[goal.advertiserId] = goal.advertiserName;
            }
        }

        for (const user of users) {
            if (!userNames[user.id]) {
                userNames[user.id] = user.name;
            }
        }

        return { goals, userNames, agencies, advertisers };
    }, [users, goalList]);

    // SET TABLE DATA PROVIDER ON INITIAL RENDER AND DATA CHANGES
    useEffect(() => {
        const formattedData = data.map(
            (alertData): CustomAlertTableRow => ({
                ...alertData,
                agencyName: names.agencies[alertData.agencyId] || '',
                advertiserName: names.advertisers[alertData.advertiserId] || '',
                createdByName: names.userNames[alertData.createdBy] || '',
            })
        );
        setProvider(new DataProvider({ data: formattedData }));
    }, [data, names]);

    // JSX
    return (
        <div className="custom-alerts-table-container">
            <div className="custom-alerts-table">
                <DataTable data={provider} bindKey="alertId">
                    {/* --- ALERT ID --- */}
                    <ColumnTemplate
                        bind="alertId"
                        align={TD_ALIGN.CENTER}
                        minWidth
                    >
                        Alert ID
                    </ColumnTemplate>

                    {/* --- AGENCY --- */}
                    <ColumnTemplate bind="agencyName" minWidth>
                        Agency
                    </ColumnTemplate>

                    {/* --- ADVERTISER --- */}
                    <ColumnTemplate bind="advertiserName" minWidth>
                        Advertiser
                    </ColumnTemplate>

                    {/* --- ALERT TYPE --- */}
                    <ColumnTemplate
                        bind="alertType"
                        onRender={(alertType: 2 | 3) => (
                            <span>
                                {alertType === 2 ? 'Pacing' : 'Performance'}
                            </span>
                        )}
                        minWidth
                    >
                        Type
                    </ColumnTemplate>

                    {/* --- CREATED BY --- */}
                    <ColumnTemplate bind="createdByName" minWidth>
                        Created By
                    </ColumnTemplate>

                    {/* --- ACTIONS --- */}
                    <ColumnTemplate
                        bind="alertId"
                        type="actions"
                        align={TD_ALIGN.CENTER}
                        onRender={(
                            alertId: number,
                            rowData: CustomAlertTableRow
                        ) => {
                            return (
                                <>
                                    {/* --- VIEW ALERT --- */}
                                    {!!onView && (
                                        <Tooltip title="View">
                                            <button
                                                onClick={() => onView(alertId)}
                                            >
                                                <i className="icon-view" />
                                            </button>
                                        </Tooltip>
                                    )}

                                    {/* --- ONLY CREATOR AND UPDATE/EDIT --- */}
                                    {userId &&
                                        userId ===
                                            rowData.createdBy.toString() && (
                                            <>
                                                {/* --- EDIT ALERT --- */}
                                                {!!onEdit && (
                                                    <Tooltip title="Edit">
                                                        <button
                                                            onClick={() =>
                                                                onEdit(alertId)
                                                            }
                                                        >
                                                            <i className="icon-edit" />
                                                        </button>
                                                    </Tooltip>
                                                )}

                                                {/* --- DELETE ALERT --- */}
                                                {!!onDelete && (
                                                    <Tooltip title="Delete">
                                                        <button
                                                            onClick={() =>
                                                                onDelete(
                                                                    alertId
                                                                )
                                                            }
                                                        >
                                                            <i className="icon-delete" />
                                                        </button>
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                </>
                            );
                        }}
                        minWidth
                    >
                        Actions
                    </ColumnTemplate>
                </DataTable>
            </div>
        </div>
    );
}

export default CustomAlertsTable;
