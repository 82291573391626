// TYPES
import { FormattedPerformanceData } from '../../../state';

// UI COMPONENTS
import DataTable, { ColumnTemplate } from '@publicismedia-ds/ui-datatable';

// COMPONENT PROPS
interface KeywordChartProps {
    data: FormattedPerformanceData[];
    priorDate: string;
    recentDate: string;
}

// FUNCTIONAL COMPONENT (KEYWORDS CHART)
function KeywordChart({ data, priorDate, recentDate }: KeywordChartProps) {
    return (
        <>
            <DataTable
                data={data}
                bindKey="keywordId"
                stickyHead={true}
                emptyState={
                    <>
                        <h2>No data available</h2>
                    </>
                }
            >
                <ColumnTemplate
                    bind="keywordName"
                    type="number"
                    align="left"
                    minWidth
                >
                    Keyword
                </ColumnTemplate>
                <ColumnTemplate
                    bind="prior"
                    align="center"
                    type="number"
                    minWidth
                >
                    {priorDate}
                </ColumnTemplate>
                <ColumnTemplate
                    bind="recent"
                    align="center"
                    type="number"
                    minWidth
                >
                    {recentDate}
                </ColumnTemplate>
                <ColumnTemplate
                    bind="difference"
                    align="center"
                    type="number"
                    minWidth
                >
                    % Difference
                </ColumnTemplate>
            </DataTable>
        </>
    );
}
export default KeywordChart;
