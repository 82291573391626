import authReducer from './auth-reducer';
import alertsReducer from './alert-reducer';
import goalsListReducer from './goals-reducer';
import { notesReducer } from './notes-reducer';
import productsReducer from './products-reducer';
import { combineReducers } from '@reduxjs/toolkit';
import { adhocModalReducer } from './adhoc-modal-reducer';
import { editAccountReducer } from './edit-account-reducer';
import universalSettingsReducer from './universal-settings-reducer';

const reducer = combineReducers({
  auth: authReducer,
  notes: notesReducer,
  alerts: alertsReducer,
  goals: goalsListReducer,
  products: productsReducer,
  adhocModal: adhocModalReducer,
  editAccountForm: editAccountReducer,
  universalSettings: universalSettingsReducer,
});

export default reducer;
