import './Edit-Goal-Modal.scss';

import { DEFAULT_API_ERROR } from '../../constants';
import { useActions, useAppSelector } from '../../hooks';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { findDeselectedOption, isEmptyObj, parseFile } from '../../utils';
import {
    EditGoalForm,
    editGoalFormReducer,
    EDIT_GOAL_FORM_TYPES,
    CampaignDetails,
    CampaignFilter,
    DropdownOption,
    EngineDetails,
    GoalsListItem,
} from '../../state';
import { getAccountsData, editGoalMapping } from '../../api';

// UI COMPONENTS
import Modal from '../Modal/Modal';
import { Row } from '@publicismedia-ds/ui-grid';
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Checkbox from '@publicismedia-ds/ui-checkbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import UploadExcluded from '../Upload-Excluded/Upload-Excluded';
import FilterCampaigns from '../Filter-Campaigns/Filter-Campaigns';
import SelectCampaigns from '../Select-Campaigns/Select-Campaigns';
import ModalFrame from '../Modal/Modal-Frame';
import ModalHeader from '../Modal/Modal-Header';
import ModalContent from '../Modal/Model-Content';
import ModalFooter from '../Modal/Modal-Footer';

// EDIT GOAL MODAL PROPS TYPE
interface EditGoalModalProps {
    goal: GoalsListItem;
    onClose: () => void;
}

// FUNCTIONAL COMPONENT
function EditGoalModal({ goal, onClose }: EditGoalModalProps) {
    // TOGGLE GOAL MAPPING NAME INPUT ENABLED/DISABLED
    const [isEdittingName, setIsEdittingName] = useState(false);

    // REDUX USER ID
    const user = useAppSelector(({ auth }) => auth.user);

    // CURRENT CAMPAIGN OPTIONS
    const [campaignOptions, setCampaignOptions] = useState<DropdownOption[]>(
        []
    );

    // REDUX ACTIONS
    const { setLoading, alertError, alertSuccess, loadGoalsList } =
        useActions();

    // INITIAL FORM STATE (MOST RECENTLY SAVED STATE OF GOAL)
    const INITIAL_FORM_STATE = useMemo((): EditGoalForm => {
        const engineIds = goal.engineIds.split(',').sort();
        const engineNames = goal.engineNames.split(',');

        const campaignIds = goal.campaignIds.split(',').sort();
        const campaignNames = goal.campaignNames.split(',');

        const selectedEngines = engineIds.map((id, index): DropdownOption => {
            return {
                label: engineNames[index],
                value: id,
            };
        });

        const selectedCampaigns = campaignIds.map(
            (id, index): DropdownOption => {
                return {
                    label: campaignNames[index],
                    value: id,
                };
            }
        );

        let campaignFilters = [];

        try {
            campaignFilters = JSON.parse(goal.filters);
        } catch {
            campaignFilters = [];
        }

        return {
            goalId: goal.goalId,
            name: goal.goalMappingName,
            agencyId: goal.agencyId,
            reportType: goal.reportType,
            advertiser: null,
            syncGoal: goal.syncGoals,
            allEngines: {},
            selectedEngines,
            selectedCampaigns,
            autoAdd360: goal.autoAddCampaign,
            minBid: goal.minBid,
            maxBid: goal.maxBid,
            minDailyBudget: goal.minDailyBudget.toString(),
            maxDailyBudget: goal.maxDailyBudget.toString(),
            excludedCampaigns: [],
            excludedKeywords: [],
            isIntraday: goal.isIntraday,
            campaignFilters,
            useFilter: goal.useFilter,
        };
    }, []);

    // FORM STATE
    const [formState, dispatch] = useReducer(
        editGoalFormReducer,
        INITIAL_FORM_STATE
    );

    // FETCH AND STORE GOAL ACCOUNT DATA ON INITIAL LOAD
    useEffect(() => {
        (async function () {
            if (!goal || !user) return;

            // OPEN LOADING MODAL
            setLoading(true);
            try {
                // FETCH & STORE ACCOUNT DATA
                const data = await getAccountsData(user.id, goal.agencyId);

                // FIND GOAL'S ADVERTISER DATA
                const advertiser =
                    data.advertisers.find(
                        (advertiser) =>
                            advertiser.advertiserId === goal.advertiserId
                    ) || null;

                // SET ADVERTISER DATA
                dispatch({
                    type: EDIT_GOAL_FORM_TYPES.SET_ADVERTISER,
                    payload: advertiser,
                });
            } catch (error: any) {
                // ALERT MESSAGE ON ERROR
                alertError(
                    error.response?.data?.errorMessage || DEFAULT_API_ERROR
                );
            } finally {
                // CLOSE LOADING MODAL
                setLoading(false);
            }
        })();
    }, [goal.goalId]);

    // UPDATE CAMPAIGN DROPDOWN OPTIONS ON CHANGES TO SELECTED ENGINES
    useEffect(() => {
        // RETURN EMPTY ARRAY IF NO ENGINE DATA
        if (isEmptyObj(formState.allEngines)) return;

        const availableCampaigns = formState.selectedEngines
            // ADD ALL CAMPAIGNS FOR EACH SELECTED ENGINE
            .reduce((camps: CampaignDetails[], { value }) => {
                if (value === 'all') return camps;
                camps.push(...formState.allEngines[value]?.campaigns);
                return camps;
            }, []);

        const campaignsObject = availableCampaigns.reduce(
            (data: { [key: string]: CampaignDetails }, camp) => {
                data[camp.campaignId] = camp;
                return data;
            },
            {}
        );

        const options = Object.keys(campaignsObject).map(
            (campaignId): DropdownOption => ({
                label: campaignsObject[campaignId].campaignName,
                value: campaignId,
            })
        );

        setCampaignOptions(options);
    }, [formState.selectedEngines, formState.allEngines]);

    // CREATE ENGINE DROPDOWN OPTIONS
    const engineOptions: DropdownOption[] =
        formState.advertiser?.engines.map((engine) => ({
            label: engine.engineName,
            value: engine.engineId,
        })) || [];

    // HANDLE EDITTING GOAL NAME
    const onEditGoalName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: EDIT_GOAL_FORM_TYPES.SET_NAME,
            payload: evt.target.value,
        });
    };

    // HANDLE ENGINE SELECTION
    const onSelectEngine = (selections: DropdownOption[]) => {
        if (!formState.advertiser) return;

        // IF OPTION WAS SELECTED
        if (selections.length > formState.selectedEngines.length) {
            dispatch({
                type: EDIT_GOAL_FORM_TYPES.ADD_SELECTED_ENGINE,
                payload: selections[selections.length - 1],
            });

            // IF OPTION WAS DE-SELECTED
        } else {
            // FIND DE-SELECTED OPTION
            const deselectedOption = findDeselectedOption(
                selections,
                formState.selectedEngines
            );

            if (!deselectedOption) return;

            // REMOVE DE-SELECTED OPTION
            dispatch({
                type: EDIT_GOAL_FORM_TYPES.REMOVE_SELECTED_ENGINE,
                payload: deselectedOption,
            });
        }
    };

    // HANDLE SYNC GOAL BETWEEN PROD & QA
    const onSyncGoal = (
        evt: React.ChangeEvent<HTMLInputElement>,
        isChecked: boolean
    ) => {
        dispatch({
            type: EDIT_GOAL_FORM_TYPES.SET_SYNC_GOAL,
            payload: isChecked,
        });
    };

    // HANDLE CAMPAIGN SELECTIONS
    const onSelectCampaigns = (
        selections: DropdownOption[],
        uploaded?: boolean
    ) => {
        if (!formState.selectedEngines) return;

        // IF SELECTIONS ARE FROM UPLOADED FILE
        if (uploaded) {
            dispatch({
                type: EDIT_GOAL_FORM_TYPES.UPLOAD_CAMPAIGN_IDS,
                payload: selections,
            });
            return;
        }

        // IF ITEM SELECTED
        if (selections.length > formState.selectedCampaigns.length) {
            dispatch({
                type: EDIT_GOAL_FORM_TYPES.ADD_SELECTED_CAMPAIGN,
                payload: selections[selections.length - 1],
            });
            return;
        } else {
            // FIND AND REMOVE SELECTED OPTION
            const deselectedOption = findDeselectedOption(
                selections,
                formState.selectedCampaigns
            );

            if (deselectedOption) {
                dispatch({
                    type: EDIT_GOAL_FORM_TYPES.REMOVE_SELECTED_CAMPAIGN,
                    payload: deselectedOption,
                });
            }
        }
    };

    // HANDLE UPLOADING EXCLUDED CAMPAIGNS FROM FILE
    const onUploadExcludedCampaigns = async (
        evt: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (!evt.target.files?.length) return;

        // DISPLAY LOADING MODAL
        setLoading(true);

        try {
            // ATTEMPT TO PARSE FILE AND ADD EXCLUDED CAMPAIGNS
            const excludedCampaigns = await parseFile(
                evt.target.files[0],
                'campaign'
            );

            dispatch({
                type: EDIT_GOAL_FORM_TYPES.UPLOAD_EXCLUDED_CAMPAIGNS,
                payload: excludedCampaigns,
            });
        } catch (error: any) {
            // ALERT ANY ERRORS THAT OCCUR DURING PROCESSING
            alertError(error);
        } finally {
            // CLOSE MODAL
            setLoading(false);
        }
    };
    // HANDLE UPLOADING EXCLUDED KEYWORDS FROM FILE
    const onUploadExcludedKeywords = async (
        evt: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (!evt.target.files?.length) return;

        // DISPLAY LOADING MODAL
        setLoading(true);

        try {
            // ATTEMPT TO PARSE FILE AND ADD EXCLUDED KEYWORDS
            const excludedKeywords = await parseFile(
                evt.target.files[0],
                'keyword'
            );
            if (!excludedKeywords) return;

            dispatch({
                type: EDIT_GOAL_FORM_TYPES.UPLOAD_EXCLUDED_KEYWORDS,
                payload: excludedKeywords,
            });
        } catch (error: any) {
            // ALERT ANY ERRORS THAT OCCUR DURING PROCESSING
            alertError(error);
        } finally {
            // CLOSE MODAL
            setLoading(false);
        }
    };

    // HANDLE ADD CAMPAIGN FILTER
    const onAddCampaignFilter = (filter: CampaignFilter) => {
        dispatch({
            type: EDIT_GOAL_FORM_TYPES.ADD_CAMPAIGN_FILTER,
            payload: {
                filter,
                campaignOptions,
            },
        });
    };

    // HANDLE REMOVE CAMPAIGN FILTER
    const onRemoveCampaignFilter = (filter: CampaignFilter) => {
        dispatch({
            type: EDIT_GOAL_FORM_TYPES.REMOVE_CAMPAIGN_FILTER,
            payload: {
                filter,
                campaignOptions,
            },
        });
    };

    // HANDLE MIN BID CHANGES
    const onSetMinBid = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: EDIT_GOAL_FORM_TYPES.SET_MIN_BID,
            payload: evt.target.value,
        });
    };

    // HANDLE MAX BID CHANGES
    const onSetMaxBid = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: EDIT_GOAL_FORM_TYPES.SET_MAX_BID,
            payload: evt.target.value,
        });
    };

    // HANDLE MIN DAILY BUDGET CHANGES
    const onSetMinDailyBudget = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: EDIT_GOAL_FORM_TYPES.SET_MIN_DAILY_BUDGET,
            payload: evt.target.value,
        });
    };
    // HANDLE MAX DAILY BUDGET CHANGES
    const onSetMaxDailyBudget = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: EDIT_GOAL_FORM_TYPES.SET_MAX_DAILY_BUDGET,
            payload: evt.target.value,
        });
    };

    // HANDLE APPLY UPDATED GOAL CHANGES
    const onApplyChanges = async (evt: React.FormEvent) => {
        evt.preventDefault();
        if (!user) return;

        // OPEN LOADING MODAL
        setLoading(true);

        try {
            // SUBMIT ADD GOAL REQUEST
            const data = await editGoalMapping(formState);

            if (!data) {
                alertError(
                    'Unable to add goal.  Please make sure all required fields contain a value.'
                );
                return;
            }

            if (data.result === 'success') {
                // DISPLAY SUCCESS MESSAGE
                alertSuccess(data.message);

                // LOAD UPDATED GOALS LIST
                loadGoalsList(user);

                // CLOSE EDIT GOAL MODAL
                onClose();
            } else {
                alertError(data.message || DEFAULT_API_ERROR);
            }
        } catch (error: any) {
            alert(error.response?.data?.errorMessage || DEFAULT_API_ERROR);
        } finally {
            // CLOSE LOADING MODAL
            setLoading(false);
        }
    };

    return (
        <Modal userClosable={false} onClose={onClose}>
            <ModalFrame className="edit-goal-modal-container">
                <form onSubmit={onApplyChanges} className="edit-goal-form">
                    <ModalHeader className="edit-goal-header">
                        {/* --- EDIT MODAL NAME --- */}
                        <div className="edit-goal-header">
                            <h1 className="modal-heading">Map Name:</h1>
                            <Textbox
                                className="edit-goal-name-input"
                                onChange={onEditGoalName}
                                value={formState.name}
                                disabled={!isEdittingName}
                                required
                                hideLabel
                            >
                                ''
                            </Textbox>
                            <i
                                className="icon-edit"
                                onClick={() =>
                                    setIsEdittingName((prev) => !prev)
                                }
                            ></i>
                        </div>
                    </ModalHeader>
                    <ModalContent className="edit-goal-content">
                        <div className="edit-goal-content">
                            <div className="edit-goal-row goal-data-read-only">
                                <p>
                                    <span className="goal-property-name">
                                        Agency:
                                    </span>{' '}
                                    {goal.agencyName}
                                </p>
                                <p>
                                    <span className="goal-property-name">
                                        Advertiser:
                                    </span>{' '}
                                    {goal.advertiserName}
                                </p>
                                <p>
                                    <span className="goal-property-name">
                                        Report Type:
                                    </span>{' '}
                                    {goal.reportType}
                                </p>
                            </div>

                            {/* --- DISPLAY MESSAGE OR CHECKBOX TO SYNC GOAL --- */}
                            <div className="edit-goal-row">
                                {goal.syncGoals ? (
                                    <p>
                                        This goal is already synced between
                                        production and QA.
                                    </p>
                                ) : (
                                    <Checkbox invert onChange={onSyncGoal}>
                                        Sync between QA and production
                                    </Checkbox>
                                )}
                            </div>
                            <div className="edit-goal-row">
                                {/* --- ENGINE(S) SELECTION --- */}
                                <Row>
                                    <Dropdown
                                        options={engineOptions}
                                        onChange={onSelectEngine}
                                        value={formState.selectedEngines}
                                        defaultValue={formState.selectedEngines}
                                        display="selectionInline"
                                        multiple
                                        invert
                                        required
                                    >
                                        Engine Account(s)
                                    </Dropdown>
                                </Row>
                            </div>

                            {/* --- CAMPAIGN SELECTIONS --- */}
                            <div className="edit-goal-row campaign-selection-inputs">
                                {goal.useFilter ? (
                                    <FilterCampaigns
                                        filters={formState.campaignFilters}
                                        campaignOptions={campaignOptions}
                                        selectedCampaigns={
                                            formState.selectedCampaigns
                                        }
                                        onAddFilter={onAddCampaignFilter}
                                        onRemoveFilter={onRemoveCampaignFilter}
                                    />
                                ) : (
                                    <SelectCampaigns
                                        campaignOptions={campaignOptions}
                                        onSelectCampaigns={onSelectCampaigns}
                                        selectedCampaigns={
                                            formState.selectedCampaigns
                                        }
                                        autoAdd360={formState.autoAdd360}
                                        onSelectAutoAdd360={() => {}}
                                    />
                                )}
                            </div>

                            {/* --- MIN/MAX BIDS/BUDGETS --- */}
                            <div className="edit-goal-row bid-budget-inputs">
                                <Textbox
                                    type="number"
                                    min="0"
                                    onChange={onSetMinBid}
                                    value={formState.minBid}
                                >
                                    Minimum Bid
                                </Textbox>
                                <Textbox
                                    type="number"
                                    min="0"
                                    onChange={onSetMaxBid}
                                    value={formState.maxBid}
                                >
                                    Maximum Bid
                                </Textbox>
                                <Textbox
                                    type="number"
                                    min="0"
                                    onChange={onSetMinDailyBudget}
                                    value={formState.minDailyBudget}
                                >
                                    Min Daily Budget
                                </Textbox>
                                <Textbox
                                    type="number"
                                    min="0"
                                    onChange={onSetMaxDailyBudget}
                                    value={formState.maxDailyBudget}
                                >
                                    Max Daily Budget
                                </Textbox>
                            </div>

                            {/* --- EXCLUDE CAMPAIGNS/KEYWORDS --- */}
                            <div className="edit-goal-row upload-excluded-inputs">
                                <UploadExcluded
                                    type="Campaign"
                                    onChange={onUploadExcludedCampaigns}
                                    options={formState.excludedCampaigns}
                                />
                                <UploadExcluded
                                    type="Keyword"
                                    onChange={onUploadExcludedKeywords}
                                    options={formState.excludedKeywords}
                                />
                            </div>
                        </div>
                    </ModalContent>

                    {/* --- APPLY/CANCEL BUTTONS --- */}
                    <ModalFooter>
                        <Button display="text" onClick={onClose}>
                            Close
                        </Button>
                        <Button type="submit">Apply Changes</Button>
                    </ModalFooter>
                </form>
            </ModalFrame>
        </Modal>
    );
}

export default EditGoalModal;
