import { useActions, useAppSelector } from '../../../hooks';
import { DropdownOption } from '../../../state';

// UI COMPONENTS
import BingSubAccounts from '../Bing-Sub-Accounts';
import Dropdown from '@publicismedia-ds/ui-dropdown';

interface EditBingAccountsProps {
    mEmails: DropdownOption[];
}

function EditBingAccounts({ mEmails }: EditBingAccountsProps) {
    // REDUX ACTIONS
    const { setBingAdsEmail } = useActions();

    // REDUX STATE
    const { bingAdsManagerEmail } = useAppSelector(
        ({ editAccountForm }) => editAccountForm
    );

    // HANDLE SELECTING BING ADS MANAGER EMAIL
    const onSelectManagerEmail = (selected: DropdownOption) => {
        setBingAdsEmail(selected.value);
    };

    return (
        <div>
            <h4 className="edit-account-header">Bing Ads:</h4>
            <div className="edit-bing-sub-accounts">
                <div className="inputs-row">
                    {/* SELECT BING MANAGER EMAIL */}
                    <Dropdown
                        options={mEmails}
                        onChange={onSelectManagerEmail}
                        value={{
                            label: '',
                            value: bingAdsManagerEmail,
                        }}
                        defaultValue={{
                            label: '',
                            value: bingAdsManagerEmail,
                        }}
                        isSearchable={false}
                        required
                    >
                        Bing Ads Manger Email
                    </Dropdown>
                </div>

                {/* --- BING ADS SUB ACCOUNTS --- */}
                <BingSubAccounts />
            </div>
        </div>
    );
}

export default EditBingAccounts;
