import { memo } from 'react';

// TYPES
import { FormulaColumnItem } from '../../state';

// UI COMPONENTS
import Checkbox from '@publicismedia-ds/ui-checkbox';

// COMPONENT PROPS
interface FormulaColumnsTableProps {
    data: FormulaColumnItem[];
    onRemove?: (index: number) => void;
    onUpdate: (formula: FormulaColumnItem, index: number) => void;
}

// FUNCTIONAL COMPONENT (INPUTED FORMULA COLUMNS)
function FormulaColumnsTable({ data, onUpdate }: FormulaColumnsTableProps) {
    // JSX
    return (
        <table className="formula-columns-table">
            <thead>
                <tr>
                    <th> </th>
                    <th>Column:</th>
                    <th>Formula:</th>
                </tr>
            </thead>
            <tbody>
                {data.map((formula, index) => {
                    return (
                        <tr key={index}>
                            <td
                                style={{
                                    maxWidth: '5px',
                                }}
                            >
                                <Checkbox
                                    onChange={(_evt: any, checked: boolean) =>
                                        onUpdate({ ...formula, checked }, index)
                                    }
                                    checked={formula.checked}
                                    invert
                                >
                                    {' '}
                                </Checkbox>
                            </td>
                            <td>{formula.columnName}</td>
                            <td className="column-formula">
                                {`${formula.columnOne?.label} ${formula.operator?.label} ${formula.columnTwo?.label}`}{' '}
                                <span className="action-icons"></span>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default memo(FormulaColumnsTable);
