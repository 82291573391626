import { useState } from 'react';
import { useActions } from '../../hooks';

// TYPES
import { DropdownOption, FormulaColumnItem } from '../../state';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';

// AVAILABLE OPERATORS
enum OPERATORS {
    ADD = '+',
    SUBTRACT = '-',
    MULTIPLY = 'x',
    DIVIDE = '/',
}

// COMPONENT PROPS
interface FormulaColumnsProps {
    columnOptions: DropdownOption[];
    onSubmit: (formState: FormulaColumnItem) => void;
    isEditing?: boolean;
}

const DEFAULT_STATE: FormulaColumnItem = {
    columnName: '',
    columnOne: null,
    operator: null,
    columnTwo: null,
    checked: true,
};

// FUNCTIONAL COMPONENT (PACING DOC CUSTOM FORMULAS)
function FormulaColumnForm({
    onSubmit,
    columnOptions,
    isEditing = false,
}: FormulaColumnsProps) {
    // REDUX ACTIONS
    const { alertError } = useActions();

    const [state, setState] = useState<FormulaColumnItem>(DEFAULT_STATE);

    const OPERATOR_OPTIONS: DropdownOption[] = Object.values(OPERATORS).map(
        (operator) => ({ label: operator, value: operator })
    );

    // HANDLE COLUMN NAME CHANGES
    const onSetColumnName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const columnName = evt.target.value;
        setState((prev) => ({ ...prev, columnName }));
    };

    // HANDLE COLUMN 1 SELECTION
    const onSetColumnOne = (selected: DropdownOption) => {
        setState((prev) => ({
            ...prev,
            columnOne:
                selected.value !== prev.columnOne?.value ? selected : null,
        }));
    };

    // HANDLE OPERATOR SELECTION
    const onSetOperator = (selected: DropdownOption) => {
        setState((prev) => ({
            ...prev,
            operator: selected.value !== prev.operator?.value ? selected : null,
        }));
    };

    // HANDLE COLUMN TWO SELECTION
    const onSetColumnTwo = (selected: DropdownOption) => {
        setState((prev) => ({
            ...prev,
            columnTwo:
                selected.value !== prev.columnTwo?.value ? selected : null,
        }));
    };

    // HANDLE ADD NEW FORMULA
    const onAddFormula = () => {
        try {
            onSubmit(state);
            setState({ ...DEFAULT_STATE });
        } catch (error: any) {
            alertError(error.message);
        }
    };

    const isRequired =
        !!state.columnName ||
        !!state.columnOne ||
        !!state.operator ||
        !!state.columnTwo;

    const isDisabled =
        !state.columnName ||
        !state.columnOne ||
        !state.operator ||
        !state.columnTwo;

    // JSX
    return (
        <>
            <Row
                className="formula-column-form"
                style={{ gridTemplateColumns: 'repeat(5, 1fr) 0.5fr' }}
            >
                <Column>
                    <Textbox
                        onChange={onSetColumnName}
                        value={state.columnName}
                        required={isRequired}
                    >
                        Column Name:
                    </Textbox>
                </Column>
                <Column>
                    <Dropdown
                        options={columnOptions}
                        onChange={onSetColumnOne}
                        value={state.columnOne}
                        required={isRequired}
                    >
                        Column 1:
                    </Dropdown>
                </Column>
                <Column>
                    <Dropdown
                        options={OPERATOR_OPTIONS}
                        onChange={onSetOperator}
                        value={state.operator}
                        required={isRequired}
                    >
                        Operator:
                    </Dropdown>
                </Column>
                <Column>
                    <Dropdown
                        className="column-two-dd"
                        options={columnOptions}
                        onChange={onSetColumnTwo}
                        value={state.columnTwo}
                        required={isRequired}
                    >
                        Column 2:
                    </Dropdown>
                </Column>
                <Column>
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            onClick={onAddFormula}
                            color="brand-2"
                            disabled={isDisabled}
                        >
                            {isEditing ? 'Update' : 'Add'}
                        </Button>
                    </div>
                </Column>
            </Row>
        </>
    );
}

export default FormulaColumnForm;
