import { axios } from '../axios-config';

// TYPES
import {
    GETAllNotes,
    NoteDetails,
    EditNotesState,
    AddNoteFormState,
} from '../../state';

// UTILS
import { formatAddNoteBody, formatEditNotesBody } from '../../utils';

// CONSTANTS
import { API_UNIVERSAL_SETTINGS } from '../../constants';

// GET ALL EXISTING NOTES
export const getAllNotes = async (userId: string) => {
    const url = API_UNIVERSAL_SETTINGS + '/getAllNotes';

    try {
        const { data } = await axios.get<GETAllNotes>(url, {
            params: { userId },
        });

        return data;
    } catch (error: any) {
        throw error;
    }
};

export const addNote = async (userId: string, formState: AddNoteFormState) => {
    const url = API_UNIVERSAL_SETTINGS + '/addNotesForEachAccount';

    const body = formatAddNoteBody(userId, formState);

    try {
        const { data } = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        return data;
    } catch (error: any) {
        throw error;
    }
};

export const editNotes = async (
    formState: EditNotesState,
    originalNotes: NoteDetails,
    userId: number
) => {
    const url = API_UNIVERSAL_SETTINGS + '/editNotesForEachAccount';

    const body = formatEditNotesBody(formState, originalNotes, userId);

    try {
        const { data } = await axios.post(url, body);
        return data;
    } catch (error: any) {
        throw error;
    }
};
