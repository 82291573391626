import { type } from 'os';
import {
    DropdownOption,
    ExistingAccount,
    GETRolesAccountBrands,
} from '../../models';

export interface SA360CronFromState {
    reportOptions: DropdownOption[];
    accountsData: { [key: string]: ExistingAccount };
    agencyOptions: DropdownOption[];
    advertiserOptions: DropdownOption[];
    selectedReport: DropdownOption;
    selectedAgencies: DropdownOption[];
    selectedAdvertisers: DropdownOption[];
    flightStart: Date | '';
    flightEnd: Date | '';
    timeFlag: number;
}

export const INITIAL_SA360_CRON_FORM: SA360CronFromState = {
    reportOptions: [],
    accountsData: {},
    selectedReport: { label: '', value: '' },
    agencyOptions: [],
    advertiserOptions: [],
    selectedAdvertisers: [],
    selectedAgencies: [],
    flightStart: '',
    flightEnd: '',
    timeFlag: 0,
};

export enum SA360_CRON_ACTION_TYPES {
    LOAD_OPTIONS_DATA = 'load_options_data',
    SET_SELECTED_REPORT = 'set_selected_report',
    ADD_SELECTED_AGENCY = 'add_selected_agency',
    REMOVE_SELECTED_AGENCY = 'remove_selected_agency',
    ADD_SELECTED_ADVERTISER = 'add_selected_advertiser',
    REMOVE_SELECTED_ADVERTISER = 'remove_selected_advertiser',
    SET_FLIGHT_START = 'set_flight_start',
    SET_FLIGHT_END = 'set_flight_end',
    SET_TIME_FLAG = 'set_time_flag',
    RESET_SA360_CRON_FORM = 'reset_sa360_cron_form',
}

interface LoadOptionsDataAction {
    type: SA360_CRON_ACTION_TYPES.LOAD_OPTIONS_DATA;
    payload: GETRolesAccountBrands;
}

interface SetSelectedReportAction {
    type: SA360_CRON_ACTION_TYPES.SET_SELECTED_REPORT;
    payload: DropdownOption;
}

interface AddSelectedAgencyAction {
    type: SA360_CRON_ACTION_TYPES.ADD_SELECTED_AGENCY;
    payload: DropdownOption;
}

interface RemoveSelectedAgencyAction {
    type: SA360_CRON_ACTION_TYPES.REMOVE_SELECTED_AGENCY;
    payload: DropdownOption;
}

interface AddSelectedAdvertiserAction {
    type: SA360_CRON_ACTION_TYPES.ADD_SELECTED_ADVERTISER;
    payload: DropdownOption;
}

interface RemoveSelctedAdvertiserAction {
    type: SA360_CRON_ACTION_TYPES.REMOVE_SELECTED_ADVERTISER;
    payload: DropdownOption;
}

interface SetFlightStartAction {
    type: SA360_CRON_ACTION_TYPES.SET_FLIGHT_START;
    payload: Date;
}

interface SetFlightEndAction {
    type: SA360_CRON_ACTION_TYPES.SET_FLIGHT_END;
    payload: Date;
}

interface SetTimeFlagAction {
    type: SA360_CRON_ACTION_TYPES.SET_TIME_FLAG;
    payload: number;
}

interface ResetSA360CronFormAction {
    type: SA360_CRON_ACTION_TYPES.RESET_SA360_CRON_FORM;
}

export type SA360CronFormAction =
    | LoadOptionsDataAction
    | SetSelectedReportAction
    | AddSelectedAgencyAction
    | RemoveSelectedAgencyAction
    | AddSelectedAdvertiserAction
    | RemoveSelctedAdvertiserAction
    | SetFlightStartAction
    | SetFlightEndAction
    | SetTimeFlagAction
    | ResetSA360CronFormAction;
