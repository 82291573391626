import React from 'react';
import { ApexOptions } from 'apexcharts';

import { ScenarioPlannerData } from '../../state';

// UI COMPONENTS
import { Column } from '@publicismedia-ds/ui-charts';
import { formatLargeNumber, numberWithCommas } from '../../utils';

// COMPONENT PROPS
interface ScenarioPlannerChartProps {
    data: ScenarioPlannerData;
}
function ScenarioPlannerChart({ data }: ScenarioPlannerChartProps) {
    const options: ApexOptions = {
        chart: {
            toolbar: {
                show: false,
                tools: {
                    download: false,
                },
            },
        },
        stroke: {
            width: [0, 4],
        },
        dataLabels: {
            enabled: false,
            enabledOnSeries: [0],
        },
        markers: {
            size: [0, 5],
        },
        title: {
            text: 'Production Levels',
        },
        labels: data.xAxis.map((label) => label.toString()) || [],
        yaxis: [
            {
                seriesName: 'Cost',
                opposite: true,
                decimalsInFloat: 0,
                title: {
                    text: 'Cost',
                },
                labels: {
                    formatter: (val) => formatLargeNumber(val),
                },
            },
            {
                seriesName: 'Efficiency',
                title: {
                    text: 'Efficiency',
                },
                decimalsInFloat: 0,
            },
        ],
    };

    const series = [
        {
            name: 'Cost',
            type: 'column',
            data:
                data.Cost.map((val) =>
                    (Math.round(val * 100) / 100).toFixed(2)
                ) || [],
        },
        {
            name: 'Efficiency',
            type: 'line',
            data:
                data.Efficiency.map((val) =>
                    (Math.round(val * 100) / 100).toFixed(2)
                ) || [],
        },
    ];
    return <Column data={series} options={options} width="100%" />;
}

export default React.memo(ScenarioPlannerChart);
