// TYPES
import {
    GetUserActivity,
    UserActivityDetails,
    UserActivityTableData,
} from '../state';

const FEATURE_ORDER = [
    'Overall',
    'Performance Monitor',
    'Yield Optimizer',
    'Scenario Planner',
    'Performance Diagnostic',
    'Bid and Bid Headroom Scan',
    'Match Type Strategy Analysis',
];

// FORMAT USER ACTIVITY DATA RESPONSE INTO TABLE DATA SET
export const formatUserActivity = (
    data: GetUserActivity
): UserActivityDetails[] => {
    const dataObj: UserActivityTableData = {};

    // GROUP PAGE VIEWS BY FEATURE
    data.visitorsPageViewData.forEach((details) => {
        if (!dataObj[details.Features]) {
            dataObj[details.Features] = {
                pageViews: details['Page Views'],
            };
        }
    });

    // GROUP RUNS BY FEATURE
    data.visitorsRunsData.forEach((details) => {
        if (!dataObj[details.Features]) {
            dataObj[details.Features] = {};
        }

        dataObj[details.Features].runs = details.Runs || 0;
    });

    // GROUP SESSION TIMES BY FEATURE
    data.SessionTimeData.forEach((details) => {
        if (!dataObj[details.Features]) {
            dataObj[details.Features] = {};
        }

        dataObj[details.Features].sessionTime =
            details['Session Time'] || '00:00:00';
    });

    // RETURN ORDERED ARRAY BY FEATURE W/ VALUES FOR DATA PROVIDER
    return FEATURE_ORDER.map((feature) => ({
        feature,
        pageViews: 0,
        runs: 0,
        sessionTime: '00:00:00',
        ...dataObj[feature],
    }));
};
