import './Reset-Password.scss';

import React, { useState } from 'react';
import { resetPassword } from '../../api';
import { useActions, useAsyncWrapper } from '../../hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';

// LOGOS
import logoDarwin from '../../assets/images/logo.svg';
import logoPerfomics from '../../assets/images/logoPerformics.svg';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';

// CONSTANTS
import { ROUTES } from '../../router/router';
import { DEFAULT_API_ERROR } from '../../constants';

function ResetPassword() {
    const navigate = useNavigate();
    const wrapper = useAsyncWrapper();
    const [search] = useSearchParams();

    // FORM STATE
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    // REDUX ACTIONS
    const { alertSuccess } = useActions();

    // GET RESET CODE VALUE
    const resetCode = search.get('id');

    // HANDLE RESET PASSWORD SUBMIT
    const onSubmit = wrapper(async (evt: React.FormEvent) => {
        evt.preventDefault();
        if (!resetCode || !password || !confirmPassword) return;

        // CHECK PASSWORDS MATCH
        if (password !== confirmPassword) {
            setErrorMessage('Passwords must match.');
            return;
        }

        const data = await resetPassword(resetCode, password, confirmPassword);

        if (data.result === 'success') {
            alertSuccess('Password successfully change. Please login.');
            navigate(ROUTES.LOGIN);
        } else {
            throw new Error(data.message || DEFAULT_API_ERROR);
        }
    });

    return (
        <div className="login-container">
            {/* ERROR MESSAGE */}
            {errorMessage && (
                <div className="error-message">
                    <p>{errorMessage}</p>
                </div>
            )}
            <div className="login-content">
                <div className="logo-container">
                    <img src={logoDarwin} alt="Darwin Logo" />
                </div>
                <h4>Reset Password</h4>
                <form className="login-form" onSubmit={onSubmit}>
                    {/* NEW PASSWORD */}
                    <div>
                        <Textbox
                            value={password}
                            placeholder="New Password"
                            type="password"
                            onChange={(
                                evt: React.ChangeEvent<HTMLInputElement>
                            ) => setPassword(evt.target.value)}
                            error="New Password required"
                            invert={true}
                            required
                        >
                            New Password
                        </Textbox>
                    </div>

                    {/* CONFIRM PASSWORD */}
                    <div>
                        <Textbox
                            value={confirmPassword}
                            placeholder="Confirm Password"
                            type="password"
                            onChange={(
                                evt: React.ChangeEvent<HTMLInputElement>
                            ) => setConfirmPassword(evt.target.value)}
                            required
                            invert={true}
                            error="Confirm Password required"
                        >
                            Confirm Password
                        </Textbox>
                    </div>

                    <div className="login-btn-container">
                        <Button
                            type="submit"
                            className="login-btn"
                            disabled={!password || !confirmPassword}
                        >
                            Reset Password
                        </Button>
                    </div>
                </form>
                <div className="login-footer">
                    <p>
                        Powerd by:{' '}
                        <img
                            src={logoPerfomics}
                            alt="Performics Logo"
                            className="logo-performics"
                        />{' '}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
