import { DropdownOption, ExistingAccount } from '../../models';

export interface AdwordsCronFormState {
    accountsData: { [key: string]: ExistingAccount };
    agencyOptions: DropdownOption[];
    subAccountOptions: DropdownOption[];
    reportType: DropdownOption | '';
    selectedAgency: DropdownOption | '';
    selectedSubAccount: DropdownOption | '';
    selectedFile: File | '';
}

export const INITIAL_ADWORDS_CRON_FORM: AdwordsCronFormState = {
    reportType: '',
    selectedAgency: '',
    selectedSubAccount: '',
    accountsData: {},
    agencyOptions: [],
    subAccountOptions: [],
    selectedFile: '',
};

export enum ADWORDS_CRON_ACTION_TYPES {
    LOAD_ACCOUNTS_DATA = 'load_accounts_data',
    SELECT_REPORT_TYPE = 'set_report_type',
    SELECT_AGENCY = 'select_agency',
    SELECT_SUB_ACCOUNT = 'select_sub_account',
    SELECT_UPLOAD_FILE = 'select_upload_file',
    RESET_ADWORDS_CRON_FORM = 'reset_adwords_cron_form',
}

interface LoadAccountsDataAction {
    type: ADWORDS_CRON_ACTION_TYPES.LOAD_ACCOUNTS_DATA;
    payload: ExistingAccount[];
}

interface SelectReportTypeAction {
    type: ADWORDS_CRON_ACTION_TYPES.SELECT_REPORT_TYPE;
    payload: DropdownOption;
}

interface SelectAgencyAction {
    type: ADWORDS_CRON_ACTION_TYPES.SELECT_AGENCY;
    payload: DropdownOption;
}

interface SelectSubAccountAction {
    type: ADWORDS_CRON_ACTION_TYPES.SELECT_SUB_ACCOUNT;
    payload: DropdownOption;
}

interface SelectUploadFileAction {
    type: ADWORDS_CRON_ACTION_TYPES.SELECT_UPLOAD_FILE;
    payload: File | '';
}

interface ResetMarinCronFormAction {
    type: ADWORDS_CRON_ACTION_TYPES.RESET_ADWORDS_CRON_FORM;
}

export type AdwordsCronFormAction =
    | LoadAccountsDataAction
    | SelectReportTypeAction
    | SelectAgencyAction
    | SelectSubAccountAction
    | SelectUploadFileAction
    | ResetMarinCronFormAction;
