import produce from 'immer';
import { AlertAction } from '../actions/alert-actions';
import { AlertActionType } from '../action-types';
import { Reducer } from 'react';

interface AlertState {
    successMessages: string[];
    errorMessages: string[];
}

const initialState: AlertState = {
    successMessages: [],
    errorMessages: [],
};

const reducer = produce(
    (state: AlertState = initialState, action: AlertAction): AlertState => {
        switch (action.type) {
            case AlertActionType.ALERT_SUCCESS:
                state.successMessages.push(action.payload.message);
                return state;

            case AlertActionType.ALERT_ERROR:
                state.errorMessages.push(action.payload.message);
                return state;

            case AlertActionType.CLEAR_ALERTS:
                state.errorMessages = [];
                state.successMessages = [];
                return state;

            default:
                return state;
        }
    },
    initialState
);

export default reducer;
