import { axios } from '../axios-config';

import { API_DIAGNOSTICS } from '../../constants';
import { ConversionType, PerformanceDiagnosticData } from '../../state';
import { saveDownloadFile } from '../../utils/download-file';

export const getPerformanceDiagnosticData = async (
    userId: string,
    goalId: string | number
) => {
    try {
        const url = API_DIAGNOSTICS + '/diagnostic';

        const body = new URLSearchParams();
        body.append(`userId`, userId);
        body.append('goalId', goalId.toString());

        const { data } = await axios.post<PerformanceDiagnosticData>(
            url,
            body,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );

        return data;
    } catch (error) {
        throw error;
    }
};

export const downloadDiagnosticsReport = async (
    userId: string,
    goalId: number,
    fileName: string
) => {
    try {
        const url = API_DIAGNOSTICS + '/createDiagnosticSheet';

        const body = new URLSearchParams();
        body.append('userId', userId);
        body.append('goalId', goalId.toString());
        body.append('isAdhoc', 'false');

        const blob = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            responseType: 'blob',
        });
        saveDownloadFile(blob, fileName);
    } catch (error) {
        throw error;
    }
};

export const downloadDiagnosticAdhoc = async (
    userId: string,
    filename: string
) => {
    const url = API_DIAGNOSTICS + '/createDiagnosticSheet';

    const body = new URLSearchParams();
    body.append('userId', userId);
    body.append('global', '');
    body.append('isAdhoc', 'true');

    try {
        const blob = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            responseType: 'blob',
        });

        saveDownloadFile(blob, filename);
    } catch (error) {
        throw error;
    }
};

export const getManualAdhocDiagnostic = async (
    userId: string,
    efficiencyTarget: string,
    conversionType: ConversionType
) => {
    try {
        const url = API_DIAGNOSTICS + '/adhocDiagnostic';

        const conversion =
            conversionType === 'Revenue' ? 'revenue' : 'conversion';

        const body = new URLSearchParams();
        body.append('userId', userId);
        body.append('efficiencyTarget', efficiencyTarget);
        body.append('efficiencyNumerator', 'cost');
        body.append('efficiencyDenominator', conversion);

        const { data } = await axios.post<PerformanceDiagnosticData>(
            url,
            body,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );

        return data;
    } catch (error) {
        throw error;
    }
};
