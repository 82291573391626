import { AxiosResponse } from 'axios';

interface Params {
    [key: string]: string | number | true;
}

// CREATES TEMP LINK FOR DOWNLOADING REPORT
export const saveDownloadFile = (response: AxiosResponse, fileName: string) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');

    link.href = href;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
};

export const convertDateToString = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month < 10 ? '0' + month : month}-${
        day < 10 ? '0' + day : day
    }`;
};

export const parseNumberInputValue = (
    evt: React.ChangeEvent<HTMLInputElement>
) => {
    const { value } = evt.target;
    const min = evt.target.getAttribute('min');
    const max = evt.target.getAttribute('max');
    const intValue = parseInt(value);

    if (isNaN(intValue)) return null;
    let actualValue = intValue;

    if (min) actualValue = Math.max(actualValue, parseInt(min));
    if (max) actualValue = Math.min(actualValue, parseInt(max));

    return actualValue;
};

export const getFileNameDate = () => {
    const now = new Date();
    const dateArr = [];
    dateArr.push((now.getMonth() + 1).toString());
    dateArr.push(now.getDate().toString());
    dateArr.push(now.getFullYear().toString());
    dateArr.push(now.getHours().toString());
    dateArr.push(now.getMinutes().toString());
    dateArr.push(now.getSeconds().toString());
    dateArr.push(now.getHours() >= 12 ? 'PM' : 'AM');

    dateArr.forEach((item, index) => {
        if (item.length < 2) dateArr[index] = '0' + item;
    });
    return dateArr.slice(0, 3).join('-') + '__' + dateArr.slice(3).join('_');
};
