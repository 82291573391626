import './Pmds-Text-List.scss';

import React, { useMemo, useState } from 'react';

// UI COMPONENTS
import Pill from '../Pmds-Pill/Pmds-Pill';
import Textbox from '@publicismedia-ds/ui-textbox';

// TEXT LIST COMPONENT PROPS
interface TextListProps {
    label?: string;
    values: string[];
    onAdd: (text: string) => void;
    onRemove: (text: string) => void;
    required?: boolean;
}

function TextList({ label, values, onAdd, onRemove, required }: TextListProps) {
    // TEXT INPUT VALUE STATE
    const [text, setText] = useState('');

    // TRIGGER DUPLICATE VALUE ERROR MESSAGE
    const [duplicate, setDuplicate] = useState(false);

    // HANDLE ADD TEXT TO LIST
    const onAddItem = (evt: React.MouseEvent) => {
        evt.preventDefault();
        if (!text) return;

        // TRIM CURRENT TEXT INPUT VALUE
        const newValue = text.trim();

        // CHECK IF VALUE ALREADY EXISTS
        const isDuplicate =
            values.findIndex(
                (value) => value.toLowerCase() === newValue.toLowerCase()
            ) >= 0;

        // DISPLAY DUPLICATE ERROR MESSAGE IF TRUE
        if (isDuplicate) {
            setDuplicate(true);
            return;
        }

        // CLEAR TEXT INPUT VALUE
        setText('');

        // ADD VALUE
        onAdd(newValue);
    };

    // HANDLE REMOVE TEXT FROM LIST
    const onRemoveItem = (index: number) => {
        onRemove(values[index]);
    };

    // CREATE VALUES LIST ELEMENTS (ONLY UPDATE IF VALUES HAS CHANGED)
    const renderedPills = useMemo(() => {
        return values.map((value, index) => (
            <Pill
                key={index}
                text={value}
                onClick={() => onRemoveItem(index)}
            />
        ));
    }, [values]);

    return (
        <div className="text-list-container">
            <div className="text-list-input">
                <Textbox
                    className="text-input"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        if (duplicate) setDuplicate(false);
                        setText(evt.target.value);
                    }}
                    value={text}
                    required={required && values.length < 1}
                >
                    {label || ''}
                </Textbox>
                <i className="icon-add" onClick={onAddItem} />
                {/* <Button onClick={onAddItem}>Add</Button> */}
            </div>
            {duplicate && (
                <p className="duplicate-error-message">Value already exists</p>
            )}
            <div className="text-list-content">
                <ul>{renderedPills}</ul>
            </div>
        </div>
    );
}

export default TextList;
