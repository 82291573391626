import React from 'react';
import { DropdownOption } from '../../../state';
import { useActions, useAppSelector } from '../../../hooks';

// UI COMPONENTS
import Radio from '@publicismedia-ds/ui-radio';
import Textbox from '@publicismedia-ds/ui-textbox';
import Fieldset from '@publicismedia-ds/ui-fieldset';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import GoogleSubAccounts from '../Google-Sub-Accounts';
import { platform } from 'os';
import { PLATFORM_IDS, STATUS_OPTIONS } from '../../../constants';

interface EditGoolgeAdsAccountsProps {
    mEmails: DropdownOption[];
}

function EditGoolgeAdsAccounts({ mEmails }: EditGoolgeAdsAccountsProps) {
    // REDUX ACTIONS
    const {
        setIsManagerAccount,
        setGoogleEmail,
        setCustomerId,
        setCustomerName,
        setCustomerStatus,
    } = useActions();

    const { platformId } = useAppSelector(
        ({ editAccountForm }) => editAccountForm
    );

    // REDUX STATE
    const {
        customerId,
        customerName,
        customerStatus,
        isManagerAccount,
        isGoogleAdsSupport,
        adwordsManagerEmail,
        editIsManagerAccount,
    } = useAppSelector(({ editAccountForm }) => editAccountForm);

    // HANDLE SELECTING GOOGLE ADS MANAGER EMAIL
    const onSelectManagerEmail = (selected: DropdownOption) => {
        setGoogleEmail(selected.value);
    };

    // HANDLE UPDATING CUSTOMER ID
    const onUpdateCustomerId = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setCustomerId(evt.target.value);
    };

    // HANDLE UPDATING CUSTOMER NAME
    const onUpdateCustomerName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setCustomerName(evt.target.value);
    };

    // HANDLE UPDATING CUSTOM STATUS (NON MANAGER ACCOUNTS ONLY)
    const onUpdateCustomerStatus = (selected: DropdownOption) => {
        setCustomerStatus(selected.value === 'true');
    };

    return (
        <div>
            <div className="google-adwords-form-container">
                <h4 className="edit-account-header">Google Ads:</h4>

                <div className="inputs-row">
                    {/* GOOGLE ADS MANAGER EMAIL */}
                    <Dropdown
                        options={mEmails}
                        onChange={onSelectManagerEmail}
                        value={{
                            label: '',
                            value: adwordsManagerEmail,
                        }}
                        defaultValue={{
                            label: '',
                            value: adwordsManagerEmail,
                        }}
                        isSearchable={false}
                        required
                    >
                        Adwords Manger Email
                    </Dropdown>
                </div>

                {/* GOOGLE ADS PLATFORM ONLY */}
                {platformId === PLATFORM_IDS.GOOGLE_ADS && (
                    <>
                        <div className="inputs-row">
                            {/* CUSTOMER NAME */}
                            <Textbox
                                value={customerName}
                                onChange={onUpdateCustomerName}
                                required
                            >
                                Customer Name
                            </Textbox>

                            {/* CUSTOMER ID */}
                            <Textbox
                                value={customerId}
                                onChange={onUpdateCustomerId}
                                required
                            >
                                Customer ID
                            </Textbox>

                            {/* CUSTOMER STATUS (NON-MANAGER ACCOUNTS ONLY) */}
                            {!isManagerAccount && (
                                <Dropdown
                                    options={STATUS_OPTIONS}
                                    onChange={onUpdateCustomerStatus}
                                    value={customerStatus}
                                >
                                    Status
                                </Dropdown>
                            )}
                        </div>

                        {/* IS MANAGER ACCOUNT SELECTION */}
                        {!isManagerAccount && (
                            <div className="inputs-row">
                                <div>
                                    <p>Is it a Manager Account?</p>
                                </div>
                                <div>
                                    <Fieldset
                                        layout="horizontal"
                                        required
                                        invert
                                    >
                                        <Radio
                                            name="isManager"
                                            checked={editIsManagerAccount}
                                            onChange={() =>
                                                setIsManagerAccount(true)
                                            }
                                            invert
                                        >
                                            Yes
                                        </Radio>
                                        <Radio
                                            name="isManager"
                                            checked={!editIsManagerAccount}
                                            onChange={() =>
                                                setIsManagerAccount(false)
                                            }
                                            invert
                                        >
                                            No
                                        </Radio>
                                    </Fieldset>
                                </div>
                            </div>
                        )}
                    </>
                )}

                {/* SA 360 & EXISTING GOOGLE ADS MANAGER ACCOUNT ONLY */}
                {platformId === PLATFORM_IDS.SA_360 &&
                    isGoogleAdsSupport &&
                    isManagerAccount && (
                        <div className="inputs-row">
                            {/* CUSTOMER NAME */}
                            <Textbox value={customerName} disabled={true}>
                                Manager Customer Name
                            </Textbox>

                            {/* CUSTOMER ID */}
                            <Textbox value={customerId} disabled={true}>
                                Manager Customer ID
                            </Textbox>
                        </div>
                    )}

                {/* GOOGLE ADS SUB ACCOUNTS (MANAGER ACCOUNT ONLY) */}
                {isManagerAccount && <GoogleSubAccounts />}
            </div>
        </div>
    );
}

export default EditGoolgeAdsAccounts;
