export enum UNIVERSAL_SETTINGS_ACTION_TYPES {
    TOGGLE_DISPLAY_SETTINGS = 'toggle_display_settings',
    LOAD_SETTINGS = 'load_settings',
    UPDATE_BUDGET = 'update_budget',
    UPDATE_FLIGHT_START = 'update_start_date',
    UPDATE_FLIGHT_END = 'update_end_date',
    UPDATE_CONVERSION_TYPE = 'update_conversion_type',
    UPDATE_CONVERSION_COLUMN = 'update_conversion_column',
    UPDATE_CUSTOM_START_DATE = 'update_custom_start_date',
    UPDATE_CUSTOM_END_DATE = 'update_custom_end_date',
    UPDATE_REVENUE_COLUMN = 'update_revenue_column',
    UPDATE_EFFICIENCY_NUMERATOR = 'update_efficiency_numerator',
    UPDATE_EFFICIENCY_DENOMINATOR = 'update_efficiency_denominator',
    UPDATE_EFFICIENCY_TARGET = 'update_efficiency_target',
    UPDATE_EXCLUDE_FLIGHT_DATES = 'update_exclude_flight_dates',
    UPDATE_LAST_N_WEEKS = 'update_last_n_weeks',
    UPDATE_LAST_N_DAYS = 'update_last_n_days',
    UPDATE_LAST_N_DAYS_LR = 'update_last_n_days_lr',
    UPDATE_MULTIPLE_TIME_PERIOD = 'update_multiple_time_period',
    UPDATE_CALCULATION_METHOD = 'update_calculation_method',
    UPDATE_OPTIMIZATION_TYPE = 'update_optimization_type',
    UPDATE_OPTIMIZATION_STRATEGY = 'update_optimization_strategy',
    UPDATE_MINIMUM_BID = 'update_minimum_bid',
    UPDATE_MAXIMUM_BID = 'update_maximum_bid',
    UPDATE_MINIMUM_BID_HEADROOM = 'update_minimum_bid_headroom',
    UPDATE_MAXIMUM_BID_HEADROOM = 'update_maximum_bid_headroom',
    UPDATE_MINIMUM_DAILY_BUDGET = 'update_minimum_daily_budget',
    UPDATE_MAXIMUM_DAILY_BUDGET = 'update_maximum_daily_budget',
    UPDATE_USE_WEIGHTED_FLAG = 'update_use_weighted_flag',
    UPDATE_ADJUST_HIGH_DB_FLAG = 'update_adjust_high_db_flag',
    UPDATE_OPTIMIZE_BUDGET_CAPS = 'update_optimize_budget_caps',
    UPDATE_PHRASE_PERCENT_FACTOR = 'update_phrase_percent_factor',
    UPDATE_BROAD_PERCENT_FACTOR = 'update_broad_percent_factor',
}
