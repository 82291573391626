import { memo } from 'react';
import { useActions } from '../../hooks';
import { STATUS_OPTIONS } from '../../constants';
import { BingAdsFormSubAccount, DropdownOption } from '../../state';

// UI COMPONENTS
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';

// COMPONENT PROPS
interface BingSubAccountProps {
    index: number;
    data: BingAdsFormSubAccount;
}

// FUNCTIONAL COMPONENT
function BingSubAccount({ index, data }: BingSubAccountProps) {
    // REDUX ACTIONS
    const { updateBingSubAccount, removeBingSubAccount } = useActions();

    return (
        <div className="inputs-row">
            <Textbox
                value={data.name}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    updateBingSubAccount({
                        index,
                        name: evt.target.value,
                    });
                }}
                disabled={data.existing}
                required
            >
                Sub Account Name
            </Textbox>
            <Textbox
                value={data.id}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    updateBingSubAccount({
                        index,
                        id: evt.target.value,
                    });
                }}
                disabled={data.existing}
                required
            >
                Sub Account ID
            </Textbox>

            <Textbox
                value={data.number}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    updateBingSubAccount({
                        index,
                        number: evt.target.value,
                    });
                }}
                disabled={data.existing}
                required
            >
                Sub Account Number
            </Textbox>
            <Textbox
                value={data.customerId}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    updateBingSubAccount({
                        index,
                        customerId: evt.target.value,
                    });
                }}
                disabled={data.existing}
                required
            >
                Sub Account Customer ID
            </Textbox>
            {data.status !== undefined && (
                <Dropdown
                    options={STATUS_OPTIONS}
                    value={{
                        label: '',
                        value: data.status.toString(),
                    }}
                    defaultValue={{
                        label: '',
                        value: data.status.toString(),
                    }}
                    onChange={(selected: DropdownOption) => {
                        updateBingSubAccount({
                            index,
                            status: selected.value === 'true',
                        });
                    }}
                    isSearchable={false}
                    required
                >
                    Status
                </Dropdown>
            )}
            {/* ICON TO REMOVE SUB ACCOUNT INPUT GROUP (NEW ONLY) */}
            {!data.existing && (
                <i
                    className="icon-close"
                    onClick={() => removeBingSubAccount(index)}
                />
            )}
        </div>
    );
}

export default memo(BingSubAccount);
