import { useActions, useAppSelector } from '../../hooks';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import SA360Advertiser from './SA360-Advertiser';
import { memo } from 'react';

function SA360Advertisers() {
    // REDUX ACTIONS
    const { addSA360Advertiser } = useActions();

    // SA 360 ADVERTISERS (REDUX)
    const { advertisers } = useAppSelector(
        ({ editAccountForm }) => editAccountForm
    );

    return (
        <div>
            <h4 className="edit-account-header">Advertisers:</h4>
            {advertisers.map((data, index) => {
                return (
                    <SA360Advertiser
                        data={data}
                        index={index}
                        key={`sa360-advertiser-${index}`}
                    />
                );
            })}
            {/* CREATE ROW FOR ADDITIONAL ADVERTISER BUTTON */}
            <div className="insert-inputs-row-button">
                <Button
                    onClick={() => {
                        addSA360Advertiser();
                    }}
                    color="brand-2"
                >
                    New Advertiser <i className="icon-add" />
                </Button>
            </div>
        </div>
    );
}

export default memo(SA360Advertisers);
