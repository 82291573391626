import './User-Activity.scss';

import { useEffect, useState } from 'react';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';

// UI COMPONENTS
import { Row } from '@publicismedia-ds/ui-grid';
import UserActivityForm from './User-Activity-Form';
import UserActivityTable from './User-Activity-Table';
import AdminPage from '../../components/Admin-Page/Admin-Page';

// TYPES
import {
    UserDetails,
    UserActivityDetails,
    UserActivityFormState,
} from '../../state';

// API REQUESTS
import { getAllUsers, getUserActivity, downloadUserActivity } from '../../api';

// UTILS
import { formatUserActivity } from '../../utils';

// FUNCTIONAL COMPONENT (USER ACTIVITY DASHBOARD)
function UserActivity() {
    // HOOKS
    const wrapper = useAsyncWrapper();

    // PAGE STATE
    const [userList, setUserList] = useState<UserDetails[]>([]);
    const [formData, setFormData] = useState<UserActivityFormState>();
    const [activityData, setActivityData] = useState<UserActivityDetails[]>([]);

    // REDUX STATE
    const user = useAppSelector(({ auth }) => auth.user);

    // REDUX ACTIONS
    const { loadGoalsList } = useActions();

    // FETCH AND STORE ALL USERS
    const loadUserList = wrapper(async () => {
        if (!user) {
            return;
        }

        const { data: allUsers } = await getAllUsers(user.id);

        setUserList(allUsers);
    });

    // LOAD ALL USERS ON INITIAL RENDER
    useEffect(() => {
        if (!user) {
            return;
        }
        loadUserList();
        loadGoalsList(user);
    }, []);

    // HANDLE FORM SELECTIONS
    const onSubmit = wrapper(async (formState: UserActivityFormState) => {
        const data = await getUserActivity(formState);

        const formattedData = formatUserActivity(data);

        setFormData(formState);
        setActivityData(formattedData);
    });

    // HANDLE USER ACTIVITY BULKSHEET DOWNLOAD
    const onDownloadBulksheet = wrapper(async () => {
        if (formData) {
            await downloadUserActivity(formData);
        }
    });

    return (
        <AdminPage header="User Activity">
            <div className="user-activity-container">
                <Row>
                    <UserActivityForm users={userList} onSubmit={onSubmit} />
                </Row>
                <Row>
                    <UserActivityTable
                        data={activityData}
                        onDownload={formData ? onDownloadBulksheet : null}
                    />
                </Row>
            </div>
        </AdminPage>
    );
}

export default UserActivity;
