import produce from 'immer';
import { LoginFormState } from '../../../state';
import { LoginFormActionTypes, LoginFormAction } from './actions';

export const initialState: LoginFormState = {
    email: '',
    password: '',
    isLoading: false,
    forgotPassword: false,
    errorMessage: '',
};

export const loginFormReducer = produce(
    (state: LoginFormState = initialState, action: LoginFormAction) => {
        switch (action.type) {
            case LoginFormActionTypes.UPDATE_EMAIL:
                state.email = action.value;
                return state;

            case LoginFormActionTypes.UPDATE_PASSWORD:
                state.password = action.value;
                return state;

            case LoginFormActionTypes.UPDATE_LOADING:
                state.isLoading = action.value;
                return state;

            case LoginFormActionTypes.UPDATE_FORGOT_PASSWORD:
                state.forgotPassword = action.value;
                return state;

            case LoginFormActionTypes.UPDATE_ERROR_MESSAGE:
                state.errorMessage = action.value;
                return state;

            case LoginFormActionTypes.RESET_FORM:
                state = initialState;
                return state;

            default:
                return state;
        }
    },
    initialState
);
