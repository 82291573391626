import './Match-Type-Analysis.scss';

// UI COMPONENTS
import UniversalSettings from '../../components/Universal-Settings/Universal-Settings';

const classNameComponent = 'match-type-analysis';

// FUNCTIONAL COMPONENT (MATCH TYPE STRATEGY ANALYSIS)
function MatchTypeAnalysis() {
    return (
        <div className={`${classNameComponent}-container`}>
            <h1 className={`${classNameComponent}-header`}>
                Match Type Strategy Analysis
            </h1>
            <div className={`${classNameComponent}-content`}>
                <UniversalSettings />
            </div>
        </div>
    );
}

export default MatchTypeAnalysis;
