import './Account-Onboarding-Form.scss';

import { PlatformId, PLATFORM_IDS } from '../../../constants';
import { ExistingAccountDetails, PlatformDetails } from '../../../state';

// UI COMPONENTS
import SearchAds360Form from '../Search-Ads-360-Form';
import KenshooAccountForm from '../Kenshoo-Account-Form';
import EngineAccountsForm from '../Engine-Accounts-Form';

// COMPONENT PROPS
interface AccountOnboardingFormProps {
    platformId?: PlatformId;
    mEmails: string[];
    account?: ExistingAccountDetails;
    onSubmit?: () => Promise<void>;
    onCancel?: () => void;
}

// FUNCTIONAL COMPONENT
function AccountOnboardingForm({
    platformId,
    mEmails,
    account,
    onSubmit,
    onCancel,
}: AccountOnboardingFormProps) {
    return (
        <div className="account-onboarding-form-container">
            {platformId === PLATFORM_IDS.SA_360 && (
                <SearchAds360Form
                    mEmails={mEmails}
                    onSubmit={onSubmit}
                    account={account}
                    onCancel={onCancel}
                />
            )}
            {platformId === PLATFORM_IDS.KENSHOO && (
                <KenshooAccountForm onSubmit={onSubmit} />
            )}
            {(platformId === PLATFORM_IDS.ENGINE_ACCOUNTS ||
                account?.platformId === PLATFORM_IDS.GOOGLE_ADS ||
                account?.platformId === PLATFORM_IDS.BING_ADS) && (
                <EngineAccountsForm
                    mEmails={mEmails}
                    account={account}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                />
            )}
            {platformId === PLATFORM_IDS.MARIN && (
                <h2>Currently Unavailable</h2>
            )}
        </div>
    );
}

export default AccountOnboardingForm;
