import { DropdownOption, YOBulksheetOption } from '.';
import { GoalsDetails } from '.';

export const OPTIMIZATION_STRATEGYS = [
    'Max Profit',
    'Max Budget',
    'Max Conversions',
    '',
] as const;

export type OptimizationStrategy = (typeof OPTIMIZATION_STRATEGYS)[number];

export type ConversionType = 'Actions' | 'Revenue';

export type CalculationMethodType = 'estimatedIndex' | 'customWeight';

export interface UniversalSettingsFormState {
    userId: string;
    goalId: string | number;
    adjustHighDbFlag: boolean;
    budget: number;
    calculationMethod: CalculationMethodType;
    conversionColumn: string; // "action" value "impressions"
    conversionType: ConversionType; // 'Actions' | 'Revenue' ???
    conversionGoal: number; // what is this???? 99999
    customEndDate: string; // =>" 2023-03-29"
    customStartDate: string; //=> "2022-09-30"
    darwinLastNDays: number; // 0
    darwinLastNDaysLR: number; // 14
    dpadLastNWeeks: number; // 2
    efficiencyDenominator: string; // "action" value "impressions"
    efficiencyNumerator: string; // always "cost" ???
    efficiencyTarget: number; // 290
    excludeFlightDates: DropdownOption[]; // yield optimizer csv of dates (yyyy-mm-dd)
    flightStart: string; // "YYYY-MM-DD"
    flightEnd: string; // "YYYY-MM-DD"
    maxBid: string; // int "100"
    maxBidHeadRoom: number;
    maxDailyBudget: number;
    minBid: string; // float ".01"
    minBidHeadRoom: number; // 0
    minDailyBudget: number; // 10
    multipleTimePeriod: boolean; // false
    optimizationType: YOBulksheetOption | '';
    optInOutIntradayAlerts: boolean; // false
    optimizationStrategy: OptimizationStrategy;
    optimizingBudgetCapsForEfficiencyFlag: boolean; // false,
    revenueColumn: string; // what is this??? ""
    useWT: boolean; // false => Use Weighted Target -- Actual Target or Weighted Target
    phrasePercentBidFactor: number;
    broadPercentBidFactor: number;
}

export interface UniversalSettingFormOptions {
    agency: DropdownOption[];
    advertiser: DropdownOption[];
    engines: DropdownOption[];
    campaigns: DropdownOption[];
    conversionType: [
        { label: 'Actions'; value: 'Actions' },
        { label: 'Revenue'; value: 'Revenue' }
    ];
    revenueColumn: DropdownOption[];
    conversionColumn: DropdownOption[];
    efficiencyNumerator: DropdownOption[];
}

export interface UniversalSettingFormState {
    goal: DropdownOption;
    agency: DropdownOption;
    advertiser: DropdownOption;
    engines: DropdownOption;
    campaigns: DropdownOption;
    budget: number;
    flightStart: Date;
    flightEnd: Date;
    convsionType: DropdownOption;
    revenueColumn: DropdownOption;
    conversionColumn: DropdownOption;
    efficiencyNumerator: DropdownOption;
    efficiencyDenominator: DropdownOption;
    efficiencyTarget: number;
}

export interface UniversalSettingsState {
    userId: string;
    adjustHighDbFlag: boolean;
    budget: number;
    calculationMethod: string;
    conversionColumn: string;
    conversionGoal: number;
    conversionType: ConversionType | null;
    customStartDate: string;
    customEndDate: string;
    darwinLastNDays: number;
    darwinLastNDaysLR: number;
    dpadLastNWeeks: number;
    efficiencyDenominator: string;
    efficiencyNumerator: string;
    efficiencyTarget: number;
    excludeFlightDates: string; // ""
    flightEnd: string | null; // Date (YYYY-MM-DD)
    flightStart: string | null; // Date (YYYY-MM-DD)
    goalId: number | null;
    goals: GoalsDetails | null;
    maxBid: string; // number as string
    maxBidHeadRoom: number;
    maxDailyBudget: number;
    minBid: string; // float as string
    minBidHeadRoom: number;
    minDailyBudget: number;
    multipleTimePeriod: boolean;
    optimizationType: YOBulksheetOption | '';
    optInOutIntradayAlerts: boolean;
    optimizationStrategy: OptimizationStrategy;
    revenueColumn: string; // ""
    useWT: boolean;
    optimizingBudgetCapsForEfficiencyFlag: boolean;
    phrasePercentBidFactor: string; // 1-100 (MATCH TYPE ANALYSIS ONLY ELSE -> 0)
    broadPercentBidFactor: string; // 1-100 (MATCH TYPE ANALYSIS ONLY ELSE -> 0)
}
