import './Scheduled-Goals.scss';

import { useEffect, useState } from 'react';
import { ROUTES } from '../../router/router';
import { useNavigate } from 'react-router-dom';
import { useActions, useAppSelector } from '../../hooks';
import { AllSchedulesData, ExecutionWay } from '../../state';
import { DEFAULT_API_ERROR, FeatureIds } from '../../constants';

// API REQUESTS
import { getAllSchedules, getRunNowSchedules, getSchedules } from '../../api';

// UI COMPONENT
import AdminPage from '../../components/Admin-Page/Admin-Page';
import RunNowGoalsTable from '../../components/Charts/Run-Now-Goals-Table';
import ScheduledGoalsTable from '../../components/Charts/Scheduled-Goals-Table';
import AddScheduledGoal from '../../components/Add-Scheduled-Goal/Add-Scheduled-Goal';

const INITIAL_SCHEDULES_DATA: AllSchedulesData = {
    scheduled: [],
    runNow: [],
};

function ManageScheduledGoals() {
    const navigate = useNavigate();

    // USER'S EXISITNG SCHEDULED AND RUN NOW SCHEDULED GOALS
    const [schedules, setSchedules] = useState(INITIAL_SCHEDULES_DATA);

    // REDUX STATE
    const user = useAppSelector(({ auth }) => auth.user);
    const goalsList = useAppSelector(({ goals }) => goals.list);

    // REDUX ACTIONS
    const { loadGoalsList, alertError } = useActions();

    // FETCH AND STORE GOALS LIST & SCHEDULED/RUN NOW SCHEDULES LIST ON INITIAL PAGE LOAD
    useEffect(() => {
        if (
            !user?.features[FeatureIds.ALL] &&
            !user?.features[FeatureIds.SCHEDULE_GOALS]
        ) {
            navigate(ROUTES.HOME);
        }

        (async function () {
            if (!user) return;

            // FETCH USER'S GOALS LIST IF NOT ALREADY STORED IN REDUX
            if (!goalsList.length) {
                loadGoalsList(user);
            }

            try {
                const schedulesData = await getAllSchedules(user.id);

                setSchedules(schedulesData);
            } catch (error: any) {
                // ALERT ERROR MESSAGE ON ERROR
                alertError(
                    error.response?.data?.errorMessage ||
                        error.response?.data?.message ||
                        DEFAULT_API_ERROR
                );
            }
        })();
    }, [user]);

    // RELOAD SCHEDULES DATA WHEN ADDING NEW SCHEDULE
    const onAddSchedule = async (executionWay: ExecutionWay) => {
        if (!user) return;

        try {
            if (executionWay === 'schedule') {
                const { scheduleList: scheduled = [] } = await getSchedules(
                    user.id
                );

                setSchedules((prev) => ({ ...prev, scheduled }));
            }

            if (executionWay === 'runNow') {
                const { scheduleList: runNow = [] } = await getRunNowSchedules(
                    user.id
                );
                setSchedules((prev) => ({ ...prev, runNow }));
            }
        } catch (error: any) {
            alertError(
                error.response?.data?.errorMessage ||
                    error.response?.data?.message ||
                    DEFAULT_API_ERROR
            );
        }
    };

    // HANDLE RELOADING OF SCHEDULES ON EXISTING GOALS TABLE ACTION
    const onExistingTableAction = async () => {
        if (!user) return;

        try {
            // FETCH UPDATED SCHEDULED GOALS
            const { scheduleList: scheduled = [] } = await getSchedules(
                user.id
            );

            // STORE UPDATED SCHEDULED GOALS
            setSchedules((prev) => ({ ...prev, scheduled }));
        } catch (error: any) {
            alertError(
                error.response?.data?.errorMessage ||
                    error.response?.data?.message ||
                    DEFAULT_API_ERROR
            );
        }
    };

    return (
        <AdminPage>
            <div className="manage-scheduled-goals-container">
                <div className="manage-scheduled-goals-content">
                    <AddScheduledGoal
                        goals={goalsList}
                        onAddSchedule={onAddSchedule}
                    />
                    <ScheduledGoalsTable
                        data={schedules.scheduled}
                        onAction={onExistingTableAction}
                    />
                    <RunNowGoalsTable data={schedules.runNow} />
                </div>
            </div>
        </AdminPage>
    );
}

export default ManageScheduledGoals;
