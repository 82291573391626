import { capitalize } from '.';
import { ENVIRONMENT } from '../constants';
import {
    GoalSettings,
    GoalsListItem,
    POSTAddUniversalSettingsParams,
} from '../state';
import {
    UniversalSettingFormOptions,
    UniversalSettingsState,
} from '../state/models/universal-settings-models';

// FORM SELECTED GOAL FORM OPTIONS
export const formatGoalFormOptions = (
    goal: GoalsListItem,
    settings: GoalSettings
) => {
    const options: UniversalSettingFormOptions = {
        agency: [
            {
                label: goal.agencyName,
                value: goal.agencyId,
            },
        ],
        advertiser: [
            {
                label: goal.advertiserName,
                value: goal.advertiserId,
            },
        ],
        engines: goal.engineNames.split(',').map((name) => {
            return {
                label: name,
                value: name,
            };
        }),
        campaigns: goal.campaignNames.split(',').map((name, index) => {
            return {
                label: name,
                value: name + index,
            };
        }),
        conversionType: [
            { label: 'Actions', value: 'Actions' },
            { label: 'Revenue', value: 'Revenue' },
        ],
        revenueColumn: settings.selectData.revenue.map((column) => {
            return {
                label: capitalize(column),
                value: column,
            };
        }),
        conversionColumn: settings.selectData.action.map((column) => {
            return {
                label: capitalize(column),
                value: column,
            };
        }),
        efficiencyNumerator: [{ label: 'Cost', value: 'cost' }],
    };
    return options;
};

// FORMAT GOAL SETTINGS TO UNIVERSAL SETTINGS STATE
export const formatAddGoalSettingsParams = (
    universalSettings: UniversalSettingsState,
    isMatchType: boolean = false
) => {
    // REMOVE PROPERTIES: createdAt, isActive, & goals properties
    const params = Object.keys(universalSettings)
        .filter(
            (key) =>
                key !== 'goals' && key !== 'createdAt' && key !== 'isActive'
        )
        .reduce((obj: any, key) => {
            obj[key] = universalSettings[key as keyof UniversalSettingsState];
            return obj;
        }, {}) as POSTAddUniversalSettingsParams;

    params.environment = ENVIRONMENT;

    params.broadPercentBidFactor = isMatchType
        ? parseInt(universalSettings.broadPercentBidFactor) || 0
        : 0;
    params.phrasePercentBidFactor = isMatchType
        ? parseInt(universalSettings.phrasePercentBidFactor) || 0
        : 0;

    return params;
};
