import './Bulk-Actions.scss';

import { ROUTES } from '../../router/router';
import { useLocation } from 'react-router-dom';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';

// API REQUESTS
import {
    downloadScenarioAdhoc,
    downloadScenarioReport,
    downloadDiagnosticAdhoc,
    downloadDiagnosticsReport,
} from '../../api';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import MoreMenu from '@publicismedia-ds/ui-more-menu';
import AdhocAnalysisModal from '../Adhoc-Analysis-Modal/Adhoc-Analysis-Modal';

// CONSTANTS
import { DEFAULT_API_ERROR, TODAY } from '../../constants';

// FUNCTIONAL COMPONENT
function BulkActions() {
    // HOOKS
    const { pathname } = useLocation();
    const wrapper = useAsyncWrapper();

    const isAdhocData = useAppSelector(
        ({ adhocModal }) => !!adhocModal.denominator
    );
    const { user } = useAppSelector((state) => state.auth);
    const { userId, goals, optimizationStrategy } = useAppSelector(
        (state) => state.universalSettings
    );

    const { setDisplayAdhocModal } = useActions();

    // HANLDLE BULKSHEET DOWNLOAD
    const onDownloadReport = wrapper(async () => {
        if (!goals) return; // -> NO GOAL SELECTED
        const { goalId, goalMappingName } = goals;

        try {
            // DOWNLOAD SCENARIO PLANNER BULKSHEET
            if (pathname === ROUTES.SCENARIO_PLANNER) {
                const fileName = `Scenario_${goalMappingName}_${TODAY}.xlsx`;
                await downloadScenarioReport(
                    userId,
                    goalId,
                    optimizationStrategy || 'Max Budget',
                    fileName
                );
            }

            // DOWNLAOD PERFORMANCE DIAGNOSTIC BULKSHEET
            if (pathname === ROUTES.PERFORMANCE_DIAGNOSTIC) {
                const fileName = `Performance_Diagnostic_${goalMappingName}_${TODAY}.xlsx`;

                await downloadDiagnosticsReport(userId, goalId, fileName);
            }
        } catch (error) {
            throw new Error(DEFAULT_API_ERROR);
        }
    });

    // HANDLE ADHOC BULKSHEET DOWNLOAD
    const onDownloadAdhocBulksheet = wrapper(async () => {
        try {
            // DOWNLOAD SCENARIO PLANNER BULKSHEET
            if (pathname === ROUTES.SCENARIO_PLANNER) {
                const fileName = `Scenario_Planner_Adhoc_${TODAY}.xlsx`;
                await downloadScenarioAdhoc(userId, fileName);
            }

            // DOWNLAOD PERFORMANCE DIAGNOSTIC BULKSHEET
            if (pathname === ROUTES.PERFORMANCE_DIAGNOSTIC) {
                const fileName = `Performance_Diagnostics_Adhoc_${TODAY}.xlsx`;
                await downloadDiagnosticAdhoc(userId, fileName);
            }
        } catch (error) {
            throw new Error(DEFAULT_API_ERROR);
        }
    });

    return (
        <div className="bulk-actions-container">
            <Button
                color="brand-2"
                disabled={!goals?.goalMappingName}
                onClick={onDownloadReport}
            >
                Download Report
            </Button>
            {user && user.roleId !== '4' && isAdhocData && (
                <>
                    <MoreMenu
                        trigger={
                            <Button color="brand-2">Ad hoc Analysis</Button>
                        }
                    >
                        <button onClick={() => setDisplayAdhocModal(true)}>
                            Run Analysis
                        </button>
                        <button onClick={onDownloadAdhocBulksheet}>
                            Download Adhoc Bulksheet
                        </button>
                    </MoreMenu>
                    <AdhocAnalysisModal />
                </>
            )}
        </div>
    );
}

export default BulkActions;
