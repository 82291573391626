import { useState } from 'react';

// TYPES
import {
    DropdownOption,
    KeywordMetric,
    KEYWORD_METRICS,
    MoMKeywordPerformanceData,
    YoYKeywordPerformanceData,
} from '../../../../state';

// UI COMPONENTS
import Dropdown from '@publicismedia-ds/ui-dropdown';
import KeywordChart from '../../../../components/Charts/Anomaly-Charts/Keyword-Chart';
import KeywordGraph from '../../../../components/Charts/Anomaly-Charts/Keyword-Graph';

// UTILS
import {
    formatMOMData,
    formatYOYData,
} from '../../../../utils/performance-monitor-utils';

// COMPONENT PROPS
interface KeywordPerformanceProps {
    dataMOM?: MoMKeywordPerformanceData;
    dataYOY?: YoYKeywordPerformanceData;
    conversion: string;
}

// FUNCTIONAL COMPONENT (KEYWORD PERFORMANCE)
function KeywordPerformance({
    dataMOM,
    dataYOY,
    conversion,
}: KeywordPerformanceProps) {
    const [selectedMetric, setSelectedMetric] =
        useState<KeywordMetric>('Clicks');

    const DROPDOWN_OPTIONS = KEYWORD_METRICS.map((metric) => {
        let label: string = metric;
        if (metric === 'Impr') label = 'Impressions';
        if (metric === 'Conversions') label = `Conversions (${conversion})`;
        return {
            label,
            value: metric,
        };
    });

    const formattedMOMData = dataMOM
        ? {
              top20: formatMOMData(
                  dataMOM[selectedMetric]['Top 20'],
                  selectedMetric
              ),
              bottom20: formatMOMData(
                  dataMOM[selectedMetric]['Bottom 20'],
                  selectedMetric
              ),
              priorDate: dataMOM.Date.MOMDate[0].last_to_last_month_date,
              recentDate: dataMOM.Date.MOMDate[0].last_month_date,
          }
        : null;

    const formattedYOYData = dataYOY
        ? {
              top20: formatYOYData(
                  dataYOY[selectedMetric]['Top 20'],
                  selectedMetric
              ),
              bottom20: formatYOYData(
                  dataYOY[selectedMetric]['Bottom 20'],
                  selectedMetric
              ),
              priorDate: dataYOY.Date.YOYDate[0].last_to_last_year_date,
              recentDate: dataYOY.Date.YOYDate[0].last_year_date,
          }
        : null;

    return (
        <>
            <Dropdown
                options={DROPDOWN_OPTIONS}
                value={selectedMetric}
                onChange={(selected: DropdownOption) =>
                    setSelectedMetric(selected.value as KeywordMetric)
                }
            >
                Select Metric
            </Dropdown>
            <div className="keyword-chart">
                <h3>Top-20 Keyword Performance Chart</h3>
                {!!formattedMOMData?.top20.length && (
                    <KeywordChart
                        data={formattedMOMData.top20}
                        priorDate={formattedMOMData.priorDate}
                        recentDate={formattedMOMData.recentDate}
                    />
                )}
                {!!formattedYOYData?.top20.length && (
                    <KeywordChart
                        data={formattedYOYData.top20}
                        priorDate={formattedYOYData.priorDate}
                        recentDate={formattedYOYData.recentDate}
                    />
                )}
                {!formattedMOMData?.top20.length &&
                    !formattedYOYData?.top20.length && <p>No data available</p>}
            </div>
            <div className="keyword-chart">
                <h3>Bottom-20 Keyword Performance Chart</h3>
                {!!formattedMOMData?.bottom20.length && (
                    <KeywordChart
                        data={formattedMOMData.bottom20}
                        priorDate={formattedMOMData.priorDate}
                        recentDate={formattedMOMData.recentDate}
                    />
                )}
                {!!formattedYOYData?.bottom20.length && (
                    <KeywordChart
                        data={formattedYOYData.bottom20}
                        priorDate={formattedYOYData.priorDate}
                        recentDate={formattedYOYData.recentDate}
                    />
                )}
                {!formattedMOMData?.bottom20.length &&
                    !formattedYOYData?.bottom20.length && (
                        <p>No data available</p>
                    )}
            </div>
            <div className="keyword-chart">
                <h3>top-20 keyword performance graph</h3>
                {!!formattedMOMData?.top20.length && (
                    <KeywordGraph
                        data={formattedMOMData.top20}
                        priorDate={formattedMOMData.priorDate}
                        recentDate={formattedMOMData.recentDate}
                    />
                )}
                {!!formattedYOYData?.top20.length && (
                    <KeywordGraph
                        data={formattedYOYData.top20}
                        priorDate={formattedYOYData.priorDate}
                        recentDate={formattedYOYData.recentDate}
                    />
                )}
                {!formattedMOMData?.top20.length &&
                    !formattedYOYData?.top20.length && (
                        <p>No chart data available</p>
                    )}
            </div>
            <div className="keyword-chart">
                <h3>bottom-20 keyword performance graph</h3>
                {!!formattedMOMData?.bottom20.length && (
                    <KeywordGraph
                        data={formattedMOMData.bottom20}
                        priorDate={formattedMOMData.priorDate}
                        recentDate={formattedMOMData.recentDate}
                    />
                )}
                {!!formattedYOYData?.bottom20.length && (
                    <KeywordGraph
                        data={formattedYOYData.bottom20}
                        priorDate={formattedYOYData.priorDate}
                        recentDate={formattedYOYData.recentDate}
                    />
                )}
                {!formattedMOMData?.bottom20.length &&
                    !formattedYOYData?.bottom20.length && (
                        <p>No chart data available</p>
                    )}
            </div>
        </>
    );
}

export default KeywordPerformance;
