import {
    DropdownOption,
    sa360CronFormReducer,
    GETRolesAccountBrands,
    KEYWORD_EVENT_LIST_1,
    KEYWORD_EVENT_LIST_2,
    INITIAL_SA360_CRON_FORM,
    SA360_CRON_ACTION_TYPES,
} from '../../state';
import { useActions } from '../../hooks';
import { DEFAULT_API_ERROR } from '../../constants';
import React, { useEffect, useMemo, useReducer } from 'react';
import { findDeselectedOption, onPreventEnterKeySubmit } from '../../utils';

// UI COMPONENTS
import Radio from '@publicismedia-ds/ui-radio';
import Button from '@publicismedia-ds/ui-button';
import Fieldset from '@publicismedia-ds/ui-fieldset';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import Datepicker from '@publicismedia-ds/ui-datepicker';

// COMPONENT PROPS
interface SA360CronFormProps {
    data: GETRolesAccountBrands | null;
    onSubmit: (formState: any) => Promise<void>;
}

function SA360CronForm({ data, onSubmit }: SA360CronFormProps) {
    // REDUX ACTIONS
    const { alertError } = useActions();

    // SA 360 CRON FORM STATE
    const [state, dispatch] = useReducer(
        sa360CronFormReducer,
        INITIAL_SA360_CRON_FORM
    );

    // LOAD FORM OPTIONS
    useEffect(() => {
        if (!data) return;
        dispatch({
            type: SA360_CRON_ACTION_TYPES.LOAD_OPTIONS_DATA,
            payload: data,
        });
    }, [data]);

    // HANDLE REPORT TYPE SELECTION
    const onSelectReportType = (selected: DropdownOption) => {
        dispatch({
            type: SA360_CRON_ACTION_TYPES.SET_SELECTED_REPORT,
            payload: selected,
        });
    };

    // HANDLE AGENCY SELECTION
    const onSelectAgency = (selections: DropdownOption[]) => {
        // AGENCY SELECTION WAS ADDED
        if (selections.length > state.selectedAgencies.length) {
            dispatch({
                type: SA360_CRON_ACTION_TYPES.ADD_SELECTED_AGENCY,
                payload: selections[selections.length - 1],
            });
            return;
        }

        // AGENCY SELECTION WAS REMOVED
        const removedAgency = findDeselectedOption(
            selections,
            state.selectedAgencies
        );

        if (removedAgency) {
            dispatch({
                type: SA360_CRON_ACTION_TYPES.REMOVE_SELECTED_AGENCY,
                payload: removedAgency,
            });
            return;
        }
    };

    // HANDLE ADVERTISER SELECTION
    const onSelectAdvertiser = (selections: DropdownOption[]) => {
        // ADVERTISER SELECTION WAS ADDED
        if (selections.length > state.selectedAdvertisers.length) {
            dispatch({
                type: SA360_CRON_ACTION_TYPES.ADD_SELECTED_ADVERTISER,
                payload: selections[selections.length - 1],
            });
            return;
        }

        // ADVERTISER SELECTION WAS REMOVED
        const removedAdvertiser = findDeselectedOption(
            selections,
            state.selectedAdvertisers
        );

        if (removedAdvertiser) {
            dispatch({
                type: SA360_CRON_ACTION_TYPES.REMOVE_SELECTED_ADVERTISER,
                payload: removedAdvertiser,
            });
            return;
        }
    };

    // SET YESTERDAY AS MAX FLIGHT START/END DATE (CAMPAIGN REPORT ONLY)
    const yesterday = useMemo(() => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return date;
    }, []);

    // HANDLE SETTING FLIGHT START DATE (CAMPAIGN REPORT ONLY)
    const onSetFlightStart = (date: Date) => {
        dispatch({
            type: SA360_CRON_ACTION_TYPES.SET_FLIGHT_START,
            payload: date,
        });
    };

    // HANDLE SETTING FLIGHT END DATE (CAMPAIGN REPORT ONLY)
    const onSetFlightEnd = (date: Date) => {
        dispatch({
            type: SA360_CRON_ACTION_TYPES.SET_FLIGHT_END,
            payload: date,
        });
    };

    // HANDLE SETTING TIME FLAG (NOT CAMPAIGN REPORT)
    const onSetTimeFlag = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: SA360_CRON_ACTION_TYPES.SET_TIME_FLAG,
            payload: parseInt(evt.target.value),
        });
    };

    // ENABLE/DISABLE SUBMIT BUTTON
    const isSubmitDisabled =
        !state.selectedReport.value ||
        !state.selectedAgencies.length ||
        !state.selectedAdvertisers.length;

    // HANDLE SA 360 CRON SUBMIT
    const onSubmitSA360Cron = async (evt: React.FormEvent) => {
        evt.preventDefault();

        try {
            await onSubmit(state);
            dispatch({
                type: SA360_CRON_ACTION_TYPES.RESET_SA360_CRON_FORM,
            });
        } catch (error: any) {
            alertError(
                error.response?.data?.message ||
                    error.response?.data?.errorMessage ||
                    DEFAULT_API_ERROR
            );
        }
    };

    return (
        <div className="cron-form-container">
            <form
                onSubmit={onSubmitSA360Cron}
                onKeyUp={onPreventEnterKeySubmit}
            >
                <div className="form-row">
                    <Dropdown
                        className="form-row-item"
                        options={state.reportOptions}
                        value={
                            state.selectedReport.value
                                ? state.selectedReport
                                : ''
                        }
                        defaultValue={
                            state.selectedReport.value
                                ? state.selectedReport
                                : ''
                        }
                        onChange={onSelectReportType}
                        required
                    >
                        Report Type
                    </Dropdown>
                    <Dropdown
                        className="form-row-item"
                        options={state.agencyOptions}
                        value={state.selectedAgencies}
                        defaultValue={state.selectedAgencies}
                        onChange={onSelectAgency}
                        multiple={true}
                        display="selectionInline"
                        required
                    >
                        Agencies
                    </Dropdown>
                    <Dropdown
                        className="form-row-item"
                        options={state.advertiserOptions}
                        value={state.selectedAdvertisers}
                        defaultValue={state.selectedAdvertisers}
                        onChange={onSelectAdvertiser}
                        multiple={true}
                        display="selectionInline"
                        required
                    >
                        Advertisers
                    </Dropdown>
                </div>
                <div className="form-row">
                    {/* --- DISPLAY ONLY FOR CAMPAIGN REPORT --- */}
                    {state.selectedReport.value === 'Campaign' && (
                        <>
                            <Datepicker
                                className="form-row-item"
                                maxDate={yesterday}
                                onChange={onSetFlightStart}
                                value={state.flightStart}
                            >
                                Flight Start
                            </Datepicker>
                            <Datepicker
                                className="form-row-item"
                                maxDate={yesterday}
                                minDate={state.flightStart}
                                onChange={onSetFlightEnd}
                                value={state.flightEnd}
                            >
                                Flight End
                            </Datepicker>
                        </>
                    )}

                    {/* --- DISPLAY FOR ALL OTHER REPORTS (NON CAMPAIGN) */}
                    {state.selectedReport.value !== 'Campaign' && (
                        <>
                            <Fieldset
                                layout="horizontal"
                                className="form-row-item"
                            >
                                {state.selectedReport.value === 'Keyword'
                                    ? KEYWORD_EVENT_LIST_1.map((option) => {
                                          return (
                                              <Radio
                                                  className="form-row-item"
                                                  name="timeFlag"
                                                  value={option.value}
                                                  checked={
                                                      state.timeFlag ===
                                                      option.value
                                                  }
                                                  onChange={onSetTimeFlag}
                                                  key={option.label}
                                                  invert={true}
                                              >
                                                  {option.label}
                                              </Radio>
                                          );
                                      })
                                    : KEYWORD_EVENT_LIST_2.map((option) => {
                                          return (
                                              <Radio
                                                  className="form-row-item"
                                                  name="timeFlag"
                                                  value={option.value}
                                                  checked={
                                                      state.timeFlag ===
                                                      option.value
                                                  }
                                                  onChange={onSetTimeFlag}
                                                  key={option.label}
                                                  invert={true}
                                              >
                                                  {option.label}
                                              </Radio>
                                          );
                                      })}
                            </Fieldset>
                        </>
                    )}
                </div>
                <div className="form-row">
                    <ul style={{ color: 'red' }}>
                        <li>Note:</li>
                        <li>
                            1. If multiple reports are being fetched at a time,
                            then reports will be fetched in sequential order.
                        </li>
                    </ul>
                </div>
                <div
                    className="form-row"
                    style={{
                        justifyContent: 'center',
                    }}
                >
                    <Button type="submit" disabled={isSubmitDisabled}>
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default SA360CronForm;
