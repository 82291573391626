import {
    AgencyItem,
    AccountData,
    DropdownOption,
    CustomAlertType,
} from '../../models';

// CUSTOM ALERT FORM STATE
export interface CustomAlertState {
    agencies: AgencyItem[];
    accountData: AccountData | null;
    users: DropdownOption[];
    goals: DropdownOption[];
    agency: DropdownOption | null;
    advertiser: DropdownOption | null;
    alertType: CustomAlertType | null;
    xValue: string;
    yValue: string;
    minBid: string;
    maxBid: string;
}

export enum CUSTOM_ALERT_ACTION_TYPES {
    LOAD_AGENCIES = 'load_agencies',
    SET_AGENCY = 'set_agency',
    SET_ADVERTISER = 'set_advertiser',
    SET_GOALS = 'set_goals',
    SET_USERS = 'set_users',
    SET_ALERT_TYPE = 'set_alert_type',
    SET_X_VALUE = 'set_x_value',
    SET_Y_VALUE = 'set_y_value',
    SET_MIN_BID = 'set_min_bid',
    SET_MAX_BID = 'set_max_bid',
    LOAD_EXISTING_ALERT = 'load_existing_alert',
    RESET_FORM = 'reset_form',
}

interface LoadCustomAlertAgenciesAction {
    type: CUSTOM_ALERT_ACTION_TYPES.LOAD_AGENCIES;
    payload: AgencyItem[];
}

interface SetCustomAlertAgencyAction {
    type: CUSTOM_ALERT_ACTION_TYPES.SET_AGENCY;
    payload: {
        agency: DropdownOption;
        accountData: AccountData | null;
    };
}

interface SetCustomAlertAdvertiserAction {
    type: CUSTOM_ALERT_ACTION_TYPES.SET_ADVERTISER;
    payload: DropdownOption;
}
interface SetCustomAlertGoalsAction {
    type: CUSTOM_ALERT_ACTION_TYPES.SET_GOALS;
    payload: DropdownOption[];
}
interface SetCustomAlertUsersAction {
    type: CUSTOM_ALERT_ACTION_TYPES.SET_USERS;
    payload: DropdownOption[];
}
interface SetCustomAlertTypeAction {
    type: CUSTOM_ALERT_ACTION_TYPES.SET_ALERT_TYPE;
    payload: CustomAlertType | null;
}
interface SetCustomAlertXValueAction {
    type: CUSTOM_ALERT_ACTION_TYPES.SET_X_VALUE;
    payload: string;
}
interface SetCustomAlertYValueAction {
    type: CUSTOM_ALERT_ACTION_TYPES.SET_Y_VALUE;
    payload: string;
}
interface SetCustomAlertMinBidAction {
    type: CUSTOM_ALERT_ACTION_TYPES.SET_MIN_BID;
    payload: string;
}
interface SetCustomAlertMaxBidAction {
    type: CUSTOM_ALERT_ACTION_TYPES.SET_MAX_BID;
    payload: string;
}
interface ResetCustomAlertFormAction {
    type: CUSTOM_ALERT_ACTION_TYPES.RESET_FORM;
}
interface LoadExistingCustomAlertAction {
    type: CUSTOM_ALERT_ACTION_TYPES.LOAD_EXISTING_ALERT;
    payload: CustomAlertState;
}

export type CustomAlertAction =
    | LoadCustomAlertAgenciesAction
    | SetCustomAlertAgencyAction
    | SetCustomAlertAdvertiserAction
    | SetCustomAlertGoalsAction
    | SetCustomAlertUsersAction
    | SetCustomAlertTypeAction
    | SetCustomAlertXValueAction
    | SetCustomAlertYValueAction
    | SetCustomAlertMinBidAction
    | SetCustomAlertMaxBidAction
    | ResetCustomAlertFormAction
    | LoadExistingCustomAlertAction;
