import { axios } from '../axios-config';

// UTILS
import { saveDownloadFile } from '../../utils';

// CONSTANTS
import { API_MATCH_TYPE_ANALYSIS, TODAY } from '../../constants';

export const downloadMatchTypeAnalysis = async (
    userId: string,
    goaldId: string,
    goalName: string
) => {
    const filename = `Match_Type_Strategy_Analysis_${goalName}_${TODAY}.xlsx`;
    const url = API_MATCH_TYPE_ANALYSIS;

    const body = new URLSearchParams();
    body.append('goalId', goaldId);
    body.append('userId', userId);

    try {
        const blob = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            responseType: 'blob',
        });

        saveDownloadFile(blob, filename);
        return 'File downloaded successfully.';
    } catch (error: any) {
        const response = await error.response?.data?.text();
        const { errorMessage } = JSON.parse(response);

        if (errorMessage) {
            throw new Error(errorMessage);
        } else {
            throw error;
        }
    }
};
