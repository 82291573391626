import { axios } from '../axios-config';

// TYPES
import { GetUserActivity, UserActivityFormState } from '../../state';

// CONSTANTS
import { API_USERS, DEFAULT_API_ERROR } from '../../constants';

// UTILS
import {
    removeSelectAll,
    saveDownloadFile,
    removeSpecialChars,
    convertDateToString,
} from '../../utils';

// FETCH USER ACTIVITY TABLE DATA
export const getUserActivity = async (form: UserActivityFormState) => {
    const { users, goals, fromDate, toDate } = form;

    const url = API_USERS + '/getVisitorsData';

    const userIds = removeSelectAll(users)
        .map(({ value }) => value)
        .join(',');

    const goalId = removeSelectAll(goals)
        .map(({ value }) => value)
        .join(',');

    const body = new URLSearchParams();
    body.append('userIds', userIds);
    body.append('goalId', goalId);
    body.append('fromDate', fromDate ? convertDateToString(fromDate) : '');
    body.append('toDate', toDate ? convertDateToString(toDate) : '');

    const { data } = await axios.post<GetUserActivity>(url, body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

    return data;
};

// DOWNLOAD USER ACTIVITY BULKSHEET
export const downloadUserActivity = async (form: UserActivityFormState) => {
    const { users, goals, fromDate, toDate } = form;

    const url = API_USERS + '/downloadVisitorBulksheet';

    const userIds = removeSelectAll(users)
        .map(({ value }) => value)
        .join(',');

    const goalId = removeSelectAll(goals)
        .map(({ value }) => value)
        .join(',');

    const body = new URLSearchParams();
    body.append('userIds', userIds);
    body.append('goalId', goalId);
    body.append('fromDate', fromDate ? convertDateToString(fromDate) : '');
    body.append('toDate', toDate ? convertDateToString(toDate) : '');

    try {
        const blob = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            responseType: 'blob',
        });

        const data = await blob.data.text();

        if (data.length < 100) {
            const { msg, status } = JSON.parse(data);

            if (status === 'failure') {
                throw new Error(msg || DEFAULT_API_ERROR);
            }
        }

        saveDownloadFile(
            blob,
            `Visitors_Details_${new Date().toLocaleDateString('en', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
            })}.xlsx`
        );
    } catch (error: any) {
        if (error.response) {
            const response = await error.response?.data?.text();
            const { errorMessage } = JSON.parse(response);

            throw new Error(errorMessage);
        } else {
            throw new Error(error.message);
        }
    }
};
