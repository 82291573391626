import { axios } from '../axios-config';
import { saveDownloadFile } from '../../utils/download-file';
import { formatAdwordsCronBody, formatRerunCronBody } from '../../utils';

// CONSTANTS
import {
  API_CUBIC_DC3,
  API_DIAGNOSTICS,
  ADHOC_TEMPLATES,
  API_REPORT_TEMPLATE,
  API_INTRA_DAY_SETTINGS,
  API_UNIVERSAL_SETTINGS,
  API_ADHOC_UPLOAD_STATUS,
} from '../../constants';

// TYPES
import {
  CronStatusData,
  AdwordsCronFormState,
  GETAdhocUploadStatus,
  GETAdhocDataAvailable,
  UploadAndParseResponse,
} from '../../state';

// CHECK ADHOC DATA UPLOAD STATUS (SP & PD)
export const getAdhocUploadStatus = async (userId: string) => {
  //   try {
  const { data } = await axios.get<GETAdhocUploadStatus>(
    API_ADHOC_UPLOAD_STATUS,
    {
      params: { userId },
    }
  );
  return data;
  //   } catch (error) {
  //     throw new Error('Unable to update ad hoc data status.');
  //   }
};

// CHECK IF ADHOC DATA IS AVAILBLE FOR SCENARIO-PLANNER / PERFORMANCE-DIAGNOSTIC
export const getAdhocDataAvailable = async (userId: string) => {
  try {
    const url = API_UNIVERSAL_SETTINGS + '/isAdhocDataAvailable';
    const { data } = await axios.get<GETAdhocDataAvailable>(url, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      params: {
        userId,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const addADPSettings = async (userId: string, goalId: string) => {
  try {
    const url = API_INTRA_DAY_SETTINGS + '/addIntradayGoalSettings';
    const { data } = await axios.post(
      url,
      {},
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
          userId,
          goalId,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const downloadReportTemplate = async (templateName: string) => {
  const url = API_UNIVERSAL_SETTINGS + '/downloadReportTemplate';

  const fileName = templateName + '.xlsx';

  try {
    const blob = await axios.get(url, {
      params: { file: templateName },
      responseType: 'blob',
    });

    saveDownloadFile(blob, fileName);
  } catch (error) {
    throw error;
  }
};

export const uploadAndMergeCustomColumns = async (
  userId: string,
  formState: AdwordsCronFormState
) => {
  const url = API_CUBIC_DC3 + '/Adwords/uploadAndMergeCustomColumns';

  const formattedBody = formatAdwordsCronBody(userId, formState);

  if (!formattedBody) return;

  const body = new FormData();
  body.append('agencyId', formattedBody.agencyId);
  body.append('subAccountId', formattedBody.subAccountId);
  body.append('reportType', formattedBody.reportType);
  body.append('userId', userId);
  body.append('file', formattedBody.file);

  try {
    const { data } = await axios.post(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getadhocDiagnostic = async (userId: string) => {
  try {
    const url = API_DIAGNOSTICS + '/Diagnostics/adhocDiagnostic';
    const { data } = await axios.post(
      url,
      {},
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
          userId,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export interface GetMenualAdhocDiagnosticParams {
  userId: string;
  efficiencyTarget: any;
  efficiencyNumerator: any;
  efficiencyDenominator: any;
}

export const getMenualAdhocDiagnostic = async (
  params: GetMenualAdhocDiagnosticParams
) => {
  try {
    const url = API_DIAGNOSTICS + '/Diagnostics/adhocDiagnostic';
    const { data } = await axios.post(
      url,
      {},
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params,
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export interface GetDownloadChangeSummaryLogsParams {
  userId: string;
  goalId: any;
  date: any;
  time: any;
  download: any;
  bulksheetType: any;
}
export const getDownloadChangeSummaryLogs = async (
  params: GetDownloadChangeSummaryLogsParams
) => {
  try {
    const url = API_UNIVERSAL_SETTINGS + '/getChangeSummaryLogs';
    const blob = await axios.get(url, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      params,
      responseType: 'blob',
    });
    return blob;
  } catch (error) {
    throw error;
  }
};

export const uploadAdhocFiles = async (
  adGroupFile: File,
  keyProdDSAFile: File,
  userId: string | number
) => {
  try {
    const url = API_CUBIC_DC3 + '/DoubleClick/uploadAndParse';

    const body = new FormData();
    body.append('AdGroupReport', adGroupFile);
    body.append('KeywordReport', keyProdDSAFile);
    body.append('userId', userId.toString());

    const { data } = await axios.post<UploadAndParseResponse>(url, body, {
      timeout: 8000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return data;
  } catch (error: any) {
    if (error.code === 'ECONNABORTED') {
      return {
        result: 'in progress',
        msg: 'The upload is in progress. Please check the status in the table below.',
        conversionType: null,
      };
    } else {
      throw error;
    }
  }
};

export const rerunFailedCron = async (cron: CronStatusData) => {
  const url = API_CUBIC_DC3 + '/DoubleClick/manualDataFetch';

  const formattedData = formatRerunCronBody(cron);

  const body = new URLSearchParams({ ...formattedData });

  try {
    const { data } = await axios.post(url, body, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
    return data;
  } catch (error: any) {
    throw error;
  }
};

export const downloadAdhocTemplate = async (template: ADHOC_TEMPLATES) => {
  const fileName = `${template}_template.xlsx`;
  const url = API_REPORT_TEMPLATE;

  try {
    const blob = await axios.get(url, {
      params: { file: template },
      responseType: 'blob',
    });
    saveDownloadFile(blob, fileName);
  } catch (error: any) {
    throw error;
  }
};
