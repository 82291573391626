import {
    ConversionType,
    DropdownOption,
    OptimizationStrategy,
    CalculationMethodType,
    UniversalSettingsState,
    YOBulksheetOption,
} from '../models';

import { UNIVERSAL_SETTINGS_ACTION_TYPES } from '../action-types';

import {
    LoadSettingsAction,
    UpdateBudgetAction,
    UpdateFlightEndAction,
    UpdateLastNDaysAction,
    UpdateMinimumBidAction,
    UpdateMaximumBidAction,
    UpdateLastNWeeksAction,
    UpdateFlightStartAction,
    UpdateLastNDaysLRAction,
    UpdateRevenueColumnAction,
    UpdateCustomEndDateAction,
    UpdateConversionTypeAction,
    UpdateCustomStartDateAction,
    UpdateUseWeightedFlagAction,
    UpdateAdjustHighDbFlagAction,
    UpdateEfficiencyTargetAction,
    UpdateConversionColumnAction,
    UpdateOptimizationTypeAction,
    UpdateCalculationMethodAction,
    UpdateBroadPercentFactorAction,
    UpdateMultipleTimePeriodAction,
    UpdateMinimumBidHeadroomAction,
    UpdateMaximumBidHeadroomAction,
    UpdateMinimumDailyBudgetAction,
    UpdateMaximumDailyBudgetAction,
    UpdateOptimizeBudgetCapsAction,
    UpdateExcludeFlightDatesAction,
    UpdateEfficiencyNumeratorAction,
    UpdatePhrasePercentFactorAction,
    UpdateOptimizationStrategyAction,
    UpdateEfficiencyDenominatorAction,
} from '../actions';

// LOAD CURRENT SETTINGS
export const loadCurrentSettings = (
    currentSettings: UniversalSettingsState
): LoadSettingsAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.LOAD_SETTINGS,
        payload: {
            value: currentSettings,
        },
    };
};

// UPDATED BUDGET VALUE
export const updateBudget = (budget: number): UpdateBudgetAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_BUDGET,
        payload: {
            value: budget,
        },
    };
};

// UPDATE FLIGHT START DATE VALUE
export const updateFlightStart = (startDate: Date): UpdateFlightStartAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_FLIGHT_START,
        payload: {
            value: startDate.toDateString().slice(4),
        },
    };
};

// UPDATE FLIGHT END DATE VALUE
export const updateFlightEnd = (endDate: Date): UpdateFlightEndAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_FLIGHT_END,
        payload: {
            value: endDate.toDateString().slice(4),
        },
    };
};

// UPDATE SELECTED CONVERSION TYPE
export const updateConversionType = (
    conversionType: ConversionType
): UpdateConversionTypeAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_CONVERSION_TYPE,
        payload: {
            value: conversionType,
        },
    };
};

// UPDATE CUSTOM START DATE (SP & PD ONLY)
export const updateCustomStartDate = (
    date: Date
): UpdateCustomStartDateAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_CUSTOM_START_DATE,
        payload: {
            value: date.toDateString().slice(4),
        },
    };
};

// UPDATE CUSTOM END DATE (SP & PD ONLY)
export const updateCustomEndDate = (date: Date): UpdateCustomEndDateAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_CUSTOM_END_DATE,
        payload: {
            value: date.toDateString().slice(4),
        },
    };
};

// UPDATE SELECTED REVENUE COLUMN
export const updateRevenueColumn = (
    value: string
): UpdateRevenueColumnAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_REVENUE_COLUMN,
        payload: {
            value,
        },
    };
};

// UPDATE SELECTED CONVERSION COLUMN
export const updateConversionColumn = (
    value: string
): UpdateConversionColumnAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_CONVERSION_COLUMN,
        payload: {
            value,
        },
    };
};

// UPDATE SELECTED EFFICIENCY Numerator
export const updateEfficiencyNumerator = (
    value: string
): UpdateEfficiencyNumeratorAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_EFFICIENCY_NUMERATOR,
        payload: {
            value,
        },
    };
};

// UPDATE SELECTED EFFICIENCY DENOMINATOR
export const updateEfficiencyDenominator = (
    value: string
): UpdateEfficiencyDenominatorAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_EFFICIENCY_DENOMINATOR,
        payload: {
            value,
        },
    };
};

// UPDATE EFFICIENCY TARGET VALUE
export const updateEfficiencyTarget = (
    efficiencyTarget: number
): UpdateEfficiencyTargetAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_EFFICIENCY_TARGET,
        payload: {
            value: efficiencyTarget,
        },
    };
};

// UPDATE LAST N WEEKS VALUE
export const updateLastNDays = (lastNDays: number): UpdateLastNDaysAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_LAST_N_DAYS,
        payload: {
            value: lastNDays,
        },
    };
};

// UPDATE LAST N WEEKS LONG RUN
export const updateLastNDaysLR = (
    lastNDaysLR: number
): UpdateLastNDaysLRAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_LAST_N_DAYS_LR,
        payload: {
            value: lastNDaysLR,
        },
    };
};

// UPDATE LAST N WEEKS VALUE
export const updateLastNWeeks = (
    lastNweeks: number
): UpdateLastNWeeksAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_LAST_N_WEEKS,
        payload: {
            value: lastNweeks,
        },
    };
};

// UPDATE FLIGHT DATE EXCLUSIONS
export const updateExcludeFlightDates = (
    selected: DropdownOption[]
): UpdateExcludeFlightDatesAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_EXCLUDE_FLIGHT_DATES,
        payload: selected,
    };
};

// UPDATE MULTIPLE TIME PERIOD
export const updateMultipleTimePeriod = (
    isChecked: boolean
): UpdateMultipleTimePeriodAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_MULTIPLE_TIME_PERIOD,
        payload: {
            value: isChecked,
        },
    };
};

// UPDATE CALCULATION METHOD
export const updateCalculationMethod = (
    method: CalculationMethodType
): UpdateCalculationMethodAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_CALCULATION_METHOD,
        payload: {
            value: method,
        },
    };
};

// UPDATE OPTIMIZATION TYPE (YO SETTING)
export const updateOptimizationType = (
    optimizationType: YOBulksheetOption | ''
): UpdateOptimizationTypeAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_OPTIMIZATION_TYPE,
        payload: {
            value: optimizationType,
        },
    };
};

// UPDATE OPTIMIZATION STRATEGY
export const updateOptimizationStrategy = (
    strategy: OptimizationStrategy
): UpdateOptimizationStrategyAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_OPTIMIZATION_STRATEGY,
        payload: {
            value: strategy,
        },
    };
};

// UPDATE MINIMUM BID
export const updateMinimumBid = (value: string): UpdateMinimumBidAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_MINIMUM_BID,
        payload: {
            value,
        },
    };
};

// UPDATE MAXIMUM BID
export const updateMaximumBid = (value: string): UpdateMaximumBidAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_MAXIMUM_BID,
        payload: {
            value,
        },
    };
};

// UPDATE MINIMUM BID HEADROOM
export const updateMinimumBidHeadroom = (
    value: number
): UpdateMinimumBidHeadroomAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_MINIMUM_BID_HEADROOM,
        payload: {
            value,
        },
    };
};

// UPDATE MAXIMUM BID HEADROOM
export const updateMaximumBidHeadroom = (
    value: number
): UpdateMaximumBidHeadroomAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_MAXIMUM_BID_HEADROOM,
        payload: {
            value,
        },
    };
};

// UPDATE MINIMUM DAILY BUDGET
export const updateMinimumDailyBudget = (
    value: number
): UpdateMinimumDailyBudgetAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_MINIMUM_DAILY_BUDGET,
        payload: {
            value,
        },
    };
};

// UPDATE MAXIMUM DAILY BUDGET
export const updateMaximumDailyBudget = (
    value: number
): UpdateMaximumDailyBudgetAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_MAXIMUM_DAILY_BUDGET,
        payload: {
            value,
        },
    };
};

// UPDATE USE WEIGHTED TARGET FLAG
export const updateUseWeightedFlag = (
    value: boolean
): UpdateUseWeightedFlagAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_USE_WEIGHTED_FLAG,
        payload: {
            value,
        },
    };
};

// UPDATE ADJUST HIGH DB FLAG
export const updateAdjustHighDbFlag = (
    value: boolean
): UpdateAdjustHighDbFlagAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_ADJUST_HIGH_DB_FLAG,
        payload: {
            value,
        },
    };
};

// UPDATE OPTIMIZE BUDGET CAPS FOR EFFICIENCY
export const updateOptimizeBudgetCaps = (
    checked: boolean
): UpdateOptimizeBudgetCapsAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_OPTIMIZE_BUDGET_CAPS,
        payload: {
            value: checked,
        },
    };
};

// UPDATE PHRASE PERCENT BID FACTOR
export const updatePhrasePercentFactor = (
    value: string
): UpdatePhrasePercentFactorAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_PHRASE_PERCENT_FACTOR,
        payload: {
            value,
        },
    };
};

// UPDATE BROAD PERCENT BID FACTOR
export const updateBroadPercentFactor = (
    value: string
): UpdateBroadPercentFactorAction => {
    return {
        type: UNIVERSAL_SETTINGS_ACTION_TYPES.UPDATE_BROAD_PERCENT_FACTOR,
        payload: {
            value,
        },
    };
};
