import { XLSX_FILE_TYPE } from '../constants';
import { read, utils } from 'xlsx';
import { DropdownOption } from '../state';

export interface ParsedFileRow {
    [key: string]: number | string;
}

// FUNCTION TO PARSE XLSX FILES
export const parseFile = (
    file: File,
    type: 'campaign' | 'keyword'
): Promise<DropdownOption[]> => {
    return new Promise((resolve, reject) => {
        if (file.type === XLSX_FILE_TYPE) {
            // CREATE NEW READER INSTANCE
            const reader = new FileReader();

            // RUNS FUNCTION ONCE READER HAS FINISHED READING FILE
            reader.onloadend = (evt) => {
                if (!evt.target?.result) {
                    throw new Error('Unable to parse file.');
                }

                // READ ARRAY BUFFER AND CONVERT TO WORKBOOK
                const wb = read(evt.target.result);

                // GET WORKBOOK SHEET NAME
                const sheetName = wb.SheetNames[0];

                // CONVERT WORKBOOK SHEET TO JSON DATA
                const data = utils.sheet_to_json<ParsedFileRow>(
                    wb.Sheets[sheetName]
                );

                // FIND CASE INSENSITIVE CAMPAIGN/KEYWORD ID COLUMN
                const [idColumn] = Object.keys(data[0]).filter(
                    (key) => key.toLowerCase().trim() === `${type} id`
                );

                // FIND CASE INSENSIVITVE CAMPAIGN/KEYWORD NAME COLUMN
                const [nameColumn] = Object.keys(data[0]).filter(
                    (key) => key.toLowerCase().trim() === type
                );

                // THROW ERROR IF ID/NAME COLUMN IS NOT PRESENT
                if (!idColumn || !nameColumn) {
                    reject(
                        `The provided headers in the uploaded file are incorrect. File must conatian a "${type.toUpperCase()}" and "${type.toUpperCase()} ID" column.`
                    );
                    return;
                }

                // STORE UNIQUE OCCURRENCES OF CAMPAIGN/KEYWORDS TO BE SELECTED/EXCLUDED
                const resultsObj = data.reduce(
                    (results: { [key: string]: string }, row) => {
                        const id = row[idColumn].toString();
                        const name = row[nameColumn].toString();

                        results[id] = name;

                        return results;
                    },
                    {}
                );

                // CREATE ARRAY OF DROPDOWN OPTIONS FROM RESULTS OBJECT
                const options: DropdownOption[] = Object.keys(resultsObj).map(
                    (key) => ({
                        label: resultsObj[key],
                        value: key,
                    })
                );

                resolve(options);
            };

            // START READER
            reader.readAsArrayBuffer(file);
        }
    });
};
