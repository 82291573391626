import { convertDateToString } from '..';

// TYPES
import {
    CronStatusData,
    POSTMarinCronBody,
    POSTSA360CronBody,
    SA360CronFromState,
    MarinCronFormState,
    POSTAdwordsCronBody,
    AdwordsCronFormState,
} from '../../state';

// FORMAT SA 360 CRON BODY
export const formatSA360CronBody = (
    userId: string,
    state: SA360CronFromState
): POSTSA360CronBody => {
    const startDate = state.flightStart
        ? convertDateToString(state.flightStart)
        : '';
    const endDate = state.flightEnd ? convertDateToString(state.flightEnd) : '';

    const accountDetails = state.advertiserOptions.reduce(
        (data: { [key: string]: string[] }, { value }) => {
            if (value !== 'Select all') {
                const [agendyId, advertiserId] = value.split('-');
                if (!data[agendyId]) {
                    data[agendyId] = [];
                }
                data[agendyId].push(advertiserId);
            }

            return data;
        },
        {}
    );

    return {
        userId,
        reportType: state.selectedReport.value,
        accountDetails: JSON.stringify(accountDetails),
        startDate,
        endDate,
        timeFlag: state.timeFlag.toString(),
    };
};

// FORMAT ADWORDS CRON BODY
export const formatAdwordsCronBody = (
    userId: string,
    state: AdwordsCronFormState
) => {
    if (
        !state.selectedAgency ||
        !state.selectedSubAccount ||
        !state.reportType ||
        !state.selectedFile
    )
        return;

    const body: POSTAdwordsCronBody = {
        userId,
        agencyId: state.selectedAgency.value,
        subAccountId: state.selectedSubAccount.value,
        reportType: state.reportType.value.toLowerCase(),
        file: state.selectedFile,
    };
    return body;
};

// FORMAT MARIN CRON BODY
export const formatMarinCronBody = (
    userId: string,
    state: MarinCronFormState
) => {
    if (!state.reportType || !state.selectedSubAccount || !state.selectedAgency)
        return;

    const [accountId, advertiserId, brandId] =
        state.selectedSubAccount.value.split('-');

    const body: POSTMarinCronBody = {
        userId,
        brandId,
        accountId,
        advertiserId,
        reportType: state.reportType.value,
        agencyId: state.selectedAgency.value,
        advertiser: state.selectedSubAccount.label,
    };

    return body;
};

// FORMAT RERUN CRON BODY
export const formatRerunCronBody = (cron: CronStatusData) => {
    const dateStart = new Date(cron.startDate);
    const dateEnd = new Date(cron.endDate);

    const details: { [key: string]: string[] } = {};
    details[cron.agencyId] = [cron.advertiserId];

    const body: POSTSA360CronBody = {
        userId: cron.user_id.toString(),
        accountDetails: JSON.stringify(details),
        reportType: cron.reportType,
        startDate: convertDateToString(dateStart),
        endDate: convertDateToString(dateEnd),
        timeFlag: cron.timeFlag?.toString() || '0',
    };

    return body;
};
