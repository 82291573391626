import './Darwin-Alerts-Modal.scss';

import { useEffect, useState } from 'react';

// TYPES
import { DarwinAlert } from '../../state';

// UI COMPONENTS
import Modal from '../Modal/Modal';
import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';

// COMPONENT PROPS
interface DarwinAlertsModalProps {
    alerts: DarwinAlert[];
    onClose: () => void;
    onDelete: (id: string) => void;
}

// FUNCTIONAL COMPONENT (VIEW DARWIN ALERTS MODAL)
function DarwinAlertsModal({
    alerts,
    onClose,
    onDelete,
}: DarwinAlertsModalProps) {
    const [data, setData] = useState();

    useEffect(() => {
        setData(new DataProvider({ data: alerts, rowsPerPage: 5 }));
    }, [alerts]);

    return (
        <Modal
            onClose={onClose}
            header="Alerts"
            content={
                <div className="darwin-alerts-modal-content">
                    <DataTable data={data} bindKey="alertId" stickyHead={true}>
                        <ColumnTemplate bind="alertTime">
                            Date/Time
                        </ColumnTemplate>
                        <ColumnTemplate
                            bind="alertType"
                            className="padding-bottom"
                            onRender={(alertType: '1' | '2' | '3') => {
                                let text = 'Min/Max';
                                if (alertType === '2') {
                                    text = 'Pacing';
                                }
                                if (alertType === '3') {
                                    text = 'Performance';
                                }
                                return <>{text}</>;
                            }}
                        >
                            Alert Type
                        </ColumnTemplate>
                        <ColumnTemplate
                            bind="message"
                            className="padding-bottom"
                        >
                            Notification
                        </ColumnTemplate>
                        <ColumnTemplate
                            className="table-icon"
                            bind="alertId"
                            type="actions"
                            onRender={(id: string) => {
                                return (
                                    <div onClick={() => onDelete(id)}>
                                        <i className="icon-delete" />
                                    </div>
                                );
                            }}
                        >
                            Remove
                        </ColumnTemplate>
                    </DataTable>
                </div>
            }
        />
    );
}

export default DarwinAlertsModal;
