import { Outlet, Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks';

function PrivateRoutes() {
    const { isAuthenticated } = useAppSelector(({ auth }) => auth);

    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoutes;
