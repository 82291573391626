import { deleteAccount } from '../../api';
import { useEffect, useState } from 'react';
import { ROUTES } from '../../router/router';
import { useNavigate } from 'react-router-dom';
import { useActions, useAppSelector } from '../../hooks';
import {
    BrandDetails,
    ExistingAccountDetails,
    GETExistingAccountsData,
} from '../../state';

// UI COMPONENTS
import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import ConfirmationModal from '../Confirmation-Modal/Confirmation-Modal';
import { DEFAULT_API_ERROR } from '../../constants';

// COMPONENT PROPS
interface ExistingAccountsTableProps {
    data: GETExistingAccountsData | null;
    onAction?: () => Promise<void>;
}

function ExistingAccountsTable({ data, onAction }: ExistingAccountsTableProps) {
    const navigate = useNavigate();

    // TABLE DATA PROVIDER (SCHEDULED GOALS LIST)
    const [dataProvider, setDataProvider] = useState<any>([]);

    // SELECTED SCHEDULE TO DELETE
    const [deleteSelected, setDeleteSelected] =
        useState<ExistingAccountDetails | null>(null);

    // CURRENT USER'S ID (REDUX)
    const userId = useAppSelector(({ auth }) => auth.user?.id);

    // REDUX ACTIONS
    const { alertError, alertSuccess, setLoading } = useActions();

    // CREATE TABLE DATA PROVIDER FROM RAW ACCOUNT DATA
    useEffect(() => {
        if (!data) return;

        // CONVERT RETURNED DATA OBJECT INTO AN ARRAY
        const formattedData = Object.keys(data)
            .map((id) => {
                return { ...data[id], id };
            })
            .sort((a, b) => {
                if (a.accountName.toLowerCase() < b.accountName.toLowerCase()) {
                    return -1;
                }

                if (a.accountName.toLowerCase() > b.accountName.toLowerCase()) {
                    return 1;
                }

                return 0;
            });

        // STORE EXISTING ACCOUNTS DATA
        setDataProvider(new DataProvider({ data: formattedData }));
    }, [data]);

    // HANDLE ACTION COLUMN EDIT ICON CLICK
    const onEditIconClick = (id: number) => {
        // FIND ACCOUNT DATA BY ID
        const account = data?.[id];

        if (!account) return;

        navigate(ROUTES.EDIT_EXISTING_ACCOUNT, {
            state: account,
        });
    };

    // HANDLE ACTION COLUMN DELETE ICON CLICK
    const onDeleteIconClick = (id: number) => {
        const account = data?.[id.toString()];
        if (!account) return;

        setDeleteSelected(account);
    };

    // HANDLE DELETION OF SCHEDULE
    const onDeleteSchedule = async () => {
        if (!userId || !deleteSelected) return;
        // OPEN LOADING MODAL
        setLoading(true);
        try {
            // ATTEMPT TO DELETE SCHEDULE
            const data = await deleteAccount(deleteSelected.agencyId);
            if (data?.result === 'success') {
                alertSuccess(data.message);
            } else {
                alertError(data.message);
            }
            // RELOAD TABLE DATA
            if (onAction) {
                await onAction();
            }
        } catch (error: any) {
            // ALERT ERROR ON ERROR
            alertError(
                error.response?.data?.errorMessage ||
                    error.response?.data?.message ||
                    DEFAULT_API_ERROR
            );
        } finally {
            // CLOSE LOADING MODAL
            setLoading(false);
            setDeleteSelected(null);
        }
    };

    return (
        <>
            <DataTable
                className="scheduled-goals-data-table"
                data={dataProvider}
                stickyHead={true}
                bindKey="id"
                emptyState={
                    <>
                        <h2>No data available.</h2>
                    </>
                }
            >
                <ColumnTemplate bind="agencyId" isSortable minWidth={true}>
                    Agency Id
                </ColumnTemplate>
                <ColumnTemplate bind="accountName" isSortable minWidth>
                    Account
                </ColumnTemplate>
                <ColumnTemplate bind="platform" isSortable minWidth>
                    Platform
                </ColumnTemplate>
                <ColumnTemplate
                    bind="brands"
                    isSortable
                    onRender={(brands: BrandDetails[] | undefined) => (
                        <>
                            <span>
                                {brands ? brands.length : 0} Advertisers
                            </span>
                        </>
                    )}
                    minWidth
                >
                    Advertisers
                </ColumnTemplate>
                <ColumnTemplate
                    bind="isPLASupport"
                    onRender={(supported: boolean) => (
                        <span>{supported ? 'Enabled' : 'Disabled'}</span>
                    )}
                    isSortable
                    minWidth
                >
                    PLA Support
                </ColumnTemplate>
                <ColumnTemplate
                    bind="id"
                    isSortable
                    onRender={(accountId: number) => (
                        <>
                            <button
                                className="action-button"
                                onClick={() => {
                                    onEditIconClick(accountId);
                                }}
                            >
                                <i className="icon-edit" title="Edit"></i>
                            </button>
                            <button
                                className="action-button"
                                onClick={() => {
                                    onDeleteIconClick(accountId);
                                }}
                            >
                                <i className="icon-delete" title="Delete"></i>
                            </button>
                        </>
                    )}
                    minWidth={true}
                >
                    Actions
                </ColumnTemplate>
            </DataTable>
            {!!deleteSelected && (
                <ConfirmationModal
                    header={`Delete: ${deleteSelected.accountName}`}
                    message="Are you sure you want to delete this account?"
                    onCancel={() => setDeleteSelected(null)}
                    onConfirm={onDeleteSchedule}
                />
            )}
        </>
    );
}

export default ExistingAccountsTable;
