import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import { useEffect, useState } from 'react';
import { createBulksheetLogs, getBulksheetLogs } from '../../../api';
import { useActions, useAppSelector } from '../../../hooks';

function BulksheetLogsTable() {
    // BULKSHEET LOGS TABLE DATA PROVIDER
    const [data, setData] = useState([]);
    const { user } = useAppSelector((state) => state.auth);

    const { setLoading, alertError } = useActions();

    // FETCH AND LOAD USER'S BULKSHEET LOGS
    useEffect(() => {
        if (!user?.id) return;
        (async function () {
            const bulksheetLogs = await getBulksheetLogs(user.id);
            setData(new DataProvider({ data: bulksheetLogs }));
        })();
    }, []);

    // DOWNLOAD BULKSHEET LOGS FILE
    const onDownloadBulksheetLogsData = async (fileName: string) => {
        setLoading(true);
        try {
            await createBulksheetLogs(fileName);
        } catch (error: any) {
            alertError(
                error?.response?.data?.errorMessage ||
                    'Unable to download file.'
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <DataTable
                data={data}
                bindKey={'emailId'}
                stickyHead
                emptyState={
                    <>
                        <h2>You have no bulksheetLogs at this time.</h2>
                    </>
                }
                hasVirtualScrolling={false}
            >
                <ColumnTemplate
                    bind="userName"
                    isSortable
                    onRender={(text: string) => (
                        <>
                            <span>{text}</span>
                        </>
                    )}
                    minWidth={true}
                >
                    User Name
                </ColumnTemplate>
                <ColumnTemplate
                    bind="emailId"
                    isSortable
                    onRender={(text: string) => (
                        <>
                            <span>{text}</span>
                        </>
                    )}
                    minWidth={true}
                >
                    Email ID
                </ColumnTemplate>
                <ColumnTemplate
                    bind="file_name"
                    isSortable
                    onRender={(text: string) => (
                        <>
                            <span>{text}</span>
                        </>
                    )}
                    minWidth={true}
                >
                    File Name
                </ColumnTemplate>
                <ColumnTemplate
                    bind="modified_on"
                    isSortable
                    onRender={(text: string) => (
                        <>
                            <span>{text}</span>
                        </>
                    )}
                    minWidth={true}
                >
                    Uploaded On
                </ColumnTemplate>
                <ColumnTemplate
                    bind="file_name"
                    className="table-icon"
                    onRender={(text: string) => (
                        <>
                            <div className="justify-column-center">
                                <span
                                    onClick={() =>
                                        onDownloadBulksheetLogsData(text)
                                    }
                                >
                                    <i className="icon-download"></i>
                                </span>
                            </div>
                        </>
                    )}
                    minWidth={true}
                >
                    Download
                </ColumnTemplate>
            </DataTable>
        </>
    );
}

export default BulksheetLogsTable;
