import './Manage-User-Form.scss';

import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { findDeselectedOption, onPreventEnterKeySubmit } from '../../utils';
import {
    UserDetails,
    UserFormState,
    USER_ROLE_IDS,
    DropdownOption,
    userFormReducer,
    GETRolesAccountBrands,
    INITIAL_USER_FORM_STATE,
    USER_FORM_ACTION_TYPES,
} from '../../state';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';

// COMPONENT PROPS
interface ManageUserFormProps {
    accessData: GETRolesAccountBrands | null;
    user?: UserDetails;
    onSubmit: (state: UserFormState) => Promise<void>;
    onCancel?: () => void;
}

function ManageUserForm({
    accessData,
    user,
    onSubmit,
    onCancel,
}: ManageUserFormProps) {
    // USER FORM STATE
    const [state, dispatch] = useReducer(
        userFormReducer,
        INITIAL_USER_FORM_STATE
    );

    // LOAD ACCESS OPTIONS INTO STATE AND CREATE DROPDOWN OPTIONS
    useEffect(() => {
        if (!accessData) return;

        dispatch({
            type: USER_FORM_ACTION_TYPES.SET_ACCESS_DATA,
            payload: accessData,
        });
    }, [accessData]);

    // HANDLE LOADING EXISTING USER DATA
    useEffect(() => {
        if (!user) return;
        dispatch({
            type: USER_FORM_ACTION_TYPES.LOAD_EXISTING_USER,
            payload: user,
        });
    }, [user]);

    // HANDLE USER FORM SUBMISSION
    const onSubmitUser = async (evt: React.FormEvent) => {
        evt.preventDefault();

        try {
            await onSubmit(state);

            dispatch({
                type: USER_FORM_ACTION_TYPES.RESET_USER_FORM,
            });
        } catch (error: any) {
            throw error;
        }
    };

    // HANDLE SETTING EMAIL VALUE
    const onSetEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: USER_FORM_ACTION_TYPES.SET_EMAIL,
            payload: evt.target.value,
        });
    };

    // HANDLE SETTING LION EMAIL VALUE
    const onSetLionEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: USER_FORM_ACTION_TYPES.SET_LION_EMAIL,
            payload: evt.target.value,
        });
    };

    // HANDLE SETTING FIRST NAME VALUE
    const onSetFirstName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: USER_FORM_ACTION_TYPES.SET_FIRST_NAME,
            payload: evt.target.value,
        });
    };

    // HANDLE SETTING LAST NAME VALUE
    const onSetLastName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: USER_FORM_ACTION_TYPES.SET_LAST_NAME,
            payload: evt.target.value,
        });
    };

    // HANDLE SETTING ROLE VALUE
    const onSetRole = (selected: DropdownOption) => {
        dispatch({
            type: USER_FORM_ACTION_TYPES.SET_ROLE,
            payload: selected,
        });
    };

    // HANDLE SELECTING ACCOUNT
    const onAccountSelection = (selections: DropdownOption[]) => {
        const numSelections = selections.length;

        // HANDLE SELECTING ACCOUNT
        if (numSelections > state.accounts.length) {
            dispatch({
                type: USER_FORM_ACTION_TYPES.ADD_ACCOUNT,
                payload: selections[numSelections - 1],
            });
            return;
        }

        // HANDLE UNSELECTING ACCOUNT
        if (numSelections < state.accounts.length) {
            const unselected = findDeselectedOption(selections, state.accounts);

            if (!unselected) return;

            dispatch({
                type: USER_FORM_ACTION_TYPES.REMOVE_ACCOUNT,
                payload: unselected,
            });
            return;
        }
    };

    // HANDLE SETTING ADVERTISERS VALUE
    const onAdvertiserSelection = (selections: DropdownOption[]) => {
        const numSelections = selections.length;

        // HANDLE SELECTING ADVERTISER
        if (numSelections > state.advertisers.length) {
            dispatch({
                type: USER_FORM_ACTION_TYPES.ADD_ADVERTISER,
                payload: selections[numSelections - 1],
            });
            return;
        }

        // HANDLE UNSELECTING ADVERTISER
        if (numSelections < state.advertisers.length) {
            const unselected = findDeselectedOption(
                selections,
                state.advertisers
            );

            if (!unselected) return;
            dispatch({
                type: USER_FORM_ACTION_TYPES.REMOVE_ADVERTISER,
                payload: unselected,
            });
        }
    };

    // HANDLE SETTING FEATURES VALUE
    const onSetFeatures = (selections: DropdownOption[]) => {
        const numSelections = selections.length;

        // HANDLE SELECTING FEATURE
        if (numSelections > state.features.length) {
            dispatch({
                type: USER_FORM_ACTION_TYPES.ADD_FEATURE,
                payload: selections[numSelections - 1],
            });
            return;
        }

        // HANDLE UNSELECTING FEATURE
        if (numSelections < state.features.length) {
            const unselected = findDeselectedOption(selections, state.features);

            if (!unselected) return;
            dispatch({
                type: USER_FORM_ACTION_TYPES.REMOVE_FEATURE,
                payload: unselected,
            });
        }
    };

    // HANDLE SETTING NEW PASSWORD VALUE
    const onSetNewPassword = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: USER_FORM_ACTION_TYPES.SET_NEW_PASSWORD,
            payload: evt.target.value,
        });
    };

    // HANDLE SETTING CONFIRM PASSWORD VALUE
    const onSetConfirmPassword = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: USER_FORM_ACTION_TYPES.SET_CONFIRM_PASSWORD,
            payload: evt.target.value,
        });
    };

    // SELECT ALL ACCESS PERMISSIONS AND DISABLE INPUTS IF 'ADMIN'
    const isAdmin =
        (state.role && state.role.value === USER_ROLE_IDS[0].toString()) ||
        false;

    // ENABLE/DISABLE SUBMIT USER BUTTON
    const isSubmitDisabled =
        !state.email ||
        !state.firstName ||
        !state.lastName ||
        !state.role ||
        !state.accounts.length ||
        !state.advertisers.length ||
        !state.features.length ||
        state.newPassword !== state.confirmPassword;

    return (
        <div className="manage-user-form-container">
            <form onSubmit={onSubmitUser} onKeyDown={onPreventEnterKeySubmit}>
                <div className="form-row">
                    <h3>User Information</h3>
                    <div className="form-inputs">
                        <Textbox
                            onChange={onSetEmail}
                            value={state.email}
                            disabled={!!user}
                            required
                        >
                            Email
                        </Textbox>
                        <Textbox
                            onChange={onSetLionEmail}
                            value={state.lionEmail}
                            disabled={!!user}
                        >
                            Lion Email
                        </Textbox>
                        <Textbox
                            onChange={onSetFirstName}
                            value={state.firstName}
                            required
                        >
                            First Name
                        </Textbox>
                        <Textbox
                            onChange={onSetLastName}
                            value={state.lastName}
                            required
                        >
                            Last Name
                        </Textbox>
                    </div>
                </div>
                <div className="form-row">
                    <h3>User Access</h3>
                    <div className="form-inputs">
                        <Dropdown
                            options={state.roleOptions}
                            onChange={onSetRole}
                            value={state.role}
                            defaultValue={state.role}
                            required
                        >
                            Roles
                        </Dropdown>
                        <Dropdown
                            options={state.accountOptions}
                            onChange={onAccountSelection}
                            value={state.accounts}
                            defaultValue={state.accounts}
                            multiple
                            display="selectionInline"
                            disabled={isAdmin}
                            required
                        >
                            Agencies
                        </Dropdown>
                        <Dropdown
                            options={state.advertiserOptions}
                            onChange={onAdvertiserSelection}
                            value={state.advertisers}
                            defaultValue={state.advertisers}
                            multiple
                            display="selectionInline"
                            disabled={isAdmin}
                            required
                        >
                            Advertisers
                        </Dropdown>
                        <Dropdown
                            options={state.featureOptions}
                            onChange={onSetFeatures}
                            value={state.features}
                            defaultValue={state.features}
                            multiple
                            display="selectionInline"
                            disabled={isAdmin}
                            required
                        >
                            Features
                        </Dropdown>
                    </div>
                </div>
                {!!user && (
                    <div className="form-row">
                        <div className="form-inputs">
                            <Textbox
                                onChange={onSetNewPassword}
                                value={state.newPassword || ''}
                                type="password"
                                required={!!state.confirmPassword}
                            >
                                New Password
                            </Textbox>
                            <Textbox
                                onChange={onSetConfirmPassword}
                                value={state.confirmPassword || ''}
                                required={
                                    !!state.newPassword &&
                                    state.newPassword !== state.confirmPassword
                                }
                                type="password"
                            >
                                Confirm Password
                            </Textbox>
                        </div>
                        {state.newPassword !== state.confirmPassword && (
                            <p className="error-message">
                                Passwords must match
                            </p>
                        )}
                    </div>
                )}
                <div className="form-row">
                    <div className="form-submit-button">
                        {!!user && (
                            <Button onClick={onCancel} color="red">
                                Cancel
                            </Button>
                        )}
                        <Button type="submit" disabled={isSubmitDisabled}>
                            {user ? 'Update' : 'Add'} User
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ManageUserForm;
