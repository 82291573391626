import { useActions } from '../../hooks';
import { addEngineAccount } from '../../api';
import { onPreventEnterKeySubmit } from '../../utils';
import React, { useEffect, useReducer, useState } from 'react';
import { DEFAULT_API_ERROR, PLATFORM_NAMES } from '../../constants';
import {
    bingAdsFormReducer,
    googleAdsFormReducer,
    BING_ADS_ACTION_TYPES,
    INITIAL_BING_ADS_FORM,
    GOOGLE_ADS_ACTION_TYPES,
    INITIAL_GOOGLE_ADS_FORM,
    ExistingAccountDetails,
} from '../../state';

// UI COMPONENTS
import BingAdsForm from './Bing-Ads-Form';
import GoogleAdsForm from './Google-Ads-Form';
import Radio from '@publicismedia-ds/ui-radio';
import Button from '@publicismedia-ds/ui-button';
import Fieldset from '@publicismedia-ds/ui-fieldset';

// COMPONENT PROPS
interface EngineAccountsFormProps {
    mEmails: string[];
    account?: ExistingAccountDetails;
    onSubmit?: () => void;
    onCancel?: () => void;
}

type EngineAccount = PLATFORM_NAMES.GOOGLE_ADS | PLATFORM_NAMES.BING_ADS;

// FUNCTIONAL COMPONENT
function EngineAccountsForm({
    mEmails,
    account,
    onSubmit,
    onCancel,
}: EngineAccountsFormProps) {
    // REDUX ACTIONS
    const { setLoading, alertError, alertSuccess } = useActions();

    // ENGINE ACCOUNT SELECTION STATE
    const [selectedEngine, setSelectedEngine] = useState<EngineAccount>(
        PLATFORM_NAMES.GOOGLE_ADS
    );

    // GOOGLE ADS FORM STATE
    const [googleForm, dispatchGoogleForm] = useReducer(
        googleAdsFormReducer,
        INITIAL_GOOGLE_ADS_FORM
    );

    // BING ADS FORM STATE
    const [bingForm, dispatchBingForm] = useReducer(
        bingAdsFormReducer,
        INITIAL_BING_ADS_FORM
    );

    // LOAD EXISTING ACCOUNT DATA
    useEffect(() => {
        if (!account) return;

        if (account.bingAdsManagerEmail) {
            setSelectedEngine(PLATFORM_NAMES.BING_ADS);
        }

        if (account.adwordsManagerEmail) {
            setSelectedEngine(PLATFORM_NAMES.GOOGLE_ADS);
        }
    }, [account]);

    // HANDLE ENGINE ACCOUNT SELECTION
    const onSelectEngine = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const engine = evt.target.value as EngineAccount;
        if (engine === PLATFORM_NAMES.BING_ADS) {
            dispatchGoogleForm({
                type: GOOGLE_ADS_ACTION_TYPES.RESET_GOOGLE_ADS_FORM,
            });
        }

        if (engine === PLATFORM_NAMES.GOOGLE_ADS) {
            dispatchBingForm({
                type: BING_ADS_ACTION_TYPES.RESET_BING_ADS_FORM,
            });
        }
        setSelectedEngine(engine);
    };

    // ENBALE/DISABLE SUBMIT BUTTON (GOOGLE)
    let isGoogleDisabled =
        !googleForm.adwordsMangerEmail ||
        !googleForm.customerId ||
        !googleForm.customerName;

    for (let subCustomer of googleForm.customerData) {
        if (
            googleForm.isManagerAccount === '0' ||
            selectedEngine !== PLATFORM_NAMES.GOOGLE_ADS
        ) {
            break;
        }

        if (!subCustomer.id || !subCustomer.name) {
            isGoogleDisabled = true;
            break;
        }
    }

    // ENABLE/DISABLE SUBMIT BUTTON (BING)
    let isBingDisabled = !bingForm.bingAdsManagerEmail || !bingForm.agencyName;

    for (let subAccount of bingForm.accountData) {
        if (selectedEngine !== PLATFORM_NAMES.BING_ADS) break;

        if (
            !subAccount.customerId ||
            !subAccount.number ||
            !subAccount.id ||
            !subAccount.name
        ) {
            isBingDisabled = true;
            break;
        }
    }

    const isSubmitDisabled =
        selectedEngine === PLATFORM_NAMES.BING_ADS
            ? isBingDisabled
            : isGoogleDisabled;

    // HANDLE SUBMIT GOOGLE ACCOUNT
    const submitGoogleAccount = async () => {
        try {
            const data = await addEngineAccount(googleForm);

            alertSuccess(data?.message || 'Successfully updated account.');

            dispatchGoogleForm({
                type: GOOGLE_ADS_ACTION_TYPES.RESET_GOOGLE_ADS_FORM,
            });
        } catch (error: any) {
            throw error;
        }
    };

    // HANDLE SUBMIT BING ACCOUNT
    const submitBingAccount = async () => {
        setLoading(true);
        try {
            const data = await addEngineAccount(bingForm);

            alertSuccess(data?.message || 'Successfully updated account.');

            dispatchBingForm({
                type: BING_ADS_ACTION_TYPES.RESET_BING_ADS_FORM,
            });
        } catch (error: any) {
            alertError(
                error.response?.data?.errorMessage ||
                    error.response?.data?.message ||
                    DEFAULT_API_ERROR
            );
        } finally {
            setLoading(false);
        }
    };

    // HANDLE ENGINE ACCOUNT SUBMISSION
    const onSubmitEngineAccount = async (evt: React.FormEvent) => {
        evt.preventDefault();

        setLoading(true);

        try {
            if (selectedEngine === PLATFORM_NAMES.GOOGLE_ADS) {
                await submitGoogleAccount();
            }

            if (selectedEngine === PLATFORM_NAMES.BING_ADS) {
                await submitBingAccount();
            }

            // RUN SUBMIT CALLBACK IF PRESENT
            if (onSubmit) onSubmit();
        } catch (error: any) {
            alertError(
                error.response?.data?.errorMessage ||
                    error.response?.data?.message ||
                    DEFAULT_API_ERROR
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="engine-accounts-form-container">
            <form
                onSubmit={onSubmitEngineAccount}
                onKeyDown={onPreventEnterKeySubmit}
            >
                {/* DISPLAY ONLY FOR ADDING NEW ACCOUNT */}
                {!account && (
                    <div className="inputs-row">
                        <h4>Please select applicable engine account(s):</h4>
                        <Fieldset layout="horizontal">
                            <Radio
                                value={PLATFORM_NAMES.GOOGLE_ADS}
                                checked={
                                    selectedEngine === PLATFORM_NAMES.GOOGLE_ADS
                                }
                                onChange={onSelectEngine}
                                invert
                            >
                                Google Ads
                            </Radio>
                            <Radio
                                value={PLATFORM_NAMES.BING_ADS}
                                checked={
                                    selectedEngine === PLATFORM_NAMES.BING_ADS
                                }
                                onChange={onSelectEngine}
                                invert
                            >
                                Bing Ads
                            </Radio>
                        </Fieldset>
                    </div>
                )}
                {selectedEngine === PLATFORM_NAMES.GOOGLE_ADS && (
                    <GoogleAdsForm
                        mEmails={mEmails}
                        account={account}
                        state={googleForm}
                        dispatch={dispatchGoogleForm}
                    />
                )}
                {selectedEngine === PLATFORM_NAMES.BING_ADS && (
                    <BingAdsForm
                        mEmails={mEmails}
                        account={account}
                        state={bingForm}
                        dispatch={dispatchBingForm}
                    />
                )}
                <div className="submit-button-container">
                    {!!onCancel && (
                        <Button display="secondary" onClick={onCancel}>
                            Cancel
                        </Button>
                    )}
                    <Button type="submit" disabled={isSubmitDisabled}>
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default EngineAccountsForm;
