import { axios } from '../axios-config';
import { API_UNIVERSAL_SETTINGS } from '../../constants';
import { formatEditGoalBody, formatAddGoalBody } from '../../utils';
import {
    AddGoalForm,
    EditGoalForm,
    GETGoalsData,
    GETGoalSettings,
} from '../../state';
import { POSTGoalResponse } from '../../state/models/manage-goal-mappings-models';

export const getGoalsData = async (user_id: string) => {
    try {
        const {
            data: { goalList = [] },
        } = await axios.get<GETGoalsData>(
            API_UNIVERSAL_SETTINGS + '/getGoalsData',
            {
                params: {
                    user_id,
                    intraday: true,
                },
            }
        );

        goalList.sort((a, b) => {
            if (
                a.goalMappingName.toLowerCase() <
                b.goalMappingName.toLowerCase()
            ) {
                return -1;
            }
            if (
                a.goalMappingName.toLowerCase() >
                b.goalMappingName.toLowerCase()
            ) {
                return 1;
            }
            return 0;
        });

        return goalList;
    } catch (error: any) {
        throw error;
    }
};

export const getGoalSetting = async (goalId: string, userId: string) => {
    try {
        const { data: goalSetting } = await axios.get<GETGoalSettings>(
            API_UNIVERSAL_SETTINGS + '/getGoalSetting',
            {
                params: {
                    goalId,
                    userId,
                },
            }
        );
        return goalSetting;
    } catch (error) {
        throw error;
    }
};

export const addGoalMapping = async (formState: AddGoalForm) => {
    const body = formatAddGoalBody(formState);
    const url = API_UNIVERSAL_SETTINGS + '/addGoal';

    if (!body) return;

    try {
        const { data } = await axios.post<POSTGoalResponse>(url, body, {
            headers: { 'Content-Type': 'application/json' },
        });
        return data;
    } catch (error) {
        throw error;
    }
};

export const editGoalMapping = async (formState: EditGoalForm) => {
    const body = formatEditGoalBody(formState);
    const url = API_UNIVERSAL_SETTINGS + '/editGoal';

    try {
        const { data } = await axios.post<POSTGoalResponse>(url, body);
        return data;
    } catch (error) {
        throw error;
    }
};

export const deleteMap = async (goalId: string) => {
    const body = {
        goal_id: goalId,
    };
    try {
        const { data } = await axios.put(
            API_UNIVERSAL_SETTINGS + '/deleteGoal',
            body,
            {
                params: {
                    goal_id: goalId,
                },
                headers: {
                    'Content-Type': 'applicatoin/json',
                },
            }
        );
    } catch (error) {
        throw error;
    }
};
