import produce from 'immer';
import {
  BingAdsFormState,
  BingAdsFormAction,
  BING_ADS_ACTION_TYPES,
  INITIAL_BING_ADS_FORM,
  INITIAL_BING_SUB_ACCOUNT,
} from '../form-models';

export const bingAdsFormReducer = produce(
  (
    state: BingAdsFormState = INITIAL_BING_ADS_FORM,
    action: BingAdsFormAction
  ): BingAdsFormState => {
    switch (action.type) {
      case BING_ADS_ACTION_TYPES.SET_MANAGER_EMAIL:
        state.bingAdsManagerEmail = action.payload;
        return state;

      case BING_ADS_ACTION_TYPES.SET_AGENCY_NAME:
        state.agencyName = action.payload;
        return state;

      case BING_ADS_ACTION_TYPES.ADD_SUB_ACCOUNT: {
        const subAccount = { ...INITIAL_BING_SUB_ACCOUNT };

        if (state.isEditting) {
          subAccount.status = true;
        }

        state.accountData.push(subAccount);
        return state;
      }

      case BING_ADS_ACTION_TYPES.UPDATE_SUB_ACCOUNT: {
        const { id, name, index, number, status, existing, customerId } =
          action.payload;

        if (state.accountData[index]) {
          // UPDATE SUB ACCOUNT ID (NEW ONLY)
          if (!existing && id !== undefined) {
            state.accountData[index].id = id;
          }

          // UPDATE SUB ACCOUNT NAME (NEW ONLY)
          if (!existing && name !== undefined) {
            state.accountData[index].name = name;
          }

          // UPDATE SUB ACCOUNT NUMBER (NEW ONLY)
          if (!existing && number !== undefined) {
            state.accountData[index].number = number;
          }

          // UPDATE SUB ACCOUNT CUSTOMER ID (NEW ONLY)
          if (!existing && customerId !== undefined) {
            state.accountData[index].customerId = customerId;
          }

          // UPDATE SUB ACCOUNT STATUS (NEW/EXISTING)
          if (status !== undefined) {
            state.accountData[index].status = status;
          }
        }

        return state;
      }

      case BING_ADS_ACTION_TYPES.REMOVE_SUB_ACCOUNT:
        state.accountData = state.accountData.filter((data, index) => {
          return index !== action.payload;
        });
        return state;

      case BING_ADS_ACTION_TYPES.LOAD_FORM_DATA: {
        const data = action.payload;

        state.isEditting = true;
        state.bingAdsManagerEmail = {
          label: data.bingAdsManagerEmail || '',
          value: data.bingAdsManagerEmail || '',
        };
        state.accountData =
          data.bingAdsSubAccounts?.map((data) => {
            return {
              id: data.sub_account_id.toString(),
              name: data.sub_account_name,
              number: data.sub_account_number,
              customerId: data.customer_id,
              status: data.sub_act_status,
              existing: true,
            };
          }) || [];
        state.agencyName = data.agencyId;
        return state;
      }

      case BING_ADS_ACTION_TYPES.RESET_BING_ADS_FORM:
        state = { ...INITIAL_BING_ADS_FORM };
        return state;

      default:
        return state;
    }
  },
  INITIAL_BING_ADS_FORM
);
