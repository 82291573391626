import { axios } from '../axios-config';

// CONSTANTS
import {
    TODAY,
    API_DPAD,
    DEFAULT_API_ERROR,
    API_DIRECTIONAL_PACING,
} from '../../constants';

// TYPES
import {
    AnomalyItem,
    PacingDocState,
    TempPacingDocBody,
    GETDirectionalPacing,
    POSTAnomalyTableData,
} from '../../state';

// UTILS
import { saveDownloadFile } from '../../utils/download-file';

export const getDirectionalPacing = async (goalId: string | number) => {
    try {
        const url = API_DIRECTIONAL_PACING + '/directionalPacing/v2';
        const { data } = await axios.get<GETDirectionalPacing>(url, {
            params: { goalId },
        });
        return data;
    } catch (error) {
        throw error;
    }
};

export const getAnomalyTableData = async (
    userId: string,
    goalId: string | number
) => {
    try {
        const url = API_DPAD + '/AnomalyDetection/anomalyDetection';
        const { data } = await axios.post<POSTAnomalyTableData>(
            url,
            {},
            {
                params: {
                    userId,
                    goalId,
                },
            }
        );
        return data;
    } catch (error) {
        throw error;
    }
};

export const downloadAnomalyBulksheet = async (
    anomalyData: AnomalyItem[],
    goalId: string | number,
    goalName: string
) => {
    const date = new Date();
    const fileName = `Performance_Monitor_Anamoly_Bulksheet_${goalName}_${TODAY}.xlsx`;
    try {
        const url = API_DPAD + '/AnomalyDetection/downloadAnomalyBulksheet';
        const params = new URLSearchParams();
        params.append('anomalyData', JSON.stringify(anomalyData));
        params.append('goalId', goalId.toString());
        const blob = await axios.post(url, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            responseType: 'blob',
        });
        saveDownloadFile(blob, fileName);
    } catch (error) {
        const err = new Error();
        err.message = 'Server error. Unable to download bulksheet.';
        throw err;
    }
};

export const downloadMomYoyBulkSheet = async (
    goalId: string | number,
    goalName: string,
    fileType: 'M' | 'Y'
) => {
    const date = new Date();
    const fileName = `Performance_Monitor_MOM_Bulksheet_${goalName}_${TODAY}.xlsx`;
    try {
        const url = API_DPAD + '/AnomalyDetection/createMomYoyBulkSheet';
        const params = new URLSearchParams();
        params.append('goalId', goalId.toString());
        params.append('fileType', fileType);
        const blob = await axios.post(url, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            responseType: 'blob',
        });
        saveDownloadFile(blob, fileName);
    } catch (error) {
        throw error;
    }
};

// DOWNLOAD PACING DOC
export const downloadPacingDoc = async (state: PacingDocState) => {
    if (!state.accountId || !state.brandId) {
        throw new Error('Please fill all required fields.');
    }

    // FORMAT REQUEST BODY
    const body: TempPacingDocBody = {
        accountId: state.accountId,
        brandId: state.brandId,
        platformId: state.platformId || 1,
        goalIds: state.goals.map(({ value }) => parseInt(value)),
        columns: state.columns.map(({ value }) => value),
        isFormulaColumn: state.useFormulaColumns,
        formulaColumn: state.formulaColumns
            .filter((details) => details.checked)
            .map((details) => ({
                columnName: details.columnName,
                column1: details.columnOne?.value || '',
                column2: details.columnTwo?.value || '',
                operator: details.operator?.value || '',
            })),
    };

    try {
        const blob = await axios.post(
            API_DPAD + '/Pacing/generatePacingDoc',
            body,
            {
                responseType: 'blob',
            }
        );

        const agnecyName = state.agency?.label.replaceAll(' ', '_');
        const advertiserName = state.advertiser?.label.replaceAll('-', '_');

        saveDownloadFile(
            blob,
            `Pacing_Doc_${agnecyName}-${advertiserName}_${new Date().toLocaleDateString(
                'en',
                {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                }
            )}.xlsx`
        );
    } catch (error: any) {
        const res = await error.response?.data?.text();
        const { errorMessage } = JSON.parse(res);

        throw new Error(errorMessage || DEFAULT_API_ERROR);
    }
};
