import { useMemo } from 'react';
import { FeatureIds, PRODUCT_NAMES } from '../constants';
import { useLocation } from 'react-router-dom';

export const useProduct = () => {
    const location = useLocation();

    return useMemo(() => {
        const productName =
            location.pathname.split('/').pop()?.replaceAll('-', ' ') || null;

        let featureId = null;

        switch (productName) {
            case PRODUCT_NAMES.PERFORMANCE_MONITOR:
                featureId = FeatureIds.PERFORMANCE_MONITOR;
                break;

            case PRODUCT_NAMES.YIELD_OPTIMIZER:
                featureId = FeatureIds.YIELD_OPTIMIZER;
                break;

            case PRODUCT_NAMES.SCENARIO_PLANNER:
                featureId = FeatureIds.SCENARIO_PLANNER;
                break;

            case PRODUCT_NAMES.PERFORMANCE_DIAGNOSTIC:
                featureId = FeatureIds.PERFORMANCE_DIAGNOSTIC;
                break;

            default:
                break;
        }

        return { productName, pathname: location.pathname, featureId };
    }, [location.pathname]);
};
