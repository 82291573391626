import './Modal.scss';

// COMPONENT PROPS
interface ModalHeaderProps {
    className?: string;
    children: React.ReactNode;
}

// FUNCTIONAL COMPONENT (MODAL HEADER)
function ModalHeader({ className, children }: ModalHeaderProps) {
    return (
        <>
            <h1 className={`modal-heading${className ? ' ' + className : ''}`}>
                {children}
            </h1>
        </>
    );
}

export default ModalHeader;
