import produce from 'immer';
import { ProductsAction } from '../actions';
import { PRODUCTS_ACTION_TYPES } from '../action-types';

// TYPES
import { ProductsState } from '../models';

const DEFAULT_PRODUCTS_STATE: ProductsState = {
    loading: false,
    displaySettings: true,
    performanceMonitor: { data: null },
    yieldOptimizer: { data: null, bulksheets: false },
    scenarioPlanner: {
        data: null,
        isAdhoc: false,
    },
    performanceDiagnostic: {
        data: null,
        isAdhoc: false,
    },
};

const reducer = produce(
    (
        state: ProductsState = DEFAULT_PRODUCTS_STATE,
        action: ProductsAction
    ): ProductsState => {
        switch (action.type) {
            case PRODUCTS_ACTION_TYPES.SET_LOADING:
                state.loading = action.payload.value;
                return state;

            case PRODUCTS_ACTION_TYPES.TOGGLE_DISPLAY_SETTINGS:
                if (action.payload.value === undefined) {
                    state.displaySettings = !state.displaySettings;
                } else {
                    state.displaySettings = action.payload.value;
                }
                return state;

            case PRODUCTS_ACTION_TYPES.SET_PERFORMANCE_MONITOR_DATA:
                state.displaySettings = false;
                state.yieldOptimizer.data = null;
                state.yieldOptimizer.bulksheets = false;
                state.scenarioPlanner.data = null;
                state.performanceDiagnostic.data = null;
                state.performanceMonitor.data = action.payload;

                return state;

            case PRODUCTS_ACTION_TYPES.SET_YIELD_OPTIMIZER_DATA:
                state.displaySettings = false;
                state.scenarioPlanner.data = null;
                state.performanceMonitor.data = null;
                state.performanceDiagnostic.data = null;
                state.yieldOptimizer.data = action.payload;
                state.yieldOptimizer.bulksheets = true;

                return state;

            case PRODUCTS_ACTION_TYPES.SET_YIELD_OPTIMIZER_BULKSHEET:
                state.yieldOptimizer.bulksheets = action.payload;
                return state;

            case PRODUCTS_ACTION_TYPES.SET_SCENARIO_PLANNER_DATA:
                state.displaySettings = false;
                state.yieldOptimizer.data = null;
                state.yieldOptimizer.bulksheets = false;
                state.performanceMonitor.data = null;
                state.performanceDiagnostic.data = null;
                state.scenarioPlanner.data = action.payload.data;
                state.scenarioPlanner.isAdhoc = action.payload.isAdhoc;

                return state;

            case PRODUCTS_ACTION_TYPES.SET_PERFORMANCE_DIAGNOSTIC_DATA:
                state.displaySettings = false;
                state.scenarioPlanner.data = null;
                state.yieldOptimizer.data = null;
                state.yieldOptimizer.bulksheets = false;
                state.performanceMonitor.data = null;
                state.performanceDiagnostic.data = action.payload.data;
                state.performanceDiagnostic.isAdhoc = action.payload.isAdhoc;

                return state;

            case PRODUCTS_ACTION_TYPES.CLEAR_PRODUCTS_DATA:
                const loadingState = state.loading;
                state = { ...DEFAULT_PRODUCTS_STATE, loading: loadingState };
                return state;

            default:
                return state;
        }
    },
    DEFAULT_PRODUCTS_STATE
);

export default reducer;
