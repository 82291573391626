import './Scenario-Planner.scss';

import { useEffect, useState } from 'react';

import { ScenarioPlannerData } from '../../../state';

// UI COMPONENTS
import ScenarioPlannerTable from '../../../components/Charts/Scenario-Planner-Table';
import ScenarioPlannerChart from '../../../components/Charts/Scenario-Planner-Chart';
import ScenarioPlannerCalculator from '../../../components/Scenario-Planner-Calculator/Scenario-Planner-Calculator';

// COMPONENT PROPS
interface ScenarioPlannerContentProps {
    data: ScenarioPlannerData;
    isAdhoc: boolean;
}

function ScenarioPlannerContent({
    data,
    isAdhoc,
}: ScenarioPlannerContentProps) {
    // DATA STATE (IS EDITABLE)
    const [currentData, setCurrentData] = useState<ScenarioPlannerData>();

    useEffect(() => {
        setCurrentData(data);
    }, [data]);

    // HANDLE FINISHED EDITING CHANGES
    const onUpdate = (updatedData?: ScenarioPlannerData) => {
        if (updatedData) {
            // SAVE/UPDATE DATA
            setCurrentData(updatedData);
        } else {
            setCurrentData(data);
        }
    };

    return (
        <div className="scenario-planner-content">
            {!!currentData && (
                <>
                    <div className="row">
                        <ScenarioPlannerChart data={currentData} />
                    </div>
                    <div className="row">
                        <ScenarioPlannerTable
                            data={currentData}
                            onUpdate={onUpdate}
                        />
                    </div>
                    <div className="row">
                        <h3>Scenario Planner Calculator</h3>
                        <ScenarioPlannerCalculator
                            data={data}
                            isAdhoc={isAdhoc}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default ScenarioPlannerContent;
