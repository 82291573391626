import { join } from 'path';
import { ENVIRONMENT } from '../../constants';
import { AddNoteFormState, EditNotesState } from '../../state';
import {
    NoteDetails,
    POSTAddNoteBody,
    POSTEditNoteBody,
    POSTEditNoteData,
    POSTEditNoteDetails,
} from '../../state/models/notes-models';

export const formatAddNoteBody = (
    userId: string,
    formState: AddNoteFormState
) => {
    const body: POSTAddNoteBody = {
        agencyId: formState.selectedAgency,
        advertiserId: formState.selectedAdvertiser,
        notes: formState.notes,
        notesHistory: '',
        createdByUserId: userId,
        updatedByUserId: userId,
        isUpdated: '0',
        environment: ENVIRONMENT,
    };

    return new URLSearchParams({ ...body });
};

export const formatEditNotesBody = (
    editedNotes: EditNotesState,
    originalNotes: NoteDetails,
    userId: number
) => {
    const notesData: POSTEditNoteData[] = editedNotes.notesData.map(
        (edited) => {
            // FIND ORIGINAL NOTE DATA (IN NOT NEW)
            const original =
                !edited.isNew &&
                originalNotes.notesData.find(
                    (original) => original.noteId === edited.noteId
                );

            // FORMAT EDITED NOTE DATA
            const data: POSTEditNoteData = {
                noteId: edited.noteId,
                noteText: edited.noteText,
                deleteFlag: edited.deleteFlag,
                isNew: edited.isNew,
                isStrikeThrough: edited.isStrikeThrough,
                history: edited.history,
                updatedByUserId: edited.updatedByUserId,
            };

            // CHECK IF NOTE HAS BEEN UPDATED BY CURRENT USER
            if (
                original &&
                (data.noteText !== original.noteText ||
                    data.isStrikeThrough !== original.isStrikeThrough ||
                    data.deleteFlag)
            ) {
                // CHANGE UPDATED USER TO CURRENT USER
                data.updatedByUserId = userId;
            }

            return data;
        }
    );

    const editedNoteDetails: POSTEditNoteDetails = {
        agencyId: originalNotes.agencyId,
        advertiserId: originalNotes.advertiserId,
        environment: ENVIRONMENT,
        notesData,
    };

    const body: POSTEditNoteBody = {
        notes: [editedNoteDetails],
    };

    return body;
};
