import { useActions } from '../../hooks';
import { addSA360Account } from '../../api';
import React, { useEffect, useReducer } from 'react';
import { onPreventEnterKeySubmit } from '../../utils';
import {
    StatusOption,
    STATUS_OPTIONS,
    PLATFORM_NAMES,
    DEFAULT_API_ERROR,
} from '../../constants';
import {
    DropdownOption,
    sa360FormReducer,
    bingAdsFormReducer,
    SA_360_ACTION_TYPES,
    googleAdsFormReducer,
    INITIAL_SA_360_STATE,
    INITIAL_BING_ADS_FORM,
    BING_ADS_ACTION_TYPES,
    INITIAL_GOOGLE_ADS_FORM,
    GOOGLE_ADS_ACTION_TYPES,
    ExistingAccountDetails,
} from '../../state';

// UI COMPONENTS
import BingAdsForm from './Bing-Ads-Form';
import GoogleAdsForm from './Google-Ads-Form';
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Checkbox from '@publicismedia-ds/ui-checkbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import Fieldset from '@publicismedia-ds/ui-fieldset';

// COMPONENT PROPS
interface SearchAds360FormProps {
    mEmails: string[];
    onSubmit?: () => Promise<void>;
    account?: ExistingAccountDetails;
    onCancel?: () => void;
}

// FUNCTIONAL COMPONENT
function SearchAds360Form({
    mEmails,
    account,
    onSubmit,
    onCancel,
}: SearchAds360FormProps) {
    // REDUX ACTIONS
    const { setLoading, alertError, alertSuccess } = useActions();

    // LOAD ACCOUNT DATA INTO STATE FOR EDITING
    useEffect(() => {
        if (!account) return;
        // FORMAT ACCOUNT DATA INTO FORM STATE
        dispatch({
            type: SA_360_ACTION_TYPES.LOAD_FORM_DATA,
            payload: account,
        });
    }, [account]);

    // SA 360 FORM STATE
    const [sa360Form, dispatch] = useReducer(
        sa360FormReducer,
        INITIAL_SA_360_STATE
    );

    // GOOGLE ADS FORM STATE
    const [googleAdsForm, dispatchGoogleAdsForm] = useReducer(
        googleAdsFormReducer,
        INITIAL_GOOGLE_ADS_FORM
    );

    // BING ADS FORM STATE
    const [bingAdsForm, dispatchBingAdsForm] = useReducer(
        bingAdsFormReducer,
        INITIAL_BING_ADS_FORM
    );

    // HANDLE ENABLING GOOGLE ADS SUPPORT
    const onEnableGoogleAds = (
        evt: React.ChangeEvent<HTMLInputElement>,
        isChecked: boolean
    ) => {
        dispatch({
            type: SA_360_ACTION_TYPES.SET_GOOGLE_ADS_SUPPORT,
            payload: isChecked,
        });
        if (!isChecked) {
            dispatchGoogleAdsForm({
                type: GOOGLE_ADS_ACTION_TYPES.RESET_GOOGLE_ADS_FORM,
            });
        }
    };

    // HANDLE ENABLING BING ADS SUPPORT
    const onEnableBingAds = (
        evt: React.ChangeEvent<HTMLInputElement>,
        isChecked: boolean
    ) => {
        dispatch({
            type: SA_360_ACTION_TYPES.SET_BING_ADS_SUPPORT,
            payload: isChecked,
        });
        if (!isChecked) {
            dispatchBingAdsForm({
                type: BING_ADS_ACTION_TYPES.RESET_BING_ADS_FORM,
            });
        }
    };

    // HANDLE ENABLING DSA SUPPORT
    const onEnableDSA = (
        evt: React.ChangeEvent<HTMLInputElement>,
        isChecked: boolean
    ) => {
        dispatch({
            type: SA_360_ACTION_TYPES.SET_DSA_SUPPORT,
            payload: isChecked,
        });
    };

    // HANDLE ENABLING DSA SUPPORT
    const onEnablePLA = (
        evt: React.ChangeEvent<HTMLInputElement>,
        isChecked: boolean
    ) => {
        dispatch({
            type: SA_360_ACTION_TYPES.SET_PLA_SUPPORT,
            payload: isChecked,
        });
    };

    // HANDLE ENABLING REVENUE SUPPORT
    const onEnableRevenue = (
        evt: React.ChangeEvent<HTMLInputElement>,
        isChecked: boolean
    ) => {
        dispatch({
            type: SA_360_ACTION_TYPES.SET_REVENUE_SUPPORT,
            payload: isChecked,
        });
    };

    // HANDLE ENABLING CUSTOM COLUMNS (EXISTING ACCOUNTS ONLY)
    const onSetCustomColumnsSupport = (
        evt: React.ChangeEvent<HTMLInputElement>,
        isChecked: boolean
    ) => {
        dispatch({
            type: SA_360_ACTION_TYPES.SET_CUSTOM_COLUMNS_SUPPORT,
            payload: isChecked,
        });
    };

    // HANDLE MANAGER EMAIL SELECTION
    const onSelectEmail = (selected: DropdownOption) => {
        dispatch({
            type: SA_360_ACTION_TYPES.SET_MANAGER_EMAIL,
            payload: selected,
        });
    };

    // HANDLE SETTING NEW CUSTOM CONVERSION COLUMN VALUES
    const onSetCustomColumnName = (
        index: number,
        data: { value: string; status: boolean }
    ) => {};

    // HANDLE SETTING AGENCY NAME VALUE
    const onSetAgencyName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        dispatch({
            type: SA_360_ACTION_TYPES.SET_AGENCY_NAME,
            payload: value,
        });
    };

    // HANDLE SETTING AGENCY ID VALUE
    const onSetAgencyId = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        dispatch({
            type: SA_360_ACTION_TYPES.SET_AGENCY_ID,
            payload: value,
        });
    };

    // HANDLE ADDING ADDITIONAL ADVERTISER
    const onAddAdvertiser = () => {
        dispatch({
            type: SA_360_ACTION_TYPES.ADD_ADVERTISER,
        });
    };

    // HANDLE ENABLING/DISABLING EXISTING BRAND STATUS
    const onSetAdvertiserStatus = (selected: DropdownOption, index: number) => {
        dispatch({
            type: SA_360_ACTION_TYPES.UPDATE_ADVERTISER,
            payload: {
                index,
                status: selected.value === 'true',
            },
        });
    };

    // HANDLE REMOVING ADVERTISER INPUTS ROW
    const onRemoveAdvertiser = (index: number) => {
        dispatch({
            type: SA_360_ACTION_TYPES.REMOVE_ADVERTISER,
            payload: index,
        });
    };

    // ENABLE/DISABLE SUBMIT BUTTON
    let isSubmitDisabled =
        !sa360Form.sa360ManagerEmail ||
        !sa360Form.agencyId ||
        !sa360Form.agencyName ||
        !sa360Form.agencyId;

    for (let advertiser of sa360Form.advertiserData) {
        if (!advertiser.id || !advertiser.name) {
            isSubmitDisabled = true;
            break;
        }
    }

    // ENBALE/DISABLE SUBMIT BUTTON (GOOGLE)
    let isGoogleDisabled =
        sa360Form.googleAdsSupport &&
        (!googleAdsForm.adwordsMangerEmail ||
            !googleAdsForm.customerId ||
            !googleAdsForm.customerName);

    for (let subCustomer of googleAdsForm.customerData) {
        if (!sa360Form.googleAdsSupport) break;
        if (googleAdsForm.isManagerAccount === '0') break;

        if (!subCustomer.id || !subCustomer.name) {
            isGoogleDisabled = true;
            break;
        }
    }

    // ENABLE/DISABLE SUBMIT BUTTON (BING)
    let isBingDisabled =
        sa360Form.bingAdsSupport &&
        (!bingAdsForm.bingAdsManagerEmail || !bingAdsForm.agencyName);

    for (let subAccount of bingAdsForm.accountData) {
        if (!sa360Form.bingAdsSupport) break;

        if (
            !subAccount.number ||
            !subAccount.customerId ||
            !subAccount.id ||
            !subAccount.name
        ) {
            isBingDisabled = true;
            break;
        }
    }

    // HANDLE SUBMIT SA 360 FORM
    const onSubmitSA360Account = async (evt: React.FormEvent) => {
        evt.preventDefault();

        setLoading(true);
        try {
            // ADD NEW ACCOUNT
            const data = await addSA360Account({
                sa360: sa360Form,
                googleAds: googleAdsForm,
                bingAds: bingAdsForm,
            });

            alertSuccess(data?.message || 'Successfully added account.');

            dispatch({
                type: SA_360_ACTION_TYPES.RESET_SA_360_FORM,
            });
            dispatchGoogleAdsForm({
                type: GOOGLE_ADS_ACTION_TYPES.RESET_GOOGLE_ADS_FORM,
            });
            dispatchBingAdsForm({
                type: BING_ADS_ACTION_TYPES.RESET_BING_ADS_FORM,
            });

            // RUN SUBMIT CALLBACK IF PRESENT
            if (onSubmit) onSubmit();
        } catch (error: any) {
            alertError(
                error.response?.data?.errorMessage ||
                    error.response?.data?.message ||
                    DEFAULT_API_ERROR
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="search-ads-360-inputs">
            {!!account ? (
                <div>
                    <p style={{ margin: '1rem 0' }}>
                        <strong>Account Name: </strong>
                        {account.accountName}
                    </p>
                    <p style={{ margin: '1rem 0' }}>
                        <strong>Agency ID: </strong>
                        {account.agencyId}
                    </p>
                    <p style={{ margin: '1rem 0' }}>
                        <strong>Platform: </strong>
                        {account.platform}
                    </p>
                </div>
            ) : (
                <h2 className="title">Search Ads 360</h2>
            )}
            <form
                onSubmit={onSubmitSA360Account}
                onKeyDown={onPreventEnterKeySubmit}
                id={PLATFORM_NAMES.SA_360}
            >
                {/* --- CAN ONLY INCLUDE BING/GOOGLE WHEN EDITING. (IF NOT ALREADY INCLUDED) --- */}
                {(!account ||
                    !account?.bingAdsSubAccounts?.length ||
                    !account?.googleAdsSubAccounts?.length) && (
                    <div className="inputs-row">
                        <h4>Please select applicable engine account(s):</h4>
                        <Fieldset layout="horizontal">
                            {/* INCLUDE GOOGLE ADS FORM */}
                            {(!account ||
                                !account?.googleAdsSubAccounts?.length) && (
                                <Checkbox
                                    checked={sa360Form.googleAdsSupport}
                                    onChange={onEnableGoogleAds}
                                    invert
                                >
                                    Google Ads
                                </Checkbox>
                            )}

                            {/* INCLUDE BING ADS FORM */}
                            {(!account ||
                                !account?.bingAdsSubAccounts?.length) && (
                                <Checkbox
                                    checked={sa360Form.bingAdsSupport}
                                    onChange={onEnableBingAds}
                                    invert
                                >
                                    Bing Ads
                                </Checkbox>
                            )}
                        </Fieldset>
                    </div>
                )}
                {/* --- </ END > --- */}

                <div className="inputs-row">
                    <Fieldset layout="horizontal" className="support-options">
                        {/* ENABLE DSA SUPPORT */}
                        <Checkbox
                            checked={sa360Form.dsaSupport}
                            onChange={onEnableDSA}
                            invert
                        >
                            Enable Dynamic Search Ads
                        </Checkbox>

                        {/* ENABLE PLA SUPPORT */}
                        <Checkbox
                            checked={sa360Form.plaSupport}
                            onChange={onEnablePLA}
                            invert
                        >
                            Enable Product Listing Ads Support
                        </Checkbox>

                        {/* ENABLE CUSTOM REVENUE COLUMNS SUPPORT */}
                        <Checkbox
                            checked={sa360Form.isRevenueSupport}
                            onChange={onEnableRevenue}
                            invert
                        >
                            Enable Revenue Support
                        </Checkbox>

                        {/* --- DISABLE CUSTOM CONVERSION COLUMNS SUPPORT (EDITING ONLY) --- */}
                        {!!account && (
                            <Checkbox
                                checked={sa360Form.isCustomColumnsSupport}
                                onChange={onSetCustomColumnsSupport}
                                invert
                            >
                                Enable Custom Columns
                            </Checkbox>
                        )}
                        {/* --- </ END > --- */}
                    </Fieldset>
                </div>
                <div className="inputs-row">
                    {/* SELECT SA 360 MANAGER EMAIL */}
                    <Dropdown
                        options={mEmails.map((email) => ({
                            label: email,
                            value: email,
                        }))}
                        onChange={onSelectEmail}
                        value={sa360Form.sa360ManagerEmail}
                        defaultValue={sa360Form.sa360ManagerEmail}
                        isSearchable={false}
                        key="sa360Managermail"
                        required
                    >
                        Manger Email
                    </Dropdown>
                </div>

                {/* --- NEW ACCOUNTS ONLY --- */}
                {!account && (
                    <>
                        <h4 className="edit-account-header">Agency:</h4>
                        {/* AGNECY NAME (NOT EDITIBLE) */}
                        <div className="inputs-row">
                            <Textbox
                                onChange={onSetAgencyName}
                                value={sa360Form.agencyName}
                                disabled={!!account}
                                required
                            >
                                Agency Name
                            </Textbox>

                            {/* AGENCY ID (NOT EDITIBLE) */}
                            <Textbox
                                onChange={onSetAgencyId}
                                value={sa360Form.agencyId}
                                disabled={!!account}
                                required
                            >
                                Agency ID
                            </Textbox>
                        </div>
                    </>
                )}
                {/* --- </ END > --- */}

                {/* --- ACCOUNT ADVERTISERS --- */}
                <h4 className="edit-account-header">Advertisers:</h4>
                {sa360Form.advertiserData.map((data, index) => {
                    return (
                        <div className="inputs-row" key={'advertiser-' + index}>
                            {/* ADVERTISER NAME */}
                            <Textbox
                                value={data.name}
                                onChange={(
                                    evt: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    dispatch({
                                        type: SA_360_ACTION_TYPES.UPDATE_ADVERTISER,
                                        payload: {
                                            index,
                                            name: evt.target.value,
                                        },
                                    });
                                }}
                                disabled={data.existing}
                                required
                            >
                                Advertiser Name
                            </Textbox>

                            {/* ADVERTISER ID */}
                            <Textbox
                                value={data.id}
                                onChange={(
                                    evt: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    dispatch({
                                        type: SA_360_ACTION_TYPES.UPDATE_ADVERTISER,
                                        payload: {
                                            index,
                                            id: evt.target.value,
                                        },
                                    });
                                }}
                                disabled={data.existing}
                                required
                            >
                                Advertiser ID
                            </Textbox>

                            {data.status !== undefined && (
                                <Dropdown
                                    options={STATUS_OPTIONS}
                                    value={{
                                        label: '',
                                        value: data.status.toString(),
                                    }}
                                    onChange={(selected: DropdownOption) =>
                                        onSetAdvertiserStatus(selected, index)
                                    }
                                >
                                    Status
                                </Dropdown>
                            )}

                            {/* ICON TO REMOVE ADVERTISER DATA */}
                            {!data.existing && index > 0 && (
                                <i
                                    className="icon-close"
                                    onClick={() => onRemoveAdvertiser(index)}
                                />
                            )}
                        </div>
                    );
                })}
                {/* --- </ END > */}

                {/* CREATE ROW FOR ADDITIONAL ADVERTISER BUTTON */}
                <div className="insert-inputs-row-button">
                    <Button onClick={onAddAdvertiser} color="brand-2">
                        Add Advertiser <i className="icon-add" />
                    </Button>
                </div>

                {/* --- CUSTOM CONVERSION COLUMNS (OPTIONAL) --- */}
                <>
                    <h4 className="edit-account-header">
                        Custom Conversion Columns:
                    </h4>
                    {/* ACCOUNT CUSTOM CONVERSION COLUMNS (NEW/EDITING) */}
                    {sa360Form.customColumns?.map((column, index) => {
                        return (
                            <div
                                className="inputs-row"
                                key={`-conversion-column-${index}`}
                            >
                                {/* CUSTON CONVERSION COLUMN NAME */}
                                <Textbox
                                    value={column.name}
                                    onChange={(
                                        evt: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        dispatch({
                                            type: SA_360_ACTION_TYPES.UPDATE_CONVERSION_COLUMN,
                                            payload: {
                                                index,
                                                name: evt.target.value,
                                            },
                                        });
                                    }}
                                    disabled={column.existing}
                                >
                                    Conversion Column Name
                                </Textbox>

                                {/* ENABLE/DISABLE CONVERSION COLUMN SUPPORT */}
                                <Dropdown
                                    options={STATUS_OPTIONS}
                                    value={{
                                        label: '',
                                        value: column.status.toString(),
                                    }}
                                    onChange={(selected: DropdownOption) => {
                                        dispatch({
                                            type: SA_360_ACTION_TYPES.UPDATE_CONVERSION_COLUMN,
                                            payload: {
                                                index,
                                                status:
                                                    selected.value === 'true',
                                            },
                                        });
                                    }}
                                >
                                    Status
                                </Dropdown>
                                {/* ICON TO REMOVE CONVERSION COLUMN DATA (NON-EXISTING COLUMNS ONLY) */}
                                {!column.existing && (
                                    <i
                                        className="icon-close"
                                        onClick={() =>
                                            dispatch({
                                                type: SA_360_ACTION_TYPES.REMOVE_CONVERSION_COLUMN,
                                                payload: index,
                                            })
                                        }
                                    />
                                )}
                            </div>
                        );
                    })}
                    <div className="insert-inputs-row-button">
                        <Button
                            onClick={() =>
                                dispatch({
                                    type: SA_360_ACTION_TYPES.ADD_CONVERSION_COLUMN,
                                })
                            }
                            color="brand-2"
                        >
                            Add Conversion Column <i className="icon-add" />
                        </Button>
                    </div>
                </>
                {/* --- </ END > --- */}

                {/* --- EDITTING EXISTING ACCOUNT THAT INCLUDES CUSTOM REVENUE COLUMNS --- */}
                {sa360Form.isRevenueSupport && (
                    <>
                        <h4 className="edit-account-header">
                            Custom Revenue Columns:
                        </h4>
                        {/* DISPLAY EXISTING CUSTOM REVENUE COLUMNS */}
                        {sa360Form.customRevenueColumns?.map(
                            (column, index) => {
                                return (
                                    <div
                                        className="inputs-row"
                                        key={`custom-revenue-column-${index}`}
                                    >
                                        {/* CUSTON REVENUE COLUMN NAME */}
                                        <Textbox
                                            value={column.name}
                                            onChange={(
                                                evt: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                dispatch({
                                                    type: SA_360_ACTION_TYPES.UPDATE_REVENUE_COLUMN,
                                                    payload: {
                                                        index,
                                                        name: evt.target.value,
                                                    },
                                                });
                                            }}
                                            disabled={column.existing}
                                        >
                                            Revenue Column Name
                                        </Textbox>

                                        {/* ENABLE/DISABLE CONVERSION COLUMN SUPPORT */}
                                        <Dropdown
                                            options={STATUS_OPTIONS}
                                            value={{
                                                label: '',
                                                value: column.status.toString(),
                                            }}
                                            onChange={(
                                                selected: DropdownOption
                                            ) => {
                                                dispatch({
                                                    type: SA_360_ACTION_TYPES.UPDATE_REVENUE_COLUMN,
                                                    payload: {
                                                        index,
                                                        status:
                                                            selected.value ===
                                                            'true',
                                                    },
                                                });
                                            }}
                                        >
                                            Status
                                        </Dropdown>
                                        {/* ICON TO REMOVE CONVERSION COLUMN DATA */}

                                        <i
                                            className="icon-close"
                                            onClick={() =>
                                                dispatch({
                                                    type: SA_360_ACTION_TYPES.REMOVE_REVENUE_COLUMN,
                                                    payload: index,
                                                })
                                            }
                                        />
                                    </div>
                                );
                            }
                        )}
                        <div className="insert-inputs-row-button">
                            <Button
                                onClick={() => {
                                    dispatch({
                                        type: SA_360_ACTION_TYPES.ADD_REVENUE_COLUMN,
                                    });
                                }}
                                color="brand-2"
                            >
                                Add Revenue Column <i className="icon-add" />
                            </Button>
                        </div>
                    </>
                )}
                {/* --- </ END > */}

                {/* --- DISPLAY BING ADS FORM IF SELECTED --- */}
                {sa360Form.bingAdsSupport && (
                    <div style={{ marginTop: '4rem' }}>
                        <BingAdsForm
                            mEmails={mEmails}
                            account={account}
                            state={bingAdsForm}
                            dispatch={dispatchBingAdsForm}
                        />
                    </div>
                )}
                {/* --- </ END > --- */}

                {/* --- DISPLAY GOOGLE ADS FORM IF SELECTED --- */}
                {sa360Form.googleAdsSupport && (
                    <div style={{ marginTop: '4rem' }}>
                        <GoogleAdsForm
                            mEmails={mEmails}
                            state={googleAdsForm}
                            account={account}
                            dispatch={dispatchGoogleAdsForm}
                        />
                    </div>
                )}
                {/* --- </ END > --- */}

                <div className="submit-button-container">
                    {/* --- CANCEL BUTTON IF EDITING EXISTING ACCOUNT */}
                    {!!onCancel && (
                        <Button display="secondary" onClick={onCancel}>
                            Cancel
                        </Button>
                    )}

                    {/* SUBMIT FOR NEW/UPDATING ACCOUNT */}
                    <Button
                        type="submit"
                        disabled={
                            isSubmitDisabled ||
                            isGoogleDisabled ||
                            isBingDisabled
                        }
                    >
                        {!!account ? 'Update' : 'Submit'}
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default SearchAds360Form;
