import produce from 'immer';

// TYPES
import {
  CustomAlertState,
  CustomAlertAction,
  CUSTOM_ALERT_ACTION_TYPES,
} from '../form-models';

// DEFAULT FORM STATE
export const DEFAULT_CUSTOM_ALERT_STATE: CustomAlertState = {
  agencies: [],
  accountData: null,
  users: [],
  goals: [],
  agency: null,
  advertiser: null,
  alertType: null,
  xValue: '',
  yValue: '',
  minBid: '',
  maxBid: '',
};

export const customAlertReducer = produce(
  (state: CustomAlertState, action: CustomAlertAction): CustomAlertState => {
    switch (action.type) {
      case CUSTOM_ALERT_ACTION_TYPES.LOAD_AGENCIES:
        state.agencies = action.payload;
        return state;

      case CUSTOM_ALERT_ACTION_TYPES.SET_AGENCY:
        state.agency = action.payload.agency;
        state.accountData = action.payload.accountData;
        state.advertiser = null;
        state.goals = [];
        state.users = [];
        state.xValue = '';
        state.yValue = '';
        state.minBid = '';
        state.maxBid = '';
        return state;

      case CUSTOM_ALERT_ACTION_TYPES.SET_ADVERTISER:
        state.advertiser = action.payload;
        state.goals = [];
        state.users = [];
        state.xValue = '';
        state.yValue = '';
        state.minBid = '';
        state.maxBid = '';
        return state;

      case CUSTOM_ALERT_ACTION_TYPES.SET_GOALS:
        state.goals = action.payload;
        return state;

      case CUSTOM_ALERT_ACTION_TYPES.SET_USERS:
        state.users = action.payload;
        return state;

      case CUSTOM_ALERT_ACTION_TYPES.SET_ALERT_TYPE:
        state.alertType = action.payload;
        return state;

      case CUSTOM_ALERT_ACTION_TYPES.SET_X_VALUE:
        state.xValue = action.payload;
        return state;

      case CUSTOM_ALERT_ACTION_TYPES.SET_Y_VALUE:
        state.yValue = action.payload;
        return state;

      case CUSTOM_ALERT_ACTION_TYPES.SET_MIN_BID:
        state.minBid = action.payload;
        return state;

      case CUSTOM_ALERT_ACTION_TYPES.SET_MAX_BID:
        state.maxBid = action.payload;
        return state;

      case CUSTOM_ALERT_ACTION_TYPES.LOAD_EXISTING_ALERT:
        state = { ...action.payload };
        return state;

      case CUSTOM_ALERT_ACTION_TYPES.RESET_FORM: {
        const agencies = [...state.agencies];
        state = { ...DEFAULT_CUSTOM_ALERT_STATE };
        state.agencies = agencies;
        return state;
      }

      default:
        return state;
    }
  },
  DEFAULT_CUSTOM_ALERT_STATE
);
