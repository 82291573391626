import { deleteSchedule, editSchedule, getSchedules } from '../../api';
import { useEffect, useState } from 'react';
import { ScheduleDetails, ScheduleGoalFormState } from '../../state';
import { DEFAULT_API_ERROR } from '../../constants';
import { useActions, useAppSelector } from '../../hooks';

// UI COMPONENTS
import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import {
    AccordionControl,
    AccordionContent,
} from '@publicismedia-ds/ui-accordion';
import ConfirmationModal from '../Confirmation-Modal/Confirmation-Modal';
import EditScheduleModal from '../Edit-Schedule-Modal/Edit-Schedule-Modal';

interface ScheduledGoalsTableProps {
    data: ScheduleDetails[];
    onAction: () => Promise<void>;
}

function ScheduledGoalsTable({ data, onAction }: ScheduledGoalsTableProps) {
    // ACCORIAN STATE
    const [isOpen, setIsOpen] = useState(false);

    // TABLE DATA PROVIDER (SCHEDULED GOALS LIST)
    const [dataProvider, setDataProvider] = useState();

    // SELECTED SCHEDULE TO DELETE
    const [deleteSelected, setDeleteSelected] =
        useState<ScheduleDetails | null>(null);

    // SELECTED SCHEDULE TO EDIT
    const [editSelected, setEditSelected] = useState<ScheduleDetails | null>(
        null
    );

    // CURRENT USER'S ID (REDUX)
    const userId = useAppSelector(({ auth }) => auth.user?.id);

    // REDUX ACTIONS
    const { alertError, alertSuccess, setLoading } = useActions();

    // CREATE TABLE DATA PROVIDER ON INITIAL LOAD AND WHEN DATA CHANGES
    useEffect(() => {
        setDataProvider(new DataProvider({ data }));
    }, [data]);

    // HANDLE ACTION COLUMN EDIT ICON CLICK
    const onEditIconClick = (id: number) => {
        const schedule = data.find((schedule) => schedule.scheduleId === id);
        setEditSelected(schedule || null);
    };

    // HANDLE ACTION COLUMN DELETE ICON CLICK
    const onDeleteIconClick = (id: number) => {
        // FIND SCHEDULE TO BE DELETED BY ID
        const schedule = data.find((schedule) => schedule.scheduleId === id);
        setDeleteSelected(schedule || null);
    };

    // HANDLE UPDATING SCHEDULE
    const onUpdateSchedule = async (formState: ScheduleGoalFormState) => {
        if (!userId || !editSelected || !formState) return;

        setLoading(true);
        try {
            const data = await editSchedule(
                userId,
                editSelected.scheduleId,
                formState
            );

            if (data.result === 'success') {
                alertSuccess(data.message);
            } else {
                alertError(data.message || DEFAULT_API_ERROR);
            }

            // RELOAD TABLE
            await onAction();
        } catch (error: any) {
            throw error;
        } finally {
            setLoading(false);
        }
    };

    // HANDLE DELETION OF SCHEDULE
    const onDeleteSchedule = async () => {
        if (!userId || !deleteSelected) return;

        // OPEN LOADING MODAL
        setLoading(true);
        try {
            // ATTEMPT TO DELETE SCHEDULE
            const data = await deleteSchedule(
                userId,
                deleteSelected.scheduleId
            );

            if (data?.result === 'success') {
                alertSuccess(data.message);
            } else {
                alertError(data.message);
            }
            // RELOAD TABLE DATA
            await onAction();
        } catch (error: any) {
            // ALERT ERROR ON ERROR
            alertError(
                error.response?.data?.errorMessage ||
                    error.response?.data?.message ||
                    DEFAULT_API_ERROR
            );
        } finally {
            // CLOSE LOADING MODAL
            setLoading(false);
        }
    };

    return (
        <>
            <AccordionControl
                className="table-accordion-control"
                isOpen={isOpen}
                targetId="scheduled-goals-table"
                onClick={() => setIsOpen((prev) => !prev)}
            >
                <h3>Existing Scheduled Goals</h3>
            </AccordionControl>
            <AccordionContent id="scheduled-goals-table">
                <DataTable
                    className="scheduled-goals-data-table"
                    data={dataProvider}
                    stickyHead={true}
                    bindKey="scheduleId"
                    emptyState={
                        <>
                            <h2>No data available.</h2>
                        </>
                    }
                >
                    <ColumnTemplate
                        bind="scheduleName"
                        isSortable
                        minWidth={true}
                    >
                        Name
                    </ColumnTemplate>
                    <ColumnTemplate
                        bind="moduleName"
                        isSortable
                        onRender={(text: string) => (
                            <>
                                <span>{text}</span>
                            </>
                        )}
                        minWidth={true}
                    >
                        Modules
                    </ColumnTemplate>
                    <ColumnTemplate
                        bind="emails"
                        isSortable
                        onRender={(text: string) => (
                            <>
                                <span>{text}</span>
                            </>
                        )}
                        minWidth={true}
                    >
                        Emails
                    </ColumnTemplate>
                    <ColumnTemplate
                        bind="goalId"
                        isSortable
                        onRender={(text: string) => (
                            <>
                                <span>
                                    {text.split(',').length} Goal(s) Selected
                                </span>
                            </>
                        )}
                        minWidth={true}
                    >
                        Goals
                    </ColumnTemplate>
                    <ColumnTemplate
                        bind="daysToRun"
                        onRender={(text: string) => (
                            <span>{text || 'daily'}</span>
                        )}
                        isSortable
                        minWidth={true}
                    >
                        Run Day(s)
                    </ColumnTemplate>
                    <ColumnTemplate
                        bind="timeOfTheDay"
                        isSortable
                        minWidth={true}
                    >
                        Run Time
                    </ColumnTemplate>
                    <ColumnTemplate bind="createdAt" isSortable minWidth={true}>
                        Created
                    </ColumnTemplate>
                    <ColumnTemplate
                        bind="scheduleId"
                        isSortable
                        onRender={(scheduleId: number) => (
                            <>
                                <button
                                    className="action-button"
                                    onClick={() => {
                                        onEditIconClick(scheduleId);
                                    }}
                                >
                                    <i className="icon-edit" title="Edit"></i>
                                </button>
                                <button
                                    className="action-button"
                                    onClick={() => {
                                        onDeleteIconClick(scheduleId);
                                    }}
                                >
                                    <i
                                        className="icon-delete"
                                        title="Delete"
                                    ></i>
                                </button>
                            </>
                        )}
                        minWidth={true}
                    >
                        Actions
                    </ColumnTemplate>
                </DataTable>
            </AccordionContent>
            {!!deleteSelected && (
                <ConfirmationModal
                    header={`Delete Schedule: ${deleteSelected.scheduleName}`}
                    message="Are you sure you want to delete this scheduled goal?"
                    onCancel={() => setDeleteSelected(null)}
                    onConfirm={onDeleteSchedule}
                />
            )}
            {!!editSelected && (
                <EditScheduleModal
                    data={editSelected}
                    onClose={() => setEditSelected(null)}
                    onUpdate={onUpdateSchedule}
                />
            )}
        </>
    );
}

export default ScheduledGoalsTable;
