import './Account-Onboarding.scss';

import { useEffect, useState } from 'react';
import { GETExistingAccountsData } from '../../state';
import { useActions, useAsyncWrapper } from '../../hooks';

// API REQUESTS
import { downloadAccountOnboardingDoc, getExistingAccounts } from '../../api';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import AdminPage from '../../components/Admin-Page/Admin-Page';
import ExistingAccountsTable from '../../components/Charts/Existing-Accounts-Table';
import AddAccount from '../../components/Account-Onboarding-Forms/Add-Account/Add-Account';

function AccountOnboarding() {
    // HOOKS
    const asyncWrapper = useAsyncWrapper();

    // REDUX ACTIONS
    const { alertError } = useActions();

    // RAW EXISTING ACCOUNTS DATA
    const [existingAccounts, setExistingAccounts] =
        useState<GETExistingAccountsData | null>(null);

    const loadExistingAccounts = asyncWrapper(async () => {
        // FETCH EXISTING ACCOUNTS DATA
        const data = await getExistingAccounts();

        if (!data) return;

        setExistingAccounts(data);
    });

    // FETCH EXISTING ACCOUNTS DATA AND CREATE TABLE DATA PROVIDER ON INITIAL PAGE LOAD
    useEffect(() => {
        loadExistingAccounts();
    }, []);

    // DOWNLOAD ACCOUNT ONBOARDING HELP DOCUMENT
    const onDownloadHelpDocument = asyncWrapper(async () => {
        try {
            await downloadAccountOnboardingDoc();
        } catch (error: any) {
            const response = await error.response?.data?.text();
            const { errorMessage } = JSON.parse(response);
            alertError(errorMessage);
        }
    });

    // HANDLE ADD ACCOUNT SUBMISSION
    const onSubmitAccount = async () => {
        loadExistingAccounts();
    };

    return (
        <AdminPage header="Account Onboarding">
            <div className="account-onboarding-container">
                <div className="account-onboarding-document-download">
                    <Button onClick={onDownloadHelpDocument}>
                        Account Onboarding Help Document
                    </Button>
                </div>
                <div>
                    <AddAccount onSubmit={onSubmitAccount} />
                </div>
                <div>
                    <ExistingAccountsTable data={existingAccounts} />
                </div>
            </div>
        </AdminPage>
    );
}

export default AccountOnboarding;
