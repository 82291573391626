import './Advertiser-Notes-Table.scss';

import {
    NoteData,
    EditNoteData,
    EditNotesAction,
    EDIT_NOTE_ACTION_TYPES,
} from '../../../state';

import { useAppSelector } from '../../../hooks';
import React, { useEffect, useState } from 'react';

// UI COMPONENTS
import DataTable, {
    TD_TYPE,
    TD_ALIGN,
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import Button from '@publicismedia-ds/ui-button';
import Checkbox from '@publicismedia-ds/ui-checkbox';
import EditNoteTextModal from '../../Edit-Note-Text-Modal/Edit-Note-Text-Modal';

// COMPONENT PROPS
interface AdvertiserNotesTableProps {
    data: NoteData[] | EditNoteData[];
    dispatch?: React.Dispatch<EditNotesAction>;
}

// FUNCTIONAL COMPONENT
function AdvertiserNotesTable({ data, dispatch }: AdvertiserNotesTableProps) {
    // REDUX STATE
    const { user } = useAppSelector(({ auth }) => auth);

    // SELECTED NOTE TEXT TO EDIT STATE
    const [editSelected, setEditSelected] = useState<{
        text: string;
        index: number;
        isNew: boolean;
    } | null>(null);

    // NOTES DATA TABLE PROVIDER
    const [dataProvider, setDataProvider] = useState<any>([]);

    // CREATE UPDATE DATA PROVIDER ON NOTE DATA CHANGES
    useEffect(() => {
        setDataProvider(new DataProvider({ data }));
    }, [data]);

    // HANDLE UPDATING NOTE TEXT
    const onUpdateNoteText = (text: string, index: number) => {
        if (!dispatch) return;

        dispatch({
            type: EDIT_NOTE_ACTION_TYPES.SET_NOTE_TEXT,
            payload: { text, index },
        });

        setEditSelected(null);
    };

    // HANDLE UPDATING STRIKE THROUGH FLAG
    const onSetStrikeThrough = (index: number, isChecked: boolean) => {
        if (!dispatch) return;

        dispatch({
            type: EDIT_NOTE_ACTION_TYPES.SET_STRIKE_FLAG,
            payload: { index, isChecked },
        });
    };

    // HANDLE SETTING DELETE FLAG
    const onSetDeleteFlag = (index: number, isChecked: boolean) => {
        if (!dispatch) return;

        dispatch({
            type: EDIT_NOTE_ACTION_TYPES.SET_DELETE_FLAG,
            payload: { index, isChecked },
        });
    };

    // HANDLE ADDING NEW NOTE
    const onAddNote = () => {
        if (!user || !dispatch) return;

        dispatch({
            type: EDIT_NOTE_ACTION_TYPES.ADD_NEW_NOTE,
            payload: user,
        });

        setEditSelected({ text: '', index: data.length, isNew: true });
    };

    // HANDLE REMOVING NEW NOTE
    const onRemoveNote = (index: number) => {
        if (!dispatch) return;

        dispatch({
            type: EDIT_NOTE_ACTION_TYPES.REMOVE_NEW_NOTE,
            payload: index,
        });
    };

    // HANDLE EDIT NOTE CANCEL
    const onCancelEdit = () => {
        if (dispatch && editSelected && editSelected.isNew) {
            dispatch({
                type: EDIT_NOTE_ACTION_TYPES.REMOVE_NEW_NOTE,
                payload: editSelected.index,
            });
        }

        setEditSelected(null);
    };

    return (
        <div className="advertiser-notes-table">
            <DataTable
                data={dataProvider}
                stickyHead={true}
                bindKey="createdTimestamp"
                emptyState={
                    <>
                        <h2>No data available.</h2>
                    </>
                }
                above={
                    dispatch ? (
                        <div>
                            <Button onClick={onAddNote} color="brand-2">
                                Add Note
                            </Button>
                        </div>
                    ) : null
                }
            >
                {!!dispatch ? (
                    <ColumnTemplate
                        bind="createdTimestamp"
                        type={TD_TYPE.ACCORDION_CONTROL}
                        isSortable={true}
                        onRenderAccordion={(
                            index: number,
                            noteData: EditNoteData
                        ) => {
                            const hasHistory =
                                noteData.history.length > 0 && !noteData.isNew;

                            return hasHistory ? (
                                <DataTable
                                    data={noteData.history || []}
                                    bindKey="createdTimestamp"
                                >
                                    <ColumnTemplate bind="noteText">
                                        Prior Note
                                    </ColumnTemplate>
                                    <ColumnTemplate bind="updatedByUserName">
                                        Updated By
                                    </ColumnTemplate>
                                    <ColumnTemplate bind="updatedTimestamp">
                                        Updated On
                                    </ColumnTemplate>
                                </DataTable>
                            ) : (
                                <>
                                    <p>No history data is available.</p>
                                </>
                            );
                        }}
                        minWidth={true}
                    >
                        History
                    </ColumnTemplate>
                ) : (
                    <ColumnTemplate
                        bind="createdTimestamp"
                        onRender={(
                            created: string,
                            data: NoteData,
                            index: number
                        ) => (
                            <>
                                <span>{index + 1}</span>
                            </>
                        )}
                        isSortable={true}
                        minWidth={true}
                    >
                        Sr.
                    </ColumnTemplate>
                )}

                <ColumnTemplate
                    bind="noteText"
                    className="note-text-column"
                    onRender={(
                        text: string,
                        noteData: NoteData | EditNoteData,
                        index: number
                    ) => {
                        return (
                            <>
                                <p
                                    className={`wrap-column-text-normal ${
                                        noteData.isStrikeThrough
                                            ? 'text-strike-through'
                                            : ''
                                    }`}
                                >
                                    {noteData.noteText}
                                </p>
                                {!!dispatch && (
                                    <i
                                        className="icon-edit"
                                        onClick={() =>
                                            setEditSelected({
                                                text,
                                                index,
                                                isNew: false,
                                            })
                                        }
                                    />
                                )}
                            </>
                        );
                    }}
                >
                    Notes
                </ColumnTemplate>

                <ColumnTemplate
                    bind="createdTimestamp"
                    align={TD_ALIGN.CENTER}
                    onRender={(
                        createdOn: string,
                        noteData: NoteData | EditNoteData
                    ) => {
                        return (
                            <div className="timestamp-data">
                                <p>{noteData.createdByUserName}</p>
                                <p>{createdOn}</p>
                            </div>
                        );
                    }}
                    minWidth={true}
                >
                    Created
                </ColumnTemplate>

                <ColumnTemplate
                    bind="updatedTimestamp"
                    align={TD_ALIGN.CENTER}
                    onRender={(
                        updatedOn: string,
                        noteData: NoteData | EditNoteData
                    ) => {
                        return (
                            <div className="timestamp-data">
                                <p>{noteData.updatedByUserName}</p>
                                <p>{updatedOn}</p>
                            </div>
                        );
                    }}
                    minWidth={true}
                >
                    Last Updated
                </ColumnTemplate>

                {!!dispatch && (
                    <ColumnTemplate
                        bind="isStrikeThrough"
                        onRender={(
                            isStrikeThrough: boolean,
                            noteData: EditNoteData,
                            index: number
                        ) => {
                            return noteData.isNew ? (
                                <></>
                            ) : (
                                <div className="notes-checkbox">
                                    <Checkbox
                                        checked={isStrikeThrough}
                                        onChange={(
                                            evt: React.ChangeEvent<HTMLInputElement>,
                                            isChecked: boolean
                                        ) => {
                                            onSetStrikeThrough(
                                                index,
                                                isChecked
                                            );
                                        }}
                                        invert={true}
                                    >
                                        {''}
                                    </Checkbox>
                                </div>
                            );
                        }}
                        minWidth={true}
                    >
                        Strike
                    </ColumnTemplate>
                )}

                {!!dispatch && (
                    <ColumnTemplate
                        bind="deleteFlag"
                        onRender={(
                            deleteFlag: boolean,
                            noteData: EditNoteData,
                            index: number
                        ) => {
                            return noteData.isNew ? (
                                <>
                                    <i
                                        className="icon-cancel-20"
                                        onClick={() => onRemoveNote(index)}
                                    />
                                </>
                            ) : (
                                <div className="notes-checkbox">
                                    <Checkbox
                                        checked={deleteFlag}
                                        onChange={(
                                            evt: React.ChangeEvent<HTMLInputElement>,
                                            isChecked: boolean
                                        ) => {
                                            onSetDeleteFlag(index, isChecked);
                                        }}
                                        invert={true}
                                    >
                                        {''}
                                    </Checkbox>
                                </div>
                            );
                        }}
                        minWidth={true}
                    >
                        Delete
                    </ColumnTemplate>
                )}
            </DataTable>

            {!!editSelected && (
                <EditNoteTextModal
                    data={editSelected}
                    onCancel={onCancelEdit}
                    onUpdate={onUpdateNoteText}
                />
            )}
        </div>
    );
}

export default AdvertiserNotesTable;
