import { axios } from '../axios-config';
import {
    API_CUBIC_DC3,
    API_USER_SETTING3,
    UPDATE_USER_STATUS,
} from '../../constants';
import {
    formatAddUserBody,
    formatEditUserBody,
    formatMarinCronBody,
    formatSA360CronBody,
} from '../../utils';
import {
    GETAllUsers,
    GETCronStatus,
    GETRolesAccountBrands,
    MarinCronFormState,
    POSTUserResponse,
    SA360CronFromState,
    UserFormState,
} from '../../state';

export const getRolesAccountsBrands = async (userId: string) => {
    const url = API_USER_SETTING3 + '/Users/getRolesAccountBrands';
    const body = new URLSearchParams();
    body.append('userId', userId);

    try {
        const { data } = await axios.post<GETRolesAccountBrands>(url, body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        return data;
    } catch (error: any) {
        throw error;
    }
};

export const getAllUsers = async (userId: string) => {
    const url = API_USER_SETTING3 + '/Users/getAllUsers';

    const body = new URLSearchParams();
    body.append('userId', userId);

    try {
        const { data } = await axios.post<GETAllUsers>(url, body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        return data;
    } catch (error: any) {
        throw error;
    }
};

export const addUser = async (userId: string, formState: UserFormState) => {
    const url = API_USER_SETTING3 + '/Users/add';

    const formattedData = formatAddUserBody(userId, formState);

    const body = new URLSearchParams({ ...formattedData });

    try {
        const { data } = await axios.post<POSTUserResponse>(url, body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });

        return data;
    } catch (error: any) {
        throw error;
    }
};

export const updateUser = async (userId: string, formState: UserFormState) => {
    const url = API_USER_SETTING3 + '/Users/edit';

    const formatted = formatEditUserBody(userId, formState);

    const body = new URLSearchParams({ ...formatted });

    try {
        const { data } = await axios.post(url, body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        return data;
    } catch (error: any) {
        throw error;
    }
};

export const changeUserStatus = async (
    userId: string, // === user being updated
    requestId: string, // === user making request
    mode: UPDATE_USER_STATUS
) => {
    const url = API_USER_SETTING3 + '/Users/' + mode;
    const body = new URLSearchParams();
    body.append(`userId`, userId);
    body.append(`requestId`, requestId);

    try {
        const { data } = await axios.post(url, body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });

        return data;
    } catch (error: any) {
        throw error;
    }
};

export const addMarinCron = async (
    userId: string,
    formState: MarinCronFormState
) => {
    const url = API_CUBIC_DC3 + '/Marin/marinManualDataFetch';

    const formattedBody = formatMarinCronBody(userId, formState);

    if (!formattedBody) return;

    const body = new URLSearchParams({ ...formattedBody });

    try {
        const { data } = await axios.post(url, body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        return data;
    } catch (error: any) {
        throw error;
    }
};

export const addSA360Cron = async (
    userId: string,
    formState: SA360CronFromState
) => {
    const url = API_CUBIC_DC3 + '/DoubleClick/manualDataFetch';

    const formattedData = formatSA360CronBody(userId, formState);

    const body = new URLSearchParams({ ...formattedData });

    try {
        const { data } = await axios.post(url, body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        return data;
    } catch (error: any) {
        throw error;
    }
};

export const manualDataFetch = async (
    userId: any,
    reportType: string,
    accountDetails: any
) => {
    const url = API_CUBIC_DC3 + '/DoubleClick/manualDataFetch';
    const body = new URLSearchParams();
    body.append(`userId`, userId);
    body.append(`reportType`, reportType);
    body.append(`accountDetails`, accountDetails);

    try {
        const { data } = await axios.post(url, body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        return data;
    } catch (error: any) {
        throw error;
    }
};

export const getCronStatus = async (userId: string) => {
    const url = API_USER_SETTING3 + '/UniversalSettings/getCronStatus';
    try {
        const { data } = await axios.get<GETCronStatus>(url, {
            params: { userId },
        });
        return data.data;
    } catch (error: any) {
        throw error;
    }
};
