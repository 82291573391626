import {
    SetAdhocDataAction,
    ResetAdhocDataAction,
    SetAdhocTargetAction,
    SetAdhocProductAction,
    SetAdhocNumeratorAction,
    SetAdhocDenominatorAction,
    SetDisplayAdhocModalAction,
} from '../actions';

import { ADHOC_MODAL_ACTION_TYPES } from '../action-types';

import { AdhocModalProduct, ConversionType } from '../models';

export const setAdhocData = (
    product: AdhocModalProduct,
    denominator: ConversionType
): SetAdhocDataAction => {
    return {
        type: ADHOC_MODAL_ACTION_TYPES.SET_ADHOC_DATA,
        payload: { product, denominator },
    };
};

export const setDisplayAdhocModal = (
    display: boolean
): SetDisplayAdhocModalAction => {
    return {
        type: ADHOC_MODAL_ACTION_TYPES.SET_DISPLAY_ADHOC_MODAL,
        payload: display,
    };
};

export const setAdhocTarget = (target: string): SetAdhocTargetAction => {
    return {
        type: ADHOC_MODAL_ACTION_TYPES.SET_ADHOC_TARGET,
        payload: target,
    };
};

export const setAdhocNumerator = (
    numerator: string
): SetAdhocNumeratorAction => {
    return {
        type: ADHOC_MODAL_ACTION_TYPES.SET_ADHOC_NUMERATOR,
        payload: numerator,
    };
};

export const setAdhocDenominator = (
    denominator: ConversionType
): SetAdhocDenominatorAction => {
    return {
        type: ADHOC_MODAL_ACTION_TYPES.SET_ADHOC_DENOMINATOR,
        payload: denominator,
    };
};

export const setAdhocProduct = (
    product: AdhocModalProduct
): SetAdhocProductAction => {
    return {
        type: ADHOC_MODAL_ACTION_TYPES.SET_ADHOC_PRODUCT,
        payload: product,
    };
};

export const resetAdhocData = (
    product?: AdhocModalProduct
): ResetAdhocDataAction => {
    return {
        type: ADHOC_MODAL_ACTION_TYPES.RESET_ADHOC_DATA,
        payload: product,
    };
};
