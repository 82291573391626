import {
    DropdownOption,
    CUSTOM_REPORTS,
    ExistingAccount,
    marinCronFormReducer,
    INITIAL_MARIN_CRON_FORM,
    MARIN_CRON_ACTION_TYPES,
} from '../../state';
import { useActions } from '../../hooks';
import React, { useEffect, useMemo, useReducer } from 'react';
import { findDeselectedOption, onPreventEnterKeySubmit } from '../../utils';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { DEFAULT_API_ERROR } from '../../constants';

// COMPONENT PROPS
interface SA360CronFormProps {
    data: ExistingAccount[] | null;
    onSubmit: (formState: any) => Promise<void>;
}

function SA360CronForm({ data, onSubmit }: SA360CronFormProps) {
    // REDUX ACTIONS
    const { alertError } = useActions();

    const [state, dispatch] = useReducer(
        marinCronFormReducer,
        INITIAL_MARIN_CRON_FORM
    );

    // LOAD FORM OPTIONS
    useEffect(() => {
        if (!data) return;
        dispatch({
            type: MARIN_CRON_ACTION_TYPES.LOAD_ACCOUNTS_DATA,
            payload: data,
        });
    }, [data]);

    // HANDLE REPORT TYPE SELECTION
    const onSelectReportType = (selected: DropdownOption) => {
        dispatch({
            type: MARIN_CRON_ACTION_TYPES.SELECT_REPORT_TYPE,
            payload: selected,
        });
    };

    // HANDLE AGENCY SELECTION
    const onSelectAgency = (selected: DropdownOption) => {
        dispatch({
            type: MARIN_CRON_ACTION_TYPES.SELECT_AGENCY,
            payload: selected,
        });
    };

    // HANDLE SUB ACCOUNT SELECTION
    const onSelectSubAccount = (selected: DropdownOption) => {
        dispatch({
            type: MARIN_CRON_ACTION_TYPES.SELECT_SUB_ACCOUNT,
            payload: selected,
        });
    };

    // ENABLE/DISABLE SUBMIT BUTTON
    const isSubmitDisabled =
        !state.reportType || !state.selectedAgency || !state.selectedSubAccount;

    // HANDLE MARIN CRON SUBMIT
    const onSubmitMarinCron = async (evt: React.FormEvent) => {
        evt.preventDefault();

        try {
            await onSubmit(state);
            dispatch({
                type: MARIN_CRON_ACTION_TYPES.RESET_MARIN_CRON_FORM,
            });
        } catch (error: any) {
            alertError(
                error.response?.data?.message ||
                    error.response?.data?.errorMessage ||
                    DEFAULT_API_ERROR
            );
        }
    };

    return (
        <div className="cron-form-container">
            <form
                onSubmit={onSubmitMarinCron}
                onKeyUp={onPreventEnterKeySubmit}
            >
                <div className="form-row">
                    <Dropdown
                        className="form-row-item"
                        options={CUSTOM_REPORTS.map((report) => ({
                            label: report,
                            value: report,
                        }))}
                        value={state.reportType}
                        defaultValue={state.reportType}
                        onChange={onSelectReportType}
                        required
                    >
                        Report Type
                    </Dropdown>
                    <Dropdown
                        className="form-row-item"
                        options={state.agencyOptions}
                        value={state.selectedAgency}
                        defaultValue={state.selectedAgency}
                        onChange={onSelectAgency}
                        required
                    >
                        Agency
                    </Dropdown>
                    <Dropdown
                        className="form-row-item"
                        options={state.subAccountOptions}
                        value={state.selectedSubAccount}
                        defaultValue={state.selectedSubAccount}
                        onChange={onSelectSubAccount}
                        required
                    >
                        Sub Account
                    </Dropdown>
                </div>
                <div className="form-row">
                    <ul style={{ color: 'red' }}>
                        <li>Note:</li>
                        <li>1. Data will be available after 8:30 AM CST.</li>
                    </ul>
                </div>
                <div
                    className="form-row"
                    style={{
                        justifyContent: 'center',
                    }}
                >
                    <Button type="submit" disabled={isSubmitDisabled}>
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default SA360CronForm;
