import { DropdownOption } from '../../../state';
import { useActions, useAppSelector } from '../../../hooks';

// UI COMPONENTS

import SA360Advertisers from '../SA-360-Advertisers';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import SA360SupportOptions from '../SA360-Support-Options';
import SA360EngineOptions from '../SA360-Engine-Options';

interface EditSA360AccountProps {
    mEmails: DropdownOption[];
}

function EditSA360Account({ mEmails }: EditSA360AccountProps) {
    // REDUX ACTIONS
    const { setSA360Email } = useActions();

    const { sa360ManagerEmail } = useAppSelector(
        ({ editAccountForm }) => editAccountForm
    );

    // HANDLE SELECT MANAGER EMAIL
    const onSelectEmail = (selected: DropdownOption) => {
        setSA360Email(selected.value);
    };

    return (
        <div>
            <h4 className="edit-account-header">SA 360:</h4>
            <SA360EngineOptions />
            <SA360SupportOptions />
            <div className="inputs-row">
                {/* SELECT SA 360 MANAGER EMAIL */}
                <Dropdown
                    options={mEmails}
                    onChange={onSelectEmail}
                    value={{
                        label: '',
                        value: sa360ManagerEmail,
                    }}
                    defaultValue={{
                        label: '',
                        value: sa360ManagerEmail,
                    }}
                    isSearchable={false}
                    required
                >
                    SA 360 Manger Email
                </Dropdown>
            </div>
            <SA360Advertisers />
        </div>
    );
}

export default EditSA360Account;
