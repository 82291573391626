import './Loading-Modal.scss';
import CircleLoader from 'react-spinners/CircleLoader';
import Modal from '../Modal/Modal';
import { useAppSelector } from '../../hooks';
import ModalContent from '../Modal/Model-Content';

interface LoadingModalProps {
    header?: string;
}

function LoadingModal({ header = 'Loading...' }: LoadingModalProps) {
    const { loading } = useAppSelector(({ products }) => products);
    return (
        <>
            {loading && (
                <Modal userClosable={false} onClose={() => {}}>
                    <ModalContent className="loading-modal-content">
                        <h1 className="loading-modal-header">{header}</h1>
                        <CircleLoader color="#36d7b7" />
                    </ModalContent>
                </Modal>
            )}
        </>
    );
}

export default LoadingModal;
