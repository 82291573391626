import './Notes-Modal.scss';

import { NoteDetails } from '../../state';

// UI COMPONENTS
import Modal from '../Modal/Modal';
import AdvertiserNotesTable from '../Charts/Advertiser-Notes-Table/Advertiser-Notes-Table';
import ModalFrame from '../Modal/Modal-Frame';
import ModalHeader from '../Modal/Modal-Header';
import ModalContent from '../Modal/Model-Content';

// COMPONENT PROPS
interface NotesModalProps {
    notes: NoteDetails;
    onClose: () => void;
}

// FUNCTIONAL COMPONENTS
function NotesModal({ notes, onClose }: NotesModalProps) {
    return (
        <Modal header="notes" onClose={onClose}>
            <ModalFrame className="notes-modal-container">
                <ModalHeader className="notes-modal-header">
                    Notes
                    <i className="icon-close" onClick={onClose} />
                </ModalHeader>
                <ModalContent className="notes-modal-content">
                    <p className="notes-info">
                        <strong>Agency:</strong> {notes.agencyName}
                    </p>
                    <p className="notes-info">
                        <strong>Advertiser:</strong> {notes.advertiserName}
                    </p>
                    <AdvertiserNotesTable data={notes.notesData} />
                </ModalContent>
                <div className="notes-modal-container">
                    <div className="notes-modal-content"></div>
                </div>
            </ModalFrame>
        </Modal>
    );
}

export default NotesModal;
