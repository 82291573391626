// CONSTANTS
import { CUSTOM_ALERT_TYPES } from '../constants';

// TYPES
import {
    UserAlertItem,
    DropdownOption,
    EditCustomAlert,
    CustomAlertItem,
    CustomAlertState,
    GetCustomAlertFormState,
} from '../state';

export const getCustomAlertBody = (
    formState: CustomAlertState,
    userId: string
) => {
    if (
        !formState.goals ||
        !formState.yValue ||
        !formState.agency ||
        !formState.xValue ||
        !formState.alertType ||
        !formState.advertiser
    ) {
        throw new Error('Please enter values for all required fields.');
    }
    const body = new URLSearchParams();

    body.append('userIds', formState.users.map(({ value }) => value).join(','));
    body.append('goalIds', formState.goals.map(({ value }) => value).join(','));
    body.append('agencyId', formState.agency.value);
    body.append('advertiserId', formState.advertiser.value);
    body.append('createdByUserId', userId);
    body.append('alertType', formState.alertType.value.toString());
    body.append('xValue', formState.xValue);
    body.append('yValue', formState.yValue);
    body.append('minBid', formState.minBid || '0');
    body.append('maxBid', formState.maxBid || '0');

    return body;
};

export const getCustomAlertFormState = ({
    data,
    agencies,
    goalList,
    accountData,
    userOptions,
}: GetCustomAlertFormState) => {
    const uniqueIds = data.userAlertData.reduce(
        (
            obj: {
                goals: { [key: string]: string };
                users: { [key: string]: string };
            },
            alertData
        ) => {
            if (!obj.goals[alertData.goalId]) {
                obj.goals[alertData.goalId] = alertData.goalId.toString();
            }
            if (!obj.users[alertData.userId]) {
                obj.users[alertData.userId] = alertData.userId.toString();
            }

            return obj;
        },
        { goals: {}, users: {} }
    );

    const agencyDetails = agencies.find(
        ({ agencyId }) => agencyId === data.agencyId.toString()
    );

    const advertiserDetails = accountData.advertisers.find(
        ({ advertiserId }) => advertiserId === data.advertiserId.toString()
    );

    const goalsObj = goalList.reduce((obj: { [key: string]: string }, goal) => {
        if (!obj[goal.goalId]) {
            obj[goal.goalId] = goal.goalMappingName;
        }
        return obj;
    }, {});

    const goals = Object.keys(uniqueIds.goals).reduce(
        (options: DropdownOption[], goalId) => {
            if (goalsObj[goalId]) {
                options.push({
                    label: goalsObj[goalId],
                    value: goalId,
                });
            }
            return options;
        },
        []
    );

    const state: CustomAlertState = {
        agencies,
        accountData,
        advertiser: advertiserDetails
            ? {
                  label: advertiserDetails.advertiserName,
                  value: advertiserDetails.advertiserId,
              }
            : null,
        agency: agencyDetails
            ? { label: agencyDetails.agencyName, value: agencyDetails.agencyId }
            : null,
        alertType:
            CUSTOM_ALERT_TYPES.find(({ value }) => value === data.alertType) ||
            CUSTOM_ALERT_TYPES[0],
        xValue: data.userAlertData[0].userDefinedX.toString(),
        yValue: data.userAlertData[0].userDefinedY.toString(),
        minBid: data.userAlertData[0].minBid.toString(),
        maxBid: data.userAlertData[0].maxBid.toString(),
        users: Object.keys(uniqueIds.users)
            .map((userId) => {
                return userOptions.find(({ value }) => value === userId)!;
            })
            .filter((option) => !!option.value),
        goals,
    };

    return state;
};

interface ExistingGoalsAndUsers {
    goals: { [key: string]: string };
    users: { [key: string]: string };
}

// UPDATE ALERT DETAILS
export const updateAlertDetails = (
    formState: CustomAlertState,
    originalAlert: CustomAlertItem
): EditCustomAlert => {
    // GET UPDATED MIN/MAX BIDS & X/Y VALUES
    const minBid = parseFloat(formState.minBid);
    const maxBid = parseFloat(formState.maxBid);
    const userDefinedX = parseFloat(formState.xValue);
    const userDefinedY = parseFloat(formState.yValue);

    // CREATE NEW ALERT ITEM
    const createAlertItem = (
        goalId: string,
        userId: string
    ): UserAlertItem => ({
        alertId: originalAlert.alertId,
        minBid,
        maxBid,
        userDefinedX,
        userDefinedY,
        userId: parseInt(userId),
        goalId: parseInt(goalId),
        isActive: true,
    });

    // GET SELECTED GOALS
    const selectedGoals = formState.goals.reduce(
        (obj: { [key: string]: string }, { value }) => {
            obj[value] = value;
            return obj;
        },
        {}
    );

    // GET SELECTED USERS
    const selectedUsers = formState.users.reduce(
        (obj: { [key: string]: string }, { value }) => {
            obj[value] = value;
            return obj;
        },
        {}
    );

    // GET EXISTING ALERT GOALS AND USERS
    const existingGoalsAndUsers = originalAlert.userAlertData.reduce(
        (obj: ExistingGoalsAndUsers, { goalId, userId }) => {
            if (!obj.goals[goalId]) {
                obj.goals[goalId] = goalId.toString();
            }
            if (!obj.users[userId]) {
                obj.users[userId] = userId.toString();
            }
            return obj;
        },
        {
            goals: {},
            users: {},
        }
    );

    // UPDATE EXISTING USER ALERT DATA
    const mappingsData = originalAlert.userAlertData.map(
        (userAlert): UserAlertItem => {
            const { goalId, userId } = userAlert;

            // UPDATE MIN/MAX BID & X/Y VALUES
            const updatedUserAlert: UserAlertItem = {
                ...userAlert,
                minBid,
                maxBid,
                userDefinedX,
                userDefinedY,
            };

            // DISABLE IF USER OR GOAL IS NO LONGER SELECTED
            if (!selectedGoals[goalId] || !selectedUsers[userId]) {
                updatedUserAlert.isActive = false;
            }
            return updatedUserAlert;
        }
    );

    // CHECK FOR NEWLY SELECTED GOALS
    for (const goalId in selectedGoals) {
        if (!existingGoalsAndUsers.goals[goalId]) {
            // LOOP THRU SELECTED USERS
            for (const userId in selectedUsers) {
                // ADD NEW ALERT ITEM FOR NEWLY SELECTED GOAL & EVERY SELCTED USER
                const newAlertItem = createAlertItem(goalId, userId);
                mappingsData.push(newAlertItem);
            }
        }
    }

    // CHECK FOR NEWLY SELECTED USERS
    /* Newly selected goal/user combos already added in previous step */
    for (const userId in selectedUsers) {
        if (!existingGoalsAndUsers.users[userId]) {
            // LOOP THRU SELECTED GOALS
            for (const goalId in selectedGoals) {
                // ADD NEW ITEMS FOR NEWLY SELECTED USERS AND EVERY SELECTED GOAL
                if (existingGoalsAndUsers.goals[goalId]) {
                    const newAlertItem = createAlertItem(goalId, userId);
                    mappingsData.push(newAlertItem);
                }
            }
        }
    }

    // RETURN EDIT ALERT BODY
    return {
        alertId: originalAlert.alertId,
        advertiserId: originalAlert.advertiserId,
        alertType: originalAlert.alertType,
        agencyId: originalAlert.agencyId,
        createdBy: originalAlert.createdBy,
        mappings: [...mappingsData],
        isActive: originalAlert.isActive,
    };
};
