import './Adhoc-Data-Upload.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';

// TYPES
import { AdhocUploadStatusData } from '../../state';

// CONSTANTS
import { PRODUCT_NAMES, ADHOC_TEMPLATES } from '../../constants';

// API REQUESTS
import {
    uploadAdhocFiles,
    getAdhocUploadStatus,
    downloadAdhocTemplate,
} from '../../api';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import AdhocUploadStatus from './Adhoc-Upload-Status';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import AdhocAnalysisModal from '../Adhoc-Analysis-Modal/Adhoc-Analysis-Modal';

// COMPONENT PROPS
interface DataUploadProps {
    product:
        | PRODUCT_NAMES.SCENARIO_PLANNER
        | PRODUCT_NAMES.PERFORMANCE_DIAGNOSTIC;
    onUpload?: () => void;
    tabIndex: number;
}

// FORM STATE
interface UploadFilesState {
    adGroupFile: File | null;
    reportFile: File | null;
}

// DEFAULT FORM STATE
const DEFAULT_FILES_STATE: UploadFilesState = {
    adGroupFile: null,
    reportFile: null,
};

// FUNCTIONAL COMPONENT (DATA UPLOAD FORM)
function DataUpload({ product, onUpload, tabIndex }: DataUploadProps) {
    const wrapper = useAsyncWrapper();

    // SELECTED AD GROUP FILE TO UPLOAD
    const [{ adGroupFile, reportFile }, setUploadFiles] =
        useState<UploadFilesState>(DEFAULT_FILES_STATE);

    // REDUX STATE
    const userId = useAppSelector(({ auth }) => auth.user?.id);
    const isAdhocData = useAppSelector(
        ({ adhocModal }) => !!adhocModal.denominator
    );

    // FORM REFS
    const formRef = useRef<HTMLFormElement | null>(null);
    const adGroupRef = useRef<HTMLInputElement | null>(null);

    // ADHOC UPLOAD STATUS
    const [uploadStatus, setUploadStatus] = useState<AdhocUploadStatusData[]>(
        []
    );

    // REDUX ACTIONS
    const {
        alertError,
        alertSuccess,
        setAdhocData,
        setDisplayAdhocModal,
        setScenarioPlannerData,
    } = useActions();

    // FETCH AND STORE ADHOC UPLOAD STATUS DETAILS
    const loadUploadStatus = wrapper(async () => {
        if (userId) {
            const { data } = await getAdhocUploadStatus(userId);

            if (data?.length) {
                setUploadStatus(data);

                const { uploadStatus, conversionType } = data[0];

                if (uploadStatus === 'Upload Successfull' && conversionType) {
                    setAdhocData(product, conversionType);
                }
            }
        }
    });

    // GET ADHOC UPLOAD STATUS
    useEffect(() => {
        loadUploadStatus();
    }, [userId]);

    // CLEAR FILES ON TAB CHANGES
    useEffect(() => {
        if (tabIndex === 2) {
            setUploadFiles({ ...DEFAULT_FILES_STATE });
            formRef.current?.reset();
        }
    }, [tabIndex]);

    // HANDLE TEMPLATE DOWNLOAD
    const onDownloadTemplate = wrapper(async (template: ADHOC_TEMPLATES) => {
        await downloadAdhocTemplate(template);
    });

    // HANDLE AD GROUP REPORT FILE SELECTION
    const onSelectAdGroupReport = (
        evt: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = evt.target?.files?.[0];

        setUploadFiles((prev) => {
            if (file) {
                prev.adGroupFile = file;
            }
            return { ...prev };
        });
    };

    // HANDLE KEYWORD REPORT FILE SELECTION
    const onSelectKeywordReport = (
        evt: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = evt.target?.files?.[0];

        setUploadFiles((prev) => {
            if (file) {
                prev.reportFile = file;
            }
            return { ...prev };
        });
    };

    // HANDLE UPLOADING FILES
    const onUploadFiles = wrapper(async (evt: React.FormEvent) => {
        evt.preventDefault();
        if (!userId || !adGroupFile || !reportFile) return;

        const { result, msg, conversionType } = await uploadAdhocFiles(
            adGroupFile,
            reportFile,
            userId
        );

        if (result === 'success' && conversionType) {
            setAdhocData(product, conversionType);
            alertSuccess(msg);
        }

        if (result === 'in progress') {
            alertSuccess(msg);
        }

        if (result === 'failure' || !result) {
            alertError(msg || 'Upload failed.');
        }

        setScenarioPlannerData(null);
        setUploadFiles({ ...DEFAULT_FILES_STATE });
        formRef.current?.reset();
    });

    return (
        <>
            <div className="data-upload-container">
                <h3>{product} Data Upload</h3>
                <div className="data-upload-row">
                    <p className="data-upload-notes-header">Note:</p>
                    <ol className="data-upload-notes-list">
                        <li>
                            Please download the template files for Ad
                            Group/Keyword/Product Group from the link provided
                            before uploading the files.
                        </li>
                        <li>
                            Please make sure the uploaded files have the same
                            format as the template files and do not
                            change/modify the header names specified in the
                            template files.
                        </li>
                        <li>
                            If the uploaded Keyword/Product Group file contains
                            data in the "Revenue" column, then the conversion
                            type setting used will be "Revenue." Otherwise, the
                            conversion type column used will be "Actions".
                        </li>
                        <li>
                            Successfully uploaded data will be available to the
                            user for a maximum duration of 24 hours unless the
                            same user re-uploads new data.
                        </li>
                        <li>The uploaded files should be .xlsx format only.</li>
                    </ol>
                </div>
                <div className="data-upload-row">
                    <ul className="download-template-btns">
                        <li>
                            <Button
                                color="brand-2"
                                onClick={() => {
                                    onDownloadTemplate(
                                        ADHOC_TEMPLATES.AD_GROUP
                                    );
                                }}
                            >
                                Ad Group Template
                            </Button>
                        </li>
                        <li>
                            <Button
                                color="brand-2"
                                onClick={() => {
                                    onDownloadTemplate(ADHOC_TEMPLATES.KEYWORD);
                                }}
                            >
                                Keyword Template
                            </Button>
                        </li>
                        <li>
                            <Button
                                color="brand-2"
                                onClick={() => {
                                    onDownloadTemplate(
                                        ADHOC_TEMPLATES.PRODUCT_GROUP
                                    );
                                }}
                            >
                                Product Group Template
                            </Button>
                        </li>
                        <li>
                            <Button
                                color="brand-2"
                                onClick={() => {
                                    onDownloadTemplate(ADHOC_TEMPLATES.DSA);
                                }}
                            >
                                DSA Target Template
                            </Button>
                        </li>
                    </ul>
                </div>
                <div>
                    <form
                        onSubmit={onUploadFiles}
                        ref={formRef}
                        className="data-upload-form"
                    >
                        <Row className="data-upload-form-inputs">
                            <Column>
                                <div className="upload-input ad-group-report">
                                    <label>
                                        XLSX file for Ad Group report:
                                    </label>
                                    <div>
                                        <input
                                            ref={adGroupRef}
                                            type="file"
                                            id="AdGroupReport"
                                            placeholder="Upload Ad Group Report XLSX file only"
                                            onChange={onSelectAdGroupReport}
                                            required
                                        />
                                    </div>
                                </div>
                            </Column>
                            <Column>
                                <div className="upload-input">
                                    <label>
                                        XLSX file for Keyword, Product Group, or
                                        DSA report:
                                    </label>
                                    <div>
                                        <input
                                            type="file"
                                            id="KeywordReport"
                                            placeholder="Upload Keyword Report XLSX file only"
                                            onChange={onSelectKeywordReport}
                                            required
                                        />
                                    </div>
                                </div>
                            </Column>
                        </Row>
                        <div className="data-upload-row upload-submit">
                            <Button
                                type="submit"
                                value="Upload"
                                disabled={!adGroupFile || !reportFile}
                            >
                                Upload
                            </Button>
                            {isAdhocData && (
                                <Button
                                    onClick={() => setDisplayAdhocModal(true)}
                                    display="secondary"
                                    style={{ marginLeft: '.5rem' }}
                                    color="brand-2"
                                >
                                    Run Analysis
                                </Button>
                            )}
                        </div>
                    </form>
                </div>
                <div>
                    <AdhocUploadStatus
                        data={uploadStatus}
                        onReload={loadUploadStatus}
                    />
                </div>
            </div>
            <AdhocAnalysisModal onClose={onUpload} />
        </>
    );
}

export default DataUpload;
