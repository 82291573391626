import { Dispatch } from 'redux';
import { getAllNotes } from '../../api';
import { DEFAULT_API_ERROR } from '../../constants';
import { NOTES_ACTION_TYPES } from '../action-types';

import {
    AlertAction,
    NotesAction,
    SetLoadingAction,
    LoadNotesDataAction,
} from '../actions';

import { NoteDetails } from '../models';
import { alertError } from './alert-creators';
import { setLoading } from './products-creators';

const loadNotesData = (notes: NoteDetails[]): LoadNotesDataAction => {
    return {
        type: NOTES_ACTION_TYPES.LOAD_NOTES_DATA,
        payload: notes,
    };
};

export const loadAllNotes = (userId: string) => {
    return async (dispatch: Dispatch<NotesAction | AlertAction>) => {
        try {
            const data = await getAllNotes(userId);

            if (data.status === 'success') {
                dispatch(loadNotesData(data.notes));
            }
        } catch (error: any) {
            dispatch(
                alertError(
                    error.respones?.data?.errorMessage ||
                        error.respones?.data?.error_message ||
                        error.respones?.data?.message ||
                        error.respones?.data?.msg ||
                        DEFAULT_API_ERROR
                )
            );
        }
    };
};
