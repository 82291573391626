import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import {
    ConversionType,
    KeywordChangeDetails,
    ReportType,
} from '../../../state';
import { numberWithCommas } from '../../../utils';

interface TopKeywordChangeTableProps {
    data: KeywordChangeDetails[];
    reportType: ReportType;
    conversionType: ConversionType | null;
    conversionColumn: string;
}

function TopKeywordChangeTable({
    data,
    reportType,
    conversionType,
    conversionColumn,
}: TopKeywordChangeTableProps) {
    let report = 'Keywords';
    if (reportType === 'productGroup') report = 'Product Group';
    if (reportType === 'DSA') report = 'DSA Target';

    return (
        <>
            <DataTable
                data={new DataProvider({ data })}
                bindKey={'cost'}
                stickyHead
                emptyState={
                    <>
                        <h2>No data available.</h2>
                    </>
                }
                hasVirtualScrolling={false}
            >
                <ColumnTemplate bind="keyword" isSortable minWidth>
                    Keyword
                </ColumnTemplate>
                <ColumnTemplate
                    bind="bidsChangePercentage"
                    type="number"
                    align="center"
                    onRender={(text: string) => (
                        <span>{numberWithCommas(text, 2)}%</span>
                    )}
                    isSortable
                    minWidth
                >
                    Bids Change
                </ColumnTemplate>
                <ColumnTemplate
                    bind="cost"
                    type="currency"
                    align="center"
                    isSortable
                    minWidth
                >
                    Cost
                </ColumnTemplate>
                <ColumnTemplate
                    bind="conversionColumn"
                    align="center"
                    type="number"
                    isSortable
                    onRender={(num: string) => {
                        let value = numberWithCommas(num);

                        if (!conversionType) {
                            value = '$' + value;
                        }

                        return <span>{value}</span>;
                    }}
                    minWidth
                >
                    {conversionColumn.toLowerCase()}
                </ColumnTemplate>
                <ColumnTemplate
                    bind="efficiency"
                    type="currency"
                    align="center"
                    isSortable
                    minWidth
                >
                    Efficiency
                </ColumnTemplate>
            </DataTable>
        </>
    );
}

export default TopKeywordChangeTable;
