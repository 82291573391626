import { axios } from './axios-config';
import { API_USERS } from '../constants';
import { LoginResponse } from '../state';

export const userLogin = async (email: string, password: string) => {
    const url = API_USERS + '/authenticate';

    try {
        const { data } = await axios.post<LoginResponse>(
            url,
            {},
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                params: {
                    email,
                    password,
                },
            }
        );
        return data;
    } catch (error) {
        throw error;
    }
};
