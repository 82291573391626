// TYPES
import { FormulaColumnItem, PacingDocState } from '../../state';

// UI COMPONENTS
import { Row } from '@publicismedia-ds/ui-grid';
import FormulaColumnForm from './Formula-Column-Form';
import FormulaColumnsTable from './Formula-Columns-Table';

// COMPONENT PROPS
interface FormulaColumnsProps {
    state: PacingDocState;
    onRemove: (index: number) => void;
    onAdd: (formState: FormulaColumnItem) => void;
    onUpdate: (formula: FormulaColumnItem, index: number) => void;
}

// FUNCTIONAL COMPONENT (PACING DOC CUSTOM FORMULAS)
function FormulaColumns({
    state,
    onAdd,
    onRemove,
    onUpdate,
}: FormulaColumnsProps) {
    // JSX
    return (
        <div className="formula-columns-container">
            <Row>
                <h4 className="formula-columns-header">
                    Custom Formula Columns:
                </h4>
            </Row>
            <Row className="formula-columns-content">
                <FormulaColumnForm
                    columnOptions={state.columnOptions}
                    onSubmit={onAdd}
                />
            </Row>
            <Row>
                <FormulaColumnsTable
                    data={state.formulaColumns}
                    onRemove={onRemove}
                    onUpdate={onUpdate}
                />
            </Row>
        </div>
    );
}

export default FormulaColumns;
