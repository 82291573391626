import './Performance-Diagnostic.scss';

import { useAppSelector } from '../../../hooks';

// UI COMPONENTS
import { Row, Column } from '@publicismedia-ds/ui-grid';
import Product from '../../../components/Product/Product';
import BulkActions from '../../../components/Bulk-Actions/Bulk-Actions';
import PerformanceDiagnosticContent from './Performance-Diagnostic-Content';

// FUNCTIONAL COMPONENT
function PerformanceDiagnostic() {
    // REDUX STATE
    const { data } = useAppSelector(
        ({ products }) => products.performanceDiagnostic
    );

    return (
        <>
            <Product>
                <Row cols={3}>
                    <Column from={3} to={4}>
                        <BulkActions />
                    </Column>
                </Row>
                {data ? (
                    <>
                        <PerformanceDiagnosticContent data={data} />
                    </>
                ) : (
                    <h2>No Data Available</h2>
                )}
            </Product>
        </>
    );
}

export default PerformanceDiagnostic;
