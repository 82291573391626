import { memo } from 'react';
import { useActions, useAppSelector } from '../../../hooks';

// UI COMPONENTS
import CustomColumn from '../Custom-Column';
import Button from '@publicismedia-ds/ui-button';

// COMPONENT PROPS
interface EditCustomColumnsProps {
    type: 'conversion' | 'revenue';
}

// FUNCTIONAL COMPONENT
function EditCustomColumns({ type }: EditCustomColumnsProps) {
    // REDUX ACTIONS
    const { addCustomColumn, addCustomRevenue } = useActions();

    // REDUX STATE
    const { customColumns, customRevenueColumns } = useAppSelector(
        ({ editAccountForm }) => editAccountForm
    );

    // SET COLUMNS VALUE BY TYPE
    const columns =
        type === 'conversion' ? customColumns : customRevenueColumns;

    // HANDLE ADDING CUSTOM COLUMN
    const onAddColumn = () => {
        if (type === 'conversion') {
            addCustomColumn();
        }
        if (type === 'revenue') {
            addCustomRevenue();
        }
    };

    return (
        <div>
            <h4 className="edit-account-header">Custom {type} Columns:</h4>
            {columns.map((data, index) => {
                return (
                    <CustomColumn
                        data={data}
                        index={index}
                        type={type}
                        key={`custom-${type}-column-${index}`}
                    />
                );
            })}
            {/* CREATES INPUT GROUP FOR ADDITIONAL CUSTOM COLUMN */}
            <div className="insert-inputs-row-button">
                <Button onClick={onAddColumn} color="brand-2">
                    New Custom Column <i className="icon-add" />
                </Button>
            </div>
        </div>
    );
}

export default memo(EditCustomColumns);
