import { useEffect, useMemo, useState } from 'react';
import { ROUTES } from '../../router/router';
import { useLocation } from 'react-router-dom';
import { useActions, useAppSelector } from '../../hooks';

// TYPES
import { DropdownOption } from '../../state';

// UI COMPONENTS
import Textbox from '@publicismedia-ds/ui-textbox';
import { Column } from '@publicismedia-ds/ui-grid';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import DatePicker from '@publicismedia-ds/ui-datepicker';
import InputErrorMessage from '../Input-Error-Message/Input-Error-Message';

// COMPONENT PROPS
interface SettingsDateRangeProps {
    label?: string;
    placeholder?: string;
    longRun?: boolean;
}

// CUSTOM SELECTION DROPDOWN OPTIONS
const CUSTOM_OPTIONS: DropdownOption[] = [
    { label: 'Custom Days', value: 'days' },
    { label: 'Custom Date Range', value: 'date range' },
];

// PRE-DEFINED DATE RANGE DROPDOWN OPTIONS
const DATE_RANGE_OPTIONS: DropdownOption[] = [
    { label: 'Today', value: '0' },
    { label: 'Last 7 Days', value: '7' },
    { label: 'Last 14 Days', value: '14' },
    { label: 'Last 30 Days', value: '30' },
    { label: 'Last 60 Days', value: '60' },
    { label: 'Last N Days', value: 'custom' },
];

// FUNCTIONAL COMPONENT (DATE RANGE SETTINGS)
function SettingsDateRange({
    label,
    placeholder,
    longRun,
}: SettingsDateRangeProps) {
    const { pathname } = useLocation();

    // REDUX STATE
    const { goalId, flightEnd } = useAppSelector(
        ({ universalSettings }) => universalSettings
    );

    // CURRENT PAGE IS MATCH TYPE ANALYSIS
    const isMatchTypeAnalysis =
        pathname === ROUTES.MATCH_TYPE_STRATEGY_ANALYSIS;

    // CURRENT PAGE IS SCENARIO PLANNER OR PERFORMANCE DIAGNOSTIC
    const includesCustomDateRange =
        pathname === ROUTES.SCENARIO_PLANNER ||
        pathname === ROUTES.PERFORMANCE_DIAGNOSTIC;

    // GOAL FLIGHT DATES
    const flightEndDate = flightEnd ? new Date(flightEnd) : new Date();

    // PRIOR DAY'S DATE
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    // MAX DATE ALLOWED
    const maxDate = flightEndDate < yesterday ? flightEndDate : yesterday;

    // MINIMUN DATE ALLOWED
    const minRange = isMatchTypeAnalysis ? 89 : 179;
    const minDate = new Date(yesterday);
    minDate.setHours(0, 0, 0, 0);
    minDate.setDate(minDate.getDate() - minRange);

    // CUSTOM "N DAYS" SELECTION
    const [customSelection, setCustomSelection] =
        useState<DropdownOption | null>(null);

    // CHECK IF CURRENT PRODUCT IS YIELD OPTIMIZER
    const isYieldOptimizer = pathname === ROUTES.YIELD_OPTIMIZER;

    // SET OPTIONS BASED ON DUAL TIME
    let options = longRun
        ? DATE_RANGE_OPTIONS.filter(({ value }) => value !== '0')
        : [...DATE_RANGE_OPTIONS];

    // FILTER VALID OPTIONS
    if (!isYieldOptimizer) {
        options = options.filter(
            ({ value }) => value !== '0' && value !== '60'
        );
    }

    // REDUX STATE
    const {
        customEndDate,
        customStartDate,
        darwinLastNDays,
        darwinLastNDaysLR,
    } = useAppSelector((state) => state.universalSettings);

    // REDUX ACTIONS
    const {
        updateLastNDays,
        updateLastNDaysLR,
        updateCustomEndDate,
        updateCustomStartDate,
    } = useActions();

    const days = longRun ? darwinLastNDaysLR : darwinLastNDays;

    const startDate = customStartDate ? new Date(customStartDate) : '';
    const endDate = customEndDate ? new Date(customEndDate) : '';

    // HANDLE UPDATING CUSTOM START DATE
    const onSetCustomStartDate = (date: Date) => {
        updateCustomStartDate(date);
    };

    // HANDLE UPDATING CUSTOM END DATE
    const onSetCustomEndDate = (date: Date) => {
        updateCustomEndDate(date);
    };

    const isValidCustomStart =
        customStartDate && new Date(customStartDate) >= minDate;

    // SET INITIAL CUSTOM SELECTION
    useEffect(() => {
        if (
            isMatchTypeAnalysis ||
            (includesCustomDateRange && darwinLastNDays === 0)
        ) {
            setCustomSelection(CUSTOM_OPTIONS[1]);
        } else {
            setCustomSelection(null);
        }
    }, [goalId]);

    const dateRange = customSelection
        ? { label: 'Custom', value: 'custom' }
        : options.find(({ value }) => value === days.toString()) || '';

    // VALIDATE SHORT RUN DAYS ARE LESS THAN LONG RUN DAYS
    const isValidShortRun = longRun
        ? darwinLastNDaysLR > darwinLastNDays
        : true;

    return (
        <>
            {!isMatchTypeAnalysis && (
                <Column>
                    <Dropdown
                        placeholder={placeholder || 'Date Range'}
                        options={options}
                        value={dateRange}
                        defaultValue={dateRange}
                        onChange={(selected: DropdownOption) => {
                            if (selected.value === 'custom') {
                                // SET CUSTOM OPTION TO "DAYS" BY DEFAULT
                                setCustomSelection(CUSTOM_OPTIONS[0]);
                                return;
                            }

                            if (longRun) {
                                updateLastNDaysLR(parseInt(selected.value));
                            } else {
                                updateLastNDays(parseInt(selected.value));
                            }

                            if (customSelection) setCustomSelection(null);
                        }}
                        isSearchable={false}
                        required
                    >
                        {label || 'Date Range'}
                    </Dropdown>
                    {!isValidShortRun && (
                        <p
                            style={{
                                margin: '0 0 0 .125rem',
                                fontSize: '0.8rem',
                                color: 'red',
                            }}
                        >
                            Value must be greater than Short-Run
                        </p>
                    )}
                </Column>
            )}

            {!!customSelection && (
                <>
                    {(!!includesCustomDateRange || !!isMatchTypeAnalysis) && (
                        <Column>
                            <Dropdown
                                options={
                                    isMatchTypeAnalysis
                                        ? [CUSTOM_OPTIONS[1]]
                                        : CUSTOM_OPTIONS
                                }
                                onChange={(selected: DropdownOption) => {
                                    setCustomSelection(selected);

                                    if (selected.value === 'date range') {
                                        updateLastNDays(0);
                                    }
                                }}
                                value={customSelection}
                                defaultValue={customSelection}
                                isSearchable={false}
                                required
                            >
                                Custom Range
                            </Dropdown>
                        </Column>
                    )}

                    {customSelection.value === 'days' && (
                        <Column>
                            <Textbox
                                type="number"
                                min="1"
                                max="90"
                                value={days || ''}
                                onChange={(
                                    evt: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (longRun) {
                                        updateLastNDaysLR(
                                            parseInt(evt.target.value)
                                        );
                                    } else {
                                        updateLastNDays(
                                            parseInt(evt.target.value)
                                        );
                                    }
                                }}
                                required
                                error="Please enter a value 1 thru 90"
                            >
                                {label ? `Custom - ${label}` : 'Custom Days'}
                            </Textbox>
                        </Column>
                    )}

                    {customSelection.value === 'date range' && (
                        <>
                            <Column>
                                <DatePicker
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    onChange={onSetCustomStartDate}
                                    value={startDate}
                                    focus={!isValidCustomStart}
                                    required
                                >
                                    Start Date:
                                </DatePicker>
                                <InputErrorMessage
                                    display={startDate < minDate}
                                >
                                    Please select a date within the last{' '}
                                    {minRange + 1} days.
                                </InputErrorMessage>
                            </Column>
                            <Column>
                                <DatePicker
                                    minDate={
                                        minDate < startDate
                                            ? startDate
                                            : minDate
                                    }
                                    maxDate={maxDate}
                                    onChange={onSetCustomEndDate}
                                    value={endDate}
                                    required
                                >
                                    End Date:
                                </DatePicker>
                                <InputErrorMessage display={endDate < minDate}>
                                    Please select a date within the last{' '}
                                    {minRange + 1} days.
                                </InputErrorMessage>
                            </Column>
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default SettingsDateRange;
