import type { SessionData, UserAllowedFeatures, UserData } from '../state';

export const formatUserData = (data: SessionData) => {
    // FORMAT ALLOWED FEATURES
    const features = data.features?.reduce(
        (allowedFeatures: UserAllowedFeatures, feature) => {
            allowedFeatures[feature.feature_id.toString()] =
                feature.feature_name;
            return allowedFeatures;
        },
        {}
    ) || { all: 'all features' };

    // CREATE USER OBJECT
    const user: UserData = {
        ...data,
        accounts: data.accounts ? (JSON.parse(data.accounts) as string[]) : [],
        features,
    };

    return user;
};
