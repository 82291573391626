import './App.scss';
import Login from './pages/Login/Login';
import { Routes, Route } from 'react-router-dom';

import {
    ROUTES,
    adminRoutes,
    productsRoutes,
    protectedRoutes,
    superAdminRoutes,
} from './router/router';

// UI COMPONENTS
import Navbar from './components/NavBar/Navbar';
import Products from './pages/Products/Products';
import AdminRoutes from './components/Admin-Routes';
import PageTracker from './components/Page-Tracker';
import PrivateRoutes from './components/PrivateRoutes';
import SuperAdminRoutes from './components/Super-Admin-Routes';
import PageNotFound from './pages/Page-Not-Found/Page-Not-Found';
import ResetPassword from './pages/ResetPassword/Reset-Password';
import LoadingModal from './components/Loading-Modal/Loading-Modal';
import AlertMessages from './components/Alert-Messages/Alert-Messages';

function App() {
    return (
        <main>
            <Navbar />
            <AlertMessages />
            <PageTracker>
                <Routes>
                    <Route element={<PrivateRoutes />}>
                        {protectedRoutes.map((route, index) => {
                            return <Route {...route} key={index} />;
                        })}
                        <Route path={ROUTES.PRODUCTS} element={<Products />}>
                            {productsRoutes.map((route, index) => {
                                return <Route {...route} key={index} />;
                            })}
                        </Route>
                        <Route element={<AdminRoutes />}>
                            {adminRoutes.map((route, index) => {
                                return <Route {...route} key={index} />;
                            })}
                        </Route>
                        <Route element={<SuperAdminRoutes />}>
                            {superAdminRoutes.map((route, index) => {
                                return <Route {...route} key={index} />;
                            })}
                        </Route>
                    </Route>
                    <Route path={ROUTES.LOGIN} element={<Login />} />
                    <Route
                        path={ROUTES.RESET_PASSWORD}
                        element={<ResetPassword />}
                    />
                    <Route path={ROUTES.NOT_FOUND} element={<PageNotFound />} />
                </Routes>
            </PageTracker>
            <LoadingModal />
        </main>
    );
}

export default App;
