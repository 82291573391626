// TYPES
import { BidChangeDetails, ConversionType, ReportType } from '../../../state';

// UI COMPONENTS
import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';

// UTILS
import { numberWithCommas } from '../../../utils';

// COMPONENT PROPS
interface BidChangeTableProps {
    data: BidChangeDetails[];
    conversionColumn: string;
    conversionType: ConversionType | null;
    reportType: ReportType;
}

// FUNCTIONAL COMPONENT (BID CHANGE TABLE)
function BidChangeTable({
    data,
    conversionColumn,
    conversionType,
    reportType,
}: BidChangeTableProps) {
    let report = 'Keywords';
    if (reportType === 'productGroup') report = 'Product Group';
    if (reportType === 'DSA') report = 'DSA Target';

    return (
        <div className="bid-change-table">
            <DataTable
                data={new DataProvider({ data })}
                bindKey={'cost'}
                stickyHead
                emptyState={
                    <>
                        <h2>No data available.</h2>
                    </>
                }
                hasVirtualScrolling={false}
            >
                {/* BID CHANGE DIRECTION */}
                <ColumnTemplate bind="Bid Change Direction" isSortable minWidth>
                    Bid Change Direction
                </ColumnTemplate>

                {/* NUMBER OF KEYWORDS */}
                <ColumnTemplate
                    bind="Number of Keywords"
                    align="center"
                    type="number"
                    onRender={(num: number) => (
                        <span>{numberWithCommas(num)}</span>
                    )}
                    isSortable
                    minWidth
                >
                    Number of Keywords
                </ColumnTemplate>

                {/* COST */}
                <ColumnTemplate
                    bind="cost"
                    type="currency"
                    align="center"
                    isSortable
                    minWidth
                >
                    Cost
                </ColumnTemplate>

                {/* CONVERSION COLUMN */}
                <ColumnTemplate
                    bind="conversionColumn"
                    type="number"
                    align="center"
                    onRender={(num: number) => {
                        let value = numberWithCommas(num);
                        if (!conversionType) {
                            value = '$' + value;
                        }
                        return <span>{value}</span>;
                    }}
                    isSortable
                    minWidth
                >
                    {conversionColumn.toLowerCase()}
                </ColumnTemplate>

                {/* EFFICIENCY */}
                <ColumnTemplate
                    bind="efficiency"
                    type="currency"
                    align="center"
                    isSortable
                    minWidth
                >
                    Efficiency
                </ColumnTemplate>
            </DataTable>
        </div>
    );
}

export default BidChangeTable;
