import React from 'react';
import { useActions, useAppSelector } from '../../hooks';

// UI COMPONENTS
import Fieldset from '@publicismedia-ds/ui-fieldset';
import Checkbox from '@publicismedia-ds/ui-checkbox';

function SA360EngineOptions() {
    // REDUX ACTIONS
    const { setBingAdsSupport, setGoogleAdsSupport } = useActions();

    // REDUX STATE
    const {
        isGoogleAdsSupport,
        isBingAdsSupport,
        editIsBingAdsSupport,
        editIsGoogleAdsSupport,
    } = useAppSelector(({ editAccountForm }) => editAccountForm);

    //HANDLE GOOLGE ADS OPTION CLICK
    const onClickGoogleAds = (
        evt: React.ChangeEvent<HTMLInputElement>,
        isChecked: boolean
    ) => {
        setGoogleAdsSupport(isChecked);
    };

    //HANDLE BING ADS OPTION CLICK
    const onClickBingAds = (
        evt: React.ChangeEvent<HTMLInputElement>,
        isChecked: boolean
    ) => {
        setBingAdsSupport(isChecked);
    };

    return (
        <>
            {(!isGoogleAdsSupport || !isBingAdsSupport) && (
                <div className="inputs-row">
                    <p style={{ flexGrow: '0', alignSelf: 'flex-end' }}>
                        <strong>
                            Please select applicable engine account(s):
                        </strong>
                    </p>
                    <Fieldset layout="horizontal">
                        {!isGoogleAdsSupport && (
                            <Checkbox
                                checked={editIsGoogleAdsSupport}
                                onChange={onClickGoogleAds}
                                invert={true}
                            >
                                Google Ads
                            </Checkbox>
                        )}
                        {!isBingAdsSupport && (
                            <Checkbox
                                checked={editIsBingAdsSupport}
                                onChange={onClickBingAds}
                                invert={true}
                            >
                                Bing Ads
                            </Checkbox>
                        )}
                    </Fieldset>
                </div>
            )}
        </>
    );
}

export default SA360EngineOptions;
