import { statSync } from 'fs';
import produce from 'immer';
import { EditAccountAction } from '../actions';
import { formatEditAccountData } from '../../utils';
import { EDIT_ACCOUNT_ACTION_TYPES } from '../action-types';
import { EditAccountState, INITIAL_EDIT_ACCOUNT_STATE } from '../forms';

export const editAccountReducer = produce(
    (state: EditAccountState, action: EditAccountAction): EditAccountState => {
        switch (action.type) {
            case EDIT_ACCOUNT_ACTION_TYPES.SET_DSA_SUPPORT:
                state.isDSASupport = action.payload;
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.SET_PLA_SUPPORT:
                state.isPLASupport = action.payload;
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.SET_CUSTOM_COLUMNS_SUPPORT:
                state.isCustomColumnSupport = action.payload;
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.SET_GOOGLE_SUPPORT:
                state.editIsGoogleAdsSupport = action.payload;
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.SET_BING_SUPPORT:
                state.editIsBingAdsSupport = action.paylaod;
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.ADD_CUSTOM_COLUMN:
                state.customColumns.push({
                    name: '',
                    status: true,
                    existing: false,
                });
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.UPDATE_CUSTOM_COLUMN: {
                const { index, name, status } = action.payload;

                if (state.customColumns[index]) {
                    if (name !== undefined) {
                        state.customColumns[index].name = name;
                    }

                    if (status !== undefined) {
                        state.customColumns[index].status = status;
                    }
                }
                return state;
            }

            case EDIT_ACCOUNT_ACTION_TYPES.REMOVE_CUSTOM_COLUMN:
                state.customColumns = state.customColumns.filter(
                    (data, index) => {
                        return data.existing || index !== action.payload;
                    }
                );

                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.SET_REVENUE_SUPPORT:
                state.isRevenueSupport = action.payload;
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.ADD_REVENUE_COLUMN:
                state.customRevenueColumns.push({
                    name: '',
                    status: true,
                    existing: false,
                });
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.UPDATE_REVENUE_COLUMN: {
                const { index, name, status } = action.payload;

                if (state.customRevenueColumns[index]) {
                    if (name !== undefined) {
                        state.customRevenueColumns[index].name = name;
                    }

                    if (status !== undefined) {
                        state.customRevenueColumns[index].status = status;
                    }
                }

                return state;
            }

            case EDIT_ACCOUNT_ACTION_TYPES.REMOVE_REVENUE_COLUMN:
                state.customRevenueColumns = state.customRevenueColumns.filter(
                    (data, index) => {
                        return data.existing || index !== action.payload;
                    }
                );
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.SET_SA360_MANAGER_EMAIL:
                state.sa360ManagerEmail = action.payload;
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.ADD_SA360_ADVERTISER:
                state.advertisers.push({
                    id: '',
                    name: '',
                    status: true,
                    existing: false,
                });
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.UPDATE_SA360_ADVERTISER: {
                const { index, id, name, status } = action.payload;

                if (state.advertisers[index]) {
                    if (id !== undefined) {
                        state.advertisers[index].id = id;
                    }

                    if (name !== undefined) {
                        state.advertisers[index].name = name;
                    }

                    if (status !== undefined) {
                        state.advertisers[index].status = status;
                    }
                }
                return state;
            }

            case EDIT_ACCOUNT_ACTION_TYPES.REMOVE_SA360_ADVERTISER:
                state.advertisers = state.advertisers.filter((data, index) => {
                    return data.existing || index !== action.payload;
                });
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.SET_BING_MANAGER_EMAIL:
                state.bingAdsManagerEmail = action.payload;
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.ADD_BING_SUB_ACCOUNT:
                state.bingAdsSubAccounts.push({
                    id: '',
                    name: '',
                    number: '',
                    customerId: '',
                    status: true,
                    existing: false,
                });
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.UPDATE_BING_SUB_ACCOUNT: {
                const { index, id, name, number, customerId, status } =
                    action.payload;

                if (state.bingAdsSubAccounts[index]) {
                    if (id !== undefined) {
                        state.bingAdsSubAccounts[index].id = id;
                    }
                    if (name !== undefined) {
                        state.bingAdsSubAccounts[index].name = name;
                    }
                    if (number !== undefined) {
                        state.bingAdsSubAccounts[index].number = number;
                    }
                    if (customerId !== undefined) {
                        state.bingAdsSubAccounts[index].customerId = customerId;
                    }
                    if (status !== undefined) {
                        state.bingAdsSubAccounts[index].status = status;
                    }
                }
                return state;
            }

            case EDIT_ACCOUNT_ACTION_TYPES.REMOVE_BING_SUB_ACCOUNT:
                state.bingAdsSubAccounts = state.bingAdsSubAccounts.filter(
                    (data, index) => {
                        return data.existing || index !== action.payload;
                    }
                );
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.SET_IS_MANAGER_ACCOUNT:
                state.editIsManagerAccount = action.paylaod;
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.SET_CUSTOMER_ID:
                state.customerId = action.payload;
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.SET_CUSTOMER_NAME:
                state.customerName = action.payload;
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.SET_CUSTOMER_STATUS:
                state.customerStatus = action.paylaod;
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.SET_GOOGLE_MANAGER_EMAIL:
                state.adwordsManagerEmail = action.payload;
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.ADD_GOOGLE_SUB_ACCOUNT:
                state.googleAdsSubAccounts.push({
                    id: '',
                    name: '',
                    status: true,
                    existing: false,
                });
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.UPDATE_GOOGLE_SUB_ACCOUNT: {
                const { index, id, name, status } = action.payload;

                if (state.googleAdsSubAccounts[index]) {
                    if (id !== undefined) {
                        state.googleAdsSubAccounts[index].id = id;
                    }
                    if (name !== undefined) {
                        state.googleAdsSubAccounts[index].name = name;
                    }
                    if (status !== undefined) {
                        state.googleAdsSubAccounts[index].status = status;
                    }
                }

                return state;
            }

            case EDIT_ACCOUNT_ACTION_TYPES.REMOVE_GOOGLE_SUB_ACCOUNT:
                state.googleAdsSubAccounts = state.googleAdsSubAccounts.filter(
                    (data, index) => {
                        return data.existing || index !== action.payload;
                    }
                );
                return state;

            case EDIT_ACCOUNT_ACTION_TYPES.UPDATE_KENSHOO_REPORT: {
                const { index, id } = action.payload;

                if (state.kenshooReportDetails[index]) {
                    state.kenshooReportDetails[index].report_id = id;
                }
                return state;
            }

            case EDIT_ACCOUNT_ACTION_TYPES.LOAD_ACCOUNT_DATA:
                state = formatEditAccountData(action.payload);
                return state;

            default:
                return state;
        }
    },
    INITIAL_EDIT_ACCOUNT_STATE
);
