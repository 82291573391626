import { FormEvent, useState } from 'react';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';

// TYPES
import {
    YOBulksheetOption,
    CreateBulkSheetParams,
    CreateCappingBulkSheetParams,
} from '../../state';

// API REQUESTS
import {
    createBulkSheet,
    uploadSA360BulkSheet,
    createCappingBulkSheet,
    uploadModifiedFileSA360,
    createFormattedBidsBulkSheet,
    createFormattedCappingBulkSheet,
} from '../../api';

// UI COMPONENTS
import Modal from '../Modal/Modal';
import Button from '@publicismedia-ds/ui-button';
import MoreMenu from '@publicismedia-ds/ui-more-menu';
import ConfirmationModal from '../Confirmation-Modal/Confirmation-Modal';
import BulksheetLogsTable from '../Charts/Yield-Optimizer-Charts/Bulksheet-Logs-Table';

interface YieldOptimizerBulkActionsProps {
    bulksheetOption: YOBulksheetOption;
    bInsights: string;
    cInsights: string;
}

function YieldOptimizerBulkActions({
    bulksheetOption,
    bInsights,
    cInsights,
}: YieldOptimizerBulkActionsProps) {
    const wrapper = useAsyncWrapper();

    const downloadBids = bulksheetOption.toLowerCase().includes('bids');
    const downloadCaps = bulksheetOption.toLowerCase().includes('caps');

    // DISPLAY MODAL STATES
    const [confirmUpload, setConfirmUpload] = useState(false);
    const [bulksheetLogsTable, setBulksheetLogsTable] = useState(false);
    const [uploadModifiedFile, setUploadModifiedFile] = useState(false);

    const [modifiedFile, setModifiedFile] = useState<File>();

    // REDUX STATE
    const { user } = useAppSelector((state) => state.auth);
    const platformId = useAppSelector(
        ({ goals }) => goals.goalSettings?.platformId
    );
    const { userId, goals, optimizationStrategy } = useAppSelector(
        (state) => state.universalSettings
    );

    // REDUX ACTIONS
    const { alertError, alertSuccess, setLoading } = useActions();

    // DISPLAY DOWNLOAD BUTTON ONLY ROLE ID OF: 1 - 2 - 4
    const displayDownloadButton =
        user?.roleId === '1' || user?.roleId === '2' || user?.roleId === '4';

    // DISPLAY ACTIONS MENU IF PLATFORM ID IS 1 AND ROLE ID IS 1 or 2
    const displayActions =
        (user?.roleId === '1' || user?.roleId === '2') && platformId === 1;

    // DOWNLOAD FULL BIDS/CAPS BULKSHEETS
    const onDownloadFullData = wrapper(async () => {
        if (!goals) return;

        // DOWNLOAD BIDS
        try {
            if (downloadBids) {
                const params: CreateBulkSheetParams = {
                    userId,
                    goalId: goals.goalId,
                    strategy: optimizationStrategy,
                    bInsights,
                };
                await createBulkSheet(goals.goalMappingName, params);
            }
        } catch (error: any) {
            const response = await error.response?.data?.text();
            const { msg } = JSON.parse(response);
            alertError(msg || 'Unable to download bulksheet.');
        }

        // DOWNLOAD CAPS
        try {
            if (downloadCaps) {
                const params: CreateCappingBulkSheetParams = {
                    userId,
                    goalId: goals.goalId,
                    strategy: optimizationStrategy,
                    cInsights,
                };
                await createCappingBulkSheet(goals.goalMappingName, params);
            }
        } catch (error: any) {
            const response = await error.response?.data?.text();
            const { msg } = JSON.parse(response);
            alertError(msg || 'Unable to download bulksheet.');
        }
    });

    // DOWNLOAD EDITTED BIDS/CAPS BULKSHEETS
    const onDownloadEdittedData = async () => {
        if (!goals) return;
        try {
            // DOWNLOAD BIDS
            if (downloadBids) {
                const params: CreateBulkSheetParams = {
                    userId,
                    goalId: goals.goalId,
                    strategy: optimizationStrategy,
                    bInsights,
                };
                const foo = await createFormattedBidsBulkSheet(
                    goals.goalMappingName,
                    params
                );
            }

            // DOWNLOAD CAPS
            if (downloadCaps) {
                const params: CreateCappingBulkSheetParams = {
                    userId,
                    goalId: goals.goalId,
                    strategy: optimizationStrategy,
                    cInsights,
                };
                const bar = await createFormattedCappingBulkSheet(
                    goals.goalMappingName,
                    params
                );
            }
        } catch (error: any) {
            const response = await error.response?.data?.text();
            const { msg } = JSON.parse(response);
            alertError(msg || 'Unable to download data.');
        }
    };

    // CONFIRM UPLOAD BULKSHEET FILES DIRECTLY TO SA360
    const onUploadToSA360 = async () => {
        setConfirmUpload(false);

        if (!goals) return;
        try {
            setLoading(true);
            await uploadSA360BulkSheet(
                userId,
                goals.goalId,
                optimizationStrategy
            );
            alertSuccess('Upload successful.');
        } catch (error: any) {
            alertError(
                error.response.data.errorMessage || 'Unable to upload files.'
            );
        } finally {
            setLoading(false);
        }
    };

    // UPLOAD MODIFIED FILE TO SA360
    const onUploadModifiedFile = async (evt: FormEvent) => {
        evt.preventDefault();
        if (!modifiedFile || !user) return;
        try {
            const data = await uploadModifiedFileSA360(user.id, modifiedFile);
            if (data.status === 'success') {
                alertSuccess(data.result || 'Successfully uploaded reports.');
            } else {
                alertError(data.result || 'Unable to upload files.');
            }
        } catch (error: any) {
            alertError(error.response?.data?.error_message);
        }
    };

    return (
        <div className="bulk-actions-container">
            {displayDownloadButton && (
                <MoreMenu
                    trigger={
                        <Button
                            color="brand-2"
                            className="bulk-actions-btn btn-download-report"
                        >
                            Download Report
                        </Button>
                    }
                >
                    <button onClick={onDownloadFullData}>
                        Download Bid/Cap Recommendations (FULL)
                    </button>
                    <button onClick={onDownloadEdittedData}>
                        Download Bid/Cap Recommendations (EDITTED)
                    </button>
                </MoreMenu>
            )}
            {displayActions && (
                <MoreMenu
                    trigger={
                        <Button color="brand-2" className="bulk-actions-btn">
                            Actions
                        </Button>
                    }
                >
                    <button onClick={() => setConfirmUpload(true)}>
                        Upload data directly to SA360
                    </button>
                    <button onClick={() => setUploadModifiedFile(true)}>
                        Modify and Upload data to SA360
                    </button>
                    <button onClick={() => setBulksheetLogsTable(true)}>
                        Bulksheet Logs
                    </button>
                </MoreMenu>
            )}

            {confirmUpload && (
                <ConfirmationModal
                    message="Are you sure you want to upload the files to SA360?"
                    onCancel={() => setConfirmUpload(false)}
                    onConfirm={onUploadToSA360}
                />
            )}
            {bulksheetLogsTable && (
                <Modal
                    onClose={() => setBulksheetLogsTable(false)}
                    header="Bulksheet Logs"
                    content={
                        <div className="bulksheet-logs-table">
                            <button
                                className="modal-close"
                                onClick={() => setBulksheetLogsTable(false)}
                            >
                                X
                            </button>
                            <BulksheetLogsTable />
                        </div>
                    }
                />
            )}
            {uploadModifiedFile && (
                <Modal
                    userClosable={false}
                    onClose={() => setUploadModifiedFile(false)}
                    header="Select modified file to upload:"
                    content={
                        <div className="upload-modified-file">
                            <form
                                onSubmit={onUploadModifiedFile}
                                id="form-upload-modified-file"
                            >
                                <input
                                    type="file"
                                    onChange={(evt) => {
                                        if (evt.target.files?.length) {
                                            setModifiedFile(
                                                evt.target.files[0]
                                            );
                                        }
                                    }}
                                    required
                                />
                            </form>
                        </div>
                    }
                    footer={
                        <>
                            <Button
                                display="text"
                                onClick={() => setUploadModifiedFile(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                form="form-upload-modified-file"
                                display="primary"
                            >
                                Upload File
                            </Button>
                        </>
                    }
                />
            )}
        </div>
    );
}

export default YieldOptimizerBulkActions;
