import './Manage-Users.scss';

import { useEffect, useState } from 'react';
import { useAppSelector, useActions, useAsyncWrapper } from '../../hooks';

// CONSTANTS
import { DEFAULT_API_ERROR } from '../../constants';

// API REQUESTS
import { addUser, getAllUsers, getRolesAccountsBrands } from '../../api';

// TYPES
import { GETRolesAccountBrands, UserDetails, UserFormState } from '../../state';

// UI COMPONENTS
import AdminPage from '../../components/Admin-Page/Admin-Page';
import ExistingUsersTable from '../../components/Charts/Existing-Users-Table';
import ManageUserForm from '../../components/Manage-User-Form/Manage-User-Form';

// FUNCTIONAL COMPONENT
function ManageUsers() {
    // HOOKS
    const asyncWrapper = useAsyncWrapper();

    // REDUX STATE
    const userId = useAppSelector(({ auth }) => auth.user?.id);

    // REDUX ACTIONS
    const { alertError, alertSuccess } = useActions();

    // EXISTING USERS
    const [users, setUsers] = useState<UserDetails[]>([]);

    // USER ACCESS OPTIONS
    const [accessData, setAccessData] = useState<GETRolesAccountBrands | null>(
        null
    );

    // FETCH AND STORE EXISITNG USER
    const loadExistingUsers = asyncWrapper(async () => {
        if (!userId) return;

        const response = await getAllUsers(userId);
        setUsers(response.data);
    });

    // LOAD EXITING USER DATA ON INITIAL RENEDER
    useEffect(() => {
        loadExistingUsers();
    }, []);

    // FETCH AND STORE ACCESS OPTIONS -- (ROLES, FEATURES, AND ACCOUNTS DATA)
    const loadAccessOptions = asyncWrapper(async function () {
        if (!userId) return;

        const data = await getRolesAccountsBrands(userId);
        setAccessData(data);
    });

    // LOAD USER ACCESS OPTIONS ON INITIAL RENDER
    useEffect(() => {
        loadAccessOptions();
    }, [userId]);

    // HANDLE ADD USER FORM SUBMISSION
    const onSubmitUser = asyncWrapper(async (state: UserFormState) => {
        if (!userId) return;

        const data = await addUser(userId, state);

        if (data.result === 'success') {
            alertSuccess(data.message);
            loadExistingUsers();
        } else {
            alertError(data?.message || DEFAULT_API_ERROR);
        }
    });

    return (
        <AdminPage>
            <div className="manage-users-container">
                <div className="add-user-form">
                    <ManageUserForm
                        accessData={accessData}
                        onSubmit={onSubmitUser}
                    />
                </div>
                <div className="existing-users-table">
                    <ExistingUsersTable
                        accessData={accessData}
                        data={users}
                        onAction={async () => loadExistingUsers()}
                    />
                </div>
            </div>
        </AdminPage>
    );
}

export default ManageUsers;
