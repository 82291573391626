import produce from 'immer';

import { BidHeadroomState } from '../../models';
import { BidHeadroomAction, BID_HEADROOM_ACTION_TYPES } from '../form-models';

// DEFAULT BID HEADROOM STATE
export const DEFAULT_BID_HEADROOM_STATE: BidHeadroomState = {
  agency: null,
  advertiser: null,
  conversionType: null,
  conversionColumn: null,
  revenueColumn: null,
  currentPage: 0,
  rowsPerPage: 25,
  dataFetching: {},
  isValidColumn: false,
  data: null,
};

// BID HEADROOM REDUCER
export const bidHeadroomReducer = produce(
  (state: BidHeadroomState, action: BidHeadroomAction): BidHeadroomState => {
    switch (action.type) {
      case BID_HEADROOM_ACTION_TYPES.SET_AGENCY: {
        const tempDataFetching = { ...state.dataFetching };
        state = { ...DEFAULT_BID_HEADROOM_STATE };
        state.agency = action.payload;
        state.dataFetching = tempDataFetching;
        return state;
      }

      case BID_HEADROOM_ACTION_TYPES.SET_ADVERTISER: {
        const tempAgency = state.agency;
        const tempDataFetching = { ...state.dataFetching };
        state = { ...DEFAULT_BID_HEADROOM_STATE };

        state.agency = tempAgency;
        state.advertiser = action.payload;
        state.dataFetching = tempDataFetching;

        return state;
      }

      case BID_HEADROOM_ACTION_TYPES.SET_CONVERSION_TYPE:
        state.conversionType = action.payload;
        state.conversionColumn = null;
        state.revenueColumn = null;
        return state;

      case BID_HEADROOM_ACTION_TYPES.SET_CONVERSION_COLUMN:
        state.conversionColumn = action.payload;
        return state;

      case BID_HEADROOM_ACTION_TYPES.SET_IS_VALID_COLUMN:
        state.isValidColumn = action.payload;
        return state;

      case BID_HEADROOM_ACTION_TYPES.SET_REVENUE_COLUMN:
        state.revenueColumn = action.payload;
        return state;

      case BID_HEADROOM_ACTION_TYPES.SET_CURRENT_PAGE:
        state.currentPage = action.payload;
        return state;

      case BID_HEADROOM_ACTION_TYPES.SET_ROWS_PER_PAGE:
        state.rowsPerPage = action.payload;
        return state;

      case BID_HEADROOM_ACTION_TYPES.SET_DATA_FETCHING_STATUS: {
        const { agencyId, advertiserId, status } = action.payload;

        if (!state.dataFetching[agencyId]) {
          state.dataFetching[agencyId] = {};
        }

        state.dataFetching[agencyId][advertiserId] = status;
        return state;
      }

      case BID_HEADROOM_ACTION_TYPES.SET_SCAN_DATA:
        state.data = action.payload;
        return state;

      case BID_HEADROOM_ACTION_TYPES.RESET_STATE: {
        const tempDataFetching = { ...state.dataFetching };
        state = { ...DEFAULT_BID_HEADROOM_STATE };
        state.dataFetching = { ...tempDataFetching };
        return state;
      }

      default:
        return state;
    }
  },
  DEFAULT_BID_HEADROOM_STATE
);
