import './Bullet-Chart.scss';

import { Bar } from '@publicismedia-ds/ui-charts';
import { formatLargeNumber, numberWithCommas } from '../../../utils';
import { ApexOptions } from 'apexcharts';
import { useAppSelector } from '../../../hooks';

export interface BulletChartProps {
    header: string;
    target: number;
    actual: number;
}

function BulletChart({ header, target, actual }: BulletChartProps) {
    const useWT = useAppSelector(
        ({ universalSettings }) => universalSettings.useWT
    );

    const options: ApexOptions = {
        chart: {
            height: '130',
            width: '100%',
            type: 'bar',
            toolbar: {
                show: false,
                tools: {
                    download: false,
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        colors: ['#00E396'],
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
            showForSingleSeries: true,
            customLegendItems: ['Actual', 'Expected'],
            markers: {
                fillColors: ['#00E396', '#775DD0'],
            },
        },
        xaxis: {
            tickAmount: 5,
            labels: {
                formatter: (value: string) => {
                    return formatLargeNumber(value, { decimals: 0 });
                },
            },
        },
        yaxis: {
            min: target < 0 ? target : 0,
        },
    };

    const data = [
        {
            name: 'Actual',
            data: [
                {
                    x: '',
                    y: actual,
                    goals: [
                        {
                            name: 'Target',
                            value: target,
                            strokeWidth: 6,
                            strokeHeight: 20,
                            strokeColor: '#775DD0',
                        },
                    ],
                },
            ],
        },
    ];

    return (
        <div className="bullet-chart">
            <div className="bullet-chart-header">
                <h4>{header}</h4>
            </div>
            <Bar data={data || []} options={options} />
            <div className="bullet-chart-content">
                <div className="bullet-chart-item">
                    <p>Actual:</p>
                    <p>${numberWithCommas(actual, 2)}</p>
                </div>
                <div className="bullet-chart-item">
                    <p>{useWT ? 'Weighted' : ''}Target:</p>
                    <p>${numberWithCommas(target, 2)}</p>
                </div>
                <div className="bullet-chart-item">
                    <p>% To target:</p>
                    <p>
                        {numberWithCommas(Math.round((actual / target) * 100))}%
                    </p>
                </div>
            </div>
        </div>
    );
}

export default BulletChart;
