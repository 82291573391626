import React, { useEffect, useMemo } from 'react';
import { useActions, useAppSelector } from '../../hooks';

// TYPES
import {
    DropdownOption,
    YOBulksheetOption,
    OptimizationStrategy,
    CalculationMethodType,
    OPTIMIZATION_STRATEGYS,
} from '../../state';

// UI COMPONENTS
import Radio from '@publicismedia-ds/ui-radio';
import Textbox from '@publicismedia-ds/ui-textbox';
import Checkbox from '@publicismedia-ds/ui-checkbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import Fieldset from '@publicismedia-ds/ui-fieldset';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import ProductSettings from './Product-Settings-Container';
import SettingsDateRange from '../../components/Product-Settings/Settings-Date-Range';

// UTILS
import { convertDateToString, convertStringToDate } from '../../utils';

function YieldOptimizerSettings() {
    // REDUX STATE
    const settings = useAppSelector((state) => state.universalSettings);
    const { goalId } = settings;

    // REDUX ACTIONS
    const {
        updateMinimumBid,
        updateMaximumBid,
        updateUseWeightedFlag,
        updateOptimizationType,
        updateAdjustHighDbFlag,
        updateCalculationMethod,
        updateExcludeFlightDates,
        updateMultipleTimePeriod,
        updateMinimumBidHeadroom,
        updateMaximumBidHeadroom,
        updateMinimumDailyBudget,
        updateMaximumDailyBudget,
        updateOptimizeBudgetCaps,
        updateOptimizationStrategy,
        setYieldOptimizerBulksheet,
    } = useActions();

    const maxExclusionDate = new Date();

    const minExclusionDate = new Date();

    if (settings.multipleTimePeriod) {
        minExclusionDate.setDate(
            maxExclusionDate.getDate() - settings.darwinLastNDaysLR
        );
    } else {
        minExclusionDate.setDate(
            maxExclusionDate.getDate() - settings.darwinLastNDays
        );
    }

    // SET DEFAULT OPTIMIZATION TYPE IF NOT PRESENT
    useEffect(() => {
        if (!settings.optimizationType) {
            updateOptimizationType('bidsAndCaps');
        }
        setYieldOptimizerBulksheet(false);
    }, [settings.goalId]);

    const exclusionOptions = useMemo(() => {
        const options: DropdownOption[] = [];
        let numDays = settings.multipleTimePeriod
            ? settings.darwinLastNDaysLR
            : settings.darwinLastNDays;

        if (isNaN(numDays)) {
            numDays = 0;
        }

        Array.from(Array(numDays).keys()).forEach((num) => {
            const date = new Date();
            date.setDate(date.getDate() - num);
            options.push({
                label: date.toDateString().slice(4),
                value: convertDateToString(date),
            });
        });
        return options;
    }, [
        settings.multipleTimePeriod,
        settings.darwinLastNDays,
        settings.darwinLastNDaysLR,
    ]);

    const exludedDates = useMemo(() => {
        if (!settings.excludeFlightDates) return [];

        const selected = settings.excludeFlightDates
            .split(',')
            .map((dateString) => {
                return {
                    label: convertStringToDate(dateString),
                    value: dateString,
                };
            });

        return selected;
    }, [settings.excludeFlightDates]);

    // ENABLE/DISABLE "APPLY BUTTON"
    const disableApply =
        !settings.goalId ||
        !settings.budget ||
        !settings.flightEnd ||
        !settings.flightStart ||
        !settings.conversionType ||
        !settings.efficiencyTarget ||
        !settings.conversionColumn ||
        !settings.efficiencyNumerator ||
        !settings.efficiencyDenominator ||
        !settings.optimizationStrategy ||
        (settings.multipleTimePeriod &&
            settings.darwinLastNDaysLR <= settings.darwinLastNDays) ||
        (settings.multipleTimePeriod && !settings.calculationMethod) ||
        (settings.conversionType === 'Revenue' && !settings.revenueColumn) ||
        new Date(settings.flightEnd) < new Date(new Date().toDateString());

    return (
        <ProductSettings disabled={disableApply}>
            <div className="yield-optimizer-settings-header">
                <h3>Yield Optimizer Settings</h3>
                <Checkbox
                    checked={settings.multipleTimePeriod}
                    onChange={(evt: any, checked: boolean) => {
                        updateMultipleTimePeriod(checked);
                    }}
                    invert
                    toggle
                >
                    Use Dual-Time Period?
                </Checkbox>
            </div>
            <Row cols={4}>
                {/* LONG RUN DATE RANGE - ONLY IF MULTIPLE TIME PERIOD */}
                {settings.multipleTimePeriod && (
                    <SettingsDateRange longRun label="Long-Run Date Range" />
                )}

                {/* SHORT RUN DATE RANGE */}
                <SettingsDateRange
                    label={
                        settings.multipleTimePeriod
                            ? 'Short-Run Date Range'
                            : ''
                    }
                />

                {/* EXCLUSION DATES */}
                <Column>
                    <Dropdown
                        options={exclusionOptions}
                        onChange={(selected: DropdownOption[]) => {
                            updateExcludeFlightDates(selected);
                        }}
                        multiple={true}
                        value={exludedDates}
                        display="selectionInline"
                    >
                        Exclude Dates:
                    </Dropdown>
                </Column>

                {/* CALCULATION METHOD - ONLY IF MULTIPLE TIME PERIOD */}
                {settings.multipleTimePeriod && (
                    <Column>
                        <Dropdown
                            options={[
                                {
                                    label: 'Estimated Index',
                                    value: 'estimatedIndex',
                                },
                                {
                                    label: 'Custom-Weight',
                                    value: 'customWeight',
                                },
                            ]}
                            onChange={(selected: DropdownOption) => {
                                updateCalculationMethod(
                                    selected.value as CalculationMethodType
                                );
                            }}
                            value={{
                                label: '',
                                value: settings.calculationMethod || '',
                            }}
                            defaultValue={{
                                label: '',
                                value: settings.calculationMethod || '',
                            }}
                            required
                        >
                            Calculation Method
                        </Dropdown>
                    </Column>
                )}

                <Column>
                    <Dropdown
                        options={OPTIMIZATION_STRATEGYS.map((strat) => {
                            return {
                                label: strat,
                                value: strat,
                            };
                        })}
                        onChange={(selected: {
                            label: string;
                            value: OptimizationStrategy;
                        }) => {
                            updateOptimizationStrategy(selected.value);
                        }}
                        value={{
                            label: '',
                            value: settings.optimizationStrategy || '',
                        }}
                        defaultValue={{
                            label: '',
                            value: settings.optimizationStrategy || '',
                        }}
                        required
                    >
                        Optimization Strategy
                    </Dropdown>
                </Column>

                <Column>
                    <Textbox
                        type="number"
                        min="0"
                        onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            updateMinimumBid(evt.target.value);
                        }}
                        value={goalId ? settings.minBid : ''}
                    >
                        Minimum Bid
                    </Textbox>
                </Column>

                <Column>
                    <Textbox
                        type="number"
                        min="0"
                        onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            updateMaximumBid(evt.target.value);
                        }}
                        value={goalId ? settings.maxBid : ''}
                    >
                        Max Bid
                    </Textbox>
                </Column>

                <Column>
                    <Textbox
                        type="number"
                        min="0"
                        onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            updateMinimumDailyBudget(
                                parseFloat(evt.target.value)
                            );
                        }}
                        value={goalId ? settings.minDailyBudget : ''}
                    >
                        Minimum Daily Budget
                    </Textbox>
                </Column>

                <Column>
                    <Textbox
                        type="number"
                        min="0"
                        onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            updateMaximumDailyBudget(
                                parseFloat(evt.target.value)
                            );
                        }}
                        value={goalId ? settings.maxDailyBudget : ''}
                    >
                        Maximum Daily Budget
                    </Textbox>
                </Column>

                <Column>
                    <Textbox
                        type="number"
                        min="0"
                        onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            updateMinimumBidHeadroom(
                                parseFloat(evt.target.value)
                            );
                        }}
                        value={goalId ? settings.minBidHeadRoom : ''}
                    >
                        Minimum Bid Headroom
                    </Textbox>
                </Column>

                <Column>
                    <Textbox
                        type="number"
                        min="0"
                        onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            updateMaximumBidHeadroom(
                                parseFloat(evt.target.value)
                            );
                        }}
                        value={goalId ? settings.maxBidHeadRoom : ''}
                    >
                        Maximum Bid Headroom
                    </Textbox>
                </Column>

                <Column>
                    <Fieldset
                        legend="File"
                        asChoiceTabs
                        onChange={(
                            selected: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            updateOptimizationType(
                                selected.target.value as YOBulksheetOption
                            );
                        }}
                    >
                        <Radio
                            name="fileOption"
                            value="caps"
                            asButton
                            checked={settings.optimizationType === 'caps'}
                            readOnly
                            invert
                        >
                            Caps
                        </Radio>
                        <Radio
                            name="fileOption"
                            asButton
                            value="bidsAndCaps"
                            checked={
                                settings.optimizationType === 'bidsAndCaps'
                            }
                            readOnly
                            invert
                        >
                            Caps/Bids
                        </Radio>
                        <Radio
                            name="fileOption"
                            asButton
                            value="bids"
                            checked={settings.optimizationType === 'bids'}
                            readOnly
                            invert
                        >
                            Bids
                        </Radio>
                    </Fieldset>
                </Column>

                <Column>
                    <Checkbox
                        className="radio-toggle"
                        checked={settings.useWT}
                        onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean
                        ) => {
                            updateUseWeightedFlag(checked);
                        }}
                        toggle
                        invert
                    >
                        {settings.useWT ? 'Weighted' : 'Actual'} Target
                    </Checkbox>
                </Column>

                <Column>
                    <Checkbox
                        className="radio-toggle"
                        checked={settings.adjustHighDbFlag}
                        onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean
                        ) => {
                            updateAdjustHighDbFlag(checked);
                        }}
                        invert
                        toggle
                    >
                        Adjust Daily Budget Threshold
                    </Checkbox>
                </Column>

                <Column>
                    <Checkbox
                        className="radio-toggle optimize-budget"
                        onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean
                        ) => {
                            updateOptimizeBudgetCaps(checked);
                        }}
                        toggle
                        invert
                    >
                        Optimize Budget Caps for Efficiency
                    </Checkbox>
                </Column>
            </Row>
        </ProductSettings>
    );
}

export default YieldOptimizerSettings;
