import produce from 'immer';
import {
  SCHEDULE_GOAL_TYPES,
  DATE_TO_RUN_OPTIONS,
  ScheduleGoalFormState,
  EXECUTION_WAYS_OPTIONS,
  ScheduleGoalFormAction,
  INITIAL_SCHEDULE_GOAL_FORM,
} from '../form-models';

export const scheduleGoalFormReducer = produce(
  (
    state: ScheduleGoalFormState = INITIAL_SCHEDULE_GOAL_FORM,
    action: ScheduleGoalFormAction
  ): ScheduleGoalFormState => {
    switch (action.type) {
      case SCHEDULE_GOAL_TYPES.SET_SCHEDULE_NAME:
        state.scheduleName = action.payload;
        return state;

      case SCHEDULE_GOAL_TYPES.SET_SELECTED_GOALS:
        state.selectedGoals = action.payload;
        return state;

      case SCHEDULE_GOAL_TYPES.SET_SELECTED_MODULES:
        state.selectedModules = action.payload;
        return state;

      case SCHEDULE_GOAL_TYPES.SET_EXECUTION_WAY:
        state.executionWay = action.payload;
        state.frequency = null;
        state.daysToRun = [];
        state.timeOfDay = null;
        return state;

      case SCHEDULE_GOAL_TYPES.SET_FREQUENCY:
        state.frequency = action.payload;
        state.daysToRun = [];
        state.timeOfDay = null;
        return state;

      case SCHEDULE_GOAL_TYPES.SET_DAYS_TO_RUN:
        state.daysToRun = action.payload;
        return state;

      case SCHEDULE_GOAL_TYPES.SET_TIME_OF_DAY:
        state.timeOfDay = action.payload;
        return state;

      case SCHEDULE_GOAL_TYPES.ADD_EMAIL:
        state.emails.push(action.payload);
        return state;

      case SCHEDULE_GOAL_TYPES.REMOVE_EMAIL:
        state.emails = state.emails.filter((email) => email !== action.payload);
        return state;

      case SCHEDULE_GOAL_TYPES.RESET_FORM:
        state = { ...INITIAL_SCHEDULE_GOAL_FORM };
        return state;

      case SCHEDULE_GOAL_TYPES.LOAD_SCHEDULE_DATA: {
        const { schedule, goals } = action.payload;
        const goalsObj = goals.reduce(
          (output: { [key: string]: string }, goal) => {
            output[goal.goalId] = goal.goalMappingName;
            return output;
          },
          {}
        );

        // RESET FORM
        state = { ...INITIAL_SCHEDULE_GOAL_FORM };

        state.scheduleName = schedule.scheduleName;

        // CONVERT SELECTED GOALS STRING TO DROPDOWN OPTIONS ARRAY
        state.selectedGoals = schedule.goalId
          .split(',')
          .map((id) => ({ label: goalsObj[id] || '', value: id }));

        // CONVERT SELECTED MODULES STRING TO DROPDOWN OPTIONS ARRAY
        state.selectedModules = schedule.moduleName
          .split(',')
          .map((module) => ({ label: module, value: module }));

        // CONVERT EXECUTION WAY STRING TO DROPDOWN OPTION
        state.executionWay =
          EXECUTION_WAYS_OPTIONS.find(
            ({ value }) => value === schedule.executionWay
          ) || null;

        // CONVERT FREQUENCY STRING TO DROPDOWN OPTION
        state.frequency = schedule.frequency
          ? {
              label:
                schedule.frequency[0].toUpperCase() +
                schedule.frequency.slice(1),
              value: schedule.frequency,
            }
          : null;

        // CONVERT DAYS TO RUN STRING TO DROPDOWN OPTION ARRAY (WEEKLY)
        if (schedule.frequency === 'weekly') {
          state.daysToRun = schedule.daysToRun.split(',').map((day) => ({
            label: day[0].toUpperCase() + day.slice(1),
            value: day,
          }));
        }

        // CONVERT DAYS TO RUN STRING TO DROPDOWN OPTION ARRAY (MONTHLY)
        if (schedule.frequency === 'monthly') {
          state.daysToRun = DATE_TO_RUN_OPTIONS.filter(
            ({ value }) => value === schedule.daysToRun
          );
        }

        // CONVERT TIME OF THE DAY STRING TO DROPDOWN OPTION
        state.timeOfDay = schedule.timeOfTheDay
          ? {
              label: schedule.timeOfTheDay + ', CST',
              value: schedule.timeOfTheDay,
            }
          : null;

        // CONVERT EMAILS STRING TO ARRAY
        state.emails = schedule.emails.split(',');

        return state;
      }

      default:
        return state;
    }
  },
  INITIAL_SCHEDULE_GOAL_FORM
);
