import './Bid-Headroom-Scan.scss';

import { FormEvent, useEffect, useReducer, useState } from 'react';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';

// API REQUESTS
import {
  getAccountsData,
  getUserAgencies,
  verifyCustomColumn,
  getKeywordsByMonth,
  getScanBidAndBidHeadRoom,
  downloadScannedBidAndBidHeadroomBulkSheet,
} from '../../api';

// TYPES
import {
  AccountData,
  ConversionType,
  DropdownOption,
  bidHeadroomReducer,
  DEFAULT_BID_HEADROOM_STATE,
  DownloadBidHeadroomBulkSheetParams,
} from '../../state';

// CONSTANTS
import { DEFAULT_API_ERROR, TODAY } from '../../constants';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import AdminPage from '../../components/Admin-Page/Admin-Page';
import BidHeadroomTable from '../../components/Charts/Bid-Headroom-Table';

// UTILS
import { getBidHeadroomParams } from '../../utils/admin-utils/bid-headroom-utils';

// FORM ACTIONS
import {
  setBidAgency,
  resetBidState,
  setBidScanData,
  setBidAdvertiser,
  setBidCurrentPage,
  setBidConverionType,
  setBidIsValidColumn,
  setBidRevenueColumn,
  setBidConvserionColumn,
  setBidDataFetchingStatus,
} from '../../state';

const DEFAULT_STATE: AccountData = {
  agencyName: '',
  agencyId: '',
  advertisers: [],
  reportType: [],
};

interface Platforms {
  [key: string]: {
    label: string;
    value: string;
  }[];
}

// FUNCTIONAL COMPONENT
function BidHeadroomScan() {
  // HOOKS
  const asyncWrapper = useAsyncWrapper();

  const [state, dispatch] = useReducer(
    bidHeadroomReducer,
    DEFAULT_BID_HEADROOM_STATE
  );

  // REDUX STATE
  const userId = useAppSelector(({ auth }) => auth.user?.id);

  // REDUX ACTIONS
  const { alertError, setLoading, alertSuccess } = useActions();

  // USER'S AGENCIES
  const [platforms, setPlatforms] = useState<Platforms>({});

  // BID HEADROOM SCAN CURRENT STATE
  const [accountData, setAccountData] = useState<AccountData>(DEFAULT_STATE);

  // CONVERSION COLUMN DROPDOWN OPTIONS
  const conversionColumnOptions: DropdownOption[] =
    accountData.advertisers
      .find((advertiser) => advertiser.advertiserId === state.advertiser?.value)
      ?.conversionCustomColumns?.map((column) => {
        return {
          label: column,
          value: column,
        };
      }) || [];

  const revenueColumnOptions: DropdownOption[] =
    accountData.advertisers
      .find((advertiser) => advertiser.advertiserId === state.advertiser?.value)
      ?.revenueCustomColumns?.map((column) => {
        return {
          label: column,
          value: column,
        };
      }) || [];

  // 2 MONTH DATA FETCHING STATUS
  const dataFetchingStatus =
    state.agency && state.advertiser
      ? state.dataFetching?.[state.agency.value]?.[state.advertiser.value]
      : null;

  // ENABLE/DISABLE SUBMIT BUTTON
  const isSubmitDisabled =
    (!state.isValidColumn && dataFetchingStatus !== 'success') ||
    !state.agency ||
    !state.advertiser ||
    !state.conversionType ||
    !state.conversionColumn ||
    (state.conversionType === 'Revenue' && !state.revenueColumn);

  // RENDER CONVERSION OPTIONS BASED ON AVAILABLE COLUMNS
  const renderConversionTypeOptions = () => {
    const options: DropdownOption[] = [];
    const advertiser = accountData.advertisers.find(
      (advertiser) => advertiser.advertiserId === state.advertiser?.value
    );

    if (advertiser?.conversionCustomColumns) {
      options.push({ label: 'Actions', value: 'Actions' });
    }

    if (advertiser?.revenueCustomColumns) {
      options.push({ label: 'Revenue', value: 'Revenue' });
    }

    return options;
  };

  const loadUserAgencies = asyncWrapper(async function (userId: string) {
    if (!userId) return;

    const agenciesData = await getUserAgencies(userId);

    if (!agenciesData) return;

    // GROUP AGENCY DROPDOWN OPTIONS BY PLATFORM
    const platformData = agenciesData.reduce((platforms: Platforms, agency) => {
      const { platformName, agencyName, agencyId } = agency;

      if (!platforms[platformName]) {
        platforms[platformName] = [];
      }

      platforms[platformName].push({
        label: agencyName,
        value: agencyId,
      });

      return platforms;
    }, {});

    setPlatforms(platformData);
  });

  // RESET STATE ON INITIAL LOAD (IF DATA FETCHING NOT IN PROGRESS)
  useEffect(() => {
    if (dataFetchingStatus === 'inProgress') return;

    dispatch(resetBidState());
  }, []);

  // FETCH AND SAVE USER'S AGENGIES
  useEffect(() => {
    loadUserAgencies(userId);
  }, [userId]);

  // LOAD SELECTED ACCOUNT DATA
  const loadAccountData = asyncWrapper(async function (agencyId: string) {
    if (!userId || !agencyId) return;

    const data = await getAccountsData(userId, agencyId);

    if (!data) return;

    setAccountData(data);
  });

  // FETCH AND LOAD ACCOUNT DATA UPON SELECTION
  useEffect(() => {
    if (!state.agency) return;
    loadAccountData(state.agency.value);
  }, [state.agency]);

  // VERIFY SELECTED CUSTOM COLUMN IS VALID
  const onVerifyCustomColumn = asyncWrapper(async (column: string) => {
    if (!column || !state.agency) return;

    // let isValid = dataFetchingStatus === 'success';
    let isValid = false;

    if (!isValid) {
      const { msg, status } = await verifyCustomColumn(
        column,
        state.agency.value
      );

      if (status === 'success') {
        isValid = true;
      } else {
        alertError(msg);
      }
    }

    dispatch(setBidIsValidColumn(isValid));
  });

  // VERIFY CONVERSION COLUMN ON SELECTION
  useEffect(() => {
    const column = state.conversionColumn || state.revenueColumn;

    if (state.currentPage > 0) {
      dispatch(setBidCurrentPage(0));
    }

    if (column) {
      onVerifyCustomColumn(column.value);
    }
  }, [state.conversionColumn, state.revenueColumn]);

  // FETCH BID HEADROOM DATA ON PAGE CHANGE
  useEffect(() => {
    if (state.currentPage > 0) {
      onFetchScanData();
    }
  }, [state.currentPage]);

  // HANDLE FETCHING AND STORING BID HEADROOM SCAN
  const onFetchScanData = asyncWrapper(async () => {
    if (!userId || isSubmitDisabled) return;

    const params = getBidHeadroomParams(userId, state);

    if (!params) return;

    // FETCH TABLE DATA
    const data = await getScanBidAndBidHeadRoom(params);

    if (data.status === 'failure') {
      // ALERT API ERROR ON STATUS: FAILURE
      alertError(data.msg);
    } else {
      // STORE TABLE DATA ON STATUS: SUCCESS
      dispatch(setBidScanData(data));
    }
  });

  // HANDLE BID HEADROOM SCAN SUBMIT
  const onSubmitScan = async (evt: FormEvent) => {
    evt.preventDefault();

    // FETCH AND STORE DATA
    dispatch(setBidCurrentPage(1));
  };

  // HANDLE TABLE PAGINATION
  const onPagination = (pageNumber: number) => {
    dispatch(setBidCurrentPage(pageNumber));
  };

  // HANLDE DOWNLOAD OF BID & BID HEADROOM SCAN BULKSHEET
  const onDownloadScanBulk = asyncWrapper(async () => {
    if (
      isSubmitDisabled ||
      !state.agency ||
      !state.advertiser ||
      !state.conversionType
    )
      return;

    const fileName = `Bid_BidHeadroom_Scan_${accountData.agencyName}_${TODAY}.xlsx`;

    const params: DownloadBidHeadroomBulkSheetParams = {
      fileName,
      advertiserId: state.advertiser.value,
      agencyId: state.agency.value,
      conversionColumn: state.conversionColumn?.value || '',
      conversionType: state.conversionType,
      revenueColumn: state.revenueColumn?.value || '',
    };

    // DOWNLOAD AND SAVE FILE
    await downloadScannedBidAndBidHeadroomBulkSheet(params);
  });

  // HANDLE AGENCY SELECTION
  const onSetAgency = (selected: DropdownOption) => {
    dispatch(setBidAgency(selected));
  };

  // HANDLE ADVERTISER SELECTION
  const onSetAdvertiser = (selected: DropdownOption) => {
    dispatch(setBidAdvertiser(selected));
  };

  // HANDLE CONVERSION TYPE SELECTION
  const onSetConverionType = (selected: DropdownOption) => {
    dispatch(setBidConverionType(selected.value as ConversionType));
  };

  // HANDLE REVENUE COLUMN SELECTION
  const onSetRevenueColumn = (selected: DropdownOption) => {
    dispatch(setBidRevenueColumn(selected));
  };

  // HANDLE CONVERSION COLUMN SELECTION
  const onSetConversionColumn = async (selected: DropdownOption) => {
    dispatch(setBidConvserionColumn(selected));
  };

  // HANDLE FETCHING PAST TWO MONTHS
  const onFetchPriorTwoMonths = async () => {
    if (!userId || dataFetchingStatus === 'inProgress') return;

    const params = getBidHeadroomParams(userId, state);

    if (!params) return;

    const { agencyId, advertiserId } = params;

    setLoading(true);

    // SET DATA FETCHING STATUS TO IN_PROGRESS
    dispatch(setBidDataFetchingStatus(agencyId, advertiserId, 'inProgress'));

    // CLOSE MODAL AFTER 2 SECONDS & DISPLAY DATA FETCHING MESSAGE
    setTimeout(() => {
      setLoading(false);
      alertSuccess(
        'Data fetching process started.  A notification will appear once it is complete.'
      );
    }, 2000);

    // DATA FETCHING REQUESTS
    getKeywordsByMonth(agencyId, advertiserId)
      .then(async ({ msg, status }) => {
        // GET SCAN DATA IF SUCCESS
        if (status === 'success') {
          // DISPLAY DATA FETCHING SUCCESS MESSAGE
          alertSuccess(msg);

          // GET DATA FOR PREVIOUSLY SELECTED COLUMN
          const data = await getScanBidAndBidHeadRoom(params);
          if (data.status === 'failure') {
            // ALERT API ERROR ON STATUS: FAILURE
            alertError(data.msg);
            status = 'failure';
          } else {
            // STORE TABLE DATA ON STATUS: SUCCESS
            dispatch(setBidScanData(data));
          }
        } else {
          // DISPLAY DATA FETCHING FAILURE MESSAGE
          alertError(msg);
        }
        // UPDATE DATA FETCHING STATUS
        dispatch(setBidDataFetchingStatus(agencyId, advertiserId, status));
      })
      .catch((error) => {
        alertError(
          error.response?.data?.errorMessage ||
            error.response?.data?.error_message ||
            error.response?.data?.message ||
            error.response?.data?.msg ||
            DEFAULT_API_ERROR
        );
        dispatch(setBidDataFetchingStatus(agencyId, advertiserId, 'failure'));
      });
  };

  return (
    <AdminPage header="bid headroom scan">
      <div className="bid-headroom-content">
        <form onSubmit={onSubmitScan}>
          <Row>
            {/* AGENCIES DROPDOWN */}
            <Column>
              <Dropdown
                options={Object.entries(platforms)
                  .map(([label, options]) => {
                    return {
                      label,
                      options,
                    };
                  })
                  .reverse()}
                onChange={onSetAgency}
                value={state.agency || ''}
                defaultValue={state.agency || ''}
                required={!state.agency}
              >
                Agency
              </Dropdown>
            </Column>

            {/* ADVERTISERS DROPDOWN */}
            <Column>
              <Dropdown
                options={
                  accountData?.advertisers.map((advertiser) => {
                    return {
                      label: advertiser.advertiserName,
                      value: advertiser.advertiserId,
                    };
                  }) || []
                }
                onChange={onSetAdvertiser}
                value={state.advertiser || ''}
                defaultValue={state.advertiser || ''}
                required
              >
                Advertiser
              </Dropdown>
            </Column>

            {/* CONVERSION TYPE DROPDOWN */}
            <Column>
              <Dropdown
                options={renderConversionTypeOptions()}
                onChange={onSetConverionType}
                value={
                  state.conversionType
                    ? {
                        label: '',
                        value: state.conversionType,
                      }
                    : ''
                }
                defaultValue={
                  state.conversionType
                    ? {
                        label: '',
                        value: state.conversionType,
                      }
                    : ''
                }
                required
              >
                Conversion Type
              </Dropdown>
            </Column>
          </Row>

          <Row cols={3}>
            {/* REVENUE COLUMN SELECTION */}
            {state.conversionType === 'Revenue' && (
              <Column>
                <Dropdown
                  options={revenueColumnOptions}
                  onChange={onSetRevenueColumn}
                  value={state.revenueColumn || ''}
                  defaultValue={state.revenueColumn || ''}
                  required
                >
                  Revenue Column
                </Dropdown>
              </Column>
            )}
            {/* CONVERSION COLUMN DROPDOWN - ONLY DISPLAY FOR CONVERSION TYPE "ACTIONS" */}
            {state.conversionType && (
              <Column>
                <Dropdown
                  options={conversionColumnOptions}
                  onChange={onSetConversionColumn}
                  value={state.conversionColumn || ''}
                  defaultValue={state.conversionColumn || ''}
                  required
                >
                  Conversion Column
                </Dropdown>
              </Column>
            )}
          </Row>

          <Row>
            <Column>
              <div className="submit-button-container">
                {(state.conversionColumn || state.revenueColumn) &&
                !state.isValidColumn &&
                dataFetchingStatus !== 'success' ? (
                  <>
                    <Button
                      onClick={onFetchPriorTwoMonths}
                      disabled={dataFetchingStatus === 'inProgress'}
                    >
                      Fetch past 2 months data
                    </Button>
                    {dataFetchingStatus === 'inProgress' && (
                      <p
                        style={{
                          color: 'red',
                          marginTop: '.25rem',
                          fontSize: '.8rem',
                          fontWeight: 'bold',
                        }}
                      >
                        Data fetching in progress
                      </p>
                    )}
                  </>
                ) : (
                  <Button type="submit" disabled={isSubmitDisabled}>
                    Submit
                  </Button>
                )}
              </div>
            </Column>
          </Row>
        </form>
      </div>
      <div className="bid-headroom-table">
        {!!state.data && (
          <>
            <div className="download-scan-button-container">
              <Button onClick={onDownloadScanBulk} disabled={!state.data.data}>
                Download Bid and Bid Headroom Scan Bulksheet
              </Button>
            </div>
            <BidHeadroomTable
              rawData={state.data}
              rowsPerPage={state.rowsPerPage}
              onPagination={onPagination}
            />
          </>
        )}
      </div>
    </AdminPage>
  );
}

export default BidHeadroomScan;
