import './Product-Settings-Container.scss';

import { useLocation } from 'react-router-dom';

// CONSTANTS
import { ROUTES } from '../../router/router';

// COMPONENTS
import Button from '@publicismedia-ds/ui-button';

// COMPONENT PROPS
interface ProductSettingsProps {
    children: React.ReactNode;
    disabled: boolean;
}

// FUNCTIONAL COMPONENT (PRODUCT SETTINGS)
function ProductSettings({ children, disabled }: ProductSettingsProps) {
    const { pathname } = useLocation();

    // JSX
    return (
        <>
            <div className="universal-settings-row">{children}</div>
            <div className="button-container">
                <Button
                    type="submit"
                    className="apply-settings-button"
                    disabled={disabled}
                >
                    {pathname === ROUTES.MATCH_TYPE_STRATEGY_ANALYSIS
                        ? 'Download'
                        : 'Apply'}
                </Button>
            </div>
        </>
    );
}

export default ProductSettings;
