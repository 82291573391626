import { useEffect, useState } from 'react';
import { ScenarioPlannerData } from '../../state';

// UI COMPONENTS
import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import Button from '@publicismedia-ds/ui-button';
import { numberWithCommas } from '../../utils';

// COMPONENT PROPS
interface ScenarioPlannerTableProps {
    data: ScenarioPlannerData;
    onUpdate?: (updated?: ScenarioPlannerData) => void;
}

// FUNCTIONAL COMPONENT
function ScenarioPlannerTable({ data, onUpdate }: ScenarioPlannerTableProps) {
    // ENABLE/DISABLE EDIT TABLE
    const [isEditing, setIsEditing] = useState(false);

    // TABLE STATE
    const [scenarioPlannerProvider, setScenarioPlannerProvider] = useState<any>(
        []
    );

    useEffect(() => {
        const formattedData = data.xAxis.map((saturation, index) => {
            let efficiency: string | number = data.Efficiency[index];
            const cost = Math.round(data.Cost[index]);
            const conversions = Math.round(data.Conversion[index]);

            if (efficiency < 2) {
                efficiency = (Math.round(efficiency * 100) / 100).toFixed(2);
            } else {
                efficiency = Math.round(efficiency);
            }

            return {
                saturation,
                cost: isEditing ? cost : numberWithCommas(cost),
                conversions: isEditing
                    ? conversions
                    : numberWithCommas(conversions),
                efficiency,
            };
        });

        const provider = new DataProvider({
            data: formattedData,
            rowsPerPage: 20,
        });

        setScenarioPlannerProvider(provider);
    }, [data, isEditing]);

    // HANDLE SAVING EDITED TABLE DATA
    const onEditSave = async () => {
        if (!onUpdate || !scenarioPlannerProvider) return;

        const { editStack } = scenarioPlannerProvider;

        for (let op of editStack.stack) {
            editStack.data[op.rowIndex][op.key] = op.value;
        }

        const updatedData: ScenarioPlannerData = {
            Conversion: [],
            Cost: [],
            Efficiency: [],
            xAxis: [],
            conversionType: data.conversionType,
        };

        for (let row of editStack.data) {
            const cost = parseFloat(row.cost);
            const conversions = parseFloat(row.conversions);

            updatedData.Cost.push(cost);
            updatedData.Conversion.push(conversions);
            updatedData.Efficiency.push(
                conversions > 0 ? cost / conversions : 0
            );
            updatedData.xAxis.push(parseFloat(row.saturation));
        }

        onUpdate(updatedData);

        setIsEditing(false);
    };

    return (
        <>
            <DataTable
                className="scenario-planner-data-table"
                data={scenarioPlannerProvider}
                bindKey="saturation"
                emptyState={
                    <>
                        <h2>No data available.</h2>
                    </>
                }
                above={
                    isEditing ? (
                        <div>
                            <Button
                                display="secondary"
                                onClick={() => setIsEditing(false)}
                                style={{ marginRight: '1rem' }}
                            >
                                Cancel
                            </Button>
                            <Button onClick={onEditSave}>Save</Button>
                        </div>
                    ) : (
                        <div>
                            <Button
                                display="secondary"
                                onClick={() => setIsEditing(true)}
                            >
                                Edit
                            </Button>
                            <Button
                                style={{ marginLeft: '1rem' }}
                                onClick={() => {
                                    if (onUpdate) onUpdate();
                                }}
                            >
                                Reset
                            </Button>
                        </div>
                    )
                }
                isEditing={isEditing}
                scroll={false}
            >
                <ColumnTemplate
                    className="align-head-center"
                    bind="saturation"
                    type="percent"
                    align="center"
                    isSortable
                    minWidth
                >
                    Saturation Index
                </ColumnTemplate>
                <ColumnTemplate
                    className="align-head-center currency"
                    bind="cost"
                    type="number"
                    align="center"
                    isSortable
                    isEditable
                >
                    Cost
                </ColumnTemplate>
                <ColumnTemplate
                    className={`align-head-center${
                        data.conversionType !== 'Actions' && ' currency'
                    }`}
                    bind="conversions"
                    align="center"
                    type="number"
                    isSortable
                    isEditable
                >
                    Total{' '}
                    {data.conversionType === 'Actions'
                        ? 'Conversions'
                        : 'Revunue'}
                </ColumnTemplate>
                <ColumnTemplate
                    className="align-head-center currency"
                    bind="efficiency"
                    type="number"
                    align="center"
                    isSortable
                    isEditable
                >
                    Efficiency
                </ColumnTemplate>
            </DataTable>
        </>
    );
}

export default ScenarioPlannerTable;
