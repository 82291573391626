// TYPES
import { useEffect, useState } from 'react';
import { AdhocUploadStatusData } from '../../state';

// UI COMPONENTS
import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import Button from '@publicismedia-ds/ui-button';
import Status from '@publicismedia-ds/ui-status';

// COMPONENT PROPS
interface AdhocUploadStatusProps {
    data: AdhocUploadStatusData[];
    onReload: () => Promise<void>;
}

// FUNCTIONAL COMPONENT (ADHOC DATA UPLOAD STATUS TABLE)
function AdhocUploadStatus({ data, onReload }: AdhocUploadStatusProps) {
    const [provider, setProvider] = useState<any>([]);

    // SET TABLE DATA PROVIDER
    useEffect(() => {
        setProvider(
            new DataProvider({
                data: data.map((row, index) => ({ ...row, id: index })),
            })
        );
    }, [data]);

    return (
        <DataTable
            data={provider}
            bindKey="id"
            height="tall"
            above={
                <div>
                    <Button onClick={onReload}>Reload Upload Status</Button>
                </div>
            }
        >
            {/* <ColumnTemplate bind="userName">Username</ColumnTemplate> */}
            <ColumnTemplate bind="keywordFile">Keyword File</ColumnTemplate>
            <ColumnTemplate bind="adgroupFileName">AdGroup File</ColumnTemplate>
            <ColumnTemplate bind="reportType">Report Type</ColumnTemplate>
            <ColumnTemplate bind="conversionType">
                Conversion Type
            </ColumnTemplate>
            <ColumnTemplate bind="uploadTimestamp">
                Upload Timestamp
            </ColumnTemplate>
            <ColumnTemplate
                className="status-column"
                bind="uploadStatus"
                type="status"
                onRender={(status: string) => {
                    return (
                        <>
                            {status === 'Upload Successful' && (
                                <Status
                                    type="success"
                                    displayOnFocus={false}
                                    title="Successful"
                                />
                            )}
                            {status === 'In Progress' && (
                                <Status type="pending" title="In Progress" />
                            )}
                            {status === 'Upload Failed' && (
                                <Status type="failed" title="Failed" />
                            )}
                        </>
                    );
                }}
            >
                Status
            </ColumnTemplate>
        </DataTable>
    );
}

export default AdhocUploadStatus;
