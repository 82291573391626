import { DropdownOption, ExistingAccountDetails } from '../../models';

export interface CustomColumnPayload {
    index: number;
    name?: string;
    status?: boolean;
}

export interface CustomColumnFormData {
    name: string;
    status: boolean;
    existing: boolean;
}

export enum SA_360_ACTION_TYPES {
    SET_GOOGLE_ADS_SUPPORT = 'set_google_ads_support',
    SET_BING_ADS_SUPPORT = 'set_bing_ads_support',
    SET_DSA_SUPPORT = 'set_dsa_support',
    SET_PLA_SUPPORT = 'set_pla_support',
    SET_REVENUE_SUPPORT = 'set_revenue_support',
    SET_CUSTOM_COLUMNS_SUPPORT = 'set_custom_columns_support',
    SET_MANAGER_EMAIL = 'set_manager_email',
    ADD_CONVERSION_COLUMN = 'add_conversion_column',
    UPDATE_CONVERSION_COLUMN = 'update_conversion_column',
    REMOVE_CONVERSION_COLUMN = 'remove_conversion_column',
    ADD_REVENUE_COLUMN = 'add_revenue_column',
    UPDATE_REVENUE_COLUMN = 'update_revenue_column',
    REMOVE_REVENUE_COLUMN = 'remove_revenue_column',
    ADD_ADVERTISER = 'add_advertiser',
    UPDATE_ADVERTISER = 'update_advertiser',
    REMOVE_ADVERTISER = 'remove_advertiser',
    SET_AGENCY_NAME = 'set_agency_name',
    SET_AGENCY_ID = 'set_agency_id',
    RESET_SA_360_FORM = 'reset_sa_360_form',
    LOAD_FORM_DATA = 'load_form_data',
}

export const DEFAULT_CUSTOM_COLUMN: CustomColumnFormData = {
    name: '',
    status: true,
    existing: false,
};

export interface UpdateSA360AdvertiserPayload {
    index: number;
    id?: string;
    name?: string;
    status?: boolean;
    existing?: boolean;
}

export interface SA360FormState {
    platformId: number; // => 1;
    googleAdsSupport: boolean;
    bingAdsSupport: boolean;
    dsaSupport: boolean;
    plaSupport: boolean;
    isRevenueSupport: boolean;
    sa360ManagerEmail: DropdownOption | ''; // => 'jkim10.performics@gmail.com';
    customColumns: CustomColumnFormData[]; // csv => 'test, testing, still testing'
    customRevenueColumns: CustomColumnFormData[]; // csv => 'test, testing, still testing'
    agencyName: string;
    agencyId: string;
    advertiserData: SA360FormAdvertiser[];
    isEditing: boolean;

    // EDITTING EXISTING ACCOUNT PROPERTIES
    platform?: string;
    isCustomColumnsSupport?: boolean;
    isMultiplePlatform?: boolean;
}

export interface SA360FormAdvertiser {
    id: string;
    name: string;
    status?: boolean;
    existing?: boolean;
}

export const INITIAL_SA_ADVERTISER: SA360FormAdvertiser = {
    id: '',
    name: '',
};

export const INITIAL_SA_360_STATE: SA360FormState = {
    platformId: 1,
    googleAdsSupport: false,
    bingAdsSupport: false,
    dsaSupport: false,
    plaSupport: false,
    isRevenueSupport: false,
    sa360ManagerEmail: '',
    customColumns: [DEFAULT_CUSTOM_COLUMN],
    customRevenueColumns: [DEFAULT_CUSTOM_COLUMN],
    agencyName: '',
    agencyId: '',
    advertiserData: [{ ...INITIAL_SA_ADVERTISER }],
    isEditing: false,
};

interface SetGoogleAdsSupportAction {
    type: SA_360_ACTION_TYPES.SET_GOOGLE_ADS_SUPPORT;
    payload: boolean;
}

interface SetBingAdsSupportAction {
    type: SA_360_ACTION_TYPES.SET_BING_ADS_SUPPORT;
    payload: boolean;
}

interface SetDSASupportAction {
    type: SA_360_ACTION_TYPES.SET_DSA_SUPPORT;
    payload: boolean;
}

interface SetPLASupportAction {
    type: SA_360_ACTION_TYPES.SET_PLA_SUPPORT;
    payload: boolean;
}

interface SetRevenueSupportAction {
    type: SA_360_ACTION_TYPES.SET_REVENUE_SUPPORT;
    payload: boolean;
}

interface SetCustomColumnsSupportAction {
    type: SA_360_ACTION_TYPES.SET_CUSTOM_COLUMNS_SUPPORT;
    payload: boolean;
}

interface SetManagerEmailAction {
    type: SA_360_ACTION_TYPES.SET_MANAGER_EMAIL;
    payload: DropdownOption;
}

interface AddRevenueColumnAction {
    type: SA_360_ACTION_TYPES.ADD_REVENUE_COLUMN;
}

interface UpdateRevenueColumnAction {
    type: SA_360_ACTION_TYPES.UPDATE_REVENUE_COLUMN;
    payload: CustomColumnPayload;
}

interface RemoveRevenueColumnAction {
    type: SA_360_ACTION_TYPES.REMOVE_REVENUE_COLUMN;
    payload: number;
}

interface AddConversionColumnAction {
    type: SA_360_ACTION_TYPES.ADD_CONVERSION_COLUMN;
}

interface UpdateConversionColumnAction {
    type: SA_360_ACTION_TYPES.UPDATE_CONVERSION_COLUMN;
    payload: CustomColumnPayload;
}

interface RemoveConversionColumnAction {
    type: SA_360_ACTION_TYPES.REMOVE_CONVERSION_COLUMN;
    payload: number;
}

interface SetAgencyNameAction {
    type: SA_360_ACTION_TYPES.SET_AGENCY_NAME;
    payload: string;
}

interface SetAgencyIdAction {
    type: SA_360_ACTION_TYPES.SET_AGENCY_ID;
    payload: string;
}

interface AddAdvertiserAction {
    type: SA_360_ACTION_TYPES.ADD_ADVERTISER;
}

interface UpdateAdvertisreAction {
    type: SA_360_ACTION_TYPES.UPDATE_ADVERTISER;
    payload: UpdateSA360AdvertiserPayload;
}

interface RemoveAdvertiserAction {
    type: SA_360_ACTION_TYPES.REMOVE_ADVERTISER;
    payload: number; // index
}

interface ResetFormAction {
    type: SA_360_ACTION_TYPES.RESET_SA_360_FORM;
}

interface LoadFormDataAction {
    type: SA_360_ACTION_TYPES.LOAD_FORM_DATA;
    payload: ExistingAccountDetails;
}

export type SA360FormAction =
    | SetGoogleAdsSupportAction
    | SetBingAdsSupportAction
    | SetDSASupportAction
    | SetPLASupportAction
    | SetRevenueSupportAction
    | SetCustomColumnsSupportAction
    | SetManagerEmailAction
    | AddConversionColumnAction
    | UpdateConversionColumnAction
    | RemoveConversionColumnAction
    | AddRevenueColumnAction
    | UpdateRevenueColumnAction
    | RemoveRevenueColumnAction
    | SetAgencyNameAction
    | SetAgencyIdAction
    | AddAdvertiserAction
    | UpdateAdvertisreAction
    | RemoveAdvertiserAction
    | ResetFormAction
    | LoadFormDataAction;
