import './Bullet-Charts-Container.scss';
import { MetricData } from '../../../state';
import BulletChart from '../Bullet-Chart/Bullet-Chart';

export interface BulletChartContainerProps {
    header: string;
    data: MetricData;
}

function BulletChartsContainer({ header, data }: BulletChartContainerProps) {
    return (
        <div className="bullet-charts-container">
            <div className="bullet-charts-header">
                <h2>{header}</h2>
            </div>
            <div className="bullet-charts-content">
                <BulletChart
                    header="Yesterday"
                    target={data['Yesterday'].Target}
                    actual={data['Yesterday'].Actual}
                />
                <BulletChart
                    header="Flight To Date"
                    target={data['Flight To Date'].Target}
                    actual={data['Flight To Date'].Actual}
                />
                <BulletChart
                    header="Projected"
                    target={data['Projected'].Target}
                    actual={data['Projected'].Actual}
                />
            </div>
        </div>
    );
}

export default BulletChartsContainer;
