import { Navigate } from 'react-router-dom';

// PAGES COMPONENTS
import Notes from '../pages/Notes/Notes';
import EditNotes from '../pages/Edit-Notes/Edit-Notes';
import ManageUsers from '../pages/Manage-Users/Manage-Users';
import CustomAlerts from '../pages/Custom-Alerts/Custom-Alerts';
import UserActivity from '../pages/User-Activity/User-Activity';
import RunDataCrons from '../pages/Run-Data-Crons/Run-Data-Crons';
import ChangeSummary from '../pages/Change-Summary/Change-Summary';
import BidHeadroomScan from '../pages/Bid-Headroom-Scan/Bid-Headroom-Scan';
import ManageScheduledGoals from '../pages/Scheduled-Goals/Scheduled-Goals';
import AccountOnboarding from '../pages/Account-Onboarding/Account-Onboarding';
import YieldOptimizer from '../pages/Products/Yield-Optimizer/Yield-Optimizer';
import MatchTypeAnalysis from '../pages/Match-Type-Analysis/Match-Type-Analysis';
import ScenarioPlanner from '../pages/Products/Scenario-Planner/Scenario-Planner';
import ManageGoalMappings from '../pages/Manage-Goal-Mappings/Manage-Goal-Mappings';
import EditExistingAccount from '../pages/Edit-Existing-Account/Edit-Existing-Account';
import PerformanceMonitor from '../pages/Products/Performance-Monitor/Performance-Monitor';
import PerformanceDiagnostic from '../pages/Products/Performance-Diagnostic/Performance-Diagnostic';

export enum ROUTES {
    // PROTECTED
    HOME = '/',
    PRODUCTS = '/products',
    DATA_UPLOAD = '/data-upload',
    CUSTOM_ALERTS = '/custom-alerts',
    YIELD_OPTIMIZER = '/products/yield-optimizer',
    SCENARIO_PLANNER = '/products/scenario-planner',
    AUTOMATED_DAILY_PACING = '/automated-daily-pacing',
    PERFORMANCE_MONITOR = '/products/performance-monitor',
    PERFORMANCE_DIAGNOSTIC = '/products/performance-diagnostic',

    // RoleIDs 1 or 2 only
    MANAGE_USERS = '/admin/manage-users',
    USER_ACTIVITY = '/admin/user-activity',
    MANAGE_GOAL_MAPPINGS = '/admin/manage-goal-mappings',
    MANAGE_SCHEDULED_GOALS = '/admin/manage-scheduled-goals',
    BID_AND_BID_HEADROOM_SCAN = '/admin/bid-and-bid-headroom-scan',

    // RoleID 1 only (ADMIN ONLY)
    NOTES = '/admin/notes',
    EDIT_NOTES = '/admin/notes/edit',
    ACCOUNT_ONBOARDING = '/admin/account-onboarding',
    EDIT_EXISTING_ACCOUNT = '/admin/edit-existing-account',
    CHANGE_SUMMARY_REVIEW = '/admin/change-summary-review',
    RUN_DATA_FETCHING_CRONS = '/admin/run-data-fetching-crons',
    MATCH_TYPE_STRATEGY_ANALYSIS = '/admin/match-type-strategy-analysis',

    // UNPROTECTED
    LOGIN = '/login',
    RESET_PASSWORD = '/ResetPassword',
    NOT_FOUND = '*',
}

export const protectedRoutes = [
    {
        path: ROUTES.HOME,
        element: <Navigate to={ROUTES.PERFORMANCE_MONITOR} />,
    },
    {
        path: ROUTES.CUSTOM_ALERTS,
        element: <CustomAlerts />,
    },
];

export const productsRoutes = [
    {
        path: ROUTES.PERFORMANCE_MONITOR,
        element: <PerformanceMonitor />,
    },
    {
        path: ROUTES.YIELD_OPTIMIZER,
        element: <YieldOptimizer />,
    },
    {
        path: ROUTES.SCENARIO_PLANNER,
        element: <ScenarioPlanner />,
    },
    {
        path: ROUTES.PERFORMANCE_DIAGNOSTIC,
        element: <PerformanceDiagnostic />,
    },
];

export const adminRoutes = [
    {
        path: ROUTES.MATCH_TYPE_STRATEGY_ANALYSIS,
        element: <MatchTypeAnalysis />,
    },
    {
        path: ROUTES.MANAGE_GOAL_MAPPINGS,
        element: <ManageGoalMappings />,
    },
    {
        path: ROUTES.MANAGE_SCHEDULED_GOALS,
        element: <ManageScheduledGoals />,
    },
    {
        path: ROUTES.MANAGE_USERS,
        element: <ManageUsers />,
    },
    {
        path: ROUTES.BID_AND_BID_HEADROOM_SCAN,
        element: <BidHeadroomScan />,
    },
    {
        path: ROUTES.EDIT_NOTES,
        element: <EditNotes />,
    },
    {
        path: ROUTES.USER_ACTIVITY,
        element: <UserActivity />,
    },
];

export const superAdminRoutes = [
    {
        path: ROUTES.ACCOUNT_ONBOARDING,
        element: <AccountOnboarding />,
    },
    {
        path: ROUTES.EDIT_EXISTING_ACCOUNT,
        element: <EditExistingAccount />,
    },
    {
        path: ROUTES.RUN_DATA_FETCHING_CRONS,
        element: <RunDataCrons />,
    },
    {
        path: ROUTES.CHANGE_SUMMARY_REVIEW,
        element: <ChangeSummary />,
    },
    {
        path: ROUTES.NOTES,
        element: <Notes />,
    },
];
