import './Upload-Excluded.scss';

import { DropdownOption } from '../../state';

// UI COMPONENTS
import Dropdown from '@publicismedia-ds/ui-dropdown';
import Tooltip, {
    TOOLTIP_ALIGN,
    TOOLTIP_POSITION,
} from '@publicismedia-ds/ui-tooltip';

interface UploadExcludedProps {
    type: 'Campaign' | 'Keyword';
    value?: string;
    onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    options: DropdownOption[];
}

function UploadExcluded({
    type,
    onChange,
    options,
    value,
}: UploadExcludedProps) {
    return (
        <div className="upload-excluded-container">
            <div className="file-input-group">
                <label>
                    Upload {type}s to Exclude
                    <Tooltip
                        className="add-goal-form-tool-tip"
                        title={`Please upload .xlsx file only and make sure the uploaded file contains "${type} Name" and "${type} ID" columns with the same name and order specified earlier.`}
                        align={TOOLTIP_ALIGN.TOP}
                        position={TOOLTIP_POSITION.RIGHT}
                    >
                        <i className="icon-support"></i>
                    </Tooltip>
                </label>
                <input
                    type="file"
                    onChange={onChange}
                    accept=".csv, .xlsx"
                    value={value}
                />
                <div className="file-input-dropdown">
                    <Dropdown
                        placeholder={`${options.length} Campaign(s) Excluded`}
                        options={options}
                        value=""
                    >
                        Read Only
                    </Dropdown>
                </div>
            </div>
        </div>
    );
}

export default UploadExcluded;
