import './Performance-Diagnostic.scss';

import { useEffect, useState } from 'react';
import { formatLargeNumber, numberWithCommas } from '../../../utils';

// TYPES
import { ApexOptions } from 'apexcharts';
import { PerformanceDiagnosticData } from '../../../state';

// UI COMPONENTS
import { Column } from '@publicismedia-ds/ui-charts';
import PerformanceDiagnosticTable from '../../../components/Charts/Performance-Diagnostic-Table';

// COMPONENT PROPS
interface PerformanceDiagnosticContentProps {
    data?: PerformanceDiagnosticData;
}

// FUNCTIONAL COMPONENT
function PerformanceDiagnosticContent({
    data,
}: PerformanceDiagnosticContentProps) {
    // CURRENT DATA STATE
    const [currentData, setCurrentData] = useState<PerformanceDiagnosticData>();

    const conversionType =
        currentData?.setting.conversion_type === 'Revenue'
            ? 'Revenue'
            : 'Conversions';

    // SET CURRENT DATA VALUE TO DATA ON INITIAL LOAD
    useEffect(() => {
        setCurrentData(data);
    }, []);

    // HANDLE UPDATING TABLE DATA
    const onUpdate = (updatedData?: PerformanceDiagnosticData) => {
        setCurrentData(updatedData || data);
    };

    const options: ApexOptions = {
        chart: {
            toolbar: {
                show: false,
                tools: {
                    download: false,
                },
            },
        },
        stroke: {
            width: [0, 4],
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: [0, 5],
        },
        labels:
            currentData?.diagnostic.map(
                (item, index) => 'Tier ' + (index + 1).toString()
            ) || [],
        yaxis: [
            {
                seriesName: 'Cost',
                opposite: true,
                title: {
                    text: conversionType,
                },
                labels: {
                    formatter: (value: number) => {
                        return formatLargeNumber(value, {
                            decimals: 0,
                        });
                    },
                },
            },
            {
                seriesName: conversionType,
                title: {
                    text: 'Cost',
                },
                floating: false,
                decimalsInFloat: 0,
            },
        ],
        tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex }) => {
                return `
                    <div class="pmds-chart-apex__tooltip">
                    <p>Cost: $${numberWithCommas(series[0][dataPointIndex])}</p>
                    <p>${conversionType}: ${numberWithCommas(
                    series[1][dataPointIndex]
                )}</p>
                    </div>
                    `;
            },
        },
    };

    const series = [
        {
            name: 'Cost',
            type: 'column',
            data:
                currentData?.diagnostic.map((item) => Math.round(item.cost)) ||
                [],
        },
        {
            name: conversionType,
            type: 'line',
            data:
                currentData?.diagnostic.map((item) =>
                    Math.round(item.conversions)
                ) || [],
        },
    ];

    return (
        <div className="performance-diagnostic-content">
            <div className="row">
                <h2>Performance By Tier</h2>
                {currentData && (
                    <Column data={series} options={options} width="100%" />
                )}
            </div>
            <div className="row" style={{ marginTop: '2rem' }}>
                {currentData ? (
                    <PerformanceDiagnosticTable
                        data={currentData}
                        onUpdate={onUpdate}
                    />
                ) : (
                    <h4>No data</h4>
                )}
            </div>
        </div>
    );
}

export default PerformanceDiagnosticContent;
