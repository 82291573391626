import { axios } from '../axios-config';
import { UniversalSettingsState } from '../../state';
import { API_UNIVERSAL_SETTINGS } from '../../constants';
import { formatAddGoalSettingsParams } from '../../utils';

export const addGoalSetting = async (
    universalSettings: UniversalSettingsState,
    isMatchType: boolean = false
) => {
    const params = formatAddGoalSettingsParams(universalSettings, isMatchType);

    const body = new URLSearchParams();

    for (const [key, value] of Object.entries(params)) {
        body.append(key, value);
    }

    try {
        const url = API_UNIVERSAL_SETTINGS + '/addGoalSetting';
        const { data } = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return data;
    } catch (error) {
        throw error;
    }
};

export const getSettings = async (userId: string, goalId: string | number) => {
    try {
        const url = API_UNIVERSAL_SETTINGS + '/getGoalSetting';
        const { data } = await axios.post(
            url,
            {},
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                params: { userId, goalId },
            }
        );
        return data;
    } catch (error) {
        throw error;
    }
};

export const getAlerts = async (userId: string) => {
    try {
        const url = API_UNIVERSAL_SETTINGS + '/getAlert';
        const { data } = await axios.post(
            url,
            {},
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                params: { userId },
            }
        );
        return data;
    } catch (error) {
        throw error;
    }
};
