import './Schedule-Goal-Form.scss';

import { useActions } from '../../hooks';
import { DEFAULT_API_ERROR } from '../../constants';
import React, { useEffect, useReducer } from 'react';
import {
    DropdownOption,
    GoalsListItem,
    ScheduleDetails,
    EXECUTION_WAYS,
    SCHEDULE_FREQUENCYS,
    SCHEDULE_GOAL_TYPES,
    DATE_TO_RUN_OPTIONS,
    DAYS_TO_RUN_OPTIONS,
    SCHEDULE_TIME_OF_DAY,
    ScheduleGoalFormState,
    EXECUTION_WAYS_OPTIONS,
    scheduleGoalFormReducer,
    SCHEDULE_MODULES_OPTIONS,
    INITIAL_SCHEDULE_GOAL_FORM,
    SCHEDULE_FREQUENCY_OPTIONS,
} from '../../state';

// UI COMPONENT
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import TextList from '../Pmds-Text-List/Pmds-Text-List';

// COMPONENT PROPS
interface ScheduleGoalFormProps {
    id?: string;
    goals: GoalsListItem[];
    schedule?: ScheduleDetails;
    onSubmit: (formState: ScheduleGoalFormState) => Promise<void>;
    onCancel?: () => void;
}

function ScheduleGoalForm({
    id,
    goals,
    schedule,
    onSubmit,
    onCancel,
}: ScheduleGoalFormProps) {
    // REDUX ACTIONS
    const { alertError } = useActions();

    // SCHEDULED GOAL FORM STATE (REACT REDUCER)
    const [formState, dispatch] = useReducer(
        scheduleGoalFormReducer,
        INITIAL_SCHEDULE_GOAL_FORM
    );

    // LOAD SCHEDULED GOAL DATA IF PRESENT
    useEffect(() => {
        if (!schedule) return;
        dispatch({
            type: SCHEDULE_GOAL_TYPES.LOAD_SCHEDULE_DATA,
            payload: { schedule, goals },
        });
    }, [schedule]);

    // HANDLE NAME INPUT CHANGE
    const onSetScheduleName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: SCHEDULE_GOAL_TYPES.SET_SCHEDULE_NAME,
            payload: evt.target.value,
        });
    };

    // HANDLE GOALS SELECTIONS
    const onSelectGoals = (selections: DropdownOption[]) => {
        dispatch({
            type: SCHEDULE_GOAL_TYPES.SET_SELECTED_GOALS,
            payload: selections,
        });
    };

    // HANDLE MODULES SELECTIONS
    const onSelectModules = (selections: DropdownOption[]) => {
        dispatch({
            type: SCHEDULE_GOAL_TYPES.SET_SELECTED_MODULES,
            payload: selections,
        });
    };

    // HANDLE EXECUTION TYPE SELECTION
    const onSelectExecution = (selection: DropdownOption) => {
        dispatch({
            type: SCHEDULE_GOAL_TYPES.SET_EXECUTION_WAY,
            payload: selection,
        });
    };

    // HANDLE FREQUENCY SELECTION (EXECUTION === 'SCHEDULED')
    const onSelectFrequency = (selection: DropdownOption) => {
        dispatch({
            type: SCHEDULE_GOAL_TYPES.SET_FREQUENCY,
            payload: selection,
        });
    };

    // HANDLE TIME OF DAY SELECTION
    const onSelectTimeOfDay = (selection: DropdownOption) => {
        dispatch({
            type: SCHEDULE_GOAL_TYPES.SET_TIME_OF_DAY,
            payload: selection,
        });
    };

    // HANDLE DAYS TO RUN SELECTION (EXECUTION === 'WEEKLY')
    const onSelectDaysToRun = (selections: DropdownOption[]) => {
        if (selections.length < 7) {
            dispatch({
                type: SCHEDULE_GOAL_TYPES.SET_DAYS_TO_RUN,
                payload: selections,
            });
        } else {
            alertError(
                'Please change the schedule frequency from "Weekly" to "Daily".'
            );
        }
    };

    // HANDLE DATE TO RUN SELECTION (EXECUTION === 'MONTHLY')
    const onSelectDateToRun = (selection: DropdownOption) => {
        dispatch({
            type: SCHEDULE_GOAL_TYPES.SET_DAYS_TO_RUN,
            payload: [selection],
        });
    };

    // HANDLE ADD EMAIL RECIPIENT
    const onAddEmail = (email: string) => {
        const isValidEmail = /^\S+@\S+\.\S+$/.test(email);

        // ALERT ERROR IF EMAIL IS INVALID
        if (!isValidEmail) {
            alertError('Invalid email address');
            return;
        }

        // ADD EMAIL
        dispatch({
            type: SCHEDULE_GOAL_TYPES.ADD_EMAIL,
            payload: email,
        });
    };

    // HANDLE REMOVE EMAIL RECIPIENT
    const onRemoveEmail = (email: string) => {
        dispatch({
            type: SCHEDULE_GOAL_TYPES.REMOVE_EMAIL,
            payload: email,
        });
    };

    // HANDLE FORM SUBMISSION
    const onSubmitForm = async (evt: React.FormEvent) => {
        evt.preventDefault();

        try {
            // RUN ON SUBMIT FUNCTION AND CLEAR FORM
            await onSubmit(formState);

            // CLEAR FORM IF NOT A MODAL
            if (!onCancel) {
                dispatch({
                    type: SCHEDULE_GOAL_TYPES.RESET_FORM,
                });
            }
        } catch (error: any) {
            // ALERT ERROR MESSAGE ON ERROR
            alertError(
                error.response?.data?.errorMessage ||
                    error.response?.data?.message ||
                    DEFAULT_API_ERROR
            );
        }
    };

    return (
        <div className="schedule-goal-form-container">
            <div className="schedule-goal-form-content">
                <form onSubmit={onSubmitForm} id={id ? id : ''}>
                    <div className="form-row row-group">
                        {/* --- SCHEDULE NAME --- */}
                        <Textbox
                            onChange={onSetScheduleName}
                            value={formState.scheduleName}
                            required
                        >
                            Schedule Name
                        </Textbox>

                        {/* --- SELECT GOALS --- */}
                        <Dropdown
                            options={goals.map((goal) => ({
                                label: goal.goalMappingName,
                                value: goal.goalId,
                            }))}
                            onChange={onSelectGoals}
                            value={formState.selectedGoals}
                            defaultValue={formState.selectedGoals}
                            display="selectionInline"
                            multiple
                            required
                        >
                            Select Goals
                        </Dropdown>

                        {/* --- SELECT MODULES (PRODUCTS) --- */}
                        <Dropdown
                            options={SCHEDULE_MODULES_OPTIONS}
                            onChange={onSelectModules}
                            value={formState.selectedModules}
                            defaultValue={formState.selectedModules}
                            display="selectionInline"
                            multiple
                            required
                        >
                            Select Module(s)
                        </Dropdown>
                    </div>
                    <div className="form-row row-group">
                        {/* --- SELECT EXECUTION WAY (DON'T DISPLAY IF EDITING) --- */}
                        {!schedule && (
                            <Dropdown
                                options={EXECUTION_WAYS_OPTIONS}
                                onChange={onSelectExecution}
                                value={formState.executionWay}
                                defaultValue={formState.executionWay}
                                required
                            >
                                Select Execution Type
                            </Dropdown>
                        )}
                        {/* --- DISPLAY ONLY IF EXECUTION WAY === 'SCHEDULE' --- */}
                        {formState.executionWay?.value ===
                            EXECUTION_WAYS[0] && (
                            <>
                                {/* --- EXECUTION FREQUENCY --- */}
                                <Dropdown
                                    options={SCHEDULE_FREQUENCY_OPTIONS}
                                    onChange={onSelectFrequency}
                                    value={formState.frequency}
                                    defaultValue={formState.frequency}
                                    required
                                >
                                    Select Frequency
                                </Dropdown>
                                {/* --- DISPLAY IF SCHEDULE FREQUENCY === 'WEEKLY' --- */}
                                {formState.frequency?.value ===
                                    SCHEDULE_FREQUENCYS[1] && (
                                    // SELECT DAYS TO RUN (MON, TUES, WED....)
                                    <Dropdown
                                        options={DAYS_TO_RUN_OPTIONS}
                                        onChange={onSelectDaysToRun}
                                        value={formState.daysToRun}
                                        defaultValue={formState.daysToRun}
                                        display="selectionInline"
                                        multiple
                                        required
                                    >
                                        Select Day(s) to Run
                                    </Dropdown>
                                )}
                                {/* --- DISPLAY IF SCHEDULE FREQUENCY === 'MONTHLY --- */}
                                {formState.frequency?.value ===
                                    SCHEDULE_FREQUENCYS[2] && (
                                    // SELECT MONTH DATE (1st, 2nd, 3rd....)
                                    <Dropdown
                                        options={DATE_TO_RUN_OPTIONS}
                                        onChange={onSelectDateToRun}
                                        value={formState.daysToRun}
                                    >
                                        Select Date to Run
                                    </Dropdown>
                                )}
                                {/* --- SELECT TIME OF DAY TO RUN --- */}
                                <Dropdown
                                    options={SCHEDULE_TIME_OF_DAY.map(
                                        (time) => ({
                                            label: time + ', CST',
                                            value: time,
                                        })
                                    )}
                                    onChange={onSelectTimeOfDay}
                                    value={formState.timeOfDay}
                                >
                                    Select Time of Day
                                </Dropdown>
                            </>
                        )}
                    </div>
                    <div className="form-row form-emails-input row-group">
                        <TextList
                            values={formState.emails}
                            label="Please enter valid email addresses"
                            onAdd={onAddEmail}
                            onRemove={onRemoveEmail}
                            required
                        />
                    </div>
                    <div className="form-row form-submit-button">
                        {!!onCancel && (
                            <Button display="text" onClick={onCancel}>
                                Cancel
                            </Button>
                        )}
                        <Button
                            type="submit"
                            disabled={
                                !formState.scheduleName ||
                                !formState.selectedGoals.length ||
                                !formState.selectedModules.length ||
                                !formState.executionWay ||
                                !formState.emails.length ||
                                (formState.executionWay.value === 'schedule' &&
                                    !formState.frequency)
                            }
                        >
                            {!!schedule ? 'Update' : 'Add'} Scheduled Goal
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ScheduleGoalForm;
