import { changeUserStatus, updateUser } from '../../api';
import { useEffect, useState } from 'react';
import {
    DEFAULT_API_ERROR,
    UPDATE_USER_STATUS,
    USER_STATUS,
} from '../../constants';
import { useActions, useAppSelector } from '../../hooks';
import { GETRolesAccountBrands, UserDetails, UserFormState } from '../../state';

// UI COMPONENTS
import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import Status from '@publicismedia-ds/ui-status';
import SearchFilter from '@publicismedia-ds/ui-search-filter';
import EditUserModal from '../Edit-User-Modal/Edit-User-Modal';
import ConfirmationModal from '../Confirmation-Modal/Confirmation-Modal';
import Tooltip, {
    TOOLTIP_ALIGN,
    TOOLTIP_POSITION,
} from '@publicismedia-ds/ui-tooltip';

interface ExistingUsersTableProps {
    accessData: GETRolesAccountBrands | null;
    data: UserDetails[];
    onAction: () => Promise<void>;
}

function ExistingUsersTable({
    accessData,
    data,
    onAction,
}: ExistingUsersTableProps) {
    // TABLE DATA PROVIDER (SCHEDULED GOALS LIST)
    const [dataProvider, setDataProvider] = useState<any>([]);

    // SELECTED SCHEDULE TO DELETE
    const [updateUserStatus, setUpdateUserStatus] =
        useState<UserDetails | null>(null);

    // SELECTED SCHEDULE TO EDIT
    const [editSelected, setEditSelected] = useState<UserDetails | null>(null);

    // CURRENT USER'S ID (REDUX)
    const userId = useAppSelector(({ auth }) => auth.user?.id);

    // REDUX ACTIONS
    const { alertError, alertSuccess, setLoading } = useActions();

    // CREATE TABLE DATA PROVIDER ON INITIAL LOAD AND WHEN DATA CHANGES
    useEffect(() => {
        const formattedData = data.reduce((users: any[], details) => {
            const formattedUser = {
                ...details,
                agencies: details.permissions.length || 'All',
                advertisers:
                    details.permissions.reduce((total: number, agency) => {
                        total += agency.brands.length;
                        return total;
                    }, 0) || 'All',
            };
            users.push(formattedUser);
            return users;
        }, []);

        formattedData.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }

            if (a.name > b.name) {
                return 1;
            }

            return 0;
        });
        setDataProvider(new DataProvider({ data: formattedData }));
    }, [data]);

    // HANDLE ACTION COLUMN EDIT ICON CLICK
    const onEditIconClick = (id: number) => {
        const userToEdit = data.find((user) => user.id === id);
        setEditSelected(userToEdit || null);
    };

    // HANDLE ACTION COLUMN ACTIVATE/DEACTIVATE ICON CLICK
    const onStatusIconClick = (id: number, isActive: boolean) => {
        // FIND SCHEDULE TO BE DELETED BY ID
        const userToDelete = data.find((user) => user.id === id);

        setUpdateUserStatus(userToDelete || null);
    };

    // HANDLE UPDATING USER
    const onUpdateUser = async (formState: UserFormState) => {
        if (!userId || !editSelected || !formState) return;

        setLoading(true);
        try {
            const data = await updateUser(userId, formState);

            if (data.result === 'success') {
                alertSuccess(data.message);
            } else {
                alertError(data.message || DEFAULT_API_ERROR);
            }

            // RELOAD TABLE
            await onAction();
        } catch (error: any) {
            throw error;
        } finally {
            setLoading(false);
            setEditSelected(null);
        }
    };

    // HANDLE DEACTIVATING USER
    const onDeactivateUser = async () => {
        if (!userId || !updateUserStatus) return;

        const mode =
            updateUserStatus.isActive === USER_STATUS.ACTIVE
                ? UPDATE_USER_STATUS.DEACTIVATE
                : UPDATE_USER_STATUS.ACTIVATE;

        // OPEN LOADING MODAL
        setLoading(true);
        try {
            // ATTEMPT TO UPDATE USER'S STATUS
            const data = await changeUserStatus(
                updateUserStatus.id.toString(),
                userId,
                mode
            );

            alertSuccess('User status successfully updated.');

            // RELOAD TABLE DATA
            await onAction();
        } catch (error: any) {
            // ALERT ERROR ON ERROR
            alertError(
                error.response?.data?.errorMessage ||
                    error.response?.data?.message ||
                    DEFAULT_API_ERROR
            );
        } finally {
            // CLOSE LOADING MODAL
            setLoading(false);
        }
    };

    // HANDLE SEARCH USERS TABLE CHANGE
    const onSearchChange = (text: string) => {
        if (!dataProvider) return;
        dataProvider.search(text, ['agency', 'advertiser']);
    };

    return (
        <>
            <h3 className="existing-users-title">Existing Users</h3>
            <DataTable
                bindKey="id"
                above={
                    <>
                        <SearchFilter
                            className="users-search-filter"
                            onSearchChange={onSearchChange}
                        />
                    </>
                }
                className="existing-users-data-table"
                data={dataProvider}
                stickyHead={true}
                emptyState={
                    <>
                        <h2>No data available.</h2>
                    </>
                }
            >
                <ColumnTemplate bind="name" isSortable minWidth={true}>
                    Name
                </ColumnTemplate>
                <ColumnTemplate
                    bind="email"
                    isSortable
                    onRender={(text: string) => (
                        <>
                            <span>{text}</span>
                        </>
                    )}
                    minWidth={true}
                >
                    Email
                </ColumnTemplate>
                <ColumnTemplate
                    bind="role"
                    isSortable
                    onRender={(text: string) => (
                        <>
                            <span>{text}</span>
                        </>
                    )}
                    minWidth={true}
                >
                    Role
                </ColumnTemplate>
                <ColumnTemplate bind="createdOn" isSortable minWidth={true}>
                    Created On
                </ColumnTemplate>
                <ColumnTemplate bind="lastLogin" isSortable minWidth={true}>
                    Last Logged In
                </ColumnTemplate>
                <ColumnTemplate
                    bind="agencies"
                    isSortable
                    onRender={(text: string, rowData: any) => {
                        return (
                            <span>
                                {rowData.role === 'Admin' ? 'All' : text}{' '}
                                Agencies
                            </span>
                        );
                    }}
                    minWidth={true}
                >
                    Agency Access
                </ColumnTemplate>
                <ColumnTemplate
                    bind="advertisers"
                    onRender={(text: string, rowData: any) => {
                        return (
                            <span>
                                {rowData.role === 'Admin' ? 'All' : text}{' '}
                                Advertisers
                            </span>
                        );
                    }}
                    isSortable
                    minWidth={true}
                >
                    Advertiser Access
                </ColumnTemplate>
                <ColumnTemplate
                    bind="isActive"
                    onRender={(text: number) => (
                        <>
                            {text === 1 ? (
                                <Status
                                    type="success"
                                    displayOnFocus={false}
                                    title="Active"
                                />
                            ) : (
                                <Status
                                    type="failed"
                                    displayOnFocus={false}
                                    title="Deactivated"
                                />
                            )}{' '}
                        </>
                    )}
                    isSortable
                    minWidth={true}
                >
                    Active
                </ColumnTemplate>
                <ColumnTemplate
                    bind="id"
                    isSortable
                    onRender={(userId: number, rowData: any) => {
                        const isActive = rowData.isActive === 1;
                        return (
                            <>
                                <Tooltip title="Edit">
                                    <button
                                        className="action-button"
                                        onClick={() => {
                                            onEditIconClick(userId);
                                        }}
                                    >
                                        <i
                                            className="icon-edit"
                                            title="Edit"
                                        ></i>
                                    </button>
                                </Tooltip>
                                <Tooltip
                                    title={isActive ? 'Deactivate' : 'Activate'}
                                >
                                    <button
                                        className="action-button"
                                        onClick={() => {
                                            onStatusIconClick(userId, isActive);
                                        }}
                                    >
                                        <i
                                            className={
                                                isActive
                                                    ? 'icon-cancel-16'
                                                    : 'icon-Completed_Successful_Confirmed'
                                            }
                                        ></i>
                                    </button>
                                </Tooltip>
                            </>
                        );
                    }}
                    minWidth={true}
                >
                    Actions
                </ColumnTemplate>
            </DataTable>
            {!!updateUserStatus && (
                <ConfirmationModal
                    header={`${
                        updateUserStatus.isActive === 1
                            ? 'Deactivate'
                            : 'Activate'
                    } User:`}
                    message={
                        <>
                            <p>
                                <strong>Name:</strong> {updateUserStatus.name}
                            </p>
                            <p>
                                <strong>Email:</strong> {updateUserStatus.email}
                            </p>
                            <p>
                                <strong>
                                    Are you sure you want to deactive this user?
                                </strong>
                            </p>
                        </>
                    }
                    onCancel={() => setUpdateUserStatus(null)}
                    onConfirm={onDeactivateUser}
                />
            )}
            {!!editSelected && (
                <EditUserModal
                    accessData={accessData}
                    userData={editSelected}
                    onClose={() => setEditSelected(null)}
                    onSubmit={onUpdateUser}
                />
            )}
        </>
    );
}

export default ExistingUsersTable;
