import React, { memo } from 'react';
import { useActions } from '../../../hooks';
import { KenshooReportDetails } from '../../../state';

// UI COMPONENTS
import Textbox from '@publicismedia-ds/ui-textbox';

// COMPONENT PROPS
interface KenshooReportProps {
    index: number;
    data: KenshooReportDetails;
}

// FUNCTIONAL COMPONENT
function KenshooReport({ index, data }: KenshooReportProps) {
    // REDUX ACTION
    const { updateKenshooReport } = useActions();

    // HANDLE UPDATING KENSHOO REPORT ID
    const onUpdateReportId = (evt: React.ChangeEvent<HTMLInputElement>) => {
        updateKenshooReport(index, evt.target.value);
    };

    return (
        <div className="inputs-row">
            <Textbox value={data.report_name} disabled={true}>
                Report Name
            </Textbox>
            <Textbox value={data.report_id} onChange={onUpdateReportId}>
                Report ID
            </Textbox>
            <Textbox value={data.report_type} disabled={true}>
                Report Type
            </Textbox>
        </div>
    );
}

export default memo(KenshooReport);
