import produce from 'immer';
import { UserData } from '../models';
import { AuthAction } from '../actions';
import { AuthActionType } from '../action-types';

const storedUser = sessionStorage.getItem('Darwin-User');

interface AuthState {
    isAuthenticated: boolean;
    user: UserData | null;
}

const initialAuthState: AuthState = {
    isAuthenticated: Boolean(storedUser),
    user: storedUser ? (JSON.parse(storedUser) as UserData) : null,
};

const reducer = produce(
    (state: AuthState = initialAuthState, action: AuthAction): AuthState => {
        switch (action.type) {
            case AuthActionType.AUTH_SUCCESS:
                state.isAuthenticated = true;
                state.user = action.payload.user;
                return state;

            case AuthActionType.AUTH_FAILURE:
                state.isAuthenticated = false;
                state.user = null;
                return state;

            default:
                return state;
        }
    },
    initialAuthState
);

export default reducer;
