const isProduction = process.env.REACT_APP_ENV === 'prod';

export const ENVIRONMENT = isProduction ? 'prod' : 'qa';

export const LLSignOut = '/User_Settings-3.0/Users/LLSignOut';
export const API_USERS = '/User_Settings-3.0/Users';

export const API_USER_SETTING = '/User_Settings-3.0';
export const API_USER_SETTING3 = '/User_Settings-3.0';
export const API_DARWIN = '/Darwin-3.0';
export const API_MEDIA_SAT = '/MediaSAT-3.0';
export const API_INTRA_DAY = '/IntraDay-3.0';
export const API_DPAD = '/DPAD-3.0';
export const API_CUBIC_DC3 = '/Cubic_DC-3.0';
export const API_DIAGNOSTICS = '/Diagnostics-3.0/Diagnostics';
export const API_CUSTOM_ALERTS = API_USER_SETTING + '/CustomAlerts';
export const API_INTRA_DAY_SETTINGS = API_INTRA_DAY + '/IntradaySettings';
export const API_BULKSHEET_TRANSLATOR = API_DARWIN + '/BulkSheetTranslator';
export const API_UNIVERSAL_SETTINGS = API_USER_SETTING3 + '/UniversalSettings';
export const API_MEDIA_SAT_BULKSHEET = API_MEDIA_SAT + '/Bulksheet';
export const API_DIRECTIONAL_PACING = API_DPAD + '/DirectionalPacing';
export const API_REPORT_TEMPLATE =
    API_UNIVERSAL_SETTINGS + '/downloadReportTemplate';
export const API_ADMIN_FEATURES = API_UNIVERSAL_SETTINGS + '/AdminFeatures';
export const API_MATCH_TYPE_ANALYSIS =
    API_USER_SETTING3 + '/AdminFeatures/downloadMatchTypeStrategyBulksheet';

export const SESSION_STORAGE_USER = 'Darwin-User';

export const API_USER_ACTIVITY = API_UNIVERSAL_SETTINGS + '/saveUserActivity';
export const API_GET_ALL_USERS = API_USERS + '/getAllUsers';

export const API_ADHOC_UPLOAD_STATUS =
    API_UNIVERSAL_SETTINGS + '/getAdhocDataUploadStatus';

export const XLSX_FILE_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const DEFAULT_API_ERROR =
    'Unable to fullfil request.  Please contact Darwin support.';

export const API_ROUTES = {
    GET_CUSTOM_ALERTS: API_CUSTOM_ALERTS + '/getCustomAlert',
    EDIT_CUSTOM_ALERT: API_CUSTOM_ALERTS + '/edit',
    CREATE_CUSTOM_ALERT: API_CUSTOM_ALERTS + '/createCustomAlert',
};

export enum PLATFORM_NAMES {
    SA_360 = 'Search Ads 360',
    KENSHOO = 'Kenshoo',
    MARIN = 'Marin',
    ENGINE_ACCOUNTS = 'Engine Accounts',
    GOOGLE_ADS = 'Google Ads',
    BING_ADS = 'Bing Ads',
}

export enum PLATFORM_IDS {
    SA_360 = 1,
    KENSHOO = 3,
    MARIN = 5,
    ENGINE_ACCOUNTS = 0,
    GOOGLE_ADS = 2,
    BING_ADS = 4,
}

export enum USER_STATUS {
    ACTIVE = 1,
    DEACTIVE = 0,
}

export enum UPDATE_USER_STATUS {
    ACTIVATE = 'activate',
    DEACTIVATE = 'deactivate',
}

export type PlatformId = 0 | 1 | 2 | 3 | 4 | 5;

export const FEATURE_NAMES = [
    'Performance Monitor',
    'Yield Optimizer',
    'Scenario Planner',
    'Performance Diagnostics',
    'Schedule Goals',
] as const;

export type FeatureName = (typeof FEATURE_NAMES)[number];

export const FEATURE_IDS = [1, 2, 3, 4, 5] as const;

export type FeatureId = (typeof FEATURE_IDS)[number];

export const STATUS_OPTIONS = [
    { label: 'Enabled', value: 'true' },
    { label: 'Disabled', value: 'false' },
] as const;

export type StatusOption = (typeof STATUS_OPTIONS)[number];

export enum ADHOC_TEMPLATES {
    DSA = 'DSA',
    KEYWORD = 'Keyword',
    AD_GROUP = 'AdGroup',
    PRODUCT_GROUP = 'ProductGroup',
}

export const SELECT_ALL = { label: 'Select All', value: 'Select all' };

export enum FeatureIds {
    ALL = 'all',
    PERFORMANCE_MONITOR = '1',
    YIELD_OPTIMIZER = '2',
    SCENARIO_PLANNER = '3',
    PERFORMANCE_DIAGNOSTIC = '4',
    SCHEDULE_GOALS = '5',
}

export enum PRODUCT_NAMES {
    PERFORMANCE_MONITOR = 'performance monitor',
    PERFORMANCE_DIAGNOSTIC = 'performance diagnostic',
    SCENARIO_PLANNER = 'scenario planner',
    YIELD_OPTIMIZER = 'yield optimizer',
}

export const TODAY = new Date().toLocaleString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
});

// ALERT TYPE OPTIONS
export const CUSTOM_ALERT_TYPES = [
    { label: 'Pacing', value: 2 },
    { label: 'Performance', value: 3 },
] as const;

// ACCOUNT & PLATFORM INFO
export const ACCOUNT_PLATFORMS: { [key: string]: number } = {
    '20700000001288015': 1, // PERFORMICS - VONAGE
    '2500000': 5, // SPARK
    '9516648093': 2, // FACEBOOK ADVERTISER ACQUISITION
    '20700000001389555': 1, // Performics - The General
    '20700000001397736': 1, // American Family Insurance
    '20700000001425309': 1, // BJs - SA 360
    '20700000001433490': 1, // First Republic Bank
    '20700000001477131': 1, // TP - Western Union - JEL - SA360 - US
};
