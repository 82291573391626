import { memo } from 'react';
import { useActions } from '../../hooks';
import { STATUS_OPTIONS } from '../../constants';
import { DropdownOption, GoogleAdsFormSubAccount } from '../../state';

// UI COMPONENTS
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';

// COMPONENT PROPS
interface GoogleSubAccountProps {
    index: number;
    data: GoogleAdsFormSubAccount;
}

// FUNCTIONAL COMPONENT
function GoogleSubAccount({ data, index }: GoogleSubAccountProps) {
    // REDUX ACTIONS
    const { updateGoogleSubAccount, removeGoogleSubAccount } = useActions();

    return (
        <div className="inputs-row" key={'sub-customer-' + index}>
            {/* SUB ACCOUNT NAME */}
            <Textbox
                value={data.name}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    updateGoogleSubAccount({
                        index,
                        name: evt.target.value,
                    });
                }}
                disabled={data.existing}
                required
            >
                Sub Account Name
            </Textbox>

            {/* SUB ACCOUNT ID */}
            <Textbox
                value={data.id}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    updateGoogleSubAccount({
                        index,
                        id: evt.target.value,
                    });
                }}
                disabled={data.existing}
                required
            >
                Sub Account ID
            </Textbox>

            {data.status !== undefined && (
                <Dropdown
                    options={STATUS_OPTIONS}
                    onChange={(selected: DropdownOption) => {
                        updateGoogleSubAccount({
                            index,
                            status: selected.value === 'true',
                        });
                    }}
                    value={{
                        label: '',
                        value: data.status.toString(),
                    }}
                    defaultValue={{
                        label: '',
                        value: data.status.toString(),
                    }}
                    isSearchable={false}
                    required
                >
                    Status
                </Dropdown>
            )}

            {/* ICON TO REMOVE SUB ACCOUNT INPUTS ROW */}
            {!data.existing && index > 0 && (
                <i
                    className="icon-close"
                    onClick={() => removeGoogleSubAccount(index)}
                />
            )}
        </div>
    );
}

export default memo(GoogleSubAccount);
