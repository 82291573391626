import './Filter-Campaigns.scss';

import React, { useMemo, useState } from 'react';
import { DropdownOption } from '../../state';
import {
    AndOrOption,
    AND_OR_OPTIONS,
    CampaignFilter,
    FilterOperatorOption,
    FILTER_OPERATOR_OPTIONS,
} from '../../state';

// UI COMPONENTS
import Pill from '../Pmds-Pill/Pmds-Pill';
import Radio from '@publicismedia-ds/ui-radio';
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import Fieldset from '@publicismedia-ds/ui-fieldset';
import { Row, Column } from '@publicismedia-ds/ui-grid';

// MATCH CAMPAIGNS FILTER COMPONENT
interface FilterCampaignsProps {
    filters: CampaignFilter[];
    campaignOptions: DropdownOption[];
    selectedCampaigns: DropdownOption[] | string[];
    onAddFilter: (filter: CampaignFilter) => void;
    onRemoveFilter: (filter: CampaignFilter) => void;
}

// FUNCTIONAL COMPONENT
function FilterCampaigns({
    filters,
    campaignOptions,
    selectedCampaigns,
    onAddFilter,
    onRemoveFilter,
}: FilterCampaignsProps) {
    // FILTER VALUE TEXT INPUT STATE
    const [filterValue, setFilterValue] = useState('');

    // FILTER VALUE OPERATOR RADIO STATE
    const [filterOperator, setFilterOperator] = useState<FilterOperatorOption>(
        FILTER_OPERATOR_OPTIONS[0]
    );

    // COMARISON OPERATOR RADIO STATE
    const [andOr, setAndOr] = useState<AndOrOption>(AND_OR_OPTIONS[0]);

    // RENDER USER INPUTED FILTERS
    const renderedFilters = useMemo(
        () =>
            filters.map((filter, index) => {
                return (
                    <React.Fragment key={index}>
                        <span
                            className="andOr-operator"
                            key={`${filter.andOr}-${index}`}
                        >
                            {filter.andOr}
                        </span>
                        <Pill
                            text={`${filter.operator} '${filter.value}'`}
                            onClick={() => onRemoveFilter(filter)}
                        />
                    </React.Fragment>
                );
            }),
        [filters, campaignOptions]
    );

    return (
        <div className="match-campaigns-filter-container">
            <div className="match-campaigns-filter-content">
                <div className="campaigns-filter-list">
                    <ul>{renderedFilters}</ul>
                    <div className="campaigns-filter-inputs">
                        <div className="campaigns-filter-input-group">
                            {/* --- COMPARISON OPERATOR INPUT --- */}
                            {!!filters.length && (
                                <Fieldset
                                    layout="horizontal"
                                    error="Please select an option"
                                    onChange={(
                                        evt: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setAndOr(
                                            evt.target.value as AndOrOption
                                        );
                                    }}
                                    required
                                >
                                    <Radio
                                        value={AND_OR_OPTIONS[0]}
                                        checked={andOr === AND_OR_OPTIONS[0]}
                                        readOnly
                                        invert
                                    >
                                        and
                                    </Radio>
                                    <Radio
                                        value={AND_OR_OPTIONS[1]}
                                        checked={andOr === AND_OR_OPTIONS[1]}
                                        readOnly
                                        invert
                                    >
                                        or
                                    </Radio>
                                </Fieldset>
                            )}
                        </div>
                        <div className="campaigns-filter-input-group">
                            {/* --- FILTER VALUE OPERATOR INPUT --- */}
                            <Fieldset
                                layout="horizontal"
                                error="Please select an option"
                                onChange={(
                                    evt: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setFilterOperator(
                                        evt.target.value as FilterOperatorOption
                                    );
                                }}
                                required
                            >
                                <Radio
                                    value={FILTER_OPERATOR_OPTIONS[0]}
                                    checked={
                                        filterOperator ===
                                        FILTER_OPERATOR_OPTIONS[0]
                                    }
                                    readOnly
                                    invert
                                >
                                    contains
                                </Radio>
                                <Radio
                                    value={FILTER_OPERATOR_OPTIONS[1]}
                                    checked={
                                        filterOperator ===
                                        FILTER_OPERATOR_OPTIONS[1]
                                    }
                                    readOnly
                                    invert
                                >
                                    does not contain
                                </Radio>
                            </Fieldset>
                        </div>
                        <div className="campaigns-filter-input-group">
                            <div className="filter-value-input-group">
                                {/* --- FILTER VALUE INPUT --- */}
                                <Textbox
                                    className="fitler-value-input"
                                    value={filterValue}
                                    onChange={(
                                        evt: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setFilterValue(evt.target.value);
                                    }}
                                    placeholder="Please enter a filter value"
                                    hideLabel
                                >
                                    filter value
                                </Textbox>
                                <Button
                                    className="add-filter-button"
                                    onClick={() => {
                                        // DO NOTHING IF FILTER VALUE IS EMPTY STRING
                                        if (!filterValue) return;

                                        // CREATE NEW CAMPAIGN FILTER OBJECT
                                        const newFilter: CampaignFilter = {
                                            andOr:
                                                filters.length > 0 ? andOr : '',
                                            operator: filterOperator,
                                            value: filterValue.trim(),
                                        };

                                        // ADD NEW CAMPAIGN FILTER TO FILTERS ARRAY
                                        onAddFilter(newFilter);

                                        // RESET FILTER VALUE INPUT TO EMPTY STRING
                                        setFilterValue('');
                                    }}
                                >
                                    Add Filter
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="matched-campaigns-dropdown">
                <Row>
                    <Column>
                        <Dropdown
                            options={campaignOptions}
                            value={selectedCampaigns}
                            display="selectionInline"
                            multiple
                        >
                            Matching Campaigns (read-only)
                        </Dropdown>
                    </Column>
                </Row>
            </div>
        </div>
    );
}

export default FilterCampaigns;
