import React, { useEffect } from 'react';
import {
    DropdownOption,
    BingAdsFormState,
    BingAdsFormAction,
    BING_ADS_ACTION_TYPES,
    ExistingAccountDetails,
} from '../../state';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { STATUS_OPTIONS } from '../../constants';

interface BingAdsFormProps {
    mEmails: string[];
    account?: ExistingAccountDetails;
    state: BingAdsFormState;
    dispatch: React.Dispatch<BingAdsFormAction>;
}

function BingAdsForm({ mEmails, account, state, dispatch }: BingAdsFormProps) {
    // LOAD EXISTING BING FORM DATA
    useEffect(() => {
        if (!account) return;

        dispatch({
            type: BING_ADS_ACTION_TYPES.LOAD_FORM_DATA,
            payload: account,
        });
    }, [account]);
    // HANDLE MANAGER EMAIL SELECTION
    const onSelectEmail = (selected: DropdownOption) => {
        dispatch({
            type: BING_ADS_ACTION_TYPES.SET_MANAGER_EMAIL,
            payload: selected,
        });
    };

    // HANDLE SET AGENCY NAME VALUE
    const onSetAgencyName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        dispatch({
            type: BING_ADS_ACTION_TYPES.SET_AGENCY_NAME,
            payload: value,
        });
    };

    // HANDLE ADDING SUB ACCOUNT DATA
    const onAddSubAccount = () => {
        dispatch({
            type: BING_ADS_ACTION_TYPES.ADD_SUB_ACCOUNT,
        });
    };

    // HANDLE REMOVING SUB ACCOUNT DATA
    const onRemoveSubAccount = (index: number) => {
        dispatch({
            type: BING_ADS_ACTION_TYPES.REMOVE_SUB_ACCOUNT,
            payload: index,
        });
    };

    return (
        <div className="bing-ads-inputs">
            {!!account ? (
                <h4 className="edit-account-header">Bing Ads:</h4>
            ) : (
                <h2 className="title">Bing Ads</h2>
            )}
            <div className="inputs-row">
                {/* BING MANAGER EMAIL */}
                <Dropdown
                    options={mEmails.map((email) => ({
                        label: email,
                        value: email,
                    }))}
                    onChange={onSelectEmail}
                    value={state.bingAdsManagerEmail}
                    defaultValue={state.bingAdsManagerEmail}
                    required
                >
                    Manger Email
                </Dropdown>
            </div>

            {/* CUSTOMER NAME (NOT EDITIBLE) */}
            {!account && (
                <div className="inputs-row">
                    <Textbox
                        onChange={onSetAgencyName}
                        value={state.agencyName}
                        required
                    >
                        Customer Name
                    </Textbox>
                </div>
            )}

            {/* --- BING ADS SUB ACCOUNTS --- */}
            <>
                <h4 className="edit-account-header">Sub Accounts:</h4>
                {state.accountData.map((subAccount, index) => {
                    return (
                        <div
                            className="inputs-row"
                            key={`sub-account-${index}`}
                        >
                            <Textbox
                                value={subAccount.name}
                                onChange={(
                                    evt: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    dispatch({
                                        type: BING_ADS_ACTION_TYPES.UPDATE_SUB_ACCOUNT,
                                        payload: {
                                            index,
                                            name: evt.target.value,
                                        },
                                    });
                                }}
                                disabled={subAccount.existing}
                                required
                            >
                                Sub Account Name
                            </Textbox>
                            <Textbox
                                value={subAccount.id}
                                onChange={(
                                    evt: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    dispatch({
                                        type: BING_ADS_ACTION_TYPES.UPDATE_SUB_ACCOUNT,
                                        payload: {
                                            index,
                                            id: evt.target.value,
                                        },
                                    });
                                }}
                                disabled={subAccount.existing}
                                required
                            >
                                Sub Account ID
                            </Textbox>

                            {/* ICON TO REMOVE SUB ACCOUNT INPUT GROUP (NEW ONLY) */}
                            {!subAccount.existing && (
                                <i
                                    className="icon-close"
                                    onClick={() => onRemoveSubAccount(index)}
                                />
                            )}
                            <Textbox
                                value={subAccount.number}
                                onChange={(
                                    evt: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    dispatch({
                                        type: BING_ADS_ACTION_TYPES.UPDATE_SUB_ACCOUNT,
                                        payload: {
                                            index,
                                            number: evt.target.value,
                                        },
                                    });
                                }}
                                disabled={subAccount.existing}
                                required
                            >
                                Sub Account Number
                            </Textbox>
                            <Textbox
                                value={subAccount.customerId}
                                onChange={(
                                    evt: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    dispatch({
                                        type: BING_ADS_ACTION_TYPES.UPDATE_SUB_ACCOUNT,
                                        payload: {
                                            index,
                                            customerId: evt.target.value,
                                        },
                                    });
                                }}
                                disabled={subAccount.existing}
                                required
                            >
                                Sub Account Customer ID
                            </Textbox>
                            {subAccount.status !== undefined && (
                                <Dropdown
                                    options={STATUS_OPTIONS}
                                    value={{
                                        label: '',
                                        value: subAccount.status.toString(),
                                    }}
                                    defaultValue={{
                                        label: '',
                                        value: subAccount.status.toString(),
                                    }}
                                    onChange={(selected: DropdownOption) => {
                                        dispatch({
                                            type: BING_ADS_ACTION_TYPES.UPDATE_SUB_ACCOUNT,
                                            payload: {
                                                index,
                                                status:
                                                    selected.value === 'true',
                                            },
                                        });
                                    }}
                                    required
                                >
                                    Status
                                </Dropdown>
                            )}
                        </div>
                    );
                })}
                {/* CREATES INPUT GROUP FOR ADDITIONAL SUB ACCOUNT */}
                <div className="insert-inputs-row-button">
                    <Button onClick={onAddSubAccount} color="brand-2">
                        Sub Account <i className="icon-add" />
                    </Button>
                </div>
            </>
            {/* --- </ END > --- */}
        </div>
    );
}

export default BingAdsForm;
