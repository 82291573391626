import './Products.scss';

import { useEffect, useState } from 'react';
import { ROUTES } from '../../router/router';
import { Outlet, useNavigate } from 'react-router-dom';
import { FeatureIds, PRODUCT_NAMES } from '../../constants';
import { useActions, useAppSelector, useProduct } from '../../hooks';

// UI COMPONENTS
import TabGroup from '@publicismedia-ds/ui-tabs';
import DataUpload from '../../components/Adhoc-Data-Upload/Adhoc-Data-Upload';
import UniversalSettings from '../../components/Universal-Settings/Universal-Settings';

// API REQUESTS
import { getAdhocDataAvailable } from '../../api';
import PacingDoc from '../../components/Pacing-Doc/Pacing-Doc';

const TAB_IDS = {
  UNIVSERAL_SETTINGS: 'universal-settings-content',
  PRODUCT: 'product-content',
  DATA_UPLOAD: 'data-upload',
  PACING_DOC: 'pacing-doc',
};

// FUNCTIONAL COMPONENT
function ProductsContainer() {
  const navigate = useNavigate();
  const { productName, pathname, featureId } = useProduct();

  const [selectedTab, setSelectedTab] = useState(0);

  // REDUX STATE
  const { user } = useAppSelector(({ auth }) => auth);
  const { goalId } = useAppSelector((state) => state.universalSettings);

  // REDUX ACTIONS
  const { setAdhocData, clearProductsData, toggleDisplaySettings } =
    useActions();

  const tabs = [
    {
      label: 'Universal Settings',
      disabled: false,
      panelId: TAB_IDS.UNIVSERAL_SETTINGS,
    },
    {
      label: productName,
      disabled: false,
      panelId: TAB_IDS.PRODUCT,
    },
  ];

  const displayDataUpload =
    productName === PRODUCT_NAMES.SCENARIO_PLANNER ||
    productName === PRODUCT_NAMES.PERFORMANCE_DIAGNOSTIC;

  const displayPacingDoc = productName === PRODUCT_NAMES.PERFORMANCE_MONITOR;

  if (displayDataUpload) {
    tabs.push({
      label: 'Data Upload',
      disabled: false,
      panelId: TAB_IDS.DATA_UPLOAD,
    });
  }

  if (displayPacingDoc) {
    tabs.push({
      label: 'Pacing Doc',
      disabled: false,
      panelId: TAB_IDS.PACING_DOC,
    });
  }

  // CLEAR DATA WHEN SELECTED GOAL ID CHANGES
  useEffect(() => {
    // CLEAR PRODUCTS DATA WHEN SELECTED GOAL OR PRODUCT CHANGES
    clearProductsData();

    // SET UNIVERSAL SETTINGS AS SELECTED TAB
    setSelectedTab(0);
  }, [goalId, pathname]);

  // CHECK USER FEATURE PERMISSION
  useEffect(() => {
    if (!user) {
      navigate(ROUTES.LOGIN);
      return;
    }

    if (!featureId || (!user.features[featureId] && !user.features.all)) {
      // PREVENT INFINITE LOOP
      if (featureId !== FeatureIds.PERFORMANCE_MONITOR) {
        navigate(ROUTES.HOME);
      }
    }
  }, [user, featureId]);

  // GET USER ADHOC DATA
  useEffect(() => {
    (async function () {
      if (displayDataUpload && user) {
        // QUERY IF USER HAS ADHOC DATA AVAILABLE
        const data = await getAdhocDataAvailable(user.id);

        if (!data.conversionType) return;

        // SAVE ADHOC CONVERSION TYPE FOR GETTING ADHOC DIAGNOSTIC DATA
        setAdhocData(productName, data.conversionType);
      }
    })();
  }, [user, featureId]);

  return (
    <>
      <div className="products-container">
        <TabGroup
          tabs={tabs}
          style={{ width: '100%' }}
          onSelect={(tabIndex: number) => {
            setSelectedTab(tabIndex);
            if (tabIndex === 0) {
              toggleDisplaySettings(true);
            } else {
              toggleDisplaySettings(false);
            }
          }}
          selectedTab={selectedTab}
          invert
        >
          {/* --- UNIVERSAL SETTINGS --- */}
          <div id={TAB_IDS.UNIVSERAL_SETTINGS} role="tabpanel">
            <UniversalSettings onApply={() => setSelectedTab(1)} />
          </div>

          {/* --- PRODUCT OUTLET (PM, YO, SP, & DP OUTPUT) --- */}
          <div id={TAB_IDS.PRODUCT} role="tabpanel" hidden>
            {/* NOTE:
                        Product outputs need to be displayed outside of TabGroup.
                        Causes bar charts to have incorrect sizing and spacing. 
                        */}
            <div>Product Outlet</div>
          </div>

          {/* --- DATA UPLOAD TAB (SP & PD ONLY) */}
          {displayDataUpload && (
            <div
              id={TAB_IDS.DATA_UPLOAD}
              className="DATA_UPLOAD"
              role="tabpanel"
              hidden
            >
              <DataUpload
                product={productName}
                onUpload={() => setSelectedTab(1)}
                tabIndex={selectedTab}
              />
            </div>
          )}

          {displayPacingDoc && (
            <div
              id={TAB_IDS.PACING_DOC}
              className="PACING_DOC"
              role="tabpanel"
              hidden
            >
              <PacingDoc />
            </div>
          )}
        </TabGroup>
        {/* --- PRODUCT OUTPUTS (CHARTS/TABLES) --- */}
        {selectedTab === 1 && <Outlet />}
      </div>
    </>
  );
}

export default ProductsContainer;
