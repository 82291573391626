import { AgencyItem, AccountData, DropdownOption } from '../../models';

export interface PacingDocState {
    accountId: number | null;
    brandId: number | null;
    platformId: number | null;
    agencies: AgencyItem[];
    accountData: AccountData | null;
    agency: DropdownOption | null;
    advertiser: DropdownOption | null;
    goals: DropdownOption[];
    useFormulaColumns: boolean;
    columns: DropdownOption[];
    columnOptions: DropdownOption[];
    formulaColumns: FormulaColumnItem[];
}

export interface FormulaColumnItem {
    columnName: string;
    columnOne: DropdownOption | null;
    operator: DropdownOption | null;
    columnTwo: DropdownOption | null;
    checked: boolean;
}

export enum PACING_DOC_ACTION_TYPES {
    LOAD_AGENCIES = 'load_agencies',
    SET_AGENCY = 'set_agency',
    SET_ADVERTISER = 'set_advertiser',
    SET_GOALS = 'set_goals',
    SET_COLUMNS = 'set_columns',
    USE_FORMULA_COLUMNS = 'use_formula_columns',
    ADD_FORMULA_COLUMN = 'add_formula_column',
    REMOVE_FORMULA_COLUMN = 'remove_formula_column',
    UPDATE_FORMULA_COLUMN = 'update_formula_column',
    RESET_FORM = 'reset_form',
}

interface LoadPacingDocAgenciesAction {
    type: PACING_DOC_ACTION_TYPES.LOAD_AGENCIES;
    payload: AgencyItem[];
}

interface SetPacingDocAgencyAction {
    type: PACING_DOC_ACTION_TYPES.SET_AGENCY;
    payload: {
        agency: DropdownOption | null;
        accountData: AccountData | null;
        platformId: number | null;
    };
}

interface SetPacingDocAdvertiserAction {
    type: PACING_DOC_ACTION_TYPES.SET_ADVERTISER;
    payload: {
        advertiser: DropdownOption | null;
        columnOptions: DropdownOption[];
        accountId: number | null;
        brandId: number | null;
    };
}

interface SetPacingDocGoalsAction {
    type: PACING_DOC_ACTION_TYPES.SET_GOALS;
    payload: DropdownOption[];
}

interface SetPacingDocColumnsAction {
    type: PACING_DOC_ACTION_TYPES.SET_COLUMNS;
    payload: DropdownOption[];
}

interface UseFormulaColumnsAction {
    type: PACING_DOC_ACTION_TYPES.USE_FORMULA_COLUMNS;
    payload: boolean;
}

interface AddFormulaColumnAction {
    type: PACING_DOC_ACTION_TYPES.ADD_FORMULA_COLUMN;
    payload: FormulaColumnItem;
}

interface RemoveFormulaColumnAction {
    type: PACING_DOC_ACTION_TYPES.REMOVE_FORMULA_COLUMN;
    payload: number;
}

interface UpdateFormulaColumnsAction {
    type: PACING_DOC_ACTION_TYPES.UPDATE_FORMULA_COLUMN;
    payload: {
        formula: FormulaColumnItem;
        index: number;
    };
}

export interface ResetPacingDocAction {
    type: PACING_DOC_ACTION_TYPES.RESET_FORM;
}

export type PacingDocAction =
    | LoadPacingDocAgenciesAction
    | SetPacingDocAgencyAction
    | SetPacingDocAdvertiserAction
    | SetPacingDocGoalsAction
    | SetPacingDocColumnsAction
    | UseFormulaColumnsAction
    | AddFormulaColumnAction
    | RemoveFormulaColumnAction
    | UpdateFormulaColumnsAction
    | ResetPacingDocAction;
