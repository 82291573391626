import './Modal.scss';

// COMPONENT PROPS
interface ModalFooterProps {
    className?: string;
    children: React.ReactNode;
}

// FUNCTIONAL COMPONENT (MODAL FOOTER)
function ModalFooter({ className, children }: ModalFooterProps) {
    return (
        <div className={`modal-footer${className ? ' ' + className : ''}`}>
            {children}
        </div>
    );
}

export default ModalFooter;
