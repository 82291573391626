import { axios } from '../axios-config';

// CONSTANTS
import { API_CUBIC_DC3, API_UNIVERSAL_SETTINGS } from '../../constants';

// TYPES
import {
    GETAccountData,
    GETUserAgencies,
    GETKeywordsByMonth,
    GETVerifyCustomColumn,
    GETScanBidAndBidHeadroomData,
    GetScanBidAndBidHeadRoomParams,
    DownloadBidHeadroomBulkSheetParams,
} from '../../state';

// UTILS
import { saveDownloadFile } from '../../utils';

// FETCH AGENCIES BY USER ID
export const getUserAgencies = async (userId: string) => {
    try {
        const { data } = await axios.get<GETUserAgencies>(
            API_UNIVERSAL_SETTINGS + '/getAgencies',
            {
                params: {
                    userId,
                },
            }
        );

        return data.agencies.sort((a, b) => {
            if (a.platformName < b.platformName) return -1;
            if (a.platformName > b.platformName) return 1;
            return 0;
        });
    } catch (error) {
        throw error;
    }
};

// FETCH ACCOUNT DATA
export const getAccountsData = async (userId: string, agencyId: string) => {
    try {
        const { data } = await axios.get<GETAccountData>(
            API_UNIVERSAL_SETTINGS + '/getAccountsData',
            {
                params: {
                    agencyId,
                    userId,
                },
            }
        );
        return data;
    } catch (error) {
        throw error;
    }
};

// FETCH SCAN BID AND BID HEADROOM DATA
export const getScanBidAndBidHeadRoom = async (
    params: GetScanBidAndBidHeadRoomParams
) => {
    try {
        const { data } = await axios.get<GETScanBidAndBidHeadroomData>(
            API_UNIVERSAL_SETTINGS + '/scanBidAndBidHeadRoom',
            {
                params,
            }
        );
        return data;
    } catch (error) {
        throw error;
    }
};

export const downloadScannedBidAndBidHeadroomBulkSheet = async (
    params: DownloadBidHeadroomBulkSheetParams
) => {
    const url =
        API_UNIVERSAL_SETTINGS + '/downloadScannedBidAndBidHeadroomBulkSheet';

    const body = new URLSearchParams();
    body.append('agencyId', params.agencyId);
    body.append('advertiserId', params.advertiserId);
    body.append('conversionType', params.conversionType);
    body.append('conversionCol', params.conversionColumn);
    body.append('revenueCol', params.revenueColumn);

    try {
        const blob = await axios.post(url, body, {
            headers: {
                'Content-Type':
                    'application/x-www-form-urlencoded; charset=UTF-8',
            },
            responseType: 'blob',
        });
        saveDownloadFile(blob, params.fileName);
    } catch (error) {
        throw error;
    }
};

// VERIFY CUSTOM COLUMN
export const verifyCustomColumn = async (column: string, agencyId: string) => {
    const url = API_CUBIC_DC3 + '/DoubleClick/verifyCustomColumn';

    try {
        const { data } = await axios.get<GETVerifyCustomColumn>(url, {
            params: { column, agencyId },
        });

        return data;
    } catch (error: any) {
        throw error;
    }
};

// GET KEYWORDS BY MONTH
export const getKeywordsByMonth = async (
    agencyId: string,
    advertiserId: string
) => {
    const url = API_CUBIC_DC3 + '/DoubleClick/getKeywordByMonthManually';

    try {
        const { data } = await axios.get<GETKeywordsByMonth>(url, {
            params: { agencyId, advertiserId },
        });

        return data;
    } catch (error: any) {
        throw error;
    }
};
