import { GetScanBidAndBidHeadRoomParams, BidHeadroomState } from '../../state';

export const getBidHeadroomParams = (
  userId: string,
  state: BidHeadroomState
) => {
  const {
    agency,
    advertiser,
    conversionColumn,
    conversionType,
    revenueColumn,
    currentPage,
    rowsPerPage,
  } = state;

  if (
    !agency ||
    !advertiser ||
    !conversionType ||
    (conversionType === 'Actions' && !conversionColumn) ||
    (conversionType === 'Revenue' && !revenueColumn) ||
    !rowsPerPage
  )
    return;

  const params: GetScanBidAndBidHeadRoomParams = {
    userId,
    agencyId: agency.value,
    advertiserId: advertiser.value,
    conversionType: conversionType,
    conversionCol: conversionColumn?.value || '',
    revenueCol: revenueColumn?.value || '',
    startCounter: '25',
    // startCounter: (Math.max(currentPage, 1) * rowsPerPage).toString(),
  };

  return params;
};
