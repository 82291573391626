export enum PRODUCTS_ACTION_TYPES {
    SET_LOADING = 'set_loading',
    TOGGLE_DISPLAY_SETTINGS = 'toggle_display_settings',
    SET_PERFORMANCE_MONITOR_DATA = 'set_performance_monitor_data',
    SET_SCENARIO_PLANNER_DATA = 'set_scenario_planner_data',
    SET_PERFORMANCE_DIAGNOSTIC_DATA = 'set_performance_diagnostic_data',
    SET_YIELD_OPTIMIZER_DATA = 'set_yield_optimizer_data',
    SET_YIELD_OPTIMIZER_BULKSHEET = 'set_yield_optimizer_bulksheet',
    CLEAR_PRODUCTS_DATA = 'clear_products_data',
}
