import './Adhoc-Analysis-Modal.scss';

import React from 'react';
import { useActions, useAppSelector } from '../../hooks';
import { ConversionType, DropdownOption } from '../../state';

// CONSTANTS
import { PRODUCT_NAMES } from '../../constants';

// UI COMPONENTS
import Modal from '../Modal/Modal';
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';

// API REQUESTS
import {
    getManualAdhocDiagnostic,
    getAdhocScenarioPlannerData,
} from '../../api';

// ADHOC CALCULATOR NUMERATOR OPTIONS
const NUMERATOR_OPTIONS = [{ label: 'Cost', value: 'cost' }];

// ADHOC CALCULATOR DENOMINATOR OPTIONS
const DENOMINATOR_OPTIONS = [
    { label: 'Conversion', value: 'Actions' },
    { label: 'Revenue', value: 'Revenue' },
];

// COMPONENT PROPS
interface AdhocAnalysisModalProps {
    onClose?: () => void;
}

// FUNCTIONAL COMPONENT
function AdhocAnalysisModal({ onClose }: AdhocAnalysisModalProps) {
    const { display, target, numerator, denominator, product } = useAppSelector(
        (state) => state.adhocModal
    );
    // REDUX STATE
    const user = useAppSelector(({ auth }) => auth.user);

    // REDUX ACTIONS
    const {
        setLoading,
        alertError,
        setAdhocTarget,
        setAdhocNumerator,
        setAdhocDenominator,
        setDisplayAdhocModal,
        setScenarioPlannerData,
        setPerformanceDiagnosticData,
    } = useActions();

    // HANDLE UPDATING TARGET
    const onSetTarget = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setAdhocTarget(evt.target.value);
    };

    // HANDLE UPDATING NUMERATOR
    const onSetNumerator = (selected: DropdownOption) => {
        setAdhocNumerator(selected.value);
    };

    // HANDLE UPDATING DENOMINATOR
    const onSetDenominator = (selected: DropdownOption) => {
        setAdhocDenominator(selected.value as ConversionType);
    };

    // HANDLE RUN AD HOC PERFORMANCE DIAGNOSTIC
    const runDiagnosticAdhoc = async () => {
        if (!user || !denominator || !target) return;

        try {
            // GET MANUAL ADHOC PERFORMANCE DIAGNOSTIC DATA
            const adhocData = await getManualAdhocDiagnostic(
                user.id,
                target,
                denominator
            );

            // SET ADHOC DATA
            setPerformanceDiagnosticData(adhocData, true);
        } catch (error: any) {
            throw error;
        }
    };

    // HANDLE RUNNING ADHOC SCENARIO PLANNER ANALYSIS
    const runScenarioAdhoc = async () => {
        if (!user || !target || !denominator) return;

        try {
            // GET MANUAL ADHOC SCENARIO PLANNER DATA
            const adhocData = await getAdhocScenarioPlannerData(
                user.id,
                target,
                denominator
            );

            if (adhocData) {
                // SAVE DATA
                setScenarioPlannerData(adhocData, true);
            } else {
                // ALERT ERROR IF DATA === ''
                alertError('No data available.');
            }
        } catch (error: any) {
            throw error;
        }
    };

    // HANDLE RUN ADHOC ANALYSIS
    const onRunAdhocAnalysis = async (evt: React.FormEvent) => {
        evt.preventDefault();

        if (!user) return;

        setLoading(true);

        try {
            if (product === PRODUCT_NAMES.PERFORMANCE_DIAGNOSTIC) {
                await runDiagnosticAdhoc();
            }

            if (product === PRODUCT_NAMES.SCENARIO_PLANNER) {
                await runScenarioAdhoc();
            }

            if (onClose) {
                onClose();
            }
        } catch (error: any) {
            alertError(
                error.response.data?.errorMessage ||
                    error.response.data?.error_message ||
                    'Unable to get manual data.'
            );
        } finally {
            setLoading(false);
            setDisplayAdhocModal(false);
        }
    };

    return display ? (
        <Modal
            onClose={() => setDisplayAdhocModal(false)}
            header={`run ${product}`}
            content={
                <div className="adhoc-analysis-container">
                    <form onSubmit={onRunAdhocAnalysis} id="run-adhoc-analysis">
                        <Row cols={3}>
                            <Column>
                                <Textbox
                                    type="number"
                                    value={target}
                                    onChange={onSetTarget}
                                    min="0"
                                    placeholder="ERS/ROI Efficiency Target"
                                    required
                                >
                                    Efficiency Target
                                </Textbox>
                            </Column>
                            <Column>
                                <Dropdown
                                    options={NUMERATOR_OPTIONS}
                                    value={{
                                        label: '',
                                        value: numerator,
                                    }}
                                    defaultValue={{
                                        label: '',
                                        value: numerator,
                                    }}
                                    onChange={onSetNumerator}
                                    isSearchable={false}
                                    required
                                >
                                    Efficiency Numerator
                                </Dropdown>
                            </Column>
                            <Column>
                                <Dropdown
                                    options={DENOMINATOR_OPTIONS}
                                    value={{
                                        label: '',
                                        value: denominator,
                                    }}
                                    defaultValue={{
                                        label: '',
                                        value: denominator,
                                    }}
                                    onChange={onSetDenominator}
                                    isSearchable={false}
                                    // disabled
                                    required
                                >
                                    Efficiency Denominator
                                </Dropdown>
                            </Column>
                        </Row>
                    </form>
                </div>
            }
            footer={
                <>
                    <Button
                        onClick={() => setDisplayAdhocModal(false)}
                        color="red"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" form="run-adhoc-analysis">
                        Run Analysis
                    </Button>
                </>
            }
        />
    ) : (
        <></>
    );
}

export default AdhocAnalysisModal;
