import './Add-Scheduled-Goal.scss';

import { DEFAULT_API_ERROR } from '../../constants';
import { useActions, useAppSelector } from '../../hooks';
import { addSchedule, addScheduleRunNow } from '../../api';
import {
    ExecutionWay,
    GoalsListItem,
    ScheduleGoalFormState,
} from '../../state';
import ScheduleGoalForm from '../Schedule-Goal-Form/Schedule-Goal-Form';

// UI COMPONENT

// COMPONENT PROPS
interface AddScheduledGoalFormProps {
    goals: GoalsListItem[];
    onAddSchedule: (executionWay: ExecutionWay) => Promise<void>;
}

function AddScheduledGoal({ goals, onAddSchedule }: AddScheduledGoalFormProps) {
    // REDUX ACTIONS
    const { alertError, alertSuccess, setLoading } = useActions();

    // CURRENT USER'S ID
    const userId = useAppSelector(({ auth }) => auth.user?.id);

    // HANDLE ADD SCHEDULED GOAL
    const onAddScheduledGoal = async (
        userId: string,
        formState: ScheduleGoalFormState
    ) => {
        try {
            // ATTEMPT TO ADD NEW SCHEDULE
            const data = await addSchedule(userId, formState);

            if (data?.result === 'success') {
                // ALERT SUCCESS MESSAGE
                alertSuccess(data.message);
            } else {
                // ALERT ERROR MESSAGE
                alertError(data?.message || DEFAULT_API_ERROR);
            }
        } catch (error: any) {
            throw error;
        }
    };

    // HANDLE SCHEDULE RUN NOW GOAL
    const onRunNowGoal = async (
        userId: string,
        formState: ScheduleGoalFormState
    ) => {
        try {
            const data = await addScheduleRunNow(userId, formState);

            if (data?.result === 'success') {
                // ALERT SUCCESS MESSAGE
                alertSuccess(data.message);
            } else {
                // ALERT ERROR MESSAGE
                alertError(data?.message || DEFAULT_API_ERROR);
            }
        } catch (error: any) {
            throw error;
        }
    };

    // HANDLE SUBMIT ADD SCHEDULED GOAL
    const onSubmitScheduledGoal = async (formState: ScheduleGoalFormState) => {
        // CHECK FOR LOGGED IN USER
        if (!userId || !formState.executionWay) return;

        // OPEN LOADING MODAL
        setLoading(true);

        try {
            if (formState.executionWay?.value === 'schedule') {
                await onAddScheduledGoal(userId, formState);
            }

            if (formState.executionWay?.value === 'runNow') {
                await onRunNowGoal(userId, formState);
            }

            // RELOAD CORRESPONDING SCHEUDLED/RUN NOW TABLE
            await onAddSchedule(formState.executionWay.value as ExecutionWay);
        } catch (error: any) {
            throw error;
        } finally {
            // CLOSE LOADING MODAL
            setLoading(false);
        }
    };

    return (
        <div className="add-scheduled-goal-container">
            <div className="add-scheduled-goal-header">
                <h3>add new scheduled goal</h3>
            </div>

            <div className="add-scheduled-goal-content">
                <ScheduleGoalForm
                    goals={goals}
                    onSubmit={onSubmitScheduledGoal}
                />
            </div>
        </div>
    );
}

export default AddScheduledGoal;
