import './Scenario-Planner-Calculator.scss';

import { useState } from 'react';
import { ScenarioPlannerData } from '../../state';
import { useActions, useAppSelector } from '../../hooks';
import { getAdhocWhatIfData, getWhatIfData } from '../../api';
import {
    WhatIfData,
    WhatIfDataParams,
} from '../../state/models/requests-models/scenario-planner-requests';

// UI COMPONENTS
import CalculatorField from './Calculator-Field';

export type ScenarioPlannerMetric = 'Cost' | 'Conversion' | 'Efficiency';

interface ScenarioPlannerCalculatorProps {
    data: ScenarioPlannerData;
    isAdhoc: boolean;
}

function ScenarioPlannerCalculator({
    data,
    isAdhoc,
}: ScenarioPlannerCalculatorProps) {
    const { alertError } = useActions();
    const { goalId, conversionColumn } = useAppSelector(
        (state) => state.universalSettings
    );

    const userId = useAppSelector(({ auth }) => auth.user?.id);

    // GET DEFAULT VALUES
    const cost = data.Cost.slice(-1)[0].toFixed(2);
    const conversion = data.Conversion.slice(-1)[0].toFixed(0);
    const efficiency = data.Efficiency.slice(-1)[0].toFixed(2);

    // SET DEFAULT STATE
    const DEFAULT_VALUES: WhatIfData = {
        Cost: cost,
        Conversion: conversion,
        Efficiency: efficiency,
    };

    // STATE
    const [calcValues, setCalcValues] = useState(DEFAULT_VALUES);

    // HANDLE VALUE CHANGE
    const onChange = (metric: ScenarioPlannerMetric, value: string) => {
        setCalcValues((prev) => {
            const copy = { ...prev };
            copy[metric] = value;
            return copy;
        });
    };

    // HANDLE CALCULATE WHAT IF DATA
    const onCalculate = async (
        metric: ScenarioPlannerMetric,
        value: string | number
    ) => {
        if (!userId) return;

        if (!goalId && !isAdhoc) return;

        try {
            if (isAdhoc) {
                const adhocCalcData = await getAdhocWhatIfData(
                    userId,
                    metric,
                    value.toString()
                );
                setCalcValues(adhocCalcData);
            } else {
                const params: WhatIfDataParams = {
                    userId,
                    goalId: '', //goalId.toString(),
                    metricName: metric,
                    metricValue: value.toString(),
                };
                const result = await getWhatIfData(params);
                setCalcValues(result);
            }
        } catch (error: any) {
            alertError(
                error.response?.data?.errorMessage || 'Unable to calculate data'
            );
        }
    };

    return (
        <div className="scenario-planner-calculator">
            <CalculatorField
                label="cost"
                metric="Cost"
                value={calcValues.Cost}
                onChange={onChange}
                onClick={onCalculate}
            />
            <CalculatorField
                label={conversionColumn || 'Conversions'}
                metric="Conversion"
                value={calcValues.Conversion}
                onChange={onChange}
                onClick={onCalculate}
            />
            <CalculatorField
                label="efficiency"
                metric="Efficiency"
                value={calcValues.Efficiency}
                onChange={onChange}
                onClick={onCalculate}
            />
        </div>
    );
}

export default ScenarioPlannerCalculator;
