import { useState } from 'react';

// TYPES
import { ApexOptions } from 'apexcharts';
import { DropdownOption, PerformanceDetails } from '../../../state';

// UI COMPONENTS
import { Metrics } from './Performance-Chart';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Column } from '@publicismedia-ds/ui-charts';

// UTILS
import { numberWithCommas } from '../../../utils';

interface PerformanceGraphProps {
  prior: PerformanceDetails | null;
  recent: PerformanceDetails | null;
  priorDate: string;
  recentDate: string;
}

export const metrics: Metrics = {
  clicks: 'Clicks',
  avgCPC: 'Avg CPC',
  conversion: `Conversion`,
  ctr: 'CTR',
  efficiency: 'Efficiency',
  cost: 'Cost',
  impr: 'Impressions',
};

function PerformanceGraph({
  prior,
  recent,
  priorDate,
  recentDate,
}: PerformanceGraphProps) {
  const [selectedGraph, setSelectedGraph] =
    useState<keyof PerformanceDetails>('clicks');

  const DROP_DOWN_OPTIONS = Object.keys(metrics).map((key) => {
    return {
      label: metrics[key as keyof Metrics],
      value: key,
    };
  });

  const options: ApexOptions = {
    dataLabels: {
      enabled: true,
      formatter: (val: number) => {
        return numberWithCommas(val);
      },
    },
    noData: {
      text: 'No Data',
      style: {
        fontSize: '1.5rem',
      },
    },
    xaxis: {
      categories: [priorDate, recentDate],
    },
  };

  return (
    <>
      <Dropdown
        options={DROP_DOWN_OPTIONS}
        value={{ label: '', value: selectedGraph }}
        onChange={(selected: DropdownOption) =>
          setSelectedGraph(selected.value as keyof PerformanceDetails)
        }
      >
        Select Metric
      </Dropdown>
      <div className="performance-graph">
        <Column
          data={[
            {
              name: 'Last 30 Days',
              data:
                !prior && !recent
                  ? []
                  : [
                      prior?.[selectedGraph] || null,
                      recent?.[selectedGraph] || null,
                    ],
            },
          ]}
          options={options}
        />
        <h3>{metrics[selectedGraph]}</h3>
      </div>
    </>
  );
}

export default PerformanceGraph;
