import './View-Goal-Modal.scss';

import { useActions, useAppSelector } from '../../hooks';
import { GoalsListItem } from '../../state';

// UI COMPONENTS
import Modal from '../Modal/Modal';
import Button from '@publicismedia-ds/ui-button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router/router';
import ModalFrame from '../Modal/Modal-Frame';
import ModalHeader from '../Modal/Modal-Header';
import ModalContent from '../Modal/Model-Content';
import ModalFooter from '../Modal/Modal-Footer';

// COMPONENT PROPS
interface ViewGoalModalProps {
    onClose: () => void;
    goal: GoalsListItem;
}

// FUNCTIONAL COMPONENT
function ViewGoalModal({ goal, onClose }: ViewGoalModalProps) {
    // REACT ROUTER NAVIGATION
    const navigate = useNavigate();

    // CURRENT USER'S ID
    const userId = useAppSelector(({ auth }) => auth.user?.id);

    // REDUX ACTIONS
    const { loadGoalSettings } = useActions();

    // HANDLE RUNNING REPORTS WITH SELECTED GOAL
    const onRunReports = async () => {
        if (!userId) return;

        // LOAD SELECTED GOAL'S SETTINGS
        loadGoalSettings(goal.goalId, userId);

        // NAVIGATE TO PERFORMANCE MONITOR
        navigate(ROUTES.PERFORMANCE_MONITOR);
    };

    return (
        <Modal onClose={onClose} userClosable={false}>
            <ModalFrame className="view-goal-modal-container">
                <ModalHeader>
                    <h1 className="modal-heading text-align-center">
                        Map Name: {goal.goalMappingName}
                    </h1>
                </ModalHeader>
                <ModalContent className="view-goal-modal-content">
                    <div className="view-goal-modal-row">
                        <h3>
                            Agency: <span>{goal.agencyName}</span>
                        </h3>
                        <h3>
                            Advertiser: <span>{goal.advertiserName}</span>
                        </h3>
                    </div>
                    <div className="view-goal-modal-row">
                        <h3>Engine(s):</h3>
                        <ul>
                            {goal.engineNames.split(',').map((engine) => (
                                <li key={engine}>{engine}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="view-goal-modal-row">
                        <h3>Campaign(s):</h3>
                        <ul>
                            {goal.campaignNames.split(',').map((campaign) => {
                                return <li key={campaign}>{campaign}</li>;
                            })}
                        </ul>
                    </div>
                </ModalContent>
                <ModalFooter>
                    <Button display="text" onClick={onClose}>
                        Close
                    </Button>
                    <Button onClick={onRunReports}>
                        Run Reports with goal
                    </Button>
                </ModalFooter>
            </ModalFrame>
        </Modal>
    );
}

export default ViewGoalModal;
