import { Dispatch } from 'redux';
import { alertError } from './alert-creators';
import { AuthActionType } from '../action-types';
import { userLogin } from '../../api/login-requests';
import { SESSION_STORAGE_USER } from '../../constants';
import { formatUserData } from '../../utils/login-utils';
import { AlertErrorAction } from '../actions/alert-actions';

// TYPES
import { LoginFormState, UserData } from '../models';

import {
    AuthAction,
    AuthSuccessAction,
    AuthFailureAction,
} from '../actions/auth-actions';
import { ProductsAction } from '../actions';
import { setLoading } from './products-creators';

export const authSuccess = (user: UserData): AuthSuccessAction => {
    return {
        type: AuthActionType.AUTH_SUCCESS,
        payload: {
            user,
        },
    };
};

export const authFailure = (): AuthFailureAction => {
    return {
        type: AuthActionType.AUTH_FAILURE,
        payload: {},
    };
};

export const loginUser = ({ email, password }: LoginFormState) => {
    return async (
        dispatch: Dispatch<AuthAction | AlertErrorAction | ProductsAction>
    ) => {
        try {
            dispatch(setLoading(true));
            const data = await userLogin(email, password);
            if (data.result === 'success') {
                const userData = formatUserData(data.sessionData);
                sessionStorage.setItem(
                    SESSION_STORAGE_USER,
                    JSON.stringify(userData)
                );
                dispatch(authSuccess(userData));
            }
        } catch (error: any) {
            dispatch(authFailure());
            dispatch(
                alertError(
                    error?.response?.data?.errorMessage ||
                        'Invalid username or password'
                )
            );
        } finally {
            dispatch(setLoading(false));
        }
    };
};
