import { SELECT_ALL } from '../constants';
import { DropdownOption, GoalsListItem } from '../state';

export * from './admin-utils';
export * from './login-utils';
export * from './file-reader';
export * from './download-file';
export * from './custom-alerts-utils';
export * from './user-activity-utils';
export * from './universal-settings-utils';
export * from './performance-monitor-utils';

// CONVERTS DATE STRINGS WITH FORMAT YYYY-MM-DD
export const convertStringToDate = (dateString: string) => {
    if (!dateString) return '';

    type DateArray = [number, number, number];

    // CREATE DATE ARRAY [YEAR ,MONTH, DATE]
    const dateArr: DateArray = dateString
        .split('-')
        .map((numString) => parseInt(numString)) as DateArray;

    // SUBTRACT 1 FROM MONTH --- MONTHS START AT 0 -> ie Jan === 0, Feb === 1, etc...
    --dateArr[1];

    const convertedDate = new Date(...dateArr);

    // REMOVE 'DAY' PORTION OF STRING
    return convertedDate.toDateString().slice(4);
};

// CAPITALIZE STRINGS
export const capitalize = (string: string) => {
    if (!string) return '';
    const strArr = string.split('');
    return strArr[0].toUpperCase() + string.slice(1);
};

// CONVERT NUMBER TO STRING WITH COMMAS
export const numberWithCommas = (
    num: number | string,
    fixed: number | undefined = 0
) => {
    const number = typeof num === 'number' ? num : parseFloat(num);

    if (number === 0) return '0';

    return number.toLocaleString('en-US', {
        minimumFractionDigits: fixed,
        maximumFractionDigits: fixed,
    });
};

// CONVERT DATE OBJECT TO YYYY-MM-DD FORMAT
export const convertDateToString = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month < 10 ? '0' + month : month}-${
        day < 10 ? '0' + day : day
    }`;
};

// FIND DE-SELECTED OPTION FROM MULTI DROPDOWN SELECTIONS
export const findDeselectedOption = (
    selections: DropdownOption[],
    selectionsState: DropdownOption[]
) => {
    // IF NO SELECTIONS REMAIN RETURN ONLY SELECTION
    if (!selections.length) return selectionsState[0];

    for (let i = 0; i < selectionsState.length; i++) {
        if (selections[i]?.value !== selectionsState[i].value) {
            return selectionsState[i];
        }
    }
    return;
};

// CHECK FOR EMPTY OBJECT
export const isEmptyObj = (obj: object) => {
    for (const i in obj) return false;
    return true;
};

// PREVENT ENTER KEY FROM SUBMITTING FORM
export const onPreventEnterKeySubmit = (evt: React.KeyboardEvent) => {
    if (evt.key === 'Enter') {
        evt.preventDefault();
    }
};

// FORMAT LARGE NUMBERS WITH K/M/B
export const formatLargeNumber = (
    num: number | string,
    options: {
        round?: 'floor' | 'round' | 'ceil';
        decimals?: 0 | 1 | 2;
    } = {}
) => {
    // CONVERT INPUT TO AN INT
    const actualNum = typeof num === 'number' ? Math.round(num) : parseInt(num);

    // IS NUMBER POSITIVE OR NEGATIVE
    const isNegative = actualNum < 0;

    // OUTPUT ROUNDING METHOD (DEFAULT: 'ROUND')
    const round = options.round || 'round';

    // OUTPUT DECIMAL PLACES (DEFAULT: 1)
    const decimals = options.decimals !== undefined ? options.decimals : 1;

    // CONVERT TO ABSOLUTE VALUE (INITIAL VALUE MAY BE NEGATIVE)
    const absNum = Math.abs(actualNum);

    // OUTPUT (DEFAULT: INITIAL VALUE IN STRING FORM)
    let formattedNum = absNum.toString();

    // NUMBER OF TRAILING ZEROS TO REMOVE
    const zerosToRemove = Math.floor((formattedNum.length - 1) / 3) * 3;

    // SINGLE DIGIT
    const singleDigitDecimal = (formattedNum.length - 1) % 3 === 0 ? 1 : 0;

    // ROUND VALUE BASED ON ZEROS TO REMOVE AND DECIMALS TO INCLUDE
    const roundedInt =
        Math[round](
            absNum / Math.pow(10, zerosToRemove - decimals - singleDigitDecimal)
        ) / Math.pow(10, decimals + singleDigitDecimal);

    // ADD CORESPONDING LETTER BASED ON ZEROS REMOVED
    if (zerosToRemove === 3) {
        formattedNum = roundedInt.toString() + 'K';
    }

    if (zerosToRemove === 6) {
        formattedNum = roundedInt.toString() + 'M';
    }

    if (zerosToRemove === 9) {
        formattedNum = roundedInt.toString() + 'B';
    }

    if (zerosToRemove === 10) {
        formattedNum = roundedInt.toString() + 'T';
    }

    return isNegative ? '-' + formattedNum : formattedNum;
};

// REMOVE SELECT ALL OPTION (IF PRESENT) FROM SELECTIONS
export const removeSelectAll = (selections: DropdownOption[]) => {
    return selections.filter(({ value }) => value !== SELECT_ALL.value);
};

// REMOVE SPECIAL CHARS FROM STRING
export const removeSpecialChars = (str: string) => {
    return str
        .replace(/_/g, ' ')
        .replace(/\W/g, ' ')
        .trim()
        .replace(/\s+/g, '_');
};

// DROPDOWN OPTIONS SORTER
export const sortDropdownOptions = (
    options: DropdownOption[],
    order: 'asc' | 'desc' = 'asc'
) => {
    const output = [...options];

    return order === 'asc'
        ? output.sort(SORT_DROPDOWNS.ascending)
        : output.sort(SORT_DROPDOWNS.descending);
};

// SORT DROPDOWN OPTIONS ARRAY
export const SORT_DROPDOWNS = {
    ascending: (a: DropdownOption, b: DropdownOption) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();

        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    },
    descending: (a: DropdownOption, b: DropdownOption) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();

        if (labelA < labelB) {
            return 1;
        }
        if (labelA > labelB) {
            return -1;
        }
        return 0;
    },
};

// CREATE DROPDOWN OPTIONS FOR GOALLIST
export const getGoalOptions = (options: GoalsListItem[]) => {
    const groupedGoals = options.reduce(
        (goals: { [key: string]: DropdownOption[] }, goal) => {
            if (!goals[goal.agencyName]) {
                goals[goal.agencyName] = [];
            }
            goals[goal.agencyName].push({
                label: `${goal.goalMappingName} - ${goal.advertiserName}`,
                value: goal.goalId,
            });

            return goals;
        },
        {}
    );

    // RETURN OPTIONS SORTED ALPHABETICALLY GROUPED BY AGENCY
    return Object.entries(groupedGoals)
        .map(([agencyName, dropdownOptions]) => ({
            label: agencyName,
            options: [...dropdownOptions],
        }))
        .sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
};
