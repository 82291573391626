import produce from 'immer';

// TYPES
import {
  PacingDocState,
  PacingDocAction,
  PACING_DOC_ACTION_TYPES,
} from '../form-models';

// UTILS
import { formatFormulaColumns, validateSelectedColumns } from '../../../utils';

// DEFAULT FORM STATE
export const DEFAULT_PACING_DOC_STATE: PacingDocState = {
  accountId: null,
  brandId: null,
  platformId: null,
  agencies: [],
  accountData: null,
  agency: null,
  advertiser: null,
  goals: [],
  columns: [],
  columnOptions: [],
  useFormulaColumns: false,
  formulaColumns: [],
};

export const pacingDocReducer = produce(
  (state: PacingDocState, action: PacingDocAction): PacingDocState => {
    switch (action.type) {
      case PACING_DOC_ACTION_TYPES.LOAD_AGENCIES:
        state.agencies = action.payload;
        return state;

      case PACING_DOC_ACTION_TYPES.SET_AGENCY: {
        // COPY
        const agencies = [...state.agencies];
        // RESET
        state = { ...DEFAULT_PACING_DOC_STATE };
        // UPDATE
        state.agencies = agencies;
        state.agency = action.payload.agency;
        state.platformId = action.payload.platformId;
        state.accountData = action.payload.accountData;
        return state;
      }

      case PACING_DOC_ACTION_TYPES.SET_ADVERTISER: {
        const existingForumlas =
          state.accountData?.advertisers.find(
            ({ advertiserId }) =>
              advertiserId === action.payload.advertiser?.value
          )?.formulaColumn || [];

        // RESET
        state.goals = [];
        state.columns = [];
        state.formulaColumns = [];
        state.useFormulaColumns = false;

        // UPDATE
        state.advertiser = action.payload.advertiser;
        state.columnOptions = action.payload.columnOptions;
        state.accountId = action.payload.accountId;
        state.brandId = action.payload.brandId;
        state.formulaColumns = formatFormulaColumns(existingForumlas);
        return state;
      }

      case PACING_DOC_ACTION_TYPES.SET_GOALS:
        state.goals = action.payload;
        return state;

      case PACING_DOC_ACTION_TYPES.SET_COLUMNS:
        state.columns = action.payload;
        return state;

      case PACING_DOC_ACTION_TYPES.USE_FORMULA_COLUMNS:
        state.useFormulaColumns = action.payload;
        state.columns = validateSelectedColumns(
          state.formulaColumns,
          state.columns
        );
        return state;

      case PACING_DOC_ACTION_TYPES.ADD_FORMULA_COLUMN: {
        state.columns = validateSelectedColumns(
          [action.payload],
          state.columns
        );

        // ADD FORMULA COLUMN
        state.formulaColumns.push(action.payload);
        return state;
      }

      case PACING_DOC_ACTION_TYPES.REMOVE_FORMULA_COLUMN:
        state.formulaColumns = state.formulaColumns.filter(
          (_formula, index) => index !== action.payload
        );
        return state;

      case PACING_DOC_ACTION_TYPES.UPDATE_FORMULA_COLUMN:
        state.formulaColumns[action.payload.index] = action.payload.formula;
        return state;

      case PACING_DOC_ACTION_TYPES.RESET_FORM: {
        const agencies = [...state.agencies];
        state = { ...DEFAULT_PACING_DOC_STATE };
        state.agencies = agencies;
        return state;
      }
    }
  },
  DEFAULT_PACING_DOC_STATE
);
