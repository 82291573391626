import './Modal.scss';

// COMPONENT PROPS
interface ModalFrameProps {
    className?: string;
    children: React.ReactNode;
    topBorderColor?: string;
}

// FUNCTIONAL COMPONENT (MODAL FRAME)
function ModalFrame({ className, children, topBorderColor }: ModalFrameProps) {
    return (
        <div
            className={`modal-frame${className ? ' ' + className : ''}`}
            onClick={(e) => e.stopPropagation()}
        >
            <div
                className={`modal-top-border ${
                    topBorderColor || 'modal-top-border-brand-1'
                }`}
            ></div>
            <div className="modal-content-box">{children}</div>
        </div>
    );
}

export default ModalFrame;
