import { useEffect, useState } from 'react';
import { NoteData, NoteDetails } from '../../state';

import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import Tooltip from '@publicismedia-ds/ui-tooltip';

// COMPONENT PROPS
interface NotesTableProps {
    notes: NoteDetails[];
    onEditClick: (data: NoteDetails) => void;
}

// FUNCTIONAL COMPONENT
function NotesTable({ notes, onEditClick }: NotesTableProps) {
    const [dataProvider, setDataProvider] = useState<any>([]);

    // CREATE DATA PROVIDER ON INITIAL LOAD
    useEffect(() => {
        const formattedNotes = notes.map((note, index) => {
            return { ...note, index: index + 1 };
        });
        setDataProvider(new DataProvider({ data: formattedNotes }));
    }, [notes]);

    return (
        <DataTable
            data={dataProvider}
            bindKey="index"
            emptyState={
                <>
                    <h2>No data available.</h2>
                </>
            }
        >
            <ColumnTemplate bind="index" isSortable={true} minWidth>
                Sr.
            </ColumnTemplate>
            <ColumnTemplate bind="agencyName" isSortable={true} minWidth>
                Agency
            </ColumnTemplate>
            <ColumnTemplate bind="advertiserName" isSortable={true} minWidth>
                Advertiser
            </ColumnTemplate>
            <ColumnTemplate
                bind="notesData"
                onRender={(noteData: NoteData[]) => {
                    return <span>{noteData.length} Note(s)</span>;
                }}
                minWidth
            >
                Num. of Notes
            </ColumnTemplate>
            <ColumnTemplate
                bind="index"
                type="actions"
                onRender={(index: number) => {
                    return (
                        <Tooltip title="Edit">
                            <i
                                className="icon-edit"
                                title="Edit Notes"
                                onClick={() => onEditClick(notes[index - 1])}
                            ></i>
                        </Tooltip>
                    );
                }}
            >
                Actions
            </ColumnTemplate>
        </DataTable>
    );
}

export default NotesTable;
