import { useActions } from '../../hooks';
import { addKenshooAccount } from '../../api';
import React, { useReducer, useState } from 'react';
import { DEFAULT_API_ERROR } from '../../constants';
import { onPreventEnterKeySubmit } from '../../utils';
import {
    kenshooFormReducer,
    INITIAL_KENSHOO_FORM,
    KENSHOO_ACTION_TYPES,
} from '../../state';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';

// COMPONENT PROPS
interface KenshooAccountFormProps {
    onSubmit?: () => Promise<void>;
}

// FUNCTION COMPONENT
function KenshooAccountForm({ onSubmit }: KenshooAccountFormProps) {
    // REDUX ACTIONS
    const { setLoading, alertError, alertSuccess } = useActions();

    // KENSHOO FORM STATE
    const [formState, dispatch] = useReducer(
        kenshooFormReducer,
        INITIAL_KENSHOO_FORM
    );

    // HANDLE SETTING ACCOUNT NAME VALUE
    const onSetAccountName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: KENSHOO_ACTION_TYPES.SET_ACCOUNT_NAME,
            payload: evt.target.value,
        });
    };

    // HANDLE SETTING KS ID VALUE
    const onSetKsId = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: KENSHOO_ACTION_TYPES.SET_KS_ID,
            payload: evt.target.value,
        });
    };

    // HANDLE ADDING PROFILE
    const onAddProfile = () => {
        dispatch({
            type: KENSHOO_ACTION_TYPES.ADD_PROFILE,
        });
    };

    // HANDLE SETTING PROFILE ID VALUE
    const onSetProfileId = (
        evt: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        dispatch({
            type: KENSHOO_ACTION_TYPES.SET_PROFILE_ID,
            payload: { index, value: evt.target.value },
        });
    };

    // HANDLE SETTING PROFILE NAME VALUE
    const onSetProfileName = (
        evt: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        dispatch({
            type: KENSHOO_ACTION_TYPES.SET_PROFILE_NAME,
            payload: { index, value: evt.target.value },
        });
    };

    // HANDLE REMOVING PROFILE INPUTS ROW
    const onRemoveProfile = (index: number) => {
        dispatch({
            type: KENSHOO_ACTION_TYPES.REMOVE_PROFILE,
            payload: index,
        });
    };

    // HANDLE SETTING KEYWORD REPORT ID
    const onSetKeywordReportId = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: KENSHOO_ACTION_TYPES.SET_KEYWORD_REPORT_ID,
            payload: evt.target.value,
        });
    };

    // HANDLE SETTING KEYWORD REPORT NAME
    const onSetKeywordReportName = (
        evt: React.ChangeEvent<HTMLInputElement>
    ) => {
        dispatch({
            type: KENSHOO_ACTION_TYPES.SET_KEYWORD_REPORT_NAME,
            payload: evt.target.value,
        });
    };

    // HANDLE SETTING CAMPAIGN REPORT ID
    const onSetCampaignReportId = (
        evt: React.ChangeEvent<HTMLInputElement>
    ) => {
        dispatch({
            type: KENSHOO_ACTION_TYPES.SET_CAMPAIGN_REPORT_ID,
            payload: evt.target.value,
        });
    };

    // HANDLE SETTING CAMPAIGN REPORT NAME
    const onSetCampaignReportName = (
        evt: React.ChangeEvent<HTMLInputElement>
    ) => {
        dispatch({
            type: KENSHOO_ACTION_TYPES.SET_CAMPAIGN_REPORT_NAME,
            payload: evt.target.value,
        });
    };

    // HANDLE SETTING ADGROUP REPORT ID
    const onSetAdgroupReportId = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: KENSHOO_ACTION_TYPES.SET_ADGROUP_REPORT_ID,
            payload: evt.target.value,
        });
    };

    // HANDLE SETTING ADGROUP REPORT NAME
    const onSetAdgroupReportName = (
        evt: React.ChangeEvent<HTMLInputElement>
    ) => {
        dispatch({
            type: KENSHOO_ACTION_TYPES.SET_ADGROUP_REPORT_NAME,
            payload: evt.target.value,
        });
    };

    // ENABLE/DISABLE SUBMIT BUTTON
    let isSubmitDisabled =
        !formState.accountName ||
        !formState.ksId ||
        !formState.keywordReportId ||
        !formState.keywordReportName ||
        !formState.campaignReportId ||
        !formState.campaignReportName ||
        !formState.adgroupReportId ||
        !formState.adgroupReportName;

    for (let profile of formState.profileData) {
        if (!profile.profileId || !profile.profileName) {
            isSubmitDisabled = true;
            break;
        }
    }

    // HANDLE KENSHOO ACCOUNT SUBMISSION
    const onSubmitKenshooAccount = async (evt: React.FormEvent) => {
        evt.preventDefault();

        setLoading(true);

        try {
            const data = await addKenshooAccount(formState);

            dispatch({
                type: KENSHOO_ACTION_TYPES.RESET_KENSHOO_FORM,
            });

            // RUN SUBMIT CALLBACK IF PRESENT
            if (onSubmit) onSubmit();
        } catch (error: any) {
            alertError(
                error.response?.data?.errorMessage ||
                    error.response?.data?.message ||
                    DEFAULT_API_ERROR
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="kenshoo-account-form-container">
            <h2 className="title">Kenshoo</h2>
            <form
                onSubmit={onSubmitKenshooAccount}
                onKeyDown={onPreventEnterKeySubmit}
            >
                <div className="inputs-row">
                    <Textbox
                        onChange={onSetAccountName}
                        value={formState.accountName}
                        required
                    >
                        Account Name
                    </Textbox>
                    <Textbox
                        onChange={onSetKsId}
                        value={formState.ksId}
                        required
                    >
                        KS ID
                    </Textbox>
                </div>
                {formState.profileData.map((data, index) => {
                    return (
                        <div className="inputs-row" key={index}>
                            <Textbox
                                value={data.profileName}
                                onChange={(
                                    evt: React.ChangeEvent<HTMLInputElement>
                                ) => onSetProfileName(evt, index)}
                                required
                            >
                                Profile Name
                            </Textbox>
                            <Textbox
                                value={data.profileId}
                                onChange={(
                                    evt: React.ChangeEvent<HTMLInputElement>
                                ) => onSetProfileId(evt, index)}
                                required
                            >
                                Profile ID
                            </Textbox>
                            {index > 0 && (
                                <i
                                    className="icon-close"
                                    onClick={() => onRemoveProfile(index)}
                                />
                            )}
                        </div>
                    );
                })}
                <div className="inputs-row">
                    <div className="insert-inputs-row-button">
                        <Button onClick={onAddProfile} color="brand-2">
                            Add Profile <i className="icon-add" />
                        </Button>
                    </div>
                </div>
                <div className="inputs-row">
                    <h5 style={{ maxWidth: '20%' }}>Keyword Report Type:</h5>
                    <Textbox
                        onChange={onSetKeywordReportName}
                        value={formState.keywordReportName}
                        required
                    >
                        Report Name
                    </Textbox>
                    <Textbox
                        onChange={onSetKeywordReportId}
                        value={formState.keywordReportId}
                        required
                    >
                        Report ID
                    </Textbox>
                </div>
                <div className="inputs-row">
                    <h5 style={{ maxWidth: '20%' }}>Campaigns Report Type:</h5>
                    <Textbox
                        onChange={onSetCampaignReportName}
                        value={formState.campaignReportName}
                        required
                    >
                        Report Name
                    </Textbox>
                    <Textbox
                        onChange={onSetCampaignReportId}
                        value={formState.campaignReportId}
                        required
                    >
                        Report ID
                    </Textbox>
                </div>
                <div className="inputs-row">
                    <h5 style={{ maxWidth: '20%' }}>Adgroup Report Type:</h5>
                    <Textbox
                        onChange={onSetAdgroupReportName}
                        value={formState.adgroupReportName}
                        required
                    >
                        Report Name
                    </Textbox>
                    <Textbox
                        onChange={onSetAdgroupReportId}
                        value={formState.adgroupReportId}
                        required
                    >
                        Report ID
                    </Textbox>
                </div>
                <div className="submit-button-container">
                    <Button type="submit" disabled={isSubmitDisabled}>
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default KenshooAccountForm;
