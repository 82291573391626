import { axios } from '../../api';
import { DarwinAlert, GETDarwinAlert } from '../../state';
import { useEffect, useState } from 'react';

// CONSTANTS
import { API_UNIVERSAL_SETTINGS } from '../../constants';

// UI COMPONENTS
import { useAsyncWrapper } from '../../hooks';
import DarwinAlertsModal from '../Dawin-Alerts-Modal/Darwin-Alerts-Modal';

// COMPONENT PROPS
interface NavAlertBtnProps {
  userId: string;
}

// FUNCTIONAL COMPONENT (ALERTS NAVBAR LINK)
function NavAlertBtn({ userId }: NavAlertBtnProps) {
  const wrapper = useAsyncWrapper();
  const [alerts, setAlerts] = useState<DarwinAlert[]>([]);
  const [displayAlerts, setDisplayAlerts] = useState(false);

  // FETCH AND STORE USER'S ALERTS
  const loadAlerts = async () => {
    let fetchedAlerts: DarwinAlert[] = [];
    const url = API_UNIVERSAL_SETTINGS + '/getAlert';
    const { data } = await axios.get(url, {
      params: {
        userId,
      },
    });

    if (data.alertList) {
      fetchedAlerts = [...data.alertList];
    }

    if (Array.isArray(data)) {
      fetchedAlerts = [...data];
    }
    setAlerts([...fetchedAlerts]);
  };

  // FETCH ALERTS ON INITAL LOAD
  useEffect(() => {
    if (userId) {
      loadAlerts();
    }
  }, []);

  // DELETE ALERT
  const onDeleteAlert = wrapper(async (alertId: string) => {
    const url = API_UNIVERSAL_SETTINGS + '/updateAlert';

    const body = new URLSearchParams();
    body.append('alertId', alertId);

    const { data } = await axios.post(url, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (data.result === 'success') {
      setAlerts((prev) => prev.filter((alert) => alert.alertId === alertId));
    }
  });

  // JSX
  return (
    <>
      <div className="pmds-nav-link pmds-nav-link__no-hover pmds-nav-link__not-expandable pmds-nav-link__no-icon">
        <button
          className="pmds-nav-link__action pmds-nav-link__as-button nav-alert-button"
          onClick={() => {
            setDisplayAlerts((prev) => !prev);
          }}
        >
          <span className="pmds-nav-link__text">Alerts</span>
          {!!alerts?.length && (
            <span className="alert-notification">{alerts.length}</span>
          )}
        </button>
      </div>
      {displayAlerts && (
        <DarwinAlertsModal
          alerts={alerts}
          onClose={() => setDisplayAlerts(false)}
          onDelete={onDeleteAlert}
        />
      )}
    </>
  );
}

export default NavAlertBtn;
