import './Notes.scss';

import { useEffect } from 'react';
import { ROUTES } from '../../router/router';
import { useNavigate } from 'react-router-dom';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';

// API REQUESTS
import { addNote } from '../../api';

// CONSTANTS
import { DEFAULT_API_ERROR } from '../../constants';

// TYPES
import { NoteDetails, AddNoteFormState } from '../../state';

// UI COMPONENTS
import NotesTable from '../../components/Charts/Notes-Table';
import AdminPage from '../../components/Admin-Page/Admin-Page';
import AddNoteForm from '../../components/Add-Note-Form/Add-Note-Form';

// FUNCTIONAL COMPONENT
function Notes() {
    // HOOKS
    const navigate = useNavigate();
    const asyncWrapper = useAsyncWrapper();

    // REDUX STATE
    const { notesData } = useAppSelector(({ notes }) => notes);
    const userId = useAppSelector(({ auth }) => auth.user?.id);

    // REDUX ACTIONS
    const { alertError, alertSuccess, loadAllNotes } = useActions();

    // FETCH AND STORE ALL NOTES DATA ON INITIAL PAGE LOAD
    useEffect(() => {
        if (!userId || notesData.length) return;
        loadAllNotes(userId);
    }, []);

    // HANDLE ADDING NEW NOTE
    const onAddNote = asyncWrapper(async (formState: AddNoteFormState) => {
        if (!userId) return;

        const data = await addNote(userId, formState);

        if (data.status === 'success') {
            alertSuccess(data.msg);
            loadAllNotes(userId);
        } else
            alertError(
                data?.error_message ||
                    data?.errorMessage ||
                    data?.message ||
                    data?.msg ||
                    DEFAULT_API_ERROR
            );
    });

    // HANDLE EDIT NOTE CLICK EVENT
    const onEditNoteClick = (notes: NoteDetails) => {
        navigate(ROUTES.EDIT_NOTES, { state: { notes } });
    };

    return (
        <AdminPage header="notes">
            <div className="notes-container">
                <div className="add-note-form">
                    <AddNoteForm onSubmit={onAddNote} />
                </div>
                <h2 className="existing-notes-table-header">Exisitng Notes:</h2>
                <div className="existing-notes-table">
                    <NotesTable
                        notes={notesData}
                        onEditClick={onEditNoteClick}
                    />
                </div>
            </div>
        </AdminPage>
    );
}

export default Notes;
