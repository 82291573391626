import GoogleSubAccount from './Google-Sub-Account';
import { useActions, useAppSelector } from '../../hooks';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';

// FUNCTIONAL COMPONENT
function GoogleSubAccounts() {
    // REDUX ACTIONS
    const { addGoolgeSubAccount } = useActions();

    // REDUX STATE
    const { googleAdsSubAccounts } = useAppSelector(
        ({ editAccountForm }) => editAccountForm
    );

    return (
        <div>
            <h4 className="edit-account-header">Sub Accounts:</h4>
            {googleAdsSubAccounts.map((data, index) => {
                return (
                    <GoogleSubAccount
                        data={data}
                        index={index}
                        key={`google-sub-account-${index}`}
                    />
                );
            })}
            {/* CREATE NEW INPUTS ROW FOR ADDITIONAL SUB ACCOUNT */}
            <div className="insert-inputs-row-button">
                <Button onClick={() => addGoolgeSubAccount()} color="brand-2">
                    New Sub Account
                    <i className="icon-add" />
                </Button>
            </div>
        </div>
    );
}

export default GoogleSubAccounts;
