import './Login.scss';

import { ROUTES } from '../../router/router';
import { useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';

// FORM STATE
import { LoginFormActionTypes } from './Login-state/actions';
import { initialState, loginFormReducer } from './Login-state/reducer';

// API REQUESTS
import { forgotPassword } from '../../api';

// LOGOS
import logoDarwin from '../../assets/images/logo.svg';
import logoPerfomics from '../../assets/images/logoPerformics.svg';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import { Row, Column } from '@publicismedia-ds/ui-grid';

// FUNCTIONAL COMPONENT
function Login() {
    // HOOKS
    const navigate = useNavigate();
    const wrapper = useAsyncWrapper();

    // FORM STATE
    const [formState, dispatch] = useReducer(loginFormReducer, initialState);

    // REDUX STATE
    const { isAuthenticated } = useAppSelector(({ auth }) => auth);

    // REDUX ACTIONS
    const { loginUser, alertSuccess } = useActions();

    // REDIRECT LOGGED IN USER TO LANDING
    useEffect(() => {
        if (isAuthenticated) navigate(ROUTES.HOME);
    }, [navigate, isAuthenticated]);

    // HANDLE EMAIL INPUT CHANGE
    const onEmailChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: LoginFormActionTypes.UPDATE_EMAIL,
            value: evt.target.value,
        });
    };

    // HANDLE RESET PASSWORD SUBMIT
    const onResetPassword = wrapper(async () => {
        if (!formState.email) return;

        const { result } = await forgotPassword(formState.email);

        if (result === 'success') {
            alertSuccess(
                'An email has been sent to your inbox. Please click the link in that email to reset your password. If you do not see the email, please check your Spam folder.'
            );
        } else {
            throw new Error(
                'Error: Forgot Password Api call is not working. Please contact Darwin support team.'
            );
        }

        dispatch({
            type: LoginFormActionTypes.RESET_FORM,
        });
    });

    // HANDLE FORM SUBMIT FOR LOGIN OR PASSWORD RESET
    const onSubmit = async (evt: React.FormEvent) => {
        evt.preventDefault();
        dispatch({
            type: LoginFormActionTypes.UPDATE_LOADING,
            value: true,
        });
        if (formState.forgotPassword) {
            await onResetPassword();
        } else {
            loginUser(formState);
        }
        dispatch({
            type: LoginFormActionTypes.UPDATE_LOADING,
            value: false,
        });
    };

    // JSX
    return (
        <div className="login-container">
            {/* ERROR MESSAGE */}
            {formState.errorMessage && (
                <div className="error-message">
                    <p>{formState.errorMessage}</p>
                </div>
            )}
            <div className="login-content">
                <div className="logo-container">
                    <img src={logoDarwin} alt="Darwin Logo" />
                </div>
                <h4>
                    {formState.forgotPassword
                        ? 'Forgot Password'
                        : 'User Login'}
                </h4>
                <form className="login-form" onSubmit={onSubmit}>
                    {formState.forgotPassword ? (
                        <Row cols={1}>
                            <Column>
                                {/* < FORGOT PASSWORD INPUT > */}
                                <Textbox
                                    value={formState.email}
                                    placeholder="Email required"
                                    type="email"
                                    onChange={onEmailChange}
                                    required
                                    invert={true}
                                    error="Email required"
                                >
                                    Username / Email
                                </Textbox>
                                {/* </ FORGOT PASSWORD INPUT > */}
                            </Column>
                        </Row>
                    ) : (
                        <>
                            {/* < LOGIN INPUTS > */}
                            <Row cols={1}>
                                <Column>
                                    <Textbox
                                        value={formState.email}
                                        placeholder="username or email required"
                                        onChange={onEmailChange}
                                        required
                                        invert={true}
                                        error="Username/Email required"
                                    >
                                        Username / Email
                                    </Textbox>
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <Textbox
                                        value={formState.password}
                                        placeholder="password required"
                                        type="password"
                                        onChange={(
                                            evt: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            dispatch({
                                                type: LoginFormActionTypes.UPDATE_PASSWORD,
                                                value: evt.target.value,
                                            });
                                        }}
                                        invert={true}
                                        error="Password required"
                                        required
                                    >
                                        Password
                                    </Textbox>
                                </Column>
                            </Row>
                            {/* </ LOGIN INPUTS > */}
                        </>
                    )}
                    <Row>
                        <Column>
                            <div className="login-btn-container">
                                <Button type="submit" className="login-btn">
                                    {formState.forgotPassword
                                        ? 'Reset Password'
                                        : 'Login'}
                                </Button>
                            </div>
                        </Column>
                    </Row>
                </form>
                <div className="login-footer">
                    <Button
                        display="text"
                        className="btn forgot-password-btn"
                        onClick={() =>
                            dispatch({
                                type: LoginFormActionTypes.UPDATE_FORGOT_PASSWORD,
                                value: !formState.forgotPassword,
                            })
                        }
                    >
                        {formState.forgotPassword
                            ? 'Return to Login'
                            : 'Forgot Password?'}
                    </Button>
                    <p>
                        Powerd by:{' '}
                        <img
                            src={logoPerfomics}
                            alt="Performics Logo"
                            className="logo-performics"
                        />{' '}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Login;
