import produce from 'immer';
import {
  AddNoteFormState,
  AddNoteFormAction,
  ADD_NOTE_ACTION_TYPE,
} from '../form-models';

export const DEFAULT_ADD_NOTE_STATE: AddNoteFormState = {
  agencies: [],
  agencyOptions: [],
  selectedAgency: '',
  advertiserOptions: [],
  selectedAdvertiser: '',
  notes: '',
  notesHistory: [],
  createdByUserId: '',
  updatedByUserId: '',
  isUpdated: '0',
};

export const addNoteFormReducer = produce(
  (state: AddNoteFormState, action: AddNoteFormAction): AddNoteFormState => {
    switch (action.type) {
      case ADD_NOTE_ACTION_TYPE.LOAD_AGENCIES_DATA: {
        state = { ...DEFAULT_ADD_NOTE_STATE };
        state.agencies = action.payload;
        state.agencyOptions = action.payload?.map((agency) => ({
          label: agency.agencyName,
          value: agency.agencyId,
        }));
        return state;
      }

      case ADD_NOTE_ACTION_TYPE.SET_SELECTED_AGENCY:
        state.selectedAgency = action.payload.agencyId;
        state.advertiserOptions = action.payload.advertisers?.map(
          (advertiser) => {
            return {
              label: advertiser.advertiserName,
              value: advertiser.advertiserId,
            };
          }
        );
        state.selectedAdvertiser = '';
        state.notes = '';
        return state;

      case ADD_NOTE_ACTION_TYPE.SET_SELECTED_ADVERTISER:
        state.selectedAdvertiser = action.payload.value;
        state.notes = '';
        return state;

      case ADD_NOTE_ACTION_TYPE.SET_NOTE:
        state.notes = action.payload;
        return state;

      case ADD_NOTE_ACTION_TYPE.RESET_FORM: {
        const agencyOptions = [...state.agencyOptions];
        state = { ...DEFAULT_ADD_NOTE_STATE };
        state.agencyOptions = agencyOptions;
        return state;
      }

      default:
        return state;
    }
  },
  DEFAULT_ADD_NOTE_STATE
);
