import './Add-Goal-Form.scss';
import { DEFAULT_API_ERROR } from '../../constants';
import { useAppSelector, useActions } from '../../hooks';
import { addGoalMapping, getAccountsData } from '../../api';
import React, { useReducer, useMemo, useState } from 'react';
import { findDeselectedOption, parseFile } from '../../utils';
import {
    DropdownOption,
    ReportType,
    AgencyItem,
    CampaignDetails,
    addGoalFormReducer,
    ADD_GOAL_FORM_TYPES,
    CampaignFilter,
    INITIAL_ADD_GOAL_FORM,
    SELECT_ALL_OPTION,
} from '../../state';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import TabGroup from '@publicismedia-ds/ui-tabs';
import Textbox from '@publicismedia-ds/ui-textbox';
import Checkbox from '@publicismedia-ds/ui-checkbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { alertSuccess } from '../../state/action-creators';
import UploadExcluded from '../Upload-Excluded/Upload-Excluded';
import FilterCampaigns from '../Filter-Campaigns/Filter-Campaigns';
import SelectCampaigns from '../Select-Campaigns/Select-Campaigns';

// ADD GOAL FORM COMPONENT PROPS
interface AddGoalFormProps {
    agencies: AgencyItem[];
}

// FUNCTIONAL COMPONENT
function AddGoalForm({ agencies }: AddGoalFormProps) {
    // CURRENT USER'S ID
    const user = useAppSelector(({ auth }) => auth.user);

    // ADD GOAL FORM STATE
    const [formState, dispatch] = useReducer(
        addGoalFormReducer,
        INITIAL_ADD_GOAL_FORM
    );

    // REDUX ACTIONS
    const { alertError, setLoading, loadGoalsList } = useActions();

    // ENGINES DROPDOWN OPTIONS - (MEMOIZE TO IMPROVE PERFORMANCE)
    const engineOptions: DropdownOption[] = useMemo(() => {
        const options = Object.values(formState.allEngines).map((engine) => {
            return {
                label: engine.engineName,
                value: engine.engineId,
            };
        });
        return options;
    }, [formState.advertiser]);

    // CAMPAIGN DROPDOWN OPTIONS - (MEMOIZE TO IMPROVE PERFORMANCE)
    const campaignOptions = useMemo(() => {
        const options = formState.selectedEngines
            .reduce((camps: CampaignDetails[], { value }) => {
                if (value === 'all') return camps;
                // ADD ALL CAMPAIGNS FOR ENGINE
                camps.push(...formState.allEngines[value]?.campaigns);
                return camps;
            }, [])
            .map((campaign) => ({
                label: campaign.campaignName,
                value: campaign.campaignId,
            }));
        return options;
    }, [formState.selectedEngines]);

    // DISABLE/ENABLE SUBMIT BUTTON
    const isSubmitDisabled =
        !formState.name ||
        !formState.agency ||
        !formState.advertiser ||
        !formState.selectedEngines.length ||
        !formState.reportType ||
        !formState.selectedCampaigns.length;

    // HANDLE GOAL NAME INPUT CHANGE
    const onGoalNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: ADD_GOAL_FORM_TYPES.SET_NAME,
            payload: evt.target.value,
        });
    };

    // HANDLE AGENCY SELECTION
    const onSelectAgency = async (selected: DropdownOption) => {
        if (!user) return;

        // CHECK IF SELECTED VALUE IS THE SAME
        if (formState.agency?.agencyId === selected.value) return;

        setLoading(true);

        try {
            // FETCH SELECTED AGENCY'S ACCOUNT DATA
            const data = await getAccountsData(user.id, selected.value);

            // UPDATE FORM STATE WITH ACCOUNT DATA
            dispatch({
                type: ADD_GOAL_FORM_TYPES.SET_AGENCY,
                payload: data,
            });
        } catch (error: any) {
            // ALERT ERROR ON ERROR
            alertError(
                error.response?.data?.errorMessage ||
                    'Unable to get agency account data.  Please contact Darwin support'
            );
        } finally {
            setLoading(false);
        }
    };

    // HANDLE ADVERTISER SELECTION
    const onSelectAdvertiser = (selected: DropdownOption) => {
        if (!formState.agency) return;

        // DO NOTHING IF SELECTED VALUE IS THE SAME
        if (formState.advertiser?.advertiserId === selected.value) return;

        // FIND ADVERTISER DATA
        const advertiserData = formState.agency.advertisers.find(
            (advertiser) => advertiser.advertiserId === selected.value
        );

        if (!advertiserData) return;

        // STORE ADVERTISER DATA;
        dispatch({
            type: ADD_GOAL_FORM_TYPES.SET_ADVERTISER,
            payload: advertiserData,
        });
    };

    // HANDLE ENGINE SELECTION
    const onSelectEngine = (selections: DropdownOption[]) => {
        if (!formState.advertiser) return;

        // IF OPTION WAS SELECTED
        if (selections.length > formState.selectedEngines.length) {
            dispatch({
                type: ADD_GOAL_FORM_TYPES.ADD_SELECTED_ENGINE,
                payload: selections[selections.length - 1],
            });

            // IF OPTION WAS DE-SELECTED
        } else {
            // FIND DE-SELECTED OPTION
            const deselectedOption = findDeselectedOption(
                selections,
                formState.selectedEngines
            );

            if (!deselectedOption) return;

            // REMOVE DE-SELECTED OPTION
            dispatch({
                type: ADD_GOAL_FORM_TYPES.REMOVE_SELECTED_ENGINE,
                payload: deselectedOption,
            });
        }
    };

    // HANDLE REPORT TYPE SELECTION
    const onReportTypeSelection = (selected: {
        label: string;
        value: ReportType;
    }) => {
        dispatch({
            type: ADD_GOAL_FORM_TYPES.SET_REPORT_TYPE,
            payload: selected.value,
        });
    };

    // HANDLE SYNC GOAL BETWEEN PROD & QA
    const onSyncGoalCheck = (
        evt: React.ChangeEvent<HTMLInputElement>,
        isChecked: boolean
    ) => {
        dispatch({
            type: ADD_GOAL_FORM_TYPES.SET_SYNC_GOAL,
            payload: isChecked,
        });
    };

    // HANDLE CAMPAIGN SELECTIONS
    const onSelectCampaigns = (
        selections: DropdownOption[],
        uploaded?: boolean
    ) => {
        if (!formState.selectedEngines) return;

        // IF SELECTIONS ARE FROM UPLOADED FILE
        if (uploaded) {
            dispatch({
                type: ADD_GOAL_FORM_TYPES.UPLOAD_CAMPAIGN_IDS,
                payload: selections,
            });
            return;
        }

        // IF ITEM SELECTED
        if (selections.length > formState.selectedCampaigns.length) {
            dispatch({
                type: ADD_GOAL_FORM_TYPES.ADD_SELECTED_CAMPAIGN,
                payload: selections[selections.length - 1],
            });
            return;
        } else {
            // FIND AND REMOVE SELECTED OPTION
            const deselectedOption = findDeselectedOption(
                selections,
                formState.selectedCampaigns
            );

            if (deselectedOption) {
                dispatch({
                    type: ADD_GOAL_FORM_TYPES.REMOVE_SELECTED_CAMPAIGN,
                    payload: deselectedOption,
                });
            }
        }
    };

    // HANDLE TOGGLE AUTO ADD 360
    const onSelectAutoAdd360 = (isChecked: boolean) => {
        dispatch({
            type: ADD_GOAL_FORM_TYPES.SET_AUTO_ADD_360,
            payload: isChecked,
        });
    };

    // HANDLE UPLOADING EXCLUDED CAMPAIGNS FROM FILE
    const onUploadExcludedCampaigns = async (
        evt: React.ChangeEvent<HTMLInputElement>
    ) => {
        const selectedFile = evt.target.files?.[0];

        if (!selectedFile) return;

        // DISPLAY LOADING MODAL
        setLoading(true);

        try {
            // ATTEMPT TO PARSE FILE AND ADD EXCLUDED CAMPAIGNS
            const excludedCampaigns = await parseFile(selectedFile, 'campaign');

            dispatch({
                type: ADD_GOAL_FORM_TYPES.UPLOAD_EXCLUDED_CAMPAIGNS,
                payload: excludedCampaigns,
            });
        } catch (error: any) {
            // ALERT ANY ERRORS THAT OCCUR DURING PROCESSING
            alertError(error);
        } finally {
            // CLOSE MODAL
            setLoading(false);
        }
    };
    // HANDLE UPLOADING EXCLUDED KEYWORDS FROM FILE
    const onUploadExcludedKeywords = async (
        evt: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (!evt.target.files?.length) return;

        // DISPLAY LOADING MODAL
        setLoading(true);

        try {
            // ATTEMPT TO PARSE FILE AND ADD EXCLUDED KEYWORDS
            const excludedKeywords = await parseFile(
                evt.target.files[0],
                'keyword'
            );
            if (!excludedKeywords) return;

            dispatch({
                type: ADD_GOAL_FORM_TYPES.UPLOAD_EXCLUDED_KEYWORDS,
                payload: excludedKeywords,
            });
        } catch (error: any) {
            // ALERT ANY ERRORS THAT OCCUR DURING PROCESSING
            alertError(error);
        } finally {
            // CLOSE MODAL
            setLoading(false);
        }
    };

    // HANDLE ADD CAMPAIGN FILTER
    const onAddCampaignFilter = (filter: CampaignFilter) => {
        dispatch({
            type: ADD_GOAL_FORM_TYPES.ADD_CAMPAIGN_FILTER,
            payload: {
                filter,
                campaignOptions,
            },
        });
    };

    // HANDLE REMOVE CAMPAIGN FILTER
    const onRemoveCampaignFilter = (filter: CampaignFilter) => {
        dispatch({
            type: ADD_GOAL_FORM_TYPES.REMOVE_CAMPAIGN_FILTER,
            payload: {
                filter,
                campaignOptions,
            },
        });
    };

    // HANDLE FORM SUBMIT TO ADD NEW GOAL
    const onAddNewGoal = async (evt: React.FormEvent) => {
        evt.preventDefault();
        if (!user) return;

        // OPEN LOADING MODAL
        setLoading(true);

        try {
            // SUBMIT ADD GOAL REQUEST
            const data = await addGoalMapping(formState);

            if (!data) {
                alertError(
                    'Unable to add goal.  Please make sure all required fields contain a value.'
                );
                return;
            }

            if (data.result === 'success') {
                alertSuccess(data.message);

                // RESET FORM VALUES
                dispatch({
                    type: ADD_GOAL_FORM_TYPES.RESET_ADD_GOAL_FORM,
                });

                // LOAD UPDATED GOALS LIST
                loadGoalsList(user);
            } else {
                alertError(data.message || DEFAULT_API_ERROR);
            }
        } catch (error: any) {
            alert(error.response?.data?.errorMessage || DEFAULT_API_ERROR);
        } finally {
            // CLOSE LOADING MODAL
            setLoading(false);
        }
    };

    return (
        <form onSubmit={onAddNewGoal} id="add-goal-form">
            <div className="add-goal-form-row row-items-3">
                {/* --- GOAL NAME --- */}
                <Textbox
                    value={formState.name}
                    onChange={onGoalNameChange}
                    required
                >
                    Goal Name
                </Textbox>

                {/* --- GOAL AGENCY --- */}
                <Dropdown
                    options={
                        agencies.map((agency) => ({
                            label: agency.agencyName,
                            value: agency.agencyId,
                        })) || []
                    }
                    onChange={onSelectAgency}
                    value={
                        formState.agency
                            ? {
                                  label: '',
                                  value: formState.agency.agencyId,
                              }
                            : ''
                    }
                    defaultValue={
                        formState.agency
                            ? {
                                  label: '',
                                  value: formState.agency.agencyId,
                              }
                            : ''
                    }
                    required
                >
                    Agency
                </Dropdown>

                {/* --- GOAL ADVERTISER --- */}
                <Dropdown
                    options={
                        formState?.agency?.advertisers?.map((advertiser) => ({
                            label: advertiser.advertiserName,
                            value: advertiser.advertiserId,
                        })) || []
                    }
                    onChange={onSelectAdvertiser}
                    value={
                        formState.advertiser
                            ? {
                                  label: '',
                                  value: formState.advertiser.advertiserId,
                              }
                            : ''
                    }
                    defaultValue={
                        formState.advertiser
                            ? {
                                  label: '',
                                  value: formState.advertiser.advertiserId,
                              }
                            : ''
                    }
                    required
                >
                    Advertiser
                </Dropdown>
            </div>
            <div className="add-goal-form-row row-items-3">
                {/* --- GOAL ENGINES --- */}
                <Dropdown
                    options={[SELECT_ALL_OPTION, ...engineOptions]}
                    onChange={onSelectEngine}
                    display="selectionInline"
                    value={formState.selectedEngines}
                    defaultValue={formState.selectedEngines}
                    multiple
                    required
                >
                    Engine Account(s)
                </Dropdown>

                {/* --- GOAL REPORT TYPE --- */}
                <Dropdown
                    options={
                        formState.agency?.reportType.map((report) => {
                            return {
                                label: report,
                                value: report,
                            };
                        }) || []
                    }
                    onChange={onReportTypeSelection}
                    value={
                        formState.reportType
                            ? {
                                  label: '',
                                  value: formState.reportType,
                              }
                            : ''
                    }
                    defaultValue={
                        formState.reportType
                            ? {
                                  label: '',
                                  value: formState.reportType,
                              }
                            : ''
                    }
                    required
                >
                    Report Type
                </Dropdown>

                {/* --- SYNC GOAL QA & PRODUCTION --- */}
                <Checkbox
                    className="sync-goal-input"
                    onChange={onSyncGoalCheck}
                    value={formState.syncGoal}
                >
                    Sync the goal between QA & Production
                </Checkbox>
            </div>

            {/* --- CAMPAIGN SELECTIONS --- */}
            <div className="add-goal-form-row campaigns-selections">
                <TabGroup
                    className="campaigns-selections-tabs"
                    tabs={[
                        {
                            label: 'Select/Upload Campaigns',
                            disabled: false,
                            panelId: 'select-upload-campaigns-tab',
                        },
                        {
                            label: 'Select Campaigns by Filtering',
                            disabled: false,
                            panelId: 'match-filters-campaigns-tab',
                        },
                    ]}
                    onSelect={(index: number) => {
                        dispatch({
                            type: ADD_GOAL_FORM_TYPES.SET_USE_FILTER,
                            payload: !!index,
                        });
                    }}
                    invert
                >
                    {/* --- SELECT/UPLOAD CAMPAIGNS --- */}
                    <div id="select-upload-campaigns-tab" role="tabpanel">
                        <SelectCampaigns
                            campaignOptions={campaignOptions}
                            onSelectCampaigns={onSelectCampaigns}
                            selectedCampaigns={formState.selectedCampaigns}
                            disabled={
                                !formState.agency ||
                                !formState.advertiser ||
                                formState.selectedEngines.length < 1
                            }
                            isIntraday={formState.isIntraday}
                            onSelectAutoAdd360={onSelectAutoAdd360}
                            autoAdd360={formState.autoAdd360}
                        />
                    </div>
                    <div
                        id="match-filters-campaigns-tab"
                        role="tabpanel"
                        hidden
                    >
                        <FilterCampaigns
                            filters={formState.campaignFilters}
                            campaignOptions={campaignOptions}
                            selectedCampaigns={formState.selectedCampaigns}
                            onAddFilter={onAddCampaignFilter}
                            onRemoveFilter={onRemoveCampaignFilter}
                        />
                    </div>
                </TabGroup>
            </div>
            <div className="add-goal-form-row row-items-4">
                {/* --- MIN BID --- */}
                <Textbox
                    type="number"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        dispatch({
                            type: ADD_GOAL_FORM_TYPES.SET_MIN_BID,
                            payload: evt.target.value,
                        });
                    }}
                    value={formState.minBid}
                    min="0"
                >
                    Keyword Minimum Bid:
                </Textbox>

                {/* --- MAX BID --- */}
                <Textbox
                    type="number"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        dispatch({
                            type: ADD_GOAL_FORM_TYPES.SET_MAX_BID,
                            payload: evt.target.value,
                        });
                    }}
                    value={formState.maxBid}
                    min="0"
                >
                    Keyword Maximum Bid:
                </Textbox>

                {/* --- MIN/MAX DAILY BUDGET (DISPLAY IF INTRADAY === FALSE) --- */}
                {!formState.isIntraday && (
                    <>
                        {/* --- MIN DAILY BUDGET --- */}
                        <Textbox
                            type="number"
                            onChange={(
                                evt: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                dispatch({
                                    type: ADD_GOAL_FORM_TYPES.SET_MIN_DAILY_BUDGET,
                                    payload: evt.target.value,
                                });
                            }}
                            value={formState.minDailyBudget}
                            min="0"
                        >
                            Min Daily Budget:
                        </Textbox>

                        {/* --- MAX DAILY BUDGET --- */}
                        <Textbox
                            type="number"
                            onChange={(
                                evt: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                dispatch({
                                    type: ADD_GOAL_FORM_TYPES.SET_MAX_DAILY_BUDGET,
                                    payload: evt.target.value,
                                });
                            }}
                            value={formState.maxDailyBudget}
                            min="0"
                        >
                            Max Daily Budget:
                        </Textbox>
                    </>
                )}
            </div>
            <div className="add-goal-form-row upload-excluded-inputs">
                {/* --- UPLOAD EXCLUDED CAMPAIGNS --- */}
                <UploadExcluded
                    type="Campaign"
                    onChange={onUploadExcludedCampaigns}
                    options={formState.excludedCampaigns}
                />
                <UploadExcluded
                    type="Keyword"
                    onChange={onUploadExcludedKeywords}
                    options={formState.excludedKeywords}
                />
            </div>
            {/* --- SUBMIT NEW GOAL --- */}
            <div className="submit-button-container">
                <Button type="submit" disabled={isSubmitDisabled}>
                    Add Goal
                </Button>
            </div>
        </form>
    );
}

export default AddGoalForm;
