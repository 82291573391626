import { DropdownOption, ReportType } from '../../models';
import { CampaignFilter } from './filter-campaings-model';
import {
    AdvertiserDetails,
    EngineDetails,
} from '../../models/bid-headroom-scan-models';

export enum EDIT_GOAL_FORM_TYPES {
    SET_NAME = 'update_name',
    SET_ADVERTISER = 'set_advertiser',
    ADD_SELECTED_ENGINE = 'add_selected_engine',
    REMOVE_SELECTED_ENGINE = 'remove_selected_engine',
    SET_SYNC_GOAL = 'set_sync_goal',
    ADD_SELECTED_CAMPAIGN = 'add_selected_campaign',
    REMOVE_SELECTED_CAMPAIGN = 'remove_selected_campaign',
    UPLOAD_CAMPAIGN_IDS = 'upload_campaign_ids',
    SET_AUTO_ADD_360 = 'set_auto_add_360',
    SET_MIN_BID = 'set_min_bid',
    SET_MAX_BID = 'set_max_bid',
    SET_MIN_DAILY_BUDGET = 'set_min_daily_budget',
    SET_MAX_DAILY_BUDGET = 'set_max_daily_budget',
    UPLOAD_EXCLUDED_CAMPAIGNS = 'upload_excluded_campaigns_file',
    UPLOAD_EXCLUDED_KEYWORDS = 'upload_excluded_keywords_file',
    ADD_CAMPAIGN_FILTER = 'add_campaign_filter',
    REMOVE_CAMPAIGN_FILTER = 'remove_campaign_filter',
    SET_IS_INTRADAY = 'set_is_intraday',
    RESET_EDIT_GOAL_FORM = 'reset_edit_goal_form',
}

export interface EditGoalForm {
    goalId: string;
    name: string;
    advertiser: AdvertiserDetails | null;
    agencyId: string;
    reportType: ReportType | null;
    allEngines: { [key: string]: EngineDetails };
    selectedEngines: DropdownOption[];
    syncGoal: boolean;
    selectedCampaigns: DropdownOption[];
    autoAdd360: boolean;
    minBid: string;
    maxBid: string;
    useFilter: boolean;
    minDailyBudget: string;
    maxDailyBudget: string;
    excludedCampaigns: DropdownOption[];
    excludedKeywords: DropdownOption[];
    isIntraday: boolean;
    campaignFilters: CampaignFilter[];
}

export const INITIAL_EDIT_GOAL_FORM: EditGoalForm = {
    goalId: '',
    name: '',
    advertiser: null,
    agencyId: '',
    reportType: null,
    allEngines: {},
    selectedEngines: [],
    syncGoal: false,
    selectedCampaigns: [],
    autoAdd360: false,
    minBid: '',
    maxBid: '',
    useFilter: false,
    minDailyBudget: '',
    maxDailyBudget: '',
    excludedCampaigns: [],
    excludedKeywords: [],
    isIntraday: false,
    campaignFilters: [],
};

interface SetGoalNameAction {
    type: EDIT_GOAL_FORM_TYPES.SET_NAME;
    payload: string;
}

interface SetAdvertiserAction {
    type: EDIT_GOAL_FORM_TYPES.SET_ADVERTISER;
    payload: AdvertiserDetails | null;
}

interface AddSelectedEngineAction {
    type: EDIT_GOAL_FORM_TYPES.ADD_SELECTED_ENGINE;
    payload: DropdownOption;
}

interface RemoveSelectedEngineAction {
    type: EDIT_GOAL_FORM_TYPES.REMOVE_SELECTED_ENGINE;
    payload: DropdownOption;
}

interface SetSyncGoalAction {
    type: EDIT_GOAL_FORM_TYPES.SET_SYNC_GOAL;
    payload: boolean;
}

interface AddSelectedCampaignAction {
    type: EDIT_GOAL_FORM_TYPES.ADD_SELECTED_CAMPAIGN;
    payload: DropdownOption;
}

interface RemoveSelectedCampaignAction {
    type: EDIT_GOAL_FORM_TYPES.REMOVE_SELECTED_CAMPAIGN;
    payload: DropdownOption;
}

interface UploadCampaignIdsAction {
    type: EDIT_GOAL_FORM_TYPES.UPLOAD_CAMPAIGN_IDS;
    payload: DropdownOption[];
}

interface SetAutoAdd360Action {
    type: EDIT_GOAL_FORM_TYPES.SET_AUTO_ADD_360;
    payload: boolean;
}

interface SetMinBidAction {
    type: EDIT_GOAL_FORM_TYPES.SET_MIN_BID;
    payload: string;
}

interface SetMaxBidAction {
    type: EDIT_GOAL_FORM_TYPES.SET_MAX_BID;
    payload: string;
}

interface SetMinDailyBudgetAction {
    type: EDIT_GOAL_FORM_TYPES.SET_MIN_DAILY_BUDGET;
    payload: string;
}

interface SetMaxDailyBudgetAction {
    type: EDIT_GOAL_FORM_TYPES.SET_MAX_DAILY_BUDGET;
    payload: string;
}

interface UploadExcludedCampaignsAction {
    type: EDIT_GOAL_FORM_TYPES.UPLOAD_EXCLUDED_CAMPAIGNS;
    payload: DropdownOption[];
}

interface UploadExcludedKeywordsAction {
    type: EDIT_GOAL_FORM_TYPES.UPLOAD_EXCLUDED_KEYWORDS;
    payload: DropdownOption[];
}

interface SetIsIntradayAction {
    type: EDIT_GOAL_FORM_TYPES.SET_IS_INTRADAY;
    payload: boolean;
}

interface AddCampaignFilterAction {
    type: EDIT_GOAL_FORM_TYPES.ADD_CAMPAIGN_FILTER;
    payload: {
        filter: CampaignFilter;
        campaignOptions: DropdownOption[];
    };
}

interface RemoveCampaignFilterAction {
    type: EDIT_GOAL_FORM_TYPES.REMOVE_CAMPAIGN_FILTER;
    payload: {
        filter: CampaignFilter;
        campaignOptions: DropdownOption[];
    };
}

interface ResetAddGoalFormAction {
    type: EDIT_GOAL_FORM_TYPES.RESET_EDIT_GOAL_FORM;
}

export type EditGoalFormAction =
    | SetGoalNameAction
    | SetAdvertiserAction
    | AddSelectedEngineAction
    | RemoveSelectedEngineAction
    | SetSyncGoalAction
    | AddSelectedCampaignAction
    | RemoveSelectedCampaignAction
    | UploadCampaignIdsAction
    | SetAutoAdd360Action
    | SetMinBidAction
    | SetMaxBidAction
    | SetMinDailyBudgetAction
    | SetMaxDailyBudgetAction
    | UploadExcludedCampaignsAction
    | UploadExcludedKeywordsAction
    | SetIsIntradayAction
    | AddCampaignFilterAction
    | RemoveCampaignFilterAction
    | ResetAddGoalFormAction;
