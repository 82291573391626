import { axios } from '../api';
import { useAppSelector } from '../hooks';
import { useLocation } from 'react-router-dom';
import { PropsWithChildren, useEffect, useRef } from 'react';

// TYPES
import { POSTUserActivity } from '../state';

// CONSTANTS
import { API_USER_ACTIVITY } from '../constants';

// FUNCTIONAL COMPONENT (USER PAGE VIEW TRACKER)
function PageTracker({ children }: PropsWithChildren) {
    // USER DETAILS
    const email = useAppSelector(({ auth }) => auth.user?.email) || '';
    const userId = useAppSelector(({ auth }) => auth.user?.id);

    // CURRENT PATHNAME
    const { pathname } = useLocation();

    // STORE PREVIOUS PATHNAME
    const referPageRef = useRef<string>('');

    useEffect(() => {
        if (!userId) {
            return;
        }

        const body: POSTUserActivity = {
            email,
            userId,
            pathname,
            referPage: referPageRef.current,
            loggedTime: new Date().toISOString(),
            page: '/' + pathname.split('/').pop(),
            url: window.location.origin + pathname,
        };

        // SEND USER ACTIVITY DATA
        axios.post(API_USER_ACTIVITY, body);

        // UPDATE REFER PAGE TO CURRENT PAGE
        referPageRef.current = pathname;
    }, [pathname, email, userId]);
    return <>{children}</>;
}

export default PageTracker;
