import './Performance-Monitor.scss';

import { useAppSelector } from '../../../hooks';

// UI COMPONENTS
import DpadData from './Dpad-Data/Dpad-Data';
import AnomalyData from './Anomaly-Data/Anomaly-Data';
import Product from '../../../components/Product/Product';

// FUNCTIONAL COMPONENT
function PerformanceMonitor() {
    const { data } = useAppSelector(
        ({ products }) => products.performanceMonitor
    );

    return (
        <>
            {/* PERFORMANCE MONITOR SETTINGS */}
            <Product>
                {data ? (
                    <div className="performance-monitor-content">
                        {data?.dpad && <DpadData data={data.dpad} />}
                        {data?.anomaly && (
                            <AnomalyData
                                data={data.anomaly}
                                efficiencyColumnName={
                                    data?.dpad?.['Efficiency Column Name'] || ''
                                }
                            />
                        )}
                    </div>
                ) : null}
            </Product>
        </>
    );
}

export default PerformanceMonitor;
