import { PLATFORM_IDS } from '../../../constants';
import { KenshooProfileData } from './account-onboarding-form';

export interface KenshooFormState {
    platformId: PLATFORM_IDS.KENSHOO;
    accountName: string;
    ksId: string;
    keywordReportName: string;
    keywordReportId: string;
    campaignReportName: string;
    campaignReportId: string;
    adgroupReportName: string;
    adgroupReportId: string;
    profileData: KenshooProfileData[];
}

export const INITIAL_KENSHOO_PROFILE: KenshooProfileData = {
    profileId: '',
    profileName: '',
};

export const INITIAL_KENSHOO_FORM: KenshooFormState = {
    platformId: PLATFORM_IDS.KENSHOO,
    accountName: '',
    ksId: '',
    keywordReportId: '',
    keywordReportName: '',
    campaignReportId: '',
    campaignReportName: '',
    adgroupReportId: '',
    adgroupReportName: '',
    profileData: [{ ...INITIAL_KENSHOO_PROFILE }],
};

export enum KENSHOO_ACTION_TYPES {
    SET_ACCOUNT_NAME = 'set_account_name',
    SET_KS_ID = 'set_ks_id',
    SET_KEYWORD_REPORT_ID = 'set_keyword_report_id',
    SET_KEYWORD_REPORT_NAME = 'set_keyword_report_name',
    SET_CAMPAIGN_REPORT_ID = 'set_campaign_report_id',
    SET_CAMPAIGN_REPORT_NAME = 'set_campaign_report_name',
    SET_ADGROUP_REPORT_ID = 'set_adgroup_report_id',
    SET_ADGROUP_REPORT_NAME = 'set_adgroup_report_name',
    ADD_PROFILE = 'add_profile_data',
    SET_PROFILE_ID = 'set_profile_id',
    SET_PROFILE_NAME = 'set_profile_name',
    REMOVE_PROFILE = 'remove_profile',
    RESET_KENSHOO_FORM = 'reset_kenshoo_form',
}

interface SetAccountNameAction {
    type: KENSHOO_ACTION_TYPES.SET_ACCOUNT_NAME;
    payload: string;
}

interface SetKsIdAction {
    type: KENSHOO_ACTION_TYPES.SET_KS_ID;
    payload: string;
}

interface SetKeywordReportIdAction {
    type: KENSHOO_ACTION_TYPES.SET_KEYWORD_REPORT_ID;
    payload: string;
}

interface SetKeywordReportNameAction {
    type: KENSHOO_ACTION_TYPES.SET_KEYWORD_REPORT_NAME;
    payload: string;
}

interface SetCampaignReportIdAction {
    type: KENSHOO_ACTION_TYPES.SET_CAMPAIGN_REPORT_ID;
    payload: string;
}

interface SetCampaignReportNameAction {
    type: KENSHOO_ACTION_TYPES.SET_CAMPAIGN_REPORT_NAME;
    payload: string;
}

interface SetAdgroupReportIdAction {
    type: KENSHOO_ACTION_TYPES.SET_ADGROUP_REPORT_ID;
    payload: string;
}

interface SetAdgroupReportNameAction {
    type: KENSHOO_ACTION_TYPES.SET_ADGROUP_REPORT_NAME;
    payload: string;
}

interface AddProfileAction {
    type: KENSHOO_ACTION_TYPES.ADD_PROFILE;
}

interface SetProfileIdAction {
    type: KENSHOO_ACTION_TYPES.SET_PROFILE_ID;
    payload: { index: number; value: string };
}

interface SetProfileNameAction {
    type: KENSHOO_ACTION_TYPES.SET_PROFILE_NAME;
    payload: { index: number; value: string };
}

interface RemoveProfileAction {
    type: KENSHOO_ACTION_TYPES.REMOVE_PROFILE;
    payload: number;
}

interface ResetKenshooFormAction {
    type: KENSHOO_ACTION_TYPES.RESET_KENSHOO_FORM;
}

export type KenshooFormAction =
    | SetAccountNameAction
    | SetKsIdAction
    | SetKeywordReportIdAction
    | SetKeywordReportNameAction
    | SetCampaignReportIdAction
    | SetCampaignReportNameAction
    | SetAdgroupReportIdAction
    | SetAdgroupReportNameAction
    | AddProfileAction
    | SetProfileIdAction
    | SetProfileNameAction
    | RemoveProfileAction
    | ResetKenshooFormAction;
