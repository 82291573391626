import { useAppSelector } from '../../hooks';
import React, { useMemo, useState } from 'react';

// TYPES
import {
    UserDetails,
    DropdownOption,
    UserActivityFormState,
} from '../../state';

// CONSTANTS
import { SELECT_ALL } from '../../constants';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import DatePicker from '@publicismedia-ds/ui-datepicker';

// UTILS
import { findDeselectedOption, onPreventEnterKeySubmit } from '../../utils';

// COMPONENT PROPS
interface UserActivityFormProps {
    users: UserDetails[];
    onSubmit: (formState: UserActivityFormState) => Promise<void>;
}

// "FROM" DATE MAX OF 6 MONTHS
const minFromDate = new Date();
minFromDate.setMonth(minFromDate.getMonth() - 6);

// MAX "TO" DATE OF YESTERDAY
const maxToDate = new Date();
maxToDate.setDate(maxToDate.getDate() - 1);

// DEFAULT FORM STATE
const DEFAULT_STATE: UserActivityFormState = {
    users: [],
    goals: [],
    fromDate: null,
    toDate: maxToDate,
};

// FUNCTIONAL COMPONENT (USER ACTIVITY FORM)
function UserActivityForm({ users, onSubmit }: UserActivityFormProps) {
    const [state, setState] = useState<UserActivityFormState>(DEFAULT_STATE);

    // REDUX STATE
    const goalList = useAppSelector((state) => state.goals.list);

    // CREATE USER LIST DROPDOWN OPTIONS
    const userOptions = useMemo((): DropdownOption[] => {
        // CREATE DROPDOWN OPTIONS
        return (
            users
                .map(({ name, role, id }) => ({
                    label: `${name} (${role})`,
                    value: id.toString(),
                }))
                // SORT DROPDOWN OPTIONS ASCENDING ORDER
                .sort((a, b) => {
                    const userA = a.label.toLowerCase();
                    const userB = b.label.toLowerCase();

                    if (userA < userB) {
                        return -1;
                    }

                    if (userA > userB) {
                        return 1;
                    }

                    return 0;
                })
        );
    }, [users]);

    // CREATE GOAL LIST OPTIONS
    const goalOptions = useMemo((): DropdownOption[] => {
        return goalList.map((goal) => ({
            label: goal.goalMappingName,
            value: goal.goalId,
        }));
    }, [goalList]);

    // HANDLE USER SELECTION
    const onSetUsers = (selections: DropdownOption[]) => {
        let userSelections = [...selections];

        const numSelected = userSelections.length;
        // IF ADDITIONAL ITEM WAS SELECTED
        if (numSelected > state.users.length) {
            const selected = selections[numSelected - 1];

            // CHECK IF SELECTED ITEM IS "SELECT ALL"
            if (selected.value === SELECT_ALL.value) {
                // SET SELECTIONS TO ALL USER OPTIONS
                userSelections = [SELECT_ALL, ...userOptions];
            }
        } else {
            const unSelected = findDeselectedOption(selections, state.users);

            // IF "SELECT ALL" WAS UNSELECTED CLEAR ALL SELECTED USERS
            if (unSelected?.value === SELECT_ALL.value) {
                userSelections = [];
            }
        }
        setState((prev) => ({ ...prev, users: [...userSelections] }));
    };

    // HANDLE GOAL SELECTION
    const onSetGoals = (selections: DropdownOption[]) => {
        let goalSelections = [...selections];

        const numSelected = goalSelections.length;

        // IF ADDITIONAL ITEM WAS SELECTED
        if (numSelected > state.goals.length) {
            // CHECK IF SELECTED ITEM IS "SELECT ALL"
            if (selections[numSelected - 1].value === SELECT_ALL.value) {
                // SET SELECTIONS TO ALL USER OPTIONS
                goalSelections = [SELECT_ALL, ...goalOptions];
            }
        } else {
            const unSelected = findDeselectedOption(selections, state.goals);

            // IF "SELECT ALL" WAS UNSELECTED CLEAR ALL SELECTED USERS
            if (unSelected?.value === SELECT_ALL.value) {
                goalSelections = [];
            }
        }

        setState((prev) => ({ ...prev, goals: [...goalSelections] }));
    };

    // HANDLE "FROM" DATE SELECTION
    const onSetFromDate = (date: Date) => {
        const isSameDay = date.getTime() === state.fromDate?.getTime();
        setState((prev) => ({ ...prev, fromDate: isSameDay ? null : date }));
    };

    // HANDLE "TO" DATE SELECTION
    const onSetToDate = (date: Date) => {
        setState((prev) => ({ ...prev, toDate: date }));
    };

    // HANDLE FORM SUBMISSION
    const onFormSubmit = (evt: React.FormEvent) => {
        evt.preventDefault();
        onSubmit(state);
    };

    // ENABLE/DISABLE SUBMIT BUTTON
    const isSubmitDisabled =
        !state.users.length ||
        !state.goals.length ||
        !state.fromDate ||
        !state.toDate;

    return (
        <Column>
            <div className="user-activity-form">
                <form
                    onSubmit={onFormSubmit}
                    onKeyDown={onPreventEnterKeySubmit}
                >
                    <Row>
                        <Column>
                            <Dropdown
                                options={[SELECT_ALL, ...userOptions]}
                                value={state.users.length ? state.users : ''}
                                placeholder="Select users"
                                onChange={onSetUsers}
                                display="selectionInline"
                                error="Please select at least one user."
                                multiple
                                required
                            >
                                User:
                            </Dropdown>
                        </Column>
                        <Column>
                            <Dropdown
                                options={[SELECT_ALL, ...goalOptions]}
                                placeholder="Select goals"
                                value={state.goals.length ? state.goals : ''}
                                onChange={onSetGoals}
                                display="selectionInline"
                                error="Please select a goal"
                                multiple
                                required
                            >
                                Goal:
                            </Dropdown>
                        </Column>
                        <Column>
                            <DatePicker
                                allowSameDay={true}
                                value={state.fromDate || ''}
                                onChange={onSetFromDate}
                                minDate={minFromDate}
                                maxDate={
                                    state.toDate && state.toDate < maxToDate
                                        ? state.toDate
                                        : maxToDate
                                }
                                required
                            >
                                From:
                            </DatePicker>
                        </Column>
                        <Column>
                            <DatePicker
                                value={state.toDate || ''}
                                defaultValue={state.toDate || ''}
                                onChange={onSetToDate}
                                minDate={
                                    state.fromDate &&
                                    state.fromDate > minFromDate
                                        ? state.fromDate
                                        : minFromDate
                                }
                                error="Please select a date"
                                maxDate={maxToDate}
                                required
                            >
                                To:
                            </DatePicker>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: '2rem',
                                }}
                            >
                                <Button
                                    type="submit"
                                    disabled={isSubmitDisabled}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Column>
                    </Row>
                </form>
            </div>
        </Column>
    );
}

export default UserActivityForm;
