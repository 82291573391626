import { PlatformId } from '../../constants';

export interface FeatureData {
    feature_id: number;
    feature_name: string;
    is_active: 1 | 0;
    is_read_only: boolean;
}

export const USER_ROLE_NAMES = ['Admin', 'Manger', 'Read Only'] as const;
export const USER_ROLE_IDS = [1, 2, 4] as const;
export const USER_ROLE_WEIGHTS = [1, 2, 4] as const;

export type UserRoleName = typeof USER_ROLE_NAMES[number];
export type UserRoleId = typeof USER_ROLE_IDS[number];
export type UserRoleWeight = typeof USER_ROLE_WEIGHTS[number];

export const ACCOUNT_REPORT_LIST = [
    'Campaign',
    'Keyword',
    'Product Group',
    'DSA Campaign',
    'DSA Target',
] as const;

export type AccountReportType = typeof ACCOUNT_REPORT_LIST[number];

export interface UserRoleData {
    roleId: UserRoleId;
    roleName: UserRoleName;
    roleWeight: UserRoleWeight;
    createdAt: string; // date => 'Mar 6, 2018, 7:19:06 PM';
    updatedAt: string; // date =>'Mar 6, 2018, 7:19:08 PM';
}

export interface AccountBrand {
    accountId: number; // => 7;
    createdAt: string; // date => 'Mar 11, 2020, 12:56:00 PM';
    brandName: string; // => 'Vonage Business';
    brandId: number; // => 14;
    brandStatus: number; // => 1;
    advertiserName: string; // => 'Vonage Business';
    updatedAt: string; // date => 'May 6, 2022, 7:17:57 AM';
    advertiserId: string; // int => '21700000001674735';
}

export interface ExistingAccount {
    reportType: AccountReportType[]; // => ['Keyword', 'Campaign'];
    account_id: 7;
    brands: AccountBrand[];
    account_name: 'Performics - Vonage';
    agency_id: '20700000001288015';
    platform_id: 1;
}

export interface GETRolesAccountBrands {
    features: FeatureData[];
    roles: UserRoleData[];
    accounts: ExistingAccount[];
    listOfReports: AccountReportType[];
}

export interface BrandPermission {
    brandId: number; // => 9;
    brandName: string; // => 'Search - HPInc_IPG_Supplies_AMS_CA';
}

export interface AccountPermission {
    accountId: number; // => 9;
    accountName: string; // => 'HP Inc Canada';
    brands: BrandPermission[];
}

export interface PermittedFeature {
    feature_id: number;
    feature_name: string;
}

export interface UserDetails {
    id: number; // => 5;
    name: string; // => 'test test';
    email: string; // => 'test.test@performics.com';
    roleId: UserRoleId;
    role: UserRoleName;
    createdOn: string; // date => '2018-06-20 14:20:48';
    lastLogin?: string; // date =>  "2022-11-03 23:16:34"
    isActive: 1 | 0;
    features: PermittedFeature[];
    permissions: AccountPermission[];
}

export interface GETAllUsers {
    data: UserDetails[];
}

export interface POSTUserResponse {
    result: 'success' | 'failure';
    message: string;
}

export interface POSTAddUserDetails {
    email: string;
    llEmail: string;
    fname: string;
    lname: string;
    roleId: UserRoleId | string;
}

export interface POSTUserPermissions {
    [key: string]: string[]; // {accountId: [...brandIds]}
}

export interface POSTAddUserBody {
    userId: string;
    userDetails: POSTAddUserDetails;
    userPermissions: POSTUserPermissions;
    platformId: string;
    featurePermissions: string; // csv of feature ids;
}

export interface POSTEditUserDetails extends POSTAddUserDetails {
    pwd: string;
    confirmPassword: string;
    action: string; // 0 | 1 | 2 | 3
}

export interface POSTEditUserBody {
    userId: string;
    userDetails: string;
    userPermissionsAdded: string;
    featurePermissionsAdded: string;
    pwd: string;
    confirmPassword: string;
    action: string; // 0 | 1 | 2 | 3
}
