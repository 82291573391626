import { Outlet, Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks';

function SuperAdminRoutes() {
    const { user } = useAppSelector(({ auth }) => auth);

    // USER MUST HAVE ROLE ID OF 1 ACCESS ROUTES
    const isSuperAdmin = user?.roleId === '1';

    return isSuperAdmin ? <Outlet /> : <Navigate to="/" />;
}

export default SuperAdminRoutes;
