import {
  BidDataFetchingStatus,
  ConversionType,
  DropdownOption,
} from '../../models';

// ACTIONS
import {
  SetBidAgencyAction,
  ResetBidStateAction,
  SetBidScanDataAction,
  SetBidAdvertiserAction,
  SetBidRowsPerPageAction,
  SetBidCurrentPageAction,
  SetBidIsValidColumnAction,
  SetBidRevenueColumnAction,
  SetBidConversionTypeAction,
  SetBidConverionColumnAction,
  SetBidDataFetchingStatusAction,
  BID_HEADROOM_ACTION_TYPES,
} from '../form-models';

// SET ADVERTISER SELECTION
export const setBidAdvertiser = (
  selected: DropdownOption
): SetBidAdvertiserAction => {
  return {
    type: BID_HEADROOM_ACTION_TYPES.SET_ADVERTISER,
    payload: selected,
  };
};

// SET AGENCY SELECTION
export const setBidAgency = (selected: DropdownOption): SetBidAgencyAction => {
  return {
    type: BID_HEADROOM_ACTION_TYPES.SET_AGENCY,
    payload: selected,
  };
};

// SET CONVERSION TYPE SELECTION
export const setBidConverionType = (
  conversionType: ConversionType
): SetBidConversionTypeAction => {
  return {
    type: BID_HEADROOM_ACTION_TYPES.SET_CONVERSION_TYPE,
    payload: conversionType,
  };
};

// SET CONVERSION COLUMN SELECTION
export const setBidConvserionColumn = (
  selected: DropdownOption
): SetBidConverionColumnAction => {
  return {
    type: BID_HEADROOM_ACTION_TYPES.SET_CONVERSION_COLUMN,
    payload: selected,
  };
};

// SET REVENUE COLUMN SELECTION
export const setBidRevenueColumn = (
  selected: DropdownOption
): SetBidRevenueColumnAction => {
  return {
    type: BID_HEADROOM_ACTION_TYPES.SET_REVENUE_COLUMN,
    payload: selected,
  };
};

// SET TABLE'S CURRENT PAGE
export const setBidCurrentPage = (pageNum: number): SetBidCurrentPageAction => {
  return {
    type: BID_HEADROOM_ACTION_TYPES.SET_CURRENT_PAGE,
    payload: pageNum,
  };
};

// SET TABLE'S ROWS PER PAGE
export const setRowsPerPage = (
  rowsPerPage: number
): SetBidRowsPerPageAction => {
  return {
    type: BID_HEADROOM_ACTION_TYPES.SET_ROWS_PER_PAGE,
    payload: rowsPerPage,
  };
};

// SET COLUMN VERIFICATION STATUS
export const setBidIsValidColumn = (
  isValid: boolean
): SetBidIsValidColumnAction => {
  return {
    type: BID_HEADROOM_ACTION_TYPES.SET_IS_VALID_COLUMN,
    payload: isValid,
  };
};

// SET SCAN BID HEADROOM DATA
export const setBidScanData = (data: any): SetBidScanDataAction => {
  return {
    type: BID_HEADROOM_ACTION_TYPES.SET_SCAN_DATA,
    payload: data,
  };
};

// SET FETCHING PAST 2 MONTHS DATA FLAG
export const setBidDataFetchingStatus = (
  agencyId: string,
  advertiserId: string,
  status: BidDataFetchingStatus
): SetBidDataFetchingStatusAction => {
  return {
    type: BID_HEADROOM_ACTION_TYPES.SET_DATA_FETCHING_STATUS,
    payload: { agencyId, advertiserId, status },
  };
};

// RESET FORM STATE
export const resetBidState = (): ResetBidStateAction => {
  return {
    type: BID_HEADROOM_ACTION_TYPES.RESET_STATE,
  };
};
