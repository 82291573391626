import produce from 'immer';
import {
  EditNotesState,
  EditNotesAction,
  DEFAULT_NEW_NOTE,
  EDIT_NOTE_ACTION_TYPES,
} from '../form-models';
import { ENVIRONMENT } from '../../../constants';

export const DEFAULT_EDIT_NOTES_STATE: EditNotesState = {
  advertiserId: '',
  agencyId: '',
  environment: ENVIRONMENT,
  notesData: [],
};

export const editNotesReducer = produce(
  (state: EditNotesState, action: EditNotesAction) => {
    switch (action.type) {
      case EDIT_NOTE_ACTION_TYPES.LOAD_NOTES_DATA: {
        state.advertiserId = action.payload.advertiserId;
        state.agencyId = action.payload.agencyId;
        state.environment = ENVIRONMENT;
        state.notesData = action.payload.notesData.map((note) => {
          // ADD IS_NEW & DELETE_FLAG PROPERTIES TO NOTE DATA
          return { ...note, isNew: false, deleteFlag: false };
        });
        return state;
      }

      case EDIT_NOTE_ACTION_TYPES.SET_NOTE_TEXT: {
        const { index, text } = action.payload;
        state.notesData[index].noteText = text;
        return state;
      }

      case EDIT_NOTE_ACTION_TYPES.SET_STRIKE_FLAG: {
        const { index, isChecked } = action.payload;
        state.notesData[index].isStrikeThrough = isChecked;
        return state;
      }

      case EDIT_NOTE_ACTION_TYPES.SET_DELETE_FLAG: {
        const { index, isChecked } = action.payload;
        state.notesData[index].deleteFlag = isChecked;
        return state;
      }

      case EDIT_NOTE_ACTION_TYPES.ADD_NEW_NOTE: {
        const user = action.payload;
        const newNote = { ...DEFAULT_NEW_NOTE };
        newNote.createdByUserId = parseInt(user.id);
        newNote.createdByUserName = user.name;
        newNote.updatedByUserId = parseInt(user.id);
        newNote.updatedByUserName = user.name;

        state.notesData.push(newNote);
        return state;
      }

      case EDIT_NOTE_ACTION_TYPES.REMOVE_NEW_NOTE:
        state.notesData = state.notesData.filter((note, index) => {
          return index !== action.payload;
        });
        return state;

      case EDIT_NOTE_ACTION_TYPES.RESET_NOTES:
        state = { ...DEFAULT_EDIT_NOTES_STATE };
        return state;

      default:
        return state;
    }
  },
  DEFAULT_EDIT_NOTES_STATE
);
