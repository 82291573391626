export const CRON_PLATFORM_OPTIONS = [
    { label: 'Search Ads 360', value: 1 },
    { label: 'Google Adwords', value: 2 },
    { label: 'Marin', value: 5 },
] as const;

export enum ADWORDS_TEMPLATES {
    CAMPAIGN = 'AdwordsCampaign',
    KEYWORD = 'AdwordsKeyword',
}

export const CRON_SUCCESS_MESSAGE =
    'The data pull is in progress. Please refer the below table for its status.';

export const CUSTOM_REPORTS = ['Keyword', 'Campaign'];

export const KEYWORD_EVENT_LIST_1 = [
    { label: 'Last 90 days', value: 0 },
    { label: 'Today', value: 1 },
    { label: 'Last 2 years', value: 2 },
];

export const KEYWORD_EVENT_LIST_2 = [
    { label: 'Last 90 days', value: 0 },
    { label: 'Today', value: 1 },
];

export interface CronStatusData {
    userName: string; // => 'Rahul Panchal';
    agencyName: string; // => 'American Family Insurance';
    user_id: number; // => 59;
    accountId: number; // => 24;
    agencyId: string; // => '20700000001397736';
    brandId?: number; // => 71;
    advertiserId: string; // => '21700000001903548';
    advertiserName?: string; // => 'AR~PER_AN~AmericanFamily';
    platformId: number; // => 1;
    reportType: string; // => 'Campaign';
    startTime?: string; // => '2023-01-18 02:37:15';
    endTime?: string; // => '2023-01-18 02:37:25';
    startDate: string; // => 'Oct 20, 2022';
    endDate: string; // => 'Jan 17, 2023';
    timeFlag?: number; // => 0 | 1 | 2;
    status: string; // 'Success';
}

export interface GETCronStatus {
    data: CronStatusData[];
}

export interface POSTSA360CronBody {
    userId: string;
    reportType: string;
    accountDetails: string; // stringify {agendyId: [brandIds]}
    startDate: string; // yyyy-mm-dd
    endDate: string; // yyyy-mm-dd
    timeFlag: string; // number 0, 1, 2 (0 === '90 days', 1 === 'today', 2 === 'last 2 years')
}

export interface POSTAdwordsCronBody {
    agencyId: string;
    subAccountId: string;
    reportType: string; // toLowerCase()!!!
    userId: string;
    file: File;
}

export interface POSTMarinCronBody {
    userId: string; // 140
    reportType: string; // Keyword | Campaign
    accountId: string; // 25
    agencyId: string; // 2500000
    brandId: string; // 70
    advertiserId: string; // 1306918533
    advertiser: string; // RF - US - CHI - Visionworks
}
