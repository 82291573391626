import Modal from '../Modal/Modal';
import Button from '@publicismedia-ds/ui-button';

interface ConfirmationModalProps {
    header?: string;
    message: string | React.ReactNode;
    type?: 'default' | 'warning';
    onConfirm: () => void;
    onCancel: () => void;
}

function ConfirmationModal({
    header,
    message,
    type = 'default',
    onConfirm,
    onCancel,
}: ConfirmationModalProps) {
    return (
        <Modal
            header={header || 'Confirm:'}
            userClosable={false}
            content={message}
            alertType={type === 'warning' ? 'error' : undefined}
            footer={
                <>
                    <Button display="text" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        display="primary"
                        onClick={() => {
                            onConfirm();
                            onCancel();
                        }}
                        color={type === 'warning' ? 'red' : 'brand-1'}
                    >
                        Confirm
                    </Button>
                </>
            }
            onClose={onCancel}
        />
    );
}

export default ConfirmationModal;
