import { axios } from '../axios-config';
import { API_UNIVERSAL_SETTINGS } from '../../constants';
import {
    BingAdsFormState,
    KenshooFormState,
    EditAccountState,
    GoogleAdsFormState,
    GETExistingAccountsData,
} from '../../state';
import {
    formatAddSA360,
    SA360AccountParams,
    formatUpdateAccount,
    formatAddKenshooBody,
    formatAddEngineAccountBody,
    saveDownloadFile,
} from '../../utils';

export const addEngineAccount = async (
    formState: BingAdsFormState | GoogleAdsFormState
) => {
    const url = API_UNIVERSAL_SETTINGS + '/addAccount';

    const platform = formatAddEngineAccountBody(formState);

    if (!platform) return;

    const body = { platforms: [platform] };

    try {
        const { data } = await axios.post(url, body);

        return data;
    } catch (error: any) {
        throw error;
    }
};

export const addKenshooAccount = async (formState: KenshooFormState) => {
    const url = API_UNIVERSAL_SETTINGS + '/addAccount';

    const platform = formatAddKenshooBody(formState);

    const body = { platforms: [platform] };

    try {
        const { data } = await axios.post(url, body);

        return data;
    } catch (error: any) {
        throw error;
    }
};

export const addSA360Account = async (formStates: SA360AccountParams) => {
    const url = API_UNIVERSAL_SETTINGS + '/addAccount';

    const platform = formatAddSA360(formStates);

    const body = { platforms: [platform] };

    try {
        const { data } = await axios.post(url, body);

        return data;
    } catch (error: any) {
        throw error;
    }
};

export const updateAccount = async (state: EditAccountState) => {
    const url = API_UNIVERSAL_SETTINGS + '/editAccount';

    const platform = formatUpdateAccount(state);

    const body = { platforms: [platform] };

    try {
        const { data } = await axios.post(url, body);

        return data;
    } catch (error: any) {
        throw error;
    }
};

export const deleteAccount = async (agencyId: string | number) => {
    const url = API_UNIVERSAL_SETTINGS + '/deleteAccount';

    try {
        const { data } = await axios.get(url, { params: { agencyId } });

        return data;
    } catch (error: any) {
        throw error;
    }
};

export const getPlatformsData = async () => {
    const url = API_UNIVERSAL_SETTINGS + '/getPlatformDetails';

    try {
        // CONTAINS PLATFORMS LIST PROPERTY WHICH IS AN ARRAY OF PLATFORM DETAILS WITH MANAGER EMAILS AS LAST ITEM IN ARRAY
        const { data } = await axios.get(url);

        return data;
    } catch (error: any) {
        throw error;
    }
};

export const getExistingAccounts = async () => {
    const url = API_UNIVERSAL_SETTINGS + '/getOnBoardedAccountsData';

    try {
        const { data } = await axios.get<GETExistingAccountsData>(url);

        return data;
    } catch (error: any) {
        throw error;
    }
};

export const downloadAccountOnboardingDoc = async () => {
    const url = API_UNIVERSAL_SETTINGS + '/downloadReportTemplate';
    const fileName = 'Account_Onboarding_Help_Document.docx';

    try {
        const blob = await axios.get(url, {
            params: {
                file: 'AccountOnboarding',
            },
            responseType: 'blob',
        });

        saveDownloadFile(blob, fileName);
    } catch (error: any) {
        throw error;
    }
};
