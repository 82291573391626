import './Modal.scss';

// COMPONENT PROPS
interface ModalContentProps {
    className?: string;
    children: React.ReactNode;
}

// FUNCTIONAL COMPONENT (MODAL CONTENT)
function ModalContent({ className, children }: ModalContentProps) {
    return (
        <div className={`modal-content${className ? ' ' + className : ''}`}>
            {children}
        </div>
    );
}

export default ModalContent;
