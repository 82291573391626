import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';

import { ScenarioPlannerMetric } from './Scenario-Planner-Calculator';

interface CalculatorFieldProps {
    label: string;
    metric: ScenarioPlannerMetric;
    value: string;
    onChange: (metric: ScenarioPlannerMetric, value: string) => void;
    onClick: (metric: ScenarioPlannerMetric, value: string) => Promise<void>;
}

function CalculatorField({
    label,
    metric,
    value,
    onChange,
    onClick,
}: CalculatorFieldProps) {
    const isDollars = metric === 'Cost' || metric === 'Efficiency';

    return (
        <div className="scenario-planner-calculator-field">
            <div
                className={`calculator-field-input ${
                    isDollars ? 'is-dollars' : ''
                }`}
            >
                <Textbox
                    type="number"
                    min=".001"
                    value={value}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(metric, evt.target.value);
                    }}
                >
                    {label}
                </Textbox>
            </div>
            <div className="calculator-field-button">
                <Button
                    display="secondary"
                    onClick={async () => {
                        await onClick(metric, value);
                    }}
                >
                    Calculate
                </Button>
            </div>
        </div>
    );
}

export default CalculatorField;
