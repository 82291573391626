import './Edit-User-Modal.scss';

import { GETRolesAccountBrands, UserDetails, UserFormState } from '../../state';

// UI COMPONENTS
import Modal from '../Modal/Modal';
import ManageUserForm from '../Manage-User-Form/Manage-User-Form';

// COMPONENT PROPS
interface EditUserModalProps {
    accessData: GETRolesAccountBrands | null;
    userData: UserDetails;
    onClose: () => void;
    onSubmit: (formState: UserFormState) => Promise<void>;
}

// FUNCTIONAL COMPONENT
function EditUserModal({
    accessData,
    userData,
    onClose,
    onSubmit,
}: EditUserModalProps) {
    return (
        <Modal
            userClosable={false}
            onClose={onClose}
            header="Edit User"
            content={
                <div className="edit-user-modal-content">
                    <ManageUserForm
                        accessData={accessData}
                        user={userData}
                        onSubmit={onSubmit}
                        onCancel={onClose}
                    />
                </div>
            }
        />
    );
}

export default EditUserModal;
