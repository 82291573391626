import { useEffect } from 'react';
import { useActions } from '../../hooks';
import { Navigate } from 'react-router-dom';

function PageNotFound() {
    // const { alertError } = useActions();
    // const NOT_FOUND_MESSAGE = `Unfortunately the resource you were looking for does not exist. [404 error]`;

    // useEffect(() => {
    //     alertError(NOT_FOUND_MESSAGE);
    // });

    return <Navigate to="/" />;
}

export default PageNotFound;
