import './Admin-Page.scss';

interface AdminPageProps {
    header?: string;
    children: React.ReactNode;
}

function AdminPage({ header, children }: AdminPageProps) {
    return (
        <div className="admin-page-container">
            <h1 className="admin-page-header">{header}</h1>
            <div className="admin-page-content">{children}</div>
        </div>
    );
}

export default AdminPage;
