import './Add-Note-Form.scss';

import React, { useEffect, useReducer } from 'react';
import { useActions, useAppSelector } from '../../hooks';

// API REQUESTS
import { getAccountsData, getUserAgencies } from '../../api';

// CONSTANTS
import { DEFAULT_API_ERROR } from '../../constants';

// TYPES
import {
    DropdownOption,
    AddNoteFormState,
    addNoteFormReducer,
    ADD_NOTE_ACTION_TYPE,
    DEFAULT_ADD_NOTE_STATE,
} from '../../state';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';

// UTILS
import { onPreventEnterKeySubmit } from '../../utils';

// COMPONENT PROPS
interface AddNoteFormProps {
    onSubmit: (state: AddNoteFormState) => Promise<void>;
}

function AddNoteForm({ onSubmit }: AddNoteFormProps) {
    // ADD NOTE FORM STATE
    const [state, dispatch] = useReducer(
        addNoteFormReducer,
        DEFAULT_ADD_NOTE_STATE
    );

    // REDUX ACTIONS
    const { setLoading, alertError } = useActions();

    // REDUX STATE
    const userId = useAppSelector(({ auth }) => auth.user?.id);

    // FETCH AGENCIES
    useEffect(() => {
        (async function () {
            if (!userId) return;

            setLoading(true);
            try {
                const data = await getUserAgencies(userId);

                dispatch({
                    type: ADD_NOTE_ACTION_TYPE.LOAD_AGENCIES_DATA,
                    payload: data,
                });
            } catch (error: any) {
                alertError(
                    error.response?.data?.errorMessage ||
                        error.response?.data?.error_message ||
                        error.response?.data?.message ||
                        DEFAULT_API_ERROR
                );
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    // HANDLE AGENCY SELECTION
    const onSelectAgency = async (selected: DropdownOption) => {
        if (!userId) return;

        // CHECK IF SELECTED VALUE IS THE SAME
        if (state.selectedAgency === selected.value) return;

        setLoading(true);

        try {
            // FETCH SELECTED AGENCY'S ACCOUNT DATA
            const data = await getAccountsData(userId, selected.value);

            if (!data) return;

            // UPDATE FORM STATE WITH ACCOUNT DATA
            dispatch({
                type: ADD_NOTE_ACTION_TYPE.SET_SELECTED_AGENCY,
                payload: data,
            });
        } catch (error: any) {
            // ALERT ERROR ON ERROR
            alertError(
                error.response?.data?.errorMessage ||
                    error.response?.data?.error_message ||
                    'Unable to get agency account data.  Please contact Darwin support'
            );
        } finally {
            setLoading(false);
        }
    };

    // HANDLE SELECTING ADVERTISER
    const onSelectAdvertiser = (selected: DropdownOption) => {
        dispatch({
            type: ADD_NOTE_ACTION_TYPE.SET_SELECTED_ADVERTISER,
            payload: selected,
        });
    };

    // HANDLE UPDATES TO NOTE
    const onSetNote = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: ADD_NOTE_ACTION_TYPE.SET_NOTE,
            payload: evt.target.value,
        });
    };

    // HANDLE SUBMITTING NEW NOTE
    const onSubmitNote = async (evt: React.FormEvent) => {
        evt.preventDefault();

        if (!userId) return;

        setLoading(true);
        try {
            await onSubmit(state);
            dispatch({
                type: ADD_NOTE_ACTION_TYPE.RESET_FORM,
            });
        } catch (error: any) {
            alertError(
                error.response?.data?.errorMessage ||
                    error.response?.data?.error_message ||
                    error.response?.data?.message ||
                    DEFAULT_API_ERROR
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <form
            className="notes-form"
            onSubmit={onSubmitNote}
            onKeyDown={onPreventEnterKeySubmit}
        >
            <Row>
                <Column>
                    <Dropdown
                        className="form-inputs-group-item"
                        options={state.agencyOptions}
                        onChange={onSelectAgency}
                        value={
                            state.selectedAgency
                                ? { label: '', value: state.selectedAgency }
                                : ''
                        }
                        defaultValue={
                            state.selectedAgency
                                ? { label: '', value: state.selectedAgency }
                                : ''
                        }
                        required
                    >
                        Select Agency:
                    </Dropdown>
                </Column>
                <Column>
                    <Dropdown
                        className="form-inputs-group-item"
                        options={state.advertiserOptions}
                        onChange={onSelectAdvertiser}
                        value={
                            state.selectedAdvertiser
                                ? { label: '', value: state.selectedAdvertiser }
                                : ''
                        }
                        defaultValue={
                            state.selectedAdvertiser
                                ? { label: '', value: state.selectedAdvertiser }
                                : ''
                        }
                        required
                    >
                        Select Advertiser
                    </Dropdown>
                </Column>
            </Row>
            <Row>
                <Column>
                    <Textbox
                        as="textarea"
                        className="form-inputs-group-item"
                        onChange={onSetNote}
                        value={state.notes}
                        required
                    >
                        Note:
                    </Textbox>
                </Column>
            </Row>
            <div className="form-buttons">
                <Button
                    type="submit"
                    onClick={onSubmitNote}
                    disabled={
                        !state.selectedAgency ||
                        !state.selectedAdvertiser ||
                        !state.notes
                    }
                >
                    Submit
                </Button>
            </div>
        </form>
    );
}

export default AddNoteForm;
