import { useAppSelector } from '../../hooks';

// UI COMPONENTS
import { Row } from '@publicismedia-ds/ui-grid';
import SettingsDateRange from '../../components/Product-Settings/Settings-Date-Range';
import ProductSettings from '../../components/Product-Settings/Product-Settings-Container';

// FUNCTIONAL COMPONENT
function PerformanceDiagnosticSettings() {
    // REDUX STATE
    const settings = useAppSelector((state) => state.universalSettings);

    // ENABLE/DISABLE "APPLY BUTTON"
    const disableApply =
        !settings.goalId ||
        !settings.budget ||
        !settings.flightStart ||
        !settings.flightEnd ||
        !settings.conversionType ||
        !settings.conversionColumn ||
        (settings.conversionType === 'Revenue' && !settings.revenueColumn) ||
        !settings.efficiencyNumerator ||
        !settings.efficiencyDenominator ||
        !settings.efficiencyTarget ||
        new Date(settings.flightEnd) < new Date(new Date().toDateString());

    return (
        <>
            {/* ---SETTINGS --- */}
            <ProductSettings disabled={disableApply}>
                <h3>Performance Diagnostic Settings</h3>
                <Row cols={4}>
                    <SettingsDateRange />
                </Row>
            </ProductSettings>
        </>
    );
}

export default PerformanceDiagnosticSettings;
