import React, { useEffect, useMemo, useReducer } from 'react';
import { useAppSelector, useAsyncWrapper } from '../../hooks';

// API REQUESTS
import { getAccountsData, getUserAgencies } from '../../api';

// CONSTANTS
import { CUSTOM_ALERT_TYPES } from '../../constants';

// TYPES
import {
    UserDetails,
    GoalsListItem,
    DropdownOption,
    CustomAlertItem,
    CustomAlertType,
    CustomAlertState,
    customAlertReducer,
    CUSTOM_ALERT_ACTION_TYPES,
    DEFAULT_CUSTOM_ALERT_STATE,
} from '../../state';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import InputError from '../../components/Input-Error-Message/Input-Error-Message';

// UTILS
import {
    sortDropdownOptions,
    getCustomAlertFormState,
    onPreventEnterKeySubmit,
} from '../../utils';

// COMPONENT PROPS
interface CustomAlertFormProps {
    users: UserDetails[];
    goalList: GoalsListItem[];
    data?: CustomAlertItem;
    isEditing?: boolean;
    isReadOnly?: boolean;
    onCancel?: () => void;
    onSubmit?: (formState: CustomAlertState) => Promise<void>;
}

// FUNCTIONAL COMPONENT (CUSTOM ALERT FORM)
function CustomAlertForm({
    data,
    users,
    goalList,
    onSubmit,
    onCancel,
    isEditing = false,
    isReadOnly = false,
}: CustomAlertFormProps) {
    const wrapper = useAsyncWrapper();

    // REDUX STATE
    const user = useAppSelector(({ auth }) => auth.user);

    // FORM STATE
    const [state, dispatch] = useReducer(
        customAlertReducer,
        DEFAULT_CUSTOM_ALERT_STATE
    );

    // CREATE AGENCY OPTIONS
    const agencyOptions = useMemo(() => {
        const options: DropdownOption[] = state.agencies.map((agency) => ({
            label: agency.agencyName,
            value: agency.agencyId,
        }));
        return sortDropdownOptions(options);
    }, [state.agencies]);

    // CREATE ADVERTISER OPTIONS FOR SELECTED AGENCY
    const advertiserOptions = useMemo(() => {
        const options: DropdownOption[] = [];
        if (state.accountData) {
            for (const advertiser of state.accountData.advertisers) {
                options.push({
                    label: advertiser.advertiserName,
                    value: advertiser.advertiserId,
                });
            }
        }
        return sortDropdownOptions(options);
    }, [state.accountData]);

    // CREATE GOAL OPTIONS FOR SELECTED ADVERTISER
    const goalOptions = useMemo(() => {
        const options: DropdownOption[] = [];
        if (state.advertiser) {
            for (const goal of goalList) {
                if (goal.advertiserId === state.advertiser.value) {
                    options.push({
                        label: goal.goalMappingName,
                        value: goal.goalId,
                    });
                }
            }
        }
        return sortDropdownOptions(options);
    }, [state.advertiser]);

    // CREATE USER DROPDOWN OPTIONS
    const userOptions = useMemo(() => {
        const options = users.map(
            ({ name, id, role }): DropdownOption => ({
                label: `${name} (${role})`,
                value: id.toString(),
            })
        );
        return sortDropdownOptions(options);
    }, [users]);

    // LOAD EXISTING CUSTOM ALERT DATA INTO FORM
    const loadFormData = wrapper(
        async (alertData: CustomAlertItem, userId: string) => {
            const [agencies, accountData] = await Promise.all([
                getUserAgencies(userId),
                getAccountsData(userId, alertData.agencyId.toString()),
            ]);

            const formattedData = getCustomAlertFormState({
                data: alertData,
                goalList,
                userOptions,
                agencies,
                accountData,
            });

            dispatch({
                type: CUSTOM_ALERT_ACTION_TYPES.LOAD_EXISTING_ALERT,
                payload: formattedData,
            });
        }
    );

    // LOAD AGENCIES & ALERT DATA IF PRESENT
    useEffect(() => {
        if (user) {
            if (data) {
                loadFormData(data, user.id);
            } else {
                (async () => {
                    const agencies = await getUserAgencies(user.id);
                    dispatch({
                        type: CUSTOM_ALERT_ACTION_TYPES.LOAD_AGENCIES,
                        payload: agencies,
                    });
                })();
            }
        }
    }, [user, data]);

    // HANDLE AGENCY SELECTION
    const onSetAgency = wrapper(async (selected: DropdownOption) => {
        if (user) {
            const accountData = await getAccountsData(user?.id, selected.value);
            if (!isReadOnly) {
                dispatch({
                    type: CUSTOM_ALERT_ACTION_TYPES.SET_AGENCY,
                    payload: {
                        agency: selected,
                        accountData: accountData,
                    },
                });
            }
        }
    });

    // HANDLE ADVERTISER SELECTION
    const onSetAdvertiser = (selected: DropdownOption) => {
        if (!isReadOnly) {
            dispatch({
                type: CUSTOM_ALERT_ACTION_TYPES.SET_ADVERTISER,
                payload: selected,
            });
        }
    };

    // HANDLE GOAL SELECTIONS
    const onSetGoals = (selected: DropdownOption[]) => {
        if (!isReadOnly) {
            dispatch({
                type: CUSTOM_ALERT_ACTION_TYPES.SET_GOALS,
                payload: selected,
            });
        }
    };

    // HANDLE USER SELECTIONS
    const onSetUsers = (selected: DropdownOption[]) => {
        if (!isReadOnly) {
            dispatch({
                type: CUSTOM_ALERT_ACTION_TYPES.SET_USERS,
                payload: selected,
            });
        }
    };

    // HANDLE ALERT TYPE SELECTION
    const onSelectAlertType = (selected: CustomAlertType) => {
        if (!isReadOnly) {
            dispatch({
                type: CUSTOM_ALERT_ACTION_TYPES.SET_ALERT_TYPE,
                payload: selected,
            });
        }
    };

    // HANDLE X VALUE INPUT
    const onSetXValue = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (!isReadOnly) {
            dispatch({
                type: CUSTOM_ALERT_ACTION_TYPES.SET_X_VALUE,
                payload: evt.target.value,
            });
        }
    };

    // HANDLE Y VALUE INPUT
    const onSetYValue = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (!isReadOnly) {
            dispatch({
                type: CUSTOM_ALERT_ACTION_TYPES.SET_Y_VALUE,
                payload: evt.target.value,
            });
        }
    };

    // HANDLE MIN BID INPUT
    const onSetMinBid = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: CUSTOM_ALERT_ACTION_TYPES.SET_MIN_BID,
            payload: evt.target.value,
        });
    };
    // HANDLE MAX BID INPUT
    const onSetMaxBid = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: CUSTOM_ALERT_ACTION_TYPES.SET_MAX_BID,
            payload: evt.target.value,
        });
    };

    // HANDLE FORM SUBMISSION
    const onSubmitForm = wrapper(async (evt: React.FormEvent) => {
        evt.preventDefault();

        if (onSubmit) {
            await onSubmit(state);
            dispatch({ type: CUSTOM_ALERT_ACTION_TYPES.RESET_FORM });
        }
    });

    // ENABLE/DISABLE FORM SUBMIT
    const isSubmitDisabled =
        !state.agency ||
        !state.xValue ||
        !state.yValue ||
        !state.alertType ||
        !state.advertiser ||
        !state.goals.length ||
        !state.users.length ||
        parseInt(state.minBid) > parseInt(state.maxBid);

    // JSX
    return (
        <div className="custom-alert-form-container">
            <form
                onSubmit={onSubmitForm}
                onKeyDown={onPreventEnterKeySubmit}
                className="custom-alert-form"
            >
                <Row>
                    {/* --- AGENCY --- */}
                    <Column>
                        <Dropdown
                            options={
                                isReadOnly && state.agency
                                    ? [state.agency]
                                    : agencyOptions
                            }
                            onChange={onSetAgency}
                            value={state.agency || ''}
                            disabled={isEditing}
                            required
                        >
                            Agency:
                        </Dropdown>
                    </Column>

                    {/* --- ADVERTISER --- */}
                    <Column>
                        <Dropdown
                            options={
                                isReadOnly && state.advertiser
                                    ? [state.advertiser]
                                    : advertiserOptions
                            }
                            onChange={onSetAdvertiser}
                            value={state.advertiser || ''}
                            disabled={isEditing}
                            required
                        >
                            Advertiser:
                        </Dropdown>
                    </Column>

                    {/* --- GOAL(S) --- */}
                    <Column>
                        <Dropdown
                            options={isReadOnly ? state.goals : goalOptions}
                            onChange={onSetGoals}
                            value={state.goals}
                            display="selectionInline"
                            multiple
                            required
                        >
                            Goals(s):
                        </Dropdown>
                    </Column>

                    {/* --- USER(S) --- */}
                    <Column>
                        <Dropdown
                            options={isReadOnly ? state.users : userOptions}
                            onChange={onSetUsers}
                            display="selectionInline"
                            value={state.users}
                            multiple
                            required
                        >
                            Users:
                        </Dropdown>
                    </Column>
                </Row>

                <Row>
                    {/* --- ALERT TYPE --- */}
                    <Column>
                        <Dropdown
                            options={CUSTOM_ALERT_TYPES}
                            onChange={onSelectAlertType}
                            value={state.alertType || ''}
                            disabled={isReadOnly || isEditing}
                            invert={isReadOnly}
                            required
                        >
                            Alert Type:
                        </Dropdown>
                    </Column>

                    {/* --- X VALUE --- */}
                    <Column>
                        <Textbox
                            type="number"
                            onChange={onSetXValue}
                            value={state.xValue}
                            min="0"
                            disabled={isReadOnly}
                            invert={isReadOnly}
                            required
                        >
                            X Value:
                        </Textbox>
                    </Column>

                    {/* --- Y VALUE --- */}
                    <Column>
                        <Textbox
                            type="number"
                            onChange={onSetYValue}
                            value={state.yValue}
                            min="0"
                            disabled={isReadOnly}
                            invert={isReadOnly}
                            required
                        >
                            Y Value:
                        </Textbox>
                    </Column>

                    {/* --- MIN BID --- */}
                    <Column>
                        <Textbox
                            type="number"
                            onChange={onSetMinBid}
                            value={state.minBid}
                            disabled={isReadOnly}
                            invert={isReadOnly}
                            min="0"
                        >
                            Min Bid:
                        </Textbox>
                    </Column>

                    {/* --- MAX BID --- */}
                    <Column>
                        <Textbox
                            type="number"
                            onChange={onSetMaxBid}
                            value={state.maxBid}
                            disabled={isReadOnly}
                            invert={isReadOnly}
                            min={state.minBid ? parseInt(state.minBid) : 0}
                        >
                            Max Bid:
                        </Textbox>
                        {parseInt(state.minBid) > parseInt(state.maxBid) && (
                            <InputError>
                                Value must be greater than min bid.
                            </InputError>
                        )}
                    </Column>
                </Row>

                {/* --- SUBMIT BUTTON --- */}
                <div className="form-submit-button">
                    {!!onCancel && (
                        <Button display="secondary" onClick={onCancel}>
                            {isEditing ? 'Cancel' : 'Back'}
                        </Button>
                    )}
                    {!isReadOnly && (
                        <Button type="submit" disabled={isSubmitDisabled}>
                            Submit
                        </Button>
                    )}
                </div>
            </form>
        </div>
    );
}

export default CustomAlertForm;
