import { axios } from '../axios-config';

// CONSTANTS
import { API_ROUTES } from '../../constants';

// TYPES
import {
    CustomAlertItem,
    EditCustomAlert,
    GETCustomAlerts,
    CustomAlertState,
} from '../../state';

// UTILS
import { getCustomAlertBody } from '../../utils';

// FETCH CUSTOM ALERTS
export const getCustomAlerts = async (userId: string | number) => {
    const { data } = await axios.get<GETCustomAlerts>(
        API_ROUTES.GET_CUSTOM_ALERTS,
        {
            params: { userId },
        }
    );

    return Array.isArray(data.data) ? data.data : [];
};

// CREATE CUSTOM ALERT
export const createCustomAlert = async (
    formState: CustomAlertState,
    userId: string
) => {
    const body = getCustomAlertBody(formState, userId);

    const { data } = await axios.post<{
        msg: string;
        status: 'success' | 'failure';
    }>(API_ROUTES.CREATE_CUSTOM_ALERT, body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

    return data;
};

// DELETE EXISTING CUSTOM ALERT
export const deleteCustomAlert = async (
    alertDetails: CustomAlertItem,
    userId: string
) => {
    const updatedAlert: EditCustomAlert = {
        agencyId: alertDetails.agencyId,
        advertiserId: alertDetails.advertiserId,
        alertId: alertDetails.alertId,
        alertType: alertDetails.alertType,
        createdBy: alertDetails.createdBy,
        isActive: false,
        mappings: alertDetails.userAlertData.map((details) => {
            return { ...details, isActive: false };
        }),
    };

    const { errorMessage } = await editCustomAlert(updatedAlert, userId);

    if (errorMessage) {
        throw new Error(errorMessage);
    }

    return { message: 'Alert has been successfully removed.' };
};

// EDIT EXISTING CUSTOM ALERT
export const editCustomAlert = async (
    alertDetails: EditCustomAlert,
    userId: string
) => {
    const body = new URLSearchParams();
    body.append('userId', userId);
    body.append('alertDetails', JSON.stringify(alertDetails));

    const { data } = await axios.post<{
        msg?: string;
        message?: string;
        errorMessage?: string;
        result?: 'success' | 'failure';
        status?: 'success' | 'failure';
    }>(API_ROUTES.EDIT_CUSTOM_ALERT, body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

    return data;
};
