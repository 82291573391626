import './Edit-Schedule-Modal.scss';

import { useEffect } from 'react';
import { useActions, useAppSelector } from '../../hooks';
import { ScheduleDetails, ScheduleGoalFormState } from '../../state';

// UI COMPONENTS
import Modal from '../Modal/Modal';
import ScheduleGoalForm from '../Schedule-Goal-Form/Schedule-Goal-Form';

interface EditScheduleModalProps {
    data: ScheduleDetails;
    onClose: () => void;
    onUpdate: (formState: ScheduleGoalFormState) => Promise<void>;
}

function EditScheduleModal({
    data,
    onClose,
    onUpdate,
}: EditScheduleModalProps) {
    // REDUX ACTIONS
    const { loadGoalsList } = useActions();

    // CURRENT USER'S ID
    const user = useAppSelector(({ auth }) => auth.user);

    // CURRENT USER'S GOALS LIST (REDUX)
    const goalsList = useAppSelector(({ goals }) => goals.list);

    // LOAD USER'S GOALS LIST IF NOT ALREADY LOADED IN REDUX
    useEffect(() => {
        if (!user || goalsList.length > 0) return;
        loadGoalsList(user);
    }, [goalsList]);

    // HANDLE SUBMIT UPDATE
    const onUpdateSchedule = async (formState: ScheduleGoalFormState) => {
        await onUpdate(formState);
        onClose();
    };

    return (
        <Modal
            userClosable={false}
            onClose={onClose}
            header="Edit Schedule"
            content={
                <ScheduleGoalForm
                    id="edit-scheduled-goal"
                    goals={goalsList}
                    schedule={data}
                    onSubmit={onUpdateSchedule}
                    onCancel={onClose}
                />
            }
        />
    );
}

export default EditScheduleModal;
