import './Navbar.scss';
import { Link } from 'react-router-dom';
import NavAlertBtn from './Nav-Alert-Btn';
import { ROUTES } from '../../router/router';
import { useActions, useAppSelector } from '../../hooks';

// CONSTANTS
import { FeatureIds, SESSION_STORAGE_USER } from '../../constants';

// UI COMPONENTS
import NavLink from './Nav-Link';
import { SecondaryNav } from '@publicismedia-ds/ui-navigation';

// FUNCTIONAL COMPONENT (NAVBAR)
function Navbar() {
    const { user } = useAppSelector(({ auth }) => auth);
    const { authFailure } = useActions();

    const onLogout = () => {
        sessionStorage.removeItem(SESSION_STORAGE_USER);
        authFailure();
        location.reload();
    };

    const isSuperAdmin = user?.roleId === '1';
    const isAdmin = isSuperAdmin || user?.roleId === '2';

    return (
        <SecondaryNav heading="Darwin Search Suite">
            {user ? (
                <>
                    <NavLink as={Link} text="Home" to="/" />
                    <NavAlertBtn userId={user.id || ''} />
                    <NavLink text="Products">
                        {(user.features[FeatureIds.ALL] ||
                            user.features[FeatureIds.PERFORMANCE_MONITOR]) && (
                            <NavLink
                                as={Link}
                                text="Performance Monitor"
                                to={ROUTES.PERFORMANCE_MONITOR}
                            />
                        )}
                        {(user.features[FeatureIds.ALL] ||
                            user.features[FeatureIds.YIELD_OPTIMIZER]) && (
                            <NavLink
                                as={Link}
                                text="Yield Optimizer"
                                to={ROUTES.YIELD_OPTIMIZER}
                            />
                        )}
                        {(user.features[FeatureIds.ALL] ||
                            user.features[FeatureIds.SCENARIO_PLANNER]) && (
                            <NavLink
                                as={Link}
                                text="Scenario Planner"
                                to={ROUTES.SCENARIO_PLANNER}
                            />
                        )}
                        {(user.features[FeatureIds.ALL] ||
                            user.features[
                                FeatureIds.PERFORMANCE_DIAGNOSTIC
                            ]) && (
                            <NavLink
                                as={Link}
                                text="Performance Diagnostic"
                                to={ROUTES.PERFORMANCE_DIAGNOSTIC}
                            />
                        )}
                    </NavLink>

                    {/* ADMIN NAV LINKS */}
                    {isAdmin && (
                        <NavLink text="Admin">
                            <NavLink
                                as={Link}
                                to={ROUTES.MANAGE_GOAL_MAPPINGS}
                                text="Manage Goal Mappings"
                            />
                            {(user.features[FeatureIds.ALL] ||
                                user.features[FeatureIds.SCHEDULE_GOALS]) && (
                                <NavLink
                                    as={Link}
                                    to={ROUTES.MANAGE_SCHEDULED_GOALS}
                                    text="Manage Scheduled Goals"
                                />
                            )}
                            <NavLink
                                as={Link}
                                to={ROUTES.MANAGE_USERS}
                                text="Manage Users"
                            />

                            <NavLink
                                as={Link}
                                to={ROUTES.BID_AND_BID_HEADROOM_SCAN}
                                text="Bid and Bid Headroom Scan"
                            />

                            <NavLink
                                as={Link}
                                to={ROUTES.MATCH_TYPE_STRATEGY_ANALYSIS}
                                text="Match Type Strategy Analysis"
                            />

                            <NavLink
                                as={Link}
                                to={ROUTES.USER_ACTIVITY}
                                text="User Activity"
                            />

                            <NavLink
                                as={Link}
                                to={ROUTES.CUSTOM_ALERTS}
                                text="Custom Alerts"
                            />

                            {/* SUPER ADMIN NAV LINKS */}
                            {isSuperAdmin && (
                                <>
                                    <NavLink
                                        as={Link}
                                        to={ROUTES.ACCOUNT_ONBOARDING}
                                        text="Account Onboarding"
                                    />
                                    <NavLink
                                        as={Link}
                                        to={ROUTES.RUN_DATA_FETCHING_CRONS}
                                        text="Run Data Fetching Crons"
                                    />
                                    <NavLink
                                        as={Link}
                                        to={ROUTES.CHANGE_SUMMARY_REVIEW}
                                        text="Change Summary Review"
                                    />
                                    <NavLink
                                        as={Link}
                                        to={ROUTES.NOTES}
                                        text="Notes"
                                    />
                                </>
                            )}
                        </NavLink>
                    )}
                    <NavLink text={`Hi, ${user.name.split(' ')[0]}`}>
                        <NavLink text="logout" onClick={onLogout} />
                    </NavLink>
                </>
            ) : (
                <></>
            )}
        </SecondaryNav>
    );
}

export default Navbar;
