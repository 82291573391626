export enum EDIT_ACCOUNT_ACTION_TYPES {
    SET_DSA_SUPPORT = 'set_dsa_support',
    SET_PLA_SUPPORT = 'set_pla_support',
    SET_CUSTOM_COLUMNS_SUPPORT = 'set_custom_columns_support',
    SET_GOOGLE_SUPPORT = 'set_google_support',
    SET_BING_SUPPORT = 'set_bing_support',
    ADD_CUSTOM_COLUMN = 'add_custom_column',
    UPDATE_CUSTOM_COLUMN = 'update_custom_column',
    REMOVE_CUSTOM_COLUMN = 'remove_custom_column',
    SET_REVENUE_SUPPORT = 'set_revenue_support',
    ADD_REVENUE_COLUMN = 'add_revenue_column',
    UPDATE_REVENUE_COLUMN = 'update_revenue_column',
    REMOVE_REVENUE_COLUMN = 'remove_revenue_column',
    SET_SA360_MANAGER_EMAIL = 'set_sa360_manager_email',
    ADD_SA360_ADVERTISER = 'add_sa360_advertiser',
    UPDATE_SA360_ADVERTISER = 'update_sa360_advertiser',
    REMOVE_SA360_ADVERTISER = 'remove_sa360_advertiser',
    SET_BING_MANAGER_EMAIL = 'set_bing_manager_email',
    ADD_BING_SUB_ACCOUNT = 'add_bing_sub_account',
    UPDATE_BING_SUB_ACCOUNT = 'update_bing_sub_account',
    REMOVE_BING_SUB_ACCOUNT = 'remove_bing_sub_account',
    SET_IS_MANAGER_ACCOUNT = 'set_is_manager_account',
    SET_CUSTOMER_ID = 'set_customer_id',
    SET_CUSTOMER_NAME = 'set_customer_name',
    SET_CUSTOMER_STATUS = 'set_customer_status',
    SET_GOOGLE_MANAGER_EMAIL = 'set_google_manager_email',
    ADD_GOOGLE_SUB_ACCOUNT = 'add_google_sub_account',
    UPDATE_GOOGLE_SUB_ACCOUNT = 'update_google_sub_account',
    REMOVE_GOOGLE_SUB_ACCOUNT = 'remove_google_sub_account',
    UPDATE_KENSHOO_REPORT = 'update_kenshoo_report',
    LOAD_ACCOUNT_DATA = 'load_account_data',
}
