import { useActions, useAppSelector } from '../../../hooks';

// UI COMPONENTS
import Textbox from '@publicismedia-ds/ui-textbox';
import KenshooReport from './Kenshoo-Report';

function EditKenshooAccount() {
    // REDUX ACTIONS
    const { updateKenshooReport } = useActions();

    // REDUX STATE
    const { kenshooReportDetails } = useAppSelector(
        ({ editAccountForm }) => editAccountForm
    );

    return (
        <div>
            <h4 className="edit-account-header">Kenshoo Report Details</h4>
            {kenshooReportDetails.map((data, index) => {
                return (
                    <KenshooReport
                        data={data}
                        index={index}
                        key={`kenshoo-report-${index}`}
                    />
                );
            })}
        </div>
    );
}

export default EditKenshooAccount;
