import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { RootState, AppDispatch, actionCreators } from '../state';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// Use typed versions instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useActions = () => {
    const dispatch = useDispatch();
    return useMemo(() => {
        return bindActionCreators(actionCreators, dispatch);
    }, [dispatch]);
};
