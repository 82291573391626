import { DropdownOption } from '../..';

export const SELECT_ALL_OPTION: DropdownOption = {
    label: 'Select All',
    value: 'all',
};

// AND/OR OPTION VALUES
export const AND_OR_OPTIONS = ['and', 'or', ''] as const;
export type AndOrOption = typeof AND_OR_OPTIONS[number];

// FILTER OPERATOR OPTION VALUES
export const FILTER_OPERATOR_OPTIONS = [
    'contains',
    'does not contain',
] as const;
export type FilterOperatorOption = typeof FILTER_OPERATOR_OPTIONS[number];

export interface CampaignFilter {
    andOr: AndOrOption;
    operator: FilterOperatorOption;
    value: string;
}
