import {
  ConversionType,
  DropdownOption,
  BidDataFetchingStatus,
} from '../../models';

export enum BID_HEADROOM_ACTION_TYPES {
  SET_AGENCY = 'set_agency',
  SET_ADVERTISER = 'set_advertiser',
  SET_CONVERSION_TYPE = 'set_conversion_type',
  SET_CONVERSION_COLUMN = 'set_conversion_column',
  SET_REVENUE_COLUMN = 'set_revenue_column',
  SET_CURRENT_PAGE = 'set_current_page',
  SET_ROWS_PER_PAGE = 'set_rows_per_page',
  SET_IS_VALID_COLUMN = 'set_is_valid_column',
  SET_SCAN_DATA = 'set_scan_data',
  SET_DATA_FETCHING_STATUS = 'set_data_fetching_status',
  RESET_STATE = 'reset_state',
}

export interface SetBidAgencyAction {
  type: BID_HEADROOM_ACTION_TYPES.SET_AGENCY;
  payload: DropdownOption;
}

export interface SetBidAdvertiserAction {
  type: BID_HEADROOM_ACTION_TYPES.SET_ADVERTISER;
  payload: DropdownOption;
}

export interface SetBidConversionTypeAction {
  type: BID_HEADROOM_ACTION_TYPES.SET_CONVERSION_TYPE;
  payload: ConversionType;
}

export interface SetBidConverionColumnAction {
  type: BID_HEADROOM_ACTION_TYPES.SET_CONVERSION_COLUMN;
  payload: DropdownOption;
}

export interface SetBidRevenueColumnAction {
  type: BID_HEADROOM_ACTION_TYPES.SET_REVENUE_COLUMN;
  payload: DropdownOption;
}

export interface SetBidCurrentPageAction {
  type: BID_HEADROOM_ACTION_TYPES.SET_CURRENT_PAGE;
  payload: number;
}

export interface SetBidRowsPerPageAction {
  type: BID_HEADROOM_ACTION_TYPES.SET_ROWS_PER_PAGE;
  payload: number;
}

export interface SetBidIsValidColumnAction {
  type: BID_HEADROOM_ACTION_TYPES.SET_IS_VALID_COLUMN;
  payload: boolean;
}

export interface SetBidScanDataAction {
  type: BID_HEADROOM_ACTION_TYPES.SET_SCAN_DATA;
  payload: any;
}

export interface SetBidDataFetchingStatusAction {
  type: BID_HEADROOM_ACTION_TYPES.SET_DATA_FETCHING_STATUS;
  payload: {
    agencyId: string;
    advertiserId: string;
    status: BidDataFetchingStatus;
  };
}

export interface ResetBidStateAction {
  type: BID_HEADROOM_ACTION_TYPES.RESET_STATE;
}

export type BidHeadroomAction =
  | SetBidAgencyAction
  | SetBidAdvertiserAction
  | SetBidConversionTypeAction
  | SetBidConverionColumnAction
  | SetBidRevenueColumnAction
  | SetBidCurrentPageAction
  | SetBidRowsPerPageAction
  | SetBidIsValidColumnAction
  | SetBidScanDataAction
  | SetBidDataFetchingStatusAction
  | ResetBidStateAction;
