import { getGoalsData, getGoalSetting } from '../../api';
import { AlertErrorAction } from '../actions/alert-actions';
import { GoalsListType } from '../action-types/goals-types';

import { UserData, GoalSettings, GoalsListItem } from '../models';

import {
    GoalsListAction,
    SetGoalsListAction,
    ClearGoalsListAction,
    SetGoalsLoadingAction,
    SetGoalSettingsAction,
} from '../actions/goals-actions';

import { Dispatch } from '@reduxjs/toolkit';
import { ProductsAction, SetLoadingAction } from '../actions';
import { alertError } from './alert-creators';
import { setLoading } from './products-creators';
import { DEFAULT_API_ERROR } from '../../constants';

// SET FETCHED GOALS LIST
export const setGoalsList = (
    goalsList: GoalsListItem[]
): SetGoalsListAction => {
    return {
        type: GoalsListType.SET_GOALS_LIST,
        payload: {
            goalsList,
        },
    };
};

// CLEAR STORED GOALS LIST
export const clearGoalsList = (): ClearGoalsListAction => {
    return {
        type: GoalsListType.CLEAR_GOALS_LIST,
        payload: {},
    };
};

// TOGGLE GOALS LOADING STATE
export const setGoalsLoading = (isLoading: boolean): SetGoalsLoadingAction => {
    return {
        type: GoalsListType.SET_GOALS_LOADING,
        payload: {
            isLoading,
        },
    };
};

// FETCH AND STORE SELECTED GOAL SETTINGS
export const loadGoalSettings = (goalId: string, userId: string) => {
    return async (
        dispatch: Dispatch<
            GoalsListAction | SetLoadingAction | AlertErrorAction
        >
    ) => {
        dispatch(setLoading(true));
        try {
            const goalSettings = await getGoalSetting(goalId, userId);
            dispatch(setGoalSettings(goalSettings));
        } catch (error: any) {
            dispatch(
                alertError(
                    error?.response?.data?.message ||
                        error.response?.data?.errorMessage ||
                        DEFAULT_API_ERROR
                )
            );
        } finally {
            dispatch(setLoading(false));
        }
    };
};

// FETCH AND STORE GOALS LIST
export const loadGoalsList = (user: UserData) => {
    return async (
        dispatch: Dispatch<GoalsListAction | AlertErrorAction | ProductsAction>
    ) => {
        dispatch(setLoading(true));
        try {
            const goalsList = (await getGoalsData(user.id)) || [];
            dispatch(setGoalsList(goalsList));

            if (user.lastAccessedGoalId && user.lastAccessedGoalId !== 'null') {
                const goalSettings = await getGoalSetting(
                    user.lastAccessedGoalId,
                    user.id
                );
                dispatch(setGoalSettings(goalSettings));
            }
        } catch (error: any) {
            dispatch(
                alertError(
                    error.response?.data?.message ||
                        error.response?.data?.errorMessage ||
                        DEFAULT_API_ERROR
                )
            );
        } finally {
            dispatch(setLoading(false));
        }
    };
};

const setGoalSettings = (goalSettings: GoalSettings): SetGoalSettingsAction => {
    return {
        type: GoalsListType.SET_GOAL_SETTINGS,
        payload: {
            goalSettings,
        },
    };
};
