import AXIOS from 'axios';
import { store } from '../state';

enum API_BASE_URL {
    PROD_OLD = 'https://darwin.performicssearch.com:8443',
    PROD = 'https://darwin-api.performicssearch.com',
    QA = 'https://darwin-api-qa.performicssearch.com',
}

const getBaseURL = () => {
    const apiEnv = process.env.REACT_APP_ENV;

    switch (apiEnv) {
        case 'prod-old':
            return API_BASE_URL.PROD_OLD;

        case 'prod':
            return API_BASE_URL.PROD;

        case 'development':
            return API_BASE_URL.QA;

        case 'qa':
            return API_BASE_URL.QA;

        default:
            return API_BASE_URL.QA;
    }
};

const baseURL = getBaseURL();

const axios = AXIOS.create({
    baseURL,
});

axios.interceptors.request.use((config) => {
    const token = store.getState().auth.user?.JWTToken || null;
    const pathname = window.location.pathname;

    if (token) {
        config.headers = {
            Authorization: `Bearer ${token}`,
            pathname,
        };
    }
    return config;
});

export { axios };
