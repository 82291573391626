import React, { useEffect, useState, useMemo, useRef } from 'react';

// UI COMPONENTS
import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import SearchFilter, {
    ColumnManagerData,
} from '@publicismedia-ds/ui-search-filter';
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';

// TYPES
import {
    ColumnAttrs,
    ColumnAttribute,
    PerformanceDiagnosticData,
} from '../../state';
import { numberWithCommas } from '../../utils';

// COMPONENT PROPS
interface PerformanceDiagnosticTableProps {
    data: PerformanceDiagnosticData;
    onUpdate?: (updated?: PerformanceDiagnosticData) => void;
}

// FUNCTIONAL COMPONENT
function PerformanceDiagnosticTable({
    data,
    onUpdate,
}: PerformanceDiagnosticTableProps) {
    // FORMATTED REPORT TYPE
    const reportType =
        data.reportType === 'DSA'
            ? data.reportType.toLowerCase()
            : data.reportType + 's';

    // FORMATTED CONVERSION COLUMN NAME
    const conversion = data.setting.conversion_column
        ? data.setting.conversion_column[0].toUpperCase() +
          data.setting.conversion_column.slice(1)
        : 'Conversions';

    const isRevenue = data.setting.conversion_type === 'Revenue';

    const NUM_OF_REPORT_TYPE = `NUM_OF_${reportType.toUpperCase()}`;
    const ROI_COLUMN = isRevenue ? 'ERS' : 'ROI';

    // COLUMN REFRENCES
    const COLUMN_PROPS = {
        AVG_CPC: 'avg_cpc',
        AVG_ORDER_VALUE: 'avg_order_value',
        AVG_POSITION: 'avg_position',
        CLICKS: 'clicks',
        CONVERSION_RATE: 'conversion_rate',
        CONVERSIONS: 'conversions',
        COST: 'cost',
        CPA: 'cpa',
        IMPRESSIONS: 'impressions',
        [NUM_OF_REPORT_TYPE]: NUM_OF_REPORT_TYPE.toLowerCase(),
        REVENUE: 'revenue',
        [ROI_COLUMN]: ROI_COLUMN.toLowerCase(),
        TIER: 'tier',
        UTILIZATION_RATE: 'utilization_rate',
    };

    // DEFAULT COLUMN HEADERS
    const DEFAULT_HEADERS = {
        [COLUMN_PROPS.TIER]: 'Tier',
        [COLUMN_PROPS.IMPRESSIONS]: 'Impressions',
        [COLUMN_PROPS.CLICKS]: 'Clicks',
        [COLUMN_PROPS[NUM_OF_REPORT_TYPE]]:
            reportType === 'DSA'
                ? reportType.toUpperCase()
                : reportType[0].toUpperCase() + reportType.slice(1), // => report type
        [COLUMN_PROPS.COST]: 'Cost',
        [COLUMN_PROPS.AVG_CPC]: 'Avg. CPC',
        [COLUMN_PROPS.CONVERSIONS]: conversion, // => conversion column
        [COLUMN_PROPS.CONVERSION_RATE]: 'Conversion Rate',
        [COLUMN_PROPS.CPA]: `Cost / ${conversion}`,
        [COLUMN_PROPS.UTILIZATION_RATE]: 'Utilization Rate',
        [COLUMN_PROPS.AVG_ORDER_VALUE]: 'Avg. Order Value',
        [COLUMN_PROPS[ROI_COLUMN]]: ROI_COLUMN,
        [COLUMN_PROPS.REVENUE]: data.setting.revenue_column || 'Revenue', // => revenue column
    };

    // TABLE STATE
    const [diagnosticProvider, setDiagnosticProvider] = useState<any>([]);

    // TABLE EDIT STATE
    const [isEditing, setIsEditing] = useState(false);

    // TABLE HEADERS STATE
    const headersRef = useRef(DEFAULT_HEADERS);
    const tempHeadersRef = useRef({ ...headersRef.current });

    // COLUMAN MANAGER STATE
    const [columnManagerProps, setColumnManagerProps] = useState<any>();

    // COLUMN TEMPLATE ATTRIBUTES
    const [columnAttrs, setColumnAttrs] = useState<ColumnAttrs[]>([]);

    // FORMAT TABLE DATA AND CREATE DATA TABLE PROVIDER
    useEffect(() => {
        const tableData = data.diagnostic.map((rowData) => {
            const row = { ...rowData };

            row.ers = row.ers || 0;
            row.clicks = Math.round(row.clicks);
            row.conversions = Math.round(row.conversions);
            row.cost = Math.round(row.cost);
            row.avg_cpc = Math.round(row.avg_cpc);
            row.cpa = Math.round(row.cpa);
            row.utilization_rate = Math.round(row.utilization_rate * 100);
            row.conversion_rate = Math.round(row.conversion_rate * 100);
            row.avg_order_value = Math.round(row.avg_order_value);
            row.revenue = Math.round(row.revenue);

            if (isRevenue) {
                row.ers = Math.round(row.ers * 100);
            } else {
                row.roi = Math.round(row.roi || 0);
            }
            return row;
        });
        setDiagnosticProvider(new DataProvider({ data: tableData }));
    }, [data]);

    // HANDLE SAVING EDITED TABLE DATA
    const onEditSave = async () => {
        if (!onUpdate || !diagnosticProvider) return;

        // GET EDIT STACK DATA
        const { editStack } = diagnosticProvider;

        // LOOP THRU EACH EDIT OPERATION AND UPDATE EDIT STACK DATA
        for (let op of editStack.stack) {
            editStack.data[op.rowIndex][op.key] = op.value;
        }

        // UPDATED DATA OBJECT
        const updatedData: PerformanceDiagnosticData = {
            diagnostic: [],
            setting: { ...data.setting },
            status: data.status,
            reportType: data.reportType,
        };

        // LOOP THRU EACH ROW OF EDIT STACK DATA AND PUSH TO UPDATED_DATA DIAGNOSTIC ARRAY
        for (let row of editStack.data) {
            updatedData.diagnostic.push({
                ...row,
                utilization_rate: row.utilization_rate / 100,
                conversion_rate: row.conversion_rate / 100,
                ers: row?.ers / 100 || 0,
            });
        }

        // UPDATES DATA ON PARENT COMPONENT (ALLOWS CHART TO DISPLAY UPDATED DATA)
        onUpdate(updatedData);

        headersRef.current = { ...tempHeadersRef.current };

        setIsEditing(false);
    };

    const renderColumnValue = (num: string | number) => (
        <>{numberWithCommas(num)}</>
    );

    const renderRevenueValue = (value: number | string) => (
        <>{!isRevenue && value === 0 ? '---' : '$' + numberWithCommas(value)}</>
    );

    // CREATE/UPDATE COLUMN TEMPLATE ATTRIBUTES.  CREATE/UPDATE COLUMAN MANAGER PROPS
    useMemo(() => {
        const columnData = [
            {
                name: headersRef.current[COLUMN_PROPS.IMPRESSIONS],
                value: COLUMN_PROPS.IMPRESSIONS,
                headerProp: COLUMN_PROPS.IMPRESSIONS.toUpperCase(),
                type: 'number',
            },
            {
                name: headersRef.current[COLUMN_PROPS.CLICKS],
                value: COLUMN_PROPS.CLICKS,
                headerProp: COLUMN_PROPS.CLICKS.toUpperCase(),
                type: 'number',
            },
            {
                name: headersRef.current[COLUMN_PROPS[NUM_OF_REPORT_TYPE]],
                value: COLUMN_PROPS[NUM_OF_REPORT_TYPE],
                headerProp: COLUMN_PROPS[NUM_OF_REPORT_TYPE].toUpperCase(),
                type: 'number',
            },
            {
                name: headersRef.current[COLUMN_PROPS.COST],
                value: COLUMN_PROPS.COST,
                className: isEditing ? 'number' : 'currency',
                headerProp: COLUMN_PROPS.COST.toUpperCase(),
            },
            {
                name: headersRef.current[COLUMN_PROPS.AVG_CPC],
                value: COLUMN_PROPS.AVG_CPC,
                className: isEditing ? 'number' : 'currency',
                headerProp: COLUMN_PROPS.AVG_CPC.toUpperCase(),
            },
            {
                name: headersRef.current[COLUMN_PROPS.CONVERSIONS],
                value: COLUMN_PROPS.CONVERSIONS,
                headerProp: COLUMN_PROPS.CONVERSIONS.toUpperCase(),
                type: 'number',
            },
            {
                name: headersRef.current[COLUMN_PROPS.CONVERSION_RATE],
                value: COLUMN_PROPS.CONVERSION_RATE,
                type: isEditing ? 'number' : 'percent',
                headerProp: COLUMN_PROPS.CONVERSION_RATE.toUpperCase(),
            },
            {
                name: headersRef.current[COLUMN_PROPS.CPA],
                value: COLUMN_PROPS.CPA,
                className: isEditing ? 'number' : 'currency',
                headerProp: COLUMN_PROPS.CPA.toUpperCase(),
            },
            {
                name: headersRef.current[COLUMN_PROPS.UTILIZATION_RATE],
                value: COLUMN_PROPS.UTILIZATION_RATE,
                type: isEditing ? 'number' : 'percent',
                headerProp: COLUMN_PROPS.UTILIZATION_RATE.toUpperCase(),
            },
            {
                name: headersRef.current[COLUMN_PROPS.AVG_ORDER_VALUE],
                value: COLUMN_PROPS.AVG_ORDER_VALUE,
                headerProp: COLUMN_PROPS.AVG_ORDER_VALUE.toUpperCase(),
            },
            {
                name: headersRef.current[COLUMN_PROPS[ROI_COLUMN]],
                value: COLUMN_PROPS[ROI_COLUMN],
                headerProp: COLUMN_PROPS[ROI_COLUMN].toUpperCase(),
                type: isRevenue && !isEditing ? 'percent' : 'number',
            },
            {
                name: headersRef.current[COLUMN_PROPS.REVENUE],
                value: COLUMN_PROPS.REVENUE,
                headerProp: COLUMN_PROPS.REVENUE.toUpperCase(),
            },
        ];

        // CREATE/UPDATE COLUMN MANAGER PROPS
        const columns = columnManagerProps?.columns
            ? // UPDATE COLUMN MANAGER PROPS IF EXISTING
              columnManagerProps.columns.map((col: any) => {
                  const copyColumn = { ...col };

                  // FIND MATCHING COLUMN
                  const matchingColumn = columnData.find(
                      ({ headerProp }) => headerProp === col.headerProp
                  );

                  if (matchingColumn) {
                      copyColumn.name = matchingColumn.name;
                      copyColumn.className = matchingColumn.className || '';
                      copyColumn.type = matchingColumn.type || 'number';
                  }

                  return copyColumn;
              })
            : // SET COLUMN MANAGER PROPS IF NOT EXISTING
              [...columnData];

        // CREATE NEW MANGER INSTANCE
        const nextMgr = new ColumnManagerData(columns);

        // UPDATE COLUMN ATTRIBUTES ON MANAGER CHANGES
        nextMgr.on(setColumnAttrs);

        // SET/UPDATE MANAGER PROPS
        setColumnManagerProps((prev: any) => {
            if (!prev) return nextMgr;

            prev.columns = columns;
            return prev;
        });

        // SET/UPDATE COLUMN ATTRIBUTES (USED TO CREATE COLUMN TEMPLATES)
        setColumnAttrs(nextMgr.columns);
    }, [isEditing, headersRef.current]);

    // ARRAY OF COLUMN TEMPLATE PROPS
    const columnTemplates = useMemo(() => {
        // FORMAT COLUMN TEMPLATE PROPS
        const attr2TemplateProps = ({
            headerProp,
            ...attr
        }: ColumnAttribute) => {
            let templateProps = {
                ...attr,
                minWidth: true,
                align: 'center',
                isEditable: true,
                label: (
                    <div className="diagnostic-column-header">
                        {isEditing ? (
                            <Textbox
                                onChange={(
                                    evt: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (COLUMN_PROPS[headerProp]) {
                                        tempHeadersRef.current = {
                                            ...tempHeadersRef.current,
                                            [COLUMN_PROPS[headerProp]]:
                                                evt.target.value,
                                        };
                                    }
                                }}
                                defaultValue={
                                    headersRef.current[COLUMN_PROPS[headerProp]]
                                }
                                hideLabel
                            >
                                {''}
                            </Textbox>
                        ) : (
                            attr.label
                        )}
                    </div>
                ),
                type: attr?.type || 'number',
            };
            return templateProps;
        };
        return columnAttrs
            .map((attr: ColumnAttrs, index: number): ColumnAttrs => {
                let onRender = renderColumnValue;

                if (
                    index === 9 ||
                    index === 11 ||
                    (index === 10 && !isRevenue)
                ) {
                    onRender = renderRevenueValue;
                }

                const attributes = { ...attr };
                if (!isEditing) {
                    attributes.onRender = onRender;
                }

                return {
                    ...attributes,
                    sortOrder: attr?.sortOrder ?? index,
                };
            })
            .sort(({ sortOrder: a }, { sortOrder: b }) => a - b)
            .map((attr) => ({
                ...attr,
                toggleChecked: attr?.toggleChecked ?? true,
            }))
            .filter(
                ({ toggleChecked }: { toggleChecked: boolean }) => toggleChecked
            )
            .map(
                ({ name: label, value: bind, ...attr }): ColumnAttribute => ({
                    label,
                    bind,
                    ...attr,
                })
            )
            .map(attr2TemplateProps);
    }, [columnAttrs, isEditing, headersRef]);

    return (
        <DataTable
            bindKey="tier"
            data={diagnosticProvider}
            above={
                <>
                    <SearchFilter
                        columnManagerProps={columnManagerProps}
                        hideSearchBox
                    />
                    {isEditing ? (
                        <div>
                            <Button
                                display="secondary"
                                onClick={() => setIsEditing(false)}
                                style={{ marginRight: '.5rem' }}
                            >
                                Cancel
                            </Button>
                            <Button onClick={onEditSave}>Save</Button>
                        </div>
                    ) : (
                        <div>
                            <Button
                                display="secondary"
                                onClick={() => setIsEditing(true)}
                            >
                                Edit
                            </Button>
                            <Button
                                style={{ marginLeft: '.5rem' }}
                                onClick={() => {
                                    if (onUpdate) onUpdate();
                                    headersRef.current = { ...DEFAULT_HEADERS };
                                }}
                            >
                                Reset
                            </Button>
                        </div>
                    )}
                </>
            }
            isEditing={isEditing}
        >
            {/* --- TIER --- */}
            <ColumnTemplate
                bind="tier"
                align="center"
                onRender={(text: string) => <span>{text.slice(4)}</span>}
                minWidth
                hidden={isEditing}
            >
                <div className="diagnostic-column-header">
                    {headersRef.current[COLUMN_PROPS.TIER]}
                </div>
            </ColumnTemplate>

            {columnTemplates.map(
                ({ label, ...templateProps }: { [key: string]: any }) => (
                    <ColumnTemplate {...templateProps} key={label}>
                        {label}
                    </ColumnTemplate>
                )
            )}
        </DataTable>
    );
}

export default PerformanceDiagnosticTable;
