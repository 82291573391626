import './Change-Summary.scss';

import { convertDateToString } from '../../utils';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';

// API REQUESTS
import { getChangeSummaryLogs, getTimeForChangeSummary } from '../../api';

// TYPES
import {
    DropdownOption,
    ChangeLogsSummary,
    GetChangeSummaryLogsParams,
} from '../../state';

// UI COMPONENTS
import Radio from '@publicismedia-ds/ui-radio';
import Button from '@publicismedia-ds/ui-button';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import Fieldset from '@publicismedia-ds/ui-fieldset';
import ChangeLogsSettings from './Change-Logs-Settings';
import DatePicker from '@publicismedia-ds/ui-datepicker';
import AdminPage from '../../components/Admin-Page/Admin-Page';
import BudgetChangeSummaryChart from '../../components/Charts/Budget-Change-Summary-Chart';
import BidChangeTable from '../../components/Charts/Yield-Optimizer-Charts/Bid-Change-Table';

// FORM STATE TYPE
interface ChangeSummaryState {
    goal: string;
    date: Date | '';
    time: string;
    timeOptions: string[];
    changeLogs: ChangeLogsSummary | null;
}

// DEFAULT FORM STATE VALUE
const DEFAULT_CHANGE_SUMMARY_STATE: ChangeSummaryState = {
    goal: '',
    date: '',
    time: '',
    timeOptions: [],
    changeLogs: null,
};

// FUNCTIONAL COMPONENT
function ChangeSummary() {
    // HOOKS
    const asyncWrapper = useAsyncWrapper();

    // FORM STATE
    const [state, setState] = useState<ChangeSummaryState>(
        DEFAULT_CHANGE_SUMMARY_STATE
    );

    // REDUX STATE
    const { list } = useAppSelector(({ goals }) => goals);
    const user = useAppSelector(({ auth }) => auth.user);

    // REDUX ACTIONS
    const { loadGoalsList, alertError, setLoading } = useActions();

    useEffect(() => {
        if (!user || list.length) return;
        loadGoalsList(user);
    }, []);

    // ENABLE/DISABLE SUBMIT BUTTON
    const isSubmitDisabled = !state.goal || !state.date || !state.time;

    // FORMAT DROPDOWN OPTIONS FROM GOALS LIST
    const goalOptions = useMemo(() => {
        return list.map((goal) => {
            return {
                label: `${goal.goalMappingName} - ${goal.agencyName}/${goal.advertiserName}`,
                value: goal.goalId,
            };
        });
    }, [list]);

    // HANDLE DATE SELECTION
    const onDateSelection = asyncWrapper(async (selectedDate: Date) => {
        if (!user || !state.goal) return;

        // CONVERT DATE TO YYYY-MM-DD FORMAT
        const date = convertDateToString(selectedDate);

        // FETCH TIMES FOR SELECTED GOAL ON SELECTED DATE
        const response = await getTimeForChangeSummary(
            user.id,
            state.goal,
            date
        );

        // FAILED DISPLAY MESSAGE
        if (response.status === 'failure') {
            alertError(response.msg);
        }

        // UPDATE STATE
        setState((prev) => ({
            ...prev,
            date: selectedDate,
            time: '',
            timeOptions: response.result || [],
            changeLogs: null,
        }));
    });

    // HANDLE GOAL SELECTION
    const onSelectGoal = (selected: DropdownOption) => {
        setState((prev) => ({ ...prev, goal: selected.value }));
    };

    // HANDLE TIME SELECTION
    const onSelectTime = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({ ...prev, time: evt.target.value }));
    };

    // HANDLE SUBMIT CHANGE SUMMARY REVIEW
    const onSubmitChangeSummary = asyncWrapper(async (evt: FormEvent) => {
        evt.preventDefault();

        if (!user || isSubmitDisabled || !state.date) return;

        const params: GetChangeSummaryLogsParams = {
            userId: user.id,
            goalId: state.goal,
            date: convertDateToString(state.date),
            time: state.time,
        };
        const data = await getChangeSummaryLogs(params);

        if (data.status === 'failure') {
            alertError(data.msg);
            return;
        }

        setState((prev) => ({ ...prev, changeLogs: data }));
    });

    return (
        <AdminPage header="change summary review">
            <form onSubmit={onSubmitChangeSummary}>
                <div className="change-summary-content">
                    <Dropdown
                        className="change-summary-goal-dropdown"
                        options={goalOptions}
                        onChange={onSelectGoal}
                        value={
                            state.goal ? { label: '', value: state.goal } : ''
                        }
                        defaultValue={
                            state.goal ? { label: '', value: state.goal } : ''
                        }
                        required
                    >
                        Select a goal to retrieve Change Summary Review
                    </Dropdown>
                    <DatePicker
                        className="change-summary-datepicker"
                        onChange={onDateSelection}
                        value={state.date}
                        required
                    >
                        Select Date
                    </DatePicker>

                    {!!state.timeOptions.length && (
                        <Fieldset
                            className="change-summary-times-fieldset"
                            legend="Select the Time"
                            onChange={onSelectTime}
                            asChoiceTabs
                        >
                            {state.timeOptions.map((time, index) => {
                                return (
                                    <Radio
                                        name="selectedTime"
                                        value={time}
                                        asButton
                                        selected={state.time === time}
                                        key={index}
                                    >
                                        {time}
                                    </Radio>
                                );
                            })}
                        </Fieldset>
                    )}
                </div>
                <div className="submit-button-container">
                    <Button type="submit" disabled={isSubmitDisabled}>
                        Submit
                    </Button>
                </div>
            </form>

            {state.changeLogs && (
                <>
                    <div className="change-logs-table-content">
                        <ChangeLogsSettings data={state.changeLogs} />
                    </div>
                    <div className="change-logs-table-content">
                        <h2>Budget Change Summary</h2>
                        <BudgetChangeSummaryChart
                            data={state.changeLogs.summaryLogs.dpad_result}
                        />
                    </div>
                    <div className="change-logs-table-content">
                        <h2>Keyword Bid Change Summary</h2>
                        <BidChangeTable
                            data={state.changeLogs.summaryLogs.yo_result}
                            conversionColumn="Conversion Column"
                            conversionType="Actions"
                            reportType="keyword"
                        />
                    </div>
                </>
            )}
        </AdminPage>
    );
}

export default ChangeSummary;
