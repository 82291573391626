import './Universal-Settings.scss';

import { ROUTES } from '../../router/router';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';

// COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import TextBox from '@publicismedia-ds/ui-textbox';
import NotesModal from '../Notes-Modal/Notes-Modal';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import DatePicker from '@publicismedia-ds/ui-datepicker';
import YieldOptimizerSettings from '../Product-Settings/Yield-Optimizer-Settings';
import ScenarioPlannerSettings from '../Product-Settings/Scenario-Planner-Settings';
import PerformanceMonitorSettings from '../Product-Settings/Performance-Monitor-Settings';
import PerformanceDiagnosticSettings from '../Product-Settings/Performance-Diagnostic-Settings';
import MatchTypeAnalysisSettings from '../Match-Type-Analysis-Settings/Match-Type-Analysis-Settings';

// TYPES
import {
    NoteDetails,
    ConversionType,
    DropdownOption,
    GETDarwinDataV3,
    UniversalSettingsState,
    UniversalSettingFormOptions,
} from '../../state';

// UTILS
import {
    capitalize,
    convertStringToDate,
    formatGoalFormOptions,
} from '../../utils';

// API REQUESTS
import {
    addGoalSetting,
    getDarwinDataV3,
    getAnomalyTableData,
    getDirectionalPacing,
    getScenarioPlannerData,
    downloadMatchTypeAnalysis,
    getPerformanceDiagnosticData,
} from '../../api';
import { DEFAULT_API_ERROR } from '../../constants';

// COMPONENT PROPS
interface UniversalSettingsProps {
    onApply?: () => void;
}

const today = new Date();
today.setHours(0, 0, 0, 0);

// FUNCTIONAL COMPONENT
function UniversalSettings({ onApply }: UniversalSettingsProps) {
    // HOOKS
    const { pathname } = useLocation();
    const asyncWrapper = useAsyncWrapper();

    // REDUX STATE
    const { user } = useAppSelector(({ auth }) => auth);
    const { notesData } = useAppSelector(({ notes }) => notes);
    const { goalSettings } = useAppSelector(({ goals }) => goals);
    const { list: goalsList } = useAppSelector(({ goals }) => goals);
    const settings = useAppSelector((state) => state.universalSettings);

    // REDUX ACTOINS
    const {
        alertError,
        alertSuccess,
        updateBudget,
        loadAllNotes,
        loadGoalsList,
        updateFlightEnd,
        loadGoalSettings,
        updateFlightStart,
        loadCurrentSettings,
        updateRevenueColumn,
        updateConversionType,
        setYieldOptimizerData,
        updateEfficiencyTarget,
        updateConversionColumn,
        setScenarioPlannerData,
        updateBroadPercentFactor,
        updateEfficiencyNumerator,
        setPerformanceMonitorData,
        updatePhrasePercentFactor,
        updateEfficiencyDenominator,
        setPerformanceDiagnosticData,
    } = useActions();

    // DISPLAY NOTES MODAL
    const [goalNotes, setGoalNotes] = useState<NoteDetails | null>(null);

    // TOGGLE HIDE/SHOW UNIVERISAL SETTINGS
    const [formOptions, setFormOptions] =
        useState<UniversalSettingFormOptions>();

    // GENERATE GOALS DROPDOWN OPTIONS
    const goalsOptions: DropdownOption[] = useMemo(
        () =>
            goalsList.map((goal) => {
                return {
                    label: `${goal.goalMappingName} - ${goal.agencyName} /  ${goal.advertiserName}`,
                    value: goal.goalId,
                };
            }),
        [goalsList]
    );

    // FETCH AND LOAD GOALS LIST DATA
    useEffect(() => {
        if (!user || goalsList.length) return;
        loadGoalsList(user);

        if (notesData.length) return;
        loadAllNotes(user.id);
    }, []);

    // LOAD UNIVERSAL SETTINGS STATE & FORMAT FORM OPTIONS
    useEffect(() => {
        if (!user || !goalSettings) return;

        const selectedGoal = goalsList.find(
            (goal) =>
                goal.goalId === goalSettings.universalSetting.goalId.toString()
        );

        if (!selectedGoal) return;

        const options = formatGoalFormOptions(selectedGoal, goalSettings);

        // DEFAULT CUSTOM END DATE (YESTERDAY)
        const defaultCustomEnd = new Date();
        defaultCustomEnd.setDate(defaultCustomEnd.getDate() - 1);

        // DEFAULT CUSTOM START DATE
        const defaultCustomStart = new Date();
        defaultCustomStart.setDate(defaultCustomEnd.getDate() - 6);

        const { flightStart, flightEnd, customStartDate, customEndDate } =
            goalSettings.universalSetting;

        const currentSettings: UniversalSettingsState = {
            ...goalSettings.universalSetting,
            flightStart: convertStringToDate(flightStart),
            flightEnd: convertStringToDate(flightEnd),
            customStartDate: customStartDate
                ? convertStringToDate(customStartDate)
                : defaultCustomStart.toDateString().slice(4),
            customEndDate: customEndDate
                ? convertStringToDate(customEndDate)
                : defaultCustomEnd.toDateString().slice(4),
            userId: user.id,
            phrasePercentBidFactor: goalSettings.universalSetting
                .phrasePercentBidFactor
                ? goalSettings.universalSetting.phrasePercentBidFactor.toString()
                : '',
            broadPercentBidFactor: goalSettings.universalSetting
                .broadPercentBidFactor
                ? goalSettings.universalSetting.broadPercentBidFactor.toString()
                : '',
            revenueColumn: goalSettings.universalSetting.revenueColumn || '',
            excludeFlightDates:
                goalSettings.universalSetting.excludeFlightDates || '',
            optimizationType:
                goalSettings.universalSetting?.optimizationType || '',
        };

        loadCurrentSettings(currentSettings);
        setFormOptions(options);
        loadAllNotes(user.id);
    }, [goalSettings]);

    // HANDLE GOAL SELECTION
    const onSelectGoal = (selected: DropdownOption) => {
        if (!user) return;
        loadGoalSettings(selected.value, user.id);
    };

    // HANDLE APPLY SETTINGS SUBMIT
    const onApplySettings = asyncWrapper(async (evt: React.FormEvent) => {
        evt.preventDefault();

        if (!settings.goalId) return;

        const isMatchType = pathname === ROUTES.MATCH_TYPE_STRATEGY_ANALYSIS;

        // SET BROAD/PHRASE PERCENT BID TO EMPTY STRING IF NOT MATCH TYPE
        if (!isMatchType) {
            updateBroadPercentFactor('');
            updatePhrasePercentFactor('');
        }

        // SAVE CURRENT UNIVERSAL SETTINGS
        await addGoalSetting(settings, isMatchType);

        // FETCH PERFERORMANCE MONITOR DATA
        if (pathname === ROUTES.PERFORMANCE_MONITOR) {
            const data = await Promise.allSettled([
                getDirectionalPacing(settings.goalId),
                getAnomalyTableData(settings.userId, settings.goalId),
            ]).then(([dpadData, anomalyData]) => {
                if (dpadData.status === 'rejected') {
                    alertError(
                        dpadData.reason?.response?.data?.errorMessage ||
                            DEFAULT_API_ERROR
                    );
                }

                if (anomalyData.status === 'rejected') {
                    alertError(
                        anomalyData.reason?.response?.data?.errorMessage ||
                            DEFAULT_API_ERROR
                    );
                }

                return {
                    dpad:
                        dpadData.status === 'fulfilled' ? dpadData.value : null,
                    anomaly:
                        anomalyData.status === 'fulfilled'
                            ? anomalyData.value
                            : null,
                };
            });

            // SAVE PERFORMANCE MONITOR DATA
            setPerformanceMonitorData(data);
        }

        // FETCH YIELD OPTIMIZER DATA
        if (pathname === ROUTES.YIELD_OPTIMIZER) {
            const res = await getDarwinDataV3(
                settings.userId,
                settings.goalId,
                settings.optimizationStrategy
            );

            const { Cost, msg, status } = res;
            const darwinData = !Cost && msg ? null : (res as GETDarwinDataV3);

            if (darwinData === null && status === 'success') {
                alertSuccess(msg);
                // DISPLAY BUTTON TO DOWNLOAD CAPS BULKSHEET <TODO>
            }

            if (darwinData === null && status === 'failure') {
                alertError(msg);
            }

            setYieldOptimizerData(darwinData);
        }

        // FETCH SCENARIO PLANNER DATA
        if (pathname === ROUTES.SCENARIO_PLANNER) {
            const data = await getScenarioPlannerData(
                settings.userId,
                settings.goalId
            );

            setScenarioPlannerData(data || null);

            if (!data) {
                alertError('No data available. Please contact Darwin support.');
            }
        }

        // FETCH PERFOMANCE DIAGNOSTIC DATA
        if (pathname === ROUTES.PERFORMANCE_DIAGNOSTIC) {
            const data = await getPerformanceDiagnosticData(
                settings.userId,
                settings.goalId
            );

            // SET DIAGNOSTIC DATA
            setPerformanceDiagnosticData(data || null);

            if (!data) {
                alertError('No data available. Please contact Darwin support.');
            }
        }

        // DOWNLOAD MATCH TYPE STRATEGY ANALYSIS
        if (pathname === ROUTES.MATCH_TYPE_STRATEGY_ANALYSIS) {
            if (!user) return;

            const goalName =
                goalsList.find(
                    ({ goalId }) => goalId === settings.goalId?.toString()
                )?.goalMappingName || '';

            await downloadMatchTypeAnalysis(
                user.id,
                settings.goalId.toString(),
                goalName
            );
        }

        // RUN FUNCTION IF PROP PASSED
        if (onApply) {
            onApply();
        }

        // SCROLL TO TOP OF PAGE
        window.scroll(0, 0);
    });

    // SELECTED GOAL ID DATA
    const selectedGoalData = useMemo(() => {
        if (!settings.goalId) return;

        return goalsList.find(
            (goal) => goal.goalId === settings.goalId?.toString()
        );
    }, [settings.goalId]);

    // SELECTED GOALS NOTES
    const selectedGoalNotes = notesData.find(
        (note) => note.advertiserId === selectedGoalData?.advertiserId
    );

    // JSX
    return (
        <div className="universal-settings-container">
            <form onSubmit={onApplySettings} id="universal-settings-form">
                <div className="universal-settings-row">
                    <h4>Preview/Select a Goal</h4>
                    <div className="select-goal-input">
                        <Dropdown
                            options={goalsOptions}
                            value={
                                goalsOptions.find((option) => {
                                    return (
                                        option.value ===
                                        settings.goalId?.toString()
                                    );
                                }) || ''
                            }
                            onChange={onSelectGoal}
                            placeholder="Search for Goal"
                        >
                            Select a goal to retrieve settings
                        </Dropdown>
                        <Button
                            className="notes-modal-button"
                            onClick={() => {
                                if (!selectedGoalNotes) return;
                                setGoalNotes(selectedGoalNotes);
                            }}
                            color="brand-2"
                            disabled={!selectedGoalNotes}
                        >
                            Notes
                        </Button>
                    </div>
                </div>
                <div className="universal-settings-row">
                    <h4>
                        Goal Settings For:{' '}
                        {goalSettings?.universalSetting.goals.goalMappingName}
                    </h4>
                    {/* === SETTINGS INPUTS === */}

                    <Row>
                        {/* --- AGENCY --- */}
                        <Column>
                            <Dropdown
                                options={formOptions?.agency || []}
                                value={formOptions?.agency[0] || ''}
                            >
                                Agency (read-only)
                            </Dropdown>
                        </Column>

                        {/* --- ADVERTISER --- */}
                        <Column>
                            <Dropdown
                                options={formOptions?.advertiser || []}
                                value={formOptions?.advertiser[0] || ''}
                            >
                                Advertiser (read-only)
                            </Dropdown>
                        </Column>

                        {/* --- ENGINES --- */}
                        <Column>
                            <Dropdown
                                options={formOptions?.engines || []}
                                value={
                                    formOptions
                                        ? {
                                              label: `${formOptions?.engines.length} Engine Accounts`,
                                              value: `${formOptions?.engines.length} Engine Accounts`,
                                          }
                                        : ''
                                }
                            >
                                Engine Accounts (read-only)
                            </Dropdown>
                        </Column>

                        {/* --- CAMPAIGNS --- */}
                        <Column>
                            <Dropdown
                                options={formOptions?.campaigns || []}
                                value={
                                    formOptions
                                        ? {
                                              label: `${formOptions?.campaigns.length} Campaigns`,
                                              value: `${formOptions?.campaigns.length} Campaigns`,
                                          }
                                        : ''
                                }
                            >
                                Campaigns (read-only)
                            </Dropdown>
                        </Column>
                    </Row>
                    <Row cols={4}>
                        {/* --- BUDGET --- */}
                        <Column>
                            <TextBox
                                type="number"
                                value={settings.budget || ''}
                                onChange={(
                                    evt: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateBudget(parseFloat(evt.target.value));
                                }}
                                required
                            >
                                Budget
                            </TextBox>
                        </Column>

                        {/* --- FLIGHT START DATE --- */}
                        <Column>
                            <DatePicker
                                value={
                                    settings.flightStart
                                        ? new Date(settings.flightStart)
                                        : ''
                                }
                                maxDate={Date.now()}
                                onChange={(date: Date) => {
                                    updateFlightStart(date);
                                }}
                                required
                            >
                                Flight Start Date
                            </DatePicker>
                        </Column>

                        {/* --- FLIGHT END DATE --- */}
                        <Column>
                            <DatePicker
                                value={
                                    settings.flightEnd
                                        ? new Date(settings.flightEnd)
                                        : ''
                                }
                                min={new Date()}
                                minDate={new Date()}
                                onChange={(date: Date) => {
                                    updateFlightEnd(date);
                                }}
                                required
                            >
                                Flight End Date
                            </DatePicker>
                            {settings.goalId &&
                                settings.flightEnd &&
                                new Date(settings.flightEnd) < today && (
                                    <p
                                        style={{
                                            color: 'red',
                                            margin: '0 0 0 .2rem',
                                            fontSize: '.8rem',
                                        }}
                                    >
                                        Please select a future date
                                    </p>
                                )}
                        </Column>

                        {/* --- CONVERSION TYPE --- */}
                        <Column>
                            <Dropdown
                                options={formOptions?.conversionType || []}
                                value={{
                                    label: '',
                                    value: capitalize(
                                        settings.conversionType || ''
                                    ),
                                }}
                                defaultValue={{
                                    label: settings.conversionType || '',
                                    value: settings.conversionType || '',
                                }}
                                onChange={(selected: DropdownOption) => {
                                    const value =
                                        selected.value as ConversionType;
                                    if (value === 'Actions') {
                                        updateRevenueColumn(
                                            goalSettings?.universalSetting
                                                .revenueColumn || ''
                                        );
                                    }
                                    updateConversionType(value);
                                }}
                                required
                            >
                                Conversion Type
                            </Dropdown>
                        </Column>

                        {/* --- REVENUE COLUMN --- */}
                        {settings.conversionType === 'Revenue' && (
                            <Column>
                                <Dropdown
                                    options={formOptions?.revenueColumn || []}
                                    value={
                                        settings.revenueColumn
                                            ? {
                                                  label: '',
                                                  value: settings.revenueColumn,
                                              }
                                            : ''
                                    }
                                    defaultValue={
                                        settings.revenueColumn
                                            ? {
                                                  label: '',
                                                  value: settings.revenueColumn,
                                              }
                                            : ''
                                    }
                                    placeholder="Select revenue column"
                                    onChange={({ value }: DropdownOption) => {
                                        updateRevenueColumn(value);
                                    }}
                                    required
                                >
                                    Revenue Column
                                </Dropdown>
                            </Column>
                        )}
                        {/* --- CONVERSION COLUMN --- */}
                        <Column>
                            <Dropdown
                                options={
                                    settings.conversionType === 'Revenue' &&
                                    formOptions?.conversionColumn
                                        ? [
                                              {
                                                  label: 'Revenue',
                                                  value: 'Revenue',
                                              },
                                              ...formOptions?.conversionColumn,
                                          ]
                                        : formOptions?.conversionColumn || []
                                }
                                value={
                                    {
                                        label: capitalize(
                                            settings.conversionColumn
                                        ),
                                        value: settings.conversionColumn,
                                    } || ''
                                }
                                defaultValue={
                                    {
                                        label: capitalize(
                                            settings.conversionColumn
                                        ),
                                        value: settings.conversionColumn,
                                    } || ''
                                }
                                onChange={({ value }: DropdownOption) => {
                                    updateConversionColumn(value);
                                }}
                                required
                            >
                                Conversion Column
                            </Dropdown>
                        </Column>

                        {/* --- EFFICIENCY NUMERATOR --- */}
                        <Column className="efficiency-calculator">
                            <Dropdown
                                className="efficiency-numerator"
                                options={formOptions?.efficiencyNumerator || []}
                                value={
                                    {
                                        label: capitalize(
                                            settings.efficiencyNumerator
                                        ),
                                        value: settings.efficiencyNumerator,
                                    } || ''
                                }
                                defaultValue={
                                    {
                                        label: capitalize(
                                            settings.efficiencyNumerator
                                        ),
                                        value: settings.efficiencyNumerator,
                                    } || ''
                                }
                                onChange={({ value }: DropdownOption) => {
                                    updateEfficiencyNumerator(value);
                                }}
                                required
                            >
                                Efficiency Calculator
                            </Dropdown>
                            <span className="efficiency-operator">/</span>
                        </Column>

                        {/* --- EFFICIENCY DENOMINATOR --- */}
                        <Column>
                            <Dropdown
                                className="efficiency-denominator"
                                options={
                                    settings.conversionType === 'Revenue' &&
                                    formOptions
                                        ? [
                                              ...formOptions.conversionColumn,
                                              ...formOptions.revenueColumn,
                                          ]
                                        : formOptions?.conversionColumn || []
                                }
                                value={
                                    {
                                        label: capitalize(
                                            settings.efficiencyDenominator
                                        ),
                                        value: settings.efficiencyDenominator,
                                    } || ''
                                }
                                onChange={({ value }: DropdownOption) => {
                                    updateEfficiencyDenominator(value);
                                }}
                            >
                                {''}
                            </Dropdown>
                        </Column>

                        {/* --- EFFICIENCY TARGET */}
                        <Column>
                            <TextBox
                                type="number"
                                value={settings.efficiencyTarget || ''}
                                min="0"
                                onChange={(
                                    evt: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateEfficiencyTarget(
                                        parseFloat(evt.target.value)
                                    );
                                }}
                                required
                            >
                                Efficiency Target
                            </TextBox>
                        </Column>
                    </Row>
                </div>

                {/* --- PRODUCT SPECIFIC SETTINGS --- */}

                {/* --- PERFORMANCE MONITOR SETTINGS --- */}
                {pathname === ROUTES.PERFORMANCE_MONITOR && (
                    <PerformanceMonitorSettings />
                )}

                {/* --- YIELD OPTIMIZER SETTINGS --- */}
                {pathname === ROUTES.YIELD_OPTIMIZER && (
                    <YieldOptimizerSettings />
                )}

                {/* --- SCENARIO PLANNER SETTINGS --- */}
                {pathname === ROUTES.SCENARIO_PLANNER && (
                    <ScenarioPlannerSettings />
                )}

                {/* --- PERFORMANCE DIAGNOSTIC SETTINGS --- */}
                {pathname === ROUTES.PERFORMANCE_DIAGNOSTIC && (
                    <PerformanceDiagnosticSettings />
                )}
                {/* --- MATCH TYPE STRATEGY ANALYSIS (ADMIN) --- */}
                {pathname === ROUTES.MATCH_TYPE_STRATEGY_ANALYSIS && (
                    <MatchTypeAnalysisSettings />
                )}
            </form>

            {goalNotes && (
                <NotesModal
                    notes={goalNotes}
                    onClose={() => setGoalNotes(null)}
                />
            )}
        </div>
    );
}

export default UniversalSettings;
