import produce from 'immer';
import { PLATFORM_IDS, SELECT_ALL } from '../../../constants';
import {
  DropdownOption,
  AccountReportType,
  ExistingAccount,
} from '../../models';
import {
  INITIAL_SA360_CRON_FORM,
  SA360CronFormAction,
  SA360CronFromState,
  SA360_CRON_ACTION_TYPES,
} from '../form-models';

export const sa360CronFormReducer = produce(
  (
    state: SA360CronFromState,
    action: SA360CronFormAction
  ): SA360CronFromState => {
    switch (action.type) {
      case SA360_CRON_ACTION_TYPES.LOAD_OPTIONS_DATA: {
        const { listOfReports, accounts } = action.payload;

        // CREATE REPORT OPTIONS
        state.reportOptions = listOfReports.map((report) => ({
          label: report,
          value: report,
        }));

        // FILTER OUT NON SA 360 ACCOUNTS
        state.accountsData = accounts.reduce(
          (data: { [key: string]: ExistingAccount }, account) => {
            if (account.platform_id === PLATFORM_IDS.SA_360) {
              data[account.agency_id] = account;
            }
            return data;
          },
          {}
        );

        // CREATE ADVERTISER OPTIONS
        return state;
      }

      case SA360_CRON_ACTION_TYPES.SET_SELECTED_REPORT: {
        const sa360Accounts = { ...state.accountsData };
        const reportOptions = [...state.reportOptions];

        // RESET ALL FORM VALUES
        state = { ...INITIAL_SA360_CRON_FORM };
        state.accountsData = sa360Accounts;
        state.reportOptions = reportOptions;

        // SET SELECTED REPORT VALUE
        state.selectedReport = action.payload;

        // CREATE AGENCY OPTIONS BASED ON SELECTED REPORT TYPE
        const agencyOptions = Object.values(state.accountsData).reduce(
          (options: DropdownOption[], account) => {
            // CHECK ACCOUNT SUPPORTS SELECTED REPORT TYPE
            if (
              account.reportType.includes(
                action.payload.value as AccountReportType
              )
            ) {
              // ADD ACCOUNT TO OPTIONS
              options.push({
                label: account.account_name,
                value: account.agency_id,
              });
            }

            return options;
          },
          []
        );

        state.agencyOptions = [SELECT_ALL, ...agencyOptions];

        return state;
      }

      case SA360_CRON_ACTION_TYPES.ADD_SELECTED_AGENCY: {
        const agencyId = action.payload.value;

        if (agencyId === SELECT_ALL.value) {
          // SELECT ALL AGENCY OPTIONS
          state.selectedAgencies = [...state.agencyOptions];

          // ADD ALL ADVERTISER OPTIONS
          state.advertiserOptions = [SELECT_ALL];

          // LOOP THRU ALL ACCOUNTS
          Object.values(state.accountsData).forEach((account) => {
            // CREATE BRAND DROPDOWN OPTIONS FOR EACH ACCOUNT
            const options = account.brands.reduce(
              (brandOptions: DropdownOption[], brand) => {
                if (brand.brandStatus === 1) {
                  brandOptions.push({
                    label: brand.brandName,
                    value: `${account.agency_id}-${brand.advertiserId}`,
                  });
                }
                return brandOptions;
              },
              []
            );

            // ADD BRAND DROPDOWN OPTIONS TO AVAILABLE OPTIONS
            state.advertiserOptions.push(...options);
          });
        } else {
          // ADD SELECTION TO SELECTED AGENCIES
          state.selectedAgencies.push(action.payload);

          // ADD "SELECT ALL" IF NO PRIOR ADVERTISER OPTIONS
          if (!state.advertiserOptions.length) {
            state.advertiserOptions = [SELECT_ALL];
          }

          // INCLUDE ADVERTISER OPTIONS FOR SELECTED AGENCY
          const advertiserOptions: DropdownOption[] = state.accountsData[
            agencyId
          ]?.brands.reduce((options: DropdownOption[], brand) => {
            if (brand.brandStatus === 1) {
              options.push({
                label: brand.brandName,
                value: `${agencyId}-${brand.advertiserId}`,
              });
            }
            return options;
          }, []);
          state.advertiserOptions.push(...advertiserOptions);
        }

        return state;
      }

      case SA360_CRON_ACTION_TYPES.REMOVE_SELECTED_AGENCY: {
        const agencyId = action.payload.value;

        if (agencyId === SELECT_ALL.value) {
          // REMOVE ALL SELECTED AGENCIES
          state.selectedAgencies = [];

          // REMOVE ALL SELECTED ADVERTISERS
          state.selectedAdvertisers = [];

          // REMOVE ALL ADVERTISER OPTIONS
          state.advertiserOptions = [];
        }

        // REMOVE SELECTED AGENCY
        state.selectedAgencies = state.selectedAgencies.filter(({ value }) => {
          return value !== agencyId;
        });

        // REMOVE SELECTED ADVERTISERS OF AGENCY
        // --- Note: advertiser value === `${agencyId}-${brandId}`
        state.selectedAdvertisers = state.selectedAdvertisers.filter(
          ({ value }) => {
            return value.split('-')[0] !== agencyId;
          }
        );

        // REMOVE ADVERTISER OPTIONS OF AGENCY
        // --- Note: advertiser value === `${agencyId}-${brandId}`
        state.advertiserOptions = state.advertiserOptions.filter(
          ({ value }) => {
            return value.split('-')[0] !== agencyId;
          }
        );

        return state;
      }

      case SA360_CRON_ACTION_TYPES.ADD_SELECTED_ADVERTISER:
        if (action.payload.value === SELECT_ALL.value) {
          state.selectedAdvertisers = [...state.advertiserOptions];
        } else {
          state.selectedAdvertisers.push(action.payload);
        }
        return state;

      case SA360_CRON_ACTION_TYPES.REMOVE_SELECTED_ADVERTISER:
        if (action.payload.value === SELECT_ALL.value) {
          // REMOVE ALL SELECTED ADVERTISERS
          state.selectedAdvertisers = [];
        } else {
          // REMOVE SELECTED ADVERTISER
          state.selectedAdvertisers = state.selectedAdvertisers.filter(
            ({ value }) => {
              return value !== action.payload.value;
            }
          );
        }

        return state;

      case SA360_CRON_ACTION_TYPES.SET_FLIGHT_START:
        state.flightStart = action.payload;
        return state;

      case SA360_CRON_ACTION_TYPES.SET_FLIGHT_END:
        state.flightEnd = action.payload;
        return state;

      case SA360_CRON_ACTION_TYPES.SET_TIME_FLAG:
        state.timeFlag = action.payload;
        return state;

      case SA360_CRON_ACTION_TYPES.RESET_SA360_CRON_FORM: {
        const sa360Accounts = { ...state.accountsData };
        const reportOptions = [...state.reportOptions];

        state = { ...INITIAL_SA360_CRON_FORM };
        state.accountsData = sa360Accounts;
        state.reportOptions = reportOptions;
        return state;
      }

      default:
        return state;
    }
  },
  INITIAL_SA360_CRON_FORM
);
