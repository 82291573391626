import { useActions, useAppSelector } from '../../hooks';

// UI COMPONENTS
import Fieldset from '@publicismedia-ds/ui-fieldset';
import Checkbox from '@publicismedia-ds/ui-checkbox';

function SA360SupportOptions() {
    // REDUX ACTIONS
    const {
        setDSASupport,
        setPLASupport,
        setCustomColumnSupport,
        setRevenueSupport,
    } = useActions();

    // REDUX STATE
    const {
        isDSASupport,
        isPLASupport,
        isRevenueSupport,
        isCustomColumnSupport,
    } = useAppSelector(({ editAccountForm }) => editAccountForm);

    return (
        <div className="inputs-row">
            <Fieldset layout="horizontal" className="support-options">
                {/* ENABLE DSA SUPPORT */}
                <Checkbox
                    checked={isDSASupport}
                    onChange={(
                        evt: React.ChangeEvent<HTMLInputElement>,
                        isChecked: boolean
                    ) => {
                        setDSASupport(isChecked);
                    }}
                    invert
                >
                    Enable Dynamic Search Ads
                </Checkbox>
                {/* ENABLE PLA SUPPORT */}
                <Checkbox
                    checked={isPLASupport}
                    onChange={(
                        evt: React.ChangeEvent<HTMLInputElement>,
                        isChecked: boolean
                    ) => {
                        setPLASupport(isChecked);
                    }}
                    invert
                >
                    Enable Product Listing Ads Support
                </Checkbox>

                {/* ENABLE CUSTOM REVENUE COLUMNS SUPPORT */}
                <Checkbox
                    checked={isRevenueSupport}
                    onChange={(
                        evt: React.ChangeEvent<HTMLInputElement>,
                        isChecked: boolean
                    ) => {
                        setRevenueSupport(isChecked);
                    }}
                    invert
                >
                    Enable Revenue Support
                </Checkbox>

                {/* --- DISABLE CUSTOM CONVERSION COLUMNS SUPPORT (EDITING ONLY) --- */}
                <Checkbox
                    checked={isCustomColumnSupport}
                    onChange={(
                        evt: React.ChangeEvent<HTMLInputElement>,
                        isChecked: boolean
                    ) => {
                        setCustomColumnSupport(isChecked);
                    }}
                    invert
                >
                    Enable Custom Columns
                </Checkbox>
                {/* --- </ END > --- */}
            </Fieldset>
        </div>
    );
}

export default SA360SupportOptions;
