import { PLATFORM_IDS, PLATFORM_NAMES } from '../../constants';
import {
    BingAdsAccount,
    SA360FormState,
    AddSA360Account,
    BingAdsFormState,
    GoogleAdsAccount,
    KenshooFormState,
    AddKenshooAccount,
    EngineAccountBody,
    GoogleAdsFormState,
    CustomColumnDetails,
    UpdateBingSubAccount,
    UpdateAdvertiserDetails,
    AddAccountAdvertiserData,
    UpdateGoogleSubAccount,
    ExistingAccountDetails,
    EditAccountState,
    INITIAL_EDIT_ACCOUNT_STATE,
    UpdateAccountData,
    POSTUpdateAccount,
} from '../../state';

// FORMAT POST REQUEST BODY FOR BING ADS ACCOUNT
export const formatBingAdsForm = (state: BingAdsFormState) => {
    const body: BingAdsAccount = {
        platformId: state.platformId,
        account: {
            agencyName: state.agencyName,
            bingAdsManagerEmail: state.bingAdsManagerEmail
                ? state.bingAdsManagerEmail.value
                : '',
            accountData: state.accountData.map((data) => {
                return {
                    subAccountId: data.id,
                    subAccountName: data.name,
                    accountNumber: data.number,
                    customerId: data.customerId,
                };
            }),
        },
    };
    return body;
};

// FORMAT POST REQUEST BODY FOR GOOGLE ADS ACCOUNT
const formatGoogleAdsForm = (state: GoogleAdsFormState) => {
    const body: GoogleAdsAccount = {
        platformId: state.platformId,
        account: {
            adwordsManagerEmail: state.adwordsMangerEmail
                ? state.adwordsMangerEmail.value
                : '',
            customerId: state.customerId,
            customerName: state.customerName,
            isManagerAccount: state.isManagerAccount === '1' ? 'true' : 'false',
            customerData: state.customerData.map((customer) => {
                return {
                    subCustomerId: customer.id,
                    subCustomerName: customer.name,
                };
            }),
        },
    };
    return body;
};

// RETURN FORMATTED POST REQUEST BODY FOR ADDING ENGINE ACCOUNT
type EngineAccountForm = BingAdsFormState | GoogleAdsFormState;
export const formatAddEngineAccountBody = (
    state: EngineAccountForm
): EngineAccountBody | undefined => {
    // FORMAT BINGS ADS ACCOUNT BODY
    if (state.platformId === PLATFORM_IDS.BING_ADS) {
        const body = formatBingAdsForm(state);
        return body;
    }

    // FORMAT GOOGLE ADS ACCOUNT BODY
    if (state.platformId === PLATFORM_IDS.GOOGLE_ADS) {
        const body = formatGoogleAdsForm(state);
        return body;
    }

    // RETURN UNDEFINED
    return;
};

// RETURN FORMATTED POST REQUEST BODY FOR KENSHOO ACCOUNT
export const formatAddKenshooBody = (
    state: KenshooFormState
): AddKenshooAccount => {
    return {
        platform: state.platformId,
        account: {
            accountName: state.accountName,
            ksId: state.ksId,
            profileData: [...state.profileData],
            reportData: [
                {
                    reportType: 'keyword',
                    reportId: state.keywordReportId,
                    reportName: state.keywordReportName,
                },
                {
                    reportType: 'campaign',
                    reportId: state.campaignReportId,
                    reportName: state.campaignReportName,
                },
                {
                    reportType: 'adgroup',
                    reportId: state.adgroupReportId,
                    reportName: state.adgroupReportName,
                },
            ],
        },
    };
};

export interface SA360AccountParams {
    sa360: SA360FormState;
    googleAds: GoogleAdsFormState;
    bingAds: BingAdsFormState;
}

// RETURN FORMATTED POST REQUEST BODY FOR SA360 ACCOUNT
export const formatAddSA360 = (states: SA360AccountParams): AddSA360Account => {
    const { sa360, googleAds, bingAds } = states;

    // FORMAT ADVERTISER DATA
    const advertiserData: AddAccountAdvertiserData[] =
        states.sa360.advertiserData.map((brand) => {
            return {
                advertiserName: brand.name,
                advertiserID: brand.id,
            };
        });

    // FORMAT CONVERSION COLUMN DATA
    const customColumns = sa360.customColumns
        .map((data) => data.name)
        .join(',');

    // FORMAT REVENUE COLUMN DATA
    const customRevenueColumns = sa360.customRevenueColumns
        .map((data) => data.name)
        .join(',');

    const body: AddSA360Account = {
        account: {
            advertiserData,
            agencyId: sa360.agencyId,
            agencyName: sa360.agencyName,
            customColumns,
            customRevenueColumns,
            isRevenueSupport: sa360.isRevenueSupport,
            sa360ManagerEmail: sa360.sa360ManagerEmail
                ? sa360.sa360ManagerEmail.value
                : '',
        },
        dsaSupport: sa360.dsaSupport,
        engineAccounts: [],
        isEngineAccount: sa360.googleAdsSupport || sa360.bingAdsSupport,
        plaSupport: sa360.plaSupport,
        platformId: sa360.platformId,
    };

    // ADD GOOGLE ADS DATA TO ENGINE ACCOUNTS IF PRESENT
    if (!!sa360.googleAdsSupport) {
        const data = formatAddEngineAccountBody(googleAds);
        if (data) body.engineAccounts.push(data);
    }

    // ADD BING ADS DATA TO ENGINE ACCOUNTS IF PRESENT
    if (!!sa360.bingAdsSupport) {
        const data = formatAddEngineAccountBody(bingAds);
        if (data) body.engineAccounts.push(data);
    }

    return body;
};

// FORMAT POST REQUEST EXISTING ACCOUNT
export const formatUpdateAccount = (state: EditAccountState) => {
    // FORMAT EXISTING AND ADDED ADVERTISERS (BRANDS)
    const advertisers: UpdateAdvertiserDetails[] = state.advertisers.map(
        (data) => {
            return {
                actual_adv_name: data.name,
                advertiser_id: data.id,
                brand_status: data.status ? 1 : 0,
            };
        }
    );

    // FORMAT CUSTOM CONVERSION COLUMNS
    const customColumns: CustomColumnDetails[] = state.customColumns.map(
        (data) => {
            return {
                column_name: data.name,
                column_status: data.status === true,
            };
        }
    );

    // FORMAT CUSTOM REVENUE COLUMNS
    const revenueColumns: CustomColumnDetails[] =
        state.customRevenueColumns.map((data) => {
            return {
                column_name: data.name,
                column_status: data.status === true,
            };
        });

    // FORMAT BING SUB ACCOUNTS
    const bingAdsSubAccounts: UpdateBingSubAccount[] =
        state.bingAdsSubAccounts.map((data) => {
            return {
                customer_id: data.customerId,
                sub_account_id: parseInt(data.id),
                sub_account_name: data.name,
                sub_account_number: data.number,
                sub_act_status: data.status === true,
            };
        });

    // FORMAT GOOGLE ADS SUB ACCOUNTS
    const googleAdsSubAccounts: UpdateGoogleSubAccount[] =
        state.googleAdsSubAccounts.map((data) => {
            return {
                sub_account_id: parseInt(data.id),
                sub_account_name: data.name,
                sub_act_status: data.status === true,
            };
        });

    const accountDetails: UpdateAccountData = {
        advertisers,
        customColumns,
        RevenueSupport: revenueColumns,
        accountName: state.accountName,
        agencyId: state.agencyId,
        sa360ManagerEmail: state.sa360ManagerEmail,
        bingAdsManagerEmail: state.bingAdsManagerEmail,
        bingAdsSubAccounts,
        isRevenueSupport: state.isRevenueSupport,
        isManagerAccount: state.isManagerAccount,
        adwordsManagerEmail: state.adwordsManagerEmail,
        kenshooReportDetails: [],
        googleAdsSubAccounts,
    };

    if (state.platformId === PLATFORM_IDS.SA_360) {
        // SET MANAGER CUSTOMER ID/NAME IF SA 360 ACCOUNT
        accountDetails.managerCustomerId = state.customerId;
        accountDetails.managerCustomerName = state.customerName;
    } else {
        // OTHERWISE SET CUSTOMER ID/NAME/STATUS
        accountDetails.customerId = state.customerId;
        accountDetails.customerName = state.customerName;
        accountDetails.customerStatus = state.isManagerAccount
            ? ''
            : state.customerStatus;
    }

    const body: POSTUpdateAccount = {
        platform: state.platform || PLATFORM_NAMES.SA_360,
        platformId: state.platformId,
        isDSASupport: state.isDSASupport,
        isPLASupport: state.isPLASupport,
        account: accountDetails,
    };

    return body;
};

// FORMAT POST REQUEST BODY FOR UPDATING BING ADS ACCOUNT
export const formatUpdateBingAds = (state: BingAdsFormState) => {};

// FORMAT POST REQUEST BODY FOR UPDATING GOOGLE ADS ACCOUNT
export const formatUpdateGoogleAds = (state: GoogleAdsFormState) => {};

// FORMAT POST REQUEST BODY FOR UPDATING KENSHOO ACCOUNT
export const formatUpdateKenshoo = (state: KenshooFormState) => {};

// FORMAT FETCHED ACCOUNT DATA INTO EDIT ACCOUNT FORM STATE;
export const formatEditAccountData = (account: ExistingAccountDetails) => {
    const body = { ...INITIAL_EDIT_ACCOUNT_STATE };
    body.platformId = account.platformId;
    body.platform = account.platform;
    body.accountName = account.accountName;
    body.agencyId = account.agencyId;
    body.isDSASupport = account.isDSASupport;
    body.isPLASupport = account.isPLASupport;

    // FORMAT CUSTOM CONVERSION COLUMNS DATA
    body.isCustomColumnSupport = !!account.customColumns?.length;
    body.customColumns =
        account.customColumns?.map((data) => {
            return {
                name: data.column_name,
                status: data.column_status,
                existing: true,
            };
        }) || [];

    // FORMAT CUSTOM REVENUE COLUMNS DATA
    body.isRevenueSupport = !!account.customRevenueColumns?.length;
    body.customRevenueColumns =
        account.customRevenueColumns?.map((data) => {
            return {
                name: data.column_name,
                status: data.column_status,
                existing: true,
            };
        }) || [];

    // FORMAT SA 360 ADVERTISERS
    body.sa360ManagerEmail = account.sa360ManagerEmail || '';
    body.advertisers =
        account.brands?.map((data) => {
            return {
                id: data.advertiser_id,
                name: data.actual_adv_name,
                status: data.brand_status === 1,
                existing: true,
            };
        }) || [];

    // FORMAT BING DATA
    body.isBingAdsSupport =
        !!account.bingAdsManagerEmail || !!account.bingAdsSubAccounts?.length;
    body.editIsBingAdsSupport =
        !!account.bingAdsManagerEmail || !!account.bingAdsSubAccounts?.length;
    body.bingAdsManagerEmail = account.bingAdsManagerEmail || '';
    body.bingAdsSubAccounts =
        account.bingAdsSubAccounts?.map((data) => {
            return {
                id: data.sub_account_id.toString(),
                name: data.sub_account_name,
                number: data.sub_account_number,
                customerId: data.customer_id,
                status: data.sub_act_status,
                existing: true,
            };
        }) || [];

    // FORMAT GOOGLE DATA
    body.isGoogleAdsSupport =
        !!account.adwordsManagerEmail || !!account.googleAdsSubAccounts?.length;
    body.editIsGoogleAdsSupport =
        !!account.adwordsManagerEmail || !!account.googleAdsSubAccounts?.length;
    body.adwordsManagerEmail = account.adwordsManagerEmail || '';
    body.isManagerAccount =
        account.isManagerAccount || account.platformId === PLATFORM_IDS.SA_360;
    body.editIsManagerAccount = account.isManagerAccount;

    // SET CUSTOMER DATA IF ACCOUNT PLATFORM IS GOOGLE ADS (platformId === 2)
    if (account.platformId === PLATFORM_IDS.GOOGLE_ADS) {
        if (account.isManagerAccount) {
            // GOOGLE ADS IS A MANAGER ACCOUNT
            body.customerName = account.accountName;
            body.customerId = account.agencyId;
        } else {
            // GOOGLE ADS IS NOT A MANAGER ACCOUNT
            body.customerName =
                account?.googleAdsSubAccounts?.[0].sub_account_name || '';
            body.customerId =
                account?.googleAdsSubAccounts?.[0].sub_account_id.toString() ||
                '';
            body.customerStatus =
                account?.googleAdsSubAccounts?.[0].sub_act_status || false;
        }
    }

    // SET CUSTOMER DATA IF ACCOUNT PLATFORM IS SA 360 (platformId === 1)
    if (account.platformId === PLATFORM_IDS.SA_360) {
        body.customerId = account.managerCustomerId || '';
        body.customerName = account.managerCustomerName || '';
    }
    body.googleAdsSubAccounts =
        account.googleAdsSubAccounts?.map((data) => {
            return {
                id: data.sub_account_id.toString(),
                name: data.sub_account_name,
                status: data.sub_act_status,
                existing: true,
            };
        }) || [];

    // FORMAT KENSHOO DATA
    body.kenshooReportDetails = account.kenshooReportDetails || [];

    return body;
};
