import { useEffect, useState } from 'react';
import { deleteMap } from '../../api';
import { GoalsListItem } from '../../state';
import { useActions, useAppSelector } from '../../hooks';

// UI COMPONENTS
import DataTable, {
    ColumnTemplate,
    DataProvider,
} from '@publicismedia-ds/ui-datatable';
import SearchFilter from '@publicismedia-ds/ui-search-filter';
import ViewGoalModal from '../View-Goal-Modal/View-Goal-Modal';
import EditGoalModal from '../Edit-Goal-Modal/Edit-Goal-Modal';
import ConfirmationModal from '../Confirmation-Modal/Confirmation-Modal';

// EXISTING GOALS TABLE PROPS TYPE
interface ExistingGoalsTableProps {
    goals: GoalsListItem[];
}

// TABLE ACTION BUTTON TYPES
type TableAction = 'view' | 'edit' | 'delete';

// FUNCTION COMPONENT
function ExistingGoalsTable({ goals }: ExistingGoalsTableProps) {
    // REDUX ACTIONS
    const { loadGoalsList, alertError, alertSuccess } = useActions();

    // TABLE DATA PROVIDER
    const [data, setData] = useState<any>();

    // CURRENT USER'S ID
    const user = useAppSelector(({ auth }) => auth.user);

    // VIEW GOAL MODAL DATA
    const [viewGoal, setViewGoal] = useState<GoalsListItem | null>(null);

    // DELETE MODAL GOAL DATA
    const [deleteGoal, setDeleteGoal] = useState<GoalsListItem | null>(null);

    // EDIT GOAL MODAL DATA
    const [editGoal, setEditGoal] = useState<GoalsListItem | null>(null);

    // HANDLE VIEW GOAL ACTION
    const onGoalAction = (goalId: string, action: TableAction) => {
        const goalData = goals.find((goal) => goal.goalId === goalId);

        if (!goalData) return;
        switch (action) {
            case 'delete':
                setDeleteGoal(goalData);
                return;

            case 'edit':
                setEditGoal(goalData);
                return;

            case 'view':
                setViewGoal(goalData);
                return;

            default:
                return;
        }
    };

    // CREATE DATA PROVIDER ON INITIAL PAGE LOAD
    useEffect(() => {
        const tableData = new DataProvider({ data: goals });

        setData(tableData);
    }, [goals]);

    // HANDLE SEARCH STUDIES TABLE CHANGE
    const onSearchChange = (text: string) => {
        if (!data) return;
        data.search(text, ['engineNames', 'campaignIds']);
    };

    // HANDLE DELETE GOAL ACTION
    const onDeleteGoalMapping = async (goalId: string) => {
        if (!user || !deleteGoal) return;

        try {
            // SEND DELETE GOAL MAPPING REQUEST
            await deleteMap(goalId);

            // LOAD UPDATED GOALS LIST
            loadGoalsList(user);

            // ALERT SUCCESS MESSAGE
            alertSuccess('Goal mapping has been successfully deleted.');
        } catch (error: any) {
            // ALERT ERROR MESSAGE ON ERROR
            alertError(
                error.response?.data?.errorMessage ||
                    'Error in api call. Please contact Dawrin support.'
            );
        } finally {
            setDeleteGoal(null);
        }
    };

    return (
        <>
            <DataTable
                data={data}
                bindKey="goalId"
                above={
                    <>
                        <SearchFilter
                            className="search-studies-input"
                            onSearchChange={onSearchChange}
                        />
                    </>
                }
            >
                <ColumnTemplate
                    bind="goalMappingName"
                    onRender={(text: string) => {
                        return (
                            <p className="wrap-column-text-normal">{text}</p>
                        );
                    }}
                >
                    Goal Name
                </ColumnTemplate>
                <ColumnTemplate bind="agencyName">Agency</ColumnTemplate>
                <ColumnTemplate
                    bind="advertiserName"
                    onRender={(text: string) => {
                        return (
                            <p className="wrap-column-text-break-all">{text}</p>
                        );
                    }}
                >
                    Advertiser
                </ColumnTemplate>
                <ColumnTemplate bind="reportType">Report Type</ColumnTemplate>
                <ColumnTemplate
                    bind="engineNames"
                    onRender={(text: string) => {
                        return <span>{text.split(',').length} Engine(s)</span>;
                    }}
                >
                    Engine(s)
                </ColumnTemplate>
                <ColumnTemplate
                    bind="campaignIds"
                    onRender={(ids: string) => {
                        return <span>{ids.split(',').length} Campaign(s)</span>;
                    }}
                >
                    Campaign(s)
                </ColumnTemplate>
                <ColumnTemplate
                    bind="createdAt"
                    onRender={(date: string) => {
                        return (
                            <>
                                <p className="wrap-column-text-normal">
                                    {date}
                                </p>
                            </>
                        );
                    }}
                >
                    Created On
                </ColumnTemplate>
                <ColumnTemplate
                    bind="goalId"
                    onRender={(goalId: string) => {
                        return (
                            <>
                                <button
                                    className="action-button"
                                    onClick={() => {
                                        onGoalAction(goalId, 'view');
                                    }}
                                >
                                    <i className="icon-view"></i>
                                </button>
                                <button
                                    className="action-button"
                                    onClick={() => {
                                        onGoalAction(goalId, 'edit');
                                    }}
                                >
                                    <i className="icon-edit"></i>
                                </button>
                                <button
                                    className="action-button"
                                    onClick={() => {
                                        onGoalAction(goalId, 'delete');
                                    }}
                                >
                                    <i className="icon-delete"></i>
                                </button>
                            </>
                        );
                    }}
                >
                    Actions
                </ColumnTemplate>
            </DataTable>

            {/* --- VIEW GOAL MODAL --- */}
            {!!viewGoal && (
                <ViewGoalModal
                    goal={viewGoal}
                    onClose={() => setViewGoal(null)}
                />
            )}

            {/* --- EDIT GOAL MODAL --- */}
            {!!editGoal && (
                <EditGoalModal
                    goal={editGoal}
                    onClose={() => setEditGoal(null)}
                />
            )}

            {/* --- DELETE GOAL MAPPING CONFIRMATION MODAL --- */}
            {!!deleteGoal && (
                <ConfirmationModal
                    header={`Delete: ${deleteGoal.goalMappingName}`}
                    message={
                        <div className="delete-mapping-modal-content">
                            <p
                                style={{
                                    fontSize: '1.1rem',
                                    marginTop: '2rem',
                                }}
                            >
                                Are you sure want to delete this mapping?
                            </p>
                            <h4
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                    fontStyle: 'italic',
                                }}
                            >
                                Caution: This action cannot be undone. Once
                                deleted, all historical data will be removed.
                            </h4>
                        </div>
                    }
                    onCancel={() => setDeleteGoal(null)}
                    onConfirm={() => onDeleteGoalMapping(deleteGoal.goalId)}
                />
            )}
        </>
    );
}

export default ExistingGoalsTable;
