import './Edit-Notes.scss';

import { ROUTES } from '../../router/router';
import { DEFAULT_API_ERROR } from '../../constants';
import { onPreventEnterKeySubmit } from '../../utils';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useReducer, useState } from 'react';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';

// API REQUESTS
import { editNotes } from '../../api';

// TYPES
import {
    NoteDetails,
    editNotesReducer,
    EDIT_NOTE_ACTION_TYPES,
    DEFAULT_EDIT_NOTES_STATE,
} from '../../state';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import AdminPage from '../../components/Admin-Page/Admin-Page';
import ConfirmationModal from '../../components/Confirmation-Modal/Confirmation-Modal';
import AdvertiserNotesTable from '../../components/Charts/Advertiser-Notes-Table/Advertiser-Notes-Table';

// FUNCTIONAL COMPONENT
function EditNotes() {
    // HOOKS
    const navigate = useNavigate();
    const asyncWrapper = useAsyncWrapper();
    const notes = useLocation().state.notes as NoteDetails;

    // REDUX STATE
    const userId = useAppSelector(({ auth }) => auth.user?.id);

    // REDUX ACTIONS
    const { setLoading, loadAllNotes, alertError, alertSuccess } = useActions();

    // EDIT NOTES STATE
    const [state, dispatch] = useReducer(
        editNotesReducer,
        DEFAULT_EDIT_NOTES_STATE
    );

    // NUMBER OF NOTES MARKED FOR DELETION UPON UPDATING
    const [notesToDelete, setNotesToDelete] = useState(0);

    // LOAD NOTES DATA INTO EDIT NOTES FORM STATE ON INITIAL RENDER
    useEffect(() => {
        if (!notes) return;

        dispatch({
            type: EDIT_NOTE_ACTION_TYPES.LOAD_NOTES_DATA,
            payload: notes,
        });
    }, [notes]);

    // HANDLE SUBMITTING UPDATED NOTES
    const submitEditedNotes = asyncWrapper(async () => {
        if (!notes || !state || !userId) return;

        const data = await editNotes(state, notes, parseInt(userId));

        if (data.status === 'success') {
            alertSuccess(data.msg);
            loadAllNotes(userId);

            navigate(ROUTES.NOTES);
        } else {
            alertError(data.msg);
        }
    });

    // HANDLE SUBMIT CLICK
    const onSubmit = asyncWrapper(async (evt: React.FormEvent) => {
        evt.preventDefault();
        if (!notes || !state || !userId) return;

        // CHECK FOR NOTES MARKED FOR DELETION
        let markDeleted = 0;

        for (const note of state.notesData) {
            if (note.deleteFlag) markDeleted++;
        }

        // DISPLAY CONFIRMATION MODAL IF ANY NOTES ARE MAKED FOR DELETION
        if (markDeleted > 0) {
            setNotesToDelete(markDeleted);
        } else {
            await submitEditedNotes();
        }
    });

    return (
        <AdminPage header="edit notes">
            <div className="edit-notes-form-container">
                <form
                    onSubmit={onSubmit}
                    onKeyDown={onPreventEnterKeySubmit}
                    className="edit-notes-form"
                >
                    <p className="note-info">
                        <strong>Agency Name: </strong>
                        {notes.agencyName}
                    </p>
                    <p className="note-info">
                        <strong>Advertiser Name: </strong>
                        {notes.advertiserName}
                    </p>
                    <div className="edit-notes-form-table">
                        <AdvertiserNotesTable
                            data={state.notesData}
                            dispatch={dispatch}
                        />
                    </div>
                    <div className="form-buttons">
                        <Button
                            onClick={() => navigate(ROUTES.NOTES)}
                            display="secondary"
                        >
                            Cancel
                        </Button>
                        <Button type="submit">Update</Button>
                    </div>
                </form>
            </div>
            {notesToDelete > 0 && (
                <ConfirmationModal
                    header="delete notes"
                    message={
                        <>
                            <p style={{ fontSize: '1rem' }}>
                                <strong>{notesToDelete}</strong> note
                                {notesToDelete > 1 ? 's are' : ' is'} marked to
                                be permanently deleted.
                            </p>
                            <p style={{ fontSize: '1rem' }}>
                                Do you want to conitune?
                            </p>
                        </>
                    }
                    onCancel={() => setNotesToDelete(0)}
                    onConfirm={submitEditedNotes}
                />
            )}
        </AdminPage>
    );
}

export default EditNotes;
