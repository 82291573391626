import { DropdownOption, ExistingAccount } from '../../models';

export interface MarinCronFormState {
    accountsData: { [key: string]: ExistingAccount };
    agencyOptions: DropdownOption[];
    subAccountOptions: DropdownOption[];
    accountId: string;
    brrandId: string;
    reportType: DropdownOption | '';
    selectedAgency: DropdownOption | '';
    selectedSubAccount: DropdownOption | '';
}

export const INITIAL_MARIN_CRON_FORM: MarinCronFormState = {
    reportType: '',
    selectedAgency: '',
    selectedSubAccount: '',
    accountId: '',
    brrandId: '',
    accountsData: {},
    agencyOptions: [],
    subAccountOptions: [],
};

export enum MARIN_CRON_ACTION_TYPES {
    LOAD_ACCOUNTS_DATA = 'load_accounts_data',
    SELECT_REPORT_TYPE = 'set_report_type',
    SELECT_AGENCY = 'select_agency',
    SELECT_SUB_ACCOUNT = 'select_sub_account',
    RESET_MARIN_CRON_FORM = 'reset_marin_cron_form',
}

interface LoadAccountsDataAction {
    type: MARIN_CRON_ACTION_TYPES.LOAD_ACCOUNTS_DATA;
    payload: ExistingAccount[];
}

interface SelectReportTypeAction {
    type: MARIN_CRON_ACTION_TYPES.SELECT_REPORT_TYPE;
    payload: DropdownOption;
}

interface SelectAgencyAction {
    type: MARIN_CRON_ACTION_TYPES.SELECT_AGENCY;
    payload: DropdownOption;
}

interface SelectSubAccountAction {
    type: MARIN_CRON_ACTION_TYPES.SELECT_SUB_ACCOUNT;
    payload: DropdownOption;
}

interface ResetMarinCronFormAction {
    type: MARIN_CRON_ACTION_TYPES.RESET_MARIN_CRON_FORM;
}

export type MarinCronFormAction =
    | LoadAccountsDataAction
    | SelectReportTypeAction
    | SelectAgencyAction
    | SelectSubAccountAction
    | ResetMarinCronFormAction;
