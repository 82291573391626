import { ExistingAccountDetails } from '../models';
import {
    LoadAccountDataAction,
    SetDSASupportAction,
    SetPLASupportAction,
    SetCustomColumnsSupportAction,
    AddCustomColumnAction,
    UpdateCustomColumnAction,
    RemoveCustomColumnAction,
    SetRevenueSupportAction,
    AddCustomRevenueAction,
    UpdateCustomRevenueAction,
    RemoveCustomRevenueAction,
    SetGoogleAdsSupportAction,
    SetBingAdsSupportAction,
    SetSA360ManagerEmailAction,
    AddSA360AdvertiserAction,
    UpdateSA360AdvertiserAction,
    RemoveSA360AdvertiserAction,
    SetBingManagerEmailAction,
    AddBingSubAccountAction,
    UpdateBingSubAccountAction,
    RemoveBingSubAccountAction,
    SetGoolgeManagerEmailAction,
    SetIsManagerAccountAction,
    SetCustomerStatusAction,
    AddGoogleSubAccountAction,
    UpdateGoogleSubAccountAction,
    RemoveGoogleSubAccountAction,
    UpdateKenshooReportAction,
    UpdateCustomColumnPayload,
    UpdateGoogleSubAccountPayload,
    SetCustomerIdAction,
    SetCustomerNameAction,
} from '../actions';
import { EDIT_ACCOUNT_ACTION_TYPES } from '../action-types';
import {
    BingAdsSubAccountPayload,
    UpdateSA360AdvertiserPayload,
    UpdateSubCustomerPayload,
} from '../forms';

// SET DSA SUPPORT VALUE
export const setDSASupport = (isChecked: boolean): SetDSASupportAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.SET_DSA_SUPPORT,
        payload: isChecked,
    };
};

// SET GOOLGE ADS SUPPORT
export const setGoogleAdsSupport = (
    isChecked: boolean
): SetGoogleAdsSupportAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.SET_GOOGLE_SUPPORT,
        payload: isChecked,
    };
};

// SET BING ADS SUPPORT
export const setBingAdsSupport = (
    isChecked: boolean
): SetBingAdsSupportAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.SET_BING_SUPPORT,
        paylaod: isChecked,
    };
};

// SET PLA SUPPORT VALUE
export const setPLASupport = (isChecked: boolean): SetPLASupportAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.SET_PLA_SUPPORT,
        payload: isChecked,
    };
};

// SET CUSTOMER ID (GOOGLE ADS ACCOUNT PLATFORM ONLY)
export const setCustomerId = (id: string): SetCustomerIdAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.SET_CUSTOMER_ID,
        payload: id,
    };
};

// SET CUSTOMER NAME (GOOGLE ADS ACCOUNT PLATFORM ONLY)
export const setCustomerName = (name: string): SetCustomerNameAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.SET_CUSTOMER_NAME,
        payload: name,
    };
};

// SET CUSTOMER STATUS
export const setCustomerStatus = (
    isEnabled: boolean
): SetCustomerStatusAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.SET_CUSTOMER_STATUS,
        paylaod: isEnabled,
    };
};

// SET ADD ADDITIONAL CUSTOM CONVERSION COLUMN
export const addCustomColumn = (): AddCustomColumnAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.ADD_CUSTOM_COLUMN,
    };
};

// UPDATE CUSTOM CONVERSION COLUMN
export const updateCustomColumn = (
    payload: UpdateCustomColumnPayload
): UpdateCustomColumnAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.UPDATE_CUSTOM_COLUMN,
        payload,
    };
};

// REMOVE CUSTOM CONVERSION COLUMN
export const removeCustomColumn = (index: number): RemoveCustomColumnAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.REMOVE_CUSTOM_COLUMN,
        payload: index,
    };
};

// SET CUSTOM CONVERSION COLUMNS SUPPORT
export const setCustomColumnSupport = (
    isChecked: boolean
): SetCustomColumnsSupportAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.SET_CUSTOM_COLUMNS_SUPPORT,
        payload: isChecked,
    };
};

// SET CUSTOM REVENUE COLUMNS SUPPORT
export const setRevenueSupport = (
    isChecked: boolean
): SetRevenueSupportAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.SET_REVENUE_SUPPORT,
        payload: isChecked,
    };
};

// ADD CUSTOM REVENUE COLUMN
export const addCustomRevenue = (): AddCustomRevenueAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.ADD_REVENUE_COLUMN,
    };
};

// UPDATE CUSTOM REVENUE COLUMN
export const updateCustomRevenue = (
    payload: UpdateCustomColumnPayload
): UpdateCustomRevenueAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.UPDATE_REVENUE_COLUMN,
        payload,
    };
};

// REMOVE CUSTOM REVENUE COLUMN
export const removeCustomRevenue = (
    index: number
): RemoveCustomRevenueAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.REMOVE_REVENUE_COLUMN,
        payload: index,
    };
};

// SET SA 360 MANAGER EMAIL
export const setSA360Email = (email: string): SetSA360ManagerEmailAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.SET_SA360_MANAGER_EMAIL,
        payload: email,
    };
};

// ADD SA 360 ADVERTISER
export const addSA360Advertiser = (): AddSA360AdvertiserAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.ADD_SA360_ADVERTISER,
    };
};

// UPDATE SA 360 ADVERTISER
export const updateSA360Advertiser = (
    payload: UpdateSA360AdvertiserPayload
): UpdateSA360AdvertiserAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.UPDATE_SA360_ADVERTISER,
        payload,
    };
};

// REMOVE SA 360 ADVERTISER
export const removeSA360Advertiser = (
    index: number
): RemoveSA360AdvertiserAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.REMOVE_SA360_ADVERTISER,
        payload: index,
    };
};

// SET BING ADS MANAGER EMAIL
export const setBingAdsEmail = (email: string): SetBingManagerEmailAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.SET_BING_MANAGER_EMAIL,
        payload: email,
    };
};

// ADD BING ADS SUB ACCOUNT
export const addBingSubAccount = (): AddBingSubAccountAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.ADD_BING_SUB_ACCOUNT,
    };
};

// UPDATE BING ADS SUB ACCOUNT
export const updateBingSubAccount = (
    payload: BingAdsSubAccountPayload
): UpdateBingSubAccountAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.UPDATE_BING_SUB_ACCOUNT,
        payload,
    };
};

// REMOVE BING ADS SUB ACCOUNT
export const removeBingSubAccount = (
    index: number
): RemoveBingSubAccountAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.REMOVE_BING_SUB_ACCOUNT,
        payload: index,
    };
};

// SET GOOGLE ADS MANAGER EMAIL
export const setGoogleEmail = (email: string): SetGoolgeManagerEmailAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.SET_GOOGLE_MANAGER_EMAIL,
        payload: email,
    };
};

// SET IS GOOGLE ADS MANAGER ACCOUNT
export const setIsManagerAccount = (
    isChecked: boolean
): SetIsManagerAccountAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.SET_IS_MANAGER_ACCOUNT,
        paylaod: isChecked,
    };
};

// ADD GOOGLE SUB ACCOUNT
export const addGoolgeSubAccount = (): AddGoogleSubAccountAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.ADD_GOOGLE_SUB_ACCOUNT,
    };
};

// UPDATE GOOGLE SUB ACCOUNT
export const updateGoogleSubAccount = (
    payload: UpdateGoogleSubAccountPayload
): UpdateGoogleSubAccountAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.UPDATE_GOOGLE_SUB_ACCOUNT,
        payload,
    };
};

// REMOVE GOOGLE SUB ACCOUNT
export const removeGoogleSubAccount = (
    index: number
): RemoveGoogleSubAccountAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.REMOVE_GOOGLE_SUB_ACCOUNT,
        payload: index,
    };
};

// UPDATE KENSHOO REPORT DETAILS
export const updateKenshooReport = (
    index: number,
    id: string
): UpdateKenshooReportAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.UPDATE_KENSHOO_REPORT,
        payload: {
            index,
            id,
        },
    };
};

// LOAD EXISTING ACCOUNT DATA
export const loadExistingAccount = (
    account: ExistingAccountDetails
): LoadAccountDataAction => {
    return {
        type: EDIT_ACCOUNT_ACTION_TYPES.LOAD_ACCOUNT_DATA,
        payload: account,
    };
};
