import produce from 'immer';
import {
  KenshooFormState,
  KenshooFormAction,
  KENSHOO_ACTION_TYPES,
  INITIAL_KENSHOO_FORM,
  INITIAL_KENSHOO_PROFILE,
} from '../form-models';

export const kenshooFormReducer = produce(
  (
    state: KenshooFormState = INITIAL_KENSHOO_FORM,
    action: KenshooFormAction
  ): KenshooFormState => {
    switch (action.type) {
      case KENSHOO_ACTION_TYPES.SET_ACCOUNT_NAME:
        state.accountName = action.payload;
        return state;

      case KENSHOO_ACTION_TYPES.SET_KS_ID:
        state.ksId = action.payload;
        return state;

      case KENSHOO_ACTION_TYPES.SET_KEYWORD_REPORT_ID:
        state.keywordReportId = action.payload;
        return state;

      case KENSHOO_ACTION_TYPES.SET_KEYWORD_REPORT_NAME:
        state.keywordReportName = action.payload;
        return state;

      case KENSHOO_ACTION_TYPES.SET_CAMPAIGN_REPORT_ID:
        state.campaignReportId = action.payload;
        return state;

      case KENSHOO_ACTION_TYPES.SET_CAMPAIGN_REPORT_NAME:
        state.campaignReportName = action.payload;
        return state;

      case KENSHOO_ACTION_TYPES.SET_ADGROUP_REPORT_ID:
        state.adgroupReportId = action.payload;
        return state;

      case KENSHOO_ACTION_TYPES.SET_ADGROUP_REPORT_NAME:
        state.adgroupReportName = action.payload;
        return state;

      case KENSHOO_ACTION_TYPES.ADD_PROFILE:
        state.profileData.push({ ...INITIAL_KENSHOO_PROFILE });
        return state;

      case KENSHOO_ACTION_TYPES.SET_PROFILE_ID:
        state.profileData[action.payload.index].profileId =
          action.payload.value;
        return state;

      case KENSHOO_ACTION_TYPES.SET_PROFILE_NAME:
        state.profileData[action.payload.index].profileName =
          action.payload.value;
        return state;

      case KENSHOO_ACTION_TYPES.REMOVE_PROFILE:
        state.profileData = state.profileData.filter((data, index) => {
          return index !== action.payload;
        });
        return state;

      case KENSHOO_ACTION_TYPES.RESET_KENSHOO_FORM:
        state = { ...INITIAL_KENSHOO_FORM };
        return state;

      default:
        return state;
    }
  },
  INITIAL_KENSHOO_FORM
);
