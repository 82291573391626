import { useActions, useAppSelector } from '../../hooks';

// UI COMPONENTS
import Textbox from '@publicismedia-ds/ui-textbox';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import ProductSettings from './Product-Settings-Container';

function PerformanceMonitorSettings() {
    // UNIVERSAL SETTINGS STATE (REDUX)
    const settings = useAppSelector((state) => state.universalSettings);

    // REDUX ACTIONS
    const { updateLastNWeeks } = useActions();

    // ENABLE/DISABLE "APPLY BUTTON"
    const disableApply =
        !settings.goalId ||
        !settings.budget ||
        !settings.flightStart ||
        !settings.flightEnd ||
        !settings.conversionType ||
        !settings.conversionColumn ||
        (settings.conversionType === 'Revenue' && !settings.revenueColumn) ||
        !settings.efficiencyNumerator ||
        !settings.efficiencyDenominator ||
        !settings.efficiencyTarget ||
        new Date(settings.flightEnd) < new Date(new Date().toDateString()) ||
        !settings.dpadLastNWeeks;

    return (
        <ProductSettings disabled={disableApply}>
            <>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <h3>Performance Monitor Settings</h3>
                </div>
                <Row cols={4}>
                    {/* SETTINGS INPUT TO UPDATE LASTNWEEKS */}
                    <Column>
                        <Textbox
                            type="number"
                            min="1"
                            max="100"
                            value={settings.dpadLastNWeeks || ''}
                            onChange={(
                                evt: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                updateLastNWeeks(parseInt(evt.target.value));
                            }}
                            required
                            error="Please enter a number greater than zero"
                        >
                            Last number of weeks
                        </Textbox>
                    </Column>
                </Row>
            </>
        </ProductSettings>
    );
}

export default PerformanceMonitorSettings;
