import produce from 'immer';
import { selectMatchingCampaigns } from '../../../utils';
import { DropdownOption } from '../../models';
import {
  EditGoalForm,
  EditGoalFormAction,
  EDIT_GOAL_FORM_TYPES,
  INITIAL_EDIT_GOAL_FORM,
} from '../form-models';
import {
  CampaignDetails,
  EngineDetails,
} from '../../models/bid-headroom-scan-models';

export const editGoalFormReducer = produce(
  (
    state: EditGoalForm = INITIAL_EDIT_GOAL_FORM,
    action: EditGoalFormAction
  ): EditGoalForm => {
    switch (action.type) {
      case EDIT_GOAL_FORM_TYPES.SET_NAME:
        state.name = action.payload;
        return state;

      case EDIT_GOAL_FORM_TYPES.SET_ADVERTISER: {
        // SET ADVERTISER DATA
        state.advertiser = action.payload;

        // CREATE ALL AVAILABLE ENGINES OBJECT
        state.allEngines =
          action.payload?.engines.reduce(
            (data: { [key: string]: EngineDetails }, engine) => {
              data[engine.engineId] = engine;
              return data;
            },
            {}
          ) || {};

        return state;
      }

      case EDIT_GOAL_FORM_TYPES.ADD_SELECTED_ENGINE:
        // CHECK FOR SELECT ALL ENGINES
        if (action.payload.value === 'all') {
          // CREATE DROPDOWN OPTIONS FOR ALL ENGINES
          const allEngineOptions = Object.values(state.allEngines).map(
            (engine): DropdownOption => ({
              label: engine.engineName,
              value: engine.engineId,
            })
          );

          // SET ALL SELECTED ENGINES
          state.selectedEngines = [action.payload, ...allEngineOptions];
        } else {
          // ADD SELECTED ENGINE OPTION
          state.selectedEngines.push(action.payload);
        }
        return state;

      case EDIT_GOAL_FORM_TYPES.REMOVE_SELECTED_ENGINE:
        if (action.payload.value === 'all') {
          // REMOVE ALL SELECTED ENGINES
          state.selectedEngines = [];
          state.selectedCampaigns = [];
        } else {
          // FILTER OUT DE-SELECTED ENGINE
          state.selectedEngines = state.selectedEngines.filter(
            (id) => id !== action.payload
          );

          // ONLY RUN IF CAMPAIGNS HAVE BEEN PREVIOUS SELECTED SELECTED
          if (state.selectedCampaigns.length) {
            // REMOVE SELECTED CAMPAIGNS OF DE-SELECTED ENGINE
            const engineData = state.allEngines[action.payload.value];

            // CREATE OBJECT OF ENGINE'S ASSOCIATED CAMPAIGN IDs
            const removeEngineCampaigns = engineData.campaigns.reduce(
              (campsObj: { [key: string]: string }, camp) => {
                campsObj[camp.campaignId] = camp.campaignName;
                return campsObj;
              },
              {}
            );

            // FILTER OUT ANY SELECTED CAMPAIGNS THAT EXIST IN DE-SELECTED ENGINE'S CAMPAIGN ID OBJECT
            const filteredCampaigns = state.selectedCampaigns
              .filter(({ value }) => removeEngineCampaigns[value] === undefined)
              .filter(({ value }) => value !== 'all');

            // UPDATE SELECTED CAMPAIGNS
            state.selectedCampaigns = filteredCampaigns;
          }
        }
        return state;

      case EDIT_GOAL_FORM_TYPES.SET_SYNC_GOAL:
        state.syncGoal = action.payload;
        return state;

      case EDIT_GOAL_FORM_TYPES.ADD_SELECTED_CAMPAIGN:
        if (action.payload.value === 'all') {
          // CREATE OBJECT ALL AVAILABLE CAMPAIGNS BASED ON SELECTED ENGINES
          const availableCampaigns = state.selectedEngines.reduce(
            (camps: CampaignDetails[], { value }) => {
              if (state.allEngines[value]) {
                camps.push(...state.allEngines[value].campaigns);
              }
              return camps;
            },
            []
          );

          // CREATE DROPDOWN OPTIONS FOR ALL CAMPAIGNS TO BE SELECTED
          const allCampaignOptions = availableCampaigns.map((campaign) => ({
            label: campaign.campaignName,
            value: campaign.campaignId,
          }));

          // SET ALL SELECTED CAMPAINGS
          state.selectedCampaigns = [action.payload, ...allCampaignOptions];
        } else {
          // ADD SELECTED CAMPAIGN
          state.selectedCampaigns.push(action.payload);
        }
        return state;

      case EDIT_GOAL_FORM_TYPES.REMOVE_SELECTED_CAMPAIGN:
        if (action.payload.value === 'all') {
          // REMOVE ALL SELECTED CAMPAIGNS
          state.selectedCampaigns = [];
        } else {
          // FILTER OUT DE-SELECTED CAMPAIGN
          state.selectedCampaigns = state.selectedCampaigns.filter(
            (campaignId) => campaignId !== action.payload
          );
        }
        return state;

      case EDIT_GOAL_FORM_TYPES.UPLOAD_CAMPAIGN_IDS:
        state.selectedCampaigns = action.payload;
        return state;

      case EDIT_GOAL_FORM_TYPES.SET_AUTO_ADD_360:
        state.autoAdd360 = action.payload;
        return state;

      case EDIT_GOAL_FORM_TYPES.SET_MIN_BID:
        state.minBid = action.payload;
        return state;

      case EDIT_GOAL_FORM_TYPES.SET_MAX_BID:
        state.maxBid = action.payload;
        return state;

      case EDIT_GOAL_FORM_TYPES.SET_MIN_DAILY_BUDGET:
        state.minDailyBudget = action.payload;
        return state;

      case EDIT_GOAL_FORM_TYPES.SET_MAX_DAILY_BUDGET:
        state.maxDailyBudget = action.payload;
        return state;

      case EDIT_GOAL_FORM_TYPES.UPLOAD_EXCLUDED_CAMPAIGNS:
        state.excludedCampaigns = action.payload;
        return state;

      case EDIT_GOAL_FORM_TYPES.UPLOAD_EXCLUDED_KEYWORDS:
        state.excludedKeywords = action.payload;
        return state;

      case EDIT_GOAL_FORM_TYPES.SET_IS_INTRADAY:
        state.isIntraday = action.payload;
        return state;

      case EDIT_GOAL_FORM_TYPES.ADD_CAMPAIGN_FILTER: {
        // ADD NEW FILTER TO EXISTING FILTERS ARRAY
        state.campaignFilters.push(action.payload.filter);

        // SET SELECTED CAMPAIGNS MATCHING FITLER VALUES
        state.selectedCampaigns = selectMatchingCampaigns(
          action.payload.campaignOptions,
          state.campaignFilters
        );

        return state;
      }

      case EDIT_GOAL_FORM_TYPES.REMOVE_CAMPAIGN_FILTER:
        // REMOVE SELECTED FILTER
        state.campaignFilters = state.campaignFilters.filter(
          (campFilter) => campFilter.value !== action.payload.filter.value
        );
        // SET AND/OR FOR FIRST FILTER TO EMPTY STRING IN CASE PRIOR FIRST FILTER WAS REMOVED
        if (state.campaignFilters[0]) {
          state.campaignFilters[0].andOr = '';
        }

        // SET SELECTED CAMPAIGNS MATCHING UPDATED FILTERS
        state.selectedCampaigns = selectMatchingCampaigns(
          action.payload.campaignOptions,
          state.campaignFilters
        );

        return state;

      case EDIT_GOAL_FORM_TYPES.RESET_EDIT_GOAL_FORM:
        state = { ...INITIAL_EDIT_GOAL_FORM };
        return state;

      default:
        return state;
    }
  },
  INITIAL_EDIT_GOAL_FORM
);
