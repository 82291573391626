import produce from 'immer';
import { selectMatchingCampaigns } from '../../../utils/admin-utils/add-goal-utils';
import {
  AddGoalForm,
  AddGoalFormAction,
  ADD_GOAL_FORM_TYPES,
  INITIAL_ADD_GOAL_FORM,
} from '../form-models';
import {
  CampaignDetails,
  EngineDetails,
  GETAccountData,
} from '../../models/bid-headroom-scan-models';

export const addGoalFormReducer = produce(
  (
    state: AddGoalForm = INITIAL_ADD_GOAL_FORM,
    action: AddGoalFormAction
  ): AddGoalForm => {
    switch (action.type) {
      case ADD_GOAL_FORM_TYPES.SET_NAME:
        state.name = action.payload;
        return state;

      case ADD_GOAL_FORM_TYPES.SET_AGENCY: {
        const tempName = state.name;
        state = { ...INITIAL_ADD_GOAL_FORM };
        state.name = tempName;
        state.agency = action.payload;

        return state;
      }

      case ADD_GOAL_FORM_TYPES.SET_ADVERTISER: {
        const tempName = state.name;
        const tempAgency = { ...state.agency } as GETAccountData | null;
        state = { ...INITIAL_ADD_GOAL_FORM };
        state.name = tempName;
        state.agency = tempAgency;
        state.advertiser = action.payload;
        state.allEngines =
          action.payload?.engines.reduce(
            (engines: { [key: string]: EngineDetails }, engine) => {
              engines[engine.engineId] = engine;
              return engines;
            },
            {}
          ) || {};

        return state;
      }

      case ADD_GOAL_FORM_TYPES.ADD_SELECTED_ENGINE:
        // CHECK FOR SELECT ALL ENGINES
        if (action.payload.value === 'all') {
          const allEngineOptions = Object.values(state.allEngines).map(
            (engine) => ({
              label: engine.engineName,
              value: engine.engineId,
            })
          );
          state.selectedEngines = [action.payload, ...allEngineOptions];
        } else {
          // ADD SELECTED ENGINE OPTION
          state.selectedEngines.push(action.payload);
        }
        return state;

      case ADD_GOAL_FORM_TYPES.REMOVE_SELECTED_ENGINE:
        if (action.payload.value === 'all') {
          // REMOVE ALL SELECTED ENGINES
          state.selectedEngines = [];
          state.selectedCampaigns = [];
        } else {
          // FILTER OUT DE-SELECTED ENGINE
          state.selectedEngines = state.selectedEngines.filter(
            (option) => option.value !== action.payload.value
          );

          // ONLY RUN IF CAMPAIGNS HAVE BEEN SELECTED
          if (state.selectedCampaigns.length) {
            // REMOVE SELECTED CAMPAIGNS OF DE-SELECTED ENGINE
            const engineData = state.allEngines[action.payload.value];
            // CREATE OBJECT OF ENGINE'S ASSOCIATED CAMPAIGN IDs
            const removeEngineCampaigns = engineData.campaigns.reduce(
              (campsObj: { [key: string]: string }, camp) => {
                campsObj[camp.campaignId] = camp.campaignName;
                return campsObj;
              },
              {}
            );
            // FILTER OUT ANY SELECTED CAMPAIGNS THAT EXIST IN DE-SELECTED ENGINE'S CAMPAIGN ID OBJECT
            const filteredCampaigns = state.selectedCampaigns
              .filter(
                (campaign) =>
                  removeEngineCampaigns[campaign.value] === undefined
              )
              .filter((option) => option.value !== 'all');
            // UPDATE SELECTED CAMPAIGNS
            state.selectedCampaigns = filteredCampaigns;
          }
        }
        return state;

      case ADD_GOAL_FORM_TYPES.SET_REPORT_TYPE:
        state.reportType = action.payload;
        return state;

      case ADD_GOAL_FORM_TYPES.SET_SYNC_GOAL:
        state.syncGoal = action.payload;
        return state;

      case ADD_GOAL_FORM_TYPES.ADD_SELECTED_CAMPAIGN:
        if (action.payload.value === 'all') {
          // SET ALL SELECTED ENGINE CAMPAIGNS AS SELECTED
          const allCampaignOptions = state.selectedEngines
            .reduce((camps: CampaignDetails[], { value }) => {
              if (state.allEngines[value]) {
                camps.push(...state.allEngines[value].campaigns);
              }
              return camps;
            }, [])
            .map((campaign) => ({
              label: campaign.campaignName,
              value: campaign.campaignId,
            }));
          state.selectedCampaigns = [action.payload, ...allCampaignOptions];
        } else {
          // ADD SELECTED CAMPAIGN
          state.selectedCampaigns.push(action.payload);
        }
        return state;

      case ADD_GOAL_FORM_TYPES.REMOVE_SELECTED_CAMPAIGN:
        if (action.payload.value === 'all') {
          // REMOVE ALL SELECTED CAMPAIGNS
          state.selectedCampaigns = [];
        } else {
          // FILTER OUT DE-SELECTED CAMPAIGN
          state.selectedCampaigns = state.selectedCampaigns.filter(
            (option) => option.value !== action.payload.value
          );
        }
        return state;

      case ADD_GOAL_FORM_TYPES.UPLOAD_CAMPAIGN_IDS:
        state.selectedCampaigns = action.payload;
        return state;

      case ADD_GOAL_FORM_TYPES.SET_AUTO_ADD_360:
        state.autoAdd360 = action.payload;
        return state;

      case ADD_GOAL_FORM_TYPES.SET_MIN_BID:
        state.minBid = action.payload;
        return state;

      case ADD_GOAL_FORM_TYPES.SET_MAX_BID:
        state.maxBid = action.payload;
        return state;

      case ADD_GOAL_FORM_TYPES.SET_MIN_DAILY_BUDGET:
        state.minDailyBudget = action.payload;
        return state;

      case ADD_GOAL_FORM_TYPES.SET_MAX_DAILY_BUDGET:
        state.maxDailyBudget = action.payload;
        return state;

      case ADD_GOAL_FORM_TYPES.UPLOAD_EXCLUDED_CAMPAIGNS:
        state.excludedCampaigns = action.payload;
        return state;

      case ADD_GOAL_FORM_TYPES.UPLOAD_EXCLUDED_KEYWORDS:
        state.excludedKeywords = action.payload;
        return state;

      case ADD_GOAL_FORM_TYPES.SET_USE_FILTER:
        state.useFilter = action.payload;
        return state;

      case ADD_GOAL_FORM_TYPES.SET_IS_INTRADAY:
        state.isIntraday = action.payload;
        return state;

      case ADD_GOAL_FORM_TYPES.ADD_CAMPAIGN_FILTER: {
        // ADD NEW FILTER TO EXISTING FILTERS ARRAY
        state.campaignFilters.push(action.payload.filter);

        // SET SELECTED CAMPAIGNS MATCHING FITLER VALUES
        state.selectedCampaigns = selectMatchingCampaigns(
          action.payload.campaignOptions,
          state.campaignFilters
        );

        return state;
      }

      case ADD_GOAL_FORM_TYPES.REMOVE_CAMPAIGN_FILTER:
        // REMOVE SELECTED FILTER
        state.campaignFilters = state.campaignFilters.filter(
          (campFilter) => campFilter.value !== action.payload.filter.value
        );
        // SET AND/OR FOR FIRST FILTER TO EMPTY STRING IN CASE PRIOR FIRST FILTER WAS REMOVED
        if (state.campaignFilters[0]) {
          state.campaignFilters[0].andOr = '';
        }

        // SET SELECTED CAMPAIGNS MATCHING UPDATED FILTERS
        state.selectedCampaigns = selectMatchingCampaigns(
          action.payload.campaignOptions,
          state.campaignFilters
        );
        return state;

      case ADD_GOAL_FORM_TYPES.RESET_ADD_GOAL_FORM:
        state = { ...INITIAL_ADD_GOAL_FORM };
        return state;

      default:
        return state;
    }
  },
  INITIAL_ADD_GOAL_FORM
);
