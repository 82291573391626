// TYPES
import {
    KeywordMetric,
    KeywordMoMItem,
    KeywordYoYItem,
    DropdownOption,
    FormulaColumnItem,
    FormulaColumnDetails,
    FormattedPerformanceData,
} from '../state';

// UTILS
import { capitalize, numberWithCommas } from '.';

export const formatMOMData = (
    data: KeywordMoMItem[],
    metric: KeywordMetric
) => {
    return data.map((keyword) => {
        const isDollars =
            metric === 'AvgCPC' || metric === 'Cost' || metric === 'Efficiency';
        const priorValue = numberWithCommas(
            keyword.last_to_last_month_data,
            isDollars ? 2 : 0
        );
        const recentValue = numberWithCommas(
            keyword.last_month_data,
            isDollars ? 2 : 0
        );
        const output = {
            keywordId: keyword.keyword_id,
            keywordName: keyword.keyword_name,
            prior: isDollars ? `$${priorValue}` : priorValue,
            recent: isDollars ? `$${recentValue}` : recentValue,
            difference: numberWithCommas(keyword.percent_diff_data) + '%',
        };
        if (metric === 'CTR') {
            output.prior = output.prior + '%';
            output.recent = output.recent + '%';
        }

        return output;
    });
};

export const formatYOYData = (
    data: KeywordYoYItem[],
    metric: KeywordMetric
) => {
    const formattedData: FormattedPerformanceData[] = data.map((keyword) => {
        const isDollars =
            metric === 'AvgCPC' || metric === 'Cost' || metric === 'Efficiency';
        const priorValue = numberWithCommas(
            keyword.last_to_last_year_data,
            isDollars ? 2 : 0
        );
        const recentValue = numberWithCommas(
            keyword.last_year_data,
            isDollars ? 2 : 0
        );
        const output = {
            keywordId: keyword.keyword_id,
            keywordName: keyword.keyword_name,
            prior: isDollars ? `$${priorValue}` : priorValue,
            recent: isDollars ? `$${recentValue}` : recentValue,
            difference: numberWithCommas(keyword.percent_diff_data) + '%',
        };
        if (metric === 'CTR') {
            output.prior = output.prior + '%';
            output.recent = output.recent + '%';
        }

        return output;
    });
    return formattedData;
};

export const validateFormulaColumn = (
    state: FormulaColumnItem,
    existingFormulas: FormulaColumnItem[]
) => {
    if (!state.columnName) {
        throw new Error('Column name is required');
    }

    if (!state.columnOne) {
        throw new Error('Column 1 is required.');
    }

    if (!state.operator) {
        throw new Error('Formula operator is required.');
    }

    if (!state.columnTwo) {
        throw new Error('Column 2 is required.');
    }

    for (const formula of existingFormulas) {
        // CHECK FOR DUPLICATE COLUMN NAME
        if (
            state.columnName.toLowerCase() === formula.columnName.toLowerCase()
        ) {
            throw new Error('Duplicate "Column Name" is not allowed');
        }

        // CHECK FOR DUPLICATE FORMULA
        if (
            state.columnOne.value.toLowerCase() ===
                formula.columnOne?.value.toLowerCase() &&
            state.operator.value.toLowerCase() ===
                formula.operator?.value.toLowerCase() &&
            state.columnTwo.value.toLowerCase() ===
                formula.columnTwo?.value.toLowerCase()
        ) {
            throw new Error('Duplicate formula detected.');
        }
    }
    return true;
};

export const formatFormulaColumns = (formulas: FormulaColumnDetails[]) => {
    const output: FormulaColumnItem[] = [];
    for (const formula of formulas) {
        output.push({
            columnName: formula.columnName,
            columnOne: {
                label: capitalize(formula.column1),
                value: formula.column1,
            },
            columnTwo: {
                label: capitalize(formula.column2),
                value: formula.column2,
            },
            operator: { label: formula.operator, value: formula.operator },
            checked: false,
        });
    }
    return output;
};

export const validateSelectedColumns = (
    formulas: FormulaColumnItem[],
    selectedColumns: DropdownOption[]
) => {
    const selected = selectedColumns.reduce(
        (obj: { [key: string]: string }, { label, value }) => {
            obj[value] = label;
            return obj;
        },
        {}
    );
    for (const formula of formulas) {
        const { columnOne, columnTwo } = formula;

        if (columnOne && !selected[columnOne.value]) {
            selected[columnOne.value] = columnOne.label;
        }

        if (columnTwo && !selected[columnTwo.value]) {
            selected[columnTwo.value] = columnTwo.label;
        }
    }

    return Object.entries(selected).map(
        ([value, label]): DropdownOption => ({
            label,
            value,
        })
    );
};
