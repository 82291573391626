export const KEYWORD_METRICS = [
    'CTR',
    'Impr',
    'Conversions',
    'Efficiency',
    'Clicks',
    'Cost',
    'AvgCPC',
] as const;

export type KeywordMetric = (typeof KEYWORD_METRICS)[number];

export interface FormattedPerformanceData {
    keywordId: number;
    keywordName: string;
    prior: string;
    recent: string;
    difference: string;
}

export interface FormulaColumnDetails {
    columnName: string; // => 'CPR(App + Web)',
    column1: string; // => 'cost',
    operator: string; // => '/',
    column2: string; // => 'Registrations (App + Web)',
}

export interface TempPacingDocBody {
    accountId: number; // => 1, based on Agency
    brandId: number; // => 1, based on Advertiser
    platformId: number; // => 1
    goalIds: number[]; // => [973, 72, 966, 965, 967],
    columns: string[]; // => ['clicks','avgCPC','Registrations (App + Web)','impressions','daily_budget',],
    isFormulaColumn: boolean; // => true
    formulaColumn: FormulaColumnDetails[];
}
