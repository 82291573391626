import { FormattedPerformanceData } from '../../../state';

import { Column } from '@publicismedia-ds/ui-charts';

import { numberWithCommas } from '../../../utils';

interface KeywordGraphProps {
    data: FormattedPerformanceData[];
    priorDate: string;
    recentDate: string;
}

function KeywordGraph({ data, priorDate, recentDate }: KeywordGraphProps) {
    const prior = data.map((keyword) => keyword.prior.replace(/[,$%]/g, ''));
    const recent = data.map((keyword) => keyword.recent.replace(/[,$%]/g, ''));

    return (
        <>
            <Column
                data={[
                    {
                        name: priorDate,
                        data: prior,
                    },
                    {
                        name: recentDate,
                        data: recent,
                    },
                ]}
                options={{
                    chart: {
                        toolbar: {
                            show: false,
                            tools: {
                                download: false,
                            },
                        },
                    },
                    dataLabels: {
                        enabled: false,
                        formatter: (val: number) => {
                            return numberWithCommas(val);
                        },
                    },
                    xaxis: {
                        categories: data.map((keyword) => keyword.keywordName),
                        labels: {
                            hideOverlappingLabels: false,
                            trim: true,
                        },
                    },
                }}
                width="100%"
            />
        </>
    );
}

export default KeywordGraph;
