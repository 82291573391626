import { useEffect, useState } from 'react';
import { useActions, useAppSelector } from '../../hooks';

// API REQUESTS
import { getDownloadReportInZip } from '../../api';

// CONSTANTS
import { DEFAULT_API_ERROR, TODAY } from '../../constants';

// TYPES
import { RunNowScheduleDetails, ScheduleDate } from '../../state';

// UI COMPONENTS
import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import {
    AccordionControl,
    AccordionContent,
} from '@publicismedia-ds/ui-accordion';

// COMPONENT PROPS
interface RunNowGoalsTableProps {
    data: RunNowScheduleDetails[];
}

// FUNCTIONAL COMPONENT (RUN NOW GOALS)
function RunNowGoalsTable({ data }: RunNowGoalsTableProps) {
    // ACCORIAN STATE
    const [isOpen, setIsOpen] = useState(false);

    // TABLE DATA PROVIDER
    const [dataProvider, setDataProvier] = useState();

    // CURRENT USER'S ID (REDUX)
    const userId = useAppSelector(({ auth }) => auth.user?.id);

    // REDUX ACTIONS
    const { alertError, setLoading } = useActions();

    // CREATE DATA PROVIDER ON INITIAL LOAD OR WHEN DATA CHANGES
    useEffect(() => {
        setDataProvier(new DataProvider({ data }));
    }, [data]);

    // HANDLE FILE DOWNLOAD
    const onDownload = async (id: number) => {
        if (!userId) return;

        setLoading(true);
        try {
            // FIND SELECTED SCHEDULE BY ID
            const schedule = data.find(
                (schedule) => schedule.scheduleId === id
            );

            if (!schedule) return;

            const scheduleName = schedule.scheduleName.replaceAll(/ /g, '_');

            const fileName = `${scheduleName}_${TODAY}.zip`;

            await getDownloadReportInZip(userId, id, fileName);
        } catch (error: any) {
            alertError(
                error.reponse?.data?.errorMessage ||
                    error.response?.data?.message ||
                    DEFAULT_API_ERROR
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <AccordionControl
                className="table-accordion-control"
                isOpen={isOpen}
                targetId="run-now-goals-table"
                onClick={() => setIsOpen((prev) => !prev)}
            >
                <h3>Existing Run Now Scheduled Goals</h3>
            </AccordionControl>
            <AccordionContent id="run-now-goals-table">
                <DataTable
                    className="scheduled-goals-data-table"
                    data={dataProvider}
                    stickyHead={true}
                    bindKey="scheduleId"
                    emptyState={
                        <>
                            <h2>No data available.</h2>
                        </>
                    }
                >
                    <ColumnTemplate
                        bind="scheduleName"
                        isSortable
                        minWidth={true}
                    >
                        Name
                    </ColumnTemplate>
                    <ColumnTemplate
                        bind="moduleName"
                        isSortable
                        minWidth={true}
                    >
                        Modules
                    </ColumnTemplate>
                    <ColumnTemplate bind="emails" isSortable minWidth={true}>
                        Emails
                    </ColumnTemplate>
                    <ColumnTemplate
                        bind="goalId"
                        isSortable
                        onRender={(text: string) => (
                            <>
                                <span>
                                    {text.split(',').length} Goal(s) Selected
                                </span>
                            </>
                        )}
                        minWidth={true}
                    >
                        Goals
                    </ColumnTemplate>
                    <ColumnTemplate
                        bind="createdAt"
                        onRender={(createdOn: ScheduleDate) => (
                            <>
                                <span>{`${createdOn.date.year}-${createdOn.date.month}-${createdOn.date.day}`}</span>
                            </>
                        )}
                        isSortable
                        minWidth={true}
                    >
                        Created
                    </ColumnTemplate>
                    <ColumnTemplate bind="status" isSortable minWidth={true}>
                        Created
                    </ColumnTemplate>
                    <ColumnTemplate
                        bind="scheduleId"
                        isSortable
                        onRender={(scheduleId: number) => (
                            <>
                                <button
                                    className="action-button"
                                    onClick={() => {
                                        onDownload(scheduleId);
                                    }}
                                >
                                    <i
                                        className="icon-download"
                                        title="Download"
                                    ></i>
                                </button>
                            </>
                        )}
                        minWidth={true}
                    >
                        Actions
                    </ColumnTemplate>
                </DataTable>
            </AccordionContent>
        </>
    );
}

export default RunNowGoalsTable;
