import { DropdownOption } from '.';
import { PlatformId } from '../../constants';
import {
    BingAdsFormState,
    GoogleAdsFormState,
    KenshooFormState,
    KenshooReportDetails,
    SA360FormState,
} from '../forms';

// ELIGIBLE PLATFORM SELECTIONS
export const PLATFORMS = [
    { platformId: 1, platformName: 'Search Ads 360' },
    { platformId: 3, platformName: 'Kenshoo' },
    { platformId: 0, platformName: 'Engine Accounts' },
] as const;

// PLATFORM DROPDOW MENU OPTIONS
export const PLATFORM_OPTIONS = PLATFORMS.map(
    (platform): DropdownOption => ({
        label: platform.platformName,
        value: platform.platformId.toString(),
    })
);

// TYPE OF ELIGIBLE PLATFORM DROPDOWN MENU OPTIONS
export type PLATFORM_OPTION_TYPE = typeof PLATFORM_OPTIONS[number];

export interface PlatformDetails {
    isEngineAccount: false;
    multiplePlatform?: PlatformDetails[];
    platformID: PlatformId;
    platformName: string;
}

export interface ManagerEmail {
    mEmail: string[];
}

export interface GETPlatormList {
    result: 'success' | 'failure';
    platformList: (PlatformDetails | ManagerEmail)[];
}

export interface BrandDetails {
    brand_id: number; // => 63,
    actual_adv_name: string; // => 'AR~PER_AN~WAWA_MK~US_AV~FB_CH~SEARCH',
    advertiser_id: string; // => '21700000001882977',
    brand_status: 0 | 1; // => 1,
}

export interface CustomColumnDetails {
    column_name: string; // => "Adobe Analytics Leads",
    column_status: boolean; // => true
}

export interface BingAdsSubAccountDetails {
    sub_account_name: string; // => 'Wawa - Paid Search',
    sub_account_id: number; // => 180231490,
    sub_account_number: string; // => 'F1498QFT',
    customer_id: string; // => '607834',
    platform_id: number; // => 4,
    platform_name: string; // => 'Bing Ads',
    sub_act_status: boolean; // => false,
}

export interface GoogleAdsSubAccountDetails {
    sub_account_name: string; // => 'WAWA - Search',
    sub_account_id: number; // => 1502335174,
    platform_id: number; // => 2,
    platform_name: string; // => 'Google Ads',
    sub_act_status: boolean; // => false,
}

export interface ExistingAccountDetails {
    accountName: string; // => 'Performics - WaWa'
    adwordsManagerEmail?: string; // => 'jkim10.performics@gmail.com',
    agencyId: string; // => '20700000001368004',
    bingAdsManagerEmail?: string; // => 'john.kim@performics.com',
    bingAdsSubAccounts?: BingAdsSubAccountDetails[];
    brands?: BrandDetails[];
    customColumns?: CustomColumnDetails[];
    customRevenueColumns?: CustomColumnDetails[];
    googleAdsSubAccounts?: GoogleAdsSubAccountDetails[];
    isDSASupport: boolean; // => false
    isManagerAccount: boolean; // => false,
    isMulitplePlatform: boolean; // => true,
    isPLASupport: boolean; // => false
    managerCustomerId?: string; // => "1903424266",
    managerCustomerName?: string; // => "The General"
    platformId: PlatformId; // => 1,
    platform: string; // => 'Search Ads 360',
    sa360ManagerEmail?: string; // => 'jkim10.performics@gmail.com',
    kenshooReportDetails?: KenshooReportDetails[];
}

export interface GETExistingAccountsData {
    [key: number | string]: ExistingAccountDetails;
}

export interface AddSA360Account {
    platformId: number; // => 1;
    account: SA360AccountData;
    dsaSupport: boolean;
    plaSupport: boolean;
    isEngineAccount: boolean;
    engineAccounts: (GoogleAdsAccount | BingAdsAccount)[];
}

export interface AddAccountAdvertiserData {
    advertiserName: string;
    advertiserID: string;
}

export interface SA360AccountData {
    sa360ManagerEmail: string; // => 'jkim10.performics@gmail.com';
    agencyName: string;
    agencyId: string;
    customColumns: string; // csv => 'test, testing, still testing'
    isRevenueSupport: boolean;
    customRevenueColumns: string; // csv => 'test, testing, still testing'
    advertiserData: AddAccountAdvertiserData[];
}

export interface SubCustomerData {
    subCustomerName: string;
    subCustomerId: string;
}

export interface GoogleAdsAccount {
    platformId: number; // always => 2 ???;
    account: {
        adwordsManagerEmail: string;
        customerName: string;
        customerId: string;
        isManagerAccount: 'true' | 'false';
        customerData: SubCustomerData[];
    };
}

export interface BingAdsSubAccountData {
    subAccountName: string;
    subAccountId: string;
    accountNumber: string;
    customerId: string;
}

export interface BingAdsAccount {
    platformId: number; // always => 4 ????;
    account: {
        bingAdsManagerEmail: string;
        agencyName: string;
        accountData: BingAdsSubAccountData[];
    };
}

export interface KenshooProfileData {
    profileName: string; // => 'prof Name';
    profileId: string; // => 'prof ID'
}

export interface KenshooReportData {
    reportName: string; // => 'key report name';
    reportId: string; // => 'key report id';
    reportType: string; // => 'keyword';
}

export interface KenshooAccountData {
    accountName: string; // => 'acc Name';
    ksId: string; // => 'ks ID';
    profileData: KenshooProfileData[];
    reportData: KenshooReportData[]; // REQUIRES ONE OF EACH REPORT (KEYWORD, CAMPAIGN, ADGROUP)?
}

export interface AddKenshooAccount {
    platform: number;
    account: KenshooAccountData;
}

export type EngineAccountBody = GoogleAdsAccount | BingAdsAccount;

export interface UpdateAdvertiserDetails {
    actual_adv_name: string; // 'AR~PER_AN~AmericanFamily';
    advertiser_id: string; // '21700000001903548';
    brand_status: number; //  1 | 0;
}

export interface UpdateBingSubAccount {
    sub_account_name: string; // 'AFI - Desktop Search';
    sub_account_id: number; // 1567341;
    sub_account_number: string; // 'X1053038';
    sub_act_status: boolean; // true;
    customer_id: string; // '455';
}

export interface UpdateGoogleSubAccount {
    sub_account_name: string; // 'AFI - NEW Search';
    sub_account_id: number; // 4550169140;
    sub_act_status: boolean; //true;
}

export interface UpdateAccountData {
    sa360ManagerEmail: string;
    accountName: string; // 'Facebook Advertiser Acquisition';
    agencyId: string; // '9516648093';
    advertisers: UpdateAdvertiserDetails[];
    customColumns: CustomColumnDetails[];
    bingAdsManagerEmail: string;
    bingAdsSubAccounts: UpdateBingSubAccount[];
    isRevenueSupport: boolean;
    RevenueSupport: CustomColumnDetails[];
    isManagerAccount: boolean;
    customerName?: string; // 'Facebook Advertiser Acquisition'; GOOGLE ADS PLATFORM ID ONLY?????
    customerId?: string; // '9516648093'; GOOGLE ADS PLATFORM ID ONLY????????????
    managerCustomerId?: string; // SA 360 PLATFORM ID && GOOGLE ADS IS MANAGER ACCOUNT
    managerCustomerName?: string; // SA 360 PLATFORM ID && GOOGLE ADS IS MANAGER ACCOUNT
    customerStatus?: boolean | ''; // GOOGLE ADS PLATFORM ID && NOT MANAGER ACCOUNT
    adwordsManagerEmail: string; // 'david.yang@performics.com';
    googleAdsSubAccounts: UpdateGoogleSubAccount[];
    kenshooReportDetails: KenshooReportDetails[];
}

export interface POSTUpdateAccount {
    platformId: number;
    platform: string;
    isDSASupport: boolean;
    isPLASupport: boolean;
    account: UpdateAccountData;
}
