import { useActions } from '../../hooks';
import { DropdownOption, SA360FormAdvertiser } from '../../state';

// UI COMPONENTS
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { STATUS_OPTIONS } from '../../constants';
import { memo } from 'react';

// COMPONENT PROPS
interface SA360AdvertiserProps {
    index: number;
    data: SA360FormAdvertiser;
}

function SA360Advertiser({ index, data }: SA360AdvertiserProps) {
    // REDUX ACTIONS
    const { updateSA360Advertiser, removeSA360Advertiser } = useActions();

    return (
        <div className="inputs-row">
            {/* ADVERTISER NAME */}
            <Textbox
                value={data.name}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    updateSA360Advertiser({
                        index,
                        name: evt.target.value,
                    });
                }}
                disabled={data.existing}
                required
            >
                Advertiser Name
            </Textbox>

            {/* ADVERTISER ID */}
            <Textbox
                value={data.id}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    updateSA360Advertiser({
                        index,
                        id: evt.target.value,
                    });
                }}
                disabled={data.existing}
                required
            >
                Advertiser ID
            </Textbox>

            {data.status !== undefined && (
                <Dropdown
                    options={STATUS_OPTIONS}
                    value={{
                        label: '',
                        value: data.status.toString(),
                    }}
                    onChange={(selected: DropdownOption) => {
                        updateSA360Advertiser({
                            index,
                            status: selected.value === 'true',
                        });
                    }}
                    isSearchable={false}
                >
                    Status
                </Dropdown>
            )}

            {/* ICON TO REMOVE ADVERTISER DATA */}
            {!data.existing && index > 0 && (
                <i
                    className="icon-close"
                    onClick={() => {
                        removeSA360Advertiser(index);
                    }}
                />
            )}
        </div>
    );
}

export default memo(SA360Advertiser);
