import './Edit-Note-Text-Modal.scss';

import React, { useState } from 'react';

// UI COMPONENTS
import Modal from '../Modal/Modal';
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';

// COMPONENT PROPS
interface EditNoteTextModalProps {
    data: { text: string; index: number };
    onCancel: () => void;
    onUpdate: (text: string, index: number) => void;
}

function EditNoteTextModal({
    data,
    onCancel,
    onUpdate,
}: EditNoteTextModalProps) {
    // UPDATED NOTE TEXT STATE
    const [newText, setNewText] = useState(data.text);

    return (
        <Modal
            userClosable={false}
            onClose={onCancel}
            header="Note Text:"
            content={
                <div className="edit-note-text-container">
                    <Textbox
                        as="textarea"
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            setNewText(evt.target.value)
                        }
                        hideLabel={true}
                        value={newText}
                        rows="10"
                        required
                    >
                        {' '}
                    </Textbox>
                </div>
            }
            footer={
                <>
                    <Button display="text" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        display="primary"
                        onClick={() => {
                            onUpdate(newText, data.index);
                        }}
                        disabled={!newText}
                    >
                        Confirm
                    </Button>
                </>
            }
        />
    );
}

export default EditNoteTextModal;
