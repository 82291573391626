import { numberWithCommas } from '../../../utils';
import { PerformanceDetails } from '../../../state';

import DataTable, { ColumnTemplate } from '@publicismedia-ds/ui-datatable';

interface DoDChartProps {
  prior: PerformanceDetails | null;
  recent: PerformanceDetails | null;
  difference: PerformanceDetails | null;
  priorDate: string;
  recentDate: string;
  conv: string;
}

export interface Metrics {
  clicks: string;
  avgCPC: string;
  conversion: string;
  ctr: string;
  efficiency: string;
  cost: string;
  impr: string;
}

function PerformanceChart({
  prior,
  recent,
  difference,
  priorDate,
  recentDate,
  conv,
}: DoDChartProps) {
  const metrics: Metrics = {
    clicks: 'Clicks',
    avgCPC: 'Avg CPC',
    conversion: `Conversion (${conv})`,
    ctr: 'CTR',
    efficiency: 'Efficiency',
    cost: 'Cost',
    impr: 'Impressions',
  };
  const formattedData = Object.keys(metrics)
    .sort()
    .map((key) => {
      const isCurrency =
        key === 'cost' || key === 'avgCPC' || key === 'efficiency';

      const isPercentage = key === 'ctr';

      let priorValue = numberWithCommas(
        prior?.[key as keyof PerformanceDetails] || 0,
        isCurrency ? 2 : 0
      );
      let recentValue = numberWithCommas(
        Number(recent?.[key as keyof PerformanceDetails] || '0'),
        isCurrency ? 2 : 0
      );

      const differenceValue =
        numberWithCommas(
          difference?.[key as keyof PerformanceDetails] || 0,
          2
        ) + '%';

      // INCLUDE DOLLAR SIGN FOR CURRENCY VALUES
      if (isCurrency) {
        priorValue = '$' + priorValue;
        recentValue = '$' + recentValue;
      }

      // INCLUDE PERCENT SIGN FOR PERCENTAGE VALUES
      if (isPercentage) {
        priorValue = priorValue + '%';
        recentValue = recentValue + '%';
      }

      return {
        metric: metrics[key as keyof Metrics],
        prior: priorValue,
        recent: recentValue,
        percentDifference: differenceValue,
      };
    });

  return (
    <div className="day-over-day-chart">
      <DataTable
        data={!recent && !prior ? [] : formattedData}
        bindKey="metric"
        stickyHead={true}
        emptyState={
          <>
            <h2>No data</h2>
          </>
        }
      >
        <ColumnTemplate bind="metric" align="left" minWidth>
          Metric
        </ColumnTemplate>
        <ColumnTemplate bind="prior" align="center" type="number" minWidth>
          {priorDate}
        </ColumnTemplate>
        <ColumnTemplate bind="recent" align="center" type="number" minWidth>
          {recentDate}
        </ColumnTemplate>
        <ColumnTemplate
          bind="percentDifference"
          align="center"
          type="number"
          minWidth={true}
        >
          % Difference
        </ColumnTemplate>
      </DataTable>
    </div>
  );
}

export default PerformanceChart;
