import './Pacing-Doc.scss';

// UI COMPONENTS
import Product from '../Product/Product';
import PacingDocForm from './Pacing-Doc-Form';

// FUNCTIONAL COMPONTENT (PACING DOC)
function PacingDoc() {
    return (
        <Product>
            <PacingDocForm />
        </Product>
    );
}

export default PacingDoc;
