import { axios } from '../axios-config';

// CONSTANTS
import { API_MEDIA_SAT, API_MEDIA_SAT_BULKSHEET } from '../../constants';

// TYPES
import {
    WhatIfData,
    ConversionType,
    WhatIfDataParams,
    ScenarioPlannerData,
    OptimizationStrategy,
    GETAdhocScenarioPlannerData,
} from '../../state';

// UTILS
import { saveDownloadFile } from '../../utils/download-file';

export const getScenarioPlannerData = async (
    userId: string,
    goalId: string | number
) => {
    const url = API_MEDIA_SAT + '/MediaSAT/keywordRanking';
    try {
        const body = new URLSearchParams();
        body.append(`userId`, userId);
        body.append(`goalId`, goalId.toString());

        const { data } = await axios.post<ScenarioPlannerData>(url, body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        return data;
    } catch (error: any) {
        throw error;
    }
};

export const getAdhocScenarioPlannerData = async (
    userId: string,
    efficiencyTarget: string,
    conversionType: ConversionType
) => {
    try {
        const url = API_MEDIA_SAT + '/MediaSAT/keywordAdhocRanking';
        const conversion =
            conversionType === 'Revenue' ? 'revenue' : 'conversion';

        const body = new URLSearchParams();
        body.append('userId', userId);
        body.append('efficiencyTarget', efficiencyTarget);
        body.append('efficiencyNumerator', 'cost');
        body.append('efficiencyDenominator', conversion);

        const { data } = await axios.post<GETAdhocScenarioPlannerData>(
            url,
            body,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );

        if (data.setting.conversion_type) {
            // FORMAT ADHOC DATA STATE
            const adhocData: ScenarioPlannerData = {
                xAxis: data?.xAxis || [],
                Cost: data?.Cost || [],
                Conversion: data?.Conversion || [],
                Efficiency: data?.Efficiency || [],
                conversionType: data?.setting?.conversion_type || 'Revenue',
            };
            return adhocData;
        } else {
            return '';
        }
    } catch (error) {
        throw error;
    }
};

export const getWhatIfData = async (params: WhatIfDataParams) => {
    const url = API_MEDIA_SAT + '/MediaSAT/WhatIf';
    try {
        const body = new URLSearchParams();
        body.append(`userId`, params.userId);
        body.append(`goalId`, params.goalId);
        body.append(`metricName`, params.metricName);
        body.append(`metricValue`, params.metricValue);

        const { data } = await axios.post<WhatIfData>(url, body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });

        const formatted: WhatIfData = {
            Cost: parseFloat(data.Cost).toFixed(2),
            Conversion: parseFloat(data.Conversion).toFixed(2),
            Efficiency: parseFloat(data.Efficiency).toFixed(3),
        };

        return formatted;
    } catch (error) {
        throw error;
    }
};

export const getAdhocWhatIfData = async (
    userId: string,
    metricName: string,
    metricValue: string
) => {
    try {
        const url = API_MEDIA_SAT + '/MediaSAT/adhocWhatIf';

        const body = new URLSearchParams();
        body.append('userId', userId);
        body.append('metricName', metricName);
        body.append('metricValue', metricValue);

        const { data } = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return data;
    } catch (error) {
        throw error;
    }
};

export const downloadScenarioReport = async (
    userId: string,
    goalId: string | number,
    strategy: OptimizationStrategy,
    fileName: string
) => {
    try {
        const url = API_MEDIA_SAT_BULKSHEET + '/mediaSATBulksheet';

        const body = new URLSearchParams();
        body.append('userId', userId);
        body.append('goalId', goalId.toString());
        body.append('strategy', strategy || 'Max Budget');

        const blob = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            responseType: 'blob',
        });
        saveDownloadFile(blob, fileName);
    } catch (error) {
        throw error;
    }
};

export const downloadScenarioAdhoc = async (
    userId: string,
    fileName: string
) => {
    try {
        const url = API_MEDIA_SAT_BULKSHEET + '/mediaSATBulksheet';

        const body = new URLSearchParams();
        body.append('userId', userId);
        body.append('goalId', '0');
        body.append('isAdhoc', 'true');

        const blob = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            responseType: 'blob',
        });
        saveDownloadFile(blob, fileName);
    } catch (error) {
        throw error;
    }
};
