import './Run-Data-Crons.scss';

import { useEffect, useState } from 'react';
import { DEFAULT_API_ERROR } from '../../constants';
import { useActions, useAppSelector } from '../../hooks';

// TYPES
import {
    DropdownOption,
    CronStatusData,
    MarinCronFormState,
    AdwordsCronFormState,
    CRON_SUCCESS_MESSAGE,
    GETRolesAccountBrands,
    CRON_PLATFORM_OPTIONS,
} from '../../state';

// API REQUESTS
import {
    addMarinCron,
    addSA360Cron,
    getCronStatus,
    getRolesAccountsBrands,
    uploadAndMergeCustomColumns,
} from '../../api';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import AdminPage from '../../components/Admin-Page/Admin-Page';
import MarinCronTable from '../../components/Charts/Cron-Tables/Marin-Cron-Table';
import MarinCronForm from '../../components/Run-Data-Crons-Forms/Marin-Cron-Form';
import SA360CronsTable from '../../components/Charts/Cron-Tables/SA360-Cron-Table';
import SA360CronForm from '../../components/Run-Data-Crons-Forms/SA-360-Cron-Form';
import AdwordsCronForm from '../../components/Run-Data-Crons-Forms/Adwords-Cron-Form';
import AdwordsCronsTable from '../../components/Charts/Cron-Tables/Adwords-Cron-Table';

// PAGE STATE TYPE
interface PageState {
    platform: DropdownOption;
    accounts: GETRolesAccountBrands | null;
    crons: CronStatusData[];
}

// INITIAL/DEFAULT PAGE STATE
const INITIAL_PAGE_STATE: PageState = {
    platform: { label: '', value: '' },
    accounts: null,
    crons: [],
};

// FUNCTIONAL COMPONENT
function RunDataCrons() {
    const [showReloadBtn, setShowReloadBtn] = useState(false);

    // REDUX ACTIONS
    const { setLoading, alertError, alertSuccess } = useActions();

    // STORE ACCOUNTS, REPORTS LISTS, CRONS DATA, AND SELECTED PLATFORM
    const [state, setState] = useState<PageState>(INITIAL_PAGE_STATE);

    // CURRENT USER'S ID (REDUX)
    const userId = useAppSelector(({ auth }) => auth.user?.id);

    // FETCH AND STORE REPORTS, ACCOUNTS, AND CRONS DATA
    useEffect(() => {
        (async function () {
            if (!userId) return;

            setLoading(true);
            try {
                // GET ACCOUNTS AND REPORTS DATA FOR FORM OPTIONS
                const accounts = await getRolesAccountsBrands(userId);

                // GET CURRENT CRONS STATUS DATA FOR TABLE
                const crons = await getCronStatus(userId);

                setState((prev) => ({ ...prev, accounts, crons }));
            } catch (error: any) {
                alertError(
                    error.response?.data?.message ||
                        error.response?.data?.errorMessage ||
                        DEFAULT_API_ERROR
                );
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    // HANDLE PLATFORM SELECTION
    const onSelectPlatform = (selected: DropdownOption) => {
        setState((prev) => {
            prev.platform = selected;
            return { ...prev };
        });
    };

    // HANDLE SA 360 CRON FORM SUBMIT
    const onSubmitSA360Cron = async (formState: any) => {
        if (!userId) return;

        setLoading(true);

        let isTimerRunning = true;

        let loadingTimeout = setTimeout(() => {
            setLoading(false);
            isTimerRunning = false;
            alertSuccess(CRON_SUCCESS_MESSAGE);
            setShowReloadBtn(true);
        }, 7000);

        try {
            await addSA360Cron(userId, formState);

            const crons = await getCronStatus(userId);
            setState((prev) => ({ ...prev, crons }));

            if (isTimerRunning) {
                setShowReloadBtn(true);
                clearTimeout(loadingTimeout);
                alertSuccess(CRON_SUCCESS_MESSAGE);
            }
        } catch (error: any) {
            clearTimeout(loadingTimeout);
            alertError(
                error.response?.data?.message ||
                    error.response?.data?.errorMessage ||
                    DEFAULT_API_ERROR
            );
        } finally {
            setLoading(false);
        }
    };

    // HANDLE ADWORDS CRON FORM SUBMIT
    const onSubmitAdwordsCron = async (formState: AdwordsCronFormState) => {
        if (!userId) return;

        setLoading(true);

        let isTimerRunning = true;

        const loadingTimeout = setTimeout(() => {
            setLoading(false);
            isTimerRunning = false;
            alertSuccess(CRON_SUCCESS_MESSAGE);
            setShowReloadBtn(true);
        }, 7000);

        try {
            const data = await uploadAndMergeCustomColumns(userId, formState);

            if (isTimerRunning) {
                clearTimeout(loadingTimeout);
                setShowReloadBtn(true);
            }

            if (!data) {
                alertError('Please fill in all required fields.');
            }
        } catch (error: any) {
            clearTimeout(loadingTimeout);
            alertError(
                error.response?.data?.message ||
                    error.response?.data?.errorMessage ||
                    DEFAULT_API_ERROR
            );
        } finally {
            setLoading(false);
        }
    };

    // HANDLE MARIN CRON FORM SUBMIT
    const onSubmitMarinCron = async (formState: MarinCronFormState) => {
        if (!userId) return;

        setLoading(true);

        let isTimerRunning = true;

        const loadingTimeout = setTimeout(() => {
            setLoading(false);
            isTimerRunning = false;
            alertSuccess(CRON_SUCCESS_MESSAGE);
            setShowReloadBtn(true);
        }, 7000);

        try {
            const data = await addMarinCron(userId, formState);

            if (isTimerRunning) {
                clearTimeout(loadingTimeout);
                setShowReloadBtn(true);
            }

            if (!data) {
                alertError('Please fill in all required fields.');
            }
        } catch (error: any) {
            clearTimeout(loadingTimeout);
            alertError(
                error.response?.data?.message ||
                    error.response?.data?.errorMessage ||
                    DEFAULT_API_ERROR
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <AdminPage header="Run Data Fetching Crons">
            <div className="run-data-crons-container">
                <div className="crons-section">
                    <Dropdown
                        options={CRON_PLATFORM_OPTIONS}
                        value={state.platform.value ? state.platform : ''}
                        defaultValue={
                            state.platform.value ? state.platform : ''
                        }
                        onChange={onSelectPlatform}
                        required
                    >
                        Select Platform
                    </Dropdown>
                </div>

                {/* --- CRONS FORMS --- */}
                <div className="crons-section">
                    {/* --- SA360 CRONS FORM --- */}
                    {state.platform.value.toString() === '1' && (
                        <div className="crons-section">
                            <SA360CronForm
                                data={state.accounts}
                                onSubmit={onSubmitSA360Cron}
                            />
                        </div>
                    )}

                    {/* --- ADWORDS CRON FORM ---  */}
                    {state.platform.value.toString() === '2' && (
                        <div className="crons-section">
                            <AdwordsCronForm
                                data={state.accounts?.accounts || null}
                                onSubmit={onSubmitAdwordsCron}
                            />
                        </div>
                    )}

                    {/* --- MARIN CRON FORM ---*/}
                    {state.platform.value.toString() === '5' && (
                        <div className="crons-section">
                            <MarinCronForm
                                data={state.accounts?.accounts || null}
                                onSubmit={onSubmitMarinCron}
                            />
                        </div>
                    )}
                </div>

                {/* --- CRONS DATA TABLES --- */}
                <div className="crons-section">
                    {/* --- HEADER --- */}
                    {!!state.platform.label && (
                        <div className="crons-section-header">
                            <h3 className="crons-section-title">
                                {state.platform.label} Crons
                            </h3>
                            <div
                                className="reload-crons-button"
                                hidden={!showReloadBtn}
                            >
                                <Button
                                    display="secondary"
                                    onClick={async () => {
                                        if (!userId) return;

                                        setLoading(true);
                                        try {
                                            const crons = await getCronStatus(
                                                userId
                                            );
                                            setState((prev) => ({
                                                ...prev,
                                                crons,
                                            }));
                                        } catch (error: any) {
                                            alertError(
                                                error.response?.data?.message ||
                                                    error.response?.data
                                                        ?.errorMessage ||
                                                    DEFAULT_API_ERROR
                                            );
                                        } finally {
                                            setLoading(false);
                                        }
                                    }}
                                >
                                    Reload Crons
                                </Button>
                            </div>
                        </div>
                    )}

                    {/* --- SA360 CRONS TABLE --- */}
                    {state.platform.value.toString() === '1' && (
                        <div className="crons-section">
                            <SA360CronsTable data={state.crons} />
                        </div>
                    )}

                    {/* --- ADWORDS CRONS TABLE --- */}
                    {state.platform.value.toString() === '2' && (
                        <div className="crons-section">
                            <AdwordsCronsTable data={state.crons} />
                        </div>
                    )}

                    {/* --- MARIN CRON TABLE ---*/}
                    {state.platform.value.toString() === '5' && (
                        <div className="crons-section">
                            <MarinCronTable data={state.crons} />
                        </div>
                    )}
                </div>
            </div>
        </AdminPage>
    );
}

export default RunDataCrons;
