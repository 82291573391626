import './Select-Campaigns.scss';

import { useActions } from '../../hooks';
import { DropdownOption, SELECT_ALL_OPTION } from '../../state';

// UI COMPONENTS
import Dropdown from '@publicismedia-ds/ui-dropdown';
import Checkbox from '@publicismedia-ds/ui-checkbox';
import Tooltip, {
    TOOLTIP_ALIGN,
    TOOLTIP_POSITION,
} from '@publicismedia-ds/ui-tooltip';
import { parseFile } from '../../utils/file-reader';

// SELECT CAMPAIGNS COMPONENT PROPS
interface SelectCampaignsProps {
    campaignOptions: DropdownOption[];
    onSelectCampaigns: (selected: DropdownOption[], uploaded?: boolean) => void;
    selectedCampaigns: DropdownOption[] | string[];
    disabled?: boolean;
    isIntraday?: boolean;
    onSelectAutoAdd360: (isChecked: boolean) => void;
    autoAdd360: boolean;
}

// SELECT CAMPAIGNS FUNCTION COMPONENT
function SelectCampaigns({
    campaignOptions,
    selectedCampaigns,
    onSelectCampaigns,
    disabled,
    isIntraday,
    onSelectAutoAdd360,
    autoAdd360,
}: SelectCampaignsProps) {
    const { setLoading, alertError, alertSuccess } = useActions();

    // HANDLE UPLOAD OF CAMPAIGNS FILE
    const onSelectCampaignsFile = async (
        evt: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (!evt.target.files?.length) return;

        // DISPLAY LOADING MODAL
        setLoading(true);

        try {
            // ATTEMPT TO PARSE FILE AND ADD CAMPAIGN SELECTIONS
            const parsedOptions = await parseFile(
                evt.target.files[0],
                'campaign'
            );
            // CREATE OBJECT OF AVAILBLE CAMPAINGS WHERE KEY === ID, VALUE === NAME
            const availableCampaignsObj = campaignOptions.reduce(
                (camps: { [key: string]: string }, option) => {
                    camps[option.value] = option.label;
                    return camps;
                },
                {}
            );

            // FILTER OUT PARSED OPTIONS THAT ARE NOT AVAILABLE TO BE SELECTED
            const selectedCampaigns = parsedOptions.filter(
                (option) => !!availableCampaignsObj[option.value]
            );

            // GET NUMBER OF CAMPAIGN IDS UNABLED TO BE SELECTED
            const nonMatchingIds =
                parsedOptions.length - selectedCampaigns.length;

            // ALERT RESULTS
            alertSuccess(
                `The file has been successfully processed and found ${nonMatchingIds} non matching IDs.`
            );

            // UPDATED SELECTED CAMPAIGNS
            onSelectCampaigns(selectedCampaigns, true);
        } catch (error: any) {
            // ALERT ANY ERRORS THAT OCCUR DURING PROCESSING
            alertError(error);
            // CLOSE MODAL
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="select-campaigns-container">
            {/* --- SELECT CAMPAIGNS --- */}
            <Dropdown
                options={[SELECT_ALL_OPTION, ...campaignOptions]}
                onChange={(selected: DropdownOption[]) =>
                    onSelectCampaigns(selected)
                }
                value={selectedCampaigns}
                defaultValue={selectedCampaigns}
                display="selectionInline"
                multiple
                required
            >
                Select Campaings
            </Dropdown>
            {/* --- UPLOAD CAMPAIGNS */}
            <div className="upload-campaigns-group-inputs">
                <div className="file-input-group">
                    <label>
                        Upload Campaigns
                        <Tooltip
                            className="add-goal-form-tool-tip"
                            title={`Please upload .xlsx file only and make sure the uploaded file contains "Campaign Name" and "Campaign ID" columns with the same name and order specified earlier.`}
                            align={TOOLTIP_ALIGN.TOP}
                            position={TOOLTIP_POSITION.RIGHT}
                        >
                            <i className="icon-support"></i>
                        </Tooltip>
                    </label>
                    <input
                        type="file"
                        onChange={onSelectCampaignsFile}
                        disabled={disabled}
                    />
                </div>
            </div>

            {/* --- AUTO ADD FROM SA360 (DISPLAY IF INTRADAY === FALSE) --- */}
            {!isIntraday && (
                <Checkbox
                    onChange={(
                        evt: React.ChangeEvent<HTMLInputElement>,
                        isChecked: boolean
                    ) => {
                        onSelectAutoAdd360(isChecked);
                    }}
                    value={autoAdd360}
                >
                    Automatically add new campaigns from SA360
                </Checkbox>
            )}
        </div>
    );
}

export default SelectCampaigns;
