import './Custom-Alerts.scss';

import { useEffect, useState } from 'react';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';

// API REQUESTS
import {
  getAllUsers,
  getGoalsData,
  editCustomAlert,
  getCustomAlerts,
  createCustomAlert,
  deleteCustomAlert,
} from '../../api';

// CONSTANTS
import { DEFAULT_API_ERROR } from '../../constants';

// TYPES
import { CustomAlertItem, CustomAlertState, UserDetails } from '../../state';

// UI COMPONENTS
import CustomAlertForm from './Custom-Alert-Form';
import CustomAlertModal from './Custom-Alert-Modal';
import CustomAlertsTable from './Custom-Alerts-Table';
import ConfirmationModal from '../../components/Confirmation-Modal/Confirmation-Modal';

// UTILS
import { updateAlertDetails } from '../../utils';

// SELECTED ALERT ACTIONS
interface AlertActionState {
  data: CustomAlertItem | null;
  isEditing: boolean;
  isDeleting: boolean;
}

// FUNCTIONAL COMPONENT (CUSTOM ALERTS)
function CustomAlerts() {
  const wrapper = useAsyncWrapper();

  const [alertDetails, setAlertDetails] = useState<AlertActionState>({
    data: null,
    isEditing: false,
    isDeleting: false,
  });

  // REDUX STATE
  const user = useAppSelector(({ auth }) => auth.user);
  const goalList = useAppSelector(({ goals }) => goals.list);

  // REDUX ACTIONS
  const { setGoalsList, alertSuccess, alertError } = useActions();

  // NECESSARY PAGE DATA (USERS & CUSTOM ALERTS)
  const [pageData, setPageData] = useState<{
    users: UserDetails[];
    customAlerts: CustomAlertItem[];
  }>({ users: [], customAlerts: [] });

  // FETCH ALL USERS
  const loadAllUsers = async () => {
    if (user?.id) {
      const { data } = await getAllUsers(user?.id);
      return data;
    }
    return [];
  };

  // FETCH ALERTS
  const loadAlerts = async () => {
    if (user?.id) {
      const customAlerts = await getCustomAlerts(user.id);
      return customAlerts;
    }
    return [];
  };

  // FETCH GOALS (IF NOT ALREADY LOADED)
  const loadGoals = async () => {
    if (!goalList.length && user) {
      const goals = await getGoalsData(user?.id);
      return goals;
    }
  };

  // LOAD PAGE DATA
  const loadPageData = wrapper(async () => {
    await Promise.allSettled([loadAllUsers(), loadAlerts(), loadGoals()])
      .then(([users, customAlerts, goals]) => {
        const errors = [];
        setPageData({
          users: users.status === 'fulfilled' ? users.value : [],
          customAlerts:
            customAlerts.status === 'fulfilled' ? customAlerts.value : [],
        });
        if (goals.status === 'fulfilled' && goals.value) {
          setGoalsList(goals.value);
        }

        if (customAlerts.status === 'rejected') {
          alertError(`Custom Alerts: ${customAlerts.reason.message}`);
        }
        if (users.status === 'rejected') {
          alertError(`Users: 
                    ${users.reason.message}`);
        }
        if (goals.status === 'rejected') {
          alertError(`Goals List: 
                    ${goals.reason.message}`);
        }
      })
      .catch((errors) => {
        console.error(errors);
        throw new Error(errors);
      });
  });

  // LOAD ALERTS ON INITIAL RENDER
  useEffect(() => {
    loadPageData();
  }, []);

  // HANDLE CREATE ALERT
  const onCreateAlert = async (formState: CustomAlertState) => {
    if (user) {
      const data = await createCustomAlert(formState, user.id);

      if (data.status === 'success') {
        alertSuccess(data.msg);
        const updatedAlerts = await loadAlerts();
        setPageData((prev) => ({
          ...prev,
          customAlerts: updatedAlerts,
        }));
      }

      if (data.status === 'failure') {
        throw new Error(data.msg);
      }
    }
  };

  // HANDLE VIEW ALERT CLICK
  const onViewClick = (viewAlertId: number) => {
    const foundAlert = pageData.customAlerts.find(
      ({ alertId }) => alertId === viewAlertId
    );
    setAlertDetails({
      data: foundAlert || null,
      isEditing: false,
      isDeleting: false,
    });
  };

  // HANDLE EDIT ALERT CLICK
  const onEditClick = (editAlertId: number) => {
    const foundAlert = pageData.customAlerts.find(
      ({ alertId }) => alertId === editAlertId
    );
    setAlertDetails({
      data: foundAlert || null,
      isEditing: !!foundAlert,
      isDeleting: false,
    });
  };

  // HANDLE EDIT EXISTING ALERT
  const onEditAlert = async (formState: CustomAlertState) => {
    if (alertDetails.data && user) {
      const updatedAlert = updateAlertDetails(formState, alertDetails.data);

      const { msg, message, errorMessage, status } = await editCustomAlert(
        updatedAlert,
        user.id
      );

      // RELOAD CUSTOM ALERTS TABLE
      const updatedAlerts = await loadAlerts();
      setPageData((prev) => ({ ...prev, customAlerts: updatedAlerts }));

      if (message) {
        alertSuccess(message);
      }

      if (errorMessage) {
        throw new Error(errorMessage || message);
      }

      if (status === 'failure') {
        throw new Error(msg || DEFAULT_API_ERROR);
      }

      // CLOSE MODAL
      setAlertDetails({
        data: null,
        isEditing: false,
        isDeleting: false,
      });
    }
  };

  // HANDLE DELETE ALERT ICON CLICK
  const onDeleteClick = (id: number) => {
    const foundAlert = pageData.customAlerts.find(
      ({ alertId }) => alertId === id
    );

    if (foundAlert) {
      setAlertDetails({
        data: foundAlert,
        isDeleting: true,
        isEditing: false,
      });
    }
  };

  // HANDLE DELETE CUSTOM ALERT
  const onDeleteAlert = wrapper(async (alertDetails: CustomAlertItem) => {
    if (user) {
      const { message } = await deleteCustomAlert(alertDetails, user.id);

      const updatedAlerts = await loadAlerts();
      setPageData((prev) => ({ ...prev, customAlerts: updatedAlerts }));
      alertSuccess(message);
    }
  });

  return (
    <>
      <div className="custom-alerts-container">
        <div className="custom-alerts-header">
          <h1>Custom Alert</h1>
        </div>
        <div className="custom-alerts-content">
          <CustomAlertForm
            users={pageData.users}
            goalList={goalList}
            onSubmit={onCreateAlert}
          />
          <CustomAlertsTable
            data={pageData.customAlerts}
            users={pageData.users}
            goalList={goalList}
            onEdit={onEditClick}
            onView={onViewClick}
            onDelete={onDeleteClick}
          />
          {!!alertDetails.data && !alertDetails.isDeleting && (
            <CustomAlertModal
              data={alertDetails.data}
              goals={goalList}
              users={pageData.users}
              onClose={() =>
                setAlertDetails({
                  data: null,
                  isEditing: false,
                  isDeleting: false,
                })
              }
              onEdit={onEditAlert}
              isEditing={alertDetails.isEditing}
            />
          )}

          {!!alertDetails.data && alertDetails.isDeleting && (
            <ConfirmationModal
              header="Delete Alert"
              message="Are you sure wish to delete this alert?"
              type="warning"
              onCancel={() =>
                setAlertDetails({
                  data: null,
                  isDeleting: false,
                  isEditing: false,
                })
              }
              onConfirm={() => onDeleteAlert(alertDetails.data)}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default CustomAlerts;
