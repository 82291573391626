import { axios } from '../axios-config';

// CONSTANTS
import { API_USER_SETTING3, API_USERS } from '../../constants';

// SEND FORGOT PASSWORD REQUEST
export const forgotPassword = async (email: string) => {
    const url = API_USER_SETTING3 + '/Users/forgotPassword';

    const body = new URLSearchParams();
    body.append('email', email);

    const { data } = await axios.post(url, body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

    return data;
};

// RESET PASSWORD
export const resetPassword = async (
    resetCode: string,
    pwd: string,
    confirmPassword: string
) => {
    try {
        const url = API_USERS + '/changePassword';

        const { data } = await axios.post(
            url,
            {},
            {
                params: {
                    resetCode,
                    pwd,
                    confirmPassword,
                },
            }
        );

        return data;
    } catch (error: any) {
        throw error;
    }
};
