import { ENVIRONMENT } from '../../constants';
import { EditGoalForm, POSTEditGoalBody } from '../../state';

// FORMAT ADD GOAL REQUEST BODY
export const formatEditGoalBody = (
    state: EditGoalForm
): POSTEditGoalBody | undefined => {
    if (!state.advertiser) return;

    return {
        agencyId: state.agencyId,

        advertiserId: state.advertiser.advertiserId,

        autoAddCampaign: state.autoAdd360,

        campaignIds: state.selectedCampaigns
            .filter((option) => option.value !== 'all')
            .map((option) => option.value)
            .join(','),

        goalId: state.goalId,
        goalMappingName: state.name.trim(),

        reportType: state.reportType || 'keyword',

        engineIds: state.selectedEngines
            .filter((option) => option.value !== 'all')
            .map((option) => option.value)
            .join(','),

        useFilter: state.useFilter,

        filters: JSON.stringify(state.campaignFilters).replaceAll(`"`, `\\\"`),

        minBid: state.minBid,

        maxBid: state.maxBid,

        minDailyBudget:
            state.minDailyBudget === '' ? 0 : parseInt(state.minDailyBudget),

        maxDailyBudget:
            state.maxDailyBudget === '' ? 0 : parseInt(state.maxDailyBudget),

        campaignsToExclude: state.excludedCampaigns
            .map((option) => option.value)
            .join(','),

        keywordsToExclude: state.excludedKeywords
            .map((option) => option.value)
            .join(','),

        optInOutIntradayAlerts: false,

        isIntraday: false,

        hourlyWebQueries: {
            OneDayUrl: '',
            last34DaysUrl: '',
        },

        syncGoals: state.syncGoal,

        environment: ENVIRONMENT,
    };
};
