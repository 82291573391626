import { useMemo } from 'react';

import { useAppSelector } from '../../hooks';

// TYPES
import { GETScanBidAndBidHeadroomData } from '../../state/models/bid-headroom-scan-models';

// UI COMPONENTS
import DataTable from '@publicismedia-ds/ui-datatable';

// UTILS
import { numberWithCommas } from '../../utils';

// COMPONENT PROPS
interface BidHeadroomScanTableProps {
  rawData: GETScanBidAndBidHeadroomData;
  rowsPerPage: number;
  onPagination: (pageNumber: number) => void;
}

// FUNCTIONAL COMPONENT
function BidHeadroomScanTable({
  rawData,
  rowsPerPage,
  onPagination,
}: BidHeadroomScanTableProps) {
  const loading = useAppSelector(({ products }) => products.loading);

  const COLUMN_HEADINGS = [
    {
      data: (
        <>
          <span className="content-table-header">Keyword</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Clicks</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Impressions</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Cost</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Conversions</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">CTR</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Avg CPC</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Bid</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Bid Headroom</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Revenue</span>
        </>
      ),
    },
    {
      data: (
        <>
          <span className="content-table-header">Efficiency</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Clicks</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Impressions</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Cost</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Conversions</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">CTR</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Avg CPC</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Bid</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Bid Headroom</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">Revenue</span>
        </>
      ),
    },
    {
      data: (
        <>
          <span className="content-table-header">Efficiency</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">% Difference Bid</span>
        </>
      ),
      minWidth: true,
    },
    {
      data: (
        <>
          <span className="content-table-header">
            % Difference Bid Headroom
          </span>
        </>
      ),
      minWidth: true,
    },
  ];

  const formatRawData = (res: GETScanBidAndBidHeadroomData) => ({
    head: COLUMN_HEADINGS,

    // FORMAT RAW DATA FOR FOR EACH ROW
    body: res.data
      ? res.data.map((row) => {
          return [
            row.keyword,
            {
              data: numberWithCommas(row.previousMonthData.clicks),
              type: 'number',
              minWidth: true,
            },
            {
              data: numberWithCommas(row.previousMonthData.impr),
              type: 'number',
              minWidth: true,
            },
            {
              type: 'currency',
              align: 'center',
              data: row.previousMonthData.cost,
              minWidth: true,
            },
            {
              data: numberWithCommas(row.previousMonthData.conMetric),
              align: 'center',
              minWidth: true,
            },
            {
              type: 'percent',
              data: numberWithCommas(row.previousMonthData.ctr, 2),
              align: 'center',
              minWidth: true,
            },
            {
              type: 'currency',
              data: row.previousMonthData.avgCPC,
              align: 'center',
              minWidth: true,
            },
            {
              data: numberWithCommas(row.previousMonthData.bid, 2),
              align: 'center',
              minWidth: true,
            },
            {
              data: numberWithCommas(row.previousMonthData.bid_headroom, 2),
              align: 'center',
              minWidth: true,
            },
            {
              type: row.previousMonthData.revenueMetric ? 'currency' : null,
              data:
                row.previousMonthData.revenueMetric !== undefined
                  ? row.previousMonthData.revenueMetric
                  : '---',
              align: 'center',
              minWidth: true,
            },
            {
              type: 'currency',
              data:
                row.previousMonthData.revEfficiency !== undefined
                  ? row.previousMonthData.revEfficiency
                  : row.previousMonthData.efficiency,
              align: 'center',
              minWidth: true,
            },
            {
              type: 'number',
              data: numberWithCommas(row.lastMonthData.clicks),
              align: 'center',
              minWidth: true,
            },
            {
              type: 'number',
              data: numberWithCommas(row.lastMonthData.impr),
              align: 'center',
              minWidth: true,
            },
            {
              type: 'currency',
              data: row.lastMonthData.cost,
              align: 'center',
              minWidth: true,
            },
            {
              data: numberWithCommas(row.lastMonthData.conMetric),
              align: 'center',
              minWidth: true,
            },
            {
              type: 'percent',
              data: numberWithCommas(row.lastMonthData.ctr, 2),
              align: 'center',
              minWidth: true,
            },
            {
              type: 'currency',
              data: row.lastMonthData.avgCPC,
              align: 'center',
              minWidth: true,
            },
            {
              data: numberWithCommas(row.lastMonthData.bid, 2),
              align: 'center',
              minWidth: true,
            },
            {
              data: numberWithCommas(row.lastMonthData.bid_headroom, 2),
              align: 'center',
              minWidth: true,
            },
            {
              type: row.lastMonthData.revenueMetric ? 'currency' : null,
              data:
                row.lastMonthData.revenueMetric !== undefined
                  ? '$' + row.lastMonthData.revenueMetric
                  : '---',
              align: 'center',
              minWidth: true,
            },
            {
              type: 'currency',
              data:
                row.lastMonthData.revEfficiency !== undefined
                  ? row.lastMonthData.revEfficiency
                  : row.lastMonthData.efficiency,
              align: 'center',
              minWidth: true,
            },
            {
              type: 'percent',
              data: numberWithCommas(row.percentDiffData.bid, 2),
              align: 'center',
              minWidth: true,
            },
            {
              type: 'percent',
              data: numberWithCommas(row.percentDiffData.bid_headroom, 2),
              align: 'center',
              minWidth: true,
            },
          ];
        })
      : [],
  });

  // MEMOIZE TO OPTIMIZE PERFORMANCE
  const tableData = useMemo(() => formatRawData(rawData), [rawData]);

  return (
    <>
      <DataTable
        content={tableData}
        caption={
          <div className="caption-container">
            <div className="caption-content">
              <div className="caption previous-month">
                {rawData?.previousMonth || ''}
              </div>
              <div className="caption last-month">
                {rawData?.lastMonth || ''}
              </div>
            </div>
          </div>
        }
        paginationProps={{
          pageSize: rowsPerPage,
          totalSize: rawData.count || 0,
          onChange: (newPageValue: { label: number; value: number }) =>
            onPagination(newPageValue.value),
        }}
        isLoading={loading}
        stickyFoot={true}
      />
    </>
  );
}

export default BidHeadroomScanTable;
