import { axios } from '../axios-config';
import { API_UNIVERSAL_SETTINGS } from '../../constants';
import {
    ChangeLogsSummary,
    DownloadChangeSummaryParams,
    GETChangeSummaryLogs,
    GetChangeSummaryLogsParams,
    GETTimeForChangeSummary,
} from '../../state/models/change-summary-models';
import { saveDownloadFile } from '../../utils/download-file';

export const getTimeForChangeSummary = async (
    userId: string,
    goalId: string | number,
    date: string
) => {
    try {
        const url = API_UNIVERSAL_SETTINGS + '/getTimeForChangeSummary';
        const { data } = await axios.get<GETTimeForChangeSummary>(url, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            params: {
                userId,
                goalId,
                date,
            },
        });
        return data;
    } catch (error) {
        throw error;
    }
};

export const getChangeSummaryLogs = async (
    params: GetChangeSummaryLogsParams
) => {
    try {
        const url = API_UNIVERSAL_SETTINGS + '/getChangeSummaryLogs';
        const { data } = await axios.get<GETChangeSummaryLogs>(url, {
            params: {
                ...params,
                download: false,
                bulksheetType: '',
            },
        });
        const parsedData: ChangeLogsSummary = {
            msg: data.msg,
            goalSettings: data.goalSettings,
            status: data.status,
            summaryLogs: {
                date: data.summaryLogs.date,
                time: data.summaryLogs.time,
                dpad_result: JSON.parse(data.summaryLogs.dpad_result),
                yo_result: JSON.parse(data.summaryLogs.yo_result),
            },
        };
        return parsedData;
    } catch (error) {
        throw error;
    }
};

export const downloadChangeSummaryLogs = async (
    params: DownloadChangeSummaryParams
) => {
    try {
        const url = API_UNIVERSAL_SETTINGS + '/getChangeSummaryLogs';

        const copy = { ...params };

        // REMOVE FILENAME
        const paramsCopy = Object.entries(params).reduce(
            (reqParams: { [key: string]: any }, [key, value]) => {
                if (key !== 'fileName') {
                    reqParams[key] = value;
                }

                return reqParams;
            },
            {}
        );

        const blob = await axios.get<GETChangeSummaryLogs>(url, {
            params: {
                ...paramsCopy,
                download: true,
            },
            responseType: 'blob',
        });
        saveDownloadFile(blob, params.fileName);
    } catch (error) {
        throw error;
    }
};
