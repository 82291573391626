import './Dpad-Data.scss';
import { DpadDataType } from '../../../../state';

import { Column } from '@publicismedia-ds/ui-charts';
import BulletChartsContainer from '../../../../components/Charts/Bullet-Charts-Container/Bullet-Charts-Container';

interface DpadDataProps {
    data: DpadDataType;
}

function DpadData({ data }: DpadDataProps) {
    return (
        <>
            {/* --- COST --- */}
            <div className="row">
                <BulletChartsContainer
                    header="Cost"
                    data={data['Old Cost'] || []}
                />
            </div>
            {/* --- WEIGHTED COST --- */}
            <div className="row">
                <BulletChartsContainer
                    header="Weighted Cost"
                    data={data['Cost'] || []}
                />
            </div>
            {/* --- COST TRENDS --- */}
            <div className="row">
                <h3>Cost Trend</h3>
                <Column
                    data={[
                        {
                            name: 'Last 30 Days',
                            data: data['Cost Trend']['past30days'] || [],
                        },
                        {
                            name: 'Today',
                            data: data['Cost Trend']['today'] || [],
                        },
                        {
                            name: 'Projected',
                            data: data['Cost Trend']['flightData'] || [],
                        },
                    ]}
                    options={{
                        chart: {
                            toolbar: {
                                show: false,
                                tools: {
                                    download: false,
                                },
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        xaxis: {
                            categories: data.xAxis,
                        },
                        yaxis: {
                            decimalsInFloat: 0,
                        },
                    }}
                    width="100%"
                />
            </div>
            <div className="row">
                <hr />
            </div>
            {/* --- IMPRESSIONS --- */}
            <div className="row">
                <BulletChartsContainer
                    header={data['Efficiency Column Name']}
                    data={data['Metric'] || []}
                />
            </div>
            <div className="row">
                <h3>{data['Efficiency Column Name']}</h3>
                <Column
                    data={[
                        {
                            name: 'Last 30 Days',
                            data: data['Metric Trend']['past30days'] || [],
                        },
                        {
                            name: 'Today',
                            data: data['Metric Trend']['today'] || [],
                        },
                        {
                            name: 'Projected',
                            data: data['Metric Trend']['flightData'] || [],
                        },
                    ]}
                    options={{
                        chart: {
                            toolbar: {
                                show: false,
                                tools: {
                                    download: false,
                                },
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        xaxis: {
                            categories: data.xAxis,
                        },
                        yaxis: {
                            decimalsInFloat: 0,
                        },
                    }}
                    width="100%"
                />
            </div>
            <div className="row">
                <hr />
            </div>
            <div className="row">
                <BulletChartsContainer
                    header="Efficiency"
                    data={data['Efficiency Metric'] || []}
                />
            </div>
            <div className="row">
                <h3>Efficiency Trend</h3>
                <Column
                    data={[
                        {
                            name: 'Last 30 Days',
                            data: data['Efficiency Trend']['past30days'] || [],
                        },
                        {
                            name: 'Today',
                            data: data['Efficiency Trend']['today'] || [],
                        },
                        {
                            name: 'Projected',
                            data: data['Efficiency Trend']['flightData'] || [],
                        },
                    ]}
                    options={{
                        chart: {
                            toolbar: {
                                show: false,
                                tools: {
                                    download: false,
                                },
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        xaxis: {
                            categories: data.xAxis,
                        },
                        yaxis: {
                            decimalsInFloat: 0,
                        },
                    }}
                    width="100%"
                />
            </div>
        </>
    );
}

export default DpadData;
