import produce from 'immer';
import { SELECT_ALL } from '../../../constants';
import { DropdownOption, USER_ROLE_IDS } from '../../models';
import {
  UserFormState,
  UserFormAction,
  USER_FORM_ACTION_TYPES,
  INITIAL_USER_FORM_STATE,
} from '../form-models';

export const userFormReducer = produce(
  (state: UserFormState, action: UserFormAction): UserFormState => {
    switch (action.type) {
      case USER_FORM_ACTION_TYPES.SET_ACCESS_DATA: {
        state.accessData = action.payload;
        const { roles, accounts, features } = action.payload;

        // CREATE ROLE DROPDOWN OPTIONS
        state.roleOptions = roles.map((role) => ({
          label: role.roleName.toString(),
          value: role.roleId.toString(),
        }));

        // CREATE ACCOUNT DROPDOWN OPTIONS
        const accountOptions = accounts.map((account) => ({
          label: account.account_name,
          value: account.account_id.toString(),
        }));

        state.accountOptions = [SELECT_ALL];
        state.accountOptions.push(...accountOptions);

        // CREATE ALL ADVERTISER DROPDOWN OPTIONS BY ACCOUNT ID
        state.allAdvertiserOptions = accounts.reduce(
          (all: { [key: string]: DropdownOption[] }, account) => {
            all[account.account_id.toString()] = account.brands.map((brand) => {
              return {
                label: brand.brandName,
                value: `${account.account_id}-${brand.brandId}`,
              };
            });
            return all;
          },
          {}
        );

        // CREATE FEATURES DROPDOWN OPTIONS
        const featureOptions = features.map((feature) => ({
          label: feature.feature_name,
          value: feature.feature_id.toString(),
        }));

        state.featureOptions = [SELECT_ALL, ...featureOptions];

        return state;
      }

      case USER_FORM_ACTION_TYPES.SET_EMAIL:
        state.email = action.payload;
        return state;

      case USER_FORM_ACTION_TYPES.SET_LION_EMAIL:
        state.lionEmail = action.payload;
        return state;

      case USER_FORM_ACTION_TYPES.SET_FIRST_NAME:
        state.firstName = action.payload;
        return state;

      case USER_FORM_ACTION_TYPES.SET_LAST_NAME:
        state.lastName = action.payload;
        return state;

      case USER_FORM_ACTION_TYPES.SET_ROLE: {
        state.role = action.payload;

        // RESET FEATURE OPTIONS
        state.featureOptions = [SELECT_ALL];

        // UPDATE FEATURE OPTIONS BASED ON SELECTED ROLE
        state.accessData?.features.forEach((feature) => {
          // INCLUDE ONLY READ-ONLY FEATURES FOR READ-ONLY ROLE
          if (action.payload.value === '4' && !feature.is_read_only) {
            // REMOVE FEATURE IF PREVIOUSLY SELECTED
            state.features = state.features.filter(
              ({ value }) => value !== feature.feature_id.toString()
            );

            return;
          }
          // ADD FEATURE DROPDOWN OPTION TO OPTIONS LIST
          state.featureOptions.push({
            label: feature.feature_name,
            value: feature.feature_id.toString(),
          });
        });

        // UPDATE ACTION VALUE IF EDITING EXISTING USER
        if (state.action === 0 || state.action === 2) {
          state.action++; // CHANGING 'ROLE' ACTION === 1
        }

        // SELECT ALL OPTIONS FOR ADMIN ACCOUNT
        if (action.payload.value === USER_ROLE_IDS[0].toString()) {
          // SELECT ALL ACCOUNT OPTIONS
          state.accounts = state.accountOptions.filter(
            ({ value }) => value !== SELECT_ALL.value
          );

          // CREATE ALL ADVERTISER OPTIONS
          const allAdvertiserOptions = Object.values(
            state.allAdvertiserOptions
          ).reduce((allOptions: DropdownOption[], options) => {
            allOptions.push(...options);
            return allOptions;
          }, []);

          // SET ADVERTISER OPTIONS
          state.advertiserOptions = [...allAdvertiserOptions];

          // SET SELECTED ADVERTISERS
          state.advertisers = [...allAdvertiserOptions];

          // SELECT ALL FEATURE OPTIONS
          state.features = state.featureOptions.filter(
            ({ value }) => value !== SELECT_ALL.value
          );
        } else {
          // CLEAR PREVIOUS SELECTIONS
          state.accounts = [];
          state.advertiserOptions = [];
          state.advertisers = [];
          state.features = [];
        }

        return state;
      }

      case USER_FORM_ACTION_TYPES.ADD_ACCOUNT: {
        const accountId = action.payload.value;

        if (accountId === SELECT_ALL.value) {
          // ADD ALL ACCOUNT OPTIONS TO SELECTED ACCOUNTS
          state.accounts = [...state.accountOptions];

          // ADD ALL ADVERTISER OPTIONS
          const advertiserOptions = Object.values(
            state.allAdvertiserOptions
          ).reduce((allOptions: DropdownOption[], options) => {
            allOptions.push(...options);
            return allOptions;
          }, []);

          state.advertiserOptions = [SELECT_ALL, ...advertiserOptions];
        } else {
          // ADD SELECTED ACCOUNT TO CURRENT ACCOUNT SELECTIONS
          state.accounts.push(action.payload);

          if (!state.advertiserOptions.length) {
            state.advertiserOptions.push(SELECT_ALL);
          }

          // ADD ACCOUNT'S ADVERTISERS TO LIST OF ADVERTISER OPTIONS
          state.advertiserOptions.push(
            ...state.allAdvertiserOptions[accountId]
          );
        }

        // UPDATE ACTION VALUE IF EDITING EXISTING USER
        if (state.action === 0 || state.action === 1) {
          state.action += 2;
        }

        return state;
      }

      case USER_FORM_ACTION_TYPES.REMOVE_ACCOUNT: {
        const accountId = action.payload.value;

        if (accountId === SELECT_ALL.value) {
          // CLEAR ALL SELECTED ACCOUNTS
          state.accounts = [];

          // CLEAR ALL ADVERTISER OPTIONS
          state.advertiserOptions = [];

          // CLEAR ALL SELECTED ADVERTISERS
          state.advertisers = [];
        } else {
          // FILTER OUT UNSELECTED ACCOUNT DROPDOWN OPTON FROM SELECTED ACCOUNTS
          state.accounts = state.accounts.filter(
            ({ value }) => value !== accountId
          );

          // FILTER OUT UNSELECTED ACCOUNT'S ADVERTISER OPTIONS
          // -- note advertiserOption value === '{accountId}-{brandId}' --
          state.advertiserOptions = state.advertiserOptions.filter(
            ({ value }) => value.split('-')[0] !== accountId
          );

          // FILTER OUT UNSELECTED ACCOUNT'S ADVERTISER SELECTIONS
          // -- note advertiser value === '{accountId}-{brandId}' --
          state.advertisers = state.advertisers.filter(
            ({ value }) => value.split('-')[0] !== accountId
          );
        }

        // UPDATE ACTION VALUE IF EDITING EXISTING USER
        if (state.action === 0 || state.action === 1) {
          state.action += 2;
        }

        return state;
      }

      case USER_FORM_ACTION_TYPES.ADD_ADVERTISER: {
        const advertiserId = action.payload.value;

        if (advertiserId === SELECT_ALL.value) {
          // SELECT ALL ADVERTISER OPTIONS
          state.advertisers = [...state.advertiserOptions];
        } else {
          // ADD SELECTED ADVERTISER
          state.advertisers.push(action.payload);
        }

        // UPDATE ACTION VALUE IF EDITING EXISTING USER
        if (state.action === 0 || state.action === 1) {
          state.action += 2;
        }

        return state;
      }

      case USER_FORM_ACTION_TYPES.REMOVE_ADVERTISER: {
        if (action.payload.value === SELECT_ALL.value) {
          // REMOVE ALL SELECTED ADVERTISERS
          state.advertisers = [];
        } else {
          // REMOVE SELECTED ADVERTISER
          state.advertisers = state.advertisers.filter(({ value }) => {
            return value !== action.payload.value;
          });
        }

        // UPDATE ACTION VALUE IF EDITING EXISTING USER
        if (state.action === 0 || state.action === 1) {
          state.action += 2;
        }

        return state;
      }

      case USER_FORM_ACTION_TYPES.ADD_FEATURE:
        if (action.payload.value === SELECT_ALL.value) {
          state.features = [...state.featureOptions];
        } else {
          state.features.push(action.payload);
        }
        return state;

      case USER_FORM_ACTION_TYPES.REMOVE_FEATURE:
        if (action.payload.value === SELECT_ALL.value) {
          state.features = [];
        } else {
          state.features = state.features.filter(
            (option) => option.value !== action.payload.value
          );
        }
        return state;

      case USER_FORM_ACTION_TYPES.SET_NEW_PASSWORD:
        state.newPassword = action.payload;
        return state;

      case USER_FORM_ACTION_TYPES.SET_CONFIRM_PASSWORD:
        state.confirmPassword = action.payload;
        return state;

      case USER_FORM_ACTION_TYPES.LOAD_EXISTING_USER: {
        const [firstName = '', lastName = ''] = action.payload.name.split(' ');

        state.email = action.payload.email;
        state.lionEmail = '';
        state.firstName = firstName;
        state.lastName = lastName;
        state.newPassword = '';
        state.confirmPassword = '';
        state.action = 0;
        state.role = {
          label: action.payload.role,
          value: action.payload.roleId.toString(),
        };

        // IF ROLE IS ADMIN SELECT ALL ACCOUNTS, ADVERTISERS, & FEATURES
        if (action.payload.roleId === 1 || !action.payload.permissions.length) {
          // SELECT ALL ACCOUNTS
          state.accounts = state.accountOptions.filter(
            ({ value }) => value !== SELECT_ALL.value
          );

          // GET ALL ADVERTISER DROPDOWN OPITONS
          const advertisers = Object.keys(state.allAdvertiserOptions).reduce(
            (options: DropdownOption[], accountId) => {
              options.push(...state.allAdvertiserOptions[accountId]);
              return options;
            },
            []
          );

          // SELECT ALL BRAND OPTIONS
          state.advertisers = [...advertisers];

          // SET ALL BRAND DROPDOWN OPTIONS
          state.advertiserOptions = [...advertisers];

          // SELECT ALL FEATURES
          state.features = state.featureOptions.filter(
            ({ value }) => value !== SELECT_ALL.value
          );
        } else {
          // SELECT CURRENT PERMISSIONS
          action.payload.permissions.forEach(
            ({ accountName, accountId, brands }) => {
              const accountID = accountId.toString();
              // ONLY ADD TO SELECTED IF ACCOUNT STILL EXISTS
              if (!state.allAdvertiserOptions[accountID]) return;

              // ADD ACCOUNT TO SELECTED ACCOUNTS
              state.accounts.push({
                label: accountName,
                value: accountID,
              });

              // INCLUDE ADVERTISER OPTIONS FOR ACCOUNT
              state.advertiserOptions.push(
                ...state.allAdvertiserOptions[accountID]
              );

              // ADD BRANDS TO SELECTED ADVERTISERS
              brands.forEach((brand) => {
                const dropdownValue = `${accountID}-${brand.brandId}`;

                // CHECK IF BRAND STILL EXISTS
                const brandIndex = state.allAdvertiserOptions[
                  accountID
                ].findIndex(({ value }) => value === dropdownValue);

                if (brandIndex > -1) {
                  state.advertisers.push({
                    label: brand.brandName,
                    value: dropdownValue,
                  });
                }
              });
            }
          );

          // ADD SELECTED FEATURES
          action.payload.features.forEach((feature) => {
            state.features.push({
              label: feature.feature_name,
              value: feature.feature_id.toString(),
            });
          });
        }

        if (action.payload.role === 'Read Only') {
          state.featureOptions = state.featureOptions.filter(
            ({ value }) => value !== '5'
          );
        }

        return state;
      }

      case USER_FORM_ACTION_TYPES.RESET_USER_FORM:
        state = { ...INITIAL_USER_FORM_STATE };
        return state;

      default:
        return state;
    }
  },
  INITIAL_USER_FORM_STATE
);
