import React, { useEffect } from 'react';
import {
    DropdownOption,
    GoogleAdsFormState,
    GoogleAdsFormAction,
    GOOGLE_ADS_ACTION_TYPES,
    ExistingAccountDetails,
} from '../../state';

// UI COMPONENTS
import Radio from '@publicismedia-ds/ui-radio';
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import Fieldset from '@publicismedia-ds/ui-fieldset';
import { STATUS_OPTIONS } from '../../constants';

interface GoogleAdwordsFormProps {
    mEmails: string[];
    account?: ExistingAccountDetails;
    state: GoogleAdsFormState;
    dispatch: React.Dispatch<GoogleAdsFormAction>;
}

function GoogleAdsForm({
    mEmails,
    account,
    state,
    dispatch,
}: GoogleAdwordsFormProps) {
    // LOAD ACCOUNT DATA IF PRESENT
    useEffect(() => {
        if (!account) return;

        dispatch({
            type: GOOGLE_ADS_ACTION_TYPES.LOAD_FORM_DATA,
            payload: account,
        });
    }, [account]);

    // HANDLE MANAGER EMAIL SELECTION
    const onSelectEmail = (selected: DropdownOption) => {
        dispatch({
            type: GOOGLE_ADS_ACTION_TYPES.SET_MANAGER_EMAIL,
            payload: selected,
        });
    };

    // HANDLE SETTING CUSTOMER NAME VALUE
    const onSetCustomerName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        dispatch({
            type: GOOGLE_ADS_ACTION_TYPES.SET_CUSTOMER_NAME,
            payload: value,
        });
    };

    // HANDLE SETTING CUSTOMER ID VALUE
    const onSetCustomerId = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        dispatch({
            type: GOOGLE_ADS_ACTION_TYPES.SET_CUSTOMER_ID,
            payload: value,
        });
    };

    // HANDLE IS MANAGER ACCOUNT SELECTION
    const onSetIsManagerAccount = (
        evt: React.ChangeEvent<HTMLInputElement>,
        isChecked: boolean
    ) => {
        const { value } = evt.target;
        dispatch({
            type: GOOGLE_ADS_ACTION_TYPES.SET_IS_MANGER_ACCOUNT,
            payload: value as '1' | '0',
        });
    };

    // HANDLE ADD SUB CUSTOMER INPUTS ROW
    const onAddSubCustomer = () => {
        dispatch({
            type: GOOGLE_ADS_ACTION_TYPES.ADD_SUB_CUSTOMER,
        });
    };

    // HANDLE REMOVING SUB CUSTOMER
    const onRemoveSubCustomerRow = (index: number) => {
        dispatch({
            type: GOOGLE_ADS_ACTION_TYPES.REMOVE_SUB_CUSTOMER,
            payload: index,
        });
    };

    return (
        <div className="google-adwords-form-container">
            {state.isEditing ? (
                <h4 className="edit-account-header">
                    Google Ads Sub Accounts:
                </h4>
            ) : (
                <h2 className="title">Google Adwords</h2>
            )}

            <div className="inputs-row">
                {/* GOOGLE ADS MANAGER EMAIL */}
                <Dropdown
                    options={mEmails.map((email) => ({
                        label: email,
                        value: email,
                    }))}
                    onChange={onSelectEmail}
                    value={state.adwordsMangerEmail}
                    defaultValue={state.adwordsMangerEmail}
                    key="adwordsManagerEmail"
                    required
                >
                    Manger Email
                </Dropdown>
            </div>

            <div className="inputs-row">
                {/* CUSTOMER NAME */}
                <Textbox
                    onChange={onSetCustomerName}
                    value={state.customerName}
                    required
                    disabled={state.isEditing}
                >
                    Customer Name
                </Textbox>

                {/* CUSTOMER ID */}
                <Textbox
                    onChange={onSetCustomerId}
                    value={state.customerId}
                    disabled={state.isEditing}
                    required
                >
                    Customer ID
                </Textbox>
            </div>

            {/* --- MANAGER ACCOUNT SELECTION (NOT EDITIBLE) */}
            {!state.isEditing && (
                <div className="inputs-row">
                    <div>
                        <p>Is it a Manager Account?</p>
                    </div>
                    <div>
                        <Fieldset layout="horizontal" required invert>
                            <Radio
                                name="isManager"
                                value={'1'}
                                checked={state.isManagerAccount === '1'}
                                onChange={onSetIsManagerAccount}
                                invert
                            >
                                Yes
                            </Radio>
                            <Radio
                                name="isManager"
                                value="0"
                                checked={state.isManagerAccount === '0'}
                                onChange={onSetIsManagerAccount}
                                invert
                            >
                                No
                            </Radio>
                        </Fieldset>
                    </div>
                </div>
            )}
            {/* --- </ END > */}

            {/* GOOGLE ADS SUB ACCOUNTS (MANAGER ACCOUNT ONLY) */}
            {(state.isManagerAccount === '1' || state.isEditing) && (
                <>
                    <h4 className="edit-account-header">Sub Accounts:</h4>
                    {state.customerData.map((data, index) => {
                        return (
                            <div
                                className="inputs-row"
                                key={'sub-customer-' + index}
                            >
                                {/* SUB ACCOUNT NAME */}
                                <Textbox
                                    value={data.name}
                                    onChange={(
                                        evt: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        dispatch({
                                            type: GOOGLE_ADS_ACTION_TYPES.UPDATE_SUB_CUSTOMER,
                                            payload: {
                                                index,
                                                name: evt.target.value,
                                            },
                                        });
                                    }}
                                    disabled={data.existing}
                                    required
                                >
                                    Sub Account Name
                                </Textbox>

                                {/* SUB ACCOUNT ID */}
                                <Textbox
                                    value={data.id}
                                    onChange={(
                                        evt: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        dispatch({
                                            type: GOOGLE_ADS_ACTION_TYPES.UPDATE_SUB_CUSTOMER,
                                            payload: {
                                                index,
                                                id: evt.target.value,
                                            },
                                        });
                                    }}
                                    disabled={data.existing}
                                    required
                                >
                                    Sub Account ID
                                </Textbox>

                                {data.status !== undefined && (
                                    <Dropdown
                                        options={STATUS_OPTIONS}
                                        onChange={(
                                            selected: DropdownOption
                                        ) => {
                                            dispatch({
                                                type: GOOGLE_ADS_ACTION_TYPES.UPDATE_SUB_CUSTOMER,
                                                payload: {
                                                    index,
                                                    status:
                                                        selected.value ===
                                                        'true',
                                                },
                                            });
                                        }}
                                        value={{
                                            label: '',
                                            value: data.status.toString(),
                                        }}
                                        defaultValue={{
                                            label: '',
                                            value: data.status.toString(),
                                        }}
                                        required
                                    >
                                        Status
                                    </Dropdown>
                                )}

                                {/* ICON TO REMOVE SUB ACCOUNT INPUTS ROW */}
                                {!data.existing && index > 0 && (
                                    <i
                                        className="icon-close"
                                        onClick={() =>
                                            onRemoveSubCustomerRow(index)
                                        }
                                    />
                                )}
                            </div>
                        );
                    })}

                    {/* CREATE NEW INPUTS ROW FOR ADDITIONAL SUB ACCOUNT */}
                    <div className="insert-inputs-row-button">
                        <Button onClick={onAddSubCustomer} color="brand-2">
                            Agency <i className="icon-add" />
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}

export default GoogleAdsForm;
