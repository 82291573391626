import './Yield-Optimizer.scss';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FeatureIds } from '../../../constants';
import { ROUTES } from '../../../router/router';
import { useAppSelector } from '../../../hooks';
import { numberWithCommas } from '../../../utils';

// UI COMPONENTS
import { Column } from '@publicismedia-ds/ui-charts';
import Product from '../../../components/Product/Product';
import BidChangeTable from '../../../components/Charts/Yield-Optimizer-Charts/Bid-Change-Table';
import YieldOptimizerBulkActions from '../../../components/Bulk-Actions/Yield-Optimizier-Bulk-Actions';
import BulletChartsContainer from '../../../components/Charts/Bullet-Charts-Container/Bullet-Charts-Container';
import TopKeywordChangeTable from '../../../components/Charts/Yield-Optimizer-Charts/Top-Keyword-Changed-Table';
import TopCampaignChangeTable from '../../../components/Charts/Yield-Optimizer-Charts/Top-Campaigns-Changed-Table';

function YieldOptimizer() {
    const navigate = useNavigate();

    // REDUX STATE
    const user = useAppSelector(({ auth }) => auth.user);
    const settings = useAppSelector((state) => state.universalSettings);
    const { data, bulksheets } = useAppSelector(
        ({ products }) => products.yieldOptimizer
    );

    // CHECK USER FEATURE PERMISSION
    useEffect(() => {
        if (
            !user?.features[FeatureIds.ALL] &&
            !user?.features[FeatureIds.YIELD_OPTIMIZER]
        ) {
            navigate(ROUTES.HOME);
        }
    }, [user]);

    return (
        <>
            <Product>
                <div
                    style={{
                        marginTop: '1rem',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    {bulksheets && settings.optimizationType && (
                        <YieldOptimizerBulkActions
                            bulksheetOption={settings.optimizationType}
                            bInsights={data?.insights?.bInsights || ''}
                            cInsights={data?.insights?.cInsights || ''}
                        />
                    )}
                </div>
                {data ? (
                    <>
                        <div className="yield-optimizer-content">
                            {/* --- COST --- */}
                            {data['Cost'] && (
                                <div className="row">
                                    <BulletChartsContainer
                                        header={
                                            settings.useWT
                                                ? 'Weighted Cost'
                                                : 'Cost'
                                        }
                                        data={data['Cost'] || []}
                                    />
                                </div>
                            )}

                            {/* --- EXPECTED SUMMARY CHART--- */}
                            {settings.optimizationType !== 'caps' && (
                                <>
                                    <div className="row yield-optimizer-expected-chart">
                                        {/* --- COST --- */}
                                        <div className="expected-chart-item">
                                            <p className="expected-chart-item-value">
                                                $
                                                {numberWithCommas(
                                                    data.summary?.cost || 0,
                                                    2
                                                )}
                                            </p>
                                            <p className="expected-chart-item-label">
                                                expected <span>cost</span>
                                            </p>
                                        </div>

                                        {/* --- CONVERSIONS COLUMN --- */}
                                        <div className="expected-chart-item expected-chart-item-middle">
                                            <p className="expected-chart-item-value">
                                                {data.summary.conversionType ===
                                                    'Revenue' && '$'}
                                                {numberWithCommas(
                                                    data.summary
                                                        ?.conversionColumn || 0,
                                                    2
                                                )}
                                            </p>
                                            <p className="expected-chart-item-label">
                                                expected{' '}
                                                <span>
                                                    {data.summary
                                                        .conversionType ===
                                                    'Actions'
                                                        ? 'Conversions'
                                                        : 'Revenue'}
                                                </span>
                                            </p>
                                        </div>

                                        {/* --- EFFICIENCY --- */}
                                        <div className="expected-chart-item">
                                            <p className="expected-chart-item-value">
                                                $
                                                {numberWithCommas(
                                                    data.summary?.efficiency ||
                                                        0,
                                                    2
                                                )}
                                            </p>
                                            <p className="expected-chart-item-label">
                                                expected <span>efficiency</span>
                                            </p>
                                        </div>
                                    </div>

                                    {/* --- KEYWORD BID CHANGE CHART --- */}
                                    <div className="row">
                                        <h3>
                                            {data.reportType === 'keyword' &&
                                                'Keyword'}
                                            {data.reportType ===
                                                'productGroup' &&
                                                'Product Group'}
                                            {data.reportType === 'DSA' && 'DSA'}{' '}
                                            bid change summary
                                        </h3>
                                        <BidChangeTable
                                            data={data.bidChanges}
                                            reportType={data.reportType}
                                            conversionColumn={
                                                data.columnNames
                                                    .conversionColumn
                                            }
                                            conversionType={
                                                data?.conversionType || null
                                            }
                                        />
                                    </div>

                                    {/* --- KEYWORD BID CHANGE DISTRIBUTION GRAPH ---  */}
                                    <div className="row">
                                        <h3>
                                            {data.reportType === 'keyword' &&
                                                'Keyword'}
                                            {data.reportType ===
                                                'productGroup' &&
                                                'Product Group'}
                                            {data.reportType === 'DSA' && 'DSA'}{' '}
                                            bid distribution
                                        </h3>
                                        <Column
                                            data={
                                                [
                                                    {
                                                        name: 'Bid Decrease',
                                                        data:
                                                            data.keywordBidding[
                                                                'Bid Decrease '
                                                            ] || [],
                                                    },
                                                    {
                                                        name: 'Bid Increase',
                                                        data:
                                                            data.keywordBidding[
                                                                'Bid Increase'
                                                            ] || [],
                                                    },
                                                    {
                                                        name: 'No Bid Change',
                                                        data:
                                                            data.keywordBidding[
                                                                'No Bid Change'
                                                            ] || [],
                                                    },
                                                ] || []
                                            }
                                            options={{
                                                chart: {
                                                    toolbar: {
                                                        show: false,
                                                        tools: {
                                                            download: false,
                                                        },
                                                    },
                                                },
                                                xaxis: {
                                                    categories:
                                                        data.keywordBidding.xAxis.map(
                                                            (label) =>
                                                                label.toString()
                                                        ) || [],
                                                },
                                            }}
                                            width="100%"
                                        />
                                    </div>

                                    {/* --- TOP KEYWORD CHANGED ---  */}
                                    <div className="row">
                                        <h3>
                                            Top{' '}
                                            {data.reportType === 'keyword' &&
                                                'Keyword'}
                                            {data.reportType ===
                                                'productGroup' &&
                                                'Product Group'}
                                            {data.reportType === 'DSA' && 'DSA'}{' '}
                                            changed
                                        </h3>
                                        <TopKeywordChangeTable
                                            data={data.keywordChanges}
                                            reportType={data.reportType}
                                            conversionColumn={
                                                data.columnNames
                                                    .conversionColumn
                                            }
                                            conversionType={
                                                data.conversionType || null
                                            }
                                        />
                                    </div>

                                    {/* --- TOP CAMPAINGS CHANGED CHART --- */}
                                    <div className="row">
                                        <h3>top campaigns changed</h3>
                                        <TopCampaignChangeTable
                                            data={data.campaignChanges}
                                            reportType={data.reportType}
                                            conversionColumn={
                                                data.columnNames
                                                    .conversionColumn
                                            }
                                            conversionType={
                                                data.conversionType || null
                                            }
                                        />
                                    </div>

                                    {/* --- OPTIMIZATION INSIGHTS --- */}
                                    <div className="row">
                                        <h3>optimization insights</h3>
                                        <ul className="optimization-insights-list">
                                            <li>{data.insights.bInsights}</li>
                                            <li>{data.insights.cInsights}</li>
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                ) : null}
            </Product>
        </>
    );
}

export default YieldOptimizer;
