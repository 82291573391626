import { NoteData, NoteDetails, UserData } from '../../models';

export interface EditNoteData extends NoteData {
    deleteFlag: boolean;
    isNew: boolean;
}

export interface EditNotesState {
    agencyId: string; // '20700000001425309';
    advertiserId: string; // '21700000001969587';
    environment: 'qa' | 'prod';
    notesData: EditNoteData[];
}

export const DEFAULT_NEW_NOTE: EditNoteData = {
    noteId: 0,
    noteText: '',
    updatedByUserId: 0,
    isStrikeThrough: false,
    deleteFlag: false,
    isNew: true,
    history: [],
    updatedByUserName: '',
    updatedTimestamp: '',
    createdByUserId: 0,
    createdByUserName: '',
    createdTimestamp: '',
};

export enum EDIT_NOTE_ACTION_TYPES {
    LOAD_NOTES_DATA = 'load_notes_data',
    SET_NOTE_TEXT = 'set_note_text',
    SET_STRIKE_FLAG = 'set_strike_flag',
    SET_DELETE_FLAG = 'set_delete_flag',
    ADD_NEW_NOTE = 'add_new_note',
    REMOVE_NEW_NOTE = 'remove_new_note',
    RESET_NOTES = 'reset_notes',
}

interface LoadNotesDataAction {
    type: EDIT_NOTE_ACTION_TYPES.LOAD_NOTES_DATA;
    payload: NoteDetails;
}

interface SetNoteTextAction {
    type: EDIT_NOTE_ACTION_TYPES.SET_NOTE_TEXT;
    payload: { index: number; text: string };
}

interface SetStrikeFlagAction {
    type: EDIT_NOTE_ACTION_TYPES.SET_STRIKE_FLAG;
    payload: { index: number; isChecked: boolean };
}

interface SetDeleteFlagAction {
    type: EDIT_NOTE_ACTION_TYPES.SET_DELETE_FLAG;
    payload: { index: number; isChecked: boolean };
}

interface AddNewNoteAction {
    type: EDIT_NOTE_ACTION_TYPES.ADD_NEW_NOTE;
    payload: UserData; // userId
}

interface RemoveNewNoteAction {
    type: EDIT_NOTE_ACTION_TYPES.REMOVE_NEW_NOTE;
    payload: number; // index
}

interface ResetNotesAction {
    type: EDIT_NOTE_ACTION_TYPES.RESET_NOTES;
}

export type EditNotesAction =
    | LoadNotesDataAction
    | SetNoteTextAction
    | SetStrikeFlagAction
    | SetDeleteFlagAction
    | AddNewNoteAction
    | RemoveNewNoteAction
    | ResetNotesAction;
