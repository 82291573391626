import { useEffect, useState } from 'react';

// UI COMPONENTS
import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import Button from '@publicismedia-ds/ui-button';

// TYPES
import { UserActivityDetails } from '../../state';

// COMPONENT PROPS
interface UserActivityTableProps {
    data: UserActivityDetails[];
    onDownload?: ((...args: unknown[]) => Promise<void>) | null;
}

// FUNCTIONAL COMPONENT (USER ACTIVITY DATA TABLE)
function UserActivityTable({ data, onDownload }: UserActivityTableProps) {
    // USER ACTIVITY DATA PROVIDER
    const [provider, setProvider] = useState<any>();

    // UPDATE DATA PROVIDER ON DATA CHANGES
    useEffect(() => {
        setProvider(new DataProvider({ data }));
    }, [data]);

    return (
        <div className="user-activity-table">
            <DataTable
                data={provider}
                bindKey="feature"
                above={
                    <div>
                        <Button onClick={onDownload} disabled={!onDownload}>
                            Download Bulksheet
                        </Button>
                    </div>
                }
            >
                <ColumnTemplate bind="feature">Feature</ColumnTemplate>
                <ColumnTemplate bind="pageViews" align="left">
                    Page Views
                </ColumnTemplate>
                <ColumnTemplate bind="runs" align="left">
                    Runs
                </ColumnTemplate>
                <ColumnTemplate bind="sessionTime" align="left">
                    Session Time
                </ColumnTemplate>
            </DataTable>
        </div>
    );
}

export default UserActivityTable;
