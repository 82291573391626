import './Edit-Existing-Account.scss';

import { ROUTES } from '../../router/router';
import { onPreventEnterKeySubmit } from '../../utils';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';

// API REQUESTS
import { getPlatformsData, updateAccount } from '../../api';

// TYPES
import {
    ManagerEmail,
    DropdownOption,
    ExistingAccountDetails,
} from '../../state';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import AdminPage from '../../components/Admin-Page/Admin-Page';
import EditBingAccounts from '../../components/Account-Onboarding-Forms/Edit-Account/Edit-Bing-Ads';
import EditSA360Account from '../../components/Account-Onboarding-Forms/Edit-Account/Edit-SA-360-Account';
import EditGoolgeAdsAccounts from '../../components/Account-Onboarding-Forms/Edit-Account/Edit-Google-Ads';
import EditCustomColumns from '../../components/Account-Onboarding-Forms/Edit-Account/Edit-Custom-Columns';
import EditKenshooAccount from '../../components/Account-Onboarding-Forms/Edit-Account/Edit-Kenshoo-Account';
import { DEFAULT_API_ERROR } from '../../constants';

function EditExistingAccount() {
    // HOOKS
    const navigate = useNavigate();
    const asyncWrapper = useAsyncWrapper();

    // FORM STATE (REDUX)
    const state = useAppSelector(({ editAccountForm }) => editAccountForm);

    // REDUX ACTIONS
    const { loadExistingAccount, alertError, alertSuccess, setLoading } =
        useActions();

    // ACCOUNT DATA INCLUDED IN NAVIGATION STATE
    const account = useLocation().state as ExistingAccountDetails;

    // LIST OF AVAILABLE MANAGER EMAILS FOR SA360/BING ADS/GOOGLE ADS
    const [managerEmails, setManagerEmails] = useState<string[]>([]);

    const mEmailOptions = useMemo(() => {
        const options: DropdownOption[] = managerEmails.map((email) => ({
            label: email,
            value: email,
        }));
        return options;
    }, [managerEmails]);

    // FETCH AND STORE MANAGER EMAILS
    const loadManagerEmails = asyncWrapper(async function () {
        // FETCH PLATFORM DATA
        const data = await getPlatformsData();

        // CHECK PLATFORM DATA IS PRESENT
        if (!data || !data?.platformList.length) return;

        // MANAGER EMAILS DATA (LAST ITEM OF PLATFORMS DATA ARRAY)
        const [emails]: ManagerEmail[] = data.platformList.slice(-1);

        // STORE MANAGER EMAILS
        setManagerEmails(emails.mEmail);
    });

    // GET MANAGER EMAILS ON INITIAL LOAD
    useEffect(() => {
        loadManagerEmails();
    }, []);

    // LOAD ACCOUNT DATA INTO FORM
    useEffect(() => {
        if (!account) return;
        loadExistingAccount(account);
    }, [account]);

    // HANDLE UPDATE ACCOUNT SUBMIT
    const onSubmitUpdateAccount = asyncWrapper(async (evt: React.FormEvent) => {
        evt.preventDefault();

        const data = await updateAccount(state);

        if (data?.status === 'failure') {
            alertError(data?.msg || DEFAULT_API_ERROR);
        } else {
            alertSuccess('Successfully updated account.');
            navigate(ROUTES.ACCOUNT_ONBOARDING);
        }
    });

    return (
        <AdminPage header="Edit Account">
            <div className="account-onboarding-form-container">
                <div>
                    <p style={{ margin: '1rem 0' }}>
                        <strong>Account Name: </strong>
                        {state.accountName}
                    </p>
                    <p style={{ margin: '1rem 0' }}>
                        <strong>Agency ID: </strong>
                        {state.agencyId}
                    </p>
                    <p style={{ margin: '1rem 0' }}>
                        <strong>Platform: </strong>
                        {state.platform}
                    </p>
                </div>
                <form
                    onSubmit={onSubmitUpdateAccount}
                    id="editForm"
                    onKeyDown={onPreventEnterKeySubmit}
                >
                    {/* --- EDIT SA 360 ACCOUNT DATA --- */}
                    {(!!state.sa360ManagerEmail ||
                        !!state.advertisers?.length) && (
                        <EditSA360Account mEmails={mEmailOptions} />
                    )}

                    {/* --- EDIT KENSHOO REPORT DATA --- */}
                    {!!state.kenshooReportDetails?.length && (
                        <EditKenshooAccount />
                    )}

                    {/* --- EDIT BING ADS DATA --- */}
                    {state.editIsBingAdsSupport && (
                        <EditBingAccounts mEmails={mEmailOptions} />
                    )}

                    {/* --- EDIT GOOGLE ADS DATA --- */}
                    {state.editIsGoogleAdsSupport && (
                        <EditGoolgeAdsAccounts mEmails={mEmailOptions} />
                    )}

                    {/* --- EDIT CUSTOM CONVERSION COLUMNS DATA --- */}
                    {state.isCustomColumnSupport && (
                        <EditCustomColumns type="conversion" />
                    )}

                    {/* --- EDIT CUSOM REVENUE COLUMNS DATA --- */}
                    {state.isRevenueSupport && (
                        <EditCustomColumns type="revenue" />
                    )}

                    <div className="submit-button-container">
                        {/* --- CANCEL BUTTON IF EDITING EXISTING ACCOUNT */}
                        <Button
                            display="secondary"
                            onClick={() => navigate(ROUTES.ACCOUNT_ONBOARDING)}
                        >
                            Cancel
                        </Button>

                        {/* SUBMIT FOR NEW/UPDATING ACCOUNT */}
                        <Button type="submit">Update</Button>
                    </div>
                </form>
            </div>
        </AdminPage>
    );
}

export default EditExistingAccount;
