import {
    GETAddSchedule,
    POSTEditGoalBody,
    DATE_TO_RUN_OPTIONS,
    DAYS_TO_RUN_OPTIONS,
    ScheduleGoalFormState,
    POSTEditSchedule,
} from '../../state';

export const formatAddScheduleBody = (
    userId: string,
    formState: ScheduleGoalFormState
): GETAddSchedule | undefined => {
    // CHECK FOR EXECUTION WAY SELECTION
    if (!formState.executionWay) return;

    const body = {
        userId,
        scheduleName: formState.scheduleName,
        goalId: formState.selectedGoals.map(({ value }) => value).join(','),
        moduleName: formState.selectedModules
            .map(({ value }) => value)
            .join(','),
        executionWay: formState.executionWay?.value,
        frequency: formState.frequency?.value || '',
        daysToRun: formState.daysToRun.map(({ value }) => value).join(','),
        timeOfTheDay: formState.timeOfDay?.value || '12am',
        emails: formState.emails.join(','),
    };

    if (body.frequency === 'daily') {
        body.daysToRun = '';
    }

    if (body.frequency === 'weekly' && !body.daysToRun) {
        body.daysToRun = DAYS_TO_RUN_OPTIONS[0].value;
    }

    if (body.frequency === 'monthly' && !body.daysToRun) {
        body.daysToRun = DATE_TO_RUN_OPTIONS[0].value;
    }

    if (body.executionWay === 'runNow') {
        body.timeOfTheDay = '';
    }

    return body;
};

export const formatEditScheduleBody = (
    userId: string,
    scheduleId: number,
    formState: ScheduleGoalFormState
) => {
    // INITIAL FORMATING WITHOUT SCHEDULE ID
    const formatted = formatAddScheduleBody(userId, formState);

    if (!formatted) return;

    // INCLUDE SCHEDULE ID WITH REQUEST BODY
    const body: POSTEditSchedule = {
        ...formatted,
        scheduleId: scheduleId.toString(),
    };

    return body;
};
