import { PRODUCTS_ACTION_TYPES } from '../action-types';

import {
    SetLoadingAction,
    ClearProductsDataAction,
    SetYieldOptimizerDataAction,
    ToggleDisplaySettingsAction,
    SetScenarioPlannerDataAction,
    SetPerfomanceMonitorDataAction,
    SetYieldOptimizerBulksheetAction,
    SetPerformanceDiagnosticDataAction,
} from '../actions';

import {
    DarwinDataV3,
    GETDarwinDataV3,
    ScenarioPlannerData,
    PerformanceMonitorData,
    PerformanceDiagnosticData,
} from '../models';

export const setLoading = (value: boolean): SetLoadingAction => {
    return {
        type: PRODUCTS_ACTION_TYPES.SET_LOADING,
        payload: { value },
    };
};

// TOGGLE DISPLAY SETTINGS
export const toggleDisplaySettings = (
    value?: boolean
): ToggleDisplaySettingsAction => {
    return {
        type: PRODUCTS_ACTION_TYPES.TOGGLE_DISPLAY_SETTINGS,
        payload: {
            value,
        },
    };
};

export const setPerformanceMonitorData = (
    data: PerformanceMonitorData | null
): SetPerfomanceMonitorDataAction => {
    return {
        type: PRODUCTS_ACTION_TYPES.SET_PERFORMANCE_MONITOR_DATA,
        payload: data,
    };
};

export const setScenarioPlannerData = (
    data: ScenarioPlannerData | null,
    isAdhoc: boolean = false
): SetScenarioPlannerDataAction => {
    return {
        type: PRODUCTS_ACTION_TYPES.SET_SCENARIO_PLANNER_DATA,
        payload: { data, isAdhoc },
    };
};

export const setPerformanceDiagnosticData = (
    data: PerformanceDiagnosticData | null,
    isAdhoc: boolean = false
): SetPerformanceDiagnosticDataAction => {
    return {
        type: PRODUCTS_ACTION_TYPES.SET_PERFORMANCE_DIAGNOSTIC_DATA,
        payload: { data, isAdhoc },
    };
};

export const setYieldOptimizerData = (
    data: GETDarwinDataV3 | DarwinDataV3 | null
): SetYieldOptimizerDataAction => {
    return {
        type: PRODUCTS_ACTION_TYPES.SET_YIELD_OPTIMIZER_DATA,
        payload: data,
    };
};

export const setYieldOptimizerBulksheet = (
    bulksheet: boolean
): SetYieldOptimizerBulksheetAction => {
    return {
        type: PRODUCTS_ACTION_TYPES.SET_YIELD_OPTIMIZER_BULKSHEET,
        payload: bulksheet,
    };
};

export const clearProductsData = (): ClearProductsDataAction => {
    return {
        type: PRODUCTS_ACTION_TYPES.CLEAR_PRODUCTS_DATA,
    };
};
