import './Input-Error-Message.scss';

// COMPONENT PROPS
interface InputErrorProps {
    display?: boolean;
    children: React.ReactNode;
}

// FUNCTIONAL COMPONENT (INPUT ERROR MESSAGE)
function InputError({ children, display }: InputErrorProps) {
    return display || display === undefined ? (
        <div className="input-error-container">
            <span className="input-error-text">{children}</span>
        </div>
    ) : null;
}

export default InputError;
