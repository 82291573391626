import { useEffect, useState } from 'react';

// TYPES
import { AnomalyItem } from '../../../state';

// UI COMPONENTS
import DataTable, {
  DataProvider,
  ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import SearchFilter from '@publicismedia-ds/ui-search-filter';

// UTILS
import { numberWithCommas } from '../../../utils';

// COMPONENT PROPS
interface AnomalyDataTableProps {
  data: AnomalyItem[];
  conv: string;
}

// FUNCTIONAL COMPONENT (ANOMALY DATA TABLE)
function AnomalyDataTable({ data, conv }: AnomalyDataTableProps) {
  // TABLE STATE
  const [anomaliesDataProvider, setAnomaliesDataProvider] = useState<any>([]);

  // CONVERSION NAME
  const convName = conv[0] ? conv[0].toUpperCase() + conv?.slice(1) : 'N/A';

  // SET DATA PROVIDER ON INITIAL LOAD
  useEffect(() => {
    if (data.length > 1) {
      setAnomaliesDataProvider(new DataProvider({ data }));
    }
  }, [data]);

  // HANDLE SEARCH STUDIES TABLE CHANGE
  const onSearchChange = (text: string) => {
    const updatedProvider = new DataProvider({
      data,
      searchQuery: text,
    });
    setAnomaliesDataProvider(updatedProvider);
  };

  return (
    <>
      <DataTable
        data={anomaliesDataProvider}
        stickyHead={true}
        bindKey="camp_name"
        emptyState={<h5>No anomaly data found.</h5>}
        above={
          <div className="anomalies-data-table-above">
            <SearchFilter
              className="search-studies-input"
              onSearchChange={onSearchChange}
            />
          </div>
        }
        hasVirtualScrolling={false}
      >
        <ColumnTemplate bind="camp_name" isSortable minWidth>
          Campaigns
        </ColumnTemplate>
        <ColumnTemplate
          className="data-anomaly-flag"
          bind="impressions"
          isSortable
          align="center"
          onRender={(impressions: any) => {
            return (
              <div
                className={`${
                  impressions.anomaly_flag === 'yes' ? 'anomaly-flag' : ''
                }`}
              >
                <span>{numberWithCommas(impressions.actual)}</span>
              </div>
            );
          }}
          minWidth
        >
          Impressions
        </ColumnTemplate>
        <ColumnTemplate
          bind="impressions"
          align="center"
          isSortable
          onRender={(impressions: any) => (
            <>
              <span>
                {numberWithCommas(impressions.expected_min)} -{' '}
                {numberWithCommas(impressions.expected_max)}
              </span>
            </>
          )}
          minWidth
        >
          Expected Impressions
        </ColumnTemplate>
        <ColumnTemplate
          className="data-anomaly-flag"
          bind="clicks"
          align="center"
          isSortable
          onRender={(clicks: any) => (
            <div
              className={`${
                clicks.anomaly_flag === 'yes' ? 'anomaly-flag' : ''
              }`}
            >
              <span>{numberWithCommas(clicks.actual)}</span>
            </div>
          )}
          minWidth
        >
          Clicks
        </ColumnTemplate>
        <ColumnTemplate
          bind="clicks"
          align="center"
          isSortable
          onRender={(clicks: any) => (
            <>
              <span>
                {numberWithCommas(clicks.expected_min)} -{' '}
                {numberWithCommas(clicks.expected_max)}
              </span>
            </>
          )}
          minWidth
        >
          Expected Clicks
        </ColumnTemplate>
        <ColumnTemplate
          className="data-anomaly-flag"
          bind="conv"
          align="center"
          isSortable
          onRender={(conv: any) => (
            <div
              className={`${conv.anomaly_flag === 'yes' ? 'anomaly-flag' : ''}`}
            >
              <span>{numberWithCommas(conv.actual)}</span>
            </div>
          )}
          minWidth
        >
          {convName}
        </ColumnTemplate>
        <ColumnTemplate
          bind="conv"
          align="center"
          isSortable
          onRender={(conv: any) => (
            <>
              <span>
                ${numberWithCommas(conv.expected_min)} - $
                {numberWithCommas(conv.expected_max)}
              </span>
            </>
          )}
          minWidth
        >
          Expected {convName}
        </ColumnTemplate>
        <ColumnTemplate
          className="data-anomaly-flag"
          bind="cost"
          align="center"
          isSortable
          onRender={(cost: any) => (
            <div
              className={`${cost.anomaly_flag === 'yes' ? 'anomaly-flag' : ''}`}
            >
              <span>${numberWithCommas(cost.actual, 2)}</span>
            </div>
          )}
          minWidth
        >
          Cost
        </ColumnTemplate>
        <ColumnTemplate
          bind="cost"
          align="center"
          isSortable
          onRender={(cost: any) => (
            <>
              <span>
                ${numberWithCommas(cost.expected_min, 2)} - $
                {numberWithCommas(cost.expected_max, 2)}
              </span>
            </>
          )}
          minWidth
        >
          Expected Cost
        </ColumnTemplate>
        <ColumnTemplate
          className="data-anomaly-flag"
          bind="eff_cal"
          align="center"
          isSortable
          onRender={(eff_cal: any) => (
            <div
              className={`${
                eff_cal.anomaly_flag === 'yes' ? 'anomaly-flag' : ''
              }`}
            >
              <span>${numberWithCommas(eff_cal.actual, 2)}</span>
            </div>
          )}
          minWidth
        >
          Efficiency
        </ColumnTemplate>
        <ColumnTemplate
          bind="eff_cal"
          align="center"
          isSortable
          onRender={(eff_cal: any) => (
            <>
              <span>
                ${numberWithCommas(eff_cal.expected_min, 2)} - $
                {numberWithCommas(eff_cal.expected_max, 2)}
              </span>
            </>
          )}
          minWidth
        >
          Expected Efficiency
        </ColumnTemplate>
      </DataTable>
    </>
  );
}

export default AnomalyDataTable;
