import React, { useEffect, useMemo, useReducer } from 'react';
import { useAppSelector, useAsyncWrapper } from '../../hooks';

// API REQUESTS
import {
    getGoalSetting,
    getAccountsData,
    getUserAgencies,
    downloadPacingDoc,
} from '../../api';

// CONSTANTS
import { ACCOUNT_PLATFORMS } from '../../constants';

// TYPES
import {
    DropdownOption,
    pacingDocReducer,
    FormulaColumnItem,
    PACING_DOC_ACTION_TYPES,
    DEFAULT_PACING_DOC_STATE,
} from '../../state';

// UI COMPONENTS
import FormulaColumns from './Formula-Columns';
import Button from '@publicismedia-ds/ui-button';
import Checkbox from '@publicismedia-ds/ui-checkbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';

// UTILS
import {
    capitalize,
    sortDropdownOptions,
    validateFormulaColumn,
    onPreventEnterKeySubmit,
} from '../../utils';

// EXCLUDED CUSTOM COLUMNS
const EXCLUDED_COLUMNS: { [key: string]: boolean } = {
    average_position: true,
    cpt: true,
    ctr: true,
    ers: true,
};

// FUNCTIONAL COMPONENT (PACING DOC FORM)
function PacingDocForm() {
    const wrapper = useAsyncWrapper();

    // REDUX STATE
    const { user } = useAppSelector(({ auth }) => auth);
    const goalList = useAppSelector(({ goals }) => goals.list);

    // PACING DOC FORM STATE
    const [state, dispatch] = useReducer(
        pacingDocReducer,
        DEFAULT_PACING_DOC_STATE
    );

    // FETCH AND STORE AGENCIES ON INITIAL RENDER
    useEffect(() => {
        (async () => {
            if (user) {
                const agencies = await getUserAgencies(user.id);
                dispatch({
                    type: PACING_DOC_ACTION_TYPES.LOAD_AGENCIES,
                    payload: agencies,
                });
            }
        })();
    }, []);

    // CREATE AGENCY MENU OPTIONS
    const agencyOptions = useMemo(() => {
        const options = state.agencies.map((agency) => ({
            label: agency.agencyName,
            value: agency.agencyId,
        }));
        return sortDropdownOptions(options);
    }, [state.agencies]);

    // CREATE ADVERTISER MENU OPTIONS FOR SELECTED AGENCY
    const advertiserOptions = useMemo((): DropdownOption[] => {
        const options: DropdownOption[] = [];

        if (state.accountData) {
            for (const advertiser of state.accountData.advertisers) {
                options.push({
                    label: advertiser.advertiserName,
                    value: advertiser.advertiserId,
                });
            }
        }

        return sortDropdownOptions(options);
    }, [state.accountData]);

    // CREATE GOAL MENU OPTIONS FOR SELECTED ADVERTISER
    const goalOptions = useMemo(() => {
        const options: DropdownOption[] = [];
        const advertiserId = state.advertiser?.value;

        if (advertiserId) {
            for (const goal of goalList) {
                if (goal.advertiserId === advertiserId) {
                    options.push({
                        label: goal.goalMappingName,
                        value: goal.goalId,
                    });
                }
            }
        }

        return sortDropdownOptions(options);
    }, [state.advertiser]);

    // HANDLE AGENCY SELECTION
    const onSelectAgency = wrapper(async (agency: DropdownOption) => {
        if (user) {
            const accountData = await getAccountsData(user.id, agency.value);
            dispatch({
                type: PACING_DOC_ACTION_TYPES.SET_AGENCY,
                payload: {
                    agency,
                    accountData,
                    platformId: ACCOUNT_PLATFORMS[agency.value] || null,
                },
            });
        }
    });

    // HANDLE ADVERTISER SELECTION
    const onSelectAdvertiser = wrapper(async (selected: DropdownOption) => {
        const columnOptions: DropdownOption[] = [];
        let accountId: number | null = null;
        let brandId: number | null = null;

        const foundGoal = goalList.find(
            ({ advertiserId }) => advertiserId === selected.value
        );

        if (foundGoal && user) {
            const goalDetails = await getGoalSetting(foundGoal.goalId, user.id);
            if (goalDetails) {
                // CREATE ACTION DROPDOWNS
                for (const actionColumn of goalDetails.selectData.action) {
                    if (!EXCLUDED_COLUMNS[actionColumn]) {
                        columnOptions.push({
                            label: capitalize(actionColumn),
                            value: actionColumn,
                        });
                    }
                }
                // CREATE REVENUE DROPDOWNS
                for (const revenueColumn of goalDetails.selectData.revenue) {
                    if (!EXCLUDED_COLUMNS[revenueColumn]) {
                        columnOptions.push({
                            label: capitalize(revenueColumn),
                            value: revenueColumn,
                        });
                    }
                }

                // FIND CORRESPONDING ACCOUNT DETAILS
                const foundAccount = goalDetails.selectData.accounts.find(
                    ({ account_name }) => account_name === state.agency?.label
                );

                // SET ACCOUND & BRAND IDs
                if (foundAccount) {
                    accountId = foundAccount.account_id;
                    brandId =
                        foundAccount.brands.find(
                            ({ brandName }) => brandName === selected.label
                        )?.brandId || null;
                }
            }
        }
        dispatch({
            type: PACING_DOC_ACTION_TYPES.SET_ADVERTISER,
            payload: {
                advertiser: selected,
                columnOptions: sortDropdownOptions(columnOptions),
                accountId,
                brandId,
            },
        });
    });

    // HANDLE GOALS SELECTION
    const onSelectGoals = (selections: DropdownOption[]) => {
        dispatch({
            type: PACING_DOC_ACTION_TYPES.SET_GOALS,
            payload: selections,
        });
    };

    // HANDLE COLUMNS SELECTION
    const onSelectColumns = (selections: DropdownOption[]) => {
        dispatch({
            type: PACING_DOC_ACTION_TYPES.SET_COLUMNS,
            payload: selections,
        });
    };

    // HANDLE TOGGLE DISPLAY CUSTOM FORMULA FORM
    const onUseFormulaColumns = (
        _evt: React.ChangeEvent<HTMLInputElement>,
        isChecked: boolean
    ) => {
        dispatch({
            type: PACING_DOC_ACTION_TYPES.USE_FORMULA_COLUMNS,
            payload: isChecked,
        });
    };

    // HANDLE ADD CUSTOM FORMULA
    const onAddFormula = (formula: FormulaColumnItem) => {
        validateFormulaColumn(formula, state.formulaColumns);
        dispatch({
            type: PACING_DOC_ACTION_TYPES.ADD_FORMULA_COLUMN,
            payload: formula,
        });
    };

    // HANDLE REMOVING CUSTOM FORMULA
    const onRemoveFormula = (index: number) => {
        dispatch({
            type: PACING_DOC_ACTION_TYPES.REMOVE_FORMULA_COLUMN,
            payload: index,
        });
    };

    // HANDLE UPDATING CUSTOM FORMULA
    const onUpdateFormula = (formula: FormulaColumnItem, index: number) => {
        dispatch({
            type: PACING_DOC_ACTION_TYPES.UPDATE_FORMULA_COLUMN,
            payload: {
                formula,
                index,
            },
        });
    };

    // HANDLE PACING DOC DOWNLOAD
    const onSubmit = wrapper(async (evt: React.FormEvent) => {
        evt.preventDefault();

        await downloadPacingDoc(state);

        dispatch({
            type: PACING_DOC_ACTION_TYPES.RESET_FORM,
        });
    });

    return (
        <div className="pacing-doc-form-container">
            <form
                className="pacing-doc-form-content"
                onSubmit={onSubmit}
                onKeyDown={onPreventEnterKeySubmit}
            >
                {/* --- DISPLAY FORMULA COLUMNS --- */}
                <Row>
                    <div className="formula-columns-checkbox">
                        <Checkbox
                            onChange={onUseFormulaColumns}
                            checked={state.useFormulaColumns}
                            disabled={!state.columnOptions.length}
                            toggle
                            invert
                        >
                            Forumula Columns
                        </Checkbox>
                    </div>
                </Row>
                <Row>
                    {/* --- AGENCY --- */}
                    <Column>
                        <Dropdown
                            options={agencyOptions}
                            onChange={onSelectAgency}
                            value={state.agency || ''}
                            required
                        >
                            Agency:
                        </Dropdown>
                    </Column>

                    {/* --- ADVERTISER --- */}
                    <Column>
                        <Dropdown
                            options={advertiserOptions}
                            onChange={onSelectAdvertiser}
                            value={state.advertiser || ''}
                            required
                        >
                            Advertiser:
                        </Dropdown>
                    </Column>

                    {/* --- GOALS --- */}
                    <Column>
                        <Dropdown
                            options={goalOptions}
                            onChange={onSelectGoals}
                            value={state.goals}
                            display="selectionInline"
                            required
                            multiple
                        >
                            Goals:
                        </Dropdown>
                    </Column>

                    {/* --- COLUMNS --- */}
                    <Column>
                        <Dropdown
                            options={state.columnOptions}
                            onChange={onSelectColumns}
                            value={state.columns}
                            display="selectionInline"
                            multiple
                        >
                            Columns:
                        </Dropdown>
                    </Column>
                </Row>

                {/* --- FORMULA COLUMNS FORM --- */}
                {state.useFormulaColumns ? (
                    <FormulaColumns
                        state={state}
                        onAdd={onAddFormula}
                        onUpdate={onUpdateFormula}
                        onRemove={onRemoveFormula}
                    />
                ) : null}
                <Row>
                    <div className="form-submit-button">
                        <Button type="submit">Submit</Button>
                    </div>
                </Row>
            </form>
        </div>
    );
}

export default PacingDocForm;
