import App from './App';
import { store } from './state';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

const APP_ENV = process.env.REACT_APP_ENV;

const rootElementId = 'app';
const root = document.getElementById(rootElementId);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    root
);

console.log('APP_ENV:', APP_ENV);
