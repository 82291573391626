import { convertDateToString, numberWithCommas } from '../../utils';
import { useAppSelector, useActions, useAsyncWrapper } from '../../hooks';

// API REQUESTS
import { downloadChangeSummaryLogs } from '../../api';

// TYPES
import { ChangeLogsSummary, DownloadChangeSummaryParams } from '../../state';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';

// COMPONENT PROPS
interface ChangeLogsSettingsProps {
    data: ChangeLogsSummary;
}

// FUNCTIONAL COMPONENT
function ChangeLogsSettings({ data }: ChangeLogsSettingsProps) {
    // HOOKS
    const asyncWrapper = useAsyncWrapper();

    // REDUX STATE
    const userId = useAppSelector(({ auth }) => auth.user?.id);

    // REDUX ACTIONS
    const { alertError } = useActions();

    const { summaryLogs, goalSettings } = data;

    // CONVERT DATE TO YYYY-MM-DD FORMAT
    const date = convertDateToString(new Date(summaryLogs.date));

    // HANDLE DOWNLOAD OF EDITTED BID/CAP RECOMMENDATIONS
    const onDownloadRecommendations = asyncWrapper(async () => {
        if (!userId) return;

        const csrDate = new Date(summaryLogs.date).toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

        // CREATE BASE FILE NAME
        const baseFileName = `_${goalSettings.goals.goalMappingName}_${csrDate}_${summaryLogs.time}.xlsx`;

        // SET PARAMS FOR BIDS RECOMMENDATIONS DOWNLOAD
        const params: DownloadChangeSummaryParams = {
            userId,
            date,
            goalId: goalSettings.goalId,
            time: summaryLogs.time,
            bulksheetType: 'bids',
            fileName: 'Bids' + baseFileName,
        };

        // DOWNLOAD FILES
        await Promise.all([
            // BIDS RECOMMENDATIONS
            downloadChangeSummaryLogs({ ...params }),
            // CAPS RECOMMENDATIONS
            downloadChangeSummaryLogs({
                ...params,
                bulksheetType: 'caps',
                fileName: 'Caps' + baseFileName,
            }),
        ]);
    });

    return (
        <div className="change-logs-settings">
            <div className="change-logs-settings-header">
                <h2>Goal Name: {goalSettings.goals.goalMappingName}</h2>
                <h2>Date: {date}</h2>
                <h2>Time: {summaryLogs.time}</h2>
                <div className="change-logs-table-header">
                    <h3>Goal Settings</h3>
                    <Button onClick={onDownloadRecommendations}>
                        Download Bid/Cap Recommendations (EDITED)
                    </Button>
                </div>
            </div>
            <table className="change-logs-settings-table">
                <tbody>
                    <tr>
                        <td>
                            <>
                                <span className="td-setting-name">Budget:</span>
                                ${numberWithCommas(goalSettings.budget, 2)}
                            </>
                        </td>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Conversion Type:
                                </span>
                                {goalSettings.conversionType}
                            </>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Conversion Column:
                                </span>
                                {goalSettings.conversionColumn}
                            </>
                        </td>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Revenue Column:
                                </span>
                                {goalSettings.revenueColumn}
                            </>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Flight Start Date:
                                </span>
                                {goalSettings.flightStart}
                            </>
                        </td>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Flight End Date:
                                </span>
                                {goalSettings.flightEnd}
                            </>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Efficiency Numerator:
                                </span>
                                {goalSettings.efficiencyNumerator}
                            </>
                        </td>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Efficiency Denominator:
                                </span>
                                {goalSettings.efficiencyDenominator}
                            </>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Efficiency Target:
                                </span>
                                {numberWithCommas(
                                    goalSettings.efficiencyTarget
                                )}
                            </>
                        </td>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Calculation Method:
                                </span>
                                {goalSettings.calculationMethod}
                            </>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Darwin Last N Days:
                                </span>
                                {goalSettings.darwinLastNDays}
                            </>
                        </td>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Dpad Last N Weeks:
                                </span>
                                {goalSettings.dpadLastNWeeks}
                            </>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Minimum Bid:
                                </span>
                                ${numberWithCommas(goalSettings.minBid, 2)}
                            </>
                        </td>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Maximum Bid:
                                </span>
                                ${numberWithCommas(goalSettings.maxBid, 2)}
                            </>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Min Daily Budget:
                                </span>
                                $
                                {numberWithCommas(
                                    goalSettings.minDailyBudget,
                                    2
                                )}
                            </>
                        </td>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Max Daily Budet:
                                </span>
                                $
                                {numberWithCommas(
                                    goalSettings.maxDailyBudget,
                                    2
                                )}
                            </>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <>
                                <span className="td-setting-name">
                                    Optimization Strategy:
                                </span>
                                {goalSettings.optimizationStrategy}
                            </>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default ChangeLogsSettings;
