// UI COMPONENTS
import Modal from '../../components/Modal/Modal';
import CustomAlertForm from './Custom-Alert-Form';

// TYPES
import {
    UserDetails,
    GoalsListItem,
    CustomAlertItem,
    CustomAlertState,
} from '../../state';

// COMPONENT PROPS
interface CustomAlertModalProps {
    data: CustomAlertItem;
    goals: GoalsListItem[];
    users: UserDetails[];
    onClose: () => void;
    onEdit?: (formState: CustomAlertState) => Promise<void>;
    isEditing?: boolean;
}

// FUNCTIONAL COMPONENT (EDIT/VIEW CUSTOM ALERT MODAL)
function CustomAlertModal({
    data,
    goals,
    users,
    onEdit,
    onClose,
    isEditing = false,
}: CustomAlertModalProps) {
    return (
        <Modal
            className="custom-alert-modal-container"
            onClose={() => onClose()}
            userClosable={false}
            header={`Alert ID: ${data.alertId}`}
            content={
                <CustomAlertForm
                    goalList={goals}
                    users={users}
                    data={data}
                    isEditing={isEditing}
                    isReadOnly={!isEditing}
                    onSubmit={onEdit}
                    onCancel={onClose}
                />
            }
        />
    );
}

export default CustomAlertModal;
