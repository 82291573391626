import { ENVIRONMENT } from '../../constants';
import {
    AddGoalForm,
    DropdownOption,
    CampaignFilter,
    POSTAddGoalBody,
} from '../../state';

// CHECKS IF A GIVEN CAMPAIGN MATCHES A GIVEN FILTER
const isCampaignMatch = (filter: CampaignFilter, campaign: DropdownOption) => {
    // CHECK IF CAMPAIGN NAME INCLUDES FILTER VALUE
    const isMatch = campaign.label
        .toLowerCase()
        .includes(filter.value.toLowerCase());

    if (filter.operator === 'contains' && isMatch) return true;

    if (filter.operator === 'does not contain' && !isMatch) return true;

    return false;
};

// SELECTS CAMPAIGNS MATCHING INPUTTED FILTERS
export const selectMatchingCampaigns = (
    campaigns: DropdownOption[],
    filters: CampaignFilter[]
) => {
    let filteredCampaigns: DropdownOption[] = [];

    // FOR EACH FILTER LOOP THROUGH AVAILABLE CAMPAIGNS TO FILTER OUT NON-MATCHES
    for (const filter of filters) {
        // IF AND/OR VALUE === 'AND' COMPARE AGAINST MATCHED CAMPAIGNS
        if (filter.andOr === 'and') {
            filteredCampaigns = [...filteredCampaigns].filter((campaign) =>
                isCampaignMatch(filter, campaign)
            );
        } else {
            // MATCH AGAINST ALL AVAILABLE CAMPAIGN OPTIONS - (AND/OR === 'OR' | '')
            const matches = campaigns.filter((campaign) =>
                isCampaignMatch(filter, campaign)
            );
            filteredCampaigns.push(...matches);
        }
    }

    // CREATE OBJECT TO FILTER OUT DUPLICATES
    const matchingCampaigns = filteredCampaigns.reduce(
        (matches: { [key: string]: string }, campaign) => {
            matches[campaign.value] = campaign.label;
            return matches;
        },
        {}
    );

    // RETURN ARRAY OF DROPDOWN OPTIONS OF CAMPAIGNS MATCHING FILTERS
    return Object.keys(matchingCampaigns).map(
        (key): DropdownOption => ({
            label: matchingCampaigns[key],
            value: key,
        })
    );
};

// FORMAT ADD GOAL REQUEST BODY
export const formatAddGoalBody = (
    state: AddGoalForm
): POSTAddGoalBody | undefined => {
    if (!state.agency || !state.advertiser) return;

    return {
        agencyId: state.agency.agencyId,

        advertiserId: state.advertiser.advertiserId,

        autoAddCampaign: state.autoAdd360,

        campaignIds: state.selectedCampaigns
            .filter((option) => option.value !== 'all')
            .map((option) => option.value)
            .join(','),

        goalMappingName: state.name.trim(),

        reportType: state.reportType || 'keyword',

        engineIds: state.selectedEngines
            .filter((option) => option.value !== 'all')
            .map((option) => option.value)
            .join(','),

        useFilter: state.useFilter,

        filters: JSON.stringify(state.campaignFilters).replaceAll(`"`, `\\\"`),

        minBid: state.minBid,

        maxBid: state.maxBid,

        minDailyBudget:
            state.minDailyBudget === '' ? 0 : parseInt(state.minDailyBudget),

        maxDailyBudget:
            state.maxDailyBudget === '' ? 0 : parseInt(state.maxDailyBudget),

        campaignsToExclude: state.excludedCampaigns
            .map((option) => option.value)
            .join(','),

        keywordsToExclude: state.excludedKeywords
            .map((option) => option.value)
            .join(','),

        optInOutIntradayAlerts: false,

        isIntraday: false,

        hourlyWebQueries: {
            OneDayUrl: '',
            last34DaysUrl: '',
        },

        syncGoals: state.syncGoal,

        environment: ENVIRONMENT,
    };
};
