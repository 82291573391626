import { useActions, useAppSelector } from '../../hooks';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import BingSubAccount from './Bing-Sub-Account';
import { memo } from 'react';

function BingAdsSubAccounts() {
    // REDUX ACTIONS
    const { addBingSubAccount } = useActions();

    // REDUX STATE
    const { bingAdsSubAccounts } = useAppSelector(
        ({ editAccountForm }) => editAccountForm
    );

    // HANDLE ADD BING ADS SUB ACCOUNT INPUTS
    const onAddSubAccount = () => {
        addBingSubAccount();
    };

    return (
        <>
            <h4 className="edit-account-header">Sub Accounts:</h4>
            {bingAdsSubAccounts.map((data, index) => {
                return (
                    <BingSubAccount
                        data={data}
                        index={index}
                        key={`bing-sub-account-${index}`}
                    />
                );
            })}
            {/* CREATES INPUT GROUP FOR ADDITIONAL SUB ACCOUNT */}
            <div className="insert-inputs-row-button">
                <Button onClick={onAddSubAccount} color="brand-2">
                    New Sub Account <i className="icon-add" />
                </Button>
            </div>
        </>
    );
}

export default memo(BingAdsSubAccounts);
