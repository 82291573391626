import produce from 'immer';
import {
  SA360FormState,
  SA360FormAction,
  SA_360_ACTION_TYPES,
  INITIAL_SA_360_STATE,
  INITIAL_SA_ADVERTISER,
} from '../form-models';

export const sa360FormReducer = produce(
  (
    state: SA360FormState = INITIAL_SA_360_STATE,
    action: SA360FormAction
  ): SA360FormState => {
    switch (action.type) {
      case SA_360_ACTION_TYPES.SET_GOOGLE_ADS_SUPPORT:
        state.googleAdsSupport = action.payload;
        return state;

      case SA_360_ACTION_TYPES.SET_BING_ADS_SUPPORT:
        state.bingAdsSupport = action.payload;
        return state;

      case SA_360_ACTION_TYPES.SET_DSA_SUPPORT:
        state.dsaSupport = action.payload;
        return state;

      case SA_360_ACTION_TYPES.SET_PLA_SUPPORT:
        state.plaSupport = action.payload;
        return state;

      case SA_360_ACTION_TYPES.SET_REVENUE_SUPPORT:
        state.isRevenueSupport = action.payload;
        return state;

      case SA_360_ACTION_TYPES.SET_CUSTOM_COLUMNS_SUPPORT:
        state.isCustomColumnsSupport = action.payload;
        return state;

      case SA_360_ACTION_TYPES.SET_MANAGER_EMAIL:
        state.sa360ManagerEmail = action.payload;
        return state;

      case SA_360_ACTION_TYPES.ADD_REVENUE_COLUMN:
        state.customRevenueColumns.push({
          name: '',
          status: true,
          existing: false,
        });
        return state;

      case SA_360_ACTION_TYPES.UPDATE_REVENUE_COLUMN: {
        const { index, name, status } = action.payload;

        // UPDATE NEW REVENUE COLUMN (NAME/STATUS)
        if (state.customRevenueColumns[index]) {
          if (name !== undefined) {
            state.customRevenueColumns[index].name = name;
          }

          if (status !== undefined) {
            state.customRevenueColumns[index].status = status;
          }
        }

        return state;
      }

      case SA_360_ACTION_TYPES.REMOVE_REVENUE_COLUMN:
        state.customRevenueColumns = state.customRevenueColumns.filter(
          (data, index) => index !== action.payload
        );
        return state;

      case SA_360_ACTION_TYPES.ADD_CONVERSION_COLUMN:
        state.customColumns.push({
          name: '',
          status: true,
          existing: false,
        });
        return state;

      case SA_360_ACTION_TYPES.UPDATE_CONVERSION_COLUMN: {
        const { index, name, status } = action.payload;

        if (state.customColumns[index]) {
          if (name !== undefined) {
            state.customColumns[index].name = name;
          }
          if (status !== undefined) {
            state.customColumns[index].status = status;
          }
        }

        return state;
      }

      case SA_360_ACTION_TYPES.REMOVE_CONVERSION_COLUMN:
        state.customColumns = state.customColumns.filter(
          (data, index) => index !== action.payload
        );
        return state;

      case SA_360_ACTION_TYPES.SET_AGENCY_NAME:
        state.agencyName = action.payload;
        return state;

      case SA_360_ACTION_TYPES.SET_AGENCY_ID:
        state.agencyId = action.payload;
        return state;

      case SA_360_ACTION_TYPES.ADD_ADVERTISER: {
        const advertiser = { ...INITIAL_SA_ADVERTISER };

        if (state.isEditing) {
          advertiser.status = true;
        }

        state.advertiserData.push(advertiser);
        return state;
      }

      case SA_360_ACTION_TYPES.UPDATE_ADVERTISER: {
        const { index, id, name, status, existing } = action.payload;

        if (state.advertiserData[index]) {
          // UPDATE ADVERTISER NAME (NEW ONLY)
          if (!existing && name !== undefined) {
            state.advertiserData[index].name = name;
          }

          // UPDATE ADVERTISER ID (NEW ONLY)
          if (!existing && id !== undefined) {
            state.advertiserData[index].id = id;
          }

          // UPDATE ADVERTISER STATUS (NEW/EXISTING)
          if (status !== undefined) {
            state.advertiserData[index].status = status;
          }
        }
        return state;
      }

      case SA_360_ACTION_TYPES.REMOVE_ADVERTISER:
        state.advertiserData = state.advertiserData.filter(
          // REMOVE ONLY NEW (NON-EXISTING) ADVERTISERS
          (advertiser, index) => advertiser.existing || index !== action.payload
        );
        return state;

      case SA_360_ACTION_TYPES.LOAD_FORM_DATA: {
        const data = action.payload;
        state.isEditing = true;
        state.sa360ManagerEmail = {
          label: data.sa360ManagerEmail || '',
          value: data.sa360ManagerEmail || '',
        };
        state.agencyName = data.accountName;
        state.agencyId = data.agencyId;
        state.platform = data.platform;
        state.bingAdsSupport = !!data.bingAdsSubAccounts?.length;
        state.googleAdsSupport = !!data?.googleAdsSubAccounts?.length;
        state.isRevenueSupport = !!data?.customRevenueColumns?.length;
        state.plaSupport = data.isPLASupport;
        state.dsaSupport = data.isDSASupport;
        state.customColumns =
          data.customColumns?.map((data) => ({
            name: data.column_name,
            status: data.column_status,
            existing: true,
          })) || [];
        state.customRevenueColumns =
          data.customRevenueColumns?.map((data) => ({
            name: data.column_name,
            status: data.column_status,
            existing: true,
          })) || [];
        state.agencyName = data.accountName;
        state.agencyId = data.agencyId;
        state.isCustomColumnsSupport = !!data.customColumns;
        state.advertiserData =
          data.brands?.map((brand) => {
            return {
              id: brand.advertiser_id,
              name: brand.actual_adv_name,
              status: brand.brand_status === 1,
              existing: true,
            };
          }) || [];
        return state;
      }

      case SA_360_ACTION_TYPES.RESET_SA_360_FORM:
        state = { ...INITIAL_SA_360_STATE };
        return state;

      default:
        return state;
    }
  },
  INITIAL_SA_360_STATE
);
