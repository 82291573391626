import { useActions, useAppSelector } from '../../hooks';

// UI COMPONENTS
import Textbox from '@publicismedia-ds/ui-textbox';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import SettingsDateRange from '../Product-Settings/Settings-Date-Range';
import InputErrorMessage from '../Input-Error-Message/Input-Error-Message';
import ProductSettings from '../Product-Settings/Product-Settings-Container';

// BID FACTOR MIN/MAX
const BID_FACTOR = {
    MIN: 1,
    MAX: 100,
};

// FUNCTIONAL COMPONENT (MATCH TYPE STRATEGY ANALYSIS SETTINGS)
function MatchTypeAnalysisSettings() {
    // REDUX STATE
    const {
        customEndDate,
        customStartDate,
        broadPercentBidFactor,
        phrasePercentBidFactor,
    } = useAppSelector(({ universalSettings }) => universalSettings);

    // REDUX ACTIONS
    const { updatePhrasePercentFactor, updateBroadPercentFactor } =
        useActions();

    const yesterday = new Date();
    -yesterday.setDate(yesterday.getDate() - 1);

    // MAX DATE ALLOWED
    const isValidEndDate =
        customEndDate && new Date(customEndDate) <= yesterday;

    // MINIMUN DATE ALLOWED
    const minDate = new Date(yesterday);
    minDate.setHours(0, 0, 0, 0);
    minDate.setDate(minDate.getDate() - 89);

    const intPhrasePercent = parseInt(phrasePercentBidFactor) || 0;
    const intBroadPercent = parseInt(broadPercentBidFactor) || 0;

    const isValidStartDate =
        customStartDate && new Date(customStartDate) >= minDate;

    const isValidPhrasePercent =
        intPhrasePercent >= BID_FACTOR.MIN &&
        intPhrasePercent <= BID_FACTOR.MAX;
    const isValidBroadPercent =
        intBroadPercent >= BID_FACTOR.MIN && intBroadPercent <= BID_FACTOR.MAX;

    const isDisabled =
        !isValidStartDate ||
        !isValidEndDate ||
        !isValidBroadPercent ||
        !isValidPhrasePercent;

    // HANDLE UPDATING PHRASE PERCENTAGE VALUE
    const onSetPhrasePercentage = (
        evt: React.ChangeEvent<HTMLInputElement>
    ) => {
        updatePhrasePercentFactor(evt.target.value.toString());
    };

    // HANDLE UPDATING BROAD PERCENTAGE VALUE
    const onSetBroadPercentage = (evt: React.ChangeEvent<HTMLInputElement>) => {
        updateBroadPercentFactor(evt.target.value);
    };

    return (
        <ProductSettings disabled={isDisabled}>
            <Row>
                <h3>Match Type Strategy Analysis Settings</h3>
            </Row>
            <Row cols={4}>
                <SettingsDateRange />
                <Column>
                    <Textbox
                        type="number"
                        min={BID_FACTOR.MIN}
                        max={BID_FACTOR.MAX}
                        value={phrasePercentBidFactor}
                        onChange={onSetPhrasePercentage}
                        required
                    >
                        Phrase Percent Bid Factor
                    </Textbox>
                    <InputErrorMessage display={!isValidPhrasePercent}>
                        Please enter a value 1-100
                    </InputErrorMessage>
                </Column>
                <Column>
                    <Textbox
                        type="number"
                        min={BID_FACTOR.MIN}
                        max={BID_FACTOR.MAX}
                        value={broadPercentBidFactor}
                        onChange={onSetBroadPercentage}
                        required
                    >
                        Broad Percent Bid Factor
                    </Textbox>
                    <InputErrorMessage display={!isValidBroadPercent}>
                        Please enter a value 1-100
                    </InputErrorMessage>
                </Column>
            </Row>
        </ProductSettings>
    );
}

export default MatchTypeAnalysisSettings;
