import produce from 'immer';
import {
  GoogleAdsFormState,
  GoogleAdsFormAction,
  GOOGLE_ADS_ACTION_TYPES,
  INITIAL_GOOGLE_ADS_FORM,
  INITIAL_SUB_CUSTOMER_DATA,
} from '../form-models';

export const googleAdsFormReducer = produce(
  (
    state: GoogleAdsFormState = INITIAL_GOOGLE_ADS_FORM,
    action: GoogleAdsFormAction
  ): GoogleAdsFormState => {
    switch (action.type) {
      case GOOGLE_ADS_ACTION_TYPES.SET_MANAGER_EMAIL:
        state.adwordsMangerEmail = action.payload;
        return state;

      case GOOGLE_ADS_ACTION_TYPES.SET_CUSTOMER_NAME:
        state.customerName = action.payload;
        return state;

      case GOOGLE_ADS_ACTION_TYPES.SET_CUSTOMER_ID:
        state.customerId = action.payload;
        return state;

      case GOOGLE_ADS_ACTION_TYPES.SET_IS_MANGER_ACCOUNT:
        state.isManagerAccount = action.payload;
        return state;

      case GOOGLE_ADS_ACTION_TYPES.ADD_SUB_CUSTOMER: {
        const subCustomer = { ...INITIAL_SUB_CUSTOMER_DATA };

        if (state.isEditing) {
          subCustomer.status = true;
        }

        state.customerData.push(subCustomer);

        return state;
      }

      case GOOGLE_ADS_ACTION_TYPES.UPDATE_SUB_CUSTOMER: {
        const { index, id, name, status, existing } = action.payload;

        if (state.customerData[index]) {
          if (!existing && id !== undefined) {
            state.customerData[index].id = id;
          }

          if (!existing && name !== undefined) {
            state.customerData[index].name = name;
          }

          if (status !== undefined) {
            state.customerData[index].status = status;
          }
        }

        return state;
      }

      case GOOGLE_ADS_ACTION_TYPES.REMOVE_SUB_CUSTOMER:
        state.customerData = state.customerData.filter((data, index) => {
          return index !== action.payload;
        });
        return state;

      case GOOGLE_ADS_ACTION_TYPES.LOAD_FORM_DATA: {
        const data = action.payload;

        state.adwordsMangerEmail = {
          label: data.adwordsManagerEmail || '',
          value: data.adwordsManagerEmail || '',
        };

        state.isEditing = true;
        state.isManagerAccount = data.isManagerAccount ? '1' : '0';
        state.customerName = data.managerCustomerName || data.accountName || '';
        state.customerId = data.managerCustomerId || data.agencyId || '';
        state.customerData =
          data.googleAdsSubAccounts?.map((data) => {
            return {
              id: data.sub_account_id.toString(),
              name: data.sub_account_name,
              status: data.sub_act_status,
              existing: true,
            };
          }) || [];

        return state;
      }

      case GOOGLE_ADS_ACTION_TYPES.RESET_GOOGLE_ADS_FORM:
        state = { ...INITIAL_GOOGLE_ADS_FORM };
        return state;

      default:
        return state;
    }
  },
  INITIAL_GOOGLE_ADS_FORM
);
