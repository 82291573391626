import { Outlet, Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks';

function AdminRoutes() {
    const { user } = useAppSelector(({ auth }) => auth);

    // USER MUST HAVE ROLE ID OF 1 OR 2 TO ACCESS ROUTES
    const isAdmin = user?.roleId === '1' || user?.roleId === '2';

    return isAdmin ? <Outlet /> : <Navigate to="/" />;
}

export default AdminRoutes;
