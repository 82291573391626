import './Anomaly-Data.scss';

import { useActions, useAppSelector, useAsyncWrapper } from '../../../../hooks';

// TYPES
import { AnomalyDataType } from '../../../../state';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import KeywordPerformance from './Keyword-Performance';
import AnomalyDataTable from '../../../../components/Charts/Anomaly-Charts/Performance-Table';
import PerformanceChart from '../../../../components/Charts/Anomaly-Charts/Performance-Chart';
import PerformanceGraph from '../../../../components/Charts/Anomaly-Charts/Performance-Graph';

// API REQUESTS
import {
  downloadMomYoyBulkSheet,
  downloadAnomalyBulksheet,
} from '../../../../api';

// COMPONENT PROPS
interface AnomalyDataProps {
  data: AnomalyDataType;
  efficiencyColumnName: string;
}

// FUNCTIONAL COMPONENT (ANOMALY DATA)
function AnomalyData({ data, efficiencyColumnName }: AnomalyDataProps) {
  // HOOKS
  const wrapper = useAsyncWrapper();

  // REDUX STATE
  const { goals } = useAppSelector((state) => state.universalSettings);

  // REDUX ACTIONS
  const { alertError } = useActions();

  // PRIOR DATE WEEK OVER WEEK
  const priorDateWoW =
    data.weekOverWeekPerformanceData.status !== 'failure'
      ? data.weekOverWeekPerformanceData.lastToLastWeekPerformanceData
          .startDate +
        ' to ' +
        data.weekOverWeekPerformanceData.lastToLastWeekPerformanceData.endDate
      : null;

  // RECENT DATE WEEK OVER WEEK
  const recentDateWoW =
    data.weekOverWeekPerformanceData.status !== 'failure'
      ? data.weekOverWeekPerformanceData.lastWeekPerformanceData.startDate +
        ' to ' +
        data.weekOverWeekPerformanceData.lastWeekPerformanceData.endDate
      : null;

  // HANDLE DOWNLOADING OF BULKSHEET DATA
  const onDownloadBulksheet = wrapper(async () => {
    if (!goals) return;

    await downloadAnomalyBulksheet(
      data.anomalyData,
      goals.goalId,
      goals.goalMappingName
    );
  });

  return (
    <>
      <div className="row">
        <hr />
      </div>
      <div className="row">
        <div className="anomaly-header">
          <h3>Campaigns with Anomaly</h3>
          <Button
            onClick={onDownloadBulksheet}
            disabled={data.anomalyData.length <= 1}
          >
            Download Campaign Anomaly Report
          </Button>
        </div>
      </div>
      <AnomalyDataTable data={data.anomalyData} conv={efficiencyColumnName} />
      <div className="row">
        <h3>day over day chart</h3>
        <PerformanceChart
          prior={
            data?.dayOverDayPerformanceData?.dayBeforeYestPerformanceData ||
            null
          }
          recent={data.dayOverDayPerformanceData?.yestPerformanceData || null}
          difference={
            data?.dayOverDayPerformanceData?.percentDiffPerformanceData || null
          }
          priorDate={
            data?.dayOverDayPerformanceData?.dayBeforeYestPerformanceData
              ?.date || 'N/A'
          }
          recentDate={
            data?.dayOverDayPerformanceData?.yestPerformanceData?.date || 'N/A/'
          }
          conv={efficiencyColumnName}
        />
      </div>
      <div className="row">
        <h3>day over day performance graph</h3>
        <PerformanceGraph
          prior={
            data.dayOverDayPerformanceData?.dayBeforeYestPerformanceData || null
          }
          recent={data.dayOverDayPerformanceData?.yestPerformanceData || null}
          priorDate={
            data.dayOverDayPerformanceData?.dayBeforeYestPerformanceData
              ?.date || 'N/A'
          }
          recentDate={
            data.dayOverDayPerformanceData?.yestPerformanceData?.date || 'N/A'
          }
        />
      </div>
      <div className="row">
        <h3>Week over week chart</h3>
        <PerformanceChart
          prior={data.weekOverWeekPerformanceData.lastToLastWeekPerformanceData}
          recent={data.weekOverWeekPerformanceData.lastWeekPerformanceData}
          difference={
            data.weekOverWeekPerformanceData.percentDiffPerformanceData
          }
          priorDate={priorDateWoW || 'N/A'}
          recentDate={recentDateWoW || 'N/A'}
          conv={efficiencyColumnName}
        />
      </div>
      <div className="row">
        <h3>week over week performance graph</h3>
        <PerformanceGraph
          prior={data.weekOverWeekPerformanceData.lastToLastWeekPerformanceData}
          recent={data.weekOverWeekPerformanceData.lastWeekPerformanceData}
          priorDate={priorDateWoW || 'N/A'}
          recentDate={priorDateWoW || 'N/A'}
        />
      </div>
      <div className="row">
        <div className="anomaly-header">
          <h3>month over month chart</h3>
          <Button
            onClick={async () => {
              try {
                await downloadMomYoyBulkSheet(
                  goals!.goalId,
                  goals!.goalMappingName,
                  'M'
                );
              } catch (error) {
                alertError('Server error. Unable to download bulksheet.');
              }
            }}
          >
            Download Month Over Month Bulksheet
          </Button>
        </div>
        <KeywordPerformance
          dataMOM={data.KeywordMomYoyData.MOM}
          conversion={efficiencyColumnName}
        />
      </div>
      <div className="row">
        <div className="anomaly-header">
          <h3>year over year chart</h3>
          <Button
            onClick={async () => {
              try {
                await downloadMomYoyBulkSheet(
                  goals!.goalId,
                  goals!.goalMappingName,
                  'Y'
                );
              } catch (error) {
                alertError('Server error. Unable to download bulksheet.');
              }
            }}
            disabled={data.KeywordMomYoyData.YOY.CTR['Bottom 20'].length < 1}
          >
            download year over year bulksheet
          </Button>
        </div>
        <KeywordPerformance
          dataYOY={data.KeywordMomYoyData.YOY}
          conversion={efficiencyColumnName}
        />
      </div>
    </>
  );
}

export default AnomalyData;
