import { DpadDataType } from '../../state';

import DataTable, {
    ColumnTemplate,
    DataProvider,
} from '@publicismedia-ds/ui-datatable';

// COMPONENT PROPS
interface BudgetChangeSummaryChartProps {
    data: DpadDataType;
}

// FUNCTIONAL COMPONENT
function BudgetChangeSummaryChart({ data }: BudgetChangeSummaryChartProps) {
    return (
        <DataTable
            bindkey="pacing"
            data={new DataProvider({ data: [data['Budget Change Summary']] })}
            emptyState={
                <>
                    <h2>No data available.</h2>
                </>
            }
        >
            <ColumnTemplate bind="pacing">Pacing Status</ColumnTemplate>
            <ColumnTemplate
                bind="pacingDifference"
                type="currency"
                align="center"
            >
                Pacing Difference
            </ColumnTemplate>
            <ColumnTemplate
                bind="costDBDifference"
                type="currency"
                align="center"
            >
                Cost DB Difference
            </ColumnTemplate>
        </DataTable>
    );
}

export default BudgetChangeSummaryChart;
